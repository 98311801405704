import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  place-items: center;

  width: 52px;
  height: 40px;
  border-radius: 4px;
  background: linear-gradient(150deg, ${props => props.theme.colors.turquoiseBlue} 0%, ${props => props.theme.colors.oceanGreen} 100%);

  transition: all .2s;

  &:hover {
       transform: scale(1.05);
       box-shadow:0px 10px 10px rgba(0,0,0,.1)
   }

  >a {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;

       svg {
           width: 24px;
           height: 24px;
           path {
               fill: #fff;
           }
       }
  }
`;
