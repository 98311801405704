import React, { useEffect, useState } from 'react';
import { TagContainer } from './styles';

interface TagProps {
    children?: string;
    Icon?: any;
    className?: any;    
    onClick?():void
}

const Tag: React.FC<TagProps> = ({
    children,
    Icon = false,    
    onClick = () => {}
}) => {

    return (
        <TagContainer onClick={onClick}>
            <span>{children}</span>
            {Icon && (
                <span className="icon">
                    <Icon />
                </span>
            )}
        </TagContainer>
    );
};

export default Tag;
