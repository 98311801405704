import { Link } from 'react-router-dom';
import { IconLoading } from '../../icons';
import { Container } from './styles';

interface LinkProps {
    loading?: boolean;
    children?: string;
    style?:
        | 'success'
        | 'info'
        | 'warning'
        | 'danger'
        | 'delete'
        | 'transparent';
    className?:
        | 'success'
        | 'info'
        | 'warning'
        | 'danger'
        | 'delete'
        | 'transparent';
    rounded?: boolean;
    Icon?: any;
    to: string;
}

const LinkLinear: React.FC<LinkProps> = ({
    loading = false,
    children,
    className = 'transparent',
    rounded = false,
    Icon = false,
    to = '/',
}) => {
    return (
        <Link to={to} style={{textDecoration:'none'}}>
        <Container
            rounded={rounded}
            className={`${className} form-children-default`}
            style={{color:'#fff',textDecoration:'none'}}
        >
            
                <>
                    {Icon && (
                        <span className="icon">
                            <Icon />
                        </span>
                    )}
                    {loading ? (
                        <span className="children">
                            <IconLoading />
                        </span>
                    ) : (
                        children && <span className="children">{children}</span>
                    )}
                </>
            
        </Container>
        </Link>
    );
};

export default LinkLinear;
