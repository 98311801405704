import { InputHTMLAttributes, useState, useCallback, useRef } from 'react'
import { IconClose, IconSearch } from '../../icons';
import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    handleOnClearFilterUser: () => void;
    searchTerm: string;
}

export default function InputSearch({ handleOnClearFilterUser, searchTerm, ...rest }: InputProps) {
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <Container>
            <div className="icon">
                <IconSearch size='14' />
            </div>

            <input 
                ref={inputRef}
                type="text"
                placeholder='pesquise por usuarios'
                {...rest}
            />

            {!!searchTerm.length && (
                <button
                    className="iconClose"
                    onClick={handleOnClearFilterUser}
                >
                    <IconClose size='10' color='#FF868B' />
                </button>
            )}
        </Container>
    );
}
