import React from 'react';
import { ContainerInputConditionTarget } from './styles';

// import { Container } from './styles';
interface IProps {
    indice: any;
    indiceCondition: any;
    condition: any;
    handleEditCondition(
        key: any,
        condiitonKey: any,
        type: any,
        value: any
    ): void;
}

const InputConditionTarget: React.FC<IProps> = ({
    condition,
    handleEditCondition,
    indice,
    indiceCondition,
}) => {
    return (
        condition.condition &&
        (condition.condition === 'igual' ||
            condition.condition === 'diferente' ||
            condition.condition === 'contem' ||
            condition.condition === 'comeco' ||
            condition.condition === 'termino' ||
            condition.condition === 'parecido') && (
            <ContainerInputConditionTarget>
                <input
                    onChange={(e) =>
                        handleEditCondition(
                            indice,
                            indiceCondition,
                            'value',
                            e.target.value
                        )
                    }
                    placeholder="Valores separados por virgula"
                    value={condition.value}
                    className="target"
                />
                <div className="list">
                    {condition.value &&
                        condition.value
                            .split(',')
                            .map((tag: any, tagKey: any) => (
                                <div className="tag" key={tagKey}>
                                    <span>{tag}</span>
                                </div>
                            ))}
                </div>
            </ContainerInputConditionTarget>
        )
    );
};

export default InputConditionTarget;
