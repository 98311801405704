import styled from "styled-components";

interface TdDirection {
  flexDirection: 'row' | 'column';
  justifyContent?: any 
}

export const Td = styled.div<TdDirection>`
  display: flex;
  flex-direction: ${props => props.flexDirection};
  gap: 8px;
  justify-content: ${props => props.justifyContent}; 


  @media (max-width: 900px){
    .bot {
      width: 90px;
    }
  }
`

export const TdTitle = styled.h2`
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  color: ${({theme}) => theme.colors.subTitle};
  @media (max-width: 900px) {
    white-space: break-spaces;
  }
  @media (max-width: 425px) {
    font-size: 12px;
  }
`

export const TdSubtitle = styled.h3`
  font-size: 14px;
  font-weight: normal;
  white-space: nowrap;
  color: ${({theme}) => theme.colors.subTitle};
`

export const TdDescription = styled.span`
  font-size: 10px;
  font-weight: 500;
  color: #B6B6C5;
`

export const TdButtons = styled.div`
  display: flex;
  button {
    margin-left: 10px;
  }
`