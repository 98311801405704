/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import ButtonLinear from '../../components/form/ButtonLinear';
import ContainerFilter from '../../components/form/ContainerFilter';
import SelectDefault from '../../components/form/SelectDefault';
import Column from '../../components/grid/Columns';
import Row from '../../components/grid/Row';
import { useRoutes } from '../../contexts/RoutesContext';
import { IFilterCard } from '../../types/IFilterCard';
import { Container } from './style';

import LineBarChart from '../../components/graph/LineBarChart';
import CardTotalIcon from '../../components/pages/dashboard/CardTotalIcon';
import { IconCheckedRounded, IconClock, IconHeadphone, IconPause, IconPhoneCanceled } from '../../components/icons';
import TableSimple from '../../components/TableSimple';
import CardContainer from '../../components/layout/containers/CardContainer';
import { useMessage } from '../../contexts/MessagesContext';
import Loading from '../../components/Loading/Loading';
import 'react-loading-skeleton/dist/skeleton.css';
import moment from 'moment';
import { GrupoAtendimento } from '../../types/GrupoAtendimento';
import apiTropa from '../../services/apiTropa';
import InputDefault from '../../components/form/InputDefault';

import 'react-loading-skeleton/dist/skeleton.css';

//COMPONENTS
import ModalDefault from '../../components/ModalDefault';
import FieldGroup from '../../components/layout/containers/FieldGroup';
import Field from '../../components/layout/containers/Field';
import FailedRegister from '../../components/FailedRegister';
import TableDefault from '../../components/TableDefault';

// ICONS
import { IconArrowLeft } from '../../components/icons';

import InputSwitchDefault from '../../components/form/InputSwitchDefault';
import { Td, TdTitle } from '../../components/layout/containers/Td';
import { SectionTables } from '../FilaAtendimentoGroup/styles';
import InputRadioDefault from '../../components/form/InputRadioDefault';

interface IDTOEncaminharManual {
    id_grupo_atendimento: number;
    id_fila_atendimento: number;
    id_usuario: number;
    id_operador: number;
}

interface OptionsOperator {
    apelido?: string;
    nome?: string;
    id_login: string;
}

export default function Dashboard() {
    const { setPageName } = useRoutes();
    const { addDanger, addSuccess, addConfirm } = useMessage();
    const tokenData: any = window.sessionStorage.getItem('token');

    const [loading, setLoading] = useState(false);

    //2018-06-12T19:30
    const [grupos, setGrupos] = useState<Array<GrupoAtendimento>>([]);
    const [filter, setFilter] = useState<IFilterCard>({
        dataInicio: moment().startOf('day').format('YYYY-MM-DDTHH:mm'),
        dataFim: moment().endOf('day').format('YYYY-MM-DDTHH:mm'),
        nivelDeServicoSegundos: 60,
    });

    const [operadoresDisponiveis, setOperadoresDisponiveis] = useState<Array<any>>([]);
    const [operadoresPausa, setOperadoresPausa] = useState<Array<any>>([]);

    // const [atendimentos, setAtendimentos] = useState<Array<any>>([]);

    const [atendimentosChart, setAtendimentosChart] = useState<Array<any>>([]);

    const [modalEncaminhar, setModalEncaminhar] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);
    const [DTOEncaminharManual, setDTOEncaminharManual] = useState<IDTOEncaminharManual>();
    const [optionsOperadores, setOptionsOperadores] = useState<OptionsOperator[]>([]);
    const [timeReload, setTimeReload] = useState<number>(20000);
    const [optionReload, setOptionReload] = useState<any>([]);
    const [backOff, setBackOff] = useState<any>([]);

    const [cardData, setCardData] = useState({
        nivelServico: 0,
        operadoresAtivos: 0,
        operadoresEmPausaImprodutiva: 0,
        operadoresEmPausaProdutiva: 0,
        pausasProgramadas: 0,
        percentAbandono: 0,
        percentAtendidas: 0,
        tempMedioAtendimento: 0,
        tempMedioEspera: 0,
        totalAbabdono: 0,
        totalEmAtendimento: 0,
        totalEmFila: 0,
        totalFinalizados: 0,
    });

    const setDadosFilter = (key: string, value: any) => {
        let input: any = filter;
        input[key] = value;
        setFilter({ ...input });
    };

    useEffect(() => {
        setPageName('Dashboard');
        getGrupos();
        getDashBoardData();
        getOptionsOperadores();
        getBackOff();
    }, []);

    async function getBackOff() {
        const response: any = await apiTropa.get('/cliente/get');
        setBackOff(([response.data.data.backoffice]))
    }

    function handleOnBackOff(item: any) {
        setBackOff(item)
        const verify = item.includes(1)

        const response = apiTropa.put('cliente/edit', {backoffice: verify ? 1 : 0}, {
        headers: {
            'Authorization': 'Bearer '+tokenData
        }})
    }


    async function getGrupos() {
        try {
            let response: any = await apiTropa.get('grupo-get');

            setGrupos([...response.data.data]);
        } catch (e) {
            addDanger('Ocorreu um erro', 'Tente novamente mais tarde');
        }
    }


    async function getDashBoardData() {
        try {


            setLoading(true);
            let response: any = await apiTropa.get('/dashboard/admin', { params: filter });
            setCardData({...response.data.data})

            let responseOperadores: any = await apiTropa.get('/dashboard/operadores-ativos', {
                params: filter,
            });
            setOperadoresDisponiveis(responseOperadores.data.data);

            let responseOperadoresPausa: any = await apiTropa.get('/dashboard/operadores-em-pausa', {
                params: filter,
            });
            setOperadoresPausa(responseOperadoresPausa.data.data);

            // let totalAtendimentos: any = await apiTropa.get('/dashboard/total-atendimentos', { params: filter });
            // setAtendimentos(totalAtendimentos.data.data);

            let chartAtendimentos: any = await apiTropa.get('/dashboard/chart-atendimentos', { params: filter });
            setAtendimentosChart(chartAtendimentos.data.data);

            let attendenceFilter: any = await apiTropa.get(
                `relatorio/fila${
                    filter.id_grupo_atendimento ? `?gruposAtendimento[]=${filter.id_grupo_atendimento}` : ''
                }`
            );
            
            const data: any = []

            attendenceFilter.data.data.map((row:any, key:any) => {
                if ( row.fila.length > 0 ) {
                    data.push(row)
                }
            })

            setData([...data]);

            setLoading(false);
        } catch (err: any) {
            if (err.response) {
                addDanger('Atenção', String(err.response.data.message));
            } else {
                addDanger('Atenção', String(err));
            }
            setTimeout(function () {
                //  history.push('/grupo-atendimento/'+props.match.params.id)
            }, 2000);
        }
    }

    useEffect(() => {
        const setInterva = setInterval(() => {
            if (optionReload.length > 0) {
                getDashBoardData();
            }
        }, timeReload);

        return () => clearInterval(setInterva);
    }, [optionReload, timeReload]);

    function openModalEncaminhar(row: any) {
        setDTOEncaminharManual({
            id_grupo_atendimento: row.id_grupo_atendimento,
            id_fila_atendimento: row.id_fila_atendimento,
            id_usuario: row.id_usuario,
            id_operador: 0,
        });
        setModalEncaminhar(true);
    }

    async function onChangeDTOEncaminharManual(key: any, value: any) {
        const copy = DTOEncaminharManual as any;
        copy[key] = value;
        setDTOEncaminharManual({ ...copy });
    }

    async function handleEncaminharManual() {
        try {
            setLoading(true);
            await apiTropa.post(
                `/atendimento/encaminhar-manualmente/${DTOEncaminharManual?.id_grupo_atendimento}`,
                DTOEncaminharManual
            );
            addSuccess('Sucesso', 'Encaminhado com sucesso!');
            setModalEncaminhar(false);
            getDashBoardData();
        } catch (err: any) {
            if (err.response) {
                addDanger('Atenção', String(err.response.data.message));
            } else {
                addDanger('Atenção', String(err));
            }
            setLoading(false);
        }
    }

    function getOptionsOperadores() {
        apiTropa.get('/chat-cliente-get-operadores').then((res: any) => {
            setOptionsOperadores(
                res.data.data.filter((obj: any) => obj.status_operador === 'online').map((row: any) => row)
            );
        });
    }

    function converterDadosEntradaForName(data: any) {
        try {
            const dadosEntrada: any = JSON.parse(JSON.parse(data));
            return dadosEntrada['Nome completo'];
        } catch (err: any) {
            return '';
        }
    }

    async function handleOnLogoutOperator(id: any) {
        try {
            await apiTropa.get(`operador/logout-externo/${id}`)
            addSuccess('Sucesso', 'Usuario deslogado com sucesso!');
            getDashBoardData();
        } catch(err: any) {
            console.log(err)
        }
    }


    const ContainerDadosEntrada = ({ objData, canal }: any) => {
        return (
            <div className="sectionTable">
                <TableSimple width="100%" height="250px">
                    <thead>
                        <tr>
                            <th>Posição na fila</th>
                            <th>Canal</th>
                            <th>Nome</th>
                            <th>Data Entrada</th>
                            <th className="thButton">Encaminhar</th>
                        </tr>
                    </thead>

                    <tbody>
                        {objData.map((row: any, key: number) => (
                            <>
                                <tr key={row.id_usuario}>
                                    <td>#{key + 1}</td>
                                    <td>{row.canal}</td>
                                    <td>{converterDadosEntradaForName(row.informacao_entrada)}</td>
                                    <td>{moment(row.data_entrada).utc().format('DD/MM/YYYY HH:mm:ss')}</td>
                                    <td className="buttonForward">
                                        <ButtonLinear onClick={() => openModalEncaminhar(row)} Icon={IconArrowLeft}>
                                            Encaminhar
                                        </ButtonLinear>
                                    </td>
                                </tr>
                            </>
                        ))}
                    </tbody>
                </TableSimple>
            </div>
        );
    };

    return (
        <>
            {loading === true ? (
                <Loading active={loading} />
            ) : (
                <Container>
                    <ContainerFilter>
                        <label style={{ color: '#fff' }}>
                            <InputDefault
                                title="De"
                                placeholder="de"
                                onChangeText={(value) => setDadosFilter('dataInicio', value)}
                                value={filter.dataInicio}
                                type={'datetime-local'}
                            />
                        </label>
                        <label style={{ color: '#fff' }}>
                            <InputDefault
                                title="Até"
                                placeholder="ate"
                                onChangeText={(value) => setDadosFilter('dataFim', value)}
                                value={filter.dataFim}
                                type={'datetime-local'}
                            />
                        </label>
                        <label style={{ color: '#fff' }}>
                            <InputDefault
                                title="Nivel de serviço (Segundos)"
                                placeholder="60"
                                onChangeText={(value) => setDadosFilter('nivelDeServicoSegundos', value)}
                                value={filter.nivelDeServicoSegundos}
                                type={'number'}
                            />
                        </label>
                        <label style={{ color: '#fff' }}>
                            <SelectDefault
                                value={filter.id_grupo_atendimento}
                                placeholder="Grupos de Atendimento"
                                onChangeText={(value) => setDadosFilter('id_grupo_atendimento', value)}
                            >
                                {grupos.map((row, key) => (
                                    <option value={row.id_grupo_atendimento} key={key}>
                                        {row.grupo_atendimento}
                                    </option>
                                ))}
                            </SelectDefault>
                        </label>
                        <label style={{ color: '#fff' }}>
                            <SelectDefault
                                value={filter.canal}
                                placeholder="Grupos por canal"
                                onChangeText={(value) => setDadosFilter('canal', value)}
                            >
                                    <option value={'whatsapp'}>
                                        WhatsApp
                                    </option>
                                    <option value={'web'}>
                                        Web
                                    </option>
                            </SelectDefault>
                        </label>
                        <label style={{ color: '#fff' }}>
                            <ButtonLinear className="info" onClick={() => getDashBoardData()}>
                                Aplicar
                            </ButtonLinear>
                        </label>

                        <ButtonLinear onClick={() => getDashBoardData()} className="success">
                            Atualizar
                        </ButtonLinear>

                        <InputSwitchDefault
                            onChange={(value) => setOptionReload(value)}
                            flexDirection="column"
                            options={[
                                {
                                    value: '1',
                                    label: 'Atualizar automaticamente',
                                },
                            ]}
                            value={optionReload}
                        />

                        <InputSwitchDefault
                            onChange={(value) => handleOnBackOff(value)}
                            flexDirection="column"
                            options={[
                                {
                                    value: 1,
                                    label: 'backoffice',
                                },
                            ]}
                            value={backOff}
                        />

                        {optionReload.length > 0 && (
                            <SelectDefault onChangeText={(value) => setTimeReload(Number(value))} value={timeReload}>
                                <option value={30000}>30 segundos</option>
                                <option value={60000}>1 minuto</option>
                                <option value={300000}>5 minutos</option>
                            </SelectDefault>
                        )}
                    </ContainerFilter>

                    <Row mt="2">
                        <Column width="4">
                            <CardContainer>
                                <CardTotalIcon
                                    data={{
                                        color: '#04FDD0',
                                        icon: <IconCheckedRounded />,
                                        name: 'Nível de Serviço',
                                        value: cardData.nivelServico ?? 0,
                                        info: 'Total de Atendimentos que esperaram x segundos / total de chamadas transferidas para operador'
                                    }}
                                />
                            </CardContainer>
                        </Column>
                        <Column width="4">
                            <CardContainer>
                                <CardTotalIcon
                                    data={{
                                        color: '#87E0EC',
                                        icon: <IconClock />,
                                        name: 'Tempo Médio de Espera',
                                        value: cardData.tempMedioEspera ?? 0,
                                        info: 'Total tempo de espera / Qtde atendimento'
                                    }}
                                />
                            </CardContainer>
                        </Column>
                        <Column width="4">
                            <CardContainer>
                                <CardTotalIcon
                                    data={{
                                        color: '#87E0EC',
                                        icon: <IconClock />,
                                        name: 'Tempo médio de atendimento',
                                        value: cardData.tempMedioAtendimento ?? 0,
                                        info: 'Total tempo no atendimento / Qtde atendimentos'
                                    }}
                                />
                            </CardContainer>
                        </Column>
                        <Column width="4">
                            <CardContainer>
                                <CardTotalIcon
                                    data={{
                                        color: '#CB3D9A',
                                        icon: <IconCheckedRounded />,
                                        name: '% de chamadas Atendidas',
                                        value: cardData.percentAtendidas ?? 0,
                                        info: 'Total de chamadas atendidas / Total de chamadas recebidas'
                                    }}
                                />
                            </CardContainer>
                        </Column>
                    </Row>

                    <Row>

                        <Column width="4">
                            <CardContainer>
                                <CardTotalIcon
                                    data={{
                                        color: '#C1E98F',
                                        icon: <IconHeadphone />,
                                        name: 'Total em fila',
                                        value: cardData.totalEmFila ?? 0,
                                    }}
                                />
                            </CardContainer>
                        </Column>
                        <Column width="4">
                            <CardContainer>
                                <CardTotalIcon
                                    data={{
                                        color: '#FF868B',
                                        icon: <IconHeadphone />,
                                        name: 'Total em Atendimento',
                                        value: cardData.totalEmAtendimento ?? 0,
                                    }}
                                />
                            </CardContainer>
                        </Column>
                        <Column width="4">
                            <CardContainer>
                                <CardTotalIcon
                                    data={{
                                        color: '#FF868B',
                                        icon: <IconHeadphone />,
                                        name: 'Total Abandono',
                                        value: cardData.totalAbabdono ?? 0,
                                    }}
                                />
                            </CardContainer>
                        </Column>
                        <Column width="4">
                            <CardContainer>
                                <CardTotalIcon
                                    data={{
                                        color: '#FF868B',
                                        icon: <IconHeadphone />,
                                        name: 'Total Finalizados + Abandonados',
                                        value: cardData.totalFinalizados ?? 0,
                                    }}
                                />
                            </CardContainer>
                        </Column>
                    </Row>

                    <Row>
                        <Column width="4">
                            <CardContainer>
                                <CardTotalIcon
                                    data={{
                                        color: '#C1E98F',
                                        icon: <IconCheckedRounded />,
                                        name: 'Número de operadores ativos',
                                        value: cardData.operadoresAtivos ?? 0,
                                    }}
                                />
                            </CardContainer>
                        </Column>
                        <Column width="4">
                            <CardContainer>
                                <CardTotalIcon
                                    data={{
                                        color: '#FF868B',
                                        icon: <IconPause />,
                                        name: 'Número de operadores em pausa (Produtiva)',
                                        value: cardData.operadoresEmPausaProdutiva ?? 0,
                                    }}
                                />
                            </CardContainer>
                        </Column>
                        <Column width="4">
                            <CardContainer>
                                <CardTotalIcon
                                    data={{
                                        color: '#FF868B',
                                        icon: <IconPause />,
                                        name: 'Número de operadores em pausa (Improdutiva)',
                                        value: cardData.operadoresEmPausaImprodutiva ?? 0,
                                    }}
                                />
                            </CardContainer>
                        </Column>
                        <Column width="4">
                            <CardContainer>
                                <CardTotalIcon
                                    data={{
                                        color: '#FF868B',
                                        icon: <IconPause />,
                                        name: 'Número de pausas programadas',
                                        value: cardData.pausasProgramadas ?? 0,
                                    }}
                                />
                            </CardContainer>
                        </Column>
                    </Row>

                    <Container style={{ paddingBottom: '0px', marginTop: '16px' }}>
                        <h2 className="titleAttendenceGroup">Fila de atendimento</h2>

                        <SectionTables>
                            {data
                                .filter((obj) => obj.fila.length)
                                .map((row, key) => (
                                    <TableDefault
                                        key={key}
                                        dropDownComponent={<ContainerDadosEntrada canal={row.canal} objData={row.fila} />}
                                        columns={1}
                                    >
                                        <Td flexDirection="column">
                                            <TdTitle>{row.grupo_atendimento}</TdTitle>
                                        </Td>
                                    </TableDefault>
                                ))}
                        </SectionTables>
                        {loading === false && data.length === 0 && <FailedRegister />}
                    </Container>

                    

                    {/* 
                    <Row>
                        <Column width="12">
                            <CardContainer>
                                <p className="title-card">Histórico de atendimento</p>
                                <LineBarChart data={atendimentosChart} />
                            </CardContainer>
                        </Column>
                    </Row>
                    <Row>
                    <Column width="12">
                        <CardContainer>
                            <p className="title-card">
                                Total de Atendimentos <u>({atendimentos.length} registros encontrados)</u>
                            </p>

                            <TableSimple>
                                <thead>
                                    <tr>
                                        <th>Operador</th>
                                        <th>Chatbot</th>
                                        <th>Protocolo</th>
                                        <th>Grupo Atendimento</th>
                                        <th>Inicio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {atendimentos.map((row, key) => (
                                        <tr key={key}>
                                            <td>
                                                {row.nome ? row.nome : row.apelido}
                                            </td>
                                            <td>
                                                {row.chatbot}
                                            </td>
                                            <td>
                                                {row.protocolo}
                                            </td>
                                            <td>
                                                {row.grupo_atendimento}
                                            </td>
                                            <td>
                                                {moment(row.data_entrada).utc().format("HH[h]:mm - DD-MM-YYYY")}  
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </TableSimple>
                            
                        </CardContainer>
                    </Column>
                </Row> */}

                    <Row>
                        <Column width="12">
                            <CardContainer>
                                <p className="title-card">Operadores Disponíveis</p>

                                <TableSimple>
                                    <thead>
                                        <tr>
                                            <th>Operador</th>
                                            <th>Grupo de atendimento</th>
                                            <th>Horario entrada</th>
                                            <th>Horario saida</th>
                                            <th>Qtde Atendimento Ativos</th>
                                            <th>Qtde Max Atendimentos</th>
                                            <th>Tempo de jornada</th>
                                            <th>Deslogar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {operadoresDisponiveis.map((row, key) => (
                                            <tr key={key}>
                                                <td>{row.nome ? row.nome : row.apelido}</td>
                                                <td>{row.grupo_atendimento}</td>
                                                <td>{row.horarioEntrada}</td>
                                                <td>{row.horarioSaida}</td>
                                                <td>{row.quantidadeAtendimentosAtivos}</td>
                                                <td>{row.quantidadeAtendimentosConfigurado}</td>
                                                <td>{row.tempoDeJornadaRestante}</td>
                                                <td>
                                                    <ButtonLinear
                                                        type='button'
                                                        className="danger"
                                                        onClick={() => handleOnLogoutOperator(row.id_operador)}
                                                    >
                                                        Deslogar
                                                    </ButtonLinear>
                                                </td>
                                            </tr>
                                        ))}
                                        {operadoresDisponiveis.length === 0 &&
                                            <tr>
                                                <td colSpan={7}>
                                                    Nenhum registro encontrado.
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </TableSimple>
                                
                            </CardContainer>
                        </Column>
                    </Row>

                    <Row>
                        <Column width="12">
                            <CardContainer>
                                <p className="title-card">Operadores em Pausa</p>
                                <TableSimple>
                                    <thead>
                                        <tr>
                                            <th>Operador</th>
                                            <th>Grupos</th>
                                            <th>Tipo de pausa</th>
                                            <th>Tempo em pausa</th>
                                            <th>Descrição da pausa</th>
                                            <th>Data</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {operadoresPausa.map((row, key) => (
                                            <tr key={key}>
                                                <td>{row.nome_operador}</td>
                                                <td>
                                                    {row.grupos.join(',')}
                                                </td>
                                                <td>{row.tipo}</td>
                                                <td>{row.minutos_em_pausa}</td>
                                                <td>{row.descricao}</td>
                                                <td>
                                                    {moment(row.data_entrada)
                                                        .utc()
                                                        .format('HH[h]:mm DD/MM/YYYY')}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </TableSimple>
                            </CardContainer>
                        </Column>
                    </Row>
                </Container>
            )}

            <ModalDefault id="modalEncaminhar" openModal={modalEncaminhar} onClose={() => setModalEncaminhar(false)}>
                <Field>
                    <SelectDefault
                        value={DTOEncaminharManual?.id_operador}
                        onChangeText={(value) => onChangeDTOEncaminharManual('id_operador', value)}
                    >
                        <option value="">Selecione o operador para atender</option>
                        {optionsOperadores.map((row, key) => (
                            <option key={key} value={row.id_login}>
                                {row.nome}
                            </option>
                        ))}
                    </SelectDefault>
                </Field>

                <FieldGroup marginTop="20px">
                    <ButtonLinear className="transparent" onClick={() => setModalEncaminhar(false)}>
                        Cancelar
                    </ButtonLinear>
                    <ButtonLinear className="success" onClick={() => handleEncaminharManual()}>
                        Encaminhar
                    </ButtonLinear>
                </FieldGroup>
            </ModalDefault>
        </>
    );
}
