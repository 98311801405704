import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ButtonOperator from '../../components/ButtonOperator';
import ClientData from '../../components/ClientData';
import {IconDownload, IconFile, IconPower, IconShape, IconUser } from '../../components/icons';
import CardChat from '../../components/Ui/CardChat';
import CardChatMessage from '../../components/Ui/CardChatMessage';
import CardMessage from '../../components/Ui/CardMessage';
import { useRoutes } from '../../contexts/RoutesContext';
import { useUser } from '../../contexts/UserContext';
import Datetime from '../../Helper/Datetime';
import apiTropa from '../../services/apiTropa';
import { IAtendimento, IMensagem } from '../../types/IAtendimento';
import {Container,Header,Main, Panel,PanelLeft,BgLeft,PanelRight,SectionMessages,Profile,Perfil,SignInOut,PanelCenter,ButtonSignInOut, PanelCenterHeader, ContentFunil} from './styles';
import Loading from '../../components/Loading/Loading';
import { Operador } from '../../types/Operador';
import { useMessage } from '../../contexts/MessagesContext';
import { GrupoAtendimento } from '../../types/GrupoAtendimento';
import Scrollbars from 'react-custom-scrollbars';
import { IFunilEtapa } from '../../types/IFunil';
import moment from 'moment';
import CardMessageRender from '../../components/Chat/CardMessageRender';
import InputDefault from '../../components/form/InputDefault';
import ButtonLinear from '../../components/form/ButtonLinear';

export default function AttendenceOperatorFinalizados(props:any) {
    const history = useHistory()
    const { setLogout } = useRoutes()
    const { user, userEyou } = useUser()
    const token = props.match.params.token
    const {addSuccess, addDanger} = useMessage()

    const [loading, setLoading] = useState(false)
    const [funilEtapas, setFunilEtapas] = useState<IFunilEtapa[]>([])

    const [load, setLoad] = useState(true)
    const [operador, setOperador] = useState<Operador>({dadosUsuario:{}, diarioOperador: {}})
    const [atendimentos, setAtendimentos] = useState<IAtendimento[]>([])
    const [atendimentosList, setAtendimentosList] = useState<any>([])
    const [atendimento, setAtendimento] = useState<IAtendimento>({})
    const [mensagens, setMensagens] = useState<IMensagem[]>([])
    const [lastMessage, setLastMessage] = useState<any>(false)

    const [gruposDeAtendimento, setGruposDeAtendimento] = useState<GrupoAtendimento[]>([])


    useEffect(() => {
        setLoading(true)
        getOperador()
        getAtendimentos()
        getGruposDeAtendimento()
    }, [])

    useEffect(() => {
        const timer = setInterval(() => { getAtendimentos() }, 10000)
        return () => { clearInterval(timer) }
    }, [atendimentos, atendimento])
      
    useEffect(() => {
        mensagens.push(lastMessage)
        setMensagens([...mensagens])
    }, [lastMessage])

    useEffect(() => {
        setAtendimentosList([...atendimentos])
    }, [atendimentos])

    function getOperador(){
        const token = String(window.sessionStorage.getItem('token'))
        const decoded = new Buffer(token, 'base64').toString('ascii')
        const decodedJson = JSON.parse(decoded)
        const idOperador = decodedJson.user.idU
        apiTropa.post(`/operador/detalhe/${idOperador}`, {
            tokenEyou: window.sessionStorage.getItem('tokenInicial')
        }).then((response:any) => {
            const operador = response.data.data
            if( operador.horarios.find((row:any) => row.data === "domingo") === undefined ) operador.horarios.splice(0, 0, {data: "domingo"})
            if( operador.horarios.find((row:any) => row.data === "segunda-feira") === undefined ) operador.horarios.splice(1, 0, {data: "segunda-feira"})
            if( operador.horarios.find((row:any) => row.data === "terca-feira") === undefined ) operador.horarios.splice(2, 0, {data: "terca-feira"})
            if( operador.horarios.find((row:any) => row.data === "quarta-feira") === undefined ) operador.horarios.splice(3, 0, {data: "quarta-feira"})
            if( operador.horarios.find((row:any) => row.data === "quinta-feira") === undefined ) operador.horarios.splice(4, 0, {data: "quinta-feira"})
            if( operador.horarios.find((row:any) => row.data === "sexta-feira") === undefined ) operador.horarios.splice(5, 0, {data: "sexta-feira"})
            if( operador.horarios.find((row:any) => row.data === "sabado") === undefined ) operador.horarios.splice(6, 0, {data: "sabado"})
            setOperador(operador)
        })
    }

    function getAtendimentos(){
        const token = String(window.sessionStorage.getItem('token'))
        const decoded = new Buffer(token, 'base64').toString('ascii')
        const decodedJson = JSON.parse(decoded)
        const idOperador = decodedJson.user.idU
        apiTropa.get(`/atendimento/get-by-operador/${idOperador}?status=offline&historico=true`).then((response:any) => {
            const atendimentos = response.data.data
            if ( atendimentos.length === 0 ) {
                history.push('/fila-de-atendimento')
                addDanger('Atenção', 'Você não tem nenhum atendimento finalizado.')
            }
            if( !atendimento.id_atendimento ) iniciarAtendimento(atendimentos[0])
            setAtendimentos([...atendimentos])
            setLoading(false)
        })
    }

    function getGruposDeAtendimento(){
        apiTropa.get(`/grupo-atendimento`).then((response:any) => {
            let result =  response.data.data
            setGruposDeAtendimento(result)
        })
    }

    function getMensagensByAtendimentoId(id_atendimento:any){
        setLoad(true)
        apiTropa.get(`/mensagem/listar/${id_atendimento}`).then((response:any) => {
            if ( response.data && response.data.data && response.data.data.length > 0 ) {
                const mensagens = response.data.data.reverse()
                setMensagens(mensagens)
            }
            setLoad(false)
        })
    }

    async function iniciarAtendimento(atendimento:IAtendimento){
        if( atendimento !== undefined && atendimento.id_atendimento ){
            setAtendimento(atendimento)
            getMensagensByAtendimentoId(atendimento.id_atendimento)

            setFunilEtapas([])

            if( atendimento.funil ){
                setFunilEtapas(JSON.parse(atendimento.funil))
            } else {
                await getFunilEtapas(atendimento.id_grupo_atendimento)
            }
        }
    }

    async function getFunilEtapas(id_grupo_atendimento:any){

        const response:any = await apiTropa.get(`/grupo-funil-grupo/${id_grupo_atendimento}`)
        const result = response.data.data
        if( result && result.etapas ){
            setFunilEtapas(result.etapas)
        }
    }

    async function handleSearch(value:any)
    {

        const result = atendimentos.filter(({informacao_entrada, protocolo}:any) => 
            informacao_entrada && informacao_entrada["Nome completo"] ?
                informacao_entrada['Nome completo'].toLowerCase().includes(value.toLowerCase())
            : protocolo.toLowerCase().includes(value.toLowerCase())
        )

        setAtendimentosList([...result])

    }

    function handleAtendimentoUpdate(atendimentoSelected:any){
        const newAtendimentos:any = []
        atendimentos.map((row:any, key:any) => {
            if ( row.id_atendimento === atendimentoSelected.id_atendimento ){
                newAtendimentos.push(atendimentoSelected)
            } else {
                newAtendimentos.push(row)
            }
        })
        setAtendimentos([...newAtendimentos])
    }

    return (
        <Container>
            <Loading active={loading} />

            <Header>
                <ButtonOperator
                    title="Atendimentos"
                    number=""
                    selected={false}
                    onClick={() => history.push("/fila-de-atendimento")}
                />

                <ButtonOperator
                    title="Histórico"
                    number=""
                    selected={true}
                    onClick={() => {}}
                />

                <Profile>
                    {user.foto === null ? (
                        <div className="user">
                            <IconUser />
                        </div>
                    ) : (
                        <div
                            className="image"
                            style={{ backgroundImage: `url(${user.foto})` }}
                        />
                    )}

                    <Perfil onClick={() => history.push('/meus-dados')}>
                        <h2>{userEyou.name}</h2>
                        <span className={`${operador.diarioOperador.status}`}>{operador.diarioOperador.status}</span>
                    </Perfil>
                </Profile>

                <ButtonSignInOut>
                    <SignInOut onClick={() => setLogout()}>
                        <IconPower color="#FF868B" size="28" />
                    </SignInOut>
                </ButtonSignInOut>
            </Header>

            <Main>

                <Panel>
                    <PanelLeft>
                        <BgLeft style={{height: '100%'}}>

                            <InputDefault
                                value={''}
                                onChangeText={(value) => handleSearch(value)}
                                search={true}
                                placeholder="Buscar cliente"
                            /> 

                            <Scrollbars style={{height: '100%'}}>
                                {(atendimentosList.length > 0) &&
                                <SectionMessages>
                                    {atendimentosList.map((row:any, key:any) => (
                                    <CardMessage
                                        onClick={() => iniciarAtendimento(row)}
                                        key={key}
                                        selected={atendimento.id_atendimento === row.id_atendimento}
                                        name={(row.informacao_entrada && row.informacao_entrada["Nome completo"]) ? row.informacao_entrada["Nome completo"] : row.protocolo}
                                        message={row.ultima_mensagem?.mensagem}
                                        data={moment(row.data_ultima_mensagem).utc().format('DD/MM/YYYY HH:mm')}
                                        panel={"Finalizados"}
                                    />
                                    ))}
                                </SectionMessages>
                                }
                            </Scrollbars>
                        </BgLeft>

                    </PanelLeft>

                    { atendimento.protocolo && 
                        <>
                        <PanelCenter style={{height: '100%'}}>
                            
                            <PanelCenterHeader>
                                
                                <div className="title">
                                    <h1>{atendimento.informacao_entrada ? atendimento.informacao_entrada["Nome completo"] : atendimento.protocolo}</h1>
                                    {atendimento.id_whatsapp && (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                aria-hidden="true"
                                                role="img"
                                                width="1em"
                                                height="1em"
                                                preserveAspectRatio="xMidYMid meet"
                                                viewBox="0 0 24 24"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    fillRule="evenodd"
                                                    d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977c0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162l-2.824.741l.753-2.753l-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188a7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73c-.205-.075-.354-.112-.504.112s-.58.729-.711.879s-.262.168-.486.056s-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393c.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666c-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008a.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387c.536.231.954.369 1.279.473c.537.171 1.026.146 1.413.089c.431-.064 1.327-.542 1.514-1.066c.187-.524.187-.973.131-1.067c-.056-.094-.207-.151-.43-.263"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        ) 
                                    }
                                </div>
                                
                                <div className="description">
                                    <h2>Protocolo:</h2> 
                                    <span>{atendimento.protocolo}</span>

                                    <h2>Grupo de Atendimento:</h2> 
                                    <span>{atendimento.grupo_atendimento}</span>
                                </div>

                            </PanelCenterHeader>

                            {/* <ContentFunil style={{marginTop: 100}}>
                                {funilEtapas.map((row, key) => (
                                    <li 
                                        style={{zIndex: key * -1}} 
                                        className={`${row.active ? "active": null}`}
                                    >
                                        {row.etapa}
                                    </li>
                                ))}

                                { funilEtapas.length === 0 && 
                                    <li className="active">
                                        Em atendimento
                                    </li>
                                }
                            </ContentFunil> */}

                            <CardChat height="100%" messages={mensagens} load={load} disabled={true}>
                                {mensagens.map((mensagem, key) => (
                                    <>
                                    {mensagem.tipo === 'alerta' ? (
                                        <div className="transferMessage">
                                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="2"><circle cx="12" cy="12" r="10"/><path stroke-linecap="round" d="M12 7h.01"/><path stroke-linecap="round" strokeLinejoin="round" d="M10 11h2v5m-2 0h4"/></g></svg>
                                            <div>
                                                <p>{mensagem.mensagem}</p>
                                                <span>
                                                    {moment(mensagem.criado).utc().format('DD/MM/YYYY HH:mm')}
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        mensagem.mensagem !== 'hiddenInitialMessage' &&
                                        <CardMessageRender
                                            data={mensagem}
                                            renderName={true}
                                            name={mensagem.id_fluxograma ? mensagem.chatbot : mensagem.apelido}
                                            messageType={mensagem.id_operador ? 'recebida' : 'enviada'}
                                        />
                                    )}
                                    </>
                                ))}

                                
    
                                <div className="finishInfo">

                                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path fill="currentColor" d="M12 2C8.145 2 5 5.145 5 9c0 2.41 1.23 4.55 3.094 5.813C4.527 16.343 2 19.883 2 24h2c0-4.43 3.57-8 8-8c1.375 0 2.656.36 3.781.969A8.007 8.007 0 0 0 14 22c0 4.406 3.594 8 8 8c4.406 0 8-3.594 8-8c0-4.406-3.594-8-8-8a7.964 7.964 0 0 0-4.688 1.531a10.134 10.134 0 0 0-1.406-.719A7.024 7.024 0 0 0 19 9c0-3.855-3.145-7-7-7zm0 2c2.773 0 5 2.227 5 5s-2.227 5-5 5s-5-2.227-5-5s2.227-5 5-5zm10 12c3.324 0 6 2.676 6 6s-2.676 6-6 6s-6-2.676-6-6s2.676-6 6-6zm-2.281 2.281L18.28 19.72L20.563 22l-2.282 2.281l1.438 1.438L22 23.437l2.281 2.282l1.438-1.438L23.437 22l2.282-2.281l-1.438-1.438L22 20.563z"/></svg>
                                    <ul>
                                        <li>
                                            Finalizado por <b>{atendimento.finalizado_por}</b>
                                        </li>
                                        { atendimento.descricao && 
                                            <li>
                                                Motivo: <b>{atendimento.descricao}</b>
                                            </li>
                                        }
                                        <li>
                                            Preencheu a pesquisa de satisfação: <b>{atendimento.id_pesquisa_satisfacao ? 'Sim' : 'Não'}</b>
                                        </li>
                                        <li>
                                            Data: <b>{moment(atendimento.data_saida).utc().format('DD/MM/YYYY HH:mm')}</b>
                                        </li>
                                    </ul>
                                    
                                </div>
                            </CardChat>
                        </PanelCenter>
                    

                    <PanelRight>
                        <ClientData
                            atendimento={atendimento}
                            setAtendimento={handleAtendimentoUpdate}
                        />
                    </PanelRight>
                    </>

                    }
                </Panel>
            </Main>

        </Container>
    );
}
