import { 
    Container,
    PersonImage,
    PersonInfo,
} from './styles'

interface UserProps {
    name?: string;
    image?: string;
}

export default function UserTable({ name, image }: UserProps) {
    return (
        <Container>
            <PersonImage>
                <img src={image ? image : '/user.png'} alt="profile" />
            </PersonImage>
            <PersonInfo>
                <h2>Nome</h2>
                <span>{name}</span>
            </PersonInfo>
        </Container>
    )
}
