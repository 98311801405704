import React from 'react';

interface ColumnProps {
    width:
        | '0'
        | '1'
        | '2'
        | '3'
        | '4'
        | '5'
        | '6'
        | '7'
        | '8'
        | '9'
        | '10'
        | '11'
        | '12';
    mt?: '0' | '1' | '2' | '3' | '4' | '5';
    mb?: '0' | '1' | '2' | '3' | '4' | '5';
    ml?: '0' | '1' | '2' | '3' | '4' | '5';
    mr?: '0' | '1' | '2' | '3' | '4' | '5';
}

const Column: React.FC<ColumnProps> = ({
    children,
    width,
    mt = 0,
    mb = 0,
    ml = 0,
    mr = 0,
}) => {
    return (
        <div
            className={`column c-${width} mt-${mt} mb-${mb} mt-${mt} ml-${ml} mr-${mr}`}
        >
            {children}
        </div>
    );
};

export default Column;
