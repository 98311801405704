import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    .subtitlemodal, p {
        font-size: 17px;
        color: ${({theme}) => theme.colors.white};
        margin-bottom: 20px;
        margin-top: 0px;
        padding-top: 0px;
    }
    p {
        font-size: 12px;
        margin-top: -15px;
    }
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .buttons {
        display: flex;
        gap: 10px;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 24px 0;
`;

export const SectionHours = styled.div`
    display: grid;
    grid-template-columns: 1fr ;
    gap: 16px;
    @media (max-width: 425px) {
        overflow: auto;
    }
`;
interface IPropsEditSelected {
    selected: 'conteudo' | 'condicoes' | 'integracoes'
}

interface ChatProps {
    backgroundColor?: string;
    selectedFile?: boolean;
}


export const FooterChat = styled.div<ChatProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    width: 100%;

    border-top: 1px solid #eaeef3;

    .buttonChat {
        display: grid;
        place-items: center;
        flex-shrink: 0;

        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: none;
        background: ${(props) =>
            props.backgroundColor ? props.backgroundColor : '#34D5FA'};
        transition: filter 0.3s;

        &:hover {
            transition: 0.3s;
            filter: brightness(0.9);
        }
    }

    .containerChatInput {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        position: relative;
        width: 100%;
        height: 48px;
        padding: 4px;

        background: #eaeef3;
        border-radius: 10px;

        .buttonEmojiChat {
            display: grid;
            place-items: center;
            flex-shrink: 0;

            width: 48px;
            height: 48px;
            border-radius: 50%;
            border: none;
            background: transparent;
            position: relative;

            svg {
                path {
                    fill: ${(props) =>
                        props.backgroundColor
                            ? props.backgroundColor
                            : '#34D5FA'};
                }
            }
        }

        #inputChat {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 8px 0px;
            border: none;
            outline: none;
            background: transparent;
            resize: none;
            overflow-y: scroll;

overflow-y: scroll;
::-webkit-scrollbar {
    display: none;
}
        }
    }

    .buttonAudioChat {
        display: grid;
        place-items: center;
        flex-shrink: 0;

        width: 48px;
        height: 48px;
        border-radius: 10px;
        border: none;
        background: ${(props) =>
            props.backgroundColor ? props.backgroundColor : '#34D5FA'};
        transition: filter 0.3s;

        svg {
            path {
                fill: #fff;
            }
        }
        &:hover {
            transition: 0.3s;
            filter: brightness(0.9);
        }
    }
`;

export const SectionFiles = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ContainerEditFlowMenuSelected = styled.div<IPropsEditSelected>`
    display: flex;
    box-sizing: border-box;
    height: calc(100% - 100px);
    #conditions-edit {
        display: ${props => props.selected === 'condicoes' ? 'flex' : 'none'};
    }
    #messages-edit {
        display: ${props => props.selected === 'conteudo' ? 'flex' : 'none'};
    }
    #integrations-edit {
        display: ${props => props.selected === 'integracoes' ? 'flex' : 'none'};
    }
`;

export const Title = styled.div`
    line-height: 40px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: bold;
    color: ${props => props.theme.colors.white};
`;

export const CardTitle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    width: 100%;
    padding: 40px 32px;

    background: ${props => props.theme.colors.spaceCadedDark};
    border-radius: 16px;

    h2 {
        font-size: 24px;
        font-weight: bold;
        color: ${props => props.theme.colors.white};
    }

    p {
        font-size: 16px;
        color: ${props => props.theme.colors.white};
    }

`;

export const CardEntryForm = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    padding: 40px 32px;

    background: ${props => props.theme.colors.spaceCadedDark};
    border-radius: 16px;
`

export const CardForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    width: 100%;
    padding: 40px 32px;

    background: ${props => props.theme.colors.spaceCadedDark};
    border-radius: 16px;
    h2 {
        font-size: 16px;
        font-weight: bold;
        color: ${props => props.theme.colors.white};
        margin-bottom: 32px;
    }
`;

export const SectionInputs = styled.div`
    /* display: grid;
    grid-template-columns: repeat( auto-fit, minmax(140px, 1fr)); */
    display: flex;
    flex-direction: column;
    gap: 16px;
    
`

export const SectionEditForm = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 16px;
    
    width: 100%;
`

export const FieldSelected = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 16px;
    width: 100%;
    button {
        padding: 0 12px;
        margin-left: 16px;
        width: 100%;
        max-width: 215px;
    }
    button:nth-child(3n-2) {
        margin-left: 0;
    }
    @media (max-width: 1400px) {
        button {
            width: 27%;
            padding: 5px 15px;
        }
    }
`;

export const GroupButtonsNew = styled.div`
    display: flex;
    .form-children-default {
        margin-left: 10px
    }
`

export const GroupCardEditHoursNew = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0px 20px;
`
export const CardEditHoursNew = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    background: ${(props) => props.theme.colors.spaceCadedDark};
    border-radius: 16px;
    padding: 20px 24px;
    width: 100%;
    height: 80px;
    margin-top: 24px;
    animation: fadeIn .2s;
    span {
        color: #fff;
    }
`;

export const CardEditHours = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    background: ${(props) => props.theme.colors.spaceCadedDark};
    border-radius: 16px;
    padding: 20px 24px;
    width: 100%;
    height: 80px;
    margin-top: 24px;
    span {
        color: #fff;
    }
`;

export const InfoHoursNew = styled.div`
    display: flex;
    align-items: center;
    span {
        font-size: 15px;
        color: ${({theme}) => theme.colors.white};
    }
    svg {
        width: 15px;
        margin-right: 10px;
        * {
            fill: ${({theme}) => theme.colors.white};
        }
    }
    .as {
        margin: 0px 5px;
    }
`

export const InfoGroupNew = styled.div`
    padding-top: 8px;
`

export const InfoHours = styled.div`
    display: flex;
    flex-direction: column;
    width: 150px;

    h3 {
        font-size: 12px;
        color: ${(props) => props.theme.colors.lavanderGray};
    }

    span {
        font-size: 14px;
        font-weight: bold;
        color: ${(props) => props.theme.colors.white};
        margin-top: 2px;
    }
`;