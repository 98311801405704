import styled from 'styled-components';

export const Container = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;

    background: transparent;
    outline: none;
    border: none;

    padding: 16px;
    width: 204px;
    height: 60px;
    border-radius: 16px;

    position: relative;

    &.active {
        &::after {
            position: absolute;
            content: '';
            bottom: -4px;
            width: 100%;
            height: 4px;
            border-radius: 4px;
            background: ${(props) =>
                props.title === 'Atendimentos'
                    ? '#38FFE7'
                    : '#fff' && props.title === 'Atendente em pausa'
                    ? '#CB3D9A'
                    : '#fff' && props.title === 'Aguardando'
                    ? '#F5A623'
                    : '#fff' && props.title === 'Finalizados'
                    ? '#4A7DFF'
                    : '#fff' && props.title === 'Operadores Online'
                    ? '#7B61FF'
                    : '#fff' && props.title === 'Abandonos'
                    ? '#FF868B'
                    : '#fff' && props.title === 'Dashboard'
                    ? '#4A7DFF'
                    : '#fff' };
        }
    }

    h3 {
        display: grid;
        place-items: center;
        width: 24px;
        height: 24px;
        color: #fff;
        border-radius: 50%;
        font-size: 16px;
        font-weight: bold;

        background: ${(props) =>
            props.title === 'Atendimentos'
                ? '#38FFE7'
                : '#fff' && props.title === 'Atendente em pausa'
                ? '#CB3D9A'
                : '#fff' && props.title === 'Aguardando'
                ? '#F5A623'
                : '#fff' && props.title === 'Finalizados'
                ? '#4A7DFF'
                : '#fff' && props.title === 'Operadores Online'
                ? '#7B61FF'
                : '#fff' && props.title === 'Abandonos'
                ? '#FF868B'
                : '#fff'};
    }
    span {
        font-size: 14px;
        color: ${({theme}) => theme.colors.white};
        margin-left: 8px;
    }
`;
