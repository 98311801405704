import React from 'react';

import { Container } from './styles';

interface RowProps {
    mt?: '0' | '1' | '2' | '3' | '4' | '5';
    mb?: '0' | '1' | '2' | '3' | '4' | '5';
    ml?: '0' | '1' | '2' | '3' | '4' | '5';
    mr?: '0' | '1' | '2' | '3' | '4' | '5';
}

const Row: React.FC<RowProps> = ({ children, mt, mb, ml, mr }) => {
    return (
        <Container className={`mt-${mt} mb-${mb} mt-${mt} ml-${ml} mr-${mr}`}>
            {children}
        </Container>
    );
};

export default Row;
