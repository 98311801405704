import React from 'react'
import ButtonLinear from '../../form/ButtonLinear';
import { IconThumbUp } from '../../icons';
import FieldGroup from '../../layout/containers/FieldGroup';

import { 
    OverlayModal,
    Container,
    LoadingTitle,
    LoadingSubtitle,
    ContentIcone,
} from './styles';


function CardLoadingChat() {
    return (
        <OverlayModal>
            <Container>
                <LoadingTitle>Aguarde um momento</LoadingTitle>
                <ContentIcone>
                    <IconThumbUp />
                </ContentIcone>
                <LoadingSubtitle>Voce é próximo na fila!</LoadingSubtitle>
            </Container>
        </OverlayModal>
    )
}

function ServiceCompletedChat() {
    return (
        <OverlayModal>
            <Container>
                <LoadingTitle>Seu atendimento foi finalizado com sucesso</LoadingTitle>
                <LoadingSubtitle>A seguir você preencherá nossa Pesquisa de Satisfação, deseja prosseguir?</LoadingSubtitle>

                <FieldGroup>
                    <ButtonLinear 
                        className='transparent'
                    >
                        Não
                    </ButtonLinear>

                    <ButtonLinear 
                        className='info'
                    >
                        sim
                    </ButtonLinear>
                </FieldGroup>
            </Container>
        </OverlayModal>
    )
}

function AppreciateContact() {
    return (
        <OverlayModal>
            <Container>
                <ContentIcone>
                    <IconThumbUp />
                </ContentIcone>

                <LoadingTitle>Seu atendimento foi finalizado com sucesso</LoadingTitle>

                <ButtonLinear 
                    className='info'
                >
                    Iniciar novo atendimento
                </ButtonLinear>
                   
            </Container>
        </OverlayModal>
    )
}

export { CardLoadingChat, ServiceCompletedChat, AppreciateContact }
