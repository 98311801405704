import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    .skeletor {
        margin-bottom: 8px;
    }
`

export const SectionTables = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 14px;
    /* margin-bottom: 40px; */
    min-height: 100%;
    margin-top: 20px;
    @media (max-width: 425px) {
        .card-table {
            div {
                button {
                    margin-right: -50px;
                    margin-top: -20px;
                }            
            }  
        }
    }    
`

export const SectionGroup = styled.div`
    display: flex;
    flex-direction: column;
    
    border-radius: 16px;
    background: ${props => props.theme.colors.spaceCadedDark};

    padding: 20px 0;

    .gropupName {
        display: block;
        margin: 0 20px;
        border-bottom: 3px solid rgba(255,255,255,0.5);

        .titleGroup {
            font-size: 1.75rem;
            color: ${props => props.theme.colors.white};
            margin-bottom: 22px;
        }
    } 

    .sectionTable {
        width: 100%;
        table {
            thead {
                .thButton {
                    text-align: center;
                }
            }   
            tbody {
                .buttonForward {
                    width: 0%;
                }
            }
        }
    }
`