import styled from "styled-components"

interface InputProps {
    flexDirection: 'column' | 'row' | 'row-reverse',
    maxColumn: any,
}

export const Container = styled.div<InputProps>`
    flex-direction: ${props => props.flexDirection};
    align-items: ${props => props.flexDirection === 'column' ? 'flex-start' : 'center'};
    position: relative;
    ${props => props.maxColumn ? 'column-count: 2;' : 'display: flex;' }
    &.reverse{
        .input-switch {
            flex-direction: row-reverse;
            .switch{
                margin: 0 0 0 12px;
            }
        }    
    }
    .input-switch {
        display: flex;
        align-items: center;
        padding-right: 8px;
        cursor: pointer;
        &.disabled {
            .switch {
                opacity: .7;
                .bullet {
                    opacity: .7;
                }
            }
        }
        .switch {
            width: 48px;
            height: 24px;
            box-sizing: border-box;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 0 12px 0 0px;
            background: ${props => props.theme.colors.cadetGrey};
            .bullet {
                border-radius: 100px;
                background: ${props => props.theme.colors.white};
                transition: all .2s;
                width: 20px;
                height: 20px;
                margin-left: 2px;
            }
            &.active {
                background: ${props => props.theme.colors.skyblue};
                .bullet {
                    margin-left: 26px;
                }
            }
        }
        .label {
            padding: 0px 0px;
            font-size: 14px;
            color: ${({theme}) => theme.colors.subTitle};
            height: 40px;
            display: flex;
            align-items: center;
        }
    }
`