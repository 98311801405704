import React, { useEffect, useState } from 'react';
import InputDefault from '../../form/InputDefault';
import CardChat from '../../../components/Chat/CardChat';

import {
    CardPerson,
    ChatContainer,
    Container,
    ContentUsers,
    ListContainer,
    PersonImage,
    PersonInfo,
} from './styles';
import CardMessageRender from '../../Chat/CardMessageRender';
import { Header } from '../../Ui/PopUpAttendence/styles';
import { IconArrowLeft, IconClose } from '../../icons';
import Skeleton from 'react-loading-skeleton';
import apiTropa from '../../../services/apiTropa';
import { useUser } from '../../../contexts/UserContext';
import moment from 'moment';
import { ContainerViewsMessages } from '../ChatOperators/styles';

interface IProps {
    showPopUp?: boolean;
    setShowPopUp?(boolean: boolean): void;
    handleSetTotal?(total:number): void
    token?:any
    tokenInicial?:any
}
export function ChatGroups({
    setShowPopUp = () => {},
    showPopUp = false,
    handleSetTotal = () => {},
    token,
    tokenInicial
}: IProps) {
    const [search, setSearch] = useState<string>('');
    const [tab, setTab] = useState<string>('list');
    const [dataLoad, setDataLoad] = useState<boolean>(true);
    const [data, setData] = useState<any>([]);
    const [list, setList] = useState<any>([]);
    const [selected, setSelected] = useState<any>({});
    const [messagesLoad, setMessagesLoad] = useState(true);
    const [messages, setMessages] = useState<any>([]);
    const { user, userEyou } = useUser();

    useEffect(() => {
        if ( showPopUp === false ) setTab('list')
    }, [showPopUp])

    useEffect(() => {
        if ( tab === 'list' && showPopUp === true ) setDataLoad(true)
        if ( tab === 'list') getData()
    }, [showPopUp, tab, user])

    useEffect(() => {
        setDataLoad(true)
        // const intervalUsers = setInterval(() => {
            getData()
        // }, 20000)
        // return () => clearInterval(intervalUsers)
    }, [])

    useEffect(() => {
        // const intervalMessages = setInterval(() => {
            getMessages();
        // }, 20000);
        if (!selected.id_chat_grupo_atendimento) {
            // clearInterval(intervalMessages);
        } else {
            getMessages();
        }
        // return () => clearInterval(intervalMessages);
    }, [selected]);

    useEffect(() => {
        setList([...data]);
    }, [data]);

    useEffect(() => {
        const result = data.filter(
            ({ Nome_Grupo_Atendimento }: any) =>
                Nome_Grupo_Atendimento &&
                Nome_Grupo_Atendimento.toLowerCase().includes(
                    search.toLowerCase()
                )
        );
        setList([...result]);
    }, [search]);

    async function getData() {
        const response: any = await apiTropa.get(
            'chat-grupoatendimento-listar', {
                headers: {
                    'Authorization': 'Bearer '+token
                }}
        );
        const responseData:any = response.data.data.filter(
            (obj: any) => obj?.Nome_Grupo_Atendimento
        );
        let totalItem:number = 0
        responseData.map((row:any) => {
            totalItem = totalItem + parseFloat(row.qtdNaoLidas)
        })
        handleSetTotal(totalItem)
        setData([...responseData])
        setDataLoad(false)

        setData([...responseData]);
        setDataLoad(false);
    }

    async function getMessages() {
        try {
            if (!selected.id_chat_grupo_atendimento)
                throw 'Nenhum grupo selecionado.';
            const response: any = await apiTropa.get(
                `chat-grupoatendimento-mensagens/${selected.id_chat_grupo_atendimento}?perPage=100&currentPage=1`, {
                    headers: {
                        'Authorization': 'Bearer '+token
                    }}
            );
            setMessages([...response.data.data.data]);
            setMessagesLoad(false);
        } catch (err: any) {
            console.log('getMEssages', err);
        }
    }

    async function handleSubmitMessage(params: any) {
        try {
            params.id_chat_grupo_atendimento =
                selected.id_chat_grupo_atendimento.toString();
            params.criado = moment().format('YYYY-MM-DDTHH:mm:00.000')+'Z'
            params.apelido = user.perfil?.apelido;
            messages.push(params);
            setMessages([...messages]);
            const response: any = await apiTropa.post(
                'chat-grupoatendimento-mensagem',
                params, {
                    headers: {
                        'Authorization': 'Bearer '+token
                    }}
            );
            getMessages();
        } catch (err: any) {}
    }

    function handleOnSelected(data: any) {
        setSelected({ ...data });
        setTab('chat');
        setMessagesLoad(true);
        setMessages([]);
    }

    function handleOnBack() {
        setTimeout(() => {
            setSelected({});
        }, 500);
        setTab('list');
    }

    return (
        <Container tab={tab}>
            <ChatContainer>
                <Header>
                    <div>
                        <a onClick={handleOnBack}>
                            <IconArrowLeft />
                        </a>
                        <h1>{selected.Nome_Grupo_Atendimento}</h1>
                    </div>
                    <button
                        onClick={() => setShowPopUp(!showPopUp)}
                        className="closePopUp"
                    >
                        <IconClose color="#34D5FA" />
                    </button>
                </Header>
                <CardChat
                    messages={messages}
                    onSubmit={handleSubmitMessage}
                    height="385px"
                    heightPreview="470px"
                    noHeader={true}
                >
                    {messages.map(
                        (row: any, key: number) =>
                            row.mensagem && (
                                <CardMessageRender
                                    key={key}
                                    name={row.apelido}
                                    messageType={
                                        row.apelido !== user.perfil?.apelido
                                            ? 'enviada'
                                            : 'recebida'
                                    }
                                    data={row}
                                />
                            )
                    )}
                </CardChat>
            </ChatContainer>

            <ListContainer>
                <Header>
                    <h1>Grupos de atendimentos</h1>
                    <button
                        onClick={() => setShowPopUp(!showPopUp)}
                        className="closePopUp"
                    >
                        <IconClose color="#34D5FA" />
                    </button>
                </Header>

                <ContentUsers>
                    <InputDefault
                        onChangeText={(value) => setSearch(value)}
                        value={''}
                        search={true}
                        placeholder={'Buscar'}
                        style={{ minHeight: 40 }}
                    />

                    <h2>Todos grupos</h2>

                    {list.length === 0 && dataLoad === false && (
                        <p className="empty">Nenhum grupo encontrado.</p>
                    )}

                    {dataLoad === true && (
                        <Skeleton
                            count={5}
                            height={40}
                            style={{ marginBottom: 16 }}
                        />
                    )}

                    {dataLoad === false &&
                        list.map((row: any, key: any) => (
                            <CardPerson
                                key={key}
                                onClick={() => handleOnSelected(row)}
                            >
                                <PersonImage
                                    style={{
                                        backgroundImage: `url('https://ui-avatars.com/api/?name=${row.Nome_Grupo_Atendimento}}'`,
                                    }}
                                />
                                <PersonInfo status={row.status}>
                                    <p>
                                        <span>
                                            {row.Nome_Grupo_Atendimento}
                                        </span>
                                    </p>
                                </PersonInfo>
                                { row.qtdNaoLidas > 0 && 
                                    <ContainerViewsMessages>{row.qtdNaoLidas}</ContainerViewsMessages>
                                }
                            </CardPerson>
                        ))}
                </ContentUsers>
            </ListContainer>
        </Container>
    );
}

export default ChatGroups;
