import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Card = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 24px;
    border-radius: 16px;
    margin-bottom: 10px;

    background: ${props => props.theme.colors.spaceCadedDark};    
`;

export const CardInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media (max-width: 900px) {
        overflow: scroll;
        gap: 10px;
    }
`;


export const InfoLeft = styled.div`
    width: 20%;
    @media (max-width: 900px) {
        width: 100%;        
    }
`;

export const NameGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h2 {
        font-size: 14px;
        font-weight: 500;
        color: ${props => props.theme.colors.cadetGrey};
        margin: 0;
    }

    span {
        font-size: 15px;
        color: ${props => props.theme.colors.white};

        margin-top: 8px;
    }

    .isActive span {
        
    }
    @media (max-width: 900px) {
        white-space: nowrap;
    }
`;

export const InfoRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

`;

export const Status = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-size: 10px;
        color: ${props => props.theme.colors.lavanderGray};
    }

`;

export const ButtonVizualisation = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.lightPurple};

    margin-left: 55px;
`

export const ButtonDown = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    

    background: transparent;
    border: none;
    outline: transparent;

    width: 24px;
    height: 24px;

    margin-left: 28px;
    cursor: pointer;
`;

