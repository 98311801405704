import { useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useChatbotContext } from '../../contexts/ChatbotContext';
import { useMessage } from '../../contexts/MessagesContext';
import apiEyou from '../../services/apiEyou';
import InputSwitchDefault from '../form/InputSwitchDefault';
import TextAreaInputDefault from '../form/TextAreaInputDefault';
import { IconAudio, IconClose, IconFile, IconImage, IconMessageRounded, IconPlusCircle, IconVideo } from '../icons';
import InputTextMessage from './InputTextMessage';
import InputUploadMessage from './InputUploadMessage';
import RenderMessage from './RenderMessage';
import {
    Container,
    ContainerEditableMessage,
    ContainerEditMessage,
    ContainerEditMessages,
    ContainerListMessages,
    ContainerMenuNewMessages,
    ContainerMenuNewMessagesButton,
    ContainerMenuNewMessagesMenu,
    ContainerMenuNewMessagesMenuItem,
    ContainerMessages,
    ContainerPadding,
    ContainerWhiteShadow,
} from './styles';

interface IPropsMessagesEdit {}

export default function MessagesEdit({}: IPropsMessagesEdit) {
    const refScroll = useRef<any>();
    const { handleEditElement, setSelected, selected } = useChatbotContext();
    const { addDanger } = useMessage();
    const [submenuNewMessage, setSubMenuNewMessage] = useState<boolean>(false);
    const [menuEmojis, setMenuEmojis] = useState<boolean>(false);
    const [messageReference, setMessageReference] = useState<any>({});
    const [filename, setFilename] = useState('');
    const [message, setMessage] = useState<string>('');
    const [sending, setSending] = useState<boolean>(false);
    const [loadUpload, setLoadUpload] = useState<boolean>(false);
    const refInputEdit = useRef<any>();

    document.addEventListener(
        'click',
        function (event: any) {
            if (!event.target.closest('#submenumessages')) setSubMenuNewMessage(false);
        },
        false
    );

    useEffect(() => {
        handleMessageEdit();
    }, [sending]);

    useEffect(() => {
        setMessageReference({});
    }, [selected]);

    function onScrollDown() {
        setTimeout(() => {
            if (refScroll && refScroll.current) refScroll.current.scrollToBottom();

            // refScroll.current.scrollToBottom();
        }, 50);
    }

    function handleMessageUserVariable(value: any) {
        const newSelected: any = selected;
        newSelected.params.messageUser.variable = value;
        if (value !== null) {
            newSelected.params.messageUser.required = true;
            newSelected.params.finalizarAtendimento = false
        }
        handleEditElement(newSelected);
        setSelected(newSelected);
    }

    function handleHorarioExcecao(value: any) {
        const newSelected: any = selected;
        newSelected.params.horarioExcecao = value;
        if (value === true && newSelected.params.finalizarAtendimento === true) {
            newSelected.params.finalizarAtendimento = false;
        }
        handleEditElement(newSelected);
        setSelected(newSelected);
    }

    function handleFinalizarAtendimento(value: any) {
        const newSelected: any = selected;
        newSelected.params.finalizarAtendimento = value;
        if (value === true) {
            newSelected.params.horarioExcecao = false;
            newSelected.params.messageUser.required = false;
            newSelected.params.messageUser.variable = null;
        }

        handleEditElement(newSelected);
        setSelected(newSelected);
    }

    function handleMessageUserRequired(value: any) {
        const newSelected: any = selected;
        if (newSelected.params.messageUser.variable !== null) {
            newSelected.params.messageUser.required = true;
        } else {
            newSelected.params.messageUser.required = value;
        }

        if ( value === true ) newSelected.params.finalizarAtendimento = false
        handleEditElement(newSelected);
        setSelected(newSelected);
    }

    function handleNewMessage(message: string, type: any) {
        setSubMenuNewMessage(false);
        const newSelected: any = selected;
        newSelected.params.messages.push({
            message: message,
            type: type,
        });
        handleEditElement(newSelected);
        setSelected(newSelected);
        onScrollDown();
    }

    function handleRemoveMessage(indice: number) {
        const newMessages: any = [];
        const newSelected: any = selected;

        newSelected.params.messages.map((row: any, key: any) => {
            if (key !== indice) {
                newMessages.push(row);
            }
        });

        newSelected.params.messages = newMessages;

        handleEditElement(newSelected);
        setSelected(newSelected);
    }

    function handleMessageEditing(indice: any) {
        const newSelected: any = selected;

        newSelected.params.messages.map((row: any, key: any) => {
            if (key === indice) {
                setMessage(row.message);
                setMessageReference({
                    message: row.message,
                    type: row.type,
                    key: key,
                });

                if (row.type === 'text') {
                    setTimeout(() => {
                        //placeCaretAtEnd(refInputEdit.current);
                    }, 50);
                } else if (row.type === 'video' || row.type === 'audio' || row.type === 'image') {
                    setTimeout(() => {
                        refInputEdit.current.click();
                    }, 50);
                }
            }
        });
    }

    function placeCaretAtEnd(el: any) {
        el.focus();
        var createTextRanges: any = document.body;
        if (typeof window.getSelection != 'undefined' && typeof document.createRange != 'undefined') {
            var range = document.createRange();
            range.selectNodeContents(el);
            range.collapse(false);
            var sel: any = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        } else if (typeof createTextRanges.createTextRange != 'undefined') {
            var textRanges: any = document.body;
            var textRange = textRanges.createTextRange();
            textRange.moveToElementText(el);
            textRange.collapse(false);
            textRange.select();
        }
    }

    function checkSubmit(e: any) {
        if (!e.shiftKey && e.key === 'Enter') {
            setSending(true);
        }
    }

    function handleMessageEdit() {
        if (sending === true) {
            setMenuEmojis(false);

            const newSelected: any = selected;

            const newMessages: any = [];

            newSelected.params.messages.map((row: any, key: any) => {
                if (key === messageReference.key) {
                    row.message = message;
                    row.message = row.message.replaceAll('*️⃣', '&ast;&#xFE0F;&#x20E3;');
                    row.filename = filename;
                }

                newMessages.push(row);
            });

            newSelected.params.messages = newMessages;

            handleEditElement(newSelected);
            setSelected(newSelected);

            setFilename('');
            setMessage('');
            setMessageReference({});
            setSending(false);
        }
    }

    async function handleUpload(e: any) {
        try {
            if (e) {
                setLoadUpload(true);

                const nameFileName = e.target.files[0].name;
                var formData = new FormData();

                formData.append('idApp', '2');
                formData.append('destination', '1');
                formData.append('share', '1');
                formData.append('files', e.target.files[0], e.target.files[0].name);
                const response: any = await apiEyou.post('apiDrive', formData, {
                    headers: {
                        Authorization: 'Bearer ' + window.sessionStorage.getItem('tokenInicial'),
                    },
                });

                if (response.data.data.error === true)
                    throw 'Não foi possivel fazer o upload do arquivo, tente novamente mais tarde ou entre em contato com um administrador.';

                setMessage(response.data.data.fileUrl);
                setFilename(nameFileName);
                setSending(true);
                setLoadUpload(false);
            }
        } catch (err: any) {
            addDanger('Atenção', err);
            setLoadUpload(false);
        }
    }

    return (
        <Container id="messages-edit">
            {selected.typeDialog === 'start' && (
                <ContainerPadding>
                    <ContainerWhiteShadow>
                        <b>Entrada</b>
                        <p>
                            A conversa do seu chatbot sempre inicia através da Entrada do usuário. Crie novos blocos
                            para adicionar conteúdos e desenvolva uma conversa com seu cliente.
                        </p>
                    </ContainerWhiteShadow>
                </ContainerPadding>
            )}

            {selected.typeDialog === 'end' && (
                <ContainerPadding>
                    <ContainerWhiteShadow>
                        <b>Exceções</b>
                        <p>O bloco de exceções vai te ajudar a tratar condições padrão do seu chatbot</p>
                    </ContainerWhiteShadow>
                </ContainerPadding>
            )}

            {selected.typeDialog !== 'start' && selected.typeDialog !== 'end' && (
                <ContainerEditMessages minimize={messageReference.type ? true : false}>
                    <Scrollbars ref={refScroll}>
                        <ContainerPadding>
                            {selected.typeDialog === 'attendance' && (
                                <ContainerWhiteShadow>
                                    <b>Atendimento humano</b>
                                    <p>
                                        O bloco de atendimento humano, sempre levará a algum operador e finalizará o
                                        fluxo do bot.
                                    </p>
                                </ContainerWhiteShadow>
                            )}
                            <ContainerWhiteShadow>
                                <b>Resposta do usuário</b>
                                <InputSwitchDefault
                                    value={[
                                        selected.params?.messageUser?.variable === null
                                            ? ''
                                            : selected.params?.messageUser?.variable,
                                    ]}
                                    options={[
                                        {
                                            label: 'Salvar resposta em variável',
                                            value: selected.params?.messageUser?.variable,
                                        },
                                    ]}
                                    onChange={() =>
                                        handleMessageUserVariable(
                                            selected.params?.messageUser?.variable === null ? '' : null
                                        )
                                    }
                                />
                                {selected.params?.messageUser?.variable !== null && (
                                    <input
                                        value={
                                            selected.params?.messageUser?.variable
                                                ? selected.params?.messageUser?.variable
                                                : ''
                                        }
                                        placeholder="Nome da variavel"
                                        onChange={(e) => handleMessageUserVariable(e.target.value)}
                                    />
                                )}
                            </ContainerWhiteShadow>
                            <ContainerWhiteShadow>
                                <InputSwitchDefault
                                    value={[selected.params?.messageUser?.required]}
                                    options={[
                                        {
                                            label: 'Aguardar resposta',
                                            value: true,
                                        },
                                    ]}
                                    onChange={(e) => handleMessageUserRequired(!selected.params?.messageUser?.required)}
                                />
                            </ContainerWhiteShadow>
                            {selected.type !== 'output' && (
                                <ContainerWhiteShadow>
                                    <InputSwitchDefault
                                        value={[selected.params?.horarioExcecao]}
                                        options={[
                                            {
                                                label: 'Validar exceção de horario',
                                                value: true,
                                            },
                                        ]}
                                        onChange={(e) => handleHorarioExcecao(!selected.params?.horarioExcecao)}
                                    />
                                </ContainerWhiteShadow>
                            )}
                            {selected.type !== 'output' && selected.type !== 'input' && (
                                <ContainerWhiteShadow>
                                    <InputSwitchDefault
                                        value={[selected.params?.finalizarAtendimento]}
                                        options={[
                                            {
                                                label: 'Finalizar atendimento',
                                                value: true,
                                            },
                                        ]}
                                        onChange={(e) =>
                                            handleFinalizarAtendimento(!selected.params?.finalizarAtendimento)
                                        }
                                    />
                                    <span className="info">Caso habilitado, será ignorado todas as exceções.</span>
                                </ContainerWhiteShadow>
                            )}
                            <ContainerMessages>
                                <ContainerMenuNewMessages id="submenumessages">
                                    <ContainerMenuNewMessagesButton
                                        active={submenuNewMessage}
                                        onClick={() => setSubMenuNewMessage(!submenuNewMessage)}
                                    >
                                        Adicionar nova mensagem
                                        <IconPlusCircle />
                                    </ContainerMenuNewMessagesButton>

                                    <ContainerMenuNewMessagesMenu active={submenuNewMessage}>
                                        <ContainerMenuNewMessagesMenuItem
                                            onClick={() => handleNewMessage('Mensagem padrão', 'text')}
                                        >
                                            <IconMessageRounded />
                                            Texto
                                        </ContainerMenuNewMessagesMenuItem>
                                        <ContainerMenuNewMessagesMenuItem onClick={() => handleNewMessage('', 'audio')}>
                                            <IconAudio />
                                            Áudio
                                        </ContainerMenuNewMessagesMenuItem>
                                        <ContainerMenuNewMessagesMenuItem
                                            onClick={() => handleNewMessage('', 'arquivo')}
                                        >
                                            <IconFile />
                                            Arquivo
                                        </ContainerMenuNewMessagesMenuItem>
                                        <ContainerMenuNewMessagesMenuItem
                                            onClick={() =>
                                                handleNewMessage(
                                                    'https://www.eyou.com.br/static/media/bg-solucoes.3b1faf15.png',
                                                    'imagem'
                                                )
                                            }
                                        >
                                            <IconImage />
                                            Imagem
                                        </ContainerMenuNewMessagesMenuItem>
                                        <ContainerMenuNewMessagesMenuItem onClick={() => handleNewMessage('', 'video')}>
                                            <IconVideo />
                                            Vídeo
                                        </ContainerMenuNewMessagesMenuItem>
                                    </ContainerMenuNewMessagesMenu>
                                </ContainerMenuNewMessages>

                                <ContainerListMessages>
                                    {selected.params?.messages &&
                                        selected.params.messages.map((row, key) => (
                                            <RenderMessage
                                                indice={key}
                                                row={row}
                                                handleMessageEditing={handleMessageEditing}
                                                handleRemoveMessage={handleRemoveMessage}
                                                loadUpload={loadUpload}
                                            />
                                        ))}
                                </ContainerListMessages>
                            </ContainerMessages>
                        </ContainerPadding>
                    </Scrollbars>
                </ContainerEditMessages>
            )}

            {messageReference.type && (
                <ContainerEditMessage>
                    <ContainerEditableMessage>
                        <div className="full-edit">
                            {messageReference.type !== 'text' && (
                                <InputUploadMessage
                                    handleUpload={handleUpload}
                                    messageReference={messageReference}
                                    refInputEdit={refInputEdit}
                                />
                            )}

                            {messageReference.type === 'text' && (
                                <form onSubmit={(e) => {e.preventDefault(); setSending(true)}}>
                                    <TextAreaInputDefault
                                        disabled={false}
                                        radius={true}
                                        value={message.replaceAll('<br>', '').replaceAll('</br>', '[/b]').replaceAll('<i>', '').replaceAll('</i>', '[/i]').replaceAll('&ast;&#xFE0F;&#x20E3;','*️⃣')}
                                        onChange={(e:any) => setMessage(e.target.value)}
                                        isButtonCheck={true}
                                        handleOnKeyPress={(e) => console.log(e)}
                                        style={{
                                            height: '140px'
                                        }}
                                    />
                                </form>
                            )}
                        </div>

                        <button className="close" onClick={() => setMessageReference({})}>
                            <IconClose />
                        </button>
                    </ContainerEditableMessage>
                </ContainerEditMessage>
            )}
        </Container>
    );
}
