import { useEffect, useRef, useState } from 'react';
import CardWellcomeToChat from '../../components/Chat/CardWellcomeToChat';
import CardChat from '../../components/Chat/CardChat';

import { Container, ContentChat } from './styles';
import apiTropa from '../../services/apiTropa';
import { useRoutes } from '../../contexts/RoutesContext';
import CardPesquisaSatisfacao from '../../components/Chat/CardPesquisaSatisfacao';
import { useSocket } from '../../contexts/SocketContext';
import Loading from '../../components/Loading/Loading';
import CardMessageRender from '../../components/Chat/CardMessageRender';
import moment from 'moment';
import { useMessage } from '../../contexts/MessagesContext';
import ButtonLinear from '../../components/form/ButtonLinear';
import { IconPower } from '../../components/icons';
import { convertMessage } from '../../utils/ConvertMessages';

export default function PageChat(props: any) {
    const { socket, onInit } = useSocket();

    const { setPageName, rules } = useRoutes();
    const { addConfirm, addDanger, addSuccess } = useMessage();
    const [loading, setLoading] = useState(false);
    const [chatStep, setChatStep] = useState('formulario-entrada');
    const [chatData, setChatData] = useState<any>({});
    const [messages, setMessages] = useState<any[]>([]);
    const [lastMessage, setLastMessage] = useState<any>(false);
    const urlPage = props.location?.search.split('=')[1]

    const token = props.match.params.token;
    // console.log(urlPage)


    const [configuracaoEstilo, setConfiguracaoEstilo] = useState<any>({
        style: {},
        styleChat: {},
        styleButtonMain: {},
        styleButtonMainDark: {},
    });
    const [botaoEntrada, setBotaoEntrada] = useState<any>({});
    const [pesquisaSatisfacao, setPesquisaSatisfacao] = useState<any>({});
    const [formularioEntrada, setFormularioEntrada] = useState<any>({});
    const [formularioEntradaObrigatorios, setFormularioEntradaObrigatorios] = useState<any>([]);
    const [formularioEntradaForm, setFormularioEntradaForm] = useState<any>([]);
    const [grupoAtendimento, setGrupoAtendimento] = useState<any>({});
    const [rotinaSend, setRotinaSend] = useState<boolean>(false);
    const [horarioEntrada, setHorarioEntrada] = useState<any>(moment().format('YYYY-MM-DD HH:mm:ss'));
    const [chatScrollDown, setSCrollDown] = useState<any>(false);

    const [avisoInatividade, setAvisoInatividade] = useState<any>('');
    const [inatividade, setInatividade] = useState<any>('');
    const [buttonReconnect, setButtonReconnect] = useState<boolean>(true)

    const [filaAtendimento, setFilaAtendimento] = useState<any>({
        atendimento: {},
        fila: false,
        posicao: 0,
    });
    const AudioMessage = useRef<any>(null);

    useEffect(() => {
        if (filaAtendimento.fila === true || inatividade || avisoInatividade) {
            setSCrollDown(new Date());
        }
    }, [filaAtendimento, inatividade, avisoInatividade]);

    useEffect(() => {
        if (chatStep === 'chat' && messages.length > 0) {
            const lastMessage = messages[messages.length - 1];
            if (lastMessage.id_fluxograma) {
                setHorarioEntrada(moment().format('YYYY-MM-DD HH:mm:ss'));
            } else {
                setHorarioEntrada(lastMessage.criado);
            }
        }
    }, [chatStep, messages]);

    const [chatAtendenceState, setChatAtendenceState] = useState<any>(false)
    const [chatPerfilState, setChatPerfilState] = useState<any>(false)

    useEffect(() => {
        //verifica se é operador
        if (window.sessionStorage.getItem('token') && rules.name === 'operador') {
            addAlertOperator();
        }

        //verifica se tem que ir para formulario de entrada ou ir para o chat
        if (window.sessionStorage.getItem('chatAtendence')) {
            setChatStep('chat');
        }

        //seta o nome da pagina
        setPageName('Chat');
        //inicia o socket
        onInit();
        getInatividade(grupoAtendimento);

        //recebe todas mensagens via socket
        socket.current.on('getMessage', (data: any) => {
            data.criado = moment().format('YYYY-MM-DDTHH:mm:ss.000') + 'Z';
            setLastMessage(data);
        });

        socket.current.on('getFilaOld', (response: any) => {
            const chatAtendenceStorage: any = window.sessionStorage.getItem('chatAtendence');
            const chatAtendence = JSON.parse(chatAtendenceStorage);

            setFilaAtendimento({ ...response });
            if ( response.horario_excessao === true ) {
                setInatividade(response.horario_excessao_mensagem)
            }
            if ( response.atendimento && response.atendimento.id_atendimento === false && response.fila === false) {
                window.sessionStorage.removeItem('chatAtendence');
                window.sessionStorage.removeItem('chatPerfil');
                window.location.reload();
            }
            if (response.atendimento && response.atendimento?.id_atendimento) {
                chatAtendence.id_atendimento = response.atendimento.id_atendimento;
            }
            chatAtendence.atendimento = response.atendimento;
            if (chatData.id_atendimento !== chatAtendence.id_atendimento) setChatData({ ...chatAtendence });
            window.sessionStorage.setItem('chatAtendence', JSON.stringify(chatAtendence));

        });

        //recebe socket de chat finalizado
        socket.current.on('attendanceFinish', (attendanceFinish: any) => {
            try {
                setChatAtendenceState(window.sessionStorage.getItem('chatAtendence'))
                setChatPerfilState(window.sessionStorage.getItem('chatPerfil'))

                if ( attendanceFinish.reconnect && attendanceFinish.reconnect !== true  ) {
                    setButtonReconnect(false)
                } else {
                    setButtonReconnect(true)
                }
                
                window.sessionStorage.removeItem('chatAtendence')

                if (attendanceFinish.enviar_pesquisa_de_satisfacao === true) {
                    setChatStep('pesquisa-satisfacao');
                } else if ( attendanceFinish.enviar_pesquisa_de_satisfacao !== true && attendanceFinish.descricao !== 'Chat Encerrado' ) {
                    setInatividade(convertMessage(attendanceFinish.descricao))
                } else if ( attendanceFinish.enviar_pesquisa_de_satisfacao !== true && !attendanceFinish.descricao ) {
                    setInatividade(convertMessage(grupoAtendimento.mensagem_inatividade))
                }

            } catch ( err:any ) {
                console.log('attendanceFinish', err)
            }
        });
        getData();
    }, []);

    useEffect(() => {
        if (lastMessage !== false) {
            messages.push(lastMessage);
            setMessages([...messages]);
            try {
                AudioMessage.current?.play();
            } catch (err: any) {
                console.log('ERROR AUDIO', err);
            }
        }
    }, [lastMessage]);

    useEffect(() => {
        if (chatData.id_atendimento){    
            getMessages();
        } 
    }, [chatData]);

    async function getMessages() {
        try {
            socket.current.emit('addConnection', {
                referenceId: 'u' + chatData.usuario.id_usuario,
                groupId: Number(chatData.id_atendimento),
            });

            if ( !chatData.id_atendimento ) throw "Sem id atendimento"
            setLoading(true);
            // if ( loading === true ) throw "Ja esta carregando."
            const response: any = await apiTropa.get('mensagem/listar/' + chatData.id_atendimento + '?itens=10&pagina=1');
            setMessages(response.data.data.reverse());
            if (
                response.data.data.length === 0 &&
                messages.filter((obj: any) => obj.mensagem === 'hiddenInitialMessage').length === 0
            ) {

                setTimeout(() => {
                    handleSubmit({
                        mensagem: 'hiddenInitialMessage',
                        tipo: 'texto',
                    }, true);
                    
                }, 1000)
            }

            setChatStep('chat');
            setLoading(false);

        } catch (err:any) {
            console.log('getMessages', err)
        }
        
    }

    useEffect(() => {
        const intervalFila = setInterval(() => {
            getFilaUser();
        }, 15000);
        return () => clearInterval(intervalFila);
    }, [chatData, rotinaSend, filaAtendimento]);

    function addAlertOperator() {
        addConfirm({
            title: 'Olá operador',
            description:
                'Você está logado como operador, para ver essa página é necessário deslogar como operador. Podemos deslogar por você?',
            textButtonCancel: 'Não sair',
            textButtonConfirm: 'Sair',
            onCancel: () => {
                addDanger(
                    'Atenção',
                    'Não será possivel visualizar essa página enquanto não sair da conta de operador.'
                );
                addAlertOperator();
            },
            onConfirm: () => {
                window.sessionStorage.removeItem('token');
                window.location.reload();
            },
        });
    }

    async function getData() {
        setLoading(true);
        const response: any = await apiTropa.get('tag-chat/' + token);
        getFilaUser();
        const configParse = JSON.parse(response.data.data.botaoEntrada.configuracao_estilo);
        const formParse = JSON.parse(response.data.data.formularioEntrada.dados_formulario);

        if (response.data.data.formularioEntrada.campos_obrigatorios) {
            setFormularioEntradaObrigatorios(JSON.parse(response.data.data.formularioEntrada.campos_obrigatorios));
        }

        setConfiguracaoEstilo({ ...configParse });
        setFormularioEntradaForm(formParse);
        setBotaoEntrada({ ...response.data.data.botaoEntrada });
        setFormularioEntrada({ ...response.data.data.formularioEntrada });
        setPesquisaSatisfacao({ ...response.data.data.pesquisaSatisfacao });
        setGrupoAtendimento({ ...response.data.data.grupoAtendimento });
        setLoading(false);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if ( !inatividade ) getInatividade(grupoAtendimento);
        }, 1000);
        return () => clearInterval(interval);
    }, [grupoAtendimento, messages, horarioEntrada, chatStep, filaAtendimento, inatividade]);

    function getInatividade(grupoAtendimento: any) {
        try {
            if (messages.length === 0) throw 'Nenhum mensagem.';
            const newLastMessage = messages[messages.length - 1];
            if (!newLastMessage) throw 'Não encontrou a ultima mensagem.';
            if (newLastMessage.mensagem === 'hiddenInitialMessage') throw 'Mensagem inicial hiddenInitialMessage.';
            if (!grupoAtendimento.tempo_inatividade) throw 'Não cadastrado tempo de inatividade.';
            if (newLastMessage.id_fluxograma && !newLastMessage.id_usuario)
                throw 'Não calcular o tempo, a ultima mensagem foi de bot.';
            if (!newLastMessage.id_operador && newLastMessage.id_usuario) throw 'Éa mensagem do próprio usuario.';
            if (chatStep !== 'chat') throw 'Não está na aba chat, portanto não deve calcular tempo.';
            if (filaAtendimento.fila === true) throw 'Não deve calcular pois está em fila.';

            const tempoInatividade = grupoAtendimento.tempo_inatividade;
            const dataInatividade = moment().format('YYYY-MM-DDT') + tempoInatividade + '.000Z';
            const tempoInatividadeMoment: any = moment(dataInatividade).utc();
            const tempoInatividadeAviso = `${tempoInatividade.split(':')[0]}:${tempoInatividade.split(':')[1]}:${
                parseFloat(tempoInatividade.split(':')[2]) - 20
            }`;

            const dtPartida = moment(horarioEntrada).utc().format('DD/MM/YYYY HH:mm:ss');
            const dtChegada = moment().format('DD/MM/YYYY HH:mm:ss');

            var ms = moment(dtChegada, 'DD/MM/YYYY HH:mm:ss').diff(moment(dtPartida, 'DD/MM/YYYY HH:mm:ss'));
            var d = moment.duration(ms);
            var s = Math.floor(d.asHours()) + moment.utc(ms).format('H:mm:ss');

           if (tempoInatividadeMoment.subtract({ s: 20 }).format('HH:mm:ss') <= s) {
               setAvisoInatividade(grupoAtendimento.mensagem_inatividade_aviso);
            } else {
                setAvisoInatividade('');
            }

            if (tempoInatividade <= s ) {
                setInatividade(grupoAtendimento.mensagem_inatividade);
            } else {
                setInatividade('');
            }
        } catch (err: any) {
            setInatividade('');
            setAvisoInatividade('');
        }
    }


    async function reconectUser() {
        try {

            setLoading(true);
            const sessionStorage: any = window.sessionStorage.getItem('chatAtendence') ?? chatAtendenceState
            const chatAtendence: any = JSON.parse(sessionStorage);

            const responseFinal: any = await apiTropa.put(
                `/atendimento/finalizar-externo/${chatAtendence.id_atendimento}`,
                {
                    finalizado_por: 'Inatividade',
                }
            );

            const response: any = await apiTropa.post('atendimento/adicionar', {
                id_canal_atendimento: '2',
                id_grupo_atendimento: chatAtendence.atendimento.id_grupo_atendimento,
                id_cliente: chatAtendence.usuario.id_cliente,
                informacao_entrada: window.sessionStorage.getItem('chatPerfil') ?? chatPerfilState,
                ip: '',
            });

            if (response.data.status !== 'success') throw response.data.message;

            window.sessionStorage.setItem('chatAtendence', JSON.stringify(response.data.data));
            window.location.reload()

        } catch (err: any) {
            //addDanger('Atenção', err.toString());
            window.sessionStorage.removeItem('chatAtendence')
            window.sessionStorage.removeItem('chatPerfil')
            window.location.reload()
            setLoading(false)
        }
    }

    async function handleOnStartService(data: any) {
        getFilaUser();
        setChatStep('chat');
        setChatData(data);
    }

    async function getFilaUser() {
        try {
            const chatAtendenceStorage: any = window.sessionStorage.getItem('chatAtendence');
            const chatPerfilStorage: any = window.sessionStorage.getItem('chatPerfil');
            const chatAtendence = JSON.parse(chatAtendenceStorage);
            const chatPerfil = JSON.parse(chatPerfilStorage);

            if (!chatAtendence) throw 'Nenhum chat de atendimento.';
            if (!chatAtendence.filaAtendimento) throw 'Nenhuma fila de atendimento.';
            if (!chatAtendence.filaAtendimento?.id_usuario) throw 'Nenhum id_usuario';

            const response: any = await apiTropa.get(
                `fila-atendimento/visualizar-posicao/${chatAtendence.filaAtendimento.id_usuario}/${chatAtendence.filaAtendimento.id_grupo_atendimento}`
            );

            const resFilaAtendimento: any = response.data.data;
            setFilaAtendimento({ ...response.data.data });

            if ( resFilaAtendimento.horario_excessao === true ) {
                setInatividade(resFilaAtendimento.horario_excessao_mensagem)
            }

            if (
                resFilaAtendimento.atendimento &&
                resFilaAtendimento.atendimento.id_atendimento === false &&
                resFilaAtendimento.fila === false
            ) {
                window.sessionStorage.removeItem('chatAtendence');
                window.sessionStorage.removeItem('chatPerfil');
                window.location.reload();
            }

            if (resFilaAtendimento.atendimento && resFilaAtendimento.atendimento?.id_atendimento) {
                chatAtendence.id_atendimento = resFilaAtendimento.atendimento.id_atendimento;
            }

            chatAtendence.atendimento = resFilaAtendimento.atendimento;

            if (chatData.id_atendimento !== chatAtendence.id_atendimento) setChatData({ ...chatAtendence });

            window.sessionStorage.setItem('chatAtendence', JSON.stringify(chatAtendence));

            if (resFilaAtendimento.fila === true) {
                //const cron: any = await apiTropa.get('atendimento/encaminhar-automatico');
                setRotinaSend(true);
            }
        } catch (err) {
            console.log('error interval', err);
        }
    }

    async function handleSubmit(data: any, loading: boolean = false) {
        try {
            if (!data.mensagem) throw 'Mensagem vazia.';
            setInatividade('');
            setAvisoInatividade('');
            data.id_usuario = 'u' + chatData.usuario.id_usuario;
            data.id_atendimento = chatData.id_atendimento;
            data.criado = moment().format('YYYY-MM-DDTHH:mm:ss.000') + 'Z';

            //data.mensagem = data.mensagem.replaceAll('*️⃣','&ast;&#xFE0F;&#x20E3;')

            messages.push(data);
            setMessages([...messages]);

            socket.current.emit('sendMessageUser', data);
            data.id_usuario = data.id_usuario.replace('u', '');

            if ( loading ) setLoading(true)
            const response = await apiTropa.post('mensagem/enviar/' + data.id_atendimento, data);

            setLoading(false)

        } catch (err: any) {
            console.log('err', err);
        }
    }

    const [messagePreLoad, setMessagePreLoad] = useState('') 
    function handleQuote(message:any) {
        const newMessage:any = messagePreLoad+'[quote]'+message+'[/quote]'
        setMessagePreLoad(newMessage)
        // setMessagePreLoad('')
    }

    return (
        <Container>
            {grupoAtendimento.audio_nova_mensagem_usuario && (
                <audio autoPlay={false} ref={AudioMessage}>
                    <source src={grupoAtendimento.audio_nova_mensagem_usuario} />
                </audio>
            )}

            <ContentChat>
                {chatStep === 'formulario-entrada' && (
                    <CardWellcomeToChat
                        data={{
                            formularioEntrada,
                            pesquisaSatisfacao,
                            botaoEntrada,
                            configuracaoEstilo,
                            formularioEntradaForm,
                            grupoAtendimento,
                            formularioEntradaObrigatorios,
                            urlPage
                        }}
                        onChange={handleOnStartService}
                    />
                )}

                {chatStep === 'chat' && (
                    <CardChat
                        icon={configuracaoEstilo.styleChat.file}
                        messages={messages}
                        onSubmit={handleSubmit}
                        setChatStep={setChatStep}
                        filaAtendimento={filaAtendimento}
                        styleButtonFinished={configuracaoEstilo.styleButtonMainDark}
                        backgroundColor={configuracaoEstilo.styleChat.colorMain}
                        audio={grupoAtendimento.audio_nova_mensagem_usuario}
                        scrollOnDownParent={chatScrollDown}
                        inatividade={inatividade}
                        messagePreLoad={messagePreLoad}
                        setMessagePreLoad={setMessagePreLoad}
                    >
                        {messages.map(
                            (row: any, key) =>
                                row.mensagem &&
                                row.tipo !== 'alerta' &&
                                row.mensagem !== 'hiddenInitialMessage' && (
                                    <CardMessageRender
                                        key={key}
                                        name={row.id_fluxograma ? row.chatbot : row.apelido}
                                        messageType={row.id_operador ? 'enviada' : 'recebida'}
                                        hasView={row.status === 'visualizada'}
                                        backgroundEnviada={configuracaoEstilo.styleChat.backgroundEnviada}
                                        backgroundRecebida={configuracaoEstilo.styleChat.backgroundRecebida}
                                        color={'#FFF'}
                                        data={row}
                                        handleQuote={handleQuote}
                                    />
                                )
                        )}

                        {!filaAtendimento.fila && avisoInatividade && !inatividade && (
                            <div className="alert-message">
                                <img src="/96304-hello.gif" />
                                <p>{avisoInatividade}</p>
                            </div>
                        )}

                        {!filaAtendimento.fila && inatividade && (
                            <div className="alert-message-off">

                                <div>
                                    <img src="/96304-hello.gif" />
                                    <p dangerouslySetInnerHTML={{__html: inatividade}}/>
                                </div>

                                {loading === true ? (
                                    <button onClick={() => {}}>
                                        Reconectando
                                    </button>
                                ) : (

                                    <>
                                        { buttonReconnect === true && 
                                            <button onClick={reconectUser}>
                                                Reconectar
                                            </button>
                                        }
                                    </>
                                )}
                            </div>
                        )}

                        {filaAtendimento.fila && (
                            <div className="alert-message">
                                <img src="/18528-clock-time.gif" />
                                <p>
                                    Sua posição na fila é <b>{filaAtendimento.posicao}</b>
                                </p>
                            </div>
                        )}
                    </CardChat>
                )}

                {chatStep === 'pesquisa-satisfacao' && (
                    <CardPesquisaSatisfacao
                        atendimento={chatData}
                        formularioEntrada={formularioEntrada}
                        pesquisaSatisfacao={pesquisaSatisfacao}
                        configuracaoEstilo={configuracaoEstilo}
                        prod={true}
                    />
                )}
            </ContentChat>

            {loading && <Loading active={true} />}
        </Container>
    );
}
