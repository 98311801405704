import React from 'react';

// import { Container } from './styles';

interface IProps {
    messageReference: any;
    handleUpload(e: any): void;
    refInputEdit: any;
}

const InputUploadMessage: React.FC<IProps> = ({
    messageReference,
    handleUpload,
    refInputEdit,
}) => {
    return (
        <div className="upload-info">
            {messageReference.type === 'video' && (
                <p>Extensões aceitas: .mp4</p>
            )}
            {messageReference.type === 'audio' && (
                <p>Extensões aceitas: .mp3 ou .ogg</p>
            )}
            {messageReference.type === 'image' && (
                <p>Extensões aceitas: .jpg ou .png</p>
            )}
            <input
                onChange={() => {}}
                type="file"
                name="file"
                onChangeCapture={handleUpload}
                ref={refInputEdit}
            />
        </div>
    );
};

export default InputUploadMessage;
