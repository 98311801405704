import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    /* height: calc(100vh - 140px); */
    display: flex;
    flex-direction: column;

    #chatbot-fluxograma {
        width: 100%;
        height: 100%;
    }
    .head-chatbot {
        .form-children-default.input {
            height: unset !important;
            padding: 10px 0px;
            border: none;
            border-radius: 0px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            margin-bottom: 30px;
            input {
                padding: 0px;
                font-size: 24px;
            }
        }
    }
`;

export const ContainerHeader = styled.div`
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    > div {
        display: flex;
    }
    .input {
        width: 300px;
    }
    button {
        margin-right: 10px;
    }
    @media (max-width: 900px) {
        .buttons {
            margin-left: 10px;
            overflow: auto;
        }
    }
    @media (max-width: 425px) {
        .input {
            width: 150px;
            margin-right: 15px;
        }
    }
`;

export const ContainerTable = styled.div`
    margin-top: 30px;
    margin-bottom: 20px;
    .skeletor {
        margin-bottom: 8px;
    }
`;
interface PropsContainerFlow {
    fullscreen: boolean
}
export const ContainerFlow = styled.div<PropsContainerFlow>`
    display: flex;
    height: calc(100vh - 280px);
    background: #f5f5f5;
    border-radius: 8px;
    overflow: hidden;
    ${({fullscreen}) => fullscreen ? `position: fixed; width: 100vw; height: 100vh; left: 0; top: 0; border-radius: 0px;` : ``}
    .react-flow__node {
        animation: fadeIn 0.5s;
    }
    .react-flow__edge-path {
        stroke-width: 4px;
        marker-end: unset
    }
    .react-flow__node-default,
    .react-flow__node-input,
    .react-flow__node-output {
        width: unset !important;
        border: none;
        box-shadow: none;
        width: 150px;
    }
    .el-full {
        padding: 15px 15px;
        border-radius: 5px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #333;
        .icon {
            svg {
                width: 18px;
                height: 18px;
                margin-right: 10px;
                * {
                    fill: #fff;
                }
            }
        }
        .text {
            color: #fff;
            font-weight: 400;
            font-size: 15px;
        }
        &.start {
            background: ${(props) => props.theme.colors.cadetGrey};
            .icon {
                svg {
                    * {
                        fill: #fff;
                    }
                }
            }
            .text {
                color: #fff;
            }
        }
        &.end {
            background: ${(props) => props.theme.colors.redPigment};
            .icon {
                svg {
                    * {
                        fill: #fff;
                    }
                }
            }
            .text {
                color: #fff;
            }
        }
        &.attendance {
            background: ${(props) => props.theme.colors.yellowCrayola};
            .icon {
                svg {
                    * {
                        fill: #fff;
                    }
                }
            }
            .text {
                color: #fff;
            }
        }
        &.integration {
            background: ${(props) => props.theme.colors.skyblue};
            .icon {
                svg {
                    * {
                        fill: #fff;
                    }
                }
            }
            .text {
                color: #fff;
            }
        }
    }
    .react-flow__edges {
        g path {
            //stroke-width: 3px !important;
        }
    }
`;

export const ContainerFullScreen = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9999;
    button {
        border: none;
        background: transparent;
        margin: 10px;
        font-size: 20px;
        background: rgba(0,0,0,0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border-radius: 4px;
        svg {
            * {
                fill: #FFF;
            }
        }
    }
`

interface IPropsEdit {
    edition: boolean;
}

export const ContainerEditFlow = styled.div<IPropsEdit>`
    display: flex;

    opacity: ${(props) => (props.edition ? '1' : '0')};
    visibility: ${(props) => (props.edition ? 'visible' : 'hidden')};
    margin-right: ${(props) => (props.edition ? '0px' : '-400px')};
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.5s;
    flex-direction: column;
    justify-content: space-between;
    background: #eeede8;
    max-width: 400px;
    min-width: 400px;
`;
export const ContainerEditFlowHead = styled.div`
    display: flex;
    padding: 24px;
    background: #f2f2f2;
    justify-content: space-between;
    input {
        outline: none;
        background: transparent;
        border: none;
        width: 100%;
        border-bottom: 1px solid #5a646e;
        font-size: 16px;
        color: #5a646e;
        height: 30px;
        padding: 0px 10px;
    }
    button {
        background: transparent;
        border: none;
        outline: none;
        margin-left: 30px;
        svg {
            path {
                fill: #5a646e;
            }
        }
    }
`;

export const ContainerButtonsChatbot = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, 0.1);
`;

export const ContainerEditFlowMenu = styled.ul`
    background: #fff;
    display: flex;
    justify-content: space-around;
    list-style: none;
    margin: 0px;
    padding: 0px;
    min-height: 32px;
`;

interface IPropsEditMenuLi {
    active: boolean;
}
export const ContainerEditFlowMenuLi = styled.li<IPropsEditMenuLi>`
    display: flex;
    font-size: 13px;
    padding: 0px 20px;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #8a99a8;
    border-bottom: 1px solid ${(props) => (props.active ? props.theme.colors.skyblue : 'transparent')};
`;

interface IPropsEditSelected {
    selected: 'conteudo' | 'condicoes' | 'integracoes';
}
export const ContainerEditFlowMenuSelected = styled.div<IPropsEditSelected>`
    display: flex;
    box-sizing: border-box;
    height: calc(100% - 100px);
    #conditions-edit {
        display: ${(props) => (props.selected === 'condicoes' ? 'flex' : 'none')};
    }
    #messages-edit {
        display: ${(props) => (props.selected === 'conteudo' ? 'flex' : 'none')};
    }
    #integrations-edit {
        display: ${(props) => (props.selected === 'integracoes' ? 'flex' : 'none')};
    }
`;

export const ContainerLoadFlow = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 100px;
        height: 100px;
    }
`;

export const SectionVariables = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    
    .headerVariable {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 18px;

        > h2 {
            font-size: 1.25rem;
            font-weight: 600;
            color: white;
            white-space: nowrap;
        }

        .buttonModalVarible {
            display: grid;
            place-items: center;
            background-color: transparent;
            border: none;
        }
    }

    /* .mainModalVariable {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        min-height: 320px;
        max-height: 460px;

        overflow-y: scroll;
        ::-webkit-scrollbar {
            width: 6px;
            background-color: #59588D;
            border-radius: 4px;

        }
        ::-webkit-scrollbar-thumb {
            background-color: #8383B2;
            border-radius: 2px;
        }
        scrollbar-color: #8383B2 transparent;
        scrollbar-width: thin;
        } */
`;

export const SelectVariable = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    padding: 8px 12px;
    margin-top: 12px;
    border-radius: 16px;

    background: #1B1D20;

    .textCopyVariavel {
        font-size: 1rem;
        font-weight: 600;
        color: white;
    }
`;