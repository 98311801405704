import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    
    
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    .form-children-default {
        margin-right: 16px;
    }
`

export const Main = styled.div`
    display: flex;
    flex-direction: column;

    margin-top: 30px;
    .skeletor {
        margin-bottom: 8px;
    }
`

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 24px;

    h1 {
        font-size: 1.5rem;
        font-weight: bold;
        color: ${props => props.theme.colors.white};
    }
`;

export const SectionHours = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const SectionSatisfation = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(220px, 1fr));
    gap: 16px;
    width: 100%;
`

export const SectionOperators = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    max-height: 248px;
    margin-top: 32px;

    padding: 24px;
    border-radius: 16px;

    background: ${props => props.theme.colors.spaceCadedDark};
`

export const InfoOperators = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    
    h2 {
       font-size: 1rem;
       font-weight: bold;
       color: ${props => props.theme.colors.white};
    }
`

export const ListOperators = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(252px, 1fr));
    gap: 22px;
    width: 100%;

    margin-top: 32px;

    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`

