import { useEffect, useRef, useState } from 'react';

import { Buffer } from 'buffer';
import ButtonOperator from '../../components/ButtonOperator';
import ClientData from '../../components/ClientData';
import ButtonLinear from '../../components/form/ButtonLinear';
import InputDefault from '../../components/form/InputDefault';
import { Helmet } from 'react-helmet';
import {
    IconArrowLeft,
    IconBlock,
    IconChatSquare,
    IconClock,
    IconClose,
    IconMessage,
    IconPause,
    IconPower,
    IconUserChat,
    IconUserPlus,
} from '../../components/icons';
import CardChat from '../../components/Ui/CardChat';
import CardMessage from '../../components/Ui/CardMessage';
import PopUpAttendence from '../../components/Ui/PopUpAttendence';
import PopUpHours from '../../components/Ui/PopUpHours';
import { useRoutes } from '../../contexts/RoutesContext';
import { useUser } from '../../contexts/UserContext';
import apiTropa from '../../services/apiTropa';
import { IAtendimento, IMensagem } from '../../types/IAtendimento';
import {
    Container,
    Header,
    Main,
    Panel,
    PanelLeft,
    BgLeft,
    PanelRight,
    SectionMessages,
    Profile,
    Perfil,
    SignInOut,
    PanelCenter,
    HeaderMessage,
    FooterMessage,
    ButtonSignInOut,
    PanelCenterHeader,
    PanelCenterNoContent,
    ButtonMessage,
    ContentMessage,
    ContentFunil,
    SectionDashOperator,
    ContentDahsFilter,
    SectionDashCards,
    SectionDashFilterSelect,
} from './styles';
import ModalDefault from '../../components/ModalDefault';
import TitleModal from '../../components/layout/containers/TitleModal';
import Field from '../../components/layout/containers/Field';
import Loading from '../../components/Loading/Loading';
import { Operador } from '../../types/Operador';
import SelectDefault from '../../components/form/SelectDefault';
import InputTextarea from '../../components/form/InputTextarea';
import { useMessage } from '../../contexts/MessagesContext';
import { GroupChannel, GrupoAtendimento, TemplatesHsm } from '../../types/GrupoAtendimento';
import { useSocket } from '../../contexts/SocketContext';
import { IMensagemPreDefinida } from '../../types/IMensagemPreDefinida';
import Scrollbars from 'react-custom-scrollbars';
import { IFunilEtapa } from '../../types/IFunil';
import CardMessageRender from '../../components/Chat/CardMessageRender';
import moment from 'moment';
import CardTotalIcon from '../../components/pages/dashboard/CardTotalIcon';
import CardContainer from '../../components/layout/containers/CardContainer';
import { IFilterCard } from '../../types/IFilterCard';
import InputSwitchDefault from '../../components/form/InputSwitchDefault';
import { useUIThemeContext } from '../../contexts/UIThemeContext';
import InputSearch from '../../components/form/InputSearch';
import { useDebounce } from '../../utils/useDebouce';
import addNotification from 'react-push-notification';

interface IDTOTranferir {
    id_grupo_atendimento?: string;
    id_atendimento?: string;
    id_usuario?: string;
    descricao?: string;
}

interface IDTOBloquear {
    ip?: string;
    chave?: string;
    valor?: string;
    descricao?: string;
}

interface IDTOFinalizar {
    descricao?: string;
}

interface IConnection {
    referenceId?: string;
    groupId?: string;
    socketId?: string;
}

export default function AttendenceOperator(props: any) {
    const { user, setUser } = useUser();
    const { socket, setLog, onInit } = useSocket();
    const { theme, changeTheme } = useUIThemeContext();
    const { addSuccess, addDanger, addConfirm } = useMessage();
    const { setLogout } = useRoutes();

    const [toggleTheme, setToggleTheme] = useState(['dark']);
    function handleOnTheme(value: any) {
        setToggleTheme(value);
        changeTheme();
    }

    const token: any = props.token ? props.token : window.sessionStorage.getItem('token');
    const tokenInicial: any = props.tokenInicial ? props.tokenInicial : window.sessionStorage.getItem('tokenInicial');
    const idAdmin: any = props.idAdmin ? props.idAdmin : null;

    const [audios, setAudios] = useState<any>({
        audio_nova_mensagem_operador: '',
        audio_nova_mensagem_usuario: '',
        audio_novo_atendimento: '',
    });
    const AudioRef = useRef<any>();
    const AudioNewAttendanceRef = useRef<any>();

    const [loading, setLoading] = useState(false);

    const [historicoMessages, setHistoricoMessages] = useState<any[]>([]);
    const [historicoLoad, setHistoricoLoad] = useState<boolean>(false);
    const [popUpAttendence, setPopUpAttendence] = useState(false);
    const [popUpGroup, setPopUpGroup] = useState(false);
    const [popUpHours, setPopUpHours] = useState(false);

    const [modalHistorico, setModalHistorico] = useState<boolean>(false);
    const [modalTransferir, setModalTransferir] = useState(false);
    const [modalOperator, setModalOperator] = useState(false);
    const [modalTypeTransferir, setModalTypeTransferir] = useState(false);
    const [modalBloquear, setModalBloquear] = useState(false);
    const [modalFinalizar, setModalFinalizar] = useState(false);
    const [modalMensagemPreDefinida, setModalMensagemPreDefinida] = useState(false);
    const [modalNewAttendance, setModalNewAttendance] = useState(false);

    const [DTOTransferir, setDTOTransferir] = useState<IDTOTranferir>({});
    const [DTOTransferirOperador, setDTOTransferirOperador] = useState({
        id_atendimento: '',
        id_operador: '',
        id_grupo_atendimento: '',
        descricao: '',
        id_usuario: '',
    } as any);
    const [DTOBloquear, setDTOBloquear] = useState<IDTOBloquear>({});
    const [DTOFinalizar, setDTOFinalizar] = useState<IDTOFinalizar>({});
    const [DTONewAttendance, setDTONewAttendace] = useState<any>({
        hsm: '',
        id_canal_atendimento: '',
        id_grupo_atendimento: '',
        type: '',
        phone: '',
    });

    const [operador, setOperador] = useState<Operador>({ dadosUsuario: {}, diarioOperador: {} });
    const [atendimentosNotifications, setAtendimentoNotifications] = useState<number>(0);
    const [atendimentosNew, setAtendimentosNew] = useState<number>(0);
    const [atendimentos, setAtendimentos] = useState<IAtendimento[]>([]);
    const [atendimento, setAtendimento] = useState<IAtendimento>({});
    const [loadAtendimento, setLoadAtendimento] = useState<boolean>(false);
    const [mensagens, setMensagens] = useState<IMensagem[]>([]);
    const [lastMessage, setLastMessage] = useState<any>(false);
    const [connections, setConnections] = useState<IConnection[]>([]);

    const [gruposAtendimentoAll, setGrupoAtendimentosAll] = useState<any[]>([]);
    const [gruposDeAtendimento, setGruposDeAtendimento] = useState<GrupoAtendimento[]>([]);
    const [grupoOperadores, setGrupoOperadores] = useState<any[]>([]);
    const [mensagensPreDefinidas, setMensagensPreDefinidas] = useState<IMensagemPreDefinida[]>([]);
    const [funilEtapas, setFunilEtapas] = useState<IFunilEtapa[]>([]);
    const [finishAdmin, setFinishAdmin] = useState<any>({});
    const [groupChannel, setGroupChannel] = useState<GroupChannel[]>([]);
    const [templateHsm, setTemplateHsm] = useState<TemplatesHsm[]>([]);

    const [filter, setFilter] = useState<IFilterCard>({
        dataInicio: moment().endOf('day').format('YYYY-MM-DD'),
        dataFim: moment().endOf('day').format('YYYY-MM-DD'),
        id_grupo_atendimento: '',
        canal: '',
        nivelDeServicoSegundos: 60,
    });

    const refFunil = useRef<HTMLUListElement>(null);

    const setDadosFilter = (key: string, value: any) => {
        setFilter({ ...filter, [key]: value });
    };

    const [chatInternalMessages, setChatInternalMessages] = useState<any>({
        operadores: 0,
        grupos: 0,
    });

    const [typeTransfer, setTypeTransfer] = useState('');
    const [typeAttendence, setTypeAttendence] = useState('Atendimentos');
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState<IAtendimento[]>([]);
    const [isSearching, setSearching] = useState(false);
    const debouncedSearchTerm = useDebounce(searchTerm, 700);
    const verifyAtt = searchTerm.length > 0 ? results : atendimentos;
    const verifyNewMessage = atendimentos.map((row: any) => row.new).filter((obj) => obj > 0);
    const newAttendence = atendimentos.filter((row: any) => row.new === 1);

    const [webworker] = useState(new window.Worker('worker.js'));
    const [result, setResult] = useState('Calculating....');
    const [isClickPlay, setIsClickPlay] = useState<boolean>(false);

    function startPlayer() {
        if (isClickPlay === false) {
            setIsClickPlay(true);
            playAudioRef(true);
            playAudioNewAttendanceRef(true);
        }
    }

    function playAudioRef(muted = false) {
        if (muted) {
            AudioRef.current.currentTime = 0;
            setLog('AudioRef muted', 'AudioRef.current.currentTime = 0');
            AudioRef.current.volume = 0;
            setLog('AudioRef muted', 'AudioRef.current.volume = 0;');
            AudioRef.current.play();
            setLog('AudioRef muted', 'AudioRef.current.play();');
            AudioRef.current.pause();
            setLog('AudioRef muted', 'AudioRef.current.pause();');
        } else {
            AudioRef.current.volume = 1;
            AudioRef.current.currentTime = 0;
            setLog('AudioRef', 'current.currentTime = 0');
            AudioRef.current.play();
            setLog('AudioRef', 'AudioRef.current.play()');
        }
    }

    function playAudioNewAttendanceRef(muted = false) {
        if (muted) {
            AudioNewAttendanceRef.current.currentTime = 0;
            setLog('AudioNewAttendanceRef muted', 'AudioNewAttendanceRef.current.currentTime = 0');
            AudioNewAttendanceRef.current.volume = 0;
            setLog('AudioNewAttendanceRef muted', 'AudioNewAttendanceRef.current.volume = 0');
            AudioNewAttendanceRef.current.play();
            setLog('AudioNewAttendanceRef muted', 'AudioNewAttendanceRef.current.play();');
            AudioNewAttendanceRef.current.pause();
            setLog('AudioNewAttendanceRef muted', 'AudioNewAttendanceRef.current.pause();');
        } else {
            AudioNewAttendanceRef.current.volume = 1;
            setLog('AudioNewAttendanceRef', 'AudioNewAttendanceRef.current.currentTime = 1');
            AudioNewAttendanceRef.current.currentTime = 0;
            setLog('AudioNewAttendanceRef', 'AudioNewAttendanceRef.current.currentTime = 0');
            AudioNewAttendanceRef.current.play();
            setLog('AudioNewAttendanceRef', 'AudioNewAttendanceRef.current.play()');
        }
    }

    useEffect(() => {
        const message = { multiply: { array: new Array(1000).fill(2) } };
        webworker.postMessage(message);

        webworker.onerror = () => {
            setResult('Error');
        };

        webworker.onmessage = (e) => {
            if (e.data) {
                setResult(e.data.result);
            } else {
                setResult('Error');
            }
        };
    }, []);

    useEffect(() => {
        return () => {
            webworker.terminate();
        };
    }, []);

    useEffect(() => {
        let showingAlert = false;

        const intervalNewMessage = setInterval(() => {
            if (!!verifyNewMessage.length) {
                document.title = showingAlert ? 'Atendimentos' : `(${verifyNewMessage.length}) Nova Mensagem`;
                showingAlert = !showingAlert;
            }
        }, 1000);
        return () => {
            document.title = 'Atendimentos';
            clearInterval(intervalNewMessage);
        };
    }, [atendimentos]);

    useEffect(() => {
        if (debouncedSearchTerm) {
            setSearching(true);
            setResults(
                atendimentos.filter((obj: any) =>
                    obj.informacao_entrada['Nome completo'].toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
            setSearching(false);
        } else {
            setResults(atendimentos);
            setSearching(false);
        }
    }, [debouncedSearchTerm]);

    //inicia conexão com socket e recupera dados necessarios
    useEffect(() => {
        initChatGlobal();
    }, []);

    useEffect(() => {
        const intervalHeart = setInterval(() => {
            socket.current.emit('heartbeat', { data: 'a' });
        }, 15000);
        return () => clearInterval(intervalHeart);
    }, []);

    useEffect(() => {
        const reloadPageOperador = setInterval(() => {
            //window.location.reload();
        }, 1200000);
        return () => clearInterval(reloadPageOperador);
    }, []);

    useEffect(() => {
        const intervalStatus = setInterval(() => {
            getStatusAtendimento();
        }, 2000);
        return () => clearInterval(intervalStatus);
    }, [props, atendimento, finishAdmin]);

    useEffect(() => {
        const intervalLogOut = setInterval(() => {
            getLogOut();
        }, 30000);
        return () => clearInterval(intervalLogOut);
    }, []);

    
    useEffect(() => {
        
            const intervalLogOut = setInterval(async () => {
                const data: any = await apiTropa.get('/atendimento/get-by-operador-complete', {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                });

                if (data.data.data.length > atendimentos.length) {
                    if (AudioNewAttendanceRef && AudioNewAttendanceRef.current) {
                        await playAudioNewAttendanceRef();
                    }
                }

                //setAtendimentos([...data.data.data]);
                // setResults([...data.data.data]);
            }, 15000);
            return () => clearInterval(intervalLogOut);
        
    }, [atendimentos, AudioNewAttendanceRef]);
    

    async function getLogOut() {
        const response: any = await apiTropa.get('operador/verificar', {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });

        if (response.data.data.log === false) {
            setLogout();
        }
        /*
        if(response.data.data.modal === true){    
            addConfirm({
                title: `Oi, ${user.apelido}!`,
                description: 'Você ainda esta online ?',
                textButtonCancel: 'Não',
                textButtonConfirm: 'Sim',
                onConfirm: () => {window.location.reload()},
                onCancel: () => setLogout()
            });
        }
        */
    }

    async function getStatusAtendimento() {
        if (props.idAdmin && !finishAdmin.data_saida && atendimento.id_atendimento) {
            const response: any = await apiTropa.get('atendimento/status/' + atendimento.id_atendimento);
            if (finishAdmin.data_saida !== response.data.data.data_saida) setLoadAtendimento(true);
            setFinishAdmin({ ...response.data.data });
            setTimeout(() => {
                setLoadAtendimento(false);
            }, 500);
        }
    }

    useEffect(() => {
        const newAtendimentos: any = [];
        atendimentos.map((row: any, key) => {
            if (row.id_atendimento === atendimento.id_atendimento) {
                row.ultima_mensagem.mensagem = atendimento.ultima_mensagem?.mensagem;
                //row.ultima_mensagem.criado = moment(row.ultima_mensagem.criado).format('YYYY-MM-DDTHH:mm:00.000')+'Z'
            }
            newAtendimentos.push(row);
        });
        setAtendimentos([...newAtendimentos]);
    }, [atendimento]);

    // Notificação no sistema
    const pushNotificationOn = () => {
        newAttendence.map((row) => {
            addNotification({
                title: 'Novo Atendimento',
                subtitle: row.informacao_entrada?.['Nome completo'],
                message: row.ultima_mensagem?.mensagem,
                duration: 9000,
                theme: 'darkblue',
                native: true,
            });
        });
    };

    //verifica se existe o atendimento na lista, caso não existe ele zera
    useEffect(() => {
        if (
            atendimento.id_atendimento &&
            atendimentos.filter((obj: any) => obj.id_atendimento === atendimento.id_atendimento).length === 0
        ) {
            setAtendimento({});
            if (!props.idAdmin) {
                //history.push('/fila-de-atendimento/finalizados')
            }
        }

        let totalNotification: number = 0;
        let totalNew: number = atendimentos.filter((obj) => obj.new).length;
        atendimentos.map((row: any) => {
            totalNotification = totalNotification + parseFloat(row.mensagens_nao_lidas);
        });
        if (totalNew > atendimentosNew && AudioNewAttendanceRef && AudioNewAttendanceRef.current) {
            playAudioNewAttendanceRef();
        }
        if (totalNotification > atendimentosNotifications && AudioRef && AudioRef.current) {
            playAudioRef();
        }
        setAtendimentosNew(totalNew);
        setAtendimentoNotifications(totalNotification);
    }, [atendimentos]);

    //recupera a ultima mensagem recebida e encaixa no chat
    useEffect(() => {
        //if (lastMessage.id_usuario === 'u' + atendimento.id_usuario) {
        mensagens.push(lastMessage);
        setMensagens([...mensagens]);
        if (AudioRef && AudioRef.current) playAudioRef();
        //}
    }, [lastMessage]);

    //sempre que o atendimentos for alterado, cai aqui
    // useEffect(() => {
    //     if (!atendimento.id_atendimento && !props.atendimento?.id_atendimento) {
    //         iniciarAtendimento(atendimentos[0]);
    //     } else if (props.atendimento && props.atendimento.id_atendimento && !atendimento.id_atendimento) {
    //         iniciarAtendimento(
    //             atendimentos.filter((obj: any) => obj.id_atendimento === props.atendimento.id_atendimento)[0]
    //         );
    //     }
    // }, [atendimentos]);

    async function initChatGlobal() {
        setLoading(true);
        await getOperador();
        await getGruposDeAtendimentoParaNovoAtendimento();
    }

    function isConnection(id_usuario: any) {
        let referenceId = `u${id_usuario}`;
        let response = connections.some((connection) => {
            return connection.referenceId === referenceId;
        });
        return response === true ? true : false;
    }

    const [idOperadorState, setIdOperadorState] = useState('');

    function getOperador() {
        const newToken = String(token);
        const decoded = new Buffer(newToken, 'base64').toString('ascii');
        const decodedJson = JSON.parse(decoded);
        const idOperador = decodedJson.user.idU;
        setIdOperadorState(idOperador);

        apiTropa
            .post(
                `/operador/detalhe/${idOperador}`,
                {
                    tokenEyou: tokenInicial,
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                }
            )
            .then((response: any) => {
                const operador = response.data.data;
                if (operador.horarios.find((row: any) => row.data === 'domingo') === undefined)
                    operador.horarios.splice(0, 0, { data: 'domingo' });
                if (operador.horarios.find((row: any) => row.data === 'segunda-feira') === undefined)
                    operador.horarios.splice(1, 0, { data: 'segunda-feira' });
                if (operador.horarios.find((row: any) => row.data === 'terca-feira') === undefined)
                    operador.horarios.splice(2, 0, { data: 'terca-feira' });
                if (operador.horarios.find((row: any) => row.data === 'quarta-feira') === undefined)
                    operador.horarios.splice(3, 0, { data: 'quarta-feira' });
                if (operador.horarios.find((row: any) => row.data === 'quinta-feira') === undefined)
                    operador.horarios.splice(4, 0, { data: 'quinta-feira' });
                if (operador.horarios.find((row: any) => row.data === 'sexta-feira') === undefined)
                    operador.horarios.splice(5, 0, { data: 'sexta-feira' });
                if (operador.horarios.find((row: any) => row.data === 'sabado') === undefined)
                    operador.horarios.splice(6, 0, { data: 'sabado' });

                const weekday = moment().weekday();
                let weekdayString = 'domingo';
                if (weekday === 1) weekdayString = 'segunda-feira';
                if (weekday === 2) weekdayString = 'terca-feira';
                if (weekday === 3) weekdayString = 'quarta-feira';
                if (weekday === 4) weekdayString = 'quinta-feira';
                if (weekday === 5) weekdayString = 'sexta-feira';
                if (weekday === 6) weekdayString = 'sabado';

                operador.horarioAtual = operador.horarios.find((row: any) => row.data === weekdayString);
                operador.horarioAtual = operador.horarioAtual.horarios;

                socket.current.emit('addConnection', {
                    referenceId: idAdmin !== null ? 'a' + idAdmin : 'o' + idOperador,
                    groupId: 0,
                });

                socket.current.on('getMessage', (data: any) => {
                    data.criado = moment().format('YYYY-MM-DDTHH:mm:00.000') + 'Z';
                    setLastMessage(data);
                });
                socket.current.on('operatorStatus', (data: any) => {
                    //operador.diarioOperador.status = data
                    operador.diarioOperador.status = data.status;
                    setOperador({ ...operador });
                });
                socket.current.on('operatorAttendances', (data: any) => {
                    setAtendimentos([...data]);
                });
                socket.current.on('getConnections', (connections: IConnection[]) => {
                    setConnections([...connections]);
                });
                socket.current.on('disconnect', (err: any) => {
                    setLog('socket.disconect', err.toString());
                    setLog('socket.try.newConnect', err.toString());
                    onInit();
                });

                user.perfil = operador.perfil[0];

                if (!user.perfil?.id_login) {
                    getOperador();
                } else {
                    setUser(user);
                    setOperador(operador);
                    getAtendimentos();
                }
            });
        const response = apiTropa
            .get('cliente/get', {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            })
            .then((response: any) => {
                setAudios({ ...response.data.data });
            });
    }

    async function getAtendimentos(loadingItem: boolean = true) {
        if (loading === false) {
            if (loadingItem === true) setLoading(true);
            const newToken = String(token);
            const decoded = new Buffer(newToken, 'base64').toString('ascii');
            const decodedJson = JSON.parse(decoded);
            const idOperador = decodedJson.user.idU;
            const response: any = await apiTropa.get(
                `/atendimento/get-by-operador/${idOperador}?status=online&historico=false`,
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                }
            );
            const atendimentos = response.data.data;

            if (
                atendimentos[0] &&
                atendimentos[0].id_atendimento &&
                atendimentos[0].id_operador &&
                !atendimentos[0].id_mensagem
            ) {
                setAtendimentos([...atendimentos]);
            } else if (atendimentos.atendimento?.id_atendimento) {
                getAtendimentos();
            }

            setLoading(false);
        }
        return atendimentos;
    }

    async function getGruposDeAtendimentoParaNovoAtendimento() {
        const response: any = await apiTropa.get(`chat-grupoatendimento-listar`, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });

        if (response && response.data && response.data.data) {
            const result = response.data.data;
            setGrupoAtendimentosAll(response.data.data);
        }
    }

    async function getGruposDeAtendimentoParaTransferencia(id_canal_atendimento: any) {
        const response: any = await apiTropa.get(`/atendimento/opcoes-tranferencia/${id_canal_atendimento}`, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });

        if (response.data.data) {
            const result = response.data.data;
            setGruposDeAtendimento(response.data.data);
        }
    }

    async function getOperadoresParaTransferir(id: any) {
        const response: any = await apiTropa.get(`/operador/online-transferencia?id_atendimento=${id}`, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });

        if (response.data.data) {
            const result = response.data.data;
            setGrupoOperadores(response.data.data);
        }
    }

    async function getMensagensPreDefinidas(id_grupo_atendimento: any) {
        const response: any = await apiTropa.get(`/mensagem-predefinida/grupo-atendimento/${id_grupo_atendimento}`, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
        const result = response.data.data;
        setMensagensPreDefinidas(result);
    }

    async function getMensagensByAtendimentoId(id_atendimento: any) {
        const response: any = await apiTropa.get(`/mensagem/listar/${id_atendimento}?isOperador=true`, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
        const result =
            response.data && response.data.data && response.data.data.length > 0 ? response.data.data.reverse() : [];
        setMensagens(result);
    }

    async function getFunilEtapas(id_grupo_atendimento: any) {
        const response: any = await apiTropa.get(`/grupo-funil-grupo/${id_grupo_atendimento}`, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
        const result = response.data.data;
        if (result && result.etapas) {
            setFunilEtapas(result.etapas);
        }
    }

    // async function getOperatorAttendenceTransfer(id: any) {
    //     const response: any = await apiTropa.get(`operador/online-transferencia?id_atendimento=3751`, {
    //         headers: {
    //             Authorization: 'Bearer ' + token,
    //         },
    //     });
    //     const result = response.data.data;
    //     if (result && result.etapas) {
    //         setOperatorTransfer(result.etapas);
    //     }
    // }

    async function iniciarAtendimento(atendimento: IAtendimento) {
        if (atendimento && atendimento.id_operador && loadAtendimento === false) {
            const newAtendimentos: any[] = [];
            atendimentos.map((row) => {
                if (row.id_atendimento === atendimento.id_atendimento) {
                    row.mensagens_nao_lidas = 0;
                    row.new = false;
                }
                newAtendimentos.push(row);
            });
            setAtendimentos([...newAtendimentos]);
            // clearInterval(interval)

            setLoadAtendimento(true);
            socket.current.emit('addConnection', {
                referenceId: idAdmin !== null ? 'a' + idAdmin : 'o' + atendimento.id_operador,
                groupId: atendimento.id_atendimento,
            });

            setAtendimento(atendimento);
            setFunilEtapas([]);
            if (atendimento.funil) {
                setFunilEtapas(JSON.parse(atendimento.funil));
            } else {
                await getFunilEtapas(atendimento.id_grupo_atendimento);
            }

            //carrega mensagens pré definidas para o grupo
            await getMensagensByAtendimentoId(atendimento.id_atendimento);
            setLoadAtendimento(false);
            await getGruposDeAtendimentoParaTransferencia(atendimento.id_whatsapp ? '1' : '2');
            await getMensagensPreDefinidas(atendimento.id_grupo_atendimento);
        }
    }

    function onChangeDTOTransferir(key: any, value: any) {
        let copy: any = DTOTransferir;
        copy[key] = value;
        setDTOTransferir({ ...copy });
    }

    function onChangeDTOTransferirOperador(value: any) {
        const verify = value[0].split(',');

        const { descricao } = DTOTransferirOperador;
        const data = {
            id_grupo_atendimento: verify[0],
            id_usuario: atendimento.id_usuario,
            descricao,
            id_atendimento: String(atendimento.id_atendimento),
            id_operador: verify[1],
        };
        setDTOTransferirOperador(data);
    }

    function handleOnMessageOperatorTransfer(name: any, value: any) {
        setDTOTransferirOperador({ ...DTOTransferirOperador, [name]: value });
    }

    function onChangeDTONewAttendance(key: any, value: any) {
        let copy: any = DTONewAttendance;
        copy[key] = value;
        setDTONewAttendace({ ...copy });
        getCanaisDoGrupo();
    }

    function onChangeHsm(name: string, value: any) {
        let copy = DTONewAttendance;
        const selected = groupChannel.filter((obj) => obj.id_grupo_canal_atendimento === Number(value))[0];
        copy[name] = value;
        copy['type'] = selected?.canal;

        setDTONewAttendace({ ...copy });
        if (selected?.configuracao_grupo.length > 2) {
            getAttendenceOperatorHsm(value);
        } else {
            setTemplateHsm([]);
        }
    }

    function onChangeTemplate(name: string, value: any) {
        let copy = DTONewAttendance;
        copy[name] = value;

        setDTONewAttendace({ ...copy });
    }

    function onChangeDTOBloquear(key: any, value: any) {
        let copy: any = DTOBloquear;
        copy[key] = value;

        if (key === 'chave') {
            let informacaoEntrada: any = atendimento.informacao_entrada;
            copy.valor = informacaoEntrada[value] ? informacaoEntrada[value] : null;
        }

        setDTOBloquear({ ...copy });
    }

    function onChangeDTOFinalizar(key: any, value: any) {
        let copy: any = DTOFinalizar;
        copy[key] = value;
        setDTOFinalizar({ ...copy });
    }

    async function enviarMensagem(data: any) {
        data.id_usuario = 0;
        data.id_operador = 'o' + atendimento.id_operador;
        data.id_admin = idAdmin;
        data.id_atendimento = atendimento.id_atendimento;
        data.apelido = props.user ? props.user.apelido : user.perfil?.apelido;
        data.status = 'recebida';
        data.criado = moment().format('YYYY-MM-DDTHH:mm:00.000') + 'Z';
        data.mensagem = data.mensagem.replaceAll('*️⃣', '&ast;&#xFE0F;&#x20E3;');
        mensagens.push(data);
        setMensagens([...mensagens]);

        socket.current.emit('sendMessageUser', data);

        data.id_operador = data.id_operador.replace('o', '');
        await apiTropa.post(`mensagem/enviar/${data.id_atendimento}`, data, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
    }

    async function handleMensagemPreDefinida(value: any) {
        setModalMensagemPreDefinida(false);
        await enviarMensagem({
            tipo: 'texto',
            mensagem: value,
            criado: new Date(),
        });
    }

    async function handleBloquear() {
        try {
            setLoading(true);

            DTOBloquear.ip = '';
            await apiTropa.post(`/bloqueio`, DTOBloquear, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });

            setDTOFinalizar({
                descricao: DTOBloquear.descricao ? DTOBloquear.descricao : 'Bloqueio',
            });
            await handleFinalizar();

            setLoading(false);
            setModalBloquear(false);
            setDTOBloquear({
                chave: '',
                valor: '',
                descricao: '',
            });
            getAtendimentos();
            addSuccess('Confirmado', 'Bloqueio efetuado com sucesso');
        } catch (err: any) {
            if (err.response) {
                addDanger('Atenção', String(err.response.data.message));
            } else {
                addDanger('Atenção', String(err));
            }
            setLoading(false);
        }
    }

    async function handleTransferir() {
        try {
            setLoading(true);
            DTOTransferir.id_atendimento = String(atendimento.id_atendimento);
            DTOTransferir.id_usuario = String(atendimento.id_usuario);
            const response: any = await apiTropa.post(`/atendimento/transferir`, DTOTransferir, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });
            setLoading(false);
            if (response.data.status === 'error') {
                addDanger('Atenção', response.data.message);
            } else {
                setModalTransferir(false);
                setDTOTransferir({
                    id_grupo_atendimento: '',
                    id_atendimento: '',
                    id_usuario: '',
                    descricao: '',
                });
                apiTropa.get('atendimento/encaminhar-automatico');
                getAtendimentos();
                addSuccess('Confirmado', 'Transferência realizada com sucesso');
            }
        } catch (err: any) {
            if (err.response) {
                addDanger('Atenção', String(err.response.data.message));
            } else {
                addDanger('Atenção', String(err));
            }
            setLoading(false);
        }
    }

    async function handleOperadorTransferir() {
        try {
            setLoading(true);
            const response: any = await apiTropa.post(`/atendimento/transferir-operador`, DTOTransferirOperador, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });
            setLoading(false);
            if (response.data.status === 'error') {
                addDanger('Atenção', response.data.message);
            } else {
                setModalTransferir(false);
                setModalOperator(false);
                setDTOTransferirOperador({
                    id_atendimento: '',
                    id_operador: '',
                    id_grupo_atendimento: '',
                    descricao: '',
                    id_usuario: '',
                });
                apiTropa.get('atendimento/encaminhar-automatico');
                getAtendimentos();
                addSuccess('Confirmado', 'Transferência realizada com sucesso');
            }
        } catch (err: any) {
            if (err.response) {
                addDanger('Atenção', String(err.response.data.message));
            } else {
                addDanger('Atenção', String(err));
            }
            setLoading(false);
        }
    }

    async function handleNewAttendance() {
        try {
            DTONewAttendance.phone = DTONewAttendance.phone
                .replace('(', '')
                .replace(')', '')
                .replaceAll(' ', '')
                .replaceAll('_', '')
                .replaceAll('-', '');
            if (DTONewAttendance.phone?.length < 11) throw 'O campo celular é obrigatório.';
            if (!DTONewAttendance.name) throw 'O campo nome é obrigatório.';
            if (!DTONewAttendance.id_grupo_atendimento) throw 'O campo grupo de atendimento é obrigatório.';
            if (!DTONewAttendance.type) throw 'O campo de HSM de mensagem é obrigatorio.';

            setLoading(true);

            const response: any = await apiTropa.post(
                'atendimento/novo',
                {
                    nome: DTONewAttendance.name,
                    celular: DTONewAttendance.phone,
                    id_grupo_atendimento: DTONewAttendance.id_grupo_atendimento,
                    tokenInicial: window.sessionStorage.getItem('tokenInicial'),
                    tipo: DTONewAttendance.type,
                    hsmWp: {
                        templateId: DTONewAttendance.hsm,
                    },
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                }
            );

            addSuccess(`Uma mensagem foi enviado ao número ${DTONewAttendance.phone}.`, response.data.data.link);
            setModalNewAttendance(false);
            setDTONewAttendace({
                phone: '',
                name: '',
                id_grupo_atendimento: '',
                hsm: '',
                type: '',
            });
            setGroupChannel([]);
            setTemplateHsm([]);
            setLoading(false);
            getAtendimentos();
        } catch (err: any) {
            addDanger('Atenção', String(err));
        }
    }

    async function handleFinalizar(enviar_pesquisa_de_satisfacao = false) {
        try {
            if (!DTOFinalizar.descricao) throw 'É obrigatório preencher o campo descrição.';

            setLoading(true);
            setModalFinalizar(false);

            await apiTropa.put(`/atendimento/finalizar/${atendimento.id_atendimento}`, DTOFinalizar, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });

            let data = {
                id_operador: 'o' + atendimento.id_operador,
                id_atendimento: atendimento.id_atendimento,
                enviar_pesquisa_de_satisfacao: enviar_pesquisa_de_satisfacao,
                descricao: 'Chat encerrado!',
            };
            socket.current.emit('attendanceFinish', data);

            socket.current.emit('adminAttendances', {
                id_atendimento: atendimento.id_atendimento,
                atendimentos: atendimentos,
            });

            setDTOFinalizar({
                descricao: '',
            });

            const newAtendimentos: any = atendimentos.filter(
                (obj: any) => obj.id_atendimento !== atendimento.id_atendimento
            );
            if (newAtendimentos.length > 0) {
                setAtendimento(newAtendimentos[0]);
            } else {
                setAtendimento({});
                setAtendimentos([...newAtendimentos]);
            }

            setAtendimentos([...newAtendimentos]);
            setLoading(false);

            addSuccess('Confirmado', 'Atendimento finalizado com sucesso');
        } catch (err: any) {
            if (err.response) {
                addDanger('Atenção', String(err.response.data.message));
            } else {
                addDanger('Atenção', String(err));
            }
            setModalFinalizar(true);
            setLoading(false);
        }
    }

    async function handleFunilEtapa(etapa: any) {
        try {
            getAtendimentos();
            let funil = [] as IFunilEtapa[];
            funilEtapas.map((row) => {
                if (row.etapa === etapa.etapa) {
                    row.active = true;
                } else {
                    row.active = false;
                }

                funil.push({
                    etapa: row.etapa,
                    active: row.active,
                });
            });
            setLoading(true);
            await apiTropa.put(
                `/atendimento/atualizar/${atendimento.id_atendimento}`,
                {
                    funil: JSON.stringify(funil),
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                }
            );
            setLoading(false);
        } catch (err: any) {
            if (err.response) {
                addDanger('Atenção', String(err.response.data.message));
            } else {
                addDanger('Atenção', String(err));
            }
            setLoading(false);
        }
    }

    function openPopUp() {
        setPopUpAttendence(!popUpAttendence);
        setPopUpGroup(false);
        setPopUpHours(false);
    }

    function openPopUpGroup() {
        setPopUpGroup(!popUpGroup);
        setPopUpAttendence(false);
        setPopUpHours(false);
    }

    function openPopUpHours() {
        setPopUpHours((popUpHours) => !popUpHours);
        setPopUpAttendence(false);
        setPopUpGroup(false);
    }

    function handleMessagesChatInternal(key: any, value: any) {
        chatInternalMessages[key] = value;
        setChatInternalMessages({ ...chatInternalMessages });
    }

    const [messagePreLoad, setMessagePreLoad] = useState('');
    function handleQuote(message: any) {
        const newMessage: any = messagePreLoad + '[quote]' + message + '[/quote]';
        setMessagePreLoad(newMessage);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            getStatusOperador(operador);
        }, 5000);

        return () => clearInterval(interval);
    }, [operador]);

    async function getStatusOperador(operador: any) {
        if (operador.diarioOperador.status === 'Aguardando Horário') {
            let newStatus: any;

            const response: any = await apiTropa.post('/operador/login');

            if (response.data.data.status === 'aguardando_horario') {
                newStatus = 'Aguardando Horário';
            } else if (response.data.data.status === 'aguardando_pausa') {
                newStatus = 'Aguardando Pausa';
            } else if (response.data.data.status === 'offline') {
                newStatus = 'Offline';
            } else if (response.data.data.status === 'online') {
                newStatus = 'Online';
            } else if (response.data.data.status === 'pausa') {
                newStatus = 'Pausa';
            }

            operador.diarioOperador.status = newStatus;
            setOperador({ ...operador });
        }
    }

    function handleAtendimentoUpdate(atendimentoSelected: any) {
        const newAtendimentos: any = [];
        atendimentos.map((row: any, key: any) => {
            if (row.id_atendimento === atendimentoSelected.id_atendimento) {
                newAtendimentos.push(atendimentoSelected);
            } else {
                newAtendimentos.push(row);
            }
        });
        setAtendimentos([...newAtendimentos]);
    }

    async function handleHistory(atendimento: any) {
        setHistoricoLoad(true);
        setModalHistorico(true);
        const response: any = await apiTropa.get(
            'mensagem/historico/' + atendimento.id_atendimento + '?isOperador=true'
        );
        setHistoricoMessages([...response.data.data.reverse()]);
        setHistoricoLoad(false);
    }

    function getCanaisDoGrupo() {
        setLoading(true);
        apiTropa
            .get(`/canal-atendimento/grupo-atendimento/get/${DTONewAttendance.id_grupo_atendimento}`)
            .then((response: any) => {
                const decoded = new Buffer(response.data.data, 'base64').toString('ascii');
                const decodedJson = JSON.parse(decoded);
                setGroupChannel(decodedJson);
                setLoading(false);
            });
    }

    function getAttendenceOperatorHsm(id: any) {
        setLoading(true);
        apiTropa.get(`/canal-atendimento/grupo-atendimento/hsm/${id}`).then((response: any) => {
            setTemplateHsm(response.data.data);
            setLoading(false);
        });
    }

    // useEffect(() => {
    //     const setInterva = setInterval(() => {
    //         if (optionReload.length > 0) {
    //             getDashBoardData();
    //         }
    //     }, timeReload);

    //     return () => clearInterval(setInterva);
    // }, [optionReload, timeReload]);

    const [dashboard, setDashboard] = useState<any>({});
    const [dashboardGrupoAtendimento, setDashboardGrupoAtendimento] = useState<any>('');

    useEffect(() => {
        if (gruposAtendimentoAll.length > 0) {
            setDashboardGrupoAtendimento(gruposAtendimentoAll[0].id_grupo_atendimento);
            getDataDashboard();
        }
    }, [gruposAtendimentoAll]);

    async function getDataDashboard() {
        try {
            setLoading(true);
            //if ( !dashboardGrupoAtendimento ) throw "Selecione pelo menos um grupo de atendimento."
            const response: any = await apiTropa.get(
                `dashboard/operador/${idOperadorState}?dataInicio=${filter.dataInicio}&dataFim=${filter.dataFim}&id_grupo_atendimento=${filter.id_grupo_atendimento}&canal=${filter.canal}`
            );
            setDashboard({ ...response.data.data });
            setLoading(false);
        } catch (err: any) {
            // addDanger('Erro', err)
        }
    }

    function handleOnClearFilterUser() {
        setSearchTerm('');
    }

    function handleOnTypeTransfer(type: string) {
        setTypeTransfer(type);
        setModalTypeTransferir(false);

        if (type === 'Grupos') {
            setModalTransferir(true);
            getGruposDeAtendimentoParaTransferencia(atendimento.id_whatsapp ? '1' : '2');
        } else {
            setModalOperator(true);
            getOperadoresParaTransferir(atendimento.id_atendimento);
        }
    }

    return (
        <Container onLoad={startPlayer}>
            {!idAdmin && (
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{atendimentosNotifications ? `(${atendimentosNotifications})` : ''} Atendimentos</title>
                    <link rel="canonical" href="http://mysite.com/example" />
                </Helmet>
            )}

            <video
                muted
                autoPlay
                controls
                loop
                style={{ position: 'fixed', top: -999999, left: -999999, width: 0, height: 0 }}
            >
                <source src="/silence.mp3" />
            </video>

            <Loading active={loading} />
            {audios.audio_nova_mensagem_operador && (
                <>
                    <audio
                        preload="auto"
                        id="audio_nova_mensagem_operador"
                        src={audios?.audio_nova_mensagem_operador}
                        ref={AudioRef}
                        autoPlay={false}
                    >
                        <source src={audios?.audio_nova_mensagem_operador} />
                    </audio>
                </>
            )}
            {audios.audio_novo_atendimento && (
                <>
                    <audio
                        preload="auto"
                        id="audio_novo_atendimento"
                        src={audios?.audio_novo_atendimento}
                        ref={AudioNewAttendanceRef}
                        autoPlay={false}
                    >
                        <source src={audios?.audio_novo_atendimento} />
                    </audio>
                </>
            )}
            {!idAdmin && (
                <Header>
                    <ButtonOperator
                        title="Atendimentos"
                        selected={typeAttendence === 'Atendimentos'}
                        onClick={() => setTypeAttendence('Atendimentos')}
                    />

                    <ButtonOperator
                        title="Dashboard"
                        selected={typeAttendence === 'Dashboard'}
                        onClick={() => setTypeAttendence('Dashboard')}
                    />

                    {/** 
                    <ButtonOperator
                        title={isClickPlay ? 'Som ativado' : 'Som desativado'}
                        selected={isClickPlay === true}
                        onClick={() => startPlayer()}
                    />
                */}

                    {/* 
                    <h1>Multiplication Of large array</h1>
                    <h2>Result: {result}</h2> 
                    */}

                    <Profile>
                        <InputSwitchDefault
                            options={[
                                {
                                    label: theme.title,
                                    value: 'dark',
                                },
                            ]}
                            value={toggleTheme}
                            onChange={(value) => handleOnTheme(value)}
                        />

                        <div className="image" style={{ backgroundImage: `url(${user.foto})` }} />

                        <Perfil>
                            <h2>{user.perfil?.nome}</h2>
                            <span className={`${operador.diarioOperador.status}`}>
                                {operador.diarioOperador.status}
                            </span>
                        </Perfil>
                    </Profile>

                    <ButtonSignInOut>
                        <SignInOut
                            onClick={() =>
                                addConfirm({
                                    title: 'Finalizar o dia',
                                    description: 'Deseja finalizar o seu dia!',
                                    hiddenButtonCancel: false,
                                    textButtonConfirm: 'Finalizar',
                                    onConfirm: () => setLogout(),
                                })
                            }
                        >
                            <IconPower color="#FF868B" size="28" />
                        </SignInOut>
                    </ButtonSignInOut>
                </Header>
            )}
            <Main>
                {typeAttendence === 'Atendimentos' && (
                    <>
                        <Panel>
                            <PanelLeft>
                                <BgLeft style={props.idAdmin && { height: 'calc(100vh - 150px)' }}>
                                    <HeaderMessage>
                                        <ButtonLinear
                                            onClick={() => setModalNewAttendance(true)}
                                            className="info"
                                            Icon={IconUserPlus}
                                        >
                                            Novo atendimento
                                        </ButtonLinear>
                                    </HeaderMessage>

                                    <InputSearch
                                        handleOnClearFilterUser={handleOnClearFilterUser}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        searchTerm={searchTerm}
                                        value={searchTerm}
                                    />

                                    <Scrollbars style={{ height: '100%' }}>
                                        {verifyAtt.length > 0 && (
                                            <SectionMessages>
                                                {verifyAtt.map((row: any, key: any) => (
                                                    <CardMessage
                                                        onClick={() => iniciarAtendimento(row)}
                                                        key={key}
                                                        newMessage={row.new}
                                                        selected={
                                                            atendimento.id_atendimento === row.id_atendimento
                                                                ? true
                                                                : false
                                                        }
                                                        name={
                                                            row.informacao_entrada &&
                                                            row.informacao_entrada['Nome completo']
                                                                ? row.informacao_entrada['Nome completo']
                                                                : row.protocolo
                                                        }
                                                        message={row.ultima_mensagem?.mensagem}
                                                        data={moment(row.data_ultima_mensagem)
                                                            .utc()
                                                            .format('DD/MM/YYYY HH:mm')}
                                                        hasNotification={row.mensagens_nao_lidas}
                                                        panel={'Atendimentos'}
                                                        typeMessage={row.id_whatsapp ? 'whatsapp' : 'web'}
                                                    />
                                                ))}
                                            </SectionMessages>
                                        )}
                                    </Scrollbars>
                                </BgLeft>

                                {!props.idAdmin && (
                                    <FooterMessage>
                                        <div className="popUpServer">
                                            <ButtonLinear
                                                onClick={openPopUp}
                                                className="info"
                                                Icon={IconUserChat}
                                                radius
                                                tooltip="Chat com operadores"
                                            >
                                                {chatInternalMessages.operadores > 0 && (
                                                    <span className="totalmessageinternal">
                                                        {chatInternalMessages.operadores}
                                                    </span>
                                                )}
                                            </ButtonLinear>
                                            <PopUpAttendence
                                                showPopUp={popUpAttendence}
                                                setShowPopUp={setPopUpAttendence}
                                                name="Operadores"
                                                handleMessagesChatInternal={handleMessagesChatInternal}
                                                token={token}
                                                tokenInicial={tokenInicial}
                                            />
                                        </div>

                                        <div className="popUpGroup">
                                            <ButtonLinear
                                                onClick={openPopUpGroup}
                                                className="danger"
                                                Icon={IconChatSquare}
                                                radius
                                                tooltip="Chat com grupos de atendimento"
                                            >
                                                {chatInternalMessages.grupos > 0 && (
                                                    <span className="totalmessageinternal">
                                                        {chatInternalMessages.grupos}
                                                    </span>
                                                )}
                                            </ButtonLinear>
                                            <PopUpAttendence
                                                showPopUp={popUpGroup}
                                                setShowPopUp={setPopUpGroup}
                                                name="Grupos"
                                                handleMessagesChatInternal={handleMessagesChatInternal}
                                                token={token}
                                                tokenInicial={tokenInicial}
                                            />
                                        </div>

                                        <div className="popUpHours">
                                            <ButtonLinear
                                                onClick={openPopUpHours}
                                                className="delete"
                                                Icon={IconClock}
                                                tooltip="Gerenciar pausa"
                                                radius
                                            />
                                            <PopUpHours
                                                showPopUp={popUpHours}
                                                setShowPopUp={setPopUpHours}
                                                operador={operador}
                                            />
                                        </div>
                                    </FooterMessage>
                                )}
                            </PanelLeft>

                            <PanelCenter>
                                {atendimento.protocolo && (
                                    <>
                                        <PanelCenterHeader>
                                            <div className="title">
                                                <div className="titleLeft">
                                                    <h1>
                                                        {atendimento.informacao_entrada
                                                            ? atendimento.informacao_entrada['Nome completo']
                                                            : atendimento.protocolo}
                                                    </h1>
                                                    {atendimento.id_whatsapp ? (
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            aria-hidden="true"
                                                            role="img"
                                                            width="1em"
                                                            height="1em"
                                                            preserveAspectRatio="xMidYMid meet"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                fillRule="evenodd"
                                                                d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977c0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162l-2.824.741l.753-2.753l-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188a7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73c-.205-.075-.354-.112-.504.112s-.58.729-.711.879s-.262.168-.486.056s-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393c.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666c-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008a.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387c.536.231.954.369 1.279.473c.537.171 1.026.146 1.413.089c.431-.064 1.327-.542 1.514-1.066c.187-.524.187-.973.131-1.067c-.056-.094-.207-.151-.43-.263"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                    ) : isConnection(atendimento.id_usuario) === true ? (
                                                        <span className="status">Online</span>
                                                    ) : (
                                                        <span className="status offline">Offline</span>
                                                    )}
                                                </div>

                                                <div className="sectionHistory">
                                                    <ButtonLinear
                                                        className="info"
                                                        onClick={() => handleHistory(atendimento)}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            aria-hidden="true"
                                                            role="img"
                                                            width="1em"
                                                            height="1em"
                                                            preserveAspectRatio="xMidYMid meet"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <g fill="currentColor">
                                                                <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                                                                <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
                                                                <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
                                                            </g>
                                                        </svg>
                                                        Histórico
                                                    </ButtonLinear>
                                                </div>
                                            </div>

                                            <div className="description">
                                                <h2>Protocolo:</h2>
                                                <span>{atendimento.protocolo}</span>

                                                <h2>Grupo de Atendimento:</h2>
                                                <span>{atendimento.grupo_atendimento}</span>
                                            </div>

                                            <div className="actions">
                                                <button onClick={() => setModalMensagemPreDefinida(true)}>
                                                    Mensagens <IconMessage />
                                                </button>

                                                <button onClick={() => setModalTypeTransferir(true)}>
                                                    Transferir <IconArrowLeft />
                                                </button>

                                                <button onClick={() => setModalBloquear(true)}>
                                                    Bloquear <IconBlock />
                                                </button>

                                                <button className="danger" onClick={() => setModalFinalizar(true)}>
                                                    Finalizar <IconPower />
                                                </button>
                                            </div>
                                        </PanelCenterHeader>

                                        <ContentFunil ref={refFunil}>
                                            {funilEtapas.map((row, key) => (
                                                <li
                                                    style={{ zIndex: key * -1 }}
                                                    className={`${row.active ? 'active' : null}`}
                                                    onClick={() => handleFunilEtapa(row)}
                                                >
                                                    {row.etapa}
                                                </li>
                                            ))}

                                            {funilEtapas.length === 0 && <li className="active">Em atendimento</li>}
                                        </ContentFunil>

                                        <CardChat
                                            messagePreLoad={messagePreLoad}
                                            setMessagePreLoad={setMessagePreLoad}
                                            atendimento={atendimento}
                                            messages={mensagens}
                                            onSubmit={enviarMensagem}
                                            load={loadAtendimento}
                                            height={props.idAdmin ? 'calc(100vh - 285px)' : 'calc(100vh - 260px)'}
                                            heightFunil={refFunil.current?.clientHeight}
                                        >
                                            {modalHistorico === true && (
                                                <div id="modalHistorico">
                                                    <div className="history-container">
                                                        <div className="header">
                                                            <h2>Histórico</h2>
                                                            <button onClick={() => setModalHistorico(false)}>
                                                                <IconClose />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="messages">
                                                        <div className="history-container">
                                                            {historicoLoad === true && <Loading active={true} />}
                                                            {historicoLoad === false &&
                                                                historicoMessages.length > 0 &&
                                                                historicoMessages.map((mensagem: any, key) => (
                                                                    <>
                                                                        {mensagem.tipo === 'alerta' ? (
                                                                            <div className="transferMessage">
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    aria-hidden="true"
                                                                                    role="img"
                                                                                    width="1em"
                                                                                    height="1em"
                                                                                    preserveAspectRatio="xMidYMid meet"
                                                                                    viewBox="0 0 24 24"
                                                                                >
                                                                                    <g
                                                                                        fill="none"
                                                                                        stroke="currentColor"
                                                                                        stroke-width="2"
                                                                                    >
                                                                                        <circle
                                                                                            cx="12"
                                                                                            cy="12"
                                                                                            r="10"
                                                                                        />
                                                                                        <path
                                                                                            stroke-linecap="round"
                                                                                            d="M12 7h.01"
                                                                                        />
                                                                                        <path
                                                                                            stroke-linecap="round"
                                                                                            strokeLinejoin="round"
                                                                                            d="M10 11h2v5m-2 0h4"
                                                                                        />
                                                                                    </g>
                                                                                </svg>
                                                                                <div>
                                                                                    <p>{mensagem.mensagem}</p>
                                                                                    <span>
                                                                                        {moment(mensagem.criado)
                                                                                            .utc()
                                                                                            .format('DD/MM/YYYY HH:mm')}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            mensagem.mensagem !==
                                                                                'hiddenInitialMessage' && (
                                                                                <CardMessageRender
                                                                                    key={key}
                                                                                    data={mensagem}
                                                                                    renderName={true}
                                                                                    handleQuote={handleQuote}
                                                                                    name={
                                                                                        mensagem.id_fluxograma
                                                                                            ? mensagem.chatbot
                                                                                            : mensagem.apelido
                                                                                    }
                                                                                    messageType={
                                                                                        mensagem.id_operador
                                                                                            ? 'recebida'
                                                                                            : 'enviada'
                                                                                    }
                                                                                    canalMessage={
                                                                                        atendimento.id_whatsapp
                                                                                            ? 'whatsApp'
                                                                                            : 'web'
                                                                                    }
                                                                                />
                                                                            )
                                                                        )}
                                                                    </>
                                                                ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {mensagens.map((mensagem: any, key) => (
                                                <>
                                                    {mensagem.tipo === 'alerta' ? (
                                                        <div className="transferMessage">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                aria-hidden="true"
                                                                role="img"
                                                                width="1em"
                                                                height="1em"
                                                                preserveAspectRatio="xMidYMid meet"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <g fill="none" stroke="currentColor" stroke-width="2">
                                                                    <circle cx="12" cy="12" r="10" />
                                                                    <path stroke-linecap="round" d="M12 7h.01" />
                                                                    <path
                                                                        stroke-linecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M10 11h2v5m-2 0h4"
                                                                    />
                                                                </g>
                                                            </svg>
                                                            <div>
                                                                <p>{mensagem.mensagem}</p>
                                                                <span>
                                                                    {moment(mensagem.criado)
                                                                        .utc()
                                                                        .format('DD/MM/YYYY HH:mm')}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        mensagem.mensagem !== 'hiddenInitialMessage' && (
                                                            <CardMessageRender
                                                                key={key}
                                                                data={mensagem}
                                                                renderName={true}
                                                                handleQuote={handleQuote}
                                                                name={
                                                                    mensagem.id_fluxograma
                                                                        ? mensagem.chatbot
                                                                        : mensagem.apelido
                                                                }
                                                                messageType={
                                                                    mensagem.id_operador ? 'recebida' : 'enviada'
                                                                }
                                                                canalMessage={
                                                                    atendimento.id_whatsapp ? 'whatsApp' : 'web'
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </>
                                            ))}

                                            {finishAdmin.data_saida && (
                                                <div className="finishInfo">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        aria-hidden="true"
                                                        role="img"
                                                        width="1em"
                                                        height="1em"
                                                        preserveAspectRatio="xMidYMid meet"
                                                        viewBox="0 0 32 32"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M12 2C8.145 2 5 5.145 5 9c0 2.41 1.23 4.55 3.094 5.813C4.527 16.343 2 19.883 2 24h2c0-4.43 3.57-8 8-8c1.375 0 2.656.36 3.781.969A8.007 8.007 0 0 0 14 22c0 4.406 3.594 8 8 8c4.406 0 8-3.594 8-8c0-4.406-3.594-8-8-8a7.964 7.964 0 0 0-4.688 1.531a10.134 10.134 0 0 0-1.406-.719A7.024 7.024 0 0 0 19 9c0-3.855-3.145-7-7-7zm0 2c2.773 0 5 2.227 5 5s-2.227 5-5 5s-5-2.227-5-5s2.227-5 5-5zm10 12c3.324 0 6 2.676 6 6s-2.676 6-6 6s-6-2.676-6-6s2.676-6 6-6zm-2.281 2.281L18.28 19.72L20.563 22l-2.282 2.281l1.438 1.438L22 23.437l2.281 2.282l1.438-1.438L23.437 22l2.282-2.281l-1.438-1.438L22 20.563z"
                                                        />
                                                    </svg>
                                                    <ul>
                                                        <li>
                                                            Finalizado por <b>{finishAdmin.finalizado_por}</b>
                                                        </li>
                                                        {finishAdmin.descricao && (
                                                            <li>
                                                                Motivo: <b>{finishAdmin.descricao}</b>
                                                            </li>
                                                        )}
                                                        <li>
                                                            Preencheu a pesquisa de satisfação:{' '}
                                                            <b>{finishAdmin.id_pesquisa_satisfacao ? 'Sim' : 'Não'}</b>
                                                        </li>
                                                        <li>
                                                            Data:{' '}
                                                            <b>
                                                                {moment(finishAdmin.data_saida)
                                                                    .utc()
                                                                    .format('DD/MM/YYYY HH:mm')}
                                                            </b>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                        </CardChat>
                                    </>
                                )}

                                {loading === false && !atendimento.protocolo && (
                                    <>
                                        <PanelCenterNoContent>Nenhum atendimento encontrado.</PanelCenterNoContent>
                                    </>
                                )}

                                {loading && !atendimento && (
                                    <>
                                        <PanelCenterNoContent>Aguardando atendimentos...</PanelCenterNoContent>
                                    </>
                                )}
                            </PanelCenter>

                            {atendimento.protocolo && (
                                <PanelRight style={props.idAdmin && { height: 'calc(100vh - 159px)' }}>
                                    <ClientData
                                        token={token}
                                        tokenInicial={tokenInicial}
                                        setAtendimento={handleAtendimentoUpdate}
                                        atendimento={atendimento}
                                    />
                                </PanelRight>
                            )}
                        </Panel>
                    </>
                )}

                {typeAttendence === 'Dashboard' && (
                    <>
                        <SectionDashOperator>
                            <ContentDahsFilter>
                                <SectionDashFilterSelect style={{ width: 600 }}>
                                    <label style={{ color: '#fff' }}>
                                        <InputDefault
                                            title="De"
                                            placeholder="de"
                                            onChangeText={(value) => setDadosFilter('dataInicio', value)}
                                            value={filter.dataInicio}
                                            type={'date'}
                                        />
                                    </label>
                                    <label style={{ color: '#fff' }}>
                                        <InputDefault
                                            title="Até"
                                            placeholder="ate"
                                            onChangeText={(value) => setDadosFilter('dataFim', value)}
                                            value={filter.dataFim}
                                            type={'date'}
                                        />
                                    </label>
                                    <label style={{ color: '#fff', whiteSpace: 'nowrap' }}>
                                        <InputDefault
                                            title="Nivel de serviço (Segundos)"
                                            placeholder="60"
                                            onChangeText={(value) => setDadosFilter('nivelDeServicoSegundos', value)}
                                            value={filter.nivelDeServicoSegundos}
                                            type={'number'}
                                        />
                                    </label>
                                    <label style={{ color: '#fff' }}>
                                        <SelectDefault
                                            value={filter.id_grupo_atendimento}
                                            placeholder="Grupos de Atendimento"
                                            onChangeText={(value) => setDadosFilter('id_grupo_atendimento', value)}
                                        >
                                            {gruposAtendimentoAll.map((row, key) => (
                                                <option value={row.id_grupo_atendimento} key={key}>
                                                    {row.Nome_Grupo_Atendimento}
                                                </option>
                                            ))}
                                        </SelectDefault>
                                    </label>
                                    <label style={{ color: '#fff' }}>
                                        <SelectDefault
                                            value={filter.id_grupo_atendimento}
                                            placeholder="Grupos por canal"
                                            onChangeText={(value) => setDadosFilter('canal', value)}
                                        >
                                            <option value={'whatsapp'}>WhatsApp</option>
                                            <option value={'web'}>Web</option>
                                        </SelectDefault>
                                    </label>
                                    <label style={{ color: '#fff' }}>
                                        <ButtonLinear className="info" onClick={getDataDashboard}>
                                            Aplicar
                                        </ButtonLinear>
                                    </label>
                                </SectionDashFilterSelect>
                            </ContentDahsFilter>

                            <SectionDashCards>
                                <CardContainer className="notMargin">
                                    <CardTotalIcon
                                        data={{
                                            color: '#04FDD0',
                                            icon: (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    aria-hidden="true"
                                                    role="img"
                                                    width="1em"
                                                    height="1em"
                                                    preserveAspectRatio="xMidYMid meet"
                                                    viewBox="0 0 28 28"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M14.302 2.104c.24-.608.936-1.235 1.82-.988C17.9 1.61 18.574 3.5 18.759 5.27c.157 1.513-.002 3.227-.347 4.73h2.167a3.75 3.75 0 0 1 3.675 4.497l-1.228 6.046a6.75 6.75 0 0 1-8.47 5.146l-7.69-2.197a3.75 3.75 0 0 1-2.668-2.989l-.416-2.496c-.22-1.321.547-2.528 1.648-3.086c4.775-2.417 6.606-7.066 8.612-12.158l.26-.66Zm1.432.487a.245.245 0 0 0-.037.064l-.316.802c-1.947 4.957-3.975 10.12-9.273 12.802c-.613.31-.942.927-.846 1.501l.416 2.496a2.25 2.25 0 0 0 1.6 1.794l7.69 2.197a5.25 5.25 0 0 0 6.588-4.003l1.228-6.046a2.25 2.25 0 0 0-2.205-2.698h-3.142a.75.75 0 0 1-.717-.968c.468-1.541.715-3.489.547-5.106c-.174-1.673-.737-2.616-1.513-2.855a.181.181 0 0 0-.02.02Z"
                                                    />
                                                </svg>
                                            ),
                                            name: 'Nível de serviço',
                                            value: dashboard.nivelServico ?? 0,
                                            info: 'Total de Atendimentos que esperaram x segundos / total de chamadas transferidas para operador',
                                        }}
                                    />
                                </CardContainer>

                                <CardContainer className="notMargin">
                                    <CardTotalIcon
                                        data={{
                                            color: '#CB3D9A',
                                            icon: <IconClock />,
                                            name: 'Tempo médio de espera',
                                            value: dashboard.tempMedioEspera ?? 0,
                                            info: 'Total tempo de espera / Qtde atendimento',
                                        }}
                                    />
                                </CardContainer>

                                <CardContainer className="notMargin">
                                    <CardTotalIcon
                                        data={{
                                            color: '#04FDD0',
                                            icon: (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    aria-hidden="true"
                                                    role="img"
                                                    width="1em"
                                                    height="1em"
                                                    preserveAspectRatio="xMidYMid meet"
                                                    viewBox="0 0 20 20"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M6.5 7.5a2.25 2.25 0 1 0 0-4.5a2.25 2.25 0 0 0 0 4.5Zm0-1a1.25 1.25 0 1 1 0-2.5a1.25 1.25 0 0 1 0 2.5ZM3 9a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v4.5a3.5 3.5 0 1 1-7 0V9Zm1 0v4.5a2.5 2.5 0 0 0 5 0V9H4Zm5.626-2.857a3.234 3.234 0 0 1-.396.87c.413.048.787.22 1.084.48a2.25 2.25 0 1 0-1.127-4.07c.193.282.342.597.439.934a1.25 1.25 0 1 1 0 1.787Zm-.127 10.712A3.5 3.5 0 0 0 14 13.5V9a1 1 0 0 0-1-1h-2.267c.17.294.268.636.268 1h2v4.5a2.5 2.5 0 0 1-2.75 2.488c-.213.32-.465.611-.75.867Zm4.127-10.712a3.236 3.236 0 0 1-.396.87c.413.048.787.22 1.084.48a2.25 2.25 0 1 0-1.127-4.07c.193.282.342.597.439.934a1.25 1.25 0 1 1 0 1.787Zm-.127 10.712A3.5 3.5 0 0 0 18 13.5V9a1 1 0 0 0-1-1h-2.267c.17.294.268.636.268 1h2v4.5a2.5 2.5 0 0 1-2.75 2.488c-.213.32-.465.611-.75.867Z"
                                                    />
                                                </svg>
                                            ),
                                            name: 'Tempo médio de atendimento',
                                            value: dashboard.tempMedioAtendimento ?? 0,
                                            info: 'Total tempo no atendimento / Qtde atendimentos',
                                        }}
                                    />
                                </CardContainer>

                                <CardContainer className="notMargin">
                                    <CardTotalIcon
                                        data={{
                                            color: '#CB3D9A',
                                            icon: (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    aria-hidden="true"
                                                    role="img"
                                                    width="1em"
                                                    height="1em"
                                                    preserveAspectRatio="xMidYMid meet"
                                                    viewBox="0 0 20 20"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M6.5 7.5a2.25 2.25 0 1 0 0-4.5a2.25 2.25 0 0 0 0 4.5Zm0-1a1.25 1.25 0 1 1 0-2.5a1.25 1.25 0 0 1 0 2.5ZM3 9a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v4.5a3.5 3.5 0 1 1-7 0V9Zm1 0v4.5a2.5 2.5 0 0 0 5 0V9H4Zm5.626-2.857a3.234 3.234 0 0 1-.396.87c.413.048.787.22 1.084.48a2.25 2.25 0 1 0-1.127-4.07c.193.282.342.597.439.934a1.25 1.25 0 1 1 0 1.787Zm-.127 10.712A3.5 3.5 0 0 0 14 13.5V9a1 1 0 0 0-1-1h-2.267c.17.294.268.636.268 1h2v4.5a2.5 2.5 0 0 1-2.75 2.488c-.213.32-.465.611-.75.867Zm4.127-10.712a3.236 3.236 0 0 1-.396.87c.413.048.787.22 1.084.48a2.25 2.25 0 1 0-1.127-4.07c.193.282.342.597.439.934a1.25 1.25 0 1 1 0 1.787Zm-.127 10.712A3.5 3.5 0 0 0 18 13.5V9a1 1 0 0 0-1-1h-2.267c.17.294.268.636.268 1h2v4.5a2.5 2.5 0 0 1-2.75 2.488c-.213.32-.465.611-.75.867Z"
                                                    />
                                                </svg>
                                            ),
                                            name: 'Total fila',
                                            value: dashboard.totalEmFila ?? 0,
                                            // info: 'Total de chamadas atendidas / Total de chamadas recebidas'
                                        }}
                                    />
                                </CardContainer>

                                <CardContainer className="notMargin">
                                    <CardTotalIcon
                                        data={{
                                            color: '#04FDD0',
                                            icon: (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    aria-hidden="true"
                                                    role="img"
                                                    width="1em"
                                                    height="1em"
                                                    preserveAspectRatio="xMidYMid meet"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M10.578 10c.016.133.034.285.05.45c.074.74.131 1.793-.013 2.677c-.072.44-.201.887-.442 1.237c-.257.372-.647.635-1.173.635c-.516 0-.83-.37-1.024-.69c-.192-.321-.36-.756-.533-1.201l-.013-.034c-.547-1.41-1.295-3.317-3.307-4.658a4.979 4.979 0 0 0-.853-.458c-.697-.289-1.322-1.057-1.157-1.936l.224-1.196a2 2 0 0 1 1.43-1.558l4.95-1.375a3.5 3.5 0 0 1 4.377 2.727l.454 2.419a2.5 2.5 0 0 1-2.458 2.96h-.512Zm1.533-5.196a2.5 2.5 0 0 0-3.126-1.948l-4.95 1.375a1 1 0 0 0-.716.78l-.224 1.195c-.053.283.162.664.558.828c.31.129.659.306 1.025.55c2.287 1.524 3.128 3.696 3.676 5.108l.008.02c.19.49.329.844.472 1.082c.069.115.12.17.151.195a.11.11 0 0 0 .016.01c.142 0 .245-.054.349-.203c.118-.173.216-.451.278-.83c.122-.75.077-1.697.005-2.417a16.374 16.374 0 0 0-.114-.897l-.009-.05l-.002-.013v-.003A.5.5 0 0 1 10 9h1.09a1.5 1.5 0 0 0 1.475-1.777l-.454-2.419Z"
                                                    />
                                                </svg>
                                            ),
                                            name: 'Total abandono',
                                            value: dashboard.totalAbabdono ?? 0,
                                        }}
                                    />
                                </CardContainer>

                                <CardContainer className="notMargin">
                                    <CardTotalIcon
                                        data={{
                                            color: '#CB3D9A',
                                            icon: (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    aria-hidden="true"
                                                    role="img"
                                                    width="1em"
                                                    height="1em"
                                                    preserveAspectRatio="xMidYMid meet"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M10.578 10c.016.133.034.285.05.45c.074.74.131 1.793-.013 2.677c-.072.44-.201.887-.442 1.237c-.257.372-.647.635-1.173.635c-.516 0-.83-.37-1.024-.69c-.192-.321-.36-.756-.533-1.201l-.013-.034c-.547-1.41-1.295-3.317-3.307-4.658a4.979 4.979 0 0 0-.853-.458c-.697-.289-1.322-1.057-1.157-1.936l.224-1.196a2 2 0 0 1 1.43-1.558l4.95-1.375a3.5 3.5 0 0 1 4.377 2.727l.454 2.419a2.5 2.5 0 0 1-2.458 2.96h-.512Zm1.533-5.196a2.5 2.5 0 0 0-3.126-1.948l-4.95 1.375a1 1 0 0 0-.716.78l-.224 1.195c-.053.283.162.664.558.828c.31.129.659.306 1.025.55c2.287 1.524 3.128 3.696 3.676 5.108l.008.02c.19.49.329.844.472 1.082c.069.115.12.17.151.195a.11.11 0 0 0 .016.01c.142 0 .245-.054.349-.203c.118-.173.216-.451.278-.83c.122-.75.077-1.697.005-2.417a16.374 16.374 0 0 0-.114-.897l-.009-.05l-.002-.013v-.003A.5.5 0 0 1 10 9h1.09a1.5 1.5 0 0 0 1.475-1.777l-.454-2.419Z"
                                                    />
                                                </svg>
                                            ),
                                            name: 'Percentual de abandono',
                                            value: dashboard.percentAbandono ?? 0,
                                        }}
                                    />
                                </CardContainer>

                                <CardContainer className="notMargin">
                                    <CardTotalIcon
                                        data={{
                                            color: '#04FDD0',
                                            icon: (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    aria-hidden="true"
                                                    role="img"
                                                    width="1em"
                                                    height="1em"
                                                    preserveAspectRatio="xMidYMid meet"
                                                    viewBox="0 0 28 28"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M14.302 2.104c.24-.608.936-1.235 1.82-.988C17.9 1.61 18.574 3.5 18.759 5.27c.157 1.513-.002 3.227-.347 4.73h2.167a3.75 3.75 0 0 1 3.675 4.497l-1.228 6.046a6.75 6.75 0 0 1-8.47 5.146l-7.69-2.197a3.75 3.75 0 0 1-2.668-2.989l-.416-2.496c-.22-1.321.547-2.528 1.648-3.086c4.775-2.417 6.606-7.066 8.612-12.158l.26-.66Zm1.432.487a.245.245 0 0 0-.037.064l-.316.802c-1.947 4.957-3.975 10.12-9.273 12.802c-.613.31-.942.927-.846 1.501l.416 2.496a2.25 2.25 0 0 0 1.6 1.794l7.69 2.197a5.25 5.25 0 0 0 6.588-4.003l1.228-6.046a2.25 2.25 0 0 0-2.205-2.698h-3.142a.75.75 0 0 1-.717-.968c.468-1.541.715-3.489.547-5.106c-.174-1.673-.737-2.616-1.513-2.855a.181.181 0 0 0-.02.02Z"
                                                    />
                                                </svg>
                                            ),
                                            name: 'Total Finalizados + Abandonados',
                                            value: dashboard.totalFinalizados ?? 0,
                                        }}
                                    />
                                </CardContainer>

                                <CardContainer className="notMargin">
                                    <CardTotalIcon
                                        data={{
                                            color: '#CB3D9A',
                                            icon: <IconPause />,
                                            name: 'Pausas',
                                            value: dashboard.pausas ?? 0,
                                        }}
                                    />
                                </CardContainer>

                                <CardContainer className="notMargin">
                                    <CardTotalIcon
                                        data={{
                                            color: '#04FDD0',
                                            icon: (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    aria-hidden="true"
                                                    role="img"
                                                    width="1em"
                                                    height="1em"
                                                    preserveAspectRatio="xMidYMid meet"
                                                    viewBox="0 0 28 28"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M14.302 2.104c.24-.608.936-1.235 1.82-.988C17.9 1.61 18.574 3.5 18.759 5.27c.157 1.513-.002 3.227-.347 4.73h2.167a3.75 3.75 0 0 1 3.675 4.497l-1.228 6.046a6.75 6.75 0 0 1-8.47 5.146l-7.69-2.197a3.75 3.75 0 0 1-2.668-2.989l-.416-2.496c-.22-1.321.547-2.528 1.648-3.086c4.775-2.417 6.606-7.066 8.612-12.158l.26-.66Zm1.432.487a.245.245 0 0 0-.037.064l-.316.802c-1.947 4.957-3.975 10.12-9.273 12.802c-.613.31-.942.927-.846 1.501l.416 2.496a2.25 2.25 0 0 0 1.6 1.794l7.69 2.197a5.25 5.25 0 0 0 6.588-4.003l1.228-6.046a2.25 2.25 0 0 0-2.205-2.698h-3.142a.75.75 0 0 1-.717-.968c.468-1.541.715-3.489.547-5.106c-.174-1.673-.737-2.616-1.513-2.855a.181.181 0 0 0-.02.02Z"
                                                    />
                                                </svg>
                                            ),
                                            name: 'Atraso',
                                            value: dashboard.totalTempoAtraso ?? 0,
                                        }}
                                    />
                                </CardContainer>
                            </SectionDashCards>
                        </SectionDashOperator>
                    </>
                )}
            </Main>
            <ModalDefault
                id="newAttendance"
                onClose={() => setModalNewAttendance(!modalNewAttendance)}
                openModal={modalNewAttendance}
            >
                <TitleModal>
                    <h2>Novo atendimento</h2>
                    <span>
                        O cliente vai receber um SMS ou uma mensagem de WhatsApp com um link para o atendimento.
                    </span>
                </TitleModal>

                <Field gap="8px" marginTop={true}>
                    <SelectDefault
                        placeholder="Selecione um grupo de atendimento"
                        value={DTONewAttendance.id_grupo_atendimento}
                        onChangeText={(value) => onChangeDTONewAttendance('id_grupo_atendimento', value)}
                    >
                        {gruposAtendimentoAll.map((row, key) => (
                            <option key={key} value={row.id_grupo_atendimento}>
                                {row.Nome_Grupo_Atendimento}
                            </option>
                        ))}
                    </SelectDefault>

                    {groupChannel.length > 0 && (
                        <SelectDefault
                            placeholder="Selecione um canal"
                            value={DTONewAttendance.id_canal_atendimento}
                            onChangeText={(value) => onChangeHsm('id_canal_atendimento', value)}
                        >
                            {groupChannel
                                .filter((obj) => obj.canal === 'Whatsapp' || obj.canal === 'SMS')
                                .map((row, key) => (
                                    <option
                                        key={key}
                                        value={row.id_grupo_canal_atendimento}
                                        // disabled={row.configuracao_grupo.length <= 1}
                                    >
                                        {row.canal}
                                    </option>
                                ))}
                        </SelectDefault>
                    )}

                    {templateHsm.length > 0 && DTONewAttendance.type !== 'SMS' && (
                        <SelectDefault
                            placeholder="Selecione Mensagem"
                            value={DTONewAttendance.template}
                            onChangeText={(value) => onChangeTemplate('hsm', value)}
                        >
                            {templateHsm
                                .filter((obj) => obj.status === 'APPROVED')
                                .map((row, key) => (
                                    <option key={key} value={row.id}>
                                        {row.data}
                                    </option>
                                ))}
                        </SelectDefault>
                    )}

                    {groupChannel.length > 0 && DTONewAttendance.type === 'SMS' && (
                        <>
                            <InputDefault
                                mask="(99) 9 9999-9999"
                                placeholder="Celular"
                                value={DTONewAttendance.phone}
                                onChangeText={(value) => onChangeDTONewAttendance('phone', value)}
                            />
                            <InputDefault
                                placeholder="Nome"
                                value={DTONewAttendance.name}
                                onChangeText={(value) => onChangeDTONewAttendance('name', value)}
                            />
                        </>
                    )}

                    {DTONewAttendance.hsm !== '' && DTONewAttendance.type !== 'SMS' && (
                        <>
                            <InputDefault
                                mask="(99) 9 9999-9999"
                                placeholder="Celular"
                                value={DTONewAttendance.phone}
                                onChangeText={(value) => onChangeDTONewAttendance('phone', value)}
                            />
                            <InputDefault
                                placeholder="Nome"
                                value={DTONewAttendance.name}
                                onChangeText={(value) => onChangeDTONewAttendance('name', value)}
                            />
                        </>
                    )}

                    <ButtonLinear
                        type="button"
                        loading={loading}
                        disabled={!DTONewAttendance.type === true}
                        className="success"
                        onClick={() => handleNewAttendance()}
                    >
                        Iniciar
                    </ButtonLinear>

                    <ButtonLinear type="button" className="transparent" onClick={() => setModalNewAttendance(false)}>
                        Cancelar
                    </ButtonLinear>
                </Field>
            </ModalDefault>

            <ModalDefault
                id="modalMensagemPreDefinida"
                onClose={() => setModalMensagemPreDefinida(false)}
                openModal={modalMensagemPreDefinida}
            >
                <TitleModal>
                    <h2>Mensagens Pré-Definidas</h2>
                    <span>Clique em uma mensagem para enviá-la</span>
                </TitleModal>

                <Field gap="8px" marginTop={true}>
                    <ContentMessage>
                        <Scrollbars>
                            {mensagensPreDefinidas &&
                                mensagensPreDefinidas.length > 0 &&
                                mensagensPreDefinidas.map((row, key) => (
                                    <ButtonMessage
                                        key={key}
                                        onClick={(e: any) => handleMensagemPreDefinida(row.mensagem)}
                                        dangerouslySetInnerHTML={{
                                            __html: `${row.mensagem?.replaceAll('\n', '</br>')}`,
                                        }}
                                    />
                                ))}
                        </Scrollbars>
                    </ContentMessage>

                    <ButtonLinear className="transparent" onClick={() => setModalMensagemPreDefinida(false)}>
                        Cancelar
                    </ButtonLinear>
                </Field>
            </ModalDefault>
            <ModalDefault
                id="modalTypeTransferir"
                onClose={() => setModalTypeTransferir(false)}
                openModal={modalTypeTransferir}
            >
                <TitleModal>
                    <h2>Selecione o tipo de transferencia</h2>
                    <span></span>
                </TitleModal>

                <Field gap="8px" marginTop={true}>
                    <ButtonLinear className="info" onClick={() => handleOnTypeTransfer('Grupos')}>
                        Grupos
                    </ButtonLinear>

                    <ButtonLinear className="info" onClick={() => handleOnTypeTransfer('Operadores')}>
                        Operadores
                    </ButtonLinear>
                </Field>
            </ModalDefault>
            <ModalDefault id="modalTransferir" onClose={() => setModalTransferir(false)} openModal={modalTransferir}>
                <TitleModal>
                    <h2>Transferência</h2>
                    <span>Selecione um grupo de atendimento para transferir o atendimento:</span>
                </TitleModal>

                <Field gap="8px" marginTop={true}>
                    <InputTextarea
                        placeholder="Digite o motivo da transferência"
                        value={DTOTransferir.descricao}
                        onChangeText={(value) => onChangeDTOTransferir('descricao', value)}
                    />

                    <SelectDefault
                        placeholder="Selecione um grupo"
                        value={DTOTransferir.id_grupo_atendimento}
                        onChangeText={(value) => onChangeDTOTransferir('id_grupo_atendimento', value)}
                    >
                        {gruposDeAtendimento.map((row, key) => (
                            <>
                                {row.id_grupo_atendimento !== atendimento.id_grupo_atendimento && (
                                    <option key={key} value={row.id_grupo_atendimento}>
                                        {row.grupo_atendimento}
                                    </option>
                                )}
                            </>
                        ))}
                    </SelectDefault>

                    <ButtonLinear className="success" onClick={() => handleTransferir()}>
                        Transferir
                    </ButtonLinear>

                    <ButtonLinear className="transparent" onClick={() => setModalTransferir(false)}>
                        Cancelar
                    </ButtonLinear>
                </Field>
            </ModalDefault>
            <ModalDefault id="modalOperator" onClose={() => setModalOperator(false)} openModal={modalOperator}>
                <TitleModal>
                    <h2>Transferência</h2>
                    <span>Selecione um Operador para transferir o atendimento:</span>
                </TitleModal>

                <Field gap="8px" marginTop={true}>
                    <InputTextarea
                        placeholder="Digite o motivo da transferência"
                        value={DTOTransferir.descricao}
                        onChangeText={(value) => handleOnMessageOperatorTransfer('descricao', value)}
                    />

                    <SelectDefault
                        placeholder="Selecione um grupo"
                        value={DTOTransferirOperador.id_grupo_atendimento - DTOTransferirOperador.apelido}
                        onChangeText={(value) => onChangeDTOTransferirOperador([value])}
                    >
                        {grupoOperadores.map((row: any, key: any) => (
                            <>
                                <option key={key} value={[row.id_grupo_atendimento, row.id_operador]}>
                                    {row.apelido} - {row.grupo_atendimento}
                                </option>
                            </>
                        ))}
                    </SelectDefault>

                    <ButtonLinear className="success" onClick={() => handleOperadorTransferir()}>
                        Transferir
                    </ButtonLinear>

                    <ButtonLinear className="transparent" onClick={() => setModalOperator(false)}>
                        Cancelar
                    </ButtonLinear>
                </Field>
            </ModalDefault>
            <ModalDefault id="modalBloquear" onClose={() => setModalBloquear(false)} openModal={modalBloquear}>
                <TitleModal>
                    <h2>Bloquear</h2>
                    <span>
                        Você deseja realmente bloquear esse cliente?
                        <br />
                        Selecione abaixo o tipo de bloqueio e descreva o motivo:
                    </span>
                </TitleModal>

                <Field gap="8px" marginTop={true}>
                    <SelectDefault
                        value={DTOBloquear.chave}
                        onChangeText={(value) => onChangeDTOBloquear('chave', value)}
                    >
                        <option value="">Selecione o tipo de bloqueio</option>
                        <option value="IP">IP</option>
                        {atendimento.informacao_entrada?.['CPF'] && <option value="CPF">CPF</option>}
                        {atendimento.informacao_entrada?.['E-mail'] && <option value="E-mail">E-mail</option>}
                        {atendimento.informacao_entrada?.['Telefone'] && <option value="Telefone">Telefone</option>}
                        {atendimento.informacao_entrada?.['Celular'] && <option value="Celular">Celular</option>}
                    </SelectDefault>

                    {DTOBloquear.valor && DTOBloquear.chave === 'CPF' && (
                        <InputDefault
                            mask="999.999.999-99"
                            placeholder="Valor"
                            value={DTOBloquear.valor}
                            disabled={true}
                            onChangeText={(value) => onChangeDTOBloquear('valor', value)}
                        />
                    )}
                    {DTOBloquear.valor && DTOBloquear.chave === 'Celular' && (
                        <InputDefault
                            mask="(99) 99999-9999"
                            placeholder="Valor"
                            disabled={true}
                            value={DTOBloquear.valor}
                            onChangeText={(value) => onChangeDTOBloquear('valor', value)}
                        />
                    )}
                    {DTOBloquear.valor && DTOBloquear.chave === 'Telefone' && (
                        <InputDefault
                            mask="(99) 99999-9999"
                            placeholder="Valor"
                            disabled={true}
                            value={DTOBloquear.valor}
                            onChangeText={(value) => onChangeDTOBloquear('valor', value)}
                        />
                    )}

                    {DTOBloquear.valor &&
                        DTOBloquear.chave !== 'Telefone' &&
                        DTOBloquear.chave !== 'Celular' &&
                        DTOBloquear.chave !== 'CPF' && (
                            <InputDefault
                                placeholder="Valor"
                                disabled={true}
                                value={DTOBloquear.valor}
                                onChangeText={(value) => onChangeDTOBloquear('valor', value)}
                            />
                        )}

                    <InputTextarea
                        placeholder="Digite o motivo do bloqueio"
                        value={DTOBloquear.descricao}
                        onChangeText={(value) => onChangeDTOBloquear('descricao', value)}
                    />

                    <ButtonLinear className="success" onClick={() => handleBloquear()}>
                        Bloquear
                    </ButtonLinear>

                    <ButtonLinear className="transparent" onClick={() => setModalBloquear(false)}>
                        Cancelar
                    </ButtonLinear>
                </Field>
            </ModalDefault>
            <ModalDefault id="modalFinalizar" onClose={() => setModalFinalizar(false)} openModal={modalFinalizar}>
                <TitleModal>
                    <h2>Encerrar atendimento</h2>
                    <span>Deseja realmente encerrar esse atendimento?</span>
                </TitleModal>

                <Field gap="8px" marginTop={true}>
                    <InputTextarea
                        placeholder="Descrição do atendimento..."
                        value={DTOFinalizar.descricao}
                        onChangeText={(value) => onChangeDTOFinalizar('descricao', value)}
                    />

                    {!atendimento.id_whatsapp && (
                        <ButtonLinear className="info" onClick={() => handleFinalizar(true)}>
                            Encerrar com Pesquisa de satisfação
                        </ButtonLinear>
                    )}

                    <ButtonLinear className="delete" onClick={() => handleFinalizar(false)}>
                        Encerrar sem Pesquisa de satisfação
                    </ButtonLinear>

                    <ButtonLinear className="transparent" onClick={() => setModalFinalizar(false)}>
                        Cancelar
                    </ButtonLinear>
                </Field>
            </ModalDefault>
        </Container>
    );
}
