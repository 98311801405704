import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    box-sizing:border-box;
    padding: 30px;
    background-color: #FFF;
`

export const HeaderChat = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    h1 {
        font-size: 24px;
        font-weight: bold;
        color: #4A7DFF;
        margin-top: 20px;
    }

    p {
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #5A646E;
        margin-top: 16px;
    }

`;

export const LogoChat = styled.div`
    display: grid;
    place-items: center;
    background-position: center;
    background-size: cover;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-color: #cecece;
    position: relative;
`

export const MainChat = styled.form`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 16px;
    width: 100%;
    >button {
       margin-top: 10px;
    }
`;

