import styled, { css } from 'styled-components';

interface EmojiProps {
    active: boolean;
}

export const Container = styled.div<EmojiProps>`
    border-radius: 5px 5px 0px 0px;
    background: #f1f1f1;
    /* height: 60px; */
    position: absolute;
    top: -40px;
    border-bottom: 2px solid #eaeef3;
    left: 0px;
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    z-index: 11;
    visibility: hidden;
    opacity: 0;
    resize: auto;
    transform: 
        translate(0)
        scale(1);

    transform-origin: 100% 0%;
    transition: all 0.35s;
    padding: 8px 8px 8px 14px;
    ${props => props.active && css`
        visibility: visible;
        opacity: 1;
        transform: translate(0) scale(1);
        animation: fadeIn .5s;
    `}
`;

export const ButtonEmojiChat = styled.button`
    display: grid;
    place-items: center;
    padding: 6px;
    border: none;
    background-color: #262655;
    border-radius: 3px;
`;
