/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Buttons, Container, InputField, Inputs, Msg, Title, User } from './styles'
import ButtonLinear from '../../components/form/ButtonLinear';
import InputBorderBottom from '../form/InputBorderBottom';
import { IconDanger, IconFile, IconMap, IconMessage, IconPerson, IconPlus, IconTag } from '../icons';
import SelectBorderBottom from '../form/SelectBorderBottom';
import Tag from '../Tag/Tag';
import { IAtendimento, IInformacaoEntrada } from '../../types/IAtendimento';
import { useMessage } from '../../contexts/MessagesContext';
import apiTropa from '../../services/apiTropa';
import Scrollbars from 'react-custom-scrollbars';
import moment from 'moment';
import ModalDefault from '../ModalDefault';
import TitleModal from '../layout/containers/TitleModal';
import Field from '../layout/containers/Field';
import InputTextarea from '../form/InputTextarea';

interface IDTOObservacao{
    id_atendimento?:string
    descricao?:string
}

interface Props {
    atendimento:IAtendimento
    setAtendimento:(atendimento:any) => void
    disabled?:boolean
    tags?: any[],
    token?: any,
    tokenInicial?: any
}

export default function ClientData({
    atendimento,
    setAtendimento,
    disabled = false,
    token = null,
}:Props) {

    const tokenData: any = token ? token : window.sessionStorage.getItem('token');

    const [activeTabs, setActiveTabs] = useState('clientTab')
    const { addDanger, addSuccess } = useMessage()
    const [DTO, setDTO] = useState<IInformacaoEntrada>({})

    const [modalObservacao, setModalObservacao] = useState<boolean>(false)
    const [observacoes, setObservacoes] = useState<any[]>([])
    const [DTOObservacao, setDTOObservacao] = useState<IDTOObservacao>({})
    const [tags, setTags] = useState<any[]>([])
    const [tagsAtendimento, setTagsAtendimento] = useState<any[]>([])

    useEffect(() => {

        getObservacoes()
        getTags(atendimento.id_grupo_atendimento)
        if ( atendimento.tags?.length ) {
            setTagsAtendimento([...atendimento.tags.split('|')])
        } else {
            setTagsAtendimento([])
        }
    }, [atendimento])

    useEffect(() => {
        setDTO({})
        if( atendimento.informacao_entrada ){
            
            const updateDTO = {
                "Nome completo": atendimento.informacao_entrada["Nome completo"] || "",
                "E-mail": atendimento.informacao_entrada["E-mail"] || "",
                "Telefone": atendimento.informacao_entrada["Telefone"] || "",
                "Celular": atendimento.informacao_entrada["Celular"] || "",
                "Sexo": atendimento.informacao_entrada["Sexo"] || "",
                "CPF": atendimento.informacao_entrada["CPF"] || "",
                "Profissão": atendimento.informacao_entrada["Profissão"] || "",
                "CEP": atendimento.informacao_entrada["CEP"] || "",
                "Endereço": atendimento.informacao_entrada["Endereço"] || "",
                "Número": atendimento.informacao_entrada["Número"] || "",
                "Complemento": atendimento.informacao_entrada["Complemento"] || "",
                "Bairro": atendimento.informacao_entrada["Bairro"] || "",
                "Cidade": atendimento.informacao_entrada["Cidade"] || "",
                "Estado": atendimento.informacao_entrada["Estado"] || "",
                "origem": atendimento.informacao_entrada["origem"] || "",
            } as IInformacaoEntrada
            setDTO(updateDTO)
            getObservacoes()
        }


    }, [atendimento])

    async function handleSetTags(tagsUpdate:any)
    {
        await apiTropa.put(`/atendimento/atualizar/${atendimento.id_atendimento}`, {
            tags: tagsUpdate.join('|')
       }, {
        headers: {
            'Authorization': 'Bearer '+tokenData
        }})
    }

    async function getTags(id_grupo_atendimento:any){
        const response:any = await apiTropa.get(`/tag-atendimento-grupo/${id_grupo_atendimento}`, {
            headers: {
                'Authorization': 'Bearer '+tokenData
            }})
        const result = response.data.data
        if ( response.data.data.length > 0 ) {
            setTags([...result])
        } else {
            setTags([])
        }
    }

    function getObservacoes(){
        apiTropa.get(`/observacao-atendimento/${atendimento.id_atendimento}`, {
            headers: {
                'Authorization': 'Bearer '+tokenData
            }}).then((response:any) => {
            if ( response.data.data.length > 0 ) {
                setObservacoes([...response.data.data])
            } else {
                setObservacoes([])
            }
        })
    }

    function onChangeDTO(key:any, value:any) {
        const copy = DTO as any
        copy[key] = value
        setDTO({...copy})
    }

    function onChangeDTOObservacao(key:any, value:any) {
        const copy = DTOObservacao as any
        copy[key] = value
        setDTOObservacao({...copy})
    }

    async function handleSave(){
        try {
            await apiTropa.put(`/usuarios/editar/${atendimento.id_usuario}`, {
                informacao_entrada: DTO
            }, {
                headers: {
                    'Authorization': 'Bearer '+tokenData
                }})
            addSuccess("Sucesso", "Salvo com sucesso")
            atendimento.informacao_entrada = DTO
            setAtendimento(atendimento)

        } catch(err:any) {
            console.log(err)
            if( err.response ){
                addDanger("Atenção", String(err.response.data.message))
            } else {
                addDanger("Atenção", String(err))
            }
        }
    }

    async function handleAdicionarObservacao(){
        try {
            DTOObservacao.id_atendimento = String(atendimento.id_atendimento)
            await apiTropa.post(`observacao`, DTOObservacao, {
                headers: {
                    'Authorization': 'Bearer '+tokenData
                }})
            getObservacoes()
            setDTOObservacao({
                id_atendimento: "",
                descricao: ""
            })
            setModalObservacao(false)
            addSuccess("Sucesso", "Salvo com sucesso")

        } catch(err:any) {
            if( err.response ){
                addDanger("Atenção", String(err.response.data.message))
            } else {
                addDanger("Atenção", String(err))
            }
        }
    }

    function handleAddTag(tag:string){

        const newTagsAtendimento:any[] = tagsAtendimento.filter(obj => obj !== tag)
        newTagsAtendimento.push(tag)
        setTagsAtendimento([...newTagsAtendimento])
        handleSetTags(newTagsAtendimento)

        atendimento.tags = newTagsAtendimento.join('|')
        setAtendimento(atendimento)

    }

    function handleRemoveTag(tag:string){
        const newTagsAtendimento:any[] = tagsAtendimento.filter(obj => obj !== tag)
        setTagsAtendimento([...newTagsAtendimento])
        handleSetTags(newTagsAtendimento)
        atendimento.tags = newTagsAtendimento.join('|')
        setAtendimento(atendimento)
    }

    return (
        <Container>
            
            <Title>
                { activeTabs === "clientTab" && <h3>Dados do cliente</h3>}
                { activeTabs === "adressTab" && <h3>Endereço</h3>}
                { activeTabs === "tagTab" && <h3>Tags</h3>}
                { activeTabs === "obsTab" && <h3>Observações</h3>}
            </Title>

            <Buttons>
                <button
                    className={activeTabs === "clientTab" ? "active" : ""}
                    onClick={() => setActiveTabs("clientTab")}
                >
                    <IconPerson />
                </button>

                <button
                    className={activeTabs === "adressTab" ? "active" : ""}
                    onClick={() => setActiveTabs("adressTab")}
                >
                    <IconMap />
                </button>

                <button
                    className={activeTabs === "tagTab" ? "active" : ""}
                    onClick={() => setActiveTabs("tagTab")}
                >
                    <IconTag />
                </button> 

                <button
                    className={activeTabs === "obsTab" ? "active" : ""}
                    onClick={() => setActiveTabs("obsTab")}
                >
                    <IconFile />
                </button>
            </Buttons>

            { activeTabs === "clientTab" && 
            <>
                <Inputs>
                    <InputField>
                        <span>Nome completo</span>
                        <InputBorderBottom
                            value={DTO["Nome completo"]}
                            onChangeText={(value) => onChangeDTO("Nome completo", value)}
                        >
                        </InputBorderBottom>    
                    </InputField>

                    <InputField>
                        <span>E-mail</span>
                        <InputBorderBottom
                            value={DTO["E-mail"]}
                            onChangeText={(value) => onChangeDTO("E-mail", value)}
                        >
                        </InputBorderBottom>    
                    </InputField>

                    <InputField>
                        <span>Telefone</span>
                        <InputBorderBottom
                            value={DTO["Telefone"]}
                            mask={'(99) 99999-9999'}
                            onChangeText={(value) => onChangeDTO("Telefone", value)}
                        >
                        </InputBorderBottom>    
                    </InputField>

                    <InputField>
                        <span>Celular</span>
                        <InputBorderBottom
                            value={DTO["Celular"]}
                            mask={'(99) 99999-9999'}
                            onChangeText={(value) => onChangeDTO("Celular", value)}
                        >
                        </InputBorderBottom>    
                    </InputField>

                    <InputField>
                        <span>Sexo</span>
                        <SelectBorderBottom
                            value={DTO["Sexo"]}
                            onChangeText={(value) => onChangeDTO("Sexo", value)}
                        >
                            <option value=""></option>
                            <option value="Masculino">Masculino</option>
                            <option value="Feminino">Feminino</option>
                            <option value="Indefinido">Indefinido</option>
                        </SelectBorderBottom>
                    </InputField>
                    
                    <InputField>
                        <span>CPF</span>
                        <InputBorderBottom
                            value={DTO["CPF"]}
                            mask={'999.999.999-99'}
                            onChangeText={(value) => onChangeDTO("CPF", value)}
                        >
                        </InputBorderBottom>        
                    </InputField>

                    <InputField>
                        <span>Profissão</span>
                        <InputBorderBottom
                            value={DTO["Profissão"]}
                            onChangeText={(value) => onChangeDTO("Profissão", value)}
                        >
                        </InputBorderBottom>        
                    </InputField>

                    <InputField>
                        <span>Origem</span>
                        <InputBorderBottom
                            value={DTO["origem"]}
                            onChangeText={(value) => {}}
                        >
                        </InputBorderBottom>        
                    </InputField>

                </Inputs>

                <hr />

                {disabled === false &&
                <ButtonLinear className="info" onClick={handleSave}>
                    Salvar
                </ButtonLinear>
                }
            </>
            }

            { activeTabs === "adressTab" &&
            <> 
                <Inputs>
                    <InputField>
                        <span>CEP</span>
                        <InputBorderBottom
                            value={DTO["CEP"]}
                            mask={'99999-999'}
                            onChangeText={(value) => onChangeDTO("CEP", value)}
                        >
                        </InputBorderBottom>    
                    </InputField>

                    <InputField>
                        <span>Endereço</span>
                        <InputBorderBottom
                            value={DTO["Endereço"]}
                            onChangeText={(value) => onChangeDTO("Endereço", value)}
                        >
                        </InputBorderBottom>    
                    </InputField>

                    <InputField>
                        <span>Número</span>
                        <InputBorderBottom
                            value={DTO["Número"]}
                            onChangeText={(value) => onChangeDTO("Número", value)}
                        >
                        </InputBorderBottom>    
                    </InputField>

                    <InputField>
                        <span>Complemento</span>
                        <InputBorderBottom
                            value={DTO["Complemento"]}
                            onChangeText={(value) => onChangeDTO("Complemento", value)}
                        >
                        </InputBorderBottom>    
                    </InputField>

                    <InputField>
                        <span>Bairro</span>
                        <InputBorderBottom
                            value={DTO["Bairro"]}
                            onChangeText={(value) => onChangeDTO("Bairro", value)}
                        >
                        </InputBorderBottom>    
                    </InputField>

                    <InputField>
                        <span>Cidade</span>
                        <InputBorderBottom
                            value={DTO["Cidade"]}
                            onChangeText={(value) => onChangeDTO("Cidade", value)}
                        >
                        </InputBorderBottom>    
                    </InputField>

                    <InputField>
                        <span>Estado</span>
                        <InputBorderBottom
                            value={DTO["Estado"]}
                            onChangeText={(value) => onChangeDTO("Estado", value)}
                        >
                        </InputBorderBottom>    
                    </InputField>

                </Inputs>

                <hr />

                {disabled === false &&
                <ButtonLinear className="info" onClick={handleSave}>
                    Salvar
                </ButtonLinear>    
                }
            </>
            }

            { activeTabs === "tagTab" && 
                <>
                    <div className={`tags ${tagsAtendimento.length > 0 ? 'hasVinc' : ''}`}>
                        <h3>Tags vinculadas</h3>
                        { tagsAtendimento.map((row:any, key:number) =>
                            <Tag
                                onClick={() => handleRemoveTag(row)}
                                key={key}
                                className="minus"
                                Icon={IconDanger}
                            >
                                {row}                       
                            </Tag>
                        )}
                    </div>

                    <div className="tags-plus">
                        <h3>Tags disponíveis</h3>

                            { tags.map((row, key) => tagsAtendimento.filter(obj => obj === row.tag).length === 0 &&
                                <Tag 
                                    onClick={() => handleAddTag(row.tag)}
                                    className="plus"
                                    Icon={IconPlus}
                                    key={key}
                                >
                                    {row.tag}                        
                                </Tag>
                            )}
                    </div>
                </>
            }

            {activeTabs === "obsTab" &&  
            <>
                <div className="observations">

                    <Scrollbars style={{width: '100%', height: 'calc(100vh - 390px)'}}>
                        {observacoes.map((row, key) => (
                        <div key={key}>
                            <User>
                                <div className="icon">
                                    <IconMessage />
                                </div>
                                <div className="user">
                                    {/* <span>Fulano da Silva</span> */}
                                    <div>
                                        {moment(row.criado).utc().format("DD/MM/YY")} às {moment(row.criado).utc().format("HH:mm:ss")}
                                    </div>
                                </div>
                            </User>
                            <Msg>
                                <span>{row.descricao}</span>
                            </Msg>
                        </div>
                        ))}
                    </Scrollbars>

                </div>
                
                <hr />

                {disabled === false &&
                <ButtonLinear 
                    className="info"
                    onClick={() => setModalObservacao(true)}
                >
                    Escrever uma observação
                </ButtonLinear>
                }

            </>
            }
            
            <ModalDefault 
                id="modalObservacao" 
                openModal={modalObservacao} 
                onClose={() => setModalObservacao(false)}
            >
                <TitleModal>
                    <h2>Observação</h2>
                    <span>Adicione uma observação sobre esse atendimento.</span>
                </TitleModal>

                <Field gap='8px' marginTop={true}>
                    <InputTextarea
                        placeholder="Digite aqui..."
                        value={DTOObservacao.descricao}
                        onChangeText={(value) => onChangeDTOObservacao("descricao", value)}
                    >
                    </InputTextarea>

                    <ButtonLinear
                        className="success"
                        onClick={() => handleAdicionarObservacao()}
                    >
                        Adicionar
                    </ButtonLinear>
                </Field>
            </ModalDefault>

        </Container>
    )
}