module.exports = {
    now: function (hour = true) {
        return this.convert(new Date(), hour);
    },

    /**
     * nova data
     * @returns {string}
     */
    new: function (value) {
        let date = new Date(value);
        // date = new Date(date.valueOf() - date.getTimezoneOffset() * 60000);
        return date.toISOString().slice(0, 10);
    },

    /**
     * retornar data atual
     * @returns {string}
     */
    today: function () {
        let date = new Date();
        date = new Date(date.valueOf() - date.getTimezoneOffset() * 60000);
        return date.toISOString().slice(0, 10);
    },

    /**
     * retornar data de ontem
     * @returns {string}
     */
    yesterday: function () {
        let date = new Date();
        date.setDate(date.getDate() - 1);
        date = new Date(date.valueOf() - date.getTimezoneOffset() * 60000);
        return date.toISOString().slice(0, 10);
    },

    /**
     * retornar data de amanhã
     * @returns {string}
     */
    tomorrow: function () {
        let date = new Date();
        date.setDate(date.getDate() + 1);
        // date = new Date(date.valueOf() - date.getTimezoneOffset() * 60000);
        return date.toISOString().slice(0, 10);
    },

    /**
     * formatar Date para string
     * @param date
     * @param hourInclude
     * @returns {string}
     */
    string: function (date, hourInclude = true) {
        let response;
        date = new Date(date);

        //validar data
        if (date instanceof Date && isNaN(date)) {
            return '--';
        }

        //verificar se é hoje
        if (date.toISOString().slice(0, 10) === this.today()) {
            response = 'Hoje';
        } else if (date.toISOString().slice(0, 10) === this.yesterday()) {
            response = 'Ontem';
        } else if (date.toISOString().slice(0, 10) === this.tomorrow()) {
            response = 'Amanhã';
        } else {
            let day = date.getDate().toString().padStart(2, '0');
            let month = (date.getMonth() + 1).toString().padStart(2, '0');
            // let year = date.getFullYear();
            // response = day + "/" + month + "/" + year;
            response = day + '/' + month;
        }

        if (hourInclude === true) {
            let hour = date.getHours().toString().padStart(2, '0');
            let minute = date.getMinutes().toString().padStart(2, '0');
            response += ' às ' + hour + 'h' + minute;
        }

        return response;
    },

    /**
     * converter para dd-mm-yyyy
     * @param date
     * @param hourInclude
     * @returns {string}
     */
    convert: function (date, hourInclude = false) {
        if (!date) return '';

        let response;
        date = new Date(date);
        let day = date.getDate().toString().padStart(2, '0');
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let year = date.getFullYear();
        response = day + '-' + month + '-' + year;

        if (hourInclude === true) {
            let hour = date.getHours().toString().padStart(2, '0');
            let minute = date.getMinutes().toString().padStart(2, '0');
            response += ' às ' + hour + 'h' + minute;
        }

        return response;
    },

    /**
     * converter do formato dd/mm/yyyy para yyyy-mm-dd
     * @param {string} DDMMYYYY
     * @returns {string}
     */
    convertYYYYMMDD: function (DDMMYYYY, HHMMSS = null) {
        if (!DDMMYYYY) return '';
        let date = DDMMYYYY.split('-');
        let day = date[0];
        let month = date[1];
        let year = date[2];
        let response = year + '-' + month + '-' + day;
        if (HHMMSS) response += ' ' + HHMMSS;
        return response;
    },

    /**
     * retornar datetime com adição na data
     * @returns {string}
     */
    datetime: function ({
        addMinute = 0,
        addHour = 0,
        addMonth = 0,
        date = new Date(),
    }) {
        if (addMinute) date.setMinutes(date.getMinutes() + addMinute);
        if (addHour) date.setHours(date.getHours() + addHour);
        if (addMonth) date.setMonth(date.getMonth() + addMonth);

        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        let hour = date.getHours().toString().padStart(2, '0');
        let minute = date.getMinutes().toString().padStart(2, '0');
        return `${year}-${month}-${day} ${hour}:${minute}`;
    },

    time: function ({ addMinute = 0 }) {
        let date = new Date();
        if (addMinute) date.setMinutes(date.getMinutes() + addMinute);
        let hour = date.getHours().toString().padStart(2, '0');
        let minute = date.getMinutes().toString().padStart(2, '0');
        return `${hour}:${minute}`;
    },

    date: function () {
        let date = new Date();
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    },

    /**
     * converter o fuso horário para BR
     * @param date
     * @returns {string}
     */
    convertTimezone: function (date) {
        date = new Date(date);
        date = new Date(date.valueOf() - date.getTimezoneOffset() * 60000);
        return date.toISOString();
    },

    /*
     * retornar o dia da semana
     */
    weekday: function (date = null) {
        date = date === null ? this.today() : this.convertYYYYMMDD(date);
        let d = new Date(date);
        let weekday = [
            'Segunda',
            'Terça',
            'Quarta',
            'Quinta',
            'Sexta',
            'Sábado',
            'Domingo',
        ];
        return weekday[d.getDay()];
    },

    weekdayInteger: function (date = null) {
        date = date === null ? this.today() : this.convertYYYYMMDD(date);
        let d = new Date(date);
        return d.getDay();
    },

    /**
     * formatar data para 10 de Junho
     * @param date
     * @returns {string}
     */
    dayAndMonth: function (date) {
        let response;
        date = new Date(date);

        let months = [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro',
        ];
        let day = date.getDate().toString().padStart(2, '0');
        let month = date.getMonth();
        response = day + ' de ' + months[month];

        return response;
    },

    /**
     * formatar hora HH:MM
     * @param date
     * @returns {string}
     */
    hour: function (date) {
        let response;
        date = new Date(date);

        let hour = date.getHours().toString().padStart(2, '0');
        let minute = date.getMinutes().toString().padStart(2, '0');
        response = hour + ':' + minute;

        return response;
    },

    /*
     * validar data "yyyy-mm-dd"
     */
    isDate: function (dateString) {
        if (!dateString) return false;

        // Parse the date parts to integers
        var parts = dateString.split('-');
        var day = parseInt(parts[2], 10);
        var month = parseInt(parts[1], 10);
        var year = parseInt(parts[0], 10);

        // Check the ranges of month and year
        if (year < 1000 || year > 3000 || month == 0 || month > 12)
            return false;

        var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // Adjust for leap years
        if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
            monthLength[1] = 29;

        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
    },

    /*
     * validar hora "hh:mm"
     */
    isTime: function (time) {
        if (!time) return false;
        if (time.length !== 5) return false;

        var parts = time.split(':');
        var hour = parseInt(parts[0], 10);
        var minute = parseInt(parts[1], 10);

        if (hour < 0 || hour > 23) return false;
        if (minute < 0 || minute > 59) return false;
        return true;
    },
};
