import StatusTable from '../../Table/StatusTable';
import { Container, PersonImage, PersonInfo, SubContainer } from './styles';

interface UserProps {
    users?: any[];
}

function getStyle(){
    return {backgrounColor: '#34D5FA'}
}

export default function CardUser({ users = [] }: UserProps) {
    return (
        <>
            <SubContainer>
                {!users.length ? (
                    <p className="empty">Nenhum operador encontrado.</p>
                )
                :
                <>
                {users.map((row, key) => (
                    <Container key={key}>
                        <PersonImage  >
                            <img src={row.foto ? row.foto : '/user.png'} style={ !row.foto ? {backgroundColor: '#fff'}: {}} alt="profile" />
                        </PersonImage>
                        <PersonInfo status={row.perfil[0]?.ativo === 'online'}>
                            <h2>
                                {row.perfil?.nome}
                                {/* <div className="ball"></div> */}
                            </h2>
                            <h3>{row.dadosUsuario?.lastLogin}</h3>
                            <StatusTable
                                    showLabel={false}
                                    status={row.perfil?.ativo === 'sim' ? true : false}
                                />
                        </PersonInfo>
                    </Container>
                ))}
                </>
                }
            </SubContainer>
        </>
    );
}
