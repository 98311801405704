import styled from "styled-components"

export const Container = styled.div`
    padding-bottom: 50px;
    hr {
        background: transparent;
        border: none;
        border-bottom: 1px solid rgba(255,255,255,.3);
        margin: 20px 0px;
    }
    h2 {
        &.default {
            color: #FFF;
            font-weight: 200;
        }
    }
    .column {
        p {
            background: rgba(255,255,255,.2);
            box-sizing: border-box;
            padding: 10px;
            margin: 0px;
        }
    }
`