
import React, { useEffect, useState } from 'react'
import { useMessage } from '../../../contexts/MessagesContext'
import apiTropa from '../../../services/apiTropa'
import ButtonConfig from '../../form/ButtonConfig'
import InputChat from '../../form/InputChat'
import { Container, HeaderChat, LogoChat, MainChat } from './styles'

export default function CardWellcomeToChat({
    onChange = (data: any) => {},
    data = {},
}: any) {
    const [stylesButton, setStylesButton] = useState<any>({})
    const { addDanger } = useMessage()
    const [DTO, setDTO] = useState<any>({})

    stylesButton.width = '100%'
    const [load, setLoad] = useState<boolean>(true)

    const script = document.createElement('content-chat-eyou-omni');
    
    const chatAttendence: any = window.sessionStorage.getItem('chatAtendence')
    const chatPerfil: any = window.sessionStorage.getItem('chatPerfil')

    // useEffect(() => {
    //     if (data.configuracaoEstilo.styleChat.theme === 'dark') {
    //         data.configuracaoEstilo.styleButtonMainDark.width = '100%'
    //         setStylesButton({ ...data.configuracaoEstilo.styleButtonMainDark })
    //     } else {
    //         data.configuracaoEstilo.styleButtonMain.width = '100%'
    //         setStylesButton({ ...data.configuracaoEstilo.styleButtonMain })
    //     }
    // }, [data])

    useEffect(() => {
        if (data.configuracaoEstilo.styleChat.theme === 'dark') {
            data.configuracaoEstilo.style.width = '100%'
            setStylesButton({ ...data.configuracaoEstilo.styleButtonMainDark })
        } else {
            data.configuracaoEstilo.style.width = '100%'
            setStylesButton({ ...data.configuracaoEstilo.styleButtonMain })
        }
        
    }, [data])

    useEffect(() => {
        if (chatAttendence && chatPerfil) {
            onChange(JSON.parse(chatAttendence))
        } else {
            setLoad(false)
        }
    }, [])

    function validationCep(cep: any) {
        if(cep === false || cep === undefined) {
            return false
        }
        if(cep.length < 9) {
            return false
        }

        return true
        // var valid = /^[0-9]{5}-[0-9]{3}$/
        // return valid.test(cep);
    }

    function validationEmail(mail: string) {
        var valid = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
        return valid.test(mail)
    }

    function validationCnpj(pj: any) {
        var valid = /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/i;
        return valid.test(pj)
    }

    function validationCpf(cp: any) {
        var valid = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/
        return valid.test(cp);
    }

    function isValidCPF(cpf: any) {
        if (typeof cpf !== 'string') return false;
        cpf = cpf.replace(/[\s.-]*/gim, '');
        if (
            !cpf ||
            cpf.length != 11 ||
            cpf == '00000000000' ||
            cpf == '11111111111' ||
            cpf == '22222222222' ||
            cpf == '33333333333' ||
            cpf == '44444444444' ||
            cpf == '55555555555' ||
            cpf == '66666666666' ||
            cpf == '77777777777' ||
            cpf == '88888888888' ||
            cpf == '99999999999'
        ) {
            return false;
        }
        var soma = 0;
        var resto;
        for (var i = 1; i <= 9; i++)
            soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
        resto = (soma * 10) % 11;
        if (resto == 10 || resto == 11) resto = 0;
        if (resto != parseInt(cpf.substring(9, 10))) return false;
        soma = 0;
        for (var i = 1; i <= 10; i++)
            soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
        resto = (soma * 10) % 11;
        if (resto == 10 || resto == 11) resto = 0;
        if (resto != parseInt(cpf.substring(10, 11))) return false;
        return true;
    }

    async function handleSubmit(e: any) {
        try {

            e.preventDefault()
            setLoad(true)
            const newDTO: any = DTO

            data.formularioEntradaForm.map((row: any) => {
                if (!newDTO[row] && data.formularioEntradaObrigatorios.filter((obj:any) => obj === row).length > 0 ) {
                    throw `Campo ${row} é obrigatório.`;
                }
                if (row == "CPF" && data.formularioEntradaObrigatorios.filter((obj:any) => obj === 'CPF').length > 0 && !isValidCPF(DTO.CPF) ) {
                    throw 'Digite um CPF valido!';
                } else if(row == "CEP" && data.formularioEntradaObrigatorios.filter((obj:any) => obj === 'CEP').length > 0 && !validationCep(DTO.CEP) ) {
                    throw 'Digite um Cep valido!';
                } else if(row == "CNPJ" && data.formularioEntradaObrigatorios.filter((obj:any) => obj === 'CNPJ').length > 0 && !validationCnpj(DTO.CNPJ)) {
                    throw 'Digite um CNPJ valido!';
                } else if(row == "E-mail" && data.formularioEntradaObrigatorios.filter((obj:any) => obj === 'E-mail').length > 0 && !validationEmail(DTO['E-mail'])) {
                    throw 'Digite um EMAIL valido!';
                }
            })

            DTO.origem = data.urlPage

            const response: any = await apiTropa.post('atendimento/adicionar', {
                id_canal_atendimento: '2',
                id_grupo_atendimento: data.grupoAtendimento.id_grupo_atendimento,
                id_cliente: data.grupoAtendimento.id_cliente,
                informacao_entrada: JSON.stringify(DTO),
                ip: '',
                origem: data.urlPage
            })
            if (response.data.status !== 'success') throw response.data.message
            window.sessionStorage.setItem('chatAtendence', JSON.stringify(response.data.data))
            window.sessionStorage.setItem('chatPerfil', JSON.stringify(DTO))
            onChange(response.data.data)
            setLoad(false)

        } catch (err: any) {
            setLoad(false)
            addDanger('Atenção', err.toString())
        }
    }

    function handleOnChange(name: string, value: string) {
        const newDTO: any = DTO
        newDTO[name] = value
        setDTO({ ...newDTO })
    }

    return (
        <Container>
            <HeaderChat>
                <LogoChat style={{backgroundImage: `url(${data.configuracaoEstilo?.styleChat?.file})`}} />
                <h1 style={{color: data.formularioEntrada?.cor_titulo}}>{data.formularioEntrada?.titulo}</h1>
                <p  style={{color: data.formularioEntrada?.cor_descricao}}>{data.formularioEntrada?.descricao}</p>
            </HeaderChat>

            <MainChat onSubmit={handleSubmit}>
                {data.formularioEntradaForm.map((row: any, key: any) => (
                    <InputChat
                        key={key}
                        value=""
                        type="text"
                        maxLength={row === 'CPF' ? 14 : 100}
                        name={row}
                        label={`${row} ${data.formularioEntradaObrigatorios.filter((obj:any) => obj === row).length ? '*' : ''}`}
                        mask={row}
                        onChange={(e) => handleOnChange(row, e.target.value)}
                    />
                ))}


                <ButtonConfig type='submit' loading={load} styles={stylesButton}>
                    {stylesButton.textButtonAction ? stylesButton.textButtonAction : 'INICIAR ATENDIMENTO'}
                </ButtonConfig>
            </MainChat>
            
        </Container>
    )
}
