import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import Login from '../pages/Login';
import Recover from '../pages/Login/recover';
import PageChatExample from '../pages/PageChat/example';

export default function PublicRoutes() {
    return (
        <BrowserRouter>
            <Route path="/" exact component={Login} />
            <Route path="/login/recuperar-senha" exact component={Recover} />
        </BrowserRouter>
    );
}
