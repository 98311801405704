import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ButtonLinear from '../../../components/form/ButtonLinear';
import InputDefault from '../../../components/form/InputDefault';
import InputRadioDefault from '../../../components/form/InputRadioDefault';
import Field from '../../../components/layout/containers/Field';
import FieldGroup from '../../../components/layout/containers/FieldGroup';
import ButtonConfig from '../../../components/form/ButtonConfig';
import InputColorUi from '../../../components/form/InputColorUi';
import { useMessage } from '../../../contexts/MessagesContext';
import { useRoutes } from '../../../contexts/RoutesContext';
import Chat from '../../../components/Chat/CardChat';
import CardMessage from '../../../components/Chat/CardMessage';
import { IconShape, IconThumbUp } from '../../../components/icons';

import {
    ContainerEdit,
    HeaderEdit,
    ContentButtonMain,
    ContentButtonAction,
    ContentConfigChat,
    ContentLeft,
    ContentRight,
    CardContainer,
    SectionTitleCard,
    TitleRightCard,
} from './styles';
import apiTropa from '../../../services/apiTropa';
import apiEyou from '../../../services/apiEyou';
import InputPixel from '../../../components/form/InputPixel';
import InputDefaultColor from '../../../components/form/InputDefaultColor';
import Loading from '../../../components/Loading/Loading';
import BackPage from '../../../components/layout/UIElements/BackPage';

export default function ConfigurarBotaoEdit(props: any) {
    const [inputRadio, setInputRadio] = useState<string>('text');
    const { setPageName } = useRoutes();
    const history = useHistory();
    const { addSuccess, addDanger } = useMessage();
    const [data, setData] = useState<any>({});
    const [buttons, setButtons] = useState<any[]>([]);
    const [buttonSelected, setButtonSelected] = useState<any>({});
    const id = props.match.params.id;
    const [load, setLoad] = useState(true)

    useEffect(() => {
        setPageName('Edição do botão');
        async function getAllButtons() {
            const response: any = await apiTropa.get(
                'botao-entrada/get-by-grupo/' + id
            );

            if (response.data.data.length > 0) {
                setData({ ...response.data.data[0] });
                const configStyle = response.data.data[0].configuracao_estilo;
                setStyle({ ...configStyle.style });
                setStyleButtonMain({ ...configStyle.styleButtonMain });
                setStyleButtonMainDark({ ...configStyle.styleButtonMainDark });
                setStyleChat({ ...configStyle.styleChat });
            }

            const responseButtons: any = await apiTropa.get(
                'botao-entrada/get-by-cliente'
            );
            setButtons([...responseButtons.data.data]);
            setLoad(false)
        }
        getAllButtons();
    }, [id]);

    async function saveEdicao() {
        try {
            addSuccess('Confirmado', 'Alterações salvas com sucesso.');
            const configuracaoEstilo: any = {};
            configuracaoEstilo.style = style;
            configuracaoEstilo.styleButtonMain = styleButtonMain;
            configuracaoEstilo.styleButtonMainDark = styleButtonMainDark;
            configuracaoEstilo.styleChat = styleChat;

            const DTOAPI = {
                id_grupo_atendimento: id,
                id_formulario_entrada: data.id_formulario_entrada,
                configuracao_estilo: configuracaoEstilo,
            };

            const response = await apiTropa.put(
                'botao-entrada/update/' + data.id_botao_entrada,
                DTOAPI
            );
        } catch (err: any) {
            addDanger('Atenção', err);
        }
    }

    const [styleChat, setStyleChat] = useState({
        icon: 'text',
        theme: 'light',
        backgroundRecebida: '#7b61ff',
        backgroundEnviada: '#7b6',
        colorMain: '#34D5FA',
    } as any);


    const [style, setStyle] = useState({
        width: '100%',
        height: '60px',
        borderRadius: '0px',
        border: '0px',
        borderStyle: 'solid',
        borderColor: '#7B61FF',
        backgroundColor: '#7B61FF',
        color: '#fff',
        textTransform: 'uppercase',
        textButton: 'Escuro',
        textSendQuestion: 'Enviar pesquisa',
        textNotSendQuestion: 'Não responder pesquisa',
        textFinishedAttendence: 'Finalizar atendimento',
    } as any);

    const [styleButtonMain, setStyleButtonMain] = useState({
        width: '100%',
        height: '60px',
        borderRadius: '0px',
        border: '0px',
        borderStyle: 'solid',
        borderColor: '#7B61FF',
        backgroundColor: '#7B61FF',
        color: '#fff',
        textTransform: 'uppercase',
        textButton: '',
        textSendQuestion: '',
        textNotSendQuestion: '',
        textFinishedAttendence: '',
        textButtonAction: '',
    } as any);

    const [styleButtonMainDark, setStyleButtonMainDark] = useState({
        width: '100%',
        height: '60px',
        borderRadius: '0px',
        border: '0px',
        borderColor: '#7B61FF',
        borderStyle: 'solid',
        backgroundColor: '#7B61FF',
        color: '#fff',
        textTransform: 'uppercase',
        textButton: 'Escuro',
        textSendQuestion: 'Enviar pesquisa',
        textNotSendQuestion: '',
        textFinishedAttendence: 'Finalizar atendimento',
    } as any);

    function handleStyles(name: any, value: any) {
        style[name] = value;
        setStyle({ ...style });
    }

    function handleStylesButtonMain(name: any, value: any) {

        styleButtonMain[name] = value;
        setStyleButtonMain({ ...styleButtonMain });
   

    }

    function handleStylesButtonMainDark(name: any, value: any) {
        styleButtonMainDark[name] = value;
        setStyleButtonMainDark({ ...styleButtonMainDark });
    }

    function handleStylesChat(name: any, value: any) {
        styleChat[name] = value;
        setStyleChat({ ...styleChat });
    }

    async function handleUpload(e: any) {
        try {
            if (e && e.target && e.target.files && e.target.files.length > 0) {
                var formData = new FormData();

                formData.append('idApp', '2');
                formData.append('destination', '1');
                formData.append('share', '1');
                formData.append(
                    'files',
                    e.target.files[0],
                    e.target.files[0].name
                );
                const response: any = await apiEyou.post('apiDrive', formData, {
                    headers: {
                        Authorization:
                            'Bearer ' +
                            window.sessionStorage.getItem('tokenInicial'),
                    },
                });

                handleStylesChat('file', response.data.data.fileUrl);
            }
        } catch (err: any) {
            addDanger('Atenção', err);
        }
    }

    function handleSetButtonSelected() {
        const configButtonSelected: any = JSON.parse(
            buttons.filter(
                (obj) =>
                    obj.id_botao_entrada.toString() ===
                    buttonSelected.toString()
            )[0].configuracao_estilo
        );

        setStyle({ ...configButtonSelected.style });
        setStyleButtonMain({
            ...configButtonSelected.styleButtonMain,
        });
        setStyleButtonMainDark({
            ...configButtonSelected.styleButtonMainDark,
        });
        setStyleChat({ ...configButtonSelected.styleChat });
    }

    return (
        <>
        
        <Loading active={load}/>
        <ContainerEdit>
            
            <HeaderEdit>
                <BackPage />
                
                <div className='buttons'>
                    <ButtonLinear onClick={() => history.goBack()}>
                        Cancelar
                    </ButtonLinear>
                    <ButtonLinear className="success" onClick={saveEdicao}>
                        Salvar
                    </ButtonLinear>
                </div>
            </HeaderEdit>

            {/* <CardContainer>
                <h1>Configuração Rápida</h1>
                <h2>Voce quer usar a configuração de algum grupo?</h2>

                <FieldGroup width="30%">
                    <SelectDefault
                        value={''}
                        placeholder="Selecione um grupo"
                        onChangeText={(value) => setButtonSelected(value)}
                    >
                        {buttons.map((row, key) => (
                            <option value={row.id_botao_entrada}>
                                {row.grupo_atendimento}
                            </option>
                        ))}
                    </SelectDefault>

                    <ButtonLinear
                        onClick={handleSetButtonSelected}
                        className="info"
                    >
                        Usar!
                    </ButtonLinear>
                </FieldGroup>
            </CardContainer> */}

            <ContentConfigChat>
                <ContentLeft>
                    <CardContainer>
                        <h1>Sua marca</h1>
                        <h2>Tamanho (px): 72x72</h2>
                        <input
                            onChange={handleUpload}
                            type="file"
                            className="custom-file-input"
                        />
                    </CardContainer>

                    <CardContainer>
                        <h1>Cores</h1>

                        <Field>
                            <h2>Balão de fala: Quem pergunta</h2>
                            <FieldGroup>
                                <InputDefaultColor
                                    onChangeText={(value) =>
                                        handleStylesChat(
                                            'backgroundRecebida',
                                            value
                                        )
                                    }
                                    value={styleChat.backgroundRecebida}
                                    placeholder="Cor do botão"
                                />

                                <InputColorUi
                                    onChangeText={(value) =>
                                        handleStylesChat(
                                            'backgroundRecebida',
                                            value
                                        )
                                    }
                                    value={styleChat.backgroundRecebida}
                                />
                            </FieldGroup>
                        </Field>

                        <Field>
                            <h2>Balão de fala: Quem responde</h2>
                            <FieldGroup>
                                <InputDefaultColor
                                    onChangeText={(value) =>
                                        handleStylesChat(
                                            'backgroundEnviada',
                                            value
                                        )
                                    }
                                    value={styleChat.backgroundEnviada}
                                    placeholder="Cor do botão"
                                />

                                <InputColorUi
                                    onChangeText={(value) =>
                                        handleStylesChat(
                                            'backgroundEnviada',
                                            value
                                        )
                                    }
                                    value={styleChat.backgroundEnviada}
                                />
                            </FieldGroup>
                        </Field>

                        <Field>
                            <h2>Cor Principal</h2>
                            <FieldGroup>
                                <InputDefaultColor
                                    onChangeText={(value) =>
                                        handleStylesChat('colorMain', value)
                                    }
                                    value={styleChat.colorMain}
                                    placeholder="Cor do botão"
                                />

                                <InputColorUi
                                    onChangeText={(value) =>
                                        handleStylesChat('colorMain', value)
                                    }
                                    value={styleChat.colorMain}
                                />
                            </FieldGroup>
                        </Field>
                    </CardContainer>
                </ContentLeft>

                <ContentRight>
                    <CardContainer>
                        <h2>Pré Visualização</h2>
                        <Chat
                            icon={styleChat.file}
                            backgroundColor={styleChat.colorMain}
                            height="566px"
                            styleButtonFinished={styleButtonMainDark}
                        >
                            <CardMessage
                                messageType="enviada"
                                hasView={true}
                                backgroundEnviada={styleChat.backgroundEnviada}
                                backgroundRecebida={
                                    styleChat.backgroundRecebida
                                }
                            >
                                <p>exemplo</p>
                                <span>11:43</span>
                                <IconShape />
                            </CardMessage>
                            <CardMessage
                                messageType="recebida"
                                hasView={false}
                                backgroundEnviada={styleChat.backgroundEnviada}
                                backgroundRecebida={
                                    styleChat.backgroundRecebida
                                }
                            >
                                <p>Outro Exemplo</p>
                                <span>11:43</span>
                                <IconShape />
                            </CardMessage>
                        </Chat>
                    </CardContainer>
                </ContentRight>
            </ContentConfigChat>

            <ContentButtonMain>
                <CardContainer>
                    <Field>
                        <h1>Configurações</h1>
                        <InputRadioDefault
                            flexDirection={'row'}
                            options={[
                                {
                                    label: 'Texto',
                                    value: 'text',
                                },
                                {
                                    label: 'Imagem',
                                    value: 'icon',
                                },
                                 /*{
                                    label: 'Texto + Icone',
                                    value: 'textIcon',
                                },*/
                            ]}
                            // value={inputRadio}
                            // onChange={(value) => setInputRadio(value)}
                            value={styleChat.icon}
                            onChange={(value) =>
                                handleStylesChat('icon', value)
                            }
                        />

                        <InputDefault
                            type="text"
                            onChangeText={(value) =>
                                handleStyles('textButton', value)
                            }
                            value={style.textButton}
                            placeholder="Texto do botão"
                        />

                        <InputDefault
                            type="text"
                            onChangeText={(value) =>
                                handleStyles('textButtonProActive', value)
                            }
                            value={style.textButtonProActive}
                            placeholder="Texto proativo"
                        />

                        <InputDefault
                            type="number"
                            onChangeText={(value) =>
                                handleStyles('textButtonProActiveTime', value)
                            }
                            value={style.textButtonProActiveTime}
                            placeholder="Tempo em segundos de aparição do texto proativo"
                        />

                        {/* {inputRadio === 'textIcon' && (
                            <>
                                <InputDefault
                                    type="text"
                                    onChangeText={(value) =>
                                        handleStyles('textButton', value)
                                    }
                                    value={style.textButton}
                                    placeholder="Texto do botão"
                                />
                                <InputDefault
                                    type="text"
                                    onChangeText={(value) =>
                                        handleStyles('icone', value)
                                    }
                                    value=""
                                    placeholder="Icone do botão"
                                />
                            </>
                        )}

                        {inputRadio === 'icon' && (
                            <InputDefault
                                type="text"
                                onChangeText={(value) =>
                                    handleStyles('icone', value)
                                }
                                value=""
                                placeholder="Icone do botão"
                            />
                        )} */}
                    </Field>

                    <Field>
                        <h3>Tamanho (pixel)</h3>
                        <FieldGroup>
                            <InputPixel
                                onChangeText={(value) =>
                                    handleStyles('width', value)
                                }
                                value={style.width}
                                title="Largura"
                            />
                            <InputPixel
                                onChangeText={(value) =>
                                    handleStyles('height', value)
                                }
                                value={style.height}
                                title="Altura"
                            />
                        </FieldGroup>
                        <FieldGroup>
                            <InputPixel
                                onChangeText={(value) =>
                                    handleStyles('borderRadius', value)
                                }
                                value={style.borderRadius}
                                title="Arredondamento"
                            />
                            <InputPixel
                                onChangeText={(value) =>
                                    handleStyles('border', value)
                                }
                                value={style.border}
                                title="Borda"
                            />
                        </FieldGroup>
                    </Field>

                    <Field>
                        <h3>Cor</h3>

                        <FieldGroup>
                            <InputDefaultColor
                                id="botton"
                                onChangeText={(value) =>
                                    handleStyles('backgroundColor', value)
                                }
                                value={style.backgroundColor}
                                title="Cor do botão"
                            />

                            <InputColorUi
                                onChangeText={(value) =>
                                    handleStyles('backgroundColor', value)
                                }
                                value={style.backgroundColor}
                            />
                        </FieldGroup>

                        <FieldGroup>
                            <InputDefaultColor
                                id="border"
                                onChangeText={(value) =>
                                    handleStyles('borderColor', value)
                                }
                                value={style.borderColor}
                                title="Cor da borda"
                            />

                            <InputColorUi
                                onChangeText={(value) =>
                                    handleStyles('borderColor', value)
                                }
                                value={style.borderColor}
                            />
                        </FieldGroup>

                        <FieldGroup>
                            <InputDefaultColor
                                id="border"
                                onChangeText={(value) =>
                                    handleStyles('color', value)
                                }
                                value={style.color}
                                title="Cor do texto"
                            />

                            <InputColorUi
                                onChangeText={(value) =>
                                    handleStyles('color', value)
                                }
                                value={style.color}
                            />
                        </FieldGroup>
                    </Field>
                </CardContainer>

                <CardContainer>
                    <h2>Botão Principal Chat</h2>

                    <Field>
                        {styleChat.icon === 'icon' ? (
                            <div className="sectionButtonImageChat" style={{
                                width: style.width,
                                height: style.height,
                                backgroundColor: style.backgroundColor,
                                borderRadius: style.borderRadius,
                                border: style.border + ' solid ' + style.borderColor,
                            }}>
                                <img className='buttonImageChat' src={styleChat.file} alt="Icone botao chat" />
                            </div>
                        ) : (
                            <ButtonConfig
                                styles={style}
                                Icon={
                                    inputRadio === 'textIcon' && 'icon'
                                        ? IconThumbUp
                                        : ''
                                }
                            >
                                {style.textButton}
                            </ButtonConfig>
                        )}

                    </Field>
                </CardContainer>
            </ContentButtonMain>

            <ContentButtonAction>

                <CardContainer>
                    <SectionTitleCard>

                    <h1>Botões de ação</h1>

                    <TitleRightCard>
                        <h1>Modo do chat</h1>
                        <InputRadioDefault
                                    flexDirection={'row'}
                                    options={[
                                        {
                                            label: 'Ação positiva',
                                            value: 'light',
                                        },
                                        {
                                            label: 'Ação negativa',
                                            value: 'dark',
                                        },
                                    ]}
                                    value={styleChat.theme}
                                    onChange={(value) =>
                                        handleStylesChat('theme', value)
                                    }
                                />
                    </TitleRightCard>

                    </SectionTitleCard>

                    { styleChat.theme === 'light' &&
                    <>
                    {/* <FieldGroup>
                        <InputPixel
                            onChangeText={(value) =>
                                handleStylesButtonMain('borderRadius', value)
                            }
                            value={styleButtonMain.borderRadius}
                            title="Arredondamento"
                        />
                        <InputPixel
                            onChangeText={(value) =>
                                handleStylesButtonMain('border', value)
                            }
                            value={styleButtonMain.border}
                            title="Borda"
                        />
                    </FieldGroup> */}

                    <FieldGroup>
                            {/* <InputPixel
                                onChangeText={(value) =>
                                    handleStylesButtonMain('width', value)
                                }
                                value={styleButtonMain.width}
                                title="Largura"
                            /> */}
                            <InputPixel
                                onChangeText={(value) =>
                                    handleStylesButtonMain('height', value)
                                }
                                value={styleButtonMain.height}
                                title="Altura"
                            />
                        </FieldGroup>
                        <FieldGroup>
                            <InputPixel
                                onChangeText={(value) =>
                                    handleStylesButtonMain('borderRadius', value)
                                }
                                value={styleButtonMain.borderRadius}
                                title="Arredondamento"
                            />
                            <InputPixel
                                onChangeText={(value) =>
                                    handleStylesButtonMain('border', value)
                                }
                                value={styleButtonMain.border}
                                title="Borda"
                            />
                        </FieldGroup>

                    <Field>
                        <h2>Cor</h2>

                        <FieldGroup>
                            <InputDefaultColor
                                id="botton"
                                onChangeText={(value) =>
                                    handleStylesButtonMain(
                                        'backgroundColor',
                                        value
                                    )
                                }
                                value={styleButtonMain.backgroundColor}
                                title="Cor do botão"
                            />

                            <InputColorUi
                                onChangeText={(value) =>
                                    handleStylesButtonMain(
                                        'backgroundColor',
                                        value
                                    )
                                }
                                value={styleButtonMain.backgroundColor}
                            />
                        </FieldGroup>

                        <FieldGroup>
                            <InputDefaultColor
                                id="botton"
                                onChangeText={(value) =>
                                    handleStylesButtonMain('borderColor', value)
                                }
                                value={styleButtonMain.borderColor}
                                title="Cor da borda"
                            />

                            <InputColorUi
                                onChangeText={(value) =>
                                    handleStylesButtonMain('borderColor', value)
                                }
                                value={styleButtonMain.borderColor}
                            />
                        </FieldGroup>

                        <FieldGroup>
                            <InputDefaultColor
                                id="border"
                                onChangeText={(value) =>
                                    handleStylesButtonMain('color', value)
                                }
                                value={styleButtonMain.color}
                                title="Cor do texto"
                            />

                            <InputColorUi
                                onChangeText={(value) =>
                                    handleStylesButtonMain('color', value)
                                }
                                value={styleButtonMain.color}
                            />
                        </FieldGroup>
                    </Field>

                    <Field>
                        <h2>Titulo</h2>

                        <FieldGroup>
                            <InputDefaultColor
                                id="textButtonAction"
                                onChangeText={(value) =>
                                    handleStylesButtonMain('textButtonAction', value)
                                }
                                value={styleButtonMain.textButtonAction && styleButtonMain.textButtonAction.length > 0 ? styleButtonMain.textButtonAction : 'Iniciar atendimento'}
                                title="Iniciar atendimento"
                                isText
                            />

                            <InputColorUi
                                type
                                onChangeText={(value) =>
                                    handleStylesButtonMain('textButtonAction', value)
                                }
                                value={styleButtonMain.textButtonAction}
                            />
                        </FieldGroup>

                        <FieldGroup>
                            <InputDefaultColor
                                id="textSendQuestion"
                                onChangeText={(value) =>
                                    handleStylesButtonMain('textSendQuestion', value)
                                }
                                value={styleButtonMain.textSendQuestion && styleButtonMain.textSendQuestion.length > 0 ? styleButtonMain.textSendQuestion : 'Enviar pesquisa'}
                                title="Enviar pesquisa"
                                isText
                            />

                            <InputColorUi
                                type
                                onChangeText={(value) =>
                                    handleStylesButtonMain('textSendQuestion', value)
                                }
                                value={styleButtonMain.textSendQuestion}
                            />
                        </FieldGroup>
                        
                    </Field>
                    </>
                    }

                    { styleChat.theme === 'dark' &&
                    <>
                    <Field>
                        <FieldGroup>

                            <InputPixel
                                onChangeText={(value) =>
                                    handleStylesButtonMainDark('height', value)
                                }
                                value={styleButtonMainDark.height}
                                title="Altura"
                            />
                        </FieldGroup>
                        <FieldGroup>
                            <InputPixel
                                onChangeText={(value) =>
                                    handleStylesButtonMainDark('borderRadius', value)
                                }
                                value={styleButtonMainDark.borderRadius}
                                title="Arredondamento"
                            />
                            <InputPixel
                                onChangeText={(value) =>
                                    handleStylesButtonMainDark('border', value)
                                }
                                value={styleButtonMainDark.border}
                                title="Borda"
                            />
                        </FieldGroup>

                        
                    </Field>

                    <Field>
                        <h2>Cor</h2>

                        <FieldGroup>
                            <InputDefaultColor
                                id="botton"
                                onChangeText={(value) =>
                                    handleStylesButtonMainDark(
                                        'backgroundColor',
                                        value
                                    )
                                }
                                value={styleButtonMainDark.backgroundColor}
                                title="Cor do botão"
                            />

                            <InputColorUi
                                onChangeText={(value) =>
                                    handleStylesButtonMainDark(
                                        'backgroundColor',
                                        value
                                    )
                                }
                                value={styleButtonMainDark.backgroundColor}
                            />
                        </FieldGroup>

                        <FieldGroup>
                            <InputDefaultColor
                                id="botton"
                                onChangeText={(value) =>
                                    handleStylesButtonMainDark(
                                        'borderColor',
                                        value
                                    )
                                }
                                value={styleButtonMainDark.borderColor}
                                title="Cor da borda"
                            />

                            <InputColorUi
                                onChangeText={(value) =>
                                    handleStylesButtonMainDark(
                                        'borderColor',
                                        value
                                    )
                                }
                                value={styleButtonMainDark.borderColor}
                            />
                        </FieldGroup>

                        <FieldGroup>
                            <InputDefaultColor
                                id="border"
                                onChangeText={(value) =>
                                    handleStylesButtonMainDark('color', value)
                                }
                                value={styleButtonMainDark.color}
                                title="Cor do texto"
                            />

                            <InputColorUi
                                onChangeText={(value) =>
                                    handleStylesButtonMainDark('color', value)
                                }
                                value={styleButtonMainDark.color}
                            />
                        </FieldGroup>
                    </Field>

                    <Field>
                        <h2>Titulo</h2>
                        <FieldGroup>
                            <InputDefaultColor
                                id="texttextNotSendQuestionButton"
                                onChangeText={(value) =>
                                    handleStylesButtonMainDark('textNotSendQuestion', value)
                                }
                                value={styleButtonMainDark.textNotSendQuestion && styleButtonMainDark.textNotSendQuestion.length > 0 ? styleButtonMainDark.textNotSendQuestion : 'Não responder a pesquisa'}
                                title="Não responder pesquisa"
                                isText
                            />

                            <InputColorUi
                                type
                                onChangeText={(value) =>
                                    handleStylesButtonMainDark('textNotSendQuestion', value)
                                }
                                value={styleButtonMainDark.textNotSendQuestion}
                            />

                        </FieldGroup>

                        <FieldGroup>
                            <InputDefaultColor
                                id="textFinishedAttendence"
                                onChangeText={(value) =>
                                    handleStylesButtonMainDark('textFinishedAttendence', value)
                                }
                                value={styleButtonMainDark.textFinishedAttendence && styleButtonMainDark.textFinishedAttendence.length > 0 ? styleButtonMainDark.textFinishedAttendence : 'Finalizar atendimento'}
                                title="Finalizar atendimento"
                                isText
                            />

                            <InputColorUi
                                type
                                onChangeText={(value) =>
                                    handleStylesButtonMainDark('textFinishedAttendence', value)
                                }
                                value={styleButtonMainDark.textFinishedAttendence}
                            />

                        </FieldGroup>
                    </Field>
                    </>
                    }
                </CardContainer>

                <CardContainer>
                    <h2>Botões de ação</h2>

                    <Field>
                        { styleChat.theme === 'light' && 

                            <>
                                <ButtonConfig
                                    styles={styleButtonMain}
                                    Icon={
                                        inputRadio === 'textIcon' && 'icon'
                                            ? IconThumbUp
                                            : ''
                                    }
                                >
                                    {styleButtonMain.textButtonAction ?? 'Iniciar atendimento'}
                                </ButtonConfig>

                                <ButtonConfig
                                    styles={styleButtonMain}
                                    Icon={
                                        inputRadio === 'textIcon' && 'icon'
                                            ? IconThumbUp
                                            : ''
                                    }
                                >
                                    {styleButtonMain.textSendQuestion ?? 'Enviar pesquisa'}
                                </ButtonConfig>
                            </>
                        }

                        { styleChat.theme === 'dark' &&
                            <>
                                <ButtonConfig
                                    styles={styleButtonMainDark}
                                    Icon={
                                        inputRadio === 'textIcon' && 'icon'
                                            ? IconThumbUp
                                            : ''
                                    }
                                >
                                    {styleButtonMainDark.textNotSendQuestion ?? 'Não enviar pesquisa'}
                                </ButtonConfig>

                                <ButtonConfig
                                    styles={styleButtonMainDark}
                                    Icon={
                                        inputRadio === 'textIcon' && 'icon'
                                            ? IconThumbUp
                                            : ''
                                    }
                                >
                                    {styleButtonMainDark.textFinishedAttendence ?? 'Finalizar atendimento'}
                                </ButtonConfig>
                            </>
                        }
                    </Field>
                </CardContainer>
            </ContentButtonAction>

            
        </ContainerEdit>
        </>
    );
}
