/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import ButtonLinear from '../../components/form/ButtonLinear';
import ContainerFilter from '../../components/form/ContainerFilter';
import SelectDefault from '../../components/form/SelectDefault';
import Column from '../../components/grid/Columns';
import Row from '../../components/grid/Row';
import { useRoutes } from '../../contexts/RoutesContext';
import { IFilterCard } from '../../types/IFilterCard';
import { Container } from './style';

import TableSimple from '../../components/TableSimple';
import CardContainer from '../../components/layout/containers/CardContainer';
import { useMessage } from '../../contexts/MessagesContext';
import Loading from '../../components/Loading/Loading';
import 'react-loading-skeleton/dist/skeleton.css';
import InputDefault from '../../components/form/InputDefault';
import moment from 'moment';
import { GrupoAtendimento } from '../../types/GrupoAtendimento';
import apiTropa from '../../services/apiTropa';
import ModalDefault from '../../components/ModalDefault';

import Field from '../../components/layout/containers/Field';
import { ContainerQuestion, TitleQuestion } from '../PageChat/styles';
import InputRating from '../../components/form/InputRating';
import { ContainerQuestionItem } from '../GruposAtendimento/pesquisaSatisfacao/styles';
import InputTextarea from '../../components/form/InputTextarea';
import InputRadioDefault from '../../components/form/InputRadioDefault';
import InputSwitchDefault from '../../components/form/InputSwitchDefault';

export default function PesquisaSatisfacao() {
    const { setPageName } = useRoutes();
    const { addDanger } = useMessage();

    const [loading, setLoading] = useState(false);

    const [modal, setModal] = useState(false);

    const [grupos, setGrupos] = useState<Array<GrupoAtendimento>>([]);
    const [filter, setFilter] = useState<IFilterCard>({
        dataInicio: moment().days(-7).startOf('day').format('YYYY-MM-DDTHH:mm'),
        dataFim: moment().endOf('day').format('YYYY-MM-DDTHH:mm'),
    });

    const [atendimentos, setAtendimentos] = useState<Array<any>>([]);

    const [questions, setquestions] = useState<Array<any>>([]);

    const setDadosFilter = (key: string, value: any) => {
        let input: any = filter;
        input[key] = value;
        setFilter({ ...input });
    };

    useEffect(() => {
        setPageName('Pesquisas Respondidas');
        getGrupos();
        getDashBoardData();
    }, [setPageName]);

    async function getGrupos() {
        try {
            let response: any = await apiTropa.get('grupo-get');

            setGrupos([...response.data.data]);
        } catch (e) {
            addDanger('Ocorreu um erro', 'Tente novamente mais tarde');
        }
    }

    async function getDashBoardData() {
        try {
            setLoading(true);

            let totalAtendimentos: any = await apiTropa.get('/dashboard/resposta-pesquisas', { params: filter });
            setAtendimentos(totalAtendimentos.data.data);

            setLoading(false);
        } catch (err: any) {
            if (err.response) {
                addDanger('Atenção', String(err.response.data.message));
            } else {
                addDanger('Atenção', String(err));
            }
            setTimeout(function () {
                //  history.push('/grupo-atendimento/'+props.match.params.id)
            }, 2000);
        }
    }

    async function chamaModal(questions: any, respostas: any) {
        setModal(true);

        for await (let question of questions) {
            if (question.type === 'radio') {
                question.resposta = respostas[question.id][0];
            } else {
                question.resposta = respostas[question.id];
            }
        }
        setquestions(questions);
    }

    return (
        <>
            {loading === true ? (
                <Loading active={loading} />
            ) : (
                <Container>
                    <ContainerFilter>
                        <label style={{ color: '#fff' }}>
                            <InputDefault
                                title="De"
                                placeholder="de"
                                onChangeText={(value) => setDadosFilter('dataInicio', value)}
                                value={filter.dataInicio}
                                type={'datetime-local'}
                            />
                        </label>
                        <label style={{ color: '#fff' }}>
                            <InputDefault
                                title="Até"
                                placeholder="ate"
                                onChangeText={(value) => setDadosFilter('dataFim', value)}
                                value={filter.dataFim}
                                type={'datetime-local'}
                            />
                        </label>
                        <label style={{ color: '#fff' }}>
                            <SelectDefault
                                value={filter.id_grupo_atendimento}
                                placeholder="Grupos de Atendimento"
                                onChangeText={(value) => setDadosFilter('id_grupo_atendimento', value)}
                            >
                                {grupos.map((row, key) => (
                                    <option value={row.id_grupo_atendimento} key={key}>
                                        {row.grupo_atendimento}
                                    </option>
                                ))}
                            </SelectDefault>
                        </label>
                        <label style={{ color: '#fff' }}>
                            <ButtonLinear className="info" onClick={() => getDashBoardData()}>
                                Aplicar
                            </ButtonLinear>
                        </label>
                    </ContainerFilter>

                    <Row>
                        <Column width="12">
                            <CardContainer>
                                <p className="title-card">
                                    Total de Pesquisas <u>({atendimentos.length} registros encontrados)</u>
                                </p>

                                <TableSimple>
                                    <thead>
                                        <tr>
                                            <th>Operador</th>
                                            <th>Chatbot</th>
                                            <th>Protocolo</th>
                                            <th>Grupo Atendimento</th>
                                            <th>Inicio</th>
                                            <th>Respostas</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {atendimentos.map((row, key) => (
                                            <tr key={key}>
                                                <td>{row.nome ? row.nome : row.apelido}</td>
                                                <td>{row.chatbot}</td>
                                                <td>{row.protocolo}</td>
                                                <td>{row.grupo_atendimento}</td>
                                                <td>
                                                    {moment(row.data_entrada).utc().format('HH[h]:mm - DD-MM-YYYY')}
                                                </td>
                                                <td>
                                                    <ButtonLinear
                                                        className="info"
                                                        onClick={() => chamaModal(row.configuracao, row.resposta)}
                                                    >
                                                        Ver respostas
                                                    </ButtonLinear>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </TableSimple>
                            </CardContainer>
                        </Column>
                    </Row>

                    <ModalDefault width="500" id="overlayModal" onClose={() => setModal(!modal)} openModal={modal}>
                        <Field style={{ color: '#fff' }}>
                            <label>Respostas</label>

                            {questions.map((question: any, key: any) => (
                                <>
                                    <TitleQuestion>
                                        <h2>{question.title}</h2>
                                    </TitleQuestion>

                                    {(question.type === 'checkbox' || question.type === 'radio') && (
                                        <ContainerQuestion>
                                            {question.items.map((item: any, itemKey: any) => (
                                                <ContainerQuestionItem key={itemKey}>
                                                    {item.type === 'checkbox' && (
                                                        <InputSwitchDefault
                                                            onChange={(value) => true}
                                                            options={[
                                                                {
                                                                    label: item.value,
                                                                    value: item.value,
                                                                },
                                                            ]}
                                                            value={question.resposta}
                                                        />
                                                    )}

                                                    {item.type === 'radio' && (
                                                        <InputRadioDefault
                                                            onChange={(value) => true}
                                                            options={[
                                                                {
                                                                    label: item.value,
                                                                    value: item.value,
                                                                },
                                                            ]}
                                                            value={question.resposta}
                                                        />
                                                    )}

                                                    {item.type === 'textarea' && (
                                                        <div style={{ width: '100%' }}>
                                                            <p style={{ marginBottom: 10 }}>{item.value}</p>
                                                            <InputTextarea
                                                                readOnly={true}
                                                                onChangeText={(value) => console.log(value)}
                                                                value={question.resposta}
                                                                placeholder={item.value}
                                                            />
                                                        </div>
                                                    )}
                                                </ContainerQuestionItem>
                                            ))}
                                        </ContainerQuestion>
                                    )}

                                    {question.type === 'rating' && (
                                        <ContainerQuestion>
                                            <InputRating
                                                min={question.min}
                                                max={question.max}
                                                onChangeText={(value) => console.log('alala')}
                                                value={question.resposta}
                                                readOnly={true}
                                            />
                                        </ContainerQuestion>
                                    )}
                                </>
                            ))}
                        </Field>

                        <ButtonLinear rounded={true} className="danger" onClick={() => setModal(false)}>
                            Fechar
                        </ButtonLinear>
                    </ModalDefault>
                </Container>
            )}
        </>
    );
}
