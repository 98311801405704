import styled, { css } from 'styled-components';

interface FieldProps {
    gap?: string;
    marginTop?: string | boolean;
}

export const Label = styled.div`
    font-size: 12px;
    font-weight: bold;
    color: #B6B6C5;
`;

export default styled.div<FieldProps>`
    display: flex;
    flex-direction: column;

    width: 100%;
    flex: 1;
    gap: ${props => props.gap ? props.gap : '16px'};
    margin-top: ${props => props.marginTop === true ? "24px" : (props.marginTop ? props.marginTop : '0')};

    @media (max-width: 425px) {
        label {
            width: 300px;           
        }
    }

    .sectionButtonImageChat {
        display: flex;
        align-items: center;
        justify-content: center;

        .buttonImageChat {
            object-fit: cover;
            height: 100%;
            padding: 4px;
        }
    }
`
