// (00) 00000-0000
function maskPhone(e: any) {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  value = value.substring(0, 12);
  value = value.replace(/(\d{2})(\d)/, "($1) $2");
  value = value.replace(/(\d{5})(\d{4})(\d)/, "$1-$2");

  e.currentTarget.value = value;
  return e;
}

function maskCNPJ(e: any) {
  var x = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
  e.target.value = !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');
  return x
}


// 00000-000
function maskCEP(e: any) {
  let value = e.currentTarget.value;
  // value = value.substring(0, 9);
  value = value.replace(/\D/g, "").replace(/^(\d{5})(\d{3})+?$/, "$1-$2");

  e.currentTarget.value = value;
  return e;
}

// 00/00/0000
function maskDate(e: any) {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{2})(\d)/, "$1/$2");
  value = value.replace(/(\d{2})(\d)/, "$1/$2");
  value = value.replace(/(\d{4})(\d)/, "$1");

  e.currentTarget.value = value;
  return e;
}

function maskCPF(e: any) {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{3})(\d)/, "$1.$2");
  value = value.replace(/(\d{3})(\d)/, "$1.$2");
  value = value.replace(/(\d{3})(\d{1,2})/, "$1-$2");
  value = value.replace(/(-\d{2})\d+?$/, "$1");

  e.currentTarget.value = value;
  return e;
}

export { maskCPF, maskDate, maskCEP, maskPhone, maskCNPJ };
