import moment from 'moment';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { io } from 'socket.io-client';
import { useUser } from './UserContext';

interface SocketContextData {
    socket: any;
    socketStatus: boolean;
    onInit(): void;
    setLog(name: string, message: any): void;
    logsConsole: any[];
}

const SocketContext = createContext<SocketContextData>({} as SocketContextData);

export const SocketProvider: React.FC = ({ children }) => {
    const [socketStatus, setSocketStatus] = useState<boolean>(false);
    const socket = useRef<any>();

    const [logsConsole, setLogsConsole] = useState<any[]>([]);
    const [logsConvertedTextarea, setLogsConvertedTextarea] = useState<any>('');

    function setLog(name: any, message: any) {
        //console.log(name, message);
        logsConsole.push({
            date: moment().format(),
            name: name,
            message: message.toString(),
        });
        setLogsConsole([...logsConsole]);
    }

    function keyDown(e: any) {
        let charStr,
            key = e.which || e.keyCode;
        if (key >= 112 && key <= 123) {
            e.preventDefault();
            e.stopPropagation();
            charStr = 'F' + (key - 111);
            switch (charStr) {
                case 'F1':
                    copyLog();
                    break;
            }
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', keyDown);
        return () => document.removeEventListener('keydown', keyDown);
    }, []);

    function copyLog() {
        let copyText: any = document.getElementById('all-logs');

        // Select the text field
        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile devices

        // Copy the text inside the text field
        navigator.clipboard.writeText(copyText.value);
    }

    useEffect(() => {
        let newMessage: string = '';
        logsConsole.map((row) => {
            newMessage += row.date + ':' + row.name + ' - ' + row.message + '\n';
        });
        setLogsConvertedTextarea(newMessage);
    }, [logsConsole]);

    useEffect(() => {
        setLog('socket.update.state', socketStatus.toString());
    }, [socketStatus]);

    async function onInit() {
        if (process.env.NODE_ENV === 'development') {
            connectionSocket('dev');
        } else {
            connectionSocket('homolog');
        }

        socket.current.on('connect', () => {
            setSocketStatus(true);
        });

        socket.current.on('update', (data: any) => setLog('socket.update', data));
        socket.current.on('connect_error', (err: any) => handleErrors(err));
        socket.current.on('connect_failed', (err: any) => handleErrors(err));
        socket.current.on('disconnect', (err: any) => handleErrors(err));
    }

    function connectionSocket(type: 'dev' | 'homolog' | 'production') {
        if (type === 'dev') {
            // socket.current = io('socketomni.backendtropa.com.br')

            socket.current = io('https://api.eyou.com.br/', {
                path: '/bin/apiMiddleware/socket/',
                rememberUpgrade: false,
            });
        } else if (type === 'homolog') {
            socket.current = io('https://api.eyou.com.br/', {
                path: '/bin/apiMiddleware/socket/',
                rememberUpgrade: false,
            });
        } else if (type === 'production') {
            socket.current = io('https://api.eyou.com.br/', {
                path: '/bin/apiMiddleware/socket/',
                rememberUpgrade: false,
            });
        }
    }

    function handleErrors(err: any) {
        console.log('err', err);
    }

    return (
        <SocketContext.Provider value={{ socket, socketStatus, onInit, setLog, logsConsole }}>
            {children}
            <textarea
                style={{
                    position: 'fixed',
                    left: -9999990,
                    top: -99999990,
                    opacity: 0,
                    visibility: 'hidden',
                }}
                id="all-logs"
                value={logsConvertedTextarea}
            />
        </SocketContext.Provider>
    );
};

export function useSocket() {
    const context = useContext(SocketContext);
    return context;
}

export default SocketContext;
