/* eslint-disable no-throw-literal */
import { useEffect, useState } from 'react';
import { useRoutes } from '../../contexts/RoutesContext';
import { useMessage } from '../../contexts/MessagesContext';

import { Container, Main, EditButton } from './styles';
import apiEyou from '../../services/apiEyou';
import { UserEyou } from '../../types/UserEyou';
import { PerfilTropa } from '../../types/PerfilTropa';
import Column from '../../components/grid/Columns';
import Row from '../../components/grid/Row';
import CardContainer from '../../components/layout/containers/CardContainer';
import InputReadOnly from '../../components/form/InputReadOnly';
import ContainerFilter from '../../components/form/ContainerFilter';
import BackPage from '../../components/layout/UIElements/BackPage';
import ButtonLinear from '../../components/form/ButtonLinear';
import { Link } from 'react-router-dom';
import apiTropa from '../../services/apiTropa';
import { useHistory } from 'react-router-dom';
import InputSwitchDefault from '../../components/form/InputSwitchDefault';
import { ButtonClose, CardOperator, Group, Header, Info, NameGroup } from '../GruposAtendimento/operadores/styles';
import { Title } from '../AttendenceOperator/styles';
import TableDefault from '../../components/TableDefault';
import { Td, TdDescription, TdSubtitle } from '../../components/layout/containers/Td';
import { IconClose, IconConfig, IconPlus } from '../../components/icons';
import ModalDefault from '../../components/ModalDefault';
import Field, { Label } from '../../components/layout/containers/Field';
import SelectDefault from '../../components/form/SelectDefault';
import FieldGroup from '../../components/layout/containers/FieldGroup';
import InputDefaultTime from '../../components/form/InputDefaultTime';
import { Separator } from '../../components/layout/containers/Separator';
import InputDefault from '../../components/form/InputDefault';
import InputRadioDefault from '../../components/form/InputRadioDefault';
import { useTheme } from 'styled-components';

interface Props {
    match: any;
}

interface IDTOPausa {
    descricao: string;
    inicio: string;
    fim: string;
    // categoria_pausa: string;
}

interface IDTO {
    id_operador: number;
    data: '' | 'segunda-feira' | 'terca-feira' | 'quarta-feira' | 'quinta-feira' | 'sexta-feira' | 'sabado' | 'domingo';
    horarios: {
        inicio: string;
        fim: string;
        pausa: IDTOPausa[];
    };
}

const UsuarioVisualizar: React.FC<Props> = (props) => {
    let history = useHistory();
    const { setPageName, getProfile } = useRoutes();
    const { addSuccess, addDanger, addConfirm } = useMessage();
    const {title} = useTheme()
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);

    //userEyou que vem com os dados do eyou
    const [userEyou, setUserEyou] = useState<UserEyou>({} as UserEyou);

    //userEyou que vem com os dados do omni tropa
    const [userTropa, setUserTropa] = useState<PerfilTropa>({} as PerfilTropa);

    const [operador, setOperador] = useState<any>();
    const [DTO, setDTO] = useState<IDTO>({
        id_operador: 0,
        data: '',
        horarios: {
            inicio: '',
            fim: '',
            pausa: [],
        },
    });

    useEffect(() => {
        getOperador();
    }, [loading]);

    useEffect(() => {
        setPageName('Usuário');

        if (props.match.params.id && props.match.params.id !== '0') {
            getuserEyouById(props.match.params.id);
        } else {
            history.push('/usuarios');
        }
    }, [setPageName, props]);


    function getOperador() {
        apiTropa
            .post(`operador/detalhe/${userTropa.id_login}`, {
                tokenEyou: window.sessionStorage.getItem('tokenInicial'),
            })
            .then((response: any) => {
                const operador = response.data.data;
                if (operador.horarios.find((row: any) => row.data === 'domingo') === undefined)
                    operador.horarios.splice(0, 0, { data: 'domingo' });
                if (operador.horarios.find((row: any) => row.data === 'segunda-feira') === undefined)
                    operador.horarios.splice(1, 0, { data: 'segunda-feira' });
                if (operador.horarios.find((row: any) => row.data === 'terca-feira') === undefined)
                    operador.horarios.splice(2, 0, { data: 'terca-feira' });
                if (operador.horarios.find((row: any) => row.data === 'quarta-feira') === undefined)
                    operador.horarios.splice(3, 0, { data: 'quarta-feira' });
                if (operador.horarios.find((row: any) => row.data === 'quinta-feira') === undefined)
                    operador.horarios.splice(4, 0, { data: 'quinta-feira' });
                if (operador.horarios.find((row: any) => row.data === 'sexta-feira') === undefined)
                    operador.horarios.splice(5, 0, { data: 'sexta-feira' });
                if (operador.horarios.find((row: any) => row.data === 'sabado') === undefined)
                    operador.horarios.splice(6, 0, { data: 'sabado' });

                setOperador(operador);
                // setPageName(`Operador ${operador.perfil[0]?.nome}`);
                setLoading(false);
            });
    }

    async function getuserEyouById(login: String) {
        try {
            let tokenInicial = window.sessionStorage.getItem('tokenInicial');

            let userEyouResult: any = await apiEyou.post(
                'apiUsuario',
                {
                    type: 'get',
                    function: 'listAllUsers',
                    data: {
                        filter: true,
                        login: login,
                    },
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + tokenInicial,
                    },
                }
            );

            //usuario eyou recuperado do eyou
            let userEyouRecuperado: UserEyou = {};

            if(userEyouResult.data.data.length > 0){
				userEyouResult.data.data.map((row:any) => {
					if(row.login == login){
						userEyouRecuperado = row
					}
				})
			}



            let tokenDecryptUser: any = await apiEyou.post(
                '/apiOmni',
                {
                    type: 'get',
                    function: 'aesDecrypt',
                    data: {
                        toDecrypt: JSON.stringify(userEyouRecuperado.idUser),
                    },
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + tokenInicial,
                    },
                }
            );

            // id usuario decrypy
            userEyouRecuperado.idUserDecrypt = tokenDecryptUser.data.data.decrypt;

            let tokenFinal: any = window.sessionStorage.getItem('token');
            let userTropaRecuperado: any = await apiTropa.get('perfil/usuario/' + userEyouRecuperado.idUserDecrypt, {
                headers: {
                    Authorization: 'Bearer ' + tokenFinal,
                },
            });

            //preenche os states
            setUserEyou(userEyouRecuperado);
            setUserTropa(userTropaRecuperado.data.data);
            setLoading(true)
        } catch (e) {
            addDanger('Ocorreu um erro', 'usuario não foi encontrado');
        }
    }

    

    function onChangeDTO(key: any, value: any, parent?: any) {
        const copy = DTO as any;
        if (parent) {
            copy[parent][key] = value;
        } else {
            copy[key] = value;
        }
        setDTO(copy);
    }

    function onChangeDTOPausas(key: any, value: any, index: any) {
        const copy = DTO as any;
        copy.horarios.pausa[index][key] = value;
        setDTO(copy);
    }

    function onClickEditarHorario(DTO: any) {
        const copy = {
            id_operador: userTropa.id_login,
            data: DTO.data,
            horarios: DTO.horarios
                ? DTO.horarios
                : {
                      inicio: '',
                      fim: '',
                      pausa: [],
                  },
        };

        setDTO({ ...copy });
        setModal(true);
    }

    function adicionarPausa() {
        DTO.horarios.pausa.push({
            descricao: '',
            inicio: '',
            fim: '',
        });
        setDTO({ ...DTO });
    }

    function removerPausa(index: any) {
        DTO.horarios.pausa.splice(index, 1);
        setDTO({ ...DTO });
    }

    async function salvarHorarios() {
        try {
            setLoading(true);

            await apiTropa.post(`/programacao-operador`, DTO);
            setModal(false);
            getOperador();
            addSuccess('Sucesso', 'Edição realizada!');
        } catch (err: any) {
            if (err.response) {
                addDanger('Atenção', String(err.response.data.message));
            } else {
                addDanger('Atenção', String(err));
            }
        }
    }

    async function removerOperadorDoGrupo(id_grupo_atendimento:any) {
        try {

            setLoading(true)
            await apiTropa.delete(`/grupo-atendimento/operador/remover`, {
                data: {
                    id_grupo_atendimento: id_grupo_atendimento,
                    id_operador: userEyou.idUserDecrypt
                }
            })
            
            addSuccess('Confirmado', 'Operador removido')
            getOperador()
            
        } catch (err:any) {
            if( err.response ){
                addDanger('Atenção', String(err.response.data.message))
            } else {
                addDanger('Atenção', String(err))
            }
        }
    }

    async function handleOnPrioridade(keyGrupo:any){

        operador.grupos.map((row:any, key:any) => {
            if ( key === keyGrupo ) {
                row.prioridade = true
            } else {
                row.prioridade = false
            }
        })

        setOperador({...operador})

        const grupoPrioridade = operador.grupos.filter((obj:any) => 
            obj.prioridade === true      
        )

        const DTO:any = operador.perfil[0]
            DTO.prioridade_grupo = grupoPrioridade.length > 0 ? grupoPrioridade[0].id_grupo_atendimento : false

        await apiTropa.put(
            'perfil/usuario/'+DTO.id_perfil_permissao,
            DTO
        )
    }

    return (
        <Container>
            <Row>
                <Column width="11">
                    <ContainerFilter>
                        <BackPage />
                    </ContainerFilter>
                </Column>
                <Column width="1">
                    <Link to={'/usuarios/editar/' + userEyou.login}>
                        <EditButton>
                            <ButtonLinear className="info">Editar</ButtonLinear>
                        </EditButton>
                    </Link>
                </Column>
            </Row>

            <Main>
                <Row>
                    <Column width="7">
                        <CardContainer>
                            <Row mb="3">
                                <Column width="9">
                                    <div
                                        className="image"
                                        style={{
                                            backgroundImage:
                                                userTropa && userTropa.foto ? 'url(' + userTropa.foto + ')' : 'url()',
                                            backgroundSize: 'cover',
                                        }}
                                    >
                                        <svg
                                            width="114"
                                            height="70"
                                            viewBox="0 0 114 70"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M112 2C112 14.6736 108.351 27.0792 101.489 37.7345C94.6272 48.3898 84.8425 56.844 73.3043 62.0872C61.7661 67.3303 48.9623 69.1405 36.4229 67.3015C23.8834 65.4624 12.1386 60.052 2.5918 51.7166"
                                                stroke="#34D5FA"
                                                stroke-width="4"
                                                stroke-linecap="round"
                                            />
                                        </svg>
                                    </div>
                                </Column>
                                <Column width="3">
                                    <span className="status-user">
                                        { userEyou.status !== 'ativo' ? 'Usuario inativo' : 'Usuario ativo'}
                                    </span>
                                </Column>
                            </Row>
                            <Row mt="5">
                                <Column width="6">
                                    <InputReadOnly label="Nome" value={userEyou.name} bold={true} />
                                </Column>
                                <Column width="6">
                                    <InputReadOnly label="Apelido" value={userTropa.apelido} bold={true} />
                                </Column>
                            </Row>
                            <Row mt="5">
                                <Column width="6">
                                    <InputReadOnly label="Telefone" value={userEyou.phone} />
                                </Column>
                                <Column width="6">
                                    <InputReadOnly label="Celular" value={userEyou.mobilePhone} />
                                </Column>
                            </Row>
                            <Row mt="5">
                                <Column width="6">
                                    <InputReadOnly label="E-mail" value={userEyou.email} />
                                </Column>
                                <Column width="6">
                                    <InputReadOnly label="Login" value={userEyou.login} />
                                </Column>
                            </Row>
                        </CardContainer>
                    </Column>
                    <Column width="5">
                        <CardContainer>
                            <p className="title-card">Perfil</p>
                            <p className="title-section">{userTropa && userTropa.perfil ? userTropa.perfil : ''}</p>
                            <div className="hr-dashed" />
                        </CardContainer>
                    </Column>
                </Row>

                <Row mt='5'>
                    <Column width="7">
                            <CardOperator>
                                <Title>
                                    <h2>Horários de Atendimento</h2>
                                </Title>
                                {operador && (
                                    <>
                                        {operador.horarios.map((row: IDTO, key: any) => (
                                            <TableDefault isArrow={false} columns={4} key={key}>
                                                <Td flexDirection="column">
                                                    <TdDescription>Dia da semana</TdDescription>
                                                    <TdSubtitle>{row.data}</TdSubtitle>
                                                </Td>
                                                <Td flexDirection="column">
                                                    <TdDescription>Horários</TdDescription>
                                                    <TdSubtitle>
                                                        {row.horarios?.inicio
                                                            ? row.horarios.inicio + ' às ' + row.horarios.fim
                                                            : 'Não definido'}
                                                    </TdSubtitle>
                                                </Td>
                                                <Td flexDirection="column">
                                                    <TdDescription>Pausas</TdDescription>
                                                    <TdSubtitle>
                                                        {!row.horarios || !row.horarios.pausa
                                                            ? 'Não definido'
                                                            : row.horarios.pausa.map((pausa: any) => (
                                                                  <p >
                                                                      {pausa.descricao} - {pausa.inicio} às {pausa.fim}
                                                                  </p>
                                                              ))}
                                                    </TdSubtitle>
                                                </Td>
                                                <Td flexDirection="column" style={{ justifySelf: 'right', width: '60px', marginRight: '2px'  }}>
                                                    <ButtonLinear
                                                        color={title === 'light' ? '#8A99A8' : '#fff'}
                                                        onClick={() => onClickEditarHorario(row)}
                                                    >
                                                        <IconConfig/>
                                                    </ButtonLinear>
                                                </Td>
                                            </TableDefault>
                                        ))}
                                    </>
                                )}
                            </CardOperator>
                    </Column>

                    <Column width='5'>
                        <CardOperator>
                            <Title>
                                <h2>Grupos que atende</h2>
                            </Title>

                        <Info>
                            {operador?.grupos.map((row:any, key:any) => (
                                <Group key={key}>
                                    <NameGroup>
                                        <h2>Grupo</h2>
                                        <span>{row.grupo_atendimento}</span>
                                        <InputRadioDefault
                                            options={[
                                                {
                                                    label: 'Priorizar grupo',
                                                    value: row.id_grupo_atendimento
                                                },
                                            ]}
                                            onChange={() => handleOnPrioridade(key)}
                                            value={operador.perfil[0].prioridade_grupo === row.id_grupo_atendimento ? row.id_grupo_atendimento : false}
                                        />
                                    </NameGroup>

                                    <ButtonLinear
                                        onClick={() =>
                                            addConfirm({
                                                title: 'Atenção',
                                                description: 'Você tem certeza que deseja remover o operador desse grupo?',
                                                onConfirm: () => removerOperadorDoGrupo(row.id_grupo_atendimento),
                                                textButtonCancel: 'Não',
                                                textButtonConfirm: 'Sim',
                                            })
                                        }
                                    >
                                        <IconClose color={title === 'light' ? '#ff868b' : '#fff'} />
                                    </ButtonLinear>
                                </Group>
                            ))}
                        </Info>
                    </CardOperator>
                    </Column>
                </Row>
            </Main>
            <ModalDefault id="overlayModal" width="550px" onClose={() => setModal(!modal)} openModal={modal}>
                <Field gap="5px">
                    <Label>Dia da Semana</Label>
                    <SelectDefault value={DTO.data} onChangeText={(value) => onChangeDTO('data', value)}>
                        <option value="domingo" disabled={true}>
                            Domingo
                        </option>
                        <option value="segunda-feira" disabled={true}>
                            Segunda-Feira
                        </option>
                        <option value="terca-feira" disabled={true}>
                            Terça-Feira
                        </option>
                        <option value="quarta-feira" disabled={true}>
                            Quarta-Feira
                        </option>
                        <option value="quinta-feira" disabled={true}>
                            Quinta-Feira
                        </option>
                        <option value="sexta-feira" disabled={true}>
                            Sexta-Feira
                        </option>
                        <option value="sabado" disabled={true}>
                            Sábado
                        </option>
                    </SelectDefault>
                </Field>

                <Field gap="5px" marginTop="16px">
                    <Label>Defina os horários de inicio e fim</Label>
                    <FieldGroup>
                        <InputDefaultTime
                            value={DTO.horarios.inicio}
                            onChangeText={(value) => onChangeDTO('inicio', value, 'horarios')}
                        />
                        <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#fff' }}>às</span>
                        <InputDefaultTime
                            value={DTO.horarios.fim}
                            onChangeText={(value) => onChangeDTO('fim', value, 'horarios')}
                        />
                    </FieldGroup>
                </Field>

                <br />
                <Header>
                    <Title>Configurar Pausas</Title>
                    <ButtonLinear onClick={() => adicionarPausa()} > <IconPlus color={title === 'light' ? '#8A99A8' : '#fff'} /> </ButtonLinear>
                </Header>
                <Separator margin="15px 0" />

                {DTO.horarios.pausa.map((row: IDTOPausa, key: any) => (
                    <>
                        <FieldGroup>
                            <div style={{ width: '480px' }}>
                                <Field gap="5px">
                                    <Label>Descrição</Label>
                                    <InputDefault
                                        value={row.descricao}
                                        onChangeText={(value) => onChangeDTOPausas('descricao', value, key)}
                                    />
                                </Field>

                                {/* <Field gap="5px" marginTop="12px">
                                    <Label>Tipo de pausa</Label>
                                    <SelectDefault value={DTO.horarios.pausa.map((row) => row.categoria_pausa)[key]} onChangeText={(value) => onChangeDTOPausas('categoria_pausa', value, key)}>
                                        <option value="0">
                                            Selecione
                                        </option>
                                        <option value="produtiva">
                                            Produtiva
                                        </option>
                                        <option value="improdutiva">
                                            Improdutiva
                                        </option>
                                    </SelectDefault>
                                </Field> */}

                                <Field gap="5px" marginTop="15px">
                                    <Label>Defina os horários de inicio e fim</Label>
                                    <FieldGroup>
                                        <InputDefaultTime
                                            value={row.inicio}
                                            onChangeText={(value) => onChangeDTOPausas('inicio', value, key)}
                                        />
                                        <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#fff' }}>às</span>
                                        <InputDefaultTime
                                            value={row.fim}
                                            onChangeText={(value) => onChangeDTOPausas('fim', value, key)}
                                        />
                                    </FieldGroup>
                                </Field>
                            </div>

                            <ButtonClose onClick={() => removerPausa(key)}>
                                <IconClose color="#FF868B" />
                            </ButtonClose>
                        </FieldGroup>

                        <Separator margin="15px 0" />
                    </>
                ))}

                <FieldGroup marginTop="32px">
                    <ButtonLinear color={title === 'light' ? '#8A99A8' : '#fff'} onClick={() => setModal(!modal)}>Cancelar</ButtonLinear>
                    <ButtonLinear onClick={salvarHorarios} className="success">
                        Salvar
                    </ButtonLinear>
                </FieldGroup>
            </ModalDefault>
        </Container>
    );
};

export default UsuarioVisualizar;
