import { useEffect, useState } from 'react';
import InputDefault from '../../components/form/InputDefault';
import { useRoutes } from '../../contexts/RoutesContext';
import { useMessage } from '../../contexts/MessagesContext';

import { Container, Main } from './styles';
// import apiEyou from '../../services/apiEyou';
import ContainerFilter from '../../components/form/ContainerFilter';
import Loading from '../../components/Loading/Loading';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { IBloqueio } from '../../types/IBloqueio';
import apiTropa from '../../services/apiTropa';
import BloqueioList from '../../components/BloqueioList';
import ModalDefault from '../../components/ModalDefault';
import TitleModal from '../../components/layout/containers/TitleModal';
import Field from '../../components/layout/containers/Field';
import SelectDefault from '../../components/form/SelectDefault';
import InputTextarea from '../../components/form/InputTextarea';
import ButtonLinear from '../../components/form/ButtonLinear';
import FailedRegister from '../../components/FailedRegister';
import { useTheme } from 'styled-components';

interface IDTOBloquear {
    ip?:string
    chave?:string
    valor?:string
    descricao?:string
}

export default function Bloqueios() {
    const { setPageName } = useRoutes();
    const { addDanger,addSuccess } = useMessage();
    const {title} = useTheme()
    const [bloqueios, setBloqueios] = useState<Array<IBloqueio>>([]);
    const [filter, setFilter] = useState<String>('');
    const [loading, setLoading] = useState(true);
    const [modalBloquear, setModalBloquear] = useState(false);
    const [DTOBloquear, setDTOBloquear] = useState<IDTOBloquear>({})

    useEffect(() => {
        setPageName('Bloqueios');
        getBloqueios();
    }, [setPageName]);

    function onChangeDTOBloquear(key:any, value:any){
        let copy:any = DTOBloquear
        copy[key] = value
        setDTOBloquear({...copy})
    }

    async function getBloqueios() {
        try{
            //change
            setLoading(true)
            let bloqueioResponse : any = await apiTropa.get('/bloqueio?filter='+filter);
            setBloqueios(bloqueioResponse.data.data)
            setLoading(false)
        }catch(err: any){
            setLoading(false)
            if(err.response){
                addDanger("Atenção", String(err.response.data.message))
            }else{
                addDanger("Atenção", String(err))
            }
            setTimeout((function(){
              //  history.push('/grupo-atendimento/'+props.match.params.id)
            }),2000)
        }
    }

    async function removeBloqueio(id:any){
        try{
            let bloqueioResponse : any = await apiTropa.delete('/bloqueio/delete/'+id);
            
            addSuccess("Sucesso", String(bloqueioResponse.data.message))
            getBloqueios()
        }catch(err: any){
            if(err.response){
                addDanger("Atenção", String(err.response.data.message))
            }else{
                addDanger("Atenção", String(err))
            }
            setTimeout((function(){
              //  history.push('/grupo-atendimento/'+props.match.params.id)
            }),2000)
        }
    }

    async function setBloqueio(){
        try{
            let setResponse : any = await apiTropa.post('/bloqueio/',DTOBloquear);
            
            addSuccess("Sucesso", String(setResponse.data.message))
            setModalBloquear(false)
            getBloqueios()
        }catch(err: any){

            console.log(err.response);
            if(err.response){
                addDanger("Atenção", String(err.response.data.message))
            }else{
                addDanger("Atenção", String(err))
            }
            setTimeout((function(){
              //  history.push('/grupo-atendimento/'+props.match.params.id)
            }),2000)
        }
    }

    useEffect(() => {
        setTimeout(() => setLoading(false), 600)
    }, [])
    return (
    <>
        { loading === true ? <Loading active={loading} /> : 
            <Container>
                <ContainerFilter>
                    <InputDefault
                        onChangeText={(value) => setFilter(value)}
                        value={''}
                        search={true}
                        onKeyUp={()=> getBloqueios()}
                        placeholder={'Buscar'}
                    />

                    <ButtonLinear className="info" 
                    onClick={()=>  setModalBloquear(true)}>
                        Novo Bloqueio
                    </ButtonLinear>
                </ContainerFilter>

                <Main>
                    {bloqueios.map((row, key) => (
                            <BloqueioList 
                                removeBloqueio={(id) => removeBloqueio(id)}
                                data={row} key={key}
                            />
                        ))
                    }

                    { loading === false && bloqueios.length === 0 &&
                        <FailedRegister/>
                    }

                    { loading &&
                        <Skeleton 
                            count={10} 
                            className="skeletor"
                            height="85px"                        
                            borderRadius="16px"
                            baseColor="#262655"
                            highlightColor="#3e3b77"
                        />
                    }
                </Main>
                <ModalDefault id="modalBloquear"
                onClose={() => setModalBloquear(false)}
                openModal={modalBloquear}
            >
                <TitleModal>
                    <h2>Bloquear</h2>
                    <span>Você deseja realmente bloquear esse cliente?<br/>Selecione abaixo o tipo de bloqueio e descreva o motivo:</span>
                </TitleModal>

                <Field gap='8px' marginTop={true}>
                    <SelectDefault value={DTOBloquear.chave} onChangeText={(value) => onChangeDTOBloquear("chave", value)}>
                        <option value="">Selecione o tipo de bloqueio</option>
                        <option value="IP">IP</option>
                        <option value="CPF">CPF</option>
                        <option value="E-mail">E-mail</option>
                        <option value="Telefone">Telefone</option>
                        <option value="Celular">Celular</option>
                    </SelectDefault>

                    { DTOBloquear.chave === 'CPF' &&
                        <InputDefault mask='999.999.999-99' placeholder="Valor" value={DTOBloquear.valor} onChangeText={(value) => onChangeDTOBloquear("valor", value)} />
                    }
                    { DTOBloquear.chave === 'Celular' &&
                        <InputDefault mask='(99) 99999-9999' placeholder="Valor" value={DTOBloquear.valor} onChangeText={(value) => onChangeDTOBloquear("valor", value)} />
                    }
                    { DTOBloquear.chave === 'Telefone' &&
                        <InputDefault mask='(99) 99999-9999' placeholder="Valor" value={DTOBloquear.valor} onChangeText={(value) => onChangeDTOBloquear("valor", value)} />
                    }

                    { DTOBloquear.chave !== 'Telefone' && DTOBloquear.chave !== 'Celular' && DTOBloquear.chave !== 'CPF' &&
                        <InputDefault placeholder="Valor" value={DTOBloquear.valor} onChangeText={(value) => onChangeDTOBloquear("valor", value)} />
                    }
                    
                    <InputTextarea placeholder="Digite o motivo do bloqueio" value={DTOBloquear.descricao} onChangeText={(value) => onChangeDTOBloquear("descricao", value)} />

                    <ButtonLinear className="success" onClick={() => setBloqueio()}>
                        Bloquear
                    </ButtonLinear>

                    <ButtonLinear  className="transparent" onClick={() => setModalBloquear(false)}>
                        Cancelar
                    </ButtonLinear>
                </Field>
            </ModalDefault>
            </Container>

            
        }
    </>
    );
}
