import ButtonLinear from '../../form/ButtonLinear'
import { Container, Info, ButtonConfig } from './styles'

interface PropsInfo {
    title: string;
    Icon?: any;
    onClick(): void;
}

export default function CardSatisfation({ title, Icon, onClick }: PropsInfo) {
    return (
        <Container>

            {Icon && <Icon />}

            <Info>
                <span>{title}</span>
            </Info>

            <ButtonConfig>
                <ButtonLinear
                    className="config"
                    onClick={onClick}
                >
                    Configurar
                </ButtonLinear>
            </ButtonConfig>
        </Container>
    )
}
