import styled from "styled-components"

interface Buttonprops {
    rounded: boolean
}

export const Container = styled.div<Buttonprops>`
   border-radius: ${props => props.rounded ? '100px' : '4px'} ;
   height: 40px;
   border: none;
   background: transparent;
   outline: none;
   padding: 0px 12px 0px 12px;
   display: flex;
   align-items: center;
   cursor: pointer;
   transition: all .2s;
   a {
       text-decoration: none;
   }
   .icon {
       margin-right: 4px;
       margin-left: 4px;
       display: flex;
       svg {
           width: 14px;
           height: 14px;
           path {
               fill: ${props => props.theme.colors.white};
           }
       }
   }
   .children {
       font-size: 16px;
       margin-left: 4px;
       margin-right: 4px;
        color: ${({theme}) => theme.colors.white};
   }
   &:hover {
       transform: scale(1.05);
       box-shadow:0px 10px 10px rgba(0,0,0,.3)
   }
   &.transparent {
        border: 1px solid ${props => props.theme.colors.silverSand};
        background: transparent;
        a {
            color: #FFF
        }
   }
   &.info {
        background: linear-gradient(150deg, ${props => props.theme.colors.skyblue} 0%, ${props => props.theme.colors.blueCrayola} 100%);
        a {
            color: #FFF;
        }
   }
   &.success {
        background: linear-gradient(150deg, ${props => props.theme.colors.turquoiseBlue} 0%, ${props => props.theme.colors.oceanGreen} 100%);
        a {
            color: #FFF;
        }
   }
   &.warning {
        background: linear-gradient(150deg, ${props => props.theme.colors.yellowCrayola} 0%, ${props => props.theme.colors.burntOrange} 100%);
        a {
            color: #FFF;
        }
   }
   &.danger {
        background: linear-gradient(150deg, ${props => props.theme.colors.lightCoral} 0%, ${props => props.theme.colors.redPigment} 100%);
        a {
            color: #FFF;
        }
   }
   &.delete {
        background: ${props => props.theme.colors.lightCoral};
        a {
            color: #FFF;
        }
   }
`