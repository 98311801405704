import { useEffect, useState } from 'react';
import { Container } from './styles';

interface InputProps {
    value: any[];
    options: IOptions[];
    onChange(value: any): void;
    flexDirection?: 'row' | 'column' | 'row-reverse';
    maxColumns?: any;
    className?: string;
}

interface IOptions {
    label: string;
    value: any;
    disabled?: boolean;
}

export default function InputSwitchDefault({
    value,
    options = [],
    onChange,
    flexDirection = 'row',
    maxColumns = false,
    className,
}: InputProps) {
    const [values, setValues] = useState<any[]>(value);
    useEffect(() => {
        setValues([...value]);
    }, [value]);

    function handleOnChange(valueObject: string) {
        if (values.filter((obj) => obj === valueObject).length) {
            onChange(values.filter((obj) => obj !== valueObject));
        } else {
            values.push(valueObject);
            onChange(values);
        }
    }

    return (
        <Container
            className={`form-children-default ${className}`}
            flexDirection={flexDirection}
            maxColumn={maxColumns}
        >
            {options.map((row, key) => (
                <div
                    className={`input-switch ${
                        row.disabled === true ? 'disabled' : 'enabled'
                    }`}
                    key={key}
                    onClick={() =>
                        !row.disabled ? handleOnChange(row.value) : () => {}
                    }
                >
                    <div
                        className={`switch ${
                            values.filter((obj) => obj === row.value).length > 0
                                ? 'active'
                                : 'inactive'
                        }`}
                    >
                        <div className="bullet" />
                    </div>
                    <span className="label" style={{textTransform:"capitalize"}}>{row.label}</span>
                </div>
            ))}
        </Container>
    );
}
