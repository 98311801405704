import { useState } from 'react';
import { useHistory } from 'react-router';
import { useRoutes } from '../../../contexts/RoutesContext';
import { useUIThemeContext } from '../../../contexts/UIThemeContext';
import { useUser } from '../../../contexts/UserContext';
import ButtonLinear from '../../form/ButtonLinear';
import InputSwitchDefault from '../../form/InputSwitchDefault';
import { IconArrow, IconBell, IconPower, IconUser } from '../../icons';
import { ContainerHeader } from './styles';

export default function Header() {
    const history = useHistory();
    const { theme, changeTheme } = useUIThemeContext();

    const { pageName, setLogout } = useRoutes();
    const { user, userEyou } = useUser();
    const [toggleTheme, setToggleTheme] = useState(['dark'])
    function handleOnTheme(value: any) {
        setToggleTheme(value)
        changeTheme()
    }

    return (
        <ContainerHeader>
            <h1>{pageName}</h1>

            <div className="profile">
                <InputSwitchDefault
                    options={[
                        {
                            label: theme.title,
                            value: 'dark',
                        },
                    ]}
                    value={toggleTheme}
                    onChange={(value) => handleOnTheme(value)}
                />

                <ButtonLinear
                    className="info"
                    rounded={true}
                    Icon={IconBell}
                    onClick={() => history.push('/notificacoes')}
                >
                    0
                </ButtonLinear>
                <div className="perfil">
                    {user.foto === null ? (
                        <div className="user">
                            <IconUser />
                        </div>
                    ) : (
                        <div
                            className="image"
                            style={{ backgroundImage: `url(${user.foto})` }}
                        />
                    )}
                    <span className="name">{userEyou.name}</span>
                    <IconArrow />
                    <div className="submenu">
                        <a onClick={() => setLogout()}>
                            <IconPower />
                            <span>Sair do painel</span>
                        </a>
                    </div>
                </div>
            </div>
        </ContainerHeader>
    );
}
