import styled from 'styled-components';

interface Props {
  openModal: boolean
  width?: string;
}

export const ContainerOverlay = styled.div<Props>`
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${props => props.openModal ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  z-index: 99999;
`;

export const Container = styled.div<Props>`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: auto;
    max-height: 90vh;

    background: ${props => props.theme.colors.spaceCadedLight};

    border-radius: 16px;
    padding: 24px;

    width: ${props => props.width ? props.width : '400px'};  
    /* gap: 10px; */

    /* overflow-y: scroll;
    ::-webkit-scrollbar {
        width: 6px;
        background-color: #59588D;
        border-radius: 4px;

    }
    ::-webkit-scrollbar-thumb {
        background-color: #8383B2;
        border-radius: 2px;
    }
    scrollbar-color: #8383B2 transparent;
    scrollbar-width: thin; */
`;

export const TitleModal = styled.h3`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  color: ${({theme}) => theme.colors.white};
`

export const DescriptionWarning = styled.div`

`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 24px;
    width: 100%;
    
`;