import styled, { keyframes } from "styled-components";

interface IProps {
    submited?: boolean
}

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    p.subtitle {
        color: ${({theme}) => theme.colors.white};
        margin-bottom: 20px;
        font-size: 13px;
    }
    .savecontent {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        margin-bottom: -40px;
    }
    .filters {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        .groupInputs {
            display: flex;
            gap: 10px;

            .date {
                border: 1px solid #C5CED7;
                padding: 5px;
                border-radius: 4px;
                position: relative;
                 
                p {
                    position: absolute;
                    top: 0;
                    color: #34D5FA;
                    font-size: 14px;
                    margin-top: -20px;
                }

                .react-date-picker__wrapper {
                    border: none;
                }
                
                .react-date-picker__calendar {
                    z-index: 12;
                }
                
                .react-date-picker__inputGroup__input,
                .react-date-picker__inputGroup__divider,
                .react-date-picker__inputGroup__leadingZero {
                    color: #FFF;
                }            
                .react-date-picker__inputGroup__leadingZero {
                    display: inline-block;
                }
                    
                .react-date-picker__clear-button {
                    svg {
                    stroke: #FFF;          
                    }
                    &:hover {
                        svg {
                            stroke: #E81E26 !important;
                        }
                    }
                }
    
                .react-date-picker__calendar-button {
                    svg {
                        stroke: #FFF;            
                    }
                    &:hover {
                        svg {
                            stroke: #4A7DFF !important;
                        }
                    }
                }
            }

        }
        .groupBy {
            border: 1px solid ${(props) => props.theme.colors.skyblue};
            border-radius: 5px;
            padding: 4px 14px;
            background: rgba(0,0,0,.1);
            animation: fadeIn .2s linear;
            margin-top: -7px;
        }
    }
    @media (max-width: 900px) {
        .filters {
            flex-direction: column;
        }  
        .groupInputs {
            flex-wrap: wrap;            
        }
        .card-table {
            div {
                padding-right: 0px;
                button {
                    margin-right: 0;
                    margin-top: 0;
                }            
            }  
        } 
        #tableManager > div {
            flex-direction: column;
            align-items: center;
            gap: 10px;
            #tableManagerColumns {
                margin-right: 0;
                width: 100%;
            }
        }     
    }
    @media (max-width: 425px) {
        .groupInputs {
            input {
                width: 115px;
            }
        }  
        .groupBy {
            .input-radio {
                &:first-child {
                    .label {
                        width: 70px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: block;
                    }
                }
                
            }
        }
        .savecontent {
            justify-content: center;
            margin-bottom: 10px;
        }

        #tableManager > div {
            flex-direction: column;
            align-items: center;
            gap: 10px;
            #tableManagerColumns {
                margin-right: 0;
                width: 100%;
            }
        }
    }
`

export const HeaderFiltered = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px dashed rgba(255,255,255,0.5);


    .sectionFilters {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 8px;

        @media (max-width: 1160px) {
            flex-direction: column;
        }

        .filterLeft {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 100%;            
        }

        .filterRight {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            .groupSearchButtons {
                display: flex;
                align-items: center;
                gap: 10px;
                width: 100%;
                justify-content: flex-end;
            }
            
        }

        .subFilters {
            display: flex;
            min-width: 200px;
            width: 100%;
            padding: 10px;
            border: 1px solid ${(props) => props.theme.colors.skyblue};
            border-radius: 5px;
            background: rgba(0,0,0,.1);
            animation: fadeIn .2s linear;
            height: 40px;

            label {
                display: flex;
                gap: 4px;
                width: 100%;
            }

            p {
                font-size: 14px;
                color: #fff;
            }

            .inputAreaFilter {
                border: none;
                background-color: transparent;
                color: #FFF;
                font-size: 14px;
                width: 100%;

                &::placeholder {
                    font-size: 16px;
                    opacity: 0.8;
                }
            }
        }

        .groupSerachButtons {
            display: flex;
            align-items: center;
            /* justify-content: flex-end; */
            width: 100%;
            gap: 10px;

            .buttonsSerach {
                display: flex;
                gap: 10px;
            }

            @media (max-width: 1160px) {
                flex-direction: column;
                align-items: flex-start;

                .buttonsSerach {
                    width: 100%;

                    >label {
                        width: 100%;
                    }

                    >button {
                        width: 100%;
                    }

                }
            }
        }
    }


`
export const ContentFiltered = styled.div<IProps>`
    display: ${(props) => props.submited === true ? 'block' : 'none'};
    margin-top: 20px;

    @media (max-width: 425px) {
        .groupInputs {
            /* flex-wrap: wrap; */
            flex-direction: column;
        }
        .two {
            flex-direction: row;            
        }
    }
`

export const SectionFiltersSelected = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
    margin-top: 20px;

    h3 {
        font-size: 1rem;
        color: ${({theme}) => theme.colors.white};
        font-weight: 600;
        margin-right: 12px;
    }
`;

const pull = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.1);
  }
  80% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const ButtonFilterSelected = styled.button`
    display: flex;
    align-items: center;
    gap: 10px;

    padding: 8px 12px;

    border: none;
    border-radius: 50px;
    background: linear-gradient(150deg, ${props => props.theme.colors.skyblue} 0%, ${props => props.theme.colors.blueCrayola} 100%);
    animation: ${pull} 0.4s;

    font-size: 0.875rem;
    color: #fff;
`;

export const ContainerModalAnalitic = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;

    .iconMessageAnalitic {
        display: grid;
        place-items: center;

        width: 30%;
        .contentImageAlert {
            padding: 1.5rem;
            img {
                width: 100%;
            }
        }
    }

    .messageModalAnalitic {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        h1 {
            font-size: 2rem;
            color: ${({theme}) => theme.colors.white};
            margin-bottom: 12px;
        }

        p {
            font-size: 18px;
            color: #C5CED7;
            margin-bottom: 14px;
        }
    }
`