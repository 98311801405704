import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    padding: 20px;
    background-image: linear-gradient(to right, rgba(255,255,255,0.1), rgba(0,0,0,0));
    width: 100%;
    ul {
        align-items: center;
        display: flex;
        list-style: none;
        padding:0;
        margin: 0;
        gap: 10px;
        li {
            p {
                color: ${({theme}) => theme.colors.white};
            }
        }
    }
`