import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

export const Main = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    margin-top: 24px;

    h1 {
        color: ${props => props.theme.colors.white};
    }

    .skeletor {
        margin-bottom: 8px;
    }

    .card-table{
        div {
            div {
                div {
                    div {
                        
                    }
                }
            }
        }
    }
`

export const MainEdit = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
    gap: 16px;
`

export const CardOperator = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 415px;
    background: ${props => props.theme.colors.spaceCadedDark};
    border-radius: 16px;
    padding: 32px;
    .card-table{
        padding: 0;
    }
`

export const HourAttendence = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: ${props => props.theme.colors.spaceCadedDark};
    border-radius: 16px;
    padding: 32px;
`;

export const Title = styled.div`
    line-height: 40px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: bold;
    color: ${props => props.theme.colors.white};
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 150px;
    margin-top: 40px;
`;

export const ButtonAdd = styled.div`
    margin-top: 40px;
`;


export const Group = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 33px;
`;

export const NameGroup = styled.div`
    display: flex;
    flex-direction: column;
    h2 {
        font-size: 12px;
        font-weight: 500;
        color: ${props => props.theme.colors.lavanderGray};
    }
    span {
        font-size: 14px;
        font-weight: bold;
        color: ${props => props.theme.colors.white};
        margin-top: 2px;
    }
`;

export const ButtonClose = styled.div`
    cursor: pointer;
`;