import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 40px;
    gap: 16px;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 24px;

    h1 {
        font-size: 1.5rem;
        font-weight: bold;
        color: ${props => props.theme.colors.white};
    }
`;

export const SectionHours = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    @media (max-width: 900px) {
        overflow-x: auto;
    }
`;

export const SectionSatisfation = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(160px, 1fr));
    gap: 16px;
    width: 100%;
`

export const SectionOperators = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    max-height: 248px;

    padding: 24px;
    border-radius: 16px;

    background: ${props => props.theme.colors.spaceCadedDark};
`

export const InfoOperators = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    h2 {
       font-size: 1rem;
       font-weight: bold;
       color: ${props => props.theme.colors.white};
    }
`

export const ListOperators = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(252px, 1fr));
    gap: 22px;
    width: 100%;

    margin-top: 32px;

    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`

export const CardEditHours = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    background: ${(props) => props.theme.colors.spaceCadedDark};
    border-radius: 16px;
    padding: 20px 24px;

    width: 100%;
    height: 80px;

    /* span {
        color: #fff;
    } */
`;

export const InfoHours = styled.div`
    display: flex;
    flex-direction: column;

    h3 {
        font-size: 12px;
        color: ${(props) => props.theme.colors.lavanderGray};
    }

    span {
        font-size: 14px;
        font-weight: bold;
        color: ${(props) => props.theme.colors.white};
        margin-top: 2px;
    }
`;
