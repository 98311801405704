import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .buttons {
        display: flex;
        gap: 15px;
        .success {
            width: 100px;
        }
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;

    margin: 24px 0;
`;

export const CardTitle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    width: 100%;
    padding: 40px 32px;

    background: ${props => props.theme.colors.spaceCadedDark};
    border-radius: 16px;

    h2 {
        font-size: 24px;
        font-weight: bold;
        color: ${props => props.theme.colors.white};
    }

    p {
        font-size: 16px;
        color: ${props => props.theme.colors.white};
    }

`;

export const CardEntryForm = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    padding: 40px 32px;

    background: ${props => props.theme.colors.spaceCadedDark};
    border-radius: 16px;

    @media (max-width: 900px) {
        input {
            width: 100%;
        }
    }
    @media (max-width: 425px) {
        display: flex;
        flex-direction: column;
    }
`

export const CardForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    width: 100%;
    padding: 40px 32px;

    background: ${props => props.theme.colors.spaceCadedDark};
    border-radius: 16px;
    h2 {
        font-size: 16px;
        font-weight: bold;
        color: ${props => props.theme.colors.white};
        margin-bottom: 32px;
    }
`;

export const SectionInputs = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(140px, 1fr));
    gap: 16px;
`

export const SectionInputsSelected = styled.div`
    display: flex;
    flex-direction: column;
    .item {
        /* background: rgba(255,255,255,0.3); */
        background: ${({theme}) => theme.colors.scrolltrack};
        border-radius: 5px;
        padding: 10px 15px;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        > span {
            color: ${({theme}) => theme.colors.white};
            font-size: 12px;
            margin-bottom: 5px;
            padding: 5px 0px;
        }
        > div {
            display: flex;
            button {
                margin-right: 10px;
            }
        }
    }
`

export const SectionEditForm = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 16px;
    
    width: 100%;

    @media (max-width: 425px) {
        flex-direction: column;
    }

`

export const FieldSelected = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 16px;
    width: 100%;
    button {
        padding: 0 12px;
        margin-left: 16px;
        width: 100%;
        max-width: 215px;
    }
    button:nth-child(3n-2) {
        margin-left: 0;
    }
    @media (max-width: 1400px) {
        button {
            width: 27%;
            padding: 5px 15px;
        }
    }
`;