/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { useMessage } from '../../contexts/MessagesContext'
import { useRoutes } from '../../contexts/RoutesContext'
import apiTropa from '../../services/apiTropa'
import moment from 'moment'

import InputDefault from '../../components/form/InputDefault'
import ButtonLinear from '../../components/form/ButtonLinear'
import ButtonDownload from '../../components/form/ButtonDownload/ButtonDownload'
import InputTextarea from '../../components/form/InputTextarea'
import InputRating from '../../components/form/InputRating'
import InputSwitchDefault from '../../components/form/InputSwitchDefault'
import InputRadioDefault from '../../components/form/InputRadioDefault'
import SelectDefaultSearch from '../../components/form/SelectDefaultSearch'

import Loading from '../../components/Loading/Loading'
import TableManager from '../../components/TableManager'
import ChartEstatistico from '../../components/ChartEstatistico'
import ModalDefault from '../../components/ModalDefault'
import Field from '../../components/layout/containers/Field'
import FieldGroup from '../../components/layout/containers/FieldGroup'

import CardMessageHistory from '../../components/Ui/CardMessageHistory'

import AlertImg from '../../assets/images/alerta.png'

//ICONS
import {  IconCheck, IconClose, IconCopy, IconDanger, IconDownload, IconFile, IconPrint, IconSearch, IconTrash } from '../../components/icons'

//STYLES
import { Container, ContentFiltered, HeaderFiltered, SectionFiltersSelected, ButtonFilterSelected, ContainerModalAnalitic } from './styles'
import { ContainerQuestionItem } from '../GruposAtendimento/pesquisaSatisfacao/styles'
import { ContainerQuestion, ContainerQuestionOne, TitleQuestion } from '../PageChat/styles'
import { useTheme } from 'styled-components'

export default function Relatorios(props:any) {

    //init config
    const { setPageName } = useRoutes()
    const { addSuccess, addDanger } = useMessage()
    const {title} = useTheme()
    //Token
    const tokenInicial: any = props.tokenInicial ? props.tokenInicial : window.sessionStorage.getItem('tokenInicial');

    //funcoes globais da pagina
    const [loading, setLoading] = useState<boolean>(false)
    const [submited, setSubmited] = useState<boolean>(false)
    const [page, setPage] = useState<number>(1)
    const [pagination, setPagination] = useState<any>({
        totalDocs: 0,
        totalPage: 0,
        page: page
    })

    //filter head
    const [type, setType] = useState<any>('sintetico')
    const [groupsByType, setGroupByTypes] = useState<any>([])
    const [groupType, setGroupType] = useState<string>('grupo')
    const groupTypePTBR:any = {
        grupo: 'Grupo de atendimento',
        operador: 'Operador',
        canal: 'Canal',
        dia: 'Dia',
        sintetico: 'sintetico'
    }

    const [dataInicio, setDataInicio] = useState<any>(moment.utc().format('YYYY-MM-DD'))
    const [dataFinal, setDataFinal] = useState<any>(moment.utc().format('YYYY-MM-DD'))
    //filters content
    const [idGrupoAtendimento, setIdGrupoAtendimento] = useState<any>([])
    const [idOperador, setIdOperador] = useState<any>([])
    const [canal, setCanal] = useState<any>([])

    //results for api
    const [headers, setHeaders] = useState<any>([])
    const [data, setData] = useState<any>([])

    //filters content load via api
    const [textFilter, setTextFilter] = useState<any>('');
    const [filtroGrupos, setFiltroGrupos] = useState<any[]>([])
    const [filtroOperadores, setFiltroOperadores] = useState<any[]>([])
    const [filtroCanais, setFiltroCanais] = useState<any[]>([
        {
            label: 'Web',
            value: 'chatweb'
        },
        {
            label: 'Whatsapp',
            value: 'whatsapp'
        },
    ])

    //Modal Analitico
    const [modalAnalitic, setModalAnalitic] = useState(false)

    //save realatorio
    const filtersParams = props.history?.location?.filters
    const [relatorioName, setRelatorioName] = useState<string>('')
    const [relatorioSave, setRelatorioSave] = useState<boolean>(false)
    const [loadDataSave, setLoadDataSave] = useState<boolean>(false)
    const tableRef = useRef<any>(null);

    //Historico de mensagens
    const [modalHistory, setModalHistory] = useState(false);
    const [historyMessage, setHistoryMessage] = useState<any>([]);

    //Pesquisa 
    const [questions, setquestions] = useState<Array<any>>([]);
    const [modalQuestion, setModalQuestion] = useState(false)

    //VisibleHeaders
    const [headersVisible, setHeadersVisible] = useState<any[]>([]);

    useEffect(() => {
        const newHeadersVisibile:any = []
        headers.map((row: any, key: any) => {newHeadersVisibile.push(row.value)});
        setHeadersVisible(newHeadersVisibile);
    }, [headers]);

    useEffect(() => {
        if ( filtersParams ){

            setType(filtersParams.tipo)
            setDataInicio(filtersParams.dataInicio)
            setDataFinal(filtersParams.dataFim)
            setHeadersVisible(filtersParams.headers);
            setLoading(true)
            setTimeout(() => {
                setGroupType(filtersParams.agrupamentoPor)
                setIdOperador([...filtersParams.filtros?.id_operador])
                setCanal([...filtersParams.filtros?.canal])
                setIdGrupoAtendimento([...filtersParams.filtros?.id_grupo_atendimento])
                setHeadersVisible(filtersParams.headers);

                setLoadDataSave(true)
            }, 1000)
        }

    }, [filtersParams])

    useEffect(() => {
        if ( loadDataSave === true ) handleSubmit()
    }, [loadDataSave])

    useEffect(() => {
        setPageName('Relatórios')
        apiTropa.get('grupo-atendimento').then((response:any) => {
            const newGrupos:any = []
            response.data.data.map((row:any) => {
                newGrupos.push({
                    label: row.grupo_atendimento,
                    value: row.id_grupo_atendimento
                })
            })
            setFiltroGrupos([...newGrupos])
        })
        apiTropa.post('operador/cliente').then((response:any) => {
            const newOperadores:any = []
            response.data.data.map((row:any) => {
                newOperadores.push({
                    label: row.nome,
                    value: row.id_login
                })
            })
            setFiltroOperadores([...newOperadores])
        })
    }, [])

    useEffect(() => {
        if (submited === true) handleSubmit()
    }, [page])

    useEffect(() => {
        if ( type === 'sintetico' ) {
            setGroupByTypes([
                {
                    label: 'Grupo de atendimento',
                    value: 'grupo'
                },
                {
                    label: 'Operador',
                    value: 'operador'
                },
                {
                    label: 'Canal',
                    value: 'canal'
                },{
                    label: 'Dia',
                    value: 'dia'
                }
            ])
            setGroupType('grupo')
        } else if (type === 'sinteticoGeral') {
            setGroupByTypes([
                {
                    label: 'Canal',
                    value: 'canal'
                },
                {
                    label: 'Grupo',
                    value: 'grupo'
                },
                {
                    label: 'Operador',
                    value: 'operador'
                },
                {
                    label: 'Dia',
                    value: 'dia'
                }
            ])
            setGroupType('canal')
        } else if (type === 'pesquisa') {
            setGroupByTypes([
                {
                    label: 'Nota Pesquisa de Satisfação',
                    value: 'pesquisa-nota'
                },
                {
                    label: 'Pesquisas Respondidas',
                    value: 'resposta-pesquisas'
                },
            ])
            setGroupType('pesquisa-nota')
        } else if (type === 'estatistico') {
            setGroupByTypes([
                {
                    label: 'Hora',
                    value: 'hora'
                },
                {
                    label: 'Dia',
                    value: 'dia'
                },
                {
                    label: 'Semana',
                    value: 'semana'
                },
                {
                    label: 'Mês',
                    value: 'mes'
                }
            ])
            setGroupType('hora')
        } else if (type === 'operador') {
            setGroupByTypes([
                {
                    label: 'Relatorio Pausa/Horário',
                    value: 'pausa'
                },
                {
                    label: 'Relatorio Atendimento',
                    value: 'atendimento'
                },
            ])
            setGroupType('pausa')
        } else if (type === 'buscaAtendimento') {
            setHeaders([
                {
                    label:'Canal',
                    value:'canal',
                }, 
                {
                    label:'Grupo',
                    value:'grupo',
                }, 
                {
                    label:'Operador',
                    value:'operador',
                }, 
                {
                    label:'Funil',
                    value:'funil',
                }, 
                {
                    label:'Tag',
                    value:'tags',
                }, 
                {
                    label:'Data e Hora',
                    value:'dataHora',
                }, 
                {
                    label:'Total De HSM',
                    value:'totalDeHSM',
                }, 
                {
                    label:'Total Saida',
                    value:'totalSaida',
                }, 
                {
                    label:'Total Entrada',
                    value:'totalEntrada',
                }, 
                {
                    label:'Protocolo',
                    value:'protocolo',
                }, 
                {
                    label:'Operador/Bot',
                    value:'operadorBot',
                }, 
                {
                    label:'Etapa do bot',
                    value:'chatbot_etapa',
                }, 
                {
                    label:'Resposta Bot',
                    value:'chatbot_mensagem',
                }, 
                {
                    label:'TA',
                    value:'TA',
                }, 
                {
                    label:'TE',
                    value:'TE',
                }, 
                {
                    label:'Status Atendimento',
                    value:'statusAtendimento',
                }, 
                {
                    label:'Observações',
                    value:'observacoes',
                }, 
                {
                    label:'Nome Cliente',
                    value:'nomeCliente',
                }, 
                {
                    label:'Email',
                    value:'email',
                }, 
                {
                    label:'cpf/doc',
                    value:'cpf',
                }, 
                {
                    label:'Telefone',
                    value:'telefone',
                }, 
                {
                    label:'Celular',
                    value:'celular',
                }, 
                {
                    label:'Sexo',
                    value:'sexo',
                }, 
                {
                    label:'Profissão',
                    value:'profissao',
                }, 
                {
                    label:'Endereço',
                    value:'endereco',
                }, 
                {
                    label:'Numero',
                    value:'numero',
                }, 
                {
                    label:'Complemento',
                    value:'complemento',
                }, 
                {
                    label:'CEP',
                    value:'cep',
                }, 
                {
                    label:'Bairro',
                    value:'bairro',
                }, 
                {
                    label:'Cidade',
                    value:'cidade',
                }, 
                {
                    label:'Estado',
                    value:'estado',
                }, 
                {
                    label:'Visualizar Conversa',
                    value:'visualizarConversa',
                    button: true,
                    function: handleComment,
                }, 
            ])
            setGroupByTypes([])
        } else if ( type === 'analitico' ) {
            setHeaders([
                {
                    label:'Canal',
                    value:'canal',
                }, 
                {
                    label:'Grupo',
                    value:'grupo',
                }, 
                {
                    label:'Operador',
                    value:'operador',
                }, 
                {
                    label:'Funil',
                    value:'funil',
                }, 
                {
                    label:'Tag',
                    value:'tags',
                }, 
                {
                    label:'Data e Hora',
                    value:'dataHora',
                }, 
                {
                    label:'Total De HSM',
                    value:'totalDeHSM',
                }, 
                {
                    label:'Total Saida',
                    value:'totalSaida',
                }, 
                {
                    label:'Total Entrada',
                    value:'totalEntrada',
                }, 
                {
                    label:'Protocolo',
                    value:'protocolo',
                }, 
                {
                    label:'Operador/Bot',
                    value:'operadorBot',
                }, 
                {
                    label:'Etapa do bot',
                    value:'chatbot_etapa',
                }, 
                {
                    label:'Resposta Bot',
                    value:'chatbot_mensagem',
                }, 
                {
                    label:'TA',
                    value:'TA',
                }, 
                {
                    label:'TE',
                    value:'TE',
                }, 
                {
                    label:'Status Atendimento',
                    value:'statusAtendimento',
                }, 
                {
                    label:'Observações',
                    value:'observacoes',
                }, 
                {
                    label:'Nome Cliente',
                    value:'nomeCliente',
                }, 
                {
                    label:'Email',
                    value:'email',
                }, 
                {
                    label:'cpf/doc',
                    value:'cpf',
                }, 
                {
                    label:'Telefone',
                    value:'telefone',
                }, 
                {
                    label:'Celular',
                    value:'celular',
                }, 
                {
                    label:'Sexo',
                    value:'sexo',
                }, 
                {
                    label:'Profissão',
                    value:'profissao',
                }, 
                {
                    label:'Endereço',
                    value:'endereco',
                }, 
                {
                    label:'Numero',
                    value:'numero',
                }, 
                {
                    label:'Complemento',
                    value:'complemento',
                }, 
                {
                    label:'CEP',
                    value:'cep',
                }, 
                {
                    label:'Bairro',
                    value:'bairro',
                }, 
                {
                    label:'Cidade',
                    value:'cidade',
                }, 
                {
                    label:'Estado',
                    value:'estado',
                }, 
                {
                    label:'Visualizar Conversa',
                    value:'visualizarConversa',
                    button: true,
                    function: handleComment,
                }, 
            ])
            setGroupByTypes([])
        } 
    }, [type])

    useEffect(() => {
        if ( type === 'sinteticoGeral' ){
            if ( groupType === 'canal' ) {
                setHeaders([
                    {
                        label: groupTypePTBR[groupType],
                        value: 'agrupamento',
                    },
                    {
                        label: 'Total de Msgs',
                        value: 'totalMsgs',
                    },
                    {
                        label: 'Total De HSM',
                        value: 'totalHSM',
                    },
                    {
                        label: 'Total Saida',
                        value: 'totalSaida',
                    },
                    {
                        label: 'Total Entrada',
                        value: 'totalEntrada',
                    }
                ])
            } else if ( groupType === 'grupo' ) {
                setHeaders([
                    {
                        label: groupTypePTBR[groupType],
                        value: 'agrupamento',
                    },
                    {
                        label: 'Canal',
                        value: 'canal',
                    },
                    {
                        label: 'Total de Msgs',
                        value: 'totalMsgs',
                    },
                    {
                        label: 'Total De HSM',
                        value: 'totalHSM',
                    },
                    {
                        label: 'Total Saida',
                        value: 'totalSaida',
                    },
                    {
                        label: 'Total Entrada',
                        value: 'totalEntrada',
                    },
                ])
            } else if ( groupType === 'operador' ) {
                setHeaders([
                    {
                        label: groupTypePTBR[groupType],
                        value: 'agrupamento',
                    },
                    {
                        label: 'Canal',
                        value: 'canal',
                    },
                    {
                        label: 'Total de Msgs',
                        value: 'totalMsgs',
                    },
                    {
                        label: 'Total De HSM',
                        value: 'totalHSM',
                    },
                    {
                        label: 'Total Saida',
                        value: 'totalSaida',
                    },
                    {
                        label: 'Total Entrada',
                        value: 'totalEntrada',
                    },
                ])
            } else if ( groupType === 'dia' ) {
                setHeaders([
                    {
                        label: groupTypePTBR[groupType],
                        value: 'agrupamento',
                    },
                    {
                        label: 'Canal',
                        value: 'canal',
                    },
                    {
                        label: 'Total de Msgs',
                        value: 'totalMsgs',
                    },
                    {
                        label: 'Total De HSM',
                        value: 'totalHSM',
                    },
                    {
                        label: 'Total Saida',
                        value: 'totalSaida',
                    },
                    {
                        label: 'Total Entrada',
                        value: 'totalEntrada',
                    },
                ])
            } else {
                // setHeaders([])
            }
        }

        if ( type === 'sintetico' ) {

            if (groupType === 'canal') {
                setHeaders([
                    {
                        label: groupTypePTBR[groupType],
                        value: 'agrupamento',
                    },
                    {
                        label:'Total de Atendimentos',
                        value:'atendimentoTotal',
                    }, 
                    {
                        label:'Em fila',
                        value:'emFila',
                    }, 
                    {
                        label:'Em Atendimento',
                        value:'emAtendimento',
                    },
                    {
                        label:'Finalizado Operador',
                        value:'finalizadoOperador',
                    }, 
                    {
                        label:'Finalizado Admin',
                        value:'finalizadoAdmin',
                    }, 
                    {
                        label: 'Finalizado Exceção',
                        value: 'finalizadoExcecao'
                    },
                    {
                        label:'Finalizado Bot',
                        value:'finalizadoBot',
                    },
                    {
                        label:'Finalizado Abandono',
                        value:'finalizadoAbandono',
                    }, 
                    {
                        label:'TMA em Segundos',
                        value:'TMAEmSegundos',
                    }, 
                    {
                        label:'TME em Segundos',
                        value:'TMEEmSegundos',
                    }, 
                ])
            } else if (groupType === 'grupo') {
                setHeaders([
                    {
                        label: groupTypePTBR[groupType],
                        value: 'agrupamento',
                    },
                    {
                        label:'Canal',
                        value:'canal',
                    }, 
                    {
                        label:'Total de Atendimentos',
                        value:'atendimentoTotal',
                    }, 
                    {
                        label:'Em fila',
                        value:'emFila',
                    }, 
                    {
                        label:'Em Atendimento',
                        value:'emAtendimento',
                    },
                    {
                        label:'Finalizado Operador',
                        value:'finalizadoOperador',
                    }, 
                    {
                        label:'Finalizado Admin',
                        value:'finalizadoAdmin',
                    }, 
                    {
                        label: 'Finalizado Exceção',
                        value: 'finalizadoExcecao'
                    },
                    {
                        label:'Finalizado Bot',
                        value:'finalizadoBot',
                    }, 
                    {
                        label:'Finalizado Abandono',
                        value:'finalizadoAbandono',
                    }, 
                    {
                        label:'TMA em Segundos',
                        value:'TMAEmSegundos',
                    }, 
                    {
                        label:'TME em Segundos',
                        value:'TMEEmSegundos',
                    },
                ])
            } else if (groupType === 'operador') {
                setHeaders([
                    {
                        label: groupTypePTBR[groupType],
                        value: 'agrupamento',
                    },
                    {
                        label:'Canal',
                        value:'canal',
                    }, 
                    {
                        label:'Em fila',
                        value:'emFila',
                    }, 
                    {
                        label:'Em Atendimento',
                        value:'emAtendimento',
                    },
                    {
                        label:'Finalizado Operador',
                        value:'finalizadoOperador',
                    }, 
                    {
                        label:'Finalizado Admin',
                        value:'finalizadoAdmin',
                    }, 
                    {
                        label: 'Finalizado Exceção',
                        value: 'finalizadoExcecao'
                    },
                    {
                        label:'Finalizado Bot',
                        value:'finalizadoBot',
                    }, 
                    {
                        label:'Finalizado Abandono',
                        value:'finalizadoAbandono',
                    }, 
                    {
                        label:'TMA em Segundos',
                        value:'TMAEmSegundos',
                    }, 
                    {
                        label:'TME em Segundos',
                        value:'TMEEmSegundos',
                    },
                ])
            } else if (groupType === 'dia') {
                setHeaders([
                    {
                        label: groupTypePTBR[groupType],
                        value: 'agrupamento',
                    },
                    {
                        label:'Canal',
                        value:'canal',
                    }, 
                    {
                        label:'Em fila',
                        value:'emFila',
                    }, 
                    {
                        label:'Em Atendimento',
                        value:'emAtendimento',
                    },
                    {
                        label:'Finalizado Operador',
                        value:'finalizadoOperador',
                    }, 
                    {
                        label:'Finalizado Admin',
                        value:'finalizadoAdmin',
                    }, 
                    {
                        label: 'Finalizado Exceção',
                        value: 'finalizadoExcecao'
                    },
                    {
                        label:'Finalizado Bot',
                        value:'finalizadoBot',
                    }, 
                    {
                        label:'Finalizado Abandono',
                        value:'finalizadoAbandono',
                    }, 
                    {
                        label:'TMA em Segundos',
                        value:'TMAEmSegundos',
                    }, 
                    {
                        label:'TME em Segundos',
                        value:'TMEEmSegundos',
                    },
                ])
            } else {
                // setHeaders([])
            }
        }

        if( type === 'pesquisa') {
            if(groupType === 'pesquisa-nota') {
                setHeaders([
                    {
                        label:'Media da Nota',
                        value:'mediaDaNota',
                    }, 
                    {
                        label:'Canal',
                        value:'canal',
                    }, 
                    {
                        label:'Total de Pesquisas Respondidas',
                        value:'totalPesquisasRespondidas',
                    }, 
                    {
                        label:'Total N1',
                        value:'totalN1',
                    }, 
                    {
                        label:'Total N2',
                        value:'totalN2',
                    }, 
                    {
                        label:'Total N3',
                        value:'totalN3',
                    }, 
                    {
                        label:'Total N4',
                        value:'totalN4',
                    }, 
                    {
                        label:'Total N5',
                        value:'totalN5',
                    }, 
                ])
            } 
            else if (groupType === 'resposta-pesquisas') {
                setHeaders([
                    {
                        label:'Operador',
                        value:'operador',
                    }, 
                    {
                        label:'Canal',
                        value:'canal',
                    }, 
                    {
                        label:'Chatbot',
                        value:'chatbot',
                    }, 
                    {
                        label:'Protocolo',
                        value:'protocolo',
                    }, 
                    {
                        label:'Grupo Atendimento',
                        value:'grupo_atendimento',
                    }, 
                    {
                        label:'Inicio',
                        value:'data_entrada',
                    }, 
                    {
                        label:'Respostas',
                        value:'respostas',
                        button: true,
                        function: handleOnModalSeacrh,
                        type: 'pesquisa'
                    }, 
                ])
            } else {
                // setHeaders([])
            }
        }

        if(type === 'operador') {
            if(groupType === 'pausa') {
                setHeaders([
                    {
                        label:'Nome',
                        value:'nome',
                    }, 
                    {
                        label:'Status',
                        value:'status',
                    }, 
                    {
                        label:'Login',
                        value:'id_login',
                    }, 
                    {
                        label: 'Apelido',
                        value: 'apelido'
                    },
                    {
                        label:'Limite Atendimentos',
                        value:'limiteAtendimentos',
                    }, 
                    {
                        label:'Total Pausa Produtiva',
                        value:'totalPausaProdutiva',
                    }, 
                    {
                        label:'Total Pausa Improdutiva',
                        value:'totalPausaImprodutiva',
                    }, 
                    {
                        label:'Total Atraso',
                        value:'totalAtraso',
                    }, 
                    {
                        label:'Total Extra',
                        value:'totalExtra',
                    }, 
                ])
            } else if (groupType === 'atendimento') {
                setHeaders([
                    {
                        label:'Canal',
                        value:'canal',
                    }, 
                    {
                        label:'Grupo',
                        value:'grupo',
                    }, 
                    {
                        label:'Total de Atendimentos ',
                        value:'totalAtendimentos',
                    }, 
                    {
                        label:'Atendimentos finalizados',
                        value:'atendimentosFinalizados',
                    }, 
                    {
                        label:'Atendimentos Abandono',
                        value:'atendimentosAbandonos',
                    }, 
                    {
                        label:'TMA Segundos',
                        value:'TMAEmSegundos',
                    }, 
                    {
                        label:'TME Segundos',
                        value:'TMEEmSegundos',
                    }, 
                    {
                        label:'Nota Pesquisa',
                        value:'notaPesquisa',
                    }, 
                    {
                        label:'Nivel de Seviço',
                        value:'nivelDeServico',
                    }, 
                ])
            } else {
                // setHeaders([])
            }
        }

    }, [groupType, type])

    function handleComment(id: any) {
        setModalHistory(!modalHistory)
        apiTropa.get(`mensagem/historico/${id}?isOperador=true`).then((resp: any) => {
            setHistoryMessage(resp.data.data);
        });
    }

    useEffect(() => {
        setIdGrupoAtendimento([])
        setIdOperador([])
        setCanal([])
        setSubmited(false)
        setPage(1)
        setPagination({
            totalDocs: 0,
            totalPage: 0,
            page: page
        })
    }, [type, groupType])

    function handleRemoveFilter(type: string, id: any) {
        if(type === 'canal') {
            const update = canal.filter((obj: any) => obj !== id);
            setCanal(update);
        } else if (type === 'operador') {
            const update = idOperador.filter((obj: any) => obj !== id);
            setIdOperador(update);
        } else if (type === 'grupo') {
            const update = idGrupoAtendimento.filter((obj: any) => obj !== id);
            setIdGrupoAtendimento(update);
        } 
        return {}
    }

    function handleClear()
    {
        setType('sintetico')
        setGroupType('grupo')
        setIdGrupoAtendimento([])
        setIdOperador([])
        setCanal([])
        setSubmited(false)
    }

    async function handleOnModalSeacrh(questions: any, respostas: any) {
        setModalQuestion(true);

        for await (let question of questions) {
            if (question.type === 'radio') {
                question.resposta = respostas[question.id][0];
            } else {
                question.resposta = respostas[question.id];
            }
        }
        setquestions(questions);
    }

    async function handleSubmit()
    {

        try {
            setLoading(true)

            if(type === 'buscaAtendimento' && textFilter.length <= 0) {
                throw 'Favor preencha o filtro!'
            }

            let urlBackend = ''

            if ( type === 'sintetico' ) {
                urlBackend = 'relatorio/sintetico/atendimento'
            } 
            else if ( type === 'sinteticoGeral' ) {
                urlBackend = '/relatorio/sintetico/geral'
            } 
            else if (type === 'operador') {
                urlBackend = `relatorio/operador/${groupType}`
            } 
            else if ( type === 'estatistico' ) {
                urlBackend = 'relatorio/estatistico'
            } 
            else if ( type === 'analitico' ) {
                urlBackend = 'relatorio/analitico?page='+page
            }
            else if (type === 'buscaAtendimento') {
                urlBackend = 'relatorio/analitico?page='+page
            } 
            else if (type === 'pesquisa') {
                urlBackend = `/dashboard/${groupType}`
            } 

           if(type === 'analitico' && dataFinal !== dataInicio){

            // setLoading(false)
            setModalAnalitic(!modalAnalitic)

                // setLoading(true)
                // const response:any = await apiTropa.post('relatorio/analitico-download', {
                //     agrupamentoPor: groupType,
                //     dataInicio: dataInicio,
                //     dataFim: dataFinal,
                //     tokenInicial: tokenInicial,
                //     filtros: {
                //         id_operador: idOperador,
                //         id_grupo_atendimento: idGrupoAtendimento,
                //         canal: canal,
                //         campos: headersVisible
                //     }
                // })

                // newFile(new Blob([s2ab(response.data.data)],{ type: 'text/csv' }), 'Relatório analitico.csv')

                // setLoading(false)
                // setSubmited(true)

                // setData([])
                // setPagination({
                //     totalDocs: 0,
                //     totalPage: 0,
                //     page: page
                // })

           }else{
                const response:any = await apiTropa.post(urlBackend, {
                    agrupamentoPor: groupType,
                    dataInicio: dataInicio,
                    dataFim: dataFinal,
                    tokenInicial: tokenInicial,
                    filtros: {
                        id_operador: idOperador,
                        id_grupo_atendimento: idGrupoAtendimento,
                        canal: canal,
                        search: textFilter
                    }
                })

                if ( type === 'sintetico' ) {
                    setData([...response.data.data])
                }
                else if (type === 'sinteticoGeral') {
                    setData([...response.data.data])
                } 
                else if ( type === 'estatistico' ){
                    setData([...response.data.data])
                } 
                else if ( type === 'analitico' ) {
                    setData([...response.data.data.docs])
                    setPagination({...response.data.data.pagination})
                }
                else if ( type === 'operador') {
                    setData([...response.data.data])
                }
                else if (type === 'pesquisa') {
                    setData([...response.data.data])
                } else if (type === 'buscaAtendimento') {
                    setData([...response.data.data.docs])
                    setPagination({...response.data.data.pagination})
                }
    
                setLoading(false)
                setSubmited(true)
           }

        } catch (err:any) {
            addDanger('Atenção', err.toString())
            setLoading(false)
            setSubmited(false)
        }
    }

    async function handleCopy(id:any) {

        const el:any = document.getElementById(id);
        const headValue: any = document.querySelectorAll('.copyy')
        var body:any = document.body, range:any, sel:any
        var headValueArr = Array.prototype.slice.call(headValue);

        headValueArr.map((row) => row.style.color = 'black')
        tableRef.current.style.backgroundColor = "white";

        if (document.createRange && window.getSelection) {
            range = document.createRange();
            sel = window.getSelection();
            sel.removeAllRanges()
            try {
                range.selectNodeContents(el)
                sel.addRange(range)
            } catch (e) {
                range.selectNode(el)
                sel.addRange(range)
            }
            document.execCommand("copy")

        } else if (body.createTextRange) {
            range = body.createTextRange()
            range.moveToElementText(el)
            range.select()
            range.execCommand("Copy")

        }
        headValueArr.map((row) => row.style.color = 'white')
        tableRef.current.style.backgroundColor = "rgba(0,0,0,0.1)";
        addSuccess('Sucesso', 'Conteúdo copiado com sucesso.')
    }

    async function handleDownload()
    {
        try {
            setLoading(true)
            const response:any = await apiTropa.post('relatorio/analitico-download', {
                agrupamentoPor: groupType,
                dataInicio: dataInicio,
                dataFim: dataFinal,
                tokenInicial: tokenInicial,
                filtros: {
                    id_operador: idOperador,
                    id_grupo_atendimento: idGrupoAtendimento,
                    canal: canal,
                    campos: headersVisible
                }
            })

            newFile(new Blob([s2ab(response.data.data)],{ type: 'text/csv' }), 'Relatório analitico.csv')
            
            setLoading(false)
            setSubmited(true)

            setModalAnalitic(false);
            setData([])
            setPagination({
                totalDocs: 0,
                totalPage: 0,
                page: page
            })

        } catch ( err:any) {
            addDanger('Atenção', err.toString())
        }
    }
    

    function s2ab(s:any) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }

    function handleChangeAreaFilter(e: ChangeEvent<HTMLInputElement>) {
        // const iSplit = e.target.value.split(';')
        // const iReplace = iSplit.map((row: any) => row.trim())
        setTextFilter(e.target.value)
    }

    function newFile(blob:any, name = 'file.txt') {

        // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
        const blobUrl = URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement('a');

        // Set link's href to point to the Blob URL
        link.href = blobUrl;
        link.download = name;

        // Append link to the body
        document.body.appendChild(link);

        // Dispatch click event on the link
        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
            })
        );
        // Remove link from body
        document.body.removeChild(link);
    }

    async function handleNewRelatorio() {
        try {
            setLoading(true)
            setRelatorioSave(false)
            const response:any = await apiTropa.post('relatoriobd', {
                nome: relatorioName,
                json: {
                    tipo: type,
                    agrupamentoPor: groupType,
                    dataInicio: dataInicio,
                    dataFim: dataFinal,
                    tokenInicial: tokenInicial,
                    filtros: {
                        id_operador: idOperador,
                        id_grupo_atendimento: idGrupoAtendimento,
                        canal: canal
                    },
                    headers: headersVisible
                }
            })

            setRelatorioName('')
            setLoading(false)
            addSuccess('Sucesso','Relatório salvo com sucesso.')

        } catch ( err:any ) {
            setRelatorioSave(true)
            addDanger('Atenção', err.toString())
        }
    }

    return (
        <>
            <Loading active={loading} />
            <Container>
                
                <HeaderFiltered>
                    <p className="subtitle">Você gostaria de um relatório:</p>

                    <div className="sectionFilters">

                        <div className="filterLeft">
                            <div className="typeGroup">
                                <InputRadioDefault
                                    // flexDirection='column'
                                    options={[
                                        {
                                            label: 'Sintético',
                                            value: 'sintetico',
                                        },
                                        {
                                            label: 'Sintético Geral',
                                            value: 'sinteticoGeral',
                                        },
                                        {
                                            label: 'Pesquisa',
                                            value: 'pesquisa',
                                        },
                                        {
                                            label: 'Analítico',
                                            value: 'analitico'
                                        }, 
                                        {
                                            label: 'Busca Atendimento',
                                            value: 'buscaAtendimento'
                                        },
                                        {
                                            label: 'Operador',
                                            value: 'operador',
                                        },
                                        // {
                                        //     label: 'Estatístico',
                                        //     value: 'estatistico',
                                        // },
                                    ]}
                                    value={type}
                                    onChange={(value) => setType(value)}
                                />
                            </div>

                            { groupsByType.length > 0 &&
                                <div className="filters">
                                    <div className="groupBy">
                                        <InputRadioDefault
                                            options={groupsByType}
                                            value={groupType}
                                            onChange={(value) => setGroupType(value)}
                                        />
                                    </div>
                                </div>
                            }

                            {/* {type === "buscaAtendimento" && (
                                <div className="subFilters">
                                    <label htmlFor="areaFilter">
                                        <p>Filtro:</p>
                                        <input 
                                            className='inputAreaFilter'
                                            type="text" 
                                            name='areaFilter' 
                                            id='areaFilter'
                                            onChange={(e) => handleChangeAreaFilter(e)}
                                            value={textFilter}
                                            placeholder="Busque por: Protocolo CPF, Telefone, Celular, E-mail "
                                        />
                                    </label>
                                </div>
                            )} */}

                            <div className="groupSerachButtons">
                                {type === "buscaAtendimento" && (
                                    <div className="subFilters">
                                        <label htmlFor="areaFilter">
                                            <p>Filtro:</p>
                                            <input 
                                                className='inputAreaFilter'
                                                type="text" 
                                                name='areaFilter' 
                                                id='areaFilter'
                                                onChange={(e) => handleChangeAreaFilter(e)}
                                                value={textFilter}
                                                placeholder="Busque por: Protocolo CPF, Telefone, Celular, E-mail"
                                                required
                                            />
                                        </label>
                                    </div>
                                )}

                                <div className="buttonsSerach">
                                    <InputDefault
                                        title="De"
                                        placeholder='De'
                                        type="date"
                                        value={dataInicio}
                                        onChangeText={(value) => setDataInicio(value)}
                                    /> 
                                    <InputDefault
                                        title="Até"
                                        type="date"
                                        value={dataFinal}
                                        onChangeText={(value) => setDataFinal(value)}
                                    />
                                    
                                </div>

                                <div className="buttonsSerach">
                                    <ButtonLinear onClick={handleClear} Icon={IconTrash} className="warning">Limpar</ButtonLinear>
                                    <ButtonLinear onClick={handleSubmit} className="info" Icon={IconSearch}>Buscar</ButtonLinear>
                                </div>
                                
                            </div>

                        </div>

                        {/* <div className="filterRight">
                            <div className="filters">
                                <div className="groupSearchButtons">
                                    <ButtonLinear onClick={handleClear} Icon={IconTrash} className="warning">Limpar</ButtonLinear>
                                    <ButtonLinear onClick={handleSubmit} className="info" Icon={IconSearch}>Buscar</ButtonLinear>
                                </div>
                            </div>
                        </div> */}
                    </div>

                    

                    {/* { groupsByType.length > 0 &&
                        <div className="filters">
                            <div className="groupBy">
                                <InputRadioDefault
                                    options={groupsByType}
                                    value={groupType}
                                    onChange={(value) => setGroupType(value)}
                                />
                            </div>
                        </div>
                    } */}
                </HeaderFiltered>

    
                    <>
                        <ContentFiltered submited={submited}>
                            <p className="subtitle">Filtrar por:</p>
                            <div className="filters">

                                { submited && 
                                <div className="groupInputs">

                                    <SelectDefaultSearch
                                        placeholder="Grupos de atendimento"
                                        name="idGrupoAtendimento" 
                                        onChange={(name, value:any) => setIdGrupoAtendimento(value)}
                                        align="right" 
                                        checked={idGrupoAtendimento}
                                        multiple={true}
                                        list={filtroGrupos} 
                                    />

                                    <SelectDefaultSearch
                                        placeholder="Operadores" 
                                        name="idOperador" 
                                        onChange={(name, value:any) => setIdOperador(value)}
                                        align="right" 
                                        checked={idOperador}
                                        multiple={true}
                                        list={filtroOperadores} 
                                    />

                                    <SelectDefaultSearch
                                        placeholder="Canais" 
                                        name="canal" 
                                        onChange={(name, value:any) => setCanal(value)}
                                        align="right" 
                                        checked={canal}
                                        multiple={true}
                                        list={filtroCanais} 
                                    />

                                    <ButtonLinear onClick={handleSubmit} className="info" Icon={IconCheck}>Filtrar</ButtonLinear>
                                    
                                </div>
                                }

                                <div className="groupInputs two">

                                    { type !== 'analitico' &&
                                        <ButtonDownload filename={`Relatório ${type}-${groupTypePTBR[groupType]}`} headers={headersVisible} data={data}/>
                                    }

                                    { type === 'analitico' &&
                                        <ButtonLinear className="success" onClick={handleDownload} Icon={IconDownload}/>
                                    }
                                    
                                    <ButtonLinear className="info" onClick={() => window.print()} Icon={IconPrint}/>
                                    <ButtonLinear className="info" onClick={() => handleCopy('tableManagerTable')} Icon={IconCopy}>
                                        Copiar conteúdo
                                    </ButtonLinear>
                                </div>
                            </div>
                            {/* {type === "buscaAtendimento" && (
                                <div className="subFilters">

                                    <label htmlFor="areaFilter">
                                        <p>Filtro | <span>Exemplo: 1901722; exemplo@teste.com;...</span></p>
                                        <input 
                                            className='inputAreaFilter'
                                            type="text" 
                                            name='areaFilter' 
                                            id='areaFilter'
                                            onChange={(e) => handleChangeAreaFilter(e)}
                                            value={textFilter}
                                        />
                                    </label>
                                </div>
                            )} */}

                        </ContentFiltered>

                        <SectionFiltersSelected>
                            {(idGrupoAtendimento.length > 0 || idOperador.length > 0 || canal.length > 0) && (
                                <h3>Filtros:</h3>
                            )}
                            {idGrupoAtendimento.map((row: any) => (
                                <ButtonFilterSelected
                                    type='button'
                                    onClick={() => handleRemoveFilter('grupo', row)}
                                >
                                    {`${filtroGrupos.filter((obj) => obj.value === row)[0].label}`}
                                    <IconClose size='12px' />
                                </ButtonFilterSelected>
                            ))}

                            {idOperador.map((row: any) => (
                                <ButtonFilterSelected
                                    type='button'
                                    onClick={() => handleRemoveFilter('operador', row)}
                                >
                                    {`${filtroOperadores.filter((obj) => obj.value === row)[0].label}`}
                                    <IconClose size='12px' />
                                </ButtonFilterSelected>
                            ))}

                            {canal.map((row: any) => (
                                <ButtonFilterSelected
                                    type='button'
                                    onClick={() => handleRemoveFilter('canal', row)}
                                >
                                    {`${filtroCanais.filter((obj) => obj.value === row)[0].label}`}
                                    <IconClose size='12px' />
                                </ButtonFilterSelected>
                            ))}

                        </SectionFiltersSelected>

                        <div id="area-print">

                            { submited === true && 
                                <div className="savecontent">
                                    <ButtonLinear Icon={IconFile} onClick={() => setRelatorioSave(true)} className="success">Salvar meu relatório</ButtonLinear>
                                </div>
                            }

                            { type === 'sintetico' &&
                                <TableManager
                                    title={`Relatório sintetico |  ${moment(dataInicio).utc().format("DD-MM-YYYY")} até ${moment(dataFinal).utc().format("DD-MM-YYYY") }`}
                                    submited={submited}
                                    type="sintetico"
                                    headers={headers}
                                    content={data}
                                    tableRef={tableRef}
                                    headersVisible={headersVisible}
                                    setHeadersVisible={(value) => setHeadersVisible(value)}
                                />
                            }

                            { type === 'sinteticoGeral' &&
                                <TableManager
                                    title={`Relatório Sintético Geral |  ${moment(dataInicio).utc().format("DD-MM-YYYY")} até ${moment(dataFinal).utc().format("DD-MM-YYYY") }`}
                                    submited={submited}
                                    type="sinteticoGeral"
                                    headers={headers}
                                    content={data}
                                    tableRef={tableRef}
                                    headersVisible={headersVisible}
                                    setHeadersVisible={(value) => setHeadersVisible(value)}

                                />
                            }

                            { type === 'estatistico' &&
                                <ChartEstatistico
                                    content={data}
                                    submited={submited}
                                    grupos={filtroGrupos}
                                />
                            }

                            { type === 'analitico' &&
                                <TableManager
                                    title={`Relatório analitico | ${dataInicio} até ${dataFinal}`}
                                    submited={submited}
                                    type="analitico"
                                    headers={headers}
                                    content={data}
                                    pagination={pagination}
                                    page={page}
                                    setPage={setPage}
                                    tableRef={tableRef}
                                    headersVisible={headersVisible}
                                    setHeadersVisible={(value) => setHeadersVisible(value)}

                                />
                            }

                            { type === 'operador' &&
                                <TableManager
                                    title={`Relatório Operador | ${dataInicio} até ${dataFinal}`}
                                    submited={submited}
                                    type="operador"
                                    headers={headers}
                                    content={data}
                                    pagination={pagination}
                                    page={page}
                                    setPage={setPage}
                                    tableRef={tableRef}
                                    headersVisible={headersVisible}
                                    setHeadersVisible={(value) => setHeadersVisible(value)}

                                />
                            }

                            { type === 'buscaAtendimento' &&
                                <TableManager
                                    title={`Relatório Busca | ${dataInicio} até ${dataFinal}`}
                                    submited={submited}
                                    type="Busca"
                                    headers={headers}
                                    content={data}
                                    pagination={pagination}
                                    page={page}
                                    setPage={setPage}
                                    tableRef={tableRef}
                                    headersVisible={headersVisible}
                                    setHeadersVisible={(value) => setHeadersVisible(value)}

                                />
                            }

                            { type === 'pesquisa' &&
                                <TableManager
                                    title={`Relatório Busca | ${dataInicio} até ${dataFinal}`}
                                    submited={submited}
                                    type="Busca"
                                    headers={headers}
                                    content={data}
                                    pagination={pagination}
                                    page={page}
                                    setPage={setPage}
                                    tableRef={tableRef}
                                    headersVisible={headersVisible}
                                    setHeadersVisible={(value) => setHeadersVisible(value)}

                                />
                            }

                        </div>
                    </>
                


                <ModalDefault
                    id="modalEncaminhar"
                    openModal={relatorioSave}
                    onClose={() => setRelatorioSave(false)}
                >
                    <Field>
                        <InputDefault
                            placeholder="Nome do relatório"
                            value={relatorioName}
                            onChangeText={(value) => setRelatorioName(value)}
                        />
                    </Field>

                    <FieldGroup marginTop='20px'>
                        <ButtonLinear
                            color={title === 'light' ? '#8A99A8' : '#fff'}
                            className="transparent" 
                            onClick={() => setRelatorioSave(false)}
                        >
                            Cancelar
                        </ButtonLinear>
                        <ButtonLinear className="success" onClick={() => handleNewRelatorio()}>
                            Salvar
                        </ButtonLinear>
                    </FieldGroup>
                </ModalDefault>
                
            </Container>

            {modalHistory && (
                <CardMessageHistory
                    handleOnclose={() => setModalHistory(false)}
                    message={historyMessage}
                />
            )}

            <ModalDefault width="500" id="overlayModal" onClose={() => setModalQuestion(!modalQuestion)} openModal={modalQuestion}>
                <Field style={{ color: '#fff' }}>
                    <label>Respostas</label>

                    {questions.map((question: any, key: any) => (
                        <>
                            <TitleQuestion>
                                <h2>{question.title}</h2>
                            </TitleQuestion>

                            {(question.type === 'checkbox' || question.type === 'radio') && (
                                <ContainerQuestionOne>
                                    {question.items.map((item: any, itemKey: any) => (
                                        <ContainerQuestionItem key={itemKey}>
                                            {item.type === 'checkbox' && (
                                                <InputSwitchDefault
                                                    onChange={(value) => true}
                                                    options={[
                                                        {
                                                            label: item.value,
                                                            value: item.value,
                                                        },
                                                    ]}
                                                    value={question.resposta}
                                                />
                                            )}

                                            {item.type === 'radio' && (
                                                <InputRadioDefault
                                                    onChange={(value) => true}
                                                    options={[
                                                        {
                                                            label: item.value,
                                                            value: item.value,
                                                        },
                                                    ]}
                                                    value={question.resposta}
                                                />
                                            )}

                                            {item.type === 'textarea' && (
                                                <div style={{width: '100%'}}>
                                                    <p style={{marginBottom: 10}}>{item.value}</p>
                                                    <InputTextarea
                                                        readOnly={true}
                                                        onChangeText={(value) => console.log(value)}
                                                        value={question.resposta}
                                                        placeholder={item.value}
                                                    />
                                                </div>
                                            )}
                                        </ContainerQuestionItem>
                                    ))}
                                </ContainerQuestionOne>
                            )}

                            {question.type === 'rating' && (
                                <ContainerQuestion>
                                    <InputRating
                                        min={question.min}
                                        max={question.max}
                                        onChangeText={(value) => console.log('alala')}
                                        value={question.resposta}
                                        readOnly={true}
                                    />
                                </ContainerQuestion>
                            )}
                        </>
                    ))}
                    <ButtonLinear rounded={true} className="danger" onClick={() => setModalQuestion(false)}>
                        Fechar
                    </ButtonLinear>
                </Field>
            </ModalDefault>

            <ModalDefault
                width='50vw'
                id="modalAnalitic"
                openModal={modalAnalitic}
                onClose={() => {
                    setModalAnalitic(!modalAnalitic)
                    setLoading(false)
                }}
            >
                <ContainerModalAnalitic>
                    <div className="iconMessageAnalitic">
                        <div className="contentImageAlert">
                            <img src={AlertImg} alt="Alerta" />
                        </div>
                    </div>

                    <div className="messageModalAnalitic">
                        <h1>Ação necessária</h1>

                        <p>Seu relatório não poderá ser exibido nessa página.</p>
                        <p>Certifique-se que os filtros e busca estão de acordo com o que precisa e <strong>faça o download abaixo.</strong></p>

                        <ButtonLinear className="success" onClick={handleDownload} Icon={IconDownload}>
                            Fazer download do meu relatório
                        </ButtonLinear>
                    </div>
                </ContainerModalAnalitic>
            </ModalDefault>
            
        </>
    )
}
