import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    
    .skeletor {
        margin-bottom: 8px;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-wrap: wrap;        
    justify-content: flex-start;
    gap: 16px;
    width: 100%;
    margin-bottom: 30px;
    select {
        width: 250px;
    }
    .info {
        width: 260px;
    }    
`;

export const AuditContainer = styled.div`
    width: 100%;
    display: flex;    
`;