export const BackgroundDetail = ({
    colorEyou = '#5A646E',
    colorOmni = '#34D5FA',
}) => (
    <svg
        width="115"
        height="253"
        viewBox="0 0 115 253"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_379:6502)">
            <path
                d="M11.3693 94.4563C10.4723 92.0959 7.83645 90.9049 5.48209 91.797C3.12774 92.6891 1.94665 95.3263 2.84365 97.6867C3.74065 100.047 6.37654 101.238 8.7309 100.346C11.0852 99.4539 12.2663 96.8167 11.3693 94.4563Z"
                fill="#34D5FA"
            />
            <path
                d="M35.2671 80.677C34.37 78.3167 31.7342 77.1257 29.3798 78.0178C27.0255 78.9099 25.8444 81.5471 26.7414 83.9075C27.6384 86.2679 30.2737 87.4574 32.6281 86.5653C34.9824 85.6732 36.1641 83.0374 35.2671 80.677Z"
                fill="#34D5FA"
            />
            <path
                d="M59.1917 66.8824C58.2947 64.522 55.6588 63.3311 53.3044 64.2232C50.9501 65.1152 49.769 67.7525 50.666 70.1129C51.563 72.4733 54.1984 73.6628 56.5527 72.7707C58.9071 71.8786 60.0887 69.2428 59.1917 66.8824Z"
                fill="#34D5FA"
            />
            <path
                d="M81.4582 53.4379C80.7301 51.0203 78.1846 49.6457 75.7726 50.3684C73.3607 51.0911 71.9959 53.6375 72.7239 56.0551C73.452 58.4727 75.9971 59.8459 78.4091 59.1232C80.821 58.4005 82.1863 55.8556 81.4582 53.4379Z"
                fill="#34D5FA"
            />
            <path
                d="M107.029 39.2633C106.132 36.903 103.497 35.712 101.142 36.6041C98.7878 37.4962 97.6067 40.1334 98.5037 42.4938C99.4007 44.8542 102.036 46.0437 104.39 45.1516C106.745 44.2595 107.926 41.6237 107.029 39.2633Z"
                fill="#34D5FA"
            />
            <path
                d="M31.6326 111.815C33.8128 110.556 34.5566 107.763 33.294 105.576C32.0313 103.389 29.2404 102.636 27.0601 103.895C24.8799 105.154 24.1361 107.947 25.3987 110.134C26.6614 112.321 29.4524 113.074 31.6326 111.815Z"
                fill="#34D5FA"
            />
            <path
                d="M55.5332 98.0159C57.7134 96.7571 58.4572 93.9638 57.1946 91.7769C55.932 89.5899 53.141 88.8375 50.9608 90.0962C48.7805 91.355 48.0367 94.1482 49.2993 96.3352C50.562 98.5222 53.353 99.2746 55.5332 98.0159Z"
                fill="#34D5FA"
            />
            <path
                d="M80.7117 78.9565C79.9837 76.5389 77.4382 75.1658 75.0262 75.8885C72.6143 76.6112 71.2491 79.1562 71.9771 81.5738C72.7052 83.9914 75.2508 85.3661 77.6627 84.6433C80.0747 83.9206 81.4398 81.3741 80.7117 78.9565Z"
                fill="#34D5FA"
            />
            <path
                d="M103.361 70.4023C105.541 69.1435 106.285 66.3502 105.023 64.1633C103.76 61.9763 100.969 61.2239 98.7889 62.4826C96.6086 63.7414 95.8648 66.5347 97.1274 68.7216C98.3901 70.9086 101.181 71.661 103.361 70.4023Z"
                fill="#34D5FA"
            />
            <path
                d="M30.2001 137.676C32.3803 136.417 33.1242 133.624 31.8615 131.437C30.5989 129.25 27.8079 128.497 25.6277 129.756C23.4475 131.015 22.7037 133.808 23.9663 135.995C25.2289 138.182 28.0199 138.935 30.2001 137.676Z"
                fill="#34D5FA"
            />
            <path
                d="M54.1276 123.861C56.3078 122.603 57.0517 119.809 55.789 117.622C54.5264 115.435 51.7354 114.683 49.5552 115.942C47.375 117.2 46.6311 119.994 47.8938 122.181C49.1564 124.368 51.9474 125.12 54.1276 123.861Z"
                fill="#34D5FA"
            />
            <path
                d="M78.0282 110.062C80.2084 108.804 80.9523 106.01 79.6896 103.823C78.427 101.636 75.636 100.884 73.4558 102.143C71.2756 103.401 70.5317 106.195 71.7944 108.382C73.057 110.569 75.848 111.321 78.0282 110.062Z"
                fill="#34D5FA"
            />
            <path
                d="M101.929 96.2634C104.109 95.0046 104.853 92.2113 103.59 90.0244C102.327 87.8374 99.5365 87.085 97.3563 88.3437C95.176 89.6024 94.4322 92.3957 95.6948 94.5827C96.9575 96.7697 99.7485 97.5221 101.929 96.2634Z"
                fill="#34D5FA"
            />
            <path
                d="M56.1509 143.737C55.2539 141.376 52.6186 140.187 50.2642 141.079C47.9099 141.971 46.7282 144.607 47.6252 146.967C48.5222 149.328 51.1581 150.518 53.5125 149.626C55.8668 148.734 57.0479 146.097 56.1509 143.737Z"
                fill="#34D5FA"
            />
            <path
                d="M80.0642 129.912C79.1672 127.552 76.5319 126.362 74.1775 127.254C71.8232 128.146 70.6415 130.782 71.5385 133.143C72.4355 135.503 75.0714 136.694 77.4258 135.802C79.7801 134.91 80.9612 132.273 80.0642 129.912Z"
                fill="#34D5FA"
            />
            <path
                d="M101.141 121.752C103.321 120.494 104.065 117.7 102.802 115.513C101.54 113.326 98.7486 112.574 96.5684 113.833C94.3882 115.092 93.6444 117.885 94.907 120.072C96.1696 122.259 98.9606 123.011 101.141 121.752Z"
                fill="#34D5FA"
            />
            <path
                d="M51.8803 175.227C54.0605 173.968 54.8044 171.175 53.5417 168.988C52.2791 166.801 49.4881 166.049 47.3079 167.307C45.1277 168.566 44.3838 171.359 45.6465 173.546C46.9091 175.733 49.7001 176.486 51.8803 175.227Z"
                fill="#34D5FA"
            />
            <path
                d="M75.8078 161.412C77.988 160.154 78.7318 157.36 77.4692 155.173C76.2066 152.987 73.4156 152.234 71.2354 153.493C69.0551 154.752 68.3113 157.545 69.5739 159.732C70.8366 161.919 73.6276 162.671 75.8078 161.412Z"
                fill="#34D5FA"
            />
            <path
                d="M99.7084 147.613C101.889 146.355 102.632 143.561 101.37 141.374C100.107 139.188 97.3162 138.435 95.136 139.694C92.9558 140.953 92.2119 143.746 93.4746 145.933C94.7372 148.12 97.5282 148.872 99.7084 147.613Z"
                fill="#34D5FA"
            />
            <path
                d="M74.9928 186.917C77.173 185.658 77.9169 182.865 76.6542 180.678C75.3916 178.491 72.6006 177.739 70.4204 178.997C68.2402 180.256 67.4963 183.049 68.759 185.236C70.0216 187.423 72.8126 188.176 74.9928 186.917Z"
                fill="#34D5FA"
            />
            <path
                d="M98.8934 173.118C101.074 171.859 101.817 169.066 100.555 166.879C99.2922 164.692 96.5012 163.94 94.321 165.198C92.1408 166.457 91.397 169.25 92.6596 171.437C93.9222 173.624 96.7132 174.377 98.8934 173.118Z"
                fill="#34D5FA"
            />
            <path
                d="M122.821 159.303C125.001 158.045 125.745 155.251 124.482 153.064C123.22 150.878 120.429 150.125 118.248 151.384C116.068 152.643 115.324 155.436 116.587 157.623C117.85 159.81 120.641 160.562 122.821 159.303Z"
                fill="#34D5FA"
            />
            <path
                d="M73.5604 212.778C75.7406 211.519 76.4844 208.726 75.2218 206.539C73.9592 204.352 71.1682 203.6 68.988 204.858C66.8078 206.117 66.0639 208.91 67.3266 211.097C68.5892 213.284 71.3802 214.037 73.5604 212.778Z"
                fill="#34D5FA"
            />
            <path
                d="M97.4879 198.963C99.6681 197.705 100.412 194.911 99.1493 192.725C97.8866 190.538 95.0957 189.785 92.9155 191.044C90.7352 192.303 89.9914 195.096 91.254 197.283C92.5167 199.47 95.3077 200.222 97.4879 198.963Z"
                fill="#34D5FA"
            />
            <path
                d="M121.388 185.164C123.569 183.906 124.313 181.112 123.05 178.925C121.787 176.739 118.996 175.986 116.816 177.245C114.636 178.504 113.892 181.297 115.155 183.484C116.417 185.671 119.208 186.423 121.388 185.164Z"
                fill="#34D5FA"
            />
            <path
                d="M96.673 224.468C98.8532 223.209 99.5971 220.416 98.3344 218.229C97.0718 216.042 94.2808 215.29 92.1006 216.549C89.9204 217.807 89.1765 220.601 90.4392 222.788C91.7018 224.974 94.4928 225.727 96.673 224.468Z"
                fill="#34D5FA"
            />
            <path
                d="M120.574 210.669C122.754 209.41 123.498 206.617 122.235 204.43C120.972 202.243 118.181 201.491 116.001 202.749C113.821 204.008 113.077 206.801 114.34 208.988C115.602 211.175 118.393 211.928 120.574 210.669Z"
                fill="#34D5FA"
            />
            <path
                d="M95.2406 250.329C97.4208 249.07 98.1646 246.277 96.902 244.09C95.6393 241.903 92.8484 241.151 90.6681 242.409C88.4879 243.668 87.7441 246.462 89.0067 248.648C90.2694 250.835 93.0604 251.588 95.2406 250.329Z"
                fill="#34D5FA"
            />
            <path
                d="M119.168 236.515C121.348 235.256 122.092 232.463 120.829 230.276C119.567 228.089 116.776 227.336 114.596 228.595C112.415 229.854 111.672 232.647 112.934 234.834C114.197 237.021 116.988 237.773 119.168 236.515Z"
                fill="#34D5FA"
            />
        </g>
        <defs>
            <clipPath id="clip0_379:6502">
                <rect
                    width="188.969"
                    height="182.617"
                    fill="white"
                    transform="translate(0 94.4844) rotate(-30)"
                />
            </clipPath>
        </defs>
    </svg>
);
