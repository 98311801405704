import React, { ButtonHTMLAttributes } from 'react'

import { Container } from './styles'

type PropsInfo = ButtonHTMLAttributes<HTMLButtonElement> & {
  number?: string;
  title: 'Atendimentos' | 'Atendente em pausa' | 'Aguardando' | 'Operadores Online' | 'Abandonos' | 'Finalizados' | 'Dashboard' | string;
  selected: boolean
}

export default function ButtonOperator({ number, title, selected, ...rest }: PropsInfo) {
  return (
    <Container className={selected ? 'active' : ''} title={title} {...rest}>
      <h3 style={{display: 'none'}}>{number}</h3>
      <span>{title}</span>
    </Container>
  )
}
