/* eslint-disable array-callback-return */
import { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useChatbotContext } from '../../contexts/ChatbotContext';
import apiTropa from '../../services/apiTropa';
import ButtonLinear from '../form/ButtonLinear';
import SelectDefault from '../form/SelectDefault';
import { IconCirclePlus, IconPlusCircle, IconTrash } from '../icons';
import InputConditionTarget from './InputConditionTarget';
import InputConditionTargetNumber from './InputConditionTargetNumber';
import {
    ButtonTransparent,
    Container,
    ContainerButtonDeleteCondition,
    ContainerConditionAll,
    ContainerConditionAllAnd,
    ContainerConditionAllIfItem,
    ContainerConditions,
    ContainerIfAndVariable,
    ContainerPadding,
    ContainerWhiteShadow,
} from './styles';

interface IProps {}

export default function ConditionsEdit({}: IProps) {
    const refScroll = useRef<any>();
    const { elements, selected, setSelected, handleEditElement, setElements, handleSetSelectedElementContext } =
        useChatbotContext();
    const [gruposAtendimento, setGruposAtendimento] = useState<any>([]);

    useEffect(() => {
        apiTropa.get('grupo-atendimento').then((response: any) => {
            setGruposAtendimento([...response.data.data]);
        });
    }, []);

    const conditionDefault = {
        all: [
            {
                if: 'respostaUsuario',
                condition: 'existe',
            },
        ],
        target: '',
    };

    function onScrollDown() {
        setTimeout(() => {
            if (refScroll && refScroll.current) refScroll.current.scrollToBottom();
        }, 50);
    }

    function handleSetExceptionTarget(target: any) {
        const newSelected: any = selected;
        newSelected.params.exception = target;

        handleEditElement(newSelected);
        setSelected({ ...newSelected });
    }

    function handleNewCondition() {
        const newSelected: any = selected;
        newSelected.params.conditions.push(conditionDefault);
        handleEditElement(newSelected);
        onScrollDown();
    }

    function handleNewConditionAnd(key: any) {
        const newSelected: any = selected;
        newSelected.params.conditions[key].all.push(conditionDefault);
        handleEditElement(newSelected);
        setSelected({ ...newSelected });
    }

    function handleEditCondition(keyParent: any, key: any, name: any, value: any) {
        const newSelected: any = selected;
        newSelected.params.conditions[keyParent].all[key][name] = value.replace(', ', ',').replace(' ,', ',');
        handleEditElement(newSelected);
        setSelected({ ...newSelected });
    }

    function handleRemoveCondition(key: any) {
        const newSelected: any = selected;

        const conditionsFIlter: any = [];

        newSelected.params.conditions.map((row: any, keyCondition: any) => {
            if (key !== keyCondition) {
                conditionsFIlter.push(row);
            }
        });

        newSelected.params.conditions = conditionsFIlter;
        handleEditElement(newSelected);

        //faz os caminhos
        const ElementsFilter = elements.filter((obj) => obj.source !== selected.id);

        newSelected.params.conditions.map((row: any, key: any) => {
            var newConnect = {
                id: `e${selected.id}-${row.target}`,
                source: selected.id,
                target: row.target,
                arrowHeadType: 'arrowclosed',
            };

            ElementsFilter.push(newConnect);
        });

        setElements(ElementsFilter);
    }

    function handleRemoveConditionAnd(keyParent: any, key: any) {
        const newSelected: any = selected;
        const conditionsFIlter: any = [];

        newSelected.params.conditions[keyParent].all.map((row: any, conditionKey: any) => {
            if (key !== conditionKey) {
                conditionsFIlter.push(row);
            }
        });

        newSelected.params.conditions[keyParent].all = conditionsFIlter;
        handleEditElement(newSelected);
        setSelected({ ...newSelected });

        if (newSelected.params.conditions[keyParent].all.length === 0) handleRemoveCondition(keyParent);
    }

    function handleEditConditionTarget(keyParent: any, value: any) {
        const newSelected: any = selected;
        newSelected.params.conditions[keyParent].target = value;
        handleEditElement(newSelected);
        setSelected({ ...newSelected });

        const ElementsFilter = elements.filter((obj) => obj.source !== selected.id);

        newSelected.params.conditions.map((row: any, key: any) => {
            var newConnect = {
                id: `e${selected.id}-${row.target}`,
                source: selected.id,
                target: row.target,
                arrowHeadType: 'arrowclosed',
            };

            ElementsFilter.push(newConnect);
        });

        setElements(ElementsFilter);
    }

    function handleEditConditionGrupo(keyParent: any, value: any) {
        const newSelected: any = selected;
        newSelected.params.conditions[keyParent].grupoAtendimento = value;
        handleEditElement(newSelected);
        setSelected({ ...newSelected });
    }

    return (
        <Container id="conditions-edit">
            {selected.typeDialog !== 'attendance' && (
                <Scrollbars ref={refScroll}>
                    <ContainerPadding>
                        <ContainerWhiteShadow>
                            <b>Saída Padrão</b>
                            <p>Sempre que o usuário sair do fluxo criado, vai ser levado a esse diálogo.</p>
                            <SelectDefault
                                value={selected.params?.exception}
                                onChangeText={(value) => handleSetExceptionTarget(value)}
                            >
                                <option value="" disabled>
                                    Selecione
                                </option>
                                {elements.map(
                                    (row, key) =>
                                        row.params?.nameDialog && (
                                            <option key={key} value={row.id}>
                                                {row.params?.nameDialog}
                                            </option>
                                        )
                                )}
                            </SelectDefault>
                        </ContainerWhiteShadow>

                        <ContainerConditions>
                            <ButtonLinear onClick={handleNewCondition} Icon={IconCirclePlus} className="info">
                                Adicionar condição
                            </ButtonLinear>

                            {selected.params?.conditions &&
                                selected.params.conditions.map((row, key) => (
                                    <ContainerWhiteShadow style={{ marginTop: '10px' }} key={key}>
                                        {row.all.map((condition, conditionKey) => (
                                            <ContainerConditionAll key={conditionKey}>
                                                {row.all.length > 1 && conditionKey > 0 && (
                                                    <ContainerConditionAllAnd>
                                                        <span>{`&`}</span>
                                                    </ContainerConditionAllAnd>
                                                )}

                                                <ContainerConditionAll>
                                                    <ContainerConditionAllIfItem>
                                                        <ContainerIfAndVariable condition={condition.if}>
                                                            <SelectDefault
                                                                value={condition.if}
                                                                onChangeText={(value) =>
                                                                    handleEditCondition(key, conditionKey, 'if', value)
                                                                }
                                                            >
                                                                <option value="" disabled>
                                                                    Se
                                                                </option>
                                                                <option value="respostaUsuario">
                                                                    Se resposta do usuário
                                                                </option>
                                                                <option value="variavel">Se variavel</option>
                                                            </SelectDefault>

                                                            {condition.if === 'variavel' && (
                                                                <input
                                                                    placeholder="Nome da variavel"
                                                                    value={condition.variable}
                                                                    onChange={(e) =>
                                                                        handleEditCondition(
                                                                            key,
                                                                            conditionKey,
                                                                            'variable',
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </ContainerIfAndVariable>

                                                        <SelectDefault
                                                            value={condition.condition}
                                                            onChangeText={(value) =>
                                                                handleEditCondition(
                                                                    key,
                                                                    conditionKey,
                                                                    'condition',
                                                                    value
                                                                )
                                                            }
                                                        >
                                                            <option value="" disabled>
                                                                Condição
                                                            </option>
                                                            <option value="existe">Existe</option>
                                                            <option value="igual">Igual a</option>
                                                            <option value="diferente">Diferente de</option>
                                                            <option value="contem">Contém</option>
                                                            <option value="comeco">Começa com</option>
                                                            <option value="termino">Termina com</option>
                                                            <option value="maior">Maior que</option>
                                                            <option value="menor">Menor que</option>
                                                            <option value="maiorIgual">Maior ou igual que</option>
                                                            <option value="menorIgual">Menor ou igual que</option>
                                                        </SelectDefault>

                                                        {condition.condition && (
                                                            <InputConditionTarget
                                                                condition={condition}
                                                                handleEditCondition={handleEditCondition}
                                                                indice={key}
                                                                indiceCondition={conditionKey}
                                                            />
                                                        )}

                                                        {condition.condition && (
                                                            <InputConditionTargetNumber
                                                                condition={condition}
                                                                handleEditCondition={handleEditCondition}
                                                                indice={key}
                                                                indiceCondition={conditionKey}
                                                            />
                                                        )}
                                                    </ContainerConditionAllIfItem>

                                                    <ButtonTransparent
                                                        onClick={() => handleRemoveConditionAnd(key, conditionKey)}
                                                    >
                                                        <IconTrash />
                                                    </ButtonTransparent>
                                                </ContainerConditionAll>
                                            </ContainerConditionAll>
                                        ))}

                                        <ContainerConditionAllAnd onClick={() => handleNewConditionAnd(key)}>
                                            <IconPlusCircle />
                                        </ContainerConditionAllAnd>

                                        <ContainerIfAndVariable>
                                            <div style={{ width: 220 }}>
                                                <SelectDefault
                                                    value={row.target}
                                                    onChangeText={(value) => handleEditConditionTarget(key, value)}
                                                >
                                                    <option disabled value="">
                                                        Ir para
                                                    </option>
                                                    {elements.map(
                                                        (row, key) =>
                                                            row.params?.nameDialog && (
                                                                <option key={key} value={row.id}>
                                                                    {row.params?.nameDialog}
                                                                </option>
                                                            )
                                                    )}
                                                </SelectDefault>
                                            </div>

                                            <ButtonLinear
                                                className="info"
                                                onClick={() =>
                                                    setSelected(
                                                        elements.filter(
                                                            (obj: any) => obj.id.toString() === row.target.toString()
                                                        )[0]
                                                    )
                                                }
                                            >
                                                Visualizar
                                            </ButtonLinear>
                                        </ContainerIfAndVariable>

                                        {Number(row.target) === 5 && (
                                            <SelectDefault
                                                placeholder="Selecione um grupo de atendimento"
                                                value={row.grupoAtendimento}
                                                onChangeText={(value) => handleEditConditionGrupo(key, value)}
                                            >
                                                {gruposAtendimento.map((row: any, key: number) => (
                                                    <option key={key} value={row.id_grupo_atendimento}>
                                                        {row.grupo_atendimento}
                                                    </option>
                                                ))}
                                            </SelectDefault>
                                        )}

                                        <ContainerButtonDeleteCondition>
                                            <ButtonLinear
                                                onClick={() => handleRemoveCondition(key)}
                                                Icon={IconTrash}
                                                className="danger"
                                            >
                                                Deletar condição
                                            </ButtonLinear>
                                        </ContainerButtonDeleteCondition>
                                    </ContainerWhiteShadow>
                                ))}
                        </ContainerConditions>
                    </ContainerPadding>
                </Scrollbars>
            )}
        </Container>
    );
}
