/* eslint-disable no-throw-literal */
import { useEffect, useState, useRef } from 'react';
import { useRoutes } from '../../contexts/RoutesContext';
import { useMessage } from '../../contexts/MessagesContext';

import apiEyou from '../../services/apiEyou';
import { UserEyou } from '../../types/UserEyou';
import { Perfil } from '../../types/Perfil';
import { PerfilTropa } from '../../types/PerfilTropa';
import Column from '../../components/grid/Columns';
import Row from '../../components/grid/Row';
import CardContainer from '../../components/layout/containers/CardContainer';
import ButtonLinear from '../../components/form/ButtonLinear';
import SelectDefault from '../../components/form/SelectDefault';
import InputDefault from '../../components/form/InputDefault';
import apiTropa from '../../services/apiTropa';
import { useHistory } from "react-router-dom";
import InputSwitchDefault from '../../components/form/InputSwitchDefault';
import { Container, Main, EditButton } from './styles';
import { useTheme } from 'styled-components';

interface Props {
	match: any;
}

const UsuarioEditar: React.FC<Props> = (props) => {
	const { setPageName, getProfile } = useRoutes();
	const { addDanger, addSuccess } = useMessage();
	let history = useHistory();
	const {title} = useTheme() 

	//userEyou que vem com os dados do eyou
	const [userEyou, setUserEyou] = useState<UserEyou>({} as UserEyou);

	//userEyou que vem com os dados do omni tropa
	const [userTropa, setUserTropa] = useState<PerfilTropa>({} as PerfilTropa)

	//lista de perfis de usuario
	const [perfilLista, setPerfilLista] = useState<Perfil[]>([]);

	//para mudar o password
	const [password, setPassword] = useState({
		password:'',
		repetePassword:''
	});

	// const [rulesList, setRuleList] = useState<any>([
	// 	{
	// 		label: 'Fila de atendimento',
	// 		value: 'Fila de atendimento'
	// 	},
	// 	{
	// 		label: 'Atendimentos',
	// 		value: 'Atendimentos'
	// 	},
	// 	{
	// 		label: 'Grupos de Atendimento',
	// 		value: 'Grupos de Atendimento'
	// 	},
	// 	{
	// 		label: 'Chatbot',
	// 		value: 'Chatbot'
	// 	},
	// 	{
	// 		label: 'Novo relatório',
	// 		value: 'Novo relatório'
	// 	},
	// 	{
	// 		label: 'Meus relatórios',
	// 		value: 'Meus relatórios'
	// 	},
	// 	{
	// 		label: 'Usuários',
	// 		value: 'Usuários'
	// 	},
	// 	{
	// 		label: 'Bloqueios',
	// 		value: 'Bloqueios'
	// 	},
	// 	{
	// 		label: 'Configurar sons',
	// 		value: 'Configurar sons'
	// 	},
	// 	// {
	// 	// 	label: 'Configurações',
	// 	// 	value: 'Configurações'
	// 	// },
	// 	// {
	// 	// 	label: 'Relatórios',
	// 	// 	value: 'Relatórios'
	// 	// },
	// 	// {
	// 	// 	label: 'Pesquisas',
	// 	// 	value: 'Pesquisas'
	// 	// },
	// 	{
	// 		label: 'Sair do painel',
	// 		value: 'Sair do painel'
	// 	},
	// ]);


	const rulesList = [
		{
            label: "Fila de atendimento",
            value: "/"
        },
        {
            label: "Atendimentos",
            value: "/atendimentos"
        },
        {
            label: "Grupos de Atendimento",
            value: "/grupos-de-atendimento"
        },
        {
            label: "Chatbot",
            value: "/chatbot"
        },
        {
            label: "Novo relatório",
            value: "/relatorios"
        },
        {
            label: "Meus relatórios",
            value: "/relatorios/salvos"
        },
        {
            label: "Usuários",
            value: "/usuarios"
        },
		{
            label: "Auditoria",
            value: "/auditoria"
        },
        {
            label: "Bloqueios",
            value: "/bloqueios"
        },
        {
            label: "Configurar sons",
            value: "/configurar-sons"
        },
        {
            label: "Sair do painel",
            value: "/sair"
        },
	] as any;


	const inputFile:any = useRef(null);
	const onClickFile = () => { inputFile.current.click() };
    
    async function onChangeFile(e:any){
        try
        {
            const formData = new FormData();
            formData.append("files", e.target.files[0], e.target.files[0].name);
			formData.append("idApp", '2 ');
			formData.append("destination", '1');
			formData.append("share",'1');

			let tokenInicial = window.sessionStorage.getItem('tokenInicial');

            let response : any = await apiEyou.post(
				'apiDrive', 
				formData,
				{
					headers: {
						Authorization: 'Bearer ' + tokenInicial,
					},
				}
			);

			if(response.data.data.error === true){
				throw 'Não foi possivel salvar a foto';
			}

			let input:any = userTropa
			input.foto = response.data.data.fileUrl
			setUserTropa({...input})
        
        } catch(e){
            addDanger('Ocorreu um erro', 'Não foi possível salvar a foto');
        }
    }

	useEffect(() => {
		setPageName('Usuário');

		if(props.match.params.id && props.match.params.id !== '0'){
			getUsuarioById(props.match.params.id);
		}

		getPerfilLista();

		if(props.match.params.id && props.match.params.id !== '0'){
			getUsuarioById(props.match.params.id);
		}

		getPerfilLista();
	}, [setPageName]);

	
	async function getPerfilLista(){
		try{
			let tokenFinal : any = window.sessionStorage.getItem('token');
		
			let perfilResult: any = await apiTropa.get(
				'perfil',
				{
					headers: {
						Authorization: 'Bearer ' + tokenFinal,
					},
				}
			);

			setPerfilLista(perfilResult.data.data)
		}catch(e){
			addDanger('Ocorreu um erro', 'Não foi possível recuperar os perfis');
		}
	}


	async function getUsuarioById(login:String) {
		try {

			let tokenInicial = window.sessionStorage.getItem('tokenInicial');
			let tokenFinal : any = window.sessionStorage.getItem('token');
			
			//recupera usuario pelo login
			let usuarioResult: any = await apiEyou.post(
				'apiUsuario',
				{
					type: 'get',
					function: 'listAllUsers',
					data: {
						filter: true,
						login: login,
					},
				},
				{
					headers: {
						Authorization: 'Bearer ' + tokenInicial,
					},
				}
			);

			let rightResult :any = {}

			if(usuarioResult.data.data.length > 0){
				usuarioResult.data.data.map((row:any) => {
					if(row.login == login){
						rightResult = row
					}
				})
			}


			
			//decripta
			let tokenDecryptUser : any = await apiEyou.post('/apiOmni', {
                type: "get",
                function: "aesDecrypt",
                data: {
                    toDecrypt: JSON.stringify(rightResult.idUser)
                }
            }, {
                headers: {
                    Authorization: 'Bearer ' + tokenInicial,
                },
            })

            rightResult.idUserDecrypt = tokenDecryptUser.data.data.decrypt;

			let perfilResult: any = await apiTropa.get(
				'perfil/usuario/'+rightResult.idUserDecrypt,
				{
					headers: {
						Authorization: 'Bearer ' + tokenFinal,
					},
				}
			);

			setUserEyou(rightResult);
			setUserTropa({...perfilResult.data.data})

		} catch(e : any){
			if(e.response){
				let errorData : any = JSON.parse(e.response.data)
				addDanger('Ocorreu um erro',errorData.message)
			}else if(e.message){
				addDanger('Ocorreu um erro', e.message)
			}else{
				addDanger('Ocorreu um erro', e)
			}
		}
	}

	async function save(){
		try{

			let tokenInicial = window.sessionStorage.getItem('tokenInicial');
			let tokenFinal : any = window.sessionStorage.getItem('token');


			if(!userTropa.id_perfil){
				throw 'Perfil é obrigatório'
			}

			if(!userTropa.apelido){
				throw 'Apelido é obrigatório'
			}

			if(!userEyou.email){
				throw 'E-mail é obrigatório'
			}

			if(!userEyou.login){
				throw 'Login é obrigatório'
			}

			await apiEyou.post(
				'apiUsuario',
				{
					type: 'set',
					function: 'updateUserData',
					data: userEyou,
				},
				{
					headers: {
						Authorization: 'Bearer ' + tokenInicial,
					},
				}
			);

			//atualiza permissoes
			let permissoes : any = await apiEyou.post('apiUsuario',{
				type: "set",
				function: "updateUserPermission",
				data: {
					idUser: userEyou.idUser,
					apiDados: 1,
					apiUsuario: 0,
					apiTimeline: 0,
					apiBlacklist: 0,
					apiCentroCusto: 0,
					apiMsgPre: 0,
					apiEnvAvulso: 0,
					apiEnvLista: 0,
					apiEnvLote: 0,
					apiEnvHttp: 0,
					apiShortOne: 0,
					apiShortTwo: 0,
					apiShortFlash: 0,
					apiShortConc: 0,
					apiShortener: 0,
					apiLong: 0,
					apiRelEnv: 0,
					apiRelResp: 0,
					apiRelAnalitico: 0,
					apiSensiveData: 0,
					apiTemplate: 0,
					apiVoz: 0
				}
			},
			{
				headers: {
					Authorization: 'Bearer ' + tokenInicial,
				},
			}) 

			await apiTropa.put(
				'perfil/usuario/'+userTropa.id_perfil_permissao,
				userTropa,
				{
					headers: {
						Authorization: 'Bearer ' + tokenFinal,
					},
				}
			)

			addSuccess('Usuario Editado com sucesso','')
			setTimeout(() => {
				getProfile()
                history.push('/usuarios')
            }, 1000);

		}catch(e : any){
			if(e.response){
				let errorData : any = e.response.data
				addDanger('Ocorreu um erro',errorData.message)
			}else if(e.message){
				addDanger('Ocorreu um erro', e.message)
			}else{
				addDanger('Ocorreu um erro', e)
			}
		}
	}

	async function savePassword(){
		try{

			let tokenInicial = window.sessionStorage.getItem('tokenInicial');

			if(password.password !== password.repetePassword ){
				throw 'Os campos senhas possuem valores diferentes'
			}
			
			let resultPass = await apiEyou.post(
				'apiUsuario',
				{
					type: 'set',
					function: 'updateUserPassword',
					data: {
						idUser: userEyou.idUser,
						password: password.password
					},
				},
				{
					headers: {
						Authorization: 'Bearer ' + tokenInicial,
					},
				}
			);

			addSuccess('Senha Alterada com sucesso','')
			/*setTimeout(() => {
                window.location.reload();
            }, 1000);
			*/
			

		}catch(e : any){
			if(e.response){
				let errorData : any = e.response.data
				addDanger('Ocorreu um erro',errorData.message)
			}else if(e.message){
				addDanger('Ocorreu um erro', e.message)
			}else{
				addDanger('Ocorreu um erro', e)
			}
		}
	}

	const setDadosEyou = (key: string, value:any) => {
		let input:any = userEyou
		input[key] = value

		if(key === 'name'){

			setDadosTropa('nome',value)
		}

		setUserEyou({...input})
	}

	const setDadosTropa = (key: string, value:any) => {
		let input : any = userTropa
		input[key] = value
		setUserTropa({...input})  
	}

	function handleOnPerfil(name: string, value: any) {	
		setUserTropa({...userTropa, [name]: value})
	}

	function handleSetData(name: string, value: any) {
		const newValue = [] as any

		value.map((row: any) => {
			const filterPaths = rulesList.filter((obj: any) => obj.value === row)
			if(filterPaths.length > 0) {
				newValue.push(filterPaths[0])
			}
		})
		
		setUserTropa({...userTropa, [name]: newValue})
	}

	const setDadosPassword = (key: string, value:any) => {
		let input : any = password
		input[key] = value
		setPassword({...input})  
	}

	async function alterarStatusDoUsuario(value:boolean){
        try {
			
            let user = userEyou 

			if(value){
				user.status = "ativo"
			}else{
				user.status = "inativo"
			} 

			setUserEyou({...user})
        } catch (err:any) {
            addDanger('Atenção', String(err))
        }
    }

	async function alterarPermissaoDoUsuario(value:boolean,key:string){
        try {
            let user = userTropa 

			if(value){
				user[key as keyof PerfilTropa] = 1
			}else{
				user[key as keyof PerfilTropa] = 0
			} 

			setUserTropa({...user})
        } catch (err:any) {
            addDanger('Atenção', String(err))
        }
    }

	// const [permissionsUser, setPermissionsUser] = useState<any[]>([])

	// useEffect(() => {
	// 	const newPermissions: any[] = []
	// 	userTropa.paths?.map((row:any) => {
	// 		newPermissions.push(row.value)
	// 	})
	// 	setPermissionsUser([...newPermissions])
	// }, [userTropa])

	return (
		<Container>
			<Row>
				<Column width='2'>
					<EditButton>
						<ButtonLinear className="success" onClick={() => save()}>
							Salvar
						</ButtonLinear>
					</EditButton>
				</Column>
				<Column width='2'>
					<EditButton>
							<ButtonLinear color={title === 'light' ? '#8A99A8' : '#fff'} onClick={() => history.goBack()} className="transparent">
								Cancelar
							</ButtonLinear>
					</EditButton>
				</Column>
				<Column width='8'>
				</Column>
			</Row>
			<Main>
				<Row>
					<Column width="6">
						<CardContainer>
							<Row>
								<Column width='9'>
									<p className="title-card">Dados Pessoais</p>
								</Column>
								<Column width='3'>
									<InputSwitchDefault
										options={[{label: "Usuário Ativo", value: "1"}]}
										value={[userEyou.status !== "ativo" ? "0" : "1"]}
										onChange={(value) => alterarStatusDoUsuario(!value.length ? false : true)}
									/>
								</Column>
							</Row>
							<Row mb="3">
								<Column width='6'>
									<input type="file"
										className="display-none" 
										ref={inputFile} 
										name="imagem"  
										onChange={(e) => onChangeFile(e)}
										style={{
											display: `none`,
										}}  
										/> 
									<div
										onClick={onClickFile}
										className="image"
										style={{
											backgroundImage: userTropa && userTropa.foto ? 'url('+userTropa.foto+')' : 'url()',
											backgroundSize: 'cover'
										}}
									>
										<svg
											width="114"
											height="70"
											viewBox="0 0 114 70"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M112 2C112 14.6736 108.351 27.0792 101.489 37.7345C94.6272 48.3898 84.8425 56.844 73.3043 62.0872C61.7661 67.3303 48.9623 69.1405 36.4229 67.3015C23.8834 65.4624 12.1386 60.052 2.5918 51.7166"
												stroke="#34D5FA"
												stroke-width="4"
												stroke-linecap="round"
											/>
										</svg>
									</div>
								</Column>
							</Row>


							<Row mt="5">
									<Column width="6">
										<InputDefault
											onChangeText={(value) => setDadosEyou('name', value)}
											placeholder={'Nome'}
											value={userEyou.name}
									
										/>
									</Column>

									<Column width="6">
										<InputDefault
											onChangeText={(value) => setDadosTropa('apelido', value)}
											placeholder={'Apelido'}
											value={userTropa.apelido}
										/>
									</Column>
									
								</Row>
								<Row mt="5">
									<Column width="6">
										<InputDefault
											onChangeText={(value) => setDadosEyou('phone', value)}
											placeholder={'Telefone'}
											value={userEyou.phone}
											mask={'(99) 9999-9999'}
										/>
									</Column>
									<Column width="6">
										<InputDefault
											onChangeText={(value) => setDadosEyou('mobilePhone', value)}
											placeholder={'Celular'}
											mask={'(99) 9999-99999'}
											value={userEyou.mobilePhone}
										/>
									</Column>
								</Row>

								<Row mt="5">
									<Column width="6">
										<InputDefault
											onChangeText={(value) => setDadosEyou('email', value)}
											placeholder={'E-mail'}
											value={userEyou.email}
										/>
									</Column>
									<Column width="6">
										<InputDefault
											defaultValue={userEyou.login}
											onChangeText={(value) => setDadosEyou('email',value)}
											placeholder={'Login'}
											disabled={true}
											value={userEyou.login}
										/>
									</Column>
								</Row>
								<Row>
									<Column width='12'>
										<div className="hr-dashed" />
										<p className="title-card">Mudar Senha</p>
									</Column>
								</Row>
								<Row mt="0">
									<Column width="6">
										<InputDefault
											onChangeText={(value) => setDadosPassword('password', value)}
											placeholder={'Senha'}
											value={password.password}
											type={'password'}
										/>
									</Column>
									<Column width="6">
										<InputDefault
											onChangeText={(value) => setDadosPassword('repetePassword',value)}
											placeholder={'Repetir Senha'}
											value={password.repetePassword}
											type={'password'}
										/>
									</Column>
								</Row>
								<Row mt='3'>
									<Column width='5'>
										<EditButton>
											<ButtonLinear className="success" onClick={() => savePassword()}>
												Alterar/Criar Senha
											</ButtonLinear>
										</EditButton>
									</Column>
								</Row>
							 </CardContainer>
							
					</Column>

					<Column width="6">
						<CardContainer>
							<p className="title-card">Perfil</p>
							<Row mt="3">
								<Column width="12">
									<SelectDefault
										value={userTropa && userTropa.id_perfil ? userTropa.id_perfil: ''}
										placeholder="Perfil do Usuario"
										onChangeText={(value) => handleOnPerfil("id_perfil", Number(value))}
									>
										{
											perfilLista && perfilLista.length > 0 ? perfilLista.map((row, key) => 
												<option value={row.id_perfil}>{row.perfil}</option>
											)
											: null
										}
									
									</SelectDefault>
								</Column>
							</Row>


							{
								perfilLista && userTropa.id_perfil &&
								perfilLista[perfilLista.findIndex(x => x.id_perfil === userTropa.id_perfil)].perfil === "Administrador" && (
								<InputSwitchDefault
									flexDirection='column'
									options={rulesList}
									value={userTropa.paths.map((row: any) => row.value)}
									onChange={(value) => handleSetData("paths", value)}
								/>
							)}

							{	// verifica se é operador
								perfilLista && userTropa.id_perfil &&
									perfilLista[perfilLista.findIndex(x => x.id_perfil === userTropa.id_perfil)].perfil  === 'Operador' ?
										<>
											<Row mt="3">
												<Column width="12">
													<InputDefault
														type='number'
														onChangeText={(value) => (setDadosTropa('limite_atendimentos',value))}
														title={'Limite de Atendimentos'}
														value={userTropa.limite_atendimentos}
													/>
												</Column>
											</Row>
											<Row>
												<Column width='12'>
													<InputSwitchDefault
														options={[{label: "Enviar imagens", value: 1}]}
														value={[userTropa.imagem !== 1 ? 0 : 1]}
														onChange={(value) => alterarPermissaoDoUsuario(!value.length ? false : true, 'imagem')}
													/>
												</Column>
											</Row>
											<Row>
												<Column width='12'>
													<InputSwitchDefault
														options={[{label: "Enviar audio", value: 1}]}
														value={[userTropa.audio !== 1 ? 0 : 1]}
														onChange={(value) => alterarPermissaoDoUsuario(!value.length ? false : true, 'audio')}
													/>
												</Column>
											</Row>
											<Row>
												<Column width='12'>
													<InputSwitchDefault
														options={[{label: "Enviar video", value: 1}]}
														value={[userTropa.video !== 1 ? 0 : 1]}
														onChange={(value) => alterarPermissaoDoUsuario(!value.length ? false : true, 'video')}
													/>
												</Column>
											</Row>

											<Row>
												<Column width='12'>
													<InputSwitchDefault
														options={[{label: "Enviar arquivo", value: 1}]}
														value={[userTropa.arquivo !== 1 ? 0 : 1]}
														onChange={(value) => alterarPermissaoDoUsuario(!value.length ? false : true, 'arquivo')}
													/>
												</Column>
											</Row>

											<Row>
												<Column width='12'>
													<InputSwitchDefault
														options={[{label: "Alterar dados de atendimentos finalizados", value: 1}]}
														value={[userTropa.alterar_dados !== 1 ? 0 : 1]}
														onChange={(value) => alterarPermissaoDoUsuario(!value.length ? false : true, 'alterar_dados')}
													/>
												</Column>
											</Row>
										</>
									: null
							}	
				
						</CardContainer>
					</Column>

				</Row>	
			</Main>
		</Container>
	);
};

export default UsuarioEditar;
