import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import apiTropa from '../../services/apiTropa';
import { Container } from './styles';

export default function PageChatSMS() {

    const history = useHistory()
    const params = useParams<any>()
    const idAtendimento = params.id_atendimento
    const tagAtendimento = params.tag
    const [load, setLoad] = useState(true)

    useEffect(() => {

        setLoad(true)
         apiTropa.get('atendimento/novo/'+idAtendimento).then((response:any) => {
            
            const chatAtendence:any = JSON.stringify(response.data.data)

            const dadosEntrada = JSON.parse(JSON.parse(response.data.data.usuario.informacao_entrada))

            const chatPerfil:any = JSON.stringify({
                "Nome completo": dadosEntrada['Nome completo']
            })

            window.sessionStorage.setItem('chatAtendence', chatAtendence);
            window.sessionStorage.setItem('chatPerfil', chatPerfil);
            history.push('/chat/'+tagAtendimento)

            setLoad(false)
        })

    }, [])

    return (
        <Container>
            <Loading active={load}/>
        </Container>
    );
}
