import React, { useEffect, useState } from 'react';
import { IconSearch } from '../../icons';
import InputMask from 'react-input-mask';

import { Container } from './styles';

interface InputProps extends React.HTMLAttributes<HTMLInputElement> {
    placeholder?: string;
    value: string | any;
    onChangeText: (value: string | any) => void;
    className?: any;
    disabled?: boolean;
    title?: string;
}

const InputPixel: React.FC<InputProps> = ({
    placeholder,
    value,
    onChangeText,
    className = '',
    disabled = false,
    title = null,
    ...rest
}) => {
    const [valueInput, setValueInput] = useState<string>(value);

    useEffect(() => {
        setValueInput(value.replaceAll('px', ''));
    }, [value]);

    function handleOnChange(e: React.FormEvent<HTMLInputElement>) {
        setValueInput(e.currentTarget.value);
        onChangeText(e.currentTarget.value + 'px');
    }

    return (
        <Container className={`form-children-default input ${className}`}>
            {title && <p className="title">{title}</p>}
            <input
                type="number"
                value={valueInput}
                onChange={handleOnChange}
                placeholder={placeholder}
                disabled={disabled}
                {...rest}
            />
        </Container>
    );
};

export default InputPixel;
