import { useEffect, useState, useRef } from 'react';
import ButtonLinear from '../../../components/form/ButtonLinear';
import BackPage from '../../../components/layout/UIElements/BackPage';
import { useMessage } from '../../../contexts/MessagesContext';
import { useRoutes } from '../../../contexts/RoutesContext';
import apiTropa from '../../../services/apiTropa';
import Skeleton from 'react-loading-skeleton';
import { Container, Header, FunilAtendimentoComponent } from './styles';
import { useHistory } from 'react-router-dom';
import { IconConfig, IconDelete, IconPlus } from '../../../components/icons';
import { Card } from '../../../components/UserList/styles';
import ModalDefault from '../../../components/ModalDefault';
import Field, { Label } from '../../../components/layout/containers/Field';
import InputDefault from '../../../components/form/InputDefault';
import SelectDefault from '../../../components/form/SelectDefault';
import FieldGroup from '../../../components/layout/containers/FieldGroup';


interface IMessage {
    message: string;
    type: 'texto' | 'audio' | 'video' | 'imagem' | 'arquivo';
    url?: string;
    date: Date;
    status: 'pendente' | 'enviado' | 'confirmado';
}

interface IGrupoAtendimentoFunilEtapa{
    id_grupo_atendimento_funil_etapa?: string|any;
    id_grupo_atendimento_funil?:string;
    etapa?:string;
    ordem?:number|any;
    status?:string;
    modificado?:Date;
    criado?:Date;
}

interface IGrupoAtendimentoFunil{    
    id_grupo_atendimento_funil?:string;
    id_grupo_atendimento?: string;
    ordem?:number;
    status?:string;
    modificado?:Date;
    criado?:Date;
}

export default function FunilAtendimento(props: any) {
    const { setPageName } = useRoutes()
    const { addConfirm, addDanger, addSuccess } = useMessage()
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const [modal, setModal] = useState(false)
    const id = props.match.params.id

    const [etapaEdit, setEtapaEdit] = useState<IGrupoAtendimentoFunilEtapa>(); 

    const [etapas, setEtapas] = useState <Array<IGrupoAtendimentoFunilEtapa>>([]);
    const [funil, setFunil] = useState <IGrupoAtendimentoFunil>();
    const [ordem, setOrdem] = useState([1])

    const inputRef = useRef<any>();
    const [message, setMessage] = useState<IMessage>({
        message: '',
        type: 'texto',
        url: '',
        date: new Date(),
        status: 'pendente',
    });
    const ref = useRef<any>(null);

    useEffect(() => {
        getFunilByGrupo()
        setLoading(false)
        setPageName('Configurar Funil de Atendimento')
    }, [])

    async function getFunilByGrupo(){
        try{
            let funilResponse : any = await apiTropa.get('/grupo-funil-grupo/'+id)
            setFunil(funilResponse.data.data)

            let funilEtapasResponse : any = await apiTropa.get('/etapa-funil/'+funilResponse.data.data.id_grupo_atendimento_funil)
            setEtapas(funilEtapasResponse.data.data)

            await setOrdens()
        }catch(err: any){
            if(err.response){
                addDanger("Atenção", String(err.response.data.message))
            }else{
                addDanger("Atenção", String(err))
            }
            setTimeout((function(){
              //  history.push('/grupo-atendimento/'+props.match.params.id)
            }),2000)
        }
    }

    async function setOrdens(){
        let ordemRefresh = []
        
        if(etapas.length > 0){
            for await (let etapa of etapas) {
                if(etapa){
                    ordemRefresh.push(etapa.ordem)
                }
            }
        }

        ordemRefresh.sort()

        if(ordemRefresh.length > 0){
            ordemRefresh.push( ordemRefresh[ordemRefresh.length -1] + 1)
        }else{
            ordemRefresh.push(1)
        }
        
        setOrdem(ordemRefresh)
    }

    async function newEtapa(index:any){
        setModal(true)
        getFunilByGrupo()
    
        let etp : IGrupoAtendimentoFunilEtapa = {}

        if(index === 'nova'){
            etp.id_grupo_atendimento_funil_etapa = null
            etp.status = "ativo"
            etp.id_grupo_atendimento_funil = funil?.id_grupo_atendimento_funil
            etp.ordem = await getMaiorOrdem() + 1
            etp.etapa = "Nova Etapa"
            await setOrdens()
        }else{
            etp.status = etapas[index].status
            etp.id_grupo_atendimento_funil = funil?.id_grupo_atendimento_funil
            etp.id_grupo_atendimento_funil_etapa = etapas[index].id_grupo_atendimento_funil_etapa
            etp.ordem = etapas[index].ordem
            etp.etapa = etapas[index].etapa
            etp.modificado = etapas[index].modificado
            etp.criado = etapas[index].criado

            if(ordem.length > 1){
                delete ordem[ordem.length - 1]
            }
            setOrdem(ordem)
        }
        setEtapaEdit({...etp})
    }

    async function getMaiorOrdem() {
        let ordemReturn = 1;

        if(etapas?.length > 0){
            for await (let etapa of etapas) {
                if(etapa.ordem && etapa.ordem > ordemReturn){
                    ordemReturn = etapa.ordem + 1
                }
            }
        }
        return ordemReturn 

    }

    async function deleteEtapa(etapa:IGrupoAtendimentoFunilEtapa){
        try{
            let funilEtapasResponse : any = await apiTropa.delete('/etapa/'+etapa.id_grupo_atendimento_funil_etapa)
            getFunilByGrupo()

            addSuccess("Sucesso", String(funilEtapasResponse.data.message))

        }catch(err: any){
            if(err.response){
                addDanger("Atenção", String(err.response.data.message))
            }else{
                addDanger("Atenção", String(err))
            }
            setTimeout((function(){
              //  history.push('/grupo-atendimento/'+props.match.params.id)
            }),2000)
        }
    }

    async function reorderEtapa(){
        try{

            for await (let etapa of etapas) {
                if (etapa.ordem >= etapaEdit?.ordem && etapaEdit?.id_grupo_atendimento_funil_etapa !== etapa.id_grupo_atendimento_funil_etapa){
                    //etapa.ordem = etapa.ordem + 1

                    console.log()
                    //let etapaResponse : any = await apiTropa.put('/etapa/'+etapa.id_grupo_atendimento_funil_etapa,etapa)
                }
            }

        }catch(err: any){
            if(err.response){
                addDanger("Atenção", String(err.response.data.message))
            }else{
                addDanger("Atenção", String(err))
            }
            setTimeout((function(){
            }),2000)
        }

    }

    async function changeEtapaEdit(value:any, key:any) {
        if(etapaEdit){
            etapaEdit[key  as keyof IGrupoAtendimentoFunilEtapa] = value;
        }
    
        setEtapaEdit({...etapaEdit})
    }

    async function saveAll(){
        try{
            if(etapaEdit?.id_grupo_atendimento_funil_etapa){
                let etapaResponse : any = await apiTropa.put('/etapa/'+etapaEdit.id_grupo_atendimento_funil_etapa,etapaEdit)
                addSuccess("Sucesso", String(etapaResponse.data.message))
            }else{
                let etapaResponse : any = await apiTropa.post('/etapa', etapaEdit)
                addSuccess("Sucesso", String(etapaResponse.data.message))
            }

            await reorderEtapa()

            setModal(false)
            getFunilByGrupo()
        }catch(err: any){
            if(err.response){
                addDanger("Atenção", String(err.response.data.message))
            }else{
                addDanger("Atenção", String(err))
            }
            setTimeout((function(){
              //  history.push('/grupo-atendimento/'+props.match.params.id)
            }),2000)
        }
    }

    

    return (
        <>
            <Container>
                <Header>
                    <BackPage />
                </Header>

                {loading === true ? 
                    <Skeleton style={{marginTop: '15px'}}
                        count={7}
                        className="skeletor"
                        height="80px"
                        borderRadius="16px"
                        baseColor="#262655"
                        highlightColor="#3e3b77"
                    />
                    
                :
                    <>
                        <Card style={{marginTop: '15px'}}>
                            <FunilAtendimentoComponent>
                                {etapas ? 
                                    etapas.map((row,key) => (
            
                                        <li style={{zIndex:etapas.length - key}}>
                                            <span>{row.etapa}</span>
                                            <ButtonLinear
                                                    className='funil-delete'
                                                    Icon={IconConfig}
                                                    onClick={() =>
                                                        newEtapa(key)
                                                    }
                                                />
                                            <ButtonLinear
                                                    className='funil-delete'
                                                    Icon={IconDelete}
                                                    onClick={() =>
                                                        deleteEtapa(row)
                                                    }
                                                />
                                        </li>
                                    ))
                                : 
                                ''}
                                <li className='new' style={{zIndex:0}} onClick={() => newEtapa('nova')} >
                                    <span>Adicionar</span>
                                    <ButtonLinear
                                            className='funil-delete'
                                            Icon={IconPlus}
                                            type={'button'}
              
                                        />
                                </li>
                            </FunilAtendimentoComponent>
                        </Card>
                           
                    </>
                }
            </Container>
        
            <ModalDefault id="overlayModal" onClose={() => setModal(!modal)} openModal={modal}>
                <Field>
                    <Label>Etapa</Label>
                    <InputDefault value={etapaEdit?.etapa} 
                    onChangeText={(e:any) => changeEtapaEdit(e,'etapa')} />
                </Field>
                <Field style={{marginTop:'10px'}}>
                    <Label>Ordem</Label>
                    <SelectDefault value={etapaEdit?.ordem}  onChangeText={(e:any) => changeEtapaEdit(e,'ordem')}>
                        {ordem ? 
                            ordem.map((row,key) => (
                                <option value={key + 1}>{key + 1}</option>
                            ))
                        : <option value={1}>{1}</option>}    
                    </SelectDefault>
                </Field>

                <FieldGroup style={{ marginTop: '32px' }}>
                    <ButtonLinear onClick={() => setModal(false)}>
                        Cancelar
                    </ButtonLinear>
                    <ButtonLinear className="success" onClick={() => saveAll()}>
                        Salvar
                    </ButtonLinear>
                </FieldGroup>
            </ModalDefault>
        </>
    )
}
