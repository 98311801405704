
import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    justify-content: space-between;

    &.mt-1 {
        margin-top: 5px;
    }
    &.mt-2 {
        margin-top: 10px;
    }
    &.mt-3 {
        margin-top: 15px;
    }
    &.mt-4 {
        margin-top: 20px;
    }
    &.mt-5 {
        margin-top: 25px;
    }

    &.mb-1 {
        margin-bottom: 5px;
    }
    &.mb-2 {
        margin-bottom: 10px;
    }
    &.mb-3 {
        margin-bottom: 15px;
    }
    &.mb-4 {
        margin-bottom: 20px;
    }
    &.mb-5 {
        margin-bottom: 25px;
    }

    &.ml-1 {
        margin-left: 5px;
    }
    &.ml-2 {
        margin-left: 10px;
    }
    &.ml-3 {
        margin-left: 15px;
    }
    &.ml-4 {
        margin-left: 20px;
    }
    &.ml-5 {
        margin-left: 25px;
    }

    &.mr-1 {
        margin-right: 5px;
    }
    &.mr-2 {
        margin-right: 10px;
    }
    &.mr-3 {
        margin-right: 15px;
    }
    &.mr-4 {
        margin-right: 20px;
    }
    &.mr-5 {
        margin-right: 25px;
    }
    @media (max-width: 900px) {
        flex-direction: column;
        .mt-0,
        .mt-5 {
            gap: 10px;
        }
    }
    @media (max-width: 425px) {
        flex-direction: column;
        gap: 15px;
    }
    .column {
        margin: 0px 10px;
        @media (max-width: 900px) {
            width: 100% !important;
            margin: 0;
        }
        &:first-child {
            margin-left: 0px;
        }
        &:last-child {
            margin-right: 0px;
        }

        &.mt-1 {
            margin-top: 5px;
        }
        &.mt-2 {
            margin-top: 10px;
        }
        &.mt-3 {
            margin-top: 15px;
        }
        &.mt-4 {
            margin-top: 20px;
        }
        &.mt-5 {
            margin-top: 25px;
        }

        &.mb-1 {
            margin-bottom: 5px;
        }
        &.mb-2 {
            margin-bottom: 10px;
        }
        &.mb-3 {
            margin-bottom: 15px;
        }
        &.mb-4 {
            margin-bottom: 20px;
        }
        &.mb-5 {
            margin-bottom: 25px;
        }

        &.ml-1 {
            margin-left: 5px;
        }
        &.ml-2 {
            margin-left: 10px;
        }
        &.ml-3 {
            margin-left: 15px;
        }
        &.ml-4 {
            margin-left: 20px;
        }
        &.ml-5 {
            margin-left: 25px;
        }

        &.mr-1 {
            margin-right: 5px;
        }
        &.mr-2 {
            margin-right: 10px;
        }
        &.mr-3 {
            margin-right: 15px;
        }
        &.mr-4 {
            margin-right: 20px;
        }
        &.mr-5 {
            margin-right: 25px;
        }

        &.c-1 {
            width: 8.33%;
        }
        &.c-2 {
            width: calc(8.33%* 2);
        }
        &.c-3 {
            width: calc(8.33%* 3);
        }
        &.c-4 {
            width: calc(8.33%* 4);
        }
        &.c-5 {
            width: calc(8.33%* 5);
        }
        &.c-6 {
            width: calc(8.33%* 6);
        }
        &.c-7 {
            width: calc(8.33%* 7);
        }
        &.c-8 {
            width: calc(8.33%* 8);
        }
        &.c-9 {
            width: calc(8.33%* 9);
        }
        &.c-10 {
            width: calc(8.33%* 10);
        }
        &.c-11 {
            width: calc(8.33%* 11);
        }
        &.c-12 {
            width: calc(8.33%* 12);
        }
    }
`