import { IconAudio, IconFile, IconImage, IconVideo } from '../../icons';

import { Container, ButtonImage, ButtonVideo } from './styles';

interface FileTypes {
    audio?: number,
    arquivo?: number,
    imagem?: number,
    video?: number 
}

interface FileProps {
    active: boolean;
    handleUpload: (e: any, type: any) => void;
    inputRefType: any;
    fileTypes?: FileTypes;
}

export default function CardFiles({
    fileTypes,
    active,
    handleUpload,
    inputRefType,
}: FileProps) {
    return (
        <Container active={active}>
           {fileTypes?.arquivo === 1 && (
                <ButtonVideo>
                    <input
                        type="file"
                        name="arquivo"
                        onChange={(e) => handleUpload(e, 'arquivo')}
                        ref={inputRefType}
                    />
                    <IconFile color='#fff' />
                </ButtonVideo>
           )}

           {fileTypes?.video === 1 && (
                <ButtonVideo>
                    <input
                        type="file"
                        name="video"
                        onChange={(e) => handleUpload(e, 'video')}
                        ref={inputRefType}
                    />
                    <IconVideo color="#fff" />
                </ButtonVideo>
           )}

           {fileTypes?.imagem === 1 && (
                <ButtonImage>
                    <input
                        type="file"
                        name="image"
                        onChange={(e) => handleUpload(e, 'imagem')}
                        ref={inputRefType}
                    />
                    <IconImage color="#fff" />
                </ButtonImage>
           )}

            {fileTypes?.audio === 1 && (
                <ButtonImage
                    style={{backgroundColor: '#CB3D9A'}}
                >
                    <input
                        type="file"
                        name="audio"
                        onChange={(e) => handleUpload(e, 'audio')}
                        ref={inputRefType}
                    />
                    <IconAudio color="#fff" />
                </ButtonImage>
            )}

        </Container>
    );
}
