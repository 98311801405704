import React, { useEffect, useState } from 'react';
import { IconArrow } from '../../icons';

import { Container } from './styles';

interface InputProps extends React.HTMLAttributes<HTMLInputElement> {
    placeholder?: string;
    value: any;
    onChangeText: (value: string) => void;
    search?: boolean;
    style?: any;
}

const SelectDefault: React.FC<InputProps> = ({
    placeholder,
    value,
    onChangeText,
    children,
    style,
}) => {
    const [valueInput, setValueInput] = useState<string>(value);

    useEffect(() => {
        setValueInput(value);
    }, [value]);

    function handleOnChange(e: React.FormEvent<HTMLSelectElement>) {
        setValueInput(e.currentTarget.value);
        onChangeText(e.currentTarget.value);
    }

    return (
        <Container className="form-children-default select">
            <select value={valueInput} onChange={handleOnChange}>
                {placeholder && (
                    <option value="" disabled={false} selected={true}>
                        {placeholder}
                    </option>
                )}
                {children}
            </select>
            <IconArrow />
        </Container>
    );
};

export default SelectDefault;
