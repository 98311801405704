/* eslint-disable no-throw-literal */
import { useEffect, useState, useRef } from 'react';
import { useRoutes } from '../../contexts/RoutesContext';
import { useMessage } from '../../contexts/MessagesContext';
import { Container, Main, EditButton } from './styles';
import apiEyou from '../../services/apiEyou';
import { UserEyou } from '../../types/UserEyou';
import { Perfil } from '../../types/Perfil';
import { PerfilTropa } from '../../types/PerfilTropa';
import Column from '../../components/grid/Columns';
import Row from '../../components/grid/Row';
import CardContainer from '../../components/layout/containers/CardContainer';
import ButtonLinear from '../../components/form/ButtonLinear';
import SelectDefault from '../../components/form/SelectDefault';
import InputDefault from '../../components/form/InputDefault';
import { Link } from 'react-router-dom';
import apiTropa from '../../services/apiTropa';
import { useHistory } from "react-router-dom";
import InputSwitchDefault from '../../components/form/InputSwitchDefault';

interface Props {
	match: any;
}

const UsuarioNovo: React.FC<Props> = (props) => {
	let history = useHistory();
	const { setPageName } = useRoutes();
	const { addDanger, addSuccess } = useMessage();

	//userEyou que vem com os dados do eyou
	const [userEyou, setUserEyou] = useState<UserEyou>({
	} as UserEyou);

	//userEyou que vem com os dados do omni tropa
	const [userTropa, setUserTropa] = useState<PerfilTropa>({audio:1,video:1,imagem:1,ativo:'sim',arquivo:1,alterar_dados:1} as PerfilTropa)

	//lista de perfis de usuario
	const [perfilLista, setPerfilLista] = useState<Perfil[]>([]);



	const inputFile:any = useRef(null);
	const onClickFile = () => { inputFile.current.click() };
    
    async function onChangeFile(e:any){
        try
        {
            const formData = new FormData();
            formData.append("files", e.target.files[0], e.target.files[0].name);
			formData.append("idApp", '2');
			formData.append("destination", '1');
			formData.append("share",'1');

			let tokenInicial = window.sessionStorage.getItem('tokenInicial');

            let response : any = await apiEyou.post(
				'apiDrive', 
				formData,
				{
					headers: {
						Authorization: 'Bearer ' + tokenInicial,
					},
				}
			);

			if(response.data.data.error === true){
				throw 'Não foi possivel salvar a foto';
			}

			let input:any = userTropa
			input.foto = response.data.data.fileUrl
			setUserTropa({...input})
        
        } catch(e){
            addDanger('Ocorreu um erro', 'Não foi possível salvar a foto');
        }
    }

	useEffect(() => {
		setPageName('Usuário');

		if(props.match.params.id === '0'){
			setPageName('Novo Usuario')
		}

		getPerfilLista();
	}, [setPageName]);

	
	async function getPerfilLista(){
		try{
			let tokenFinal : any = window.sessionStorage.getItem('token');
		
			let perfilResult: any = await apiTropa.get(
				'perfil',
				{
					headers: {
						Authorization: 'Bearer ' + tokenFinal,
					},
				}
			);

			setPerfilLista(perfilResult.data.data)
		}catch(e){
			addDanger('Ocorreu um erro', 'Não foi possível recuperar os perfis');
		}
	}

	async function alterarStatusDoUsuario(value:boolean){
        try {
			let user = userTropa 
			if(value){
				user.ativo = "sim"
			}else{
				user.ativo = "nao"
			} 

			setUserTropa({...user})
            
        } catch (err:any) {
            addDanger('Atenção', String(err))
        }
    }

	async function alterarPermissaoDoUsuario(value:boolean,key:string){
        try {
            let user = userTropa 

			if(value){
				user[key as keyof PerfilTropa] = 1
			}else{
				user[key as keyof PerfilTropa] = 0
			} 

			setUserTropa({...user})
        } catch (err:any) {
            addDanger('Atenção', String(err))
        }
    }


	async function save(){
		try{

			//validar perfil tropa
			if(!userTropa.id_perfil){
				throw 'Perfil é obrigatório'
			}

			if(!userTropa.apelido){
				throw 'Apelido é obrigatório'
			}

			if(!userTropa.nome){
				throw 'Nome é obrigatório'
			}

			if(!userEyou.email){
				throw 'E-mail é obrigatório'
			}

			if(!userEyou.login){
				throw 'Login é obrigatório'
			}

			let tokenInicial = window.sessionStorage.getItem('tokenInicial');
			let tokenFinal : any = window.sessionStorage.getItem('token');


			let userSave : UserEyou = userEyou;
			//insert usuario eyou
			let insertUser = await apiEyou.post(
				'apiUsuario',
				{
					type: 'set',
					function: 'insertNewUser',
					data: userSave,
				},
				{
					headers: {
						Authorization: 'Bearer ' + tokenInicial,
					},
				}
			);

			console.log(insertUser)

			 // recupera usuario criado 
			let usuarioSelecionado: any = await apiEyou.post(
				'apiUsuario',
				{
					type: 'get',
					function: 'listAllUsers',
					data: {
						filter: true,
						login: insertUser.data.data.login,
						idUser:insertUser.data.data.idUser
					},
				},
				{
					headers: {
						Authorization: 'Bearer ' + tokenInicial,
					},
				}
			);
			
			//atualiza permissoes
			let permissoes : any = await apiEyou.post('apiUsuario',{
				type: "set",
				function: "updateUserPermission",
				data: {
					idUser: usuarioSelecionado.data.data[0].idUser,
					apiDados: 1,
					apiUsuario: 0,
					apiTimeline: 0,
					apiBlacklist: 0,
					apiCentroCusto: 0,
					apiMsgPre: 0,
					apiEnvAvulso: 0,
					apiEnvLista: 0,
					apiEnvLote: 0,
					apiEnvHttp: 0,
					apiShortOne: 0,
					apiShortTwo: 0,
					apiShortFlash: 0,
					apiShortConc: 0,
					apiShortener: 0,
					apiLong: 0,
					apiRelEnv: 0,
					apiRelResp: 0,
					apiRelAnalitico: 0,
					apiSensiveData: 0,
					apiTemplate: 0,
					apiVoz: 0
				}
			},
			{
				headers: {
					Authorization: 'Bearer ' + tokenInicial,
				},
			}) 
			

			//decripta o id_login eyou
			let tokenDecryptUser : any = await apiEyou.post('/apiOmni', {
				type: "get",
				function: "aesDecrypt",
				data: {
					toDecrypt: JSON.stringify(usuarioSelecionado.data.data[0].idUser)
				}
			}, {
				headers: {
					Authorization: 'Bearer ' + tokenInicial,
				},
			})

			//insere no state do perfil tropa
			userTropa.id_login = tokenDecryptUser.data.data.decrypt;
			setUserTropa(userTropa);

			//insere no banco tropa
			await apiTropa.post(
				'perfil/usuario',
				userTropa,
				{
					headers: {
						Authorization: 'Bearer ' + tokenFinal,
					},
				}
			)
			

			addSuccess('Usuario Criado com sucesso','Crie uma nova senha para o usuario')
			setTimeout(() => {
                history.push('/usuarios/editar/'+userEyou.login)
            }, 1000);
			

		}catch(e : any){
			if(e.response){
				let errorData : any = e.response.data
				addDanger('Ocorreu um erro',errorData.message)
			}else if(e.message){
				addDanger('Ocorreu um erro', e.message)
			}else{
				addDanger('Ocorreu um erro', e)
			}
		}
	}

	const setDadosEyou = (key: string, value:any) => {
		let input:any = userEyou
		input[key] = value

		if(key === 'name'){
			setDadosTropa('nome',value)
		}

		setUserEyou({...input})
	}

	const setDadosTropa = (key: string, value:any) => {
		let input : any = userTropa
		input[key] = value
		setUserTropa({...input})  
	}


	return (
		<Container>
			<Row>
				<Column width='2'>
					<EditButton>
						<ButtonLinear className="success" onClick={() => save()}>
							Salvar
						</ButtonLinear>
					</EditButton>
				</Column>
				<Column width='2'>
					<EditButton>
						<Link to='/usuarios'>
							<ButtonLinear className="transparent">
								Cancelar
							</ButtonLinear>
						</Link>
					</EditButton>
				</Column>
				<Column width='8'>
				</Column>
			</Row>
			<Main>
				<Row>
					<Column width="6">
						<CardContainer>
							<Row>
							<Column width='8'>
								<p className="title-card">Dados Pessoais</p>
							</Column>
							<Column width='3'>
								<InputSwitchDefault
									options={[{label: "Usuario ativo", value: "1"}]}
									value={[userTropa.ativo === "nao" ? "0" : "1"]}
									onChange={(value) => alterarStatusDoUsuario(!value.length ? false : true)}
								/>
							</Column>
							</Row>
							<Row mb="3">
								<input type="file"
									className="display-none" 
									ref={inputFile} 
									name="imagem"  
									onChange={(e) => onChangeFile(e)}
									style={{
										display: `none`,
									}}  
									/> 
								<div
									onClick={onClickFile}
									className="image"
									style={{
										backgroundImage: userTropa && userTropa.foto ? 'url('+userTropa.foto+')' : 'url()',
										backgroundSize: 'cover'
									}}
								>
									<svg
										width="114"
										height="70"
										viewBox="0 0 114 70"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M112 2C112 14.6736 108.351 27.0792 101.489 37.7345C94.6272 48.3898 84.8425 56.844 73.3043 62.0872C61.7661 67.3303 48.9623 69.1405 36.4229 67.3015C23.8834 65.4624 12.1386 60.052 2.5918 51.7166"
											stroke="#34D5FA"
											stroke-width="4"
											stroke-linecap="round"
										/>
									</svg>
								</div>
							</Row>


							<Row mt="5">
								<Column width="6">
									<InputDefault
										onChangeText={(value) => setDadosEyou('name', value)}
										placeholder={'Nome'}
										value={userEyou.name}
								
									/>
								</Column>
								<Column width="6">
									<InputDefault
										onChangeText={(value) => setDadosTropa('apelido', value)}
										placeholder={'Apelido'}
										value={userTropa.apelido}
									/>
								</Column>
							</Row>
							<Row mt="5">
								<Column width="6">
									<InputDefault
										onChangeText={(value) => setDadosEyou('phone', value)}
										placeholder={'Telefone'}
										value={userEyou.phone}
										mask={'(99) 9999-9999'}
									/>
								</Column>
								<Column width="6">
									<InputDefault
										onChangeText={(value) => setDadosEyou('mobilePhone', value)}
										placeholder={'Celular'}
										mask={'(99) 9999-99999'}
										value={userEyou.mobilePhone}
									/>
								</Column>
							</Row>

							<Row mt="5">
								<Column width="6">
									<InputDefault
										onChangeText={(value) => setDadosEyou('email', value)}
										placeholder={'E-mail'}
										value={userEyou.email}
									/>
								</Column>

								<Column width="6">
									<InputDefault
										onChangeText={(value) => (setDadosEyou('login',value))}
										placeholder={'Login'}
										value={userEyou.login}
									/>
								</Column>

							</Row>
						</CardContainer>
					</Column>

					<Column width="6">
						<CardContainer>
							<p className="title-card">Perfil</p>
							<Row mt="0">
								<Column width="12">
									<SelectDefault
										value={userTropa && userTropa.id_perfil ? userTropa.id_perfil: ''}
										placeholder="Perfil do Usuario"
										onChangeText={(value) => setDadosTropa('id_perfil',value)}
									>
										{
											perfilLista.length > 0 ? perfilLista.map((row, key) => 
												<option value={row.id_perfil}  >{row.perfil}</option>
											)
											: ''
										}
									
									</SelectDefault>
								</Column>
							</Row>

							{	// verifica se é operador
								perfilLista.length > 0 ? perfilLista.map((row, key) => 
									userTropa && userTropa.id_perfil == row.id_perfil && row.perfil === 'Operador' ?
										<>
											<Row mt="3">
												<Column width="12">
													<InputDefault
														type='number'
														onChangeText={(value) => (setDadosTropa('limite_atendimentos',value))}
														placeholder={'Limite de Atendimentos'}
														value={userTropa.limite_atendimentos}
													/>
												</Column>
											</Row>

											<Row>
												<Column width='12'>
													<InputSwitchDefault
														options={[{label: "Enviar imagens", value: 1}]}
														value={[userTropa.imagem !== 1 ? 0 : 1]}
														onChange={(value) => alterarPermissaoDoUsuario(!value.length ? false : true, 'imagem')}
													/>
												</Column>
												</Row>
												<Row>
												<Column width='12'>
													<InputSwitchDefault
														options={[{label: "Enviar audio", value: 1}]}
														value={[userTropa.audio !== 1 ? 0 : 1]}
														onChange={(value) => alterarPermissaoDoUsuario(!value.length ? false : true, 'audio')}
													/>
												</Column>
											</Row>
											<Row>
												<Column width='12'>
													<InputSwitchDefault
														options={[{label: "Enviar video", value: 1}]}
														value={[userTropa.video !== 1 ? 0 : 1]}
														onChange={(value) => alterarPermissaoDoUsuario(!value.length ? false : true, 'video')}
													/>
												</Column>
											</Row>

											<Row>
												<Column width='12'>
													<InputSwitchDefault
														options={[{label: "Enviar arquivo", value: 1}]}
														value={[userTropa.arquivo !== 1 ? 0 : 1]}
														onChange={(value) => alterarPermissaoDoUsuario(!value.length ? false : true, 'arquivo')}
													/>
												</Column>
											</Row>

											<Row>
												<Column width='12'>
													<InputSwitchDefault
														options={[{label: "Alterar dados de atendimentos finalizados", value: 1}]}
														value={[userTropa.alterar_dados !== 1 ? 0 : 1]}
														onChange={(value) => alterarPermissaoDoUsuario(!value.length ? false : true, 'alterar_dados')}
													/>
												</Column>
											</Row>
										</>
									:''
									
								):''
							}		
							
			

							
						</CardContainer>
					</Column>
				</Row>
			</Main>
		</Container>
	);
};

export default UsuarioNovo;
