import styled from 'styled-components';

interface ChatProps {
  backgroundColor?: string;
  selectedFile?: boolean;
  heightFunil?: any;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  .finishInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    background: #ffcccf;
    margin-bottom: -5px;
    border-radius: 5px;
    font-size: 13px;
    color: rgba(0,0,0,0.5);
    svg {
      font-size: 30px;
      margin-right: 20px;
      margin-left: 10px;
      animation: fadeInLeft .5s linear;
    }
    ul {
      list-style: none;
      margin: 0px;
      padding: 0px;
      li {
        margin: 10px 0px;
      }
    }
    h3 {
      margin-bottom: 10px;
      font-weight: 200;
    }
  }
  #modalHistorico {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0,0,0,0.5);
    left: 0px;
    top: 0px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .history-container {
      width: 600px;
      margin: 0px auto;
      max-width: 90%;
      .header {
        display: flex;
        color: #FFF;
        height: 80px;
        align-items: center;
        justify-content: space-between;
        button {
          border: none;
          background: none;
        }
      }
    }
    .messages {
      height: calc(100vh - 80px);
      overflow: auto; 
      width: 100%;
      backdrop-filter: blur(30px);
    }
    .messages .history-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 40px 0px 20px 0px;
      box-sizing: border-box;
    }
  }
`;

export const HeaderChat = styled.div`
  display: flex;
  flex-direction: column;
  
  width: 100%;

  padding-bottom: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
`;

export const NavBarChat = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  h1 {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    color: ${props => props.theme.colors.white};

    span {
      display: flex;
      align-items: center;

      font-size: 14px;
      font-weight: 500;
      color: ${props => props.theme.colors.white};
      margin-left: 24px;

      position: relative;
      
      &::before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        
        background-color: #38FFE7;
        
        position: absolute;
        left: -12px;
        bottom: 5px;
      }
    }
  }

`;

export const NavButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  
`;

export const ButtonIcons = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: none;
  padding: 3px;
  transition: filter 0.3s;

  &:hover {
    filter: brightness(0.8);
    transition: filter 0.3s;
  }
`;

export const InfoChat = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  width: 100%;
  height: 65px;
`;

export const InfoChatLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const Subject = styled.div`
  display: flex;
  align-items: center;
  h2 {
    font-size: 16px;
    font-weight: 500;
    color: ${props => props.theme.colors.white};
  }

  span {
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.85);
    margin-left: 8px;
  }

  button {
    margin-left: 10px;

    svg + svg {
      margin-left: 10px;
    }
  }
`;

export const OriginUrl = styled.div`
  display: flex;
  align-items: center;
  h2 {
    font-size: 16px;
    font-weight: 500;
    color: ${props => props.theme.colors.white};
  }

  span {
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.85);
    margin-left: 8px;
  }

  button {
    margin-left: 10px;
  }
`;

export const ChatLoad = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: ${({theme}) => theme.colors.bgChatOp};
  display: flex;
  align-items: center;
  justify-content: center;
  animation-duration: .2s;
  svg {
    width: 100px;
    height: 100px;
    color: ${(props) => props.theme.colors.skyblue};
  }
`

export const MainChat = styled.div<ChatProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    background: ${({theme}) => theme.colors.bgChatOp};
    height: calc(100vh - 350px);
    padding: ${(props) => props.heightFunil + 52 + 'px'} 10px 0 10px;
    box-sizing: border-box;
    > div {
        > div {
            overflow-x: hidden;
            /* scroll-behavior: smooth; */
            display: flex;
            flex-direction: column;
            gap: 24px;
            padding: 20px 0px;
        }
    }

    .buttonDown {
        position: absolute;
        bottom: 0px;
        right: 0px;
        opacity: 0;
        margin: 20px;
        display: grid;
        place-items: center;
        width: 48px;
        height: 48px;
        border-radius: 10px;
        background: ${(props) => props.backgroundColor ? props.backgroundColor : '#34D5FA'};
        box-shadow: 0px 10px 15px #00000008;
        border: none;
        z-index: 10;
        visibility: hidden;
        svg {
            path {
                transform: rotate(-90deg);
                transform-origin: 50% 50%;
                fill: #fff;
            }
        }
        transition: opacity 0.3s;

        &.buttonDowActive {
            opacity: 1;
            visibility: visible;
        }
    }
`;

export const FooterChat = styled.div<ChatProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    width: 100%;
    background: ${({theme}) => theme.colors.bgChatOp};
    padding: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .buttonChat {
        display: grid;
        place-items: center;
        flex-shrink: 0;

        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: none;
        background: ${(props) => props.backgroundColor ? props.backgroundColor : '#34D5FA'};
        transition: filter 0.3s;

        &:hover {
            transition: 0.3s;
            filter: brightness(0.9);
        }
    }

    .formMessagePre {
      width: 100%;
    }

    .containerChatInput {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        position: relative;
        width: 100%;
        height: 48px;
        padding: 4px;

        background: #eaeef3;
        border-radius: 10px;

        .buttonEmojiChat {
            display: grid;
            place-items: center;
            flex-shrink: 0;

            width: 48px;
            height: 48px;
            border-radius: 50%;
            border: none;
            background: transparent;
            position: relative;

            svg {
                path {
                    fill: ${(props) =>
                        props.backgroundColor
                            ? props.backgroundColor
                            : '#34D5FA'};
                }
            }
        }

        #inputChat {
            display: flex;
            align-items: center;

            width: 100%;
            padding: 8px 0px;
            border: none;
            outline: none;
            background: transparent;
            resize: none;
            overflow-y: scroll;

            overflow-y: scroll;
            ::-webkit-scrollbar {
                display: none;
            }
        }

        .sectionOptionText {
          /* position: absolute;
          top: -60px;
          left: 16px; */

          /* display: flex;
          align-items: center;
          gap: 4px;
          padding: 10px 8px;
          border-radius: 4px;
          background-color: #fff; */

          border-radius: 5px 5px 0px 0px;
          background: #f1f1f1;
          height: 60px;
          position: absolute;
          bottom: 42px;
          border-bottom: 20px solid #eaeef3;
          left: 0px;
          padding-left: 16px;
          display: flex;
          align-items: center;
          gap: 12px;
          width: 100%;
        }
    }

    .buttonAudioChat {
        display: grid;
        place-items: center;
        flex-shrink: 0;

        width: 48px;
        height: 48px;
        border-radius: 10px;
        border: none;
        background: ${(props) =>
            props.backgroundColor ? props.backgroundColor : '#34D5FA'};
        transition: filter 0.3s;

        svg {
            path {
                fill: #fff;
            }
        }
        &:hover {
            transition: 0.3s;
            filter: brightness(0.9);
        }
    }
`;