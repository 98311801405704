import styled from 'styled-components';

interface PopUpProps {
    popUpAttendence?: boolean;
    status?: string;
}

interface ContainerProps {
    tab: string
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    transform: ${(props) => props.tab === 'chat' ? 'translate(0%, 0px);' : 'translate(-100%, 0px);'}
    transition: all .5s;
    > div {
        width: 100%;
        min-width: 100%;
    }
    .input {
        input {
            color: #5A646E;
        }
    }
    .imagePreview {
        max-height: 180px !important;
        width: auto !important;
        max-width: 90% !important;
    }
    .descriptionFilePreview {
        text-align: center;
    }
`;

export const ContentUsers = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 24px 24px 24px;
    height: 100%;
    max-height: calc(450px - 76px);
    box-sizing: border-box;
    overflow: auto;
    h2 {
        color: #8A99A8;
        font-size: 14px;
        font-weight: normal;
    }
`;

export const CardPerson = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const PersonImage = styled.div`
    display: grid;
    place-items: center;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    border: 1px solid #ffffff;
    background: #CCC;
    background-size: cover;
`;

export const PersonInfo = styled.div<PopUpProps>`
    display: flex;
    flex-direction: column;
    margin-left: 16px;

    p {
        font-size: 14px;
        color: #5A646E;
        display: flex;
        align-items: center;
        font-weight: normal;

        .ball {
            width: 8px;
            height: 8px;
            margin-left: 4px;
            background: ${(props) =>
                props.status === 'online'
                    ? '#28b3a2'
                    : '#fff' && props.status === 'Pausa'
                    ? '#CB3D9A'
                    : '#fff' && props.status === 'Aguardando fila'
                    ? '#F5A623'
                    : '#fff' && props.status === 'Operadores Online'
                    ? '#7B61FF'
                    : '#fff' && props.status === 'Abandono'
                    ? '#FF868B'
                    : '#fff'};
            border-radius: 50%;
        }
    }
`;

export const ContainerViewsMessages = styled.div`
    background: ${(props) => props.theme.colors.skyblue};
    color: #FFF;
    padding: 5px 7px;
    border-radius: 100px;
    font-size: 9px;
    margin-left: 10px;
`

//aba
export const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
export const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
`