import { useEffect, useState, useRef } from 'react';
import ButtonLinear from '../../../components/form/ButtonLinear';
import Datetime from '../../../Helper/Datetime';
import moment from 'moment';
import BackPage from '../../../components/layout/UIElements/BackPage';
import { useMessage } from '../../../contexts/MessagesContext';
import { useRoutes } from '../../../contexts/RoutesContext';
import apiTropa from '../../../services/apiTropa';
import Skeleton from 'react-loading-skeleton';
import { Container, Header, TagsComponent } from './styles';
import { useHistory } from 'react-router-dom';
import { Label } from 'recharts';
import InputDefault from '../../../components/form/InputDefault';
import { IconConfig, IconDelete, IconPlus } from '../../../components/icons';
import Field from '../../../components/layout/containers/Field';
import FieldGroup from '../../../components/layout/containers/FieldGroup';
import ModalDefault from '../../../components/ModalDefault';
import { Card } from '../../../components/UserList/styles';

interface IMessage {
    message: string;
    type: 'texto' | 'audio' | 'video' | 'imagem' | 'arquivo';
    url?: string;
    date: Date;
    status: 'pendente' | 'enviado' | 'confirmado';
}

interface IGrupoAtendimentoTag {
    id_grupo_atendimento_tag?: string|any;
    id_grupo_atendimento?: string;
    tag?: string;
    status?:string;
    modificado?:string;
    criado?:string;
}

export default function Tags(props: any) {
    const { setPageName } = useRoutes()
    const { addConfirm, addDanger, addSuccess } = useMessage()
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const [modal, setModal] = useState(false)
    const id = props.match.params.id

    const [tagEdit, setTagEdit] = useState<IGrupoAtendimentoTag>();
    const [tags, setTags] = useState <Array<IGrupoAtendimentoTag>>([]); 

    const inputRef = useRef<any>();
    const [message, setMessage] = useState<IMessage>({
        message: '',
        type: 'texto',
        url: '',
        date: new Date(),
        status: 'pendente',
    });
    const ref = useRef<any>(null);

    useEffect(() => {
        getTagsByGrupo()
        setLoading(false)
        setPageName('Configurar Tags')
    }, [])

    async function getTagsByGrupo(){
        try{

            let tagsResponse : any = await apiTropa.get('/tag-atendimento-grupo/'+id)
            setTags(tagsResponse.data.data)

        }catch(err: any){
            if(err.response){
                addDanger("Atenção", String(err.response.data.message))
            }else{
                addDanger("Atenção", String(err))
            }
            setTimeout((function(){
              //  history.push('/grupo-atendimento/'+props.match.params.id)
            }),2000)
        }
    }


    async function newTag(index: any){
        setModal(true)
        getTagsByGrupo()
    
        let tag : IGrupoAtendimentoTag = {}

        if(index === 'nova'){
            tag.id_grupo_atendimento_tag = null
            tag.status = "ativo"
            tag.id_grupo_atendimento = id
            tag.tag = "Nova Tag"
        }else{
            tag.status = tags[index].status
            tag.id_grupo_atendimento_tag  = tags[index].id_grupo_atendimento_tag
            tag.id_grupo_atendimento = id
            tag.tag = tags[index].tag
            tag.modificado = tags[index].modificado
            tag.criado = tags[index].criado
        }


        setTagEdit({...tag})
    }

    async function deleteTag(tag:IGrupoAtendimentoTag){
        try{
            let funilEtapasResponse : any = await apiTropa.delete('/tag-atendimento/'+tag.id_grupo_atendimento_tag)
            getTagsByGrupo()

            addSuccess("Sucesso", String(funilEtapasResponse.data.message))

        }catch(err: any){
            if(err.response){
                addDanger("Atenção", String(err.response.data.message))
            }else{
                addDanger("Atenção", String(err))
            }
            setTimeout((function(){
              //  history.push('/grupo-atendimento/'+props.match.params.id)
            }),2000)
        }
    }

    async function changeTagEdit(value:any, key:any) {
        if(tagEdit){
            tagEdit[key  as keyof IGrupoAtendimentoTag] = value;
        }
    
        setTagEdit({...tagEdit})
    }


    async function saveAll(){
        try{
            if(tagEdit?.id_grupo_atendimento_tag){
                let etapaResponse : any = await apiTropa.put('/tag-atendimento/'+tagEdit.id_grupo_atendimento_tag,tagEdit)
                addSuccess("Sucesso", String(etapaResponse.data.message))
            }else{
                let etapaResponse : any = await apiTropa.post('/tag-atendimento', tagEdit)
                addSuccess("Sucesso", String(etapaResponse.data.message))
            }

            setModal(false)
            getTagsByGrupo()
        }catch(err: any){
            if(err.response){
                addDanger("Atenção", String(err.response.data.message))
            }else{
                addDanger("Atenção", String(err))
            }
            setTimeout((function(){
              //  history.push('/grupo-atendimento/'+props.match.params.id)
            }),2000)
        }
    }

    return (
        <>
            <Container>
                <Header>
                    <BackPage />
                </Header>

                {loading === true ? 
                    <Skeleton style={{marginTop: '15px'}}
                        count={7}
                        className="skeletor"
                        height="80px"
                        borderRadius="16px"
                        baseColor="#262655"
                        highlightColor="#3e3b77"
                    />
                    
                :
                    <>

                        <Card style={{marginTop: '15px'}}>
                            <TagsComponent>
                                {tags ? 
                                    tags.map((row,key) => (
            
                                        <li style={{zIndex:tags.length - key}}>
                                            <span>{row.tag}</span>
                                            <ButtonLinear
                                                    className='funil-delete'
                                                    Icon={IconConfig}
                                                    onClick={() =>
                                                        newTag(key)
                                                    }
                                                />
                                            <ButtonLinear
                                                    className='funil-delete'
                                                    Icon={IconDelete}
                                                    onClick={() =>
                                                        deleteTag(row)
                                                    }
                                                />
                                        </li>
                                    ))
                                : 
                                ''}
                                <li className='new' style={{zIndex:0}} onClick={() => newTag('nova')} >
                                    <span>Adicionar</span>
                                    <ButtonLinear
                                            className='funil-delete'
                                            Icon={IconPlus}
                                            type={'button'}
            
                                        />
                                </li>
                            </TagsComponent>
                        </Card>

                        <ModalDefault id="overlayModal" onClose={() => setModal(!modal)} openModal={modal}>
                            <Field>
                                <Label>Tag</Label>
                                <InputDefault value={tagEdit?.tag} 
                                onChangeText={(e:any) => changeTagEdit(e,'tag') } />
                            </Field>

                            <FieldGroup style={{ marginTop: '32px' }}>
                                <ButtonLinear onClick={() => setModal(false)}>
                                    Cancelar
                                </ButtonLinear>
                                <ButtonLinear className="success" onClick={() => saveAll()}>
                                    Salvar
                                </ButtonLinear>
                            </FieldGroup>
                        </ModalDefault>
                        
                    </>
                }
            </Container>
        </>
    )
}
