import { useEffect, useRef, useState } from 'react';
import ButtonLinear from '../../../components/form/ButtonLinear';
import InputSwitchDefault from '../../../components/form/InputSwitchDefault';
import BackPage from '../../../components/layout/UIElements/BackPage';
import CardSatisfation from '../../../components/Ui/CardSatisfation';
import CardUser from '../../../components/Ui/CardUser';
import { useRoutes } from '../../../contexts/RoutesContext';
import { useHistory } from 'react-router-dom';
import apiTropa from '../../../services/apiTropa';
import InputDefaultTime from '../../../components/form/InputDefaultTime';
import FieldGroup from '../../../components/layout/containers/FieldGroup';
import SelectDefault from '../../../components/form/SelectDefault';
import TimeZones from './timezones.json';
import { IconBell, IconChannel, IconChat, IconClipboard, IconFunnel, IconHashTag, IconMonitor, IconPause, IconPower, IconShape, IconSmile, IconThumbUp, IconUserChat, IconWarning} from '../../../components/icons';
import {Container, Content,Header,SectionHours,SectionSatisfation,SectionOperators,InfoOperators,ListOperators,CardEditHours,InfoHours} from './styles';
import { useMessage } from '../../../contexts/MessagesContext';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import ModalDefault from '../../../components/ModalDefault';
import CardMessage from '../../../components/Chat/CardMessage';
import Datetime from '../../../Helper/Datetime';
import { FooterChat } from '../../../components/Chat/CardChat/styles';
import CardEmoji from '../../../components/Chat/CardEmoji';
import Field from '../../../components/layout/containers/Field';
import InputDefault from '../../../components/form/InputDefault';
import InputRadioDefault from '../../../components/form/InputRadioDefault';
import { DescriptionWarning, TitleModal } from '../../../components/ModalDefault/styles';
import { useTheme } from 'styled-components';

export default function GrupoAtendimentoInfo(props: any) {
    const { setPageName, pageName } = useRoutes()
    const { addDanger, addSuccess } = useMessage()
    const {title} = useTheme()
    const history = useHistory()
    const inputRef = useRef<any>()
    const [openModal, setOpenModal] = useState(false);

    const id = props.match.params.id
    const [loading, setLoading] = useState<boolean>(false)
    const [grupoDeAtendimento, setGrupoDeAtendimento] = useState<any>({})
    
    const [modalMensagemInatividade, setModalMensagemInatividade] = useState<boolean>(false)
    const [DTOModal, setDTOModal] = useState<any>({})
    const [messageModal, setMessageModal] = useState<any>('')
    const [hasMenuEmoji, setHasMenuEmoji] = useState<boolean>(false)
    const [exibirFusoHorario, setExibirFusoHorario] = useState(false)
    const [exibirHorarioDeAtendimento, setExibirHorarioDeAtendimento] = useState<boolean>(false)
    const [exibirConfiguracaoInatividade, setExibirConfiguracaoInatividade] = useState<boolean>(false)
    const [exibirConfiguracaoInatividadeMsg, setExibirConfiguracaoInatividadeMsg] = useState<boolean>(false)
    const [horarioDeAtendimento, setHorarioDeAtendimento] = useState<any>({
        horario_inicio: '00:00:00',
        horario_final: '00:00:00',
        fuso_horario: null,
    })
    const [modalFila, setModalFila] = useState<boolean>(false)
    const [tipoDistribuicaoFila, setTipoDistribuicaoFila] = useState<string>('')
    const [preferenciaAtendimentoAnterior, setPreferenciaAtendimentoAnterior] = useState<any>([])
    const [ignorarLimiteAtendimento, setIgnorarLimiteAtendimento] = useState<any>([])

    const [namePage, setNamePage] = useState(pageName);

    useEffect(() => {

        setLoading(true)
        getData()
    }, [])
    
    async function getData() {

        const response:any = await apiTropa.get(`grupo-atendimento/${id}`)
        const grupoDeAtendimento = response.data.data

        setTipoDistribuicaoFila(grupoDeAtendimento.tipoDistribuicaoFila)
        setPreferenciaAtendimentoAnterior(grupoDeAtendimento.preferenciaAtendimentoAnterior === 1 ? ['atendimento_anterior'] : [])
        setIgnorarLimiteAtendimento(grupoDeAtendimento.ignorarLimiteAtendimento === 1 ? ['ignorar_limite'] : [])
        
        const responseOperadores:any = await apiTropa.post(`/operador/grupo-atendimento/${id}`, {
            tokenEyou: window.sessionStorage.getItem('tokenInicial')
        })
        grupoDeAtendimento.operadores = responseOperadores.data.data
        
        setGrupoDeAtendimento({ ...grupoDeAtendimento })
        setPageName(grupoDeAtendimento.grupo_atendimento)

        const responseHorarios:any = await apiTropa.get(`horario-atendimento/${id}`)
        const horarioDeAtendimento = responseHorarios.data.data

        setHorarioDeAtendimento(horarioDeAtendimento)

        setLoading(false)
    }

    async function alterarStatusDoGrupoDeAtendimento(value:boolean){
        try {
            grupoDeAtendimento.ativo = value ? "sim" : "nao"
            setGrupoDeAtendimento({...grupoDeAtendimento})
            await apiTropa.put(`grupo-atendimento/${grupoDeAtendimento.id_grupo_atendimento}`, {
                "grupo_atendimento": grupoDeAtendimento.grupo_atendimento,
                "ativo": grupoDeAtendimento.ativo
            })
        } catch (err:any) {
            addDanger('Atenção', String(err))
        }
    }

    function onChangeInputHorarioDeAtendimento(name: string, value: string) {
        horarioDeAtendimento[name] = value
        setHorarioDeAtendimento(horarioDeAtendimento)
    }

    function onChangeInputGrupoDeAtendimentoTempo(name: string, value: string) {
        grupoDeAtendimento[name] = value + ":00"
        setGrupoDeAtendimento(grupoDeAtendimento)
    }

    async function alterarHorarioDeAtendimento() {
        try {
            if( horarioDeAtendimento.horario_inicio.length < 5 ) throw 'Preencha um horário inicial correto.'
            if( horarioDeAtendimento.horario_final.length < 5 ) throw 'Preencha um horário final correto.'
            await apiTropa.put(`horario-atendimento/${id}`, horarioDeAtendimento)
            
            addSuccess('Confirmado', 'Horário de atendimento salvo com sucesso.')
            setExibirHorarioDeAtendimento(false)

        } catch (err:any) {
            addDanger('Atenção', String(err))
        }
    }

    async function alterarTempoInatividade() {
        try {
    
            if(grupoDeAtendimento['tempo_inatividade'] === '00:00:00'){
                throw "Tempo de Inatividade inválido"
            }

            await apiTropa.put(`grupo-atendimento/${id}/tempo-inatividade`, grupoDeAtendimento)
            
            setExibirConfiguracaoInatividade(false)
            addSuccess('Confirmado', 'Tempo de inatividade salvo com sucesso.')

        } catch (err:any) {
            addDanger('Atenção', String(err))
        }
    }

    async function alterarFusoHorario() {
        try {
            if( !horarioDeAtendimento.fuso_horario ) throw 'Preencha um fuso horário correto.'
            await apiTropa.put(`horario-atendimento/${id}?edit=fusohorario`, horarioDeAtendimento)
            
            addSuccess('Confirmado', 'Fuso horário de atendimento salvo com sucesso.')
            setExibirFusoHorario(false)

        } catch (err: any) {
            addDanger('Atenção', err);
        }
    }

    function alterarMensagemInatividade(name:any, message:any)
    {
        setMessageModal(message)
        setDTOModal({
            name, message
        })
        setModalMensagemInatividade(true)
    }

    function salvarModalInatividade()
    {
        grupoDeAtendimento[DTOModal.name] = messageModal
        setGrupoDeAtendimento({...grupoDeAtendimento})
        setModalMensagemInatividade(false)
    }

    async function confirmarAlteracaoMensagemInatividade() {
        try {
            if( !grupoDeAtendimento.mensagem_inatividade_aviso ) throw 'Preencha uma mensagem de aviso.'
            if( !grupoDeAtendimento.mensagem_inatividade ) throw 'Preencha uma mensagem de inatividade.'
            
            apiTropa.put(`grupo-atendimento/${id}?edit=msginatividade`, {
                mensagem_inatividade: grupoDeAtendimento.mensagem_inatividade,
                mensagem_inatividade_aviso: grupoDeAtendimento.mensagem_inatividade_aviso
            });

            addSuccess('Confirmado', 'Mensagens de aviso e inatividade salvos com sucesso.')
            setExibirConfiguracaoInatividadeMsg(false)

        } catch (err: any) {
            addDanger('Atenção', err);
        }
    }

    function insertEmoji(val: string) {
        const step: any = inputRef.current;
        step.focus();
        const selection: any = window.getSelection();
        for (var i = 0; i < step; i += 1) {
            selection.modify('extend', 'backward', 'character');
        }
        document.execCommand('insertText', false, val);
    }

    async function handleOnUpdateNamepage(item: string) {
        try{
            await apiTropa.put(`/grupo-atendimento/${id}`, {
                grupo_atendimento: item,
                ativo: grupoDeAtendimento.ativo
            },)
            addSuccess('Confirmado', 'Grupo aterado com sucesso!')
            setOpenModal(false)
            getData()
        } catch(err: any) {
            addDanger('Atenção', err);
        }
    }

    async function handleOnUpdateQueue() {
        try{

            const DTO:any = {
                tipoDistribuicaoFila: tipoDistribuicaoFila,
                preferenciaAtendimentoAnterior: preferenciaAtendimentoAnterior.length > 0 ? 1 : 0,
                ignorarLimiteAtendimento: ignorarLimiteAtendimento.length > 0 ? 1 : 0,
            }
            await apiTropa.put(`/grupo-atendimento/${id}?edit=fila`, DTO)
            addSuccess('Confirmado', 'Fila de atendimento aterada com sucesso!')
            setModalFila(false)
            getData()
        } catch(err: any) {
            addDanger('Atenção', err.toString());
        }
    }

    function handleOnCancel() {
        setExibirHorarioDeAtendimento(false)
        setExibirConfiguracaoInatividade(false)
        setExibirConfiguracaoInatividadeMsg(false)

        window.location.reload();
    }

    return (
        <Container>
            <BackPage />
            <Content>
                <Header>
                    <h1>Configurações do grupo de atendimento</h1>

                    <FieldGroup width='280px'>
                        <InputSwitchDefault
                            options={[{label: "Grupo ativo", value: "1"}]}
                            value={[grupoDeAtendimento.ativo !== "sim" ? "0" : "1"]}
                            onChange={(value) => alterarStatusDoGrupoDeAtendimento(!value.length ? false : true)}
                        />

                        <ButtonLinear
                            className="info"
                            rounded={false}
                            onClick={() => setOpenModal(!openModal)}
                        >
                            Editar grupo
                        </ButtonLinear>

                    </FieldGroup>
                </Header>

                {loading === true ?
                    <Skeleton
                        count={1}
                        className="skeletor"
                        height="80px"
                        borderRadius="16px"
                        baseColor={title === "light" ? "#fff" : "#262655"}
                        highlightColor={title === "light" ? "#C5CED7" : "#3e3b77"}
                    />
                :
                    <>
                        <SectionHours>
                            <CardEditHours>

                                {!exibirHorarioDeAtendimento ? (
                                <>
                                    <InfoHours>
                                        <h3>Horário de atendimento</h3>
                                        <span>{horarioDeAtendimento.horario_inicio_segunda_feira + ' às ' + horarioDeAtendimento.horario_final_segunda_feira}</span>
                                    </InfoHours>

                                    <ButtonLinear className={'info'} onClick={() => history.push('/grupos-de-atendimento/' + id + '/horario-atendimento')}>
                                        editar
                                    </ButtonLinear>
                                </>
                                ) : (
                                <>
                                    <FieldGroup>
                                        <InputDefaultTime
                                            value={horarioDeAtendimento.horario_inicio}
                                            onChangeText={(value) => onChangeInputHorarioDeAtendimento('horario_inicio', value)}
                                        />
                                        <span>às</span>
                                        <InputDefaultTime
                                            value={horarioDeAtendimento.horario_final}
                                            onChangeText={(value) => onChangeInputHorarioDeAtendimento('horario_final', value)}
                                        />
                                    </FieldGroup>

                                    <ButtonLinear className={'danger'} onClick={() => handleOnCancel()}>
                                        cancelar
                                    </ButtonLinear>

                                    <ButtonLinear className={'success'} onClick={() => alterarHorarioDeAtendimento()}>
                                        salvar
                                    </ButtonLinear>
                                </>
                                )}
                            </CardEditHours>

                            <CardEditHours>
                                {!exibirFusoHorario ? (
                                <>
                                    <InfoHours>
                                        <h3>Fuso horário</h3>
                                        <span>
                                            {horarioDeAtendimento.fuso_horario ? horarioDeAtendimento.fuso_horario : 'Nenhum fuso horário'}
                                        </span>
                                    </InfoHours>

                                    <ButtonLinear className={'info'} onClick={() => setExibirFusoHorario(true)}>
                                        editar
                                    </ButtonLinear>
                                </>
                                ) : (
                                <>
                                    <div style={{ maxWidth: 270 }}>
                                        <SelectDefault
                                            value={horarioDeAtendimento.fuso_horario}
                                            placeholder="Fuso horário"
                                            style={{ maxWidth: 300 }}
                                            onChangeText={(value) => onChangeInputHorarioDeAtendimento('fuso_horario', value)}
                                        >
                                            {TimeZones.map((row, key) => (
                                                <option value={row.text}>
                                                    {row.text}
                                                </option>
                                            ))}
                                        </SelectDefault>
                                    </div>

                                    <ButtonLinear className={'danger'} onClick={() => handleOnCancel()}>
                                        cancelar
                                    </ButtonLinear>
                                    <ButtonLinear className={'success'} onClick={() => alterarFusoHorario()}>
                                        salvar
                                    </ButtonLinear>
                                </>
                                )}
                            </CardEditHours>
                        </SectionHours>
                        <SectionHours>
                            <CardEditHours>
                                {!exibirConfiguracaoInatividade ? (
                                <>
                                    <InfoHours>
                                        <h3>Tempo de Inatividade</h3>
                                        <span>{ moment(moment().utc().format('YYYY-MM-DD')+' '+grupoDeAtendimento['tempo_inatividade']).format('HH:mm')}</span>
                                    </InfoHours>

                                    <ButtonLinear className={'info'} onClick={() => setExibirConfiguracaoInatividade(true)}>
                                        editar
                                    </ButtonLinear>
                                </>
                                ) : (
                                <>
                                    <FieldGroup>
                                        <InputDefaultTime
                                            value={moment(moment().utc().format('YYYY-MM-DD')+' '+grupoDeAtendimento['tempo_inatividade']).format('HH:mm:ss')}
                                            onChangeText={(value) => onChangeInputGrupoDeAtendimentoTempo('tempo_inatividade', value)}
                                            mask={"99:99"}
                                        />
                                    </FieldGroup>

                                    <ButtonLinear className={'danger'} onClick={() => handleOnCancel()}>
                                        cancelar
                                    </ButtonLinear>

                                    <ButtonLinear className={'success'} onClick={() => alterarTempoInatividade()}>
                                        salvar
                                    </ButtonLinear>
                                </>
                                )}
                            </CardEditHours>
                            <CardEditHours>
                                {!exibirConfiguracaoInatividadeMsg ? (
                                <>
                                    <InfoHours>
                                        <h3>Mensagens de Inatividade</h3>
                                        <span>Aviso de inatividade e inatividade</span>
                                    </InfoHours>

                                    <ButtonLinear type='button' className={'info'} onClick={() => setExibirConfiguracaoInatividadeMsg(true)}>
                                        editar
                                    </ButtonLinear>
                                </>
                                ) : (
                                <>
                                    <ButtonLinear 
                                        className={'transparent'} 
                                        onClick={() => alterarMensagemInatividade('mensagem_inatividade_aviso', grupoDeAtendimento.mensagem_inatividade_aviso)}
                                    >
                                        Aviso
                                        <IconBell />
                                    </ButtonLinear>

                                    <ButtonLinear Icon={IconPower} className={'transparent'} onClick={() => alterarMensagemInatividade('mensagem_inatividade', grupoDeAtendimento.mensagem_inatividade)}>
                                        Inatividade
                                    </ButtonLinear>

                                    <ButtonLinear type='button' className={'danger'} onClick={() => handleOnCancel()}>
                                        cancelar
                                    </ButtonLinear>

                                    <ButtonLinear className={'success'} onClick={() => confirmarAlteracaoMensagemInatividade()}>
                                        salvar
                                    </ButtonLinear>
                                </>
                                )}
                            </CardEditHours>
                        </SectionHours>
                    </>
                } 

                <SectionSatisfation>

                    <CardSatisfation
                        title="Fila de atendimento"
                        Icon={IconUserChat}
                        onClick={() => setModalFila(true)}
                    />

                    <CardSatisfation
                        title="Pesquisa de satisfação"
                        Icon={IconThumbUp}
                        onClick={() => history.push('/grupos-de-atendimento/' + id + '/pesquisa-de-satisfacao')}
                    />

                    <CardSatisfation
                        title="Configurar botão do site"
                        Icon={IconMonitor}
                        onClick={() => history.push('/grupos-de-atendimento/' + id + '/configurar-botao')}
                    />

                    <CardSatisfation
                        title="Configurar canais de atendimento"
                        Icon={IconChannel}
                        onClick={() => history.push('/grupos-de-atendimento/' + id + '/configurar-canais')}
                    />

                    <CardSatisfation
                        title="Formulário de entrada"
                        Icon={IconClipboard}
                        onClick={() => history.push('/grupos-de-atendimento/' + id + '/formulario-de-entrada')}
                    />
                </SectionSatisfation>
                <SectionSatisfation>
                    <CardSatisfation
                        title="Mensagens pré-definidas"
                        Icon={IconChat}
                        onClick={() => history.push('/grupos-de-atendimento/' + id + '/mensagens-pre-definidas')}
                    />

                    <CardSatisfation
                        title="Funil de Atendimento"
                        Icon={IconFunnel}
                        onClick={() => history.push('/grupos-de-atendimento/' + id + '/funil-de-atendimento')}
                    />

                    <CardSatisfation
                        title="Tags"
                        Icon={IconHashTag}
                        onClick={() => history.push('/grupos-de-atendimento/' + id + '/tags')}
                    />

                    <CardSatisfation
                        title="Configurar Pausas"
                        Icon={IconPause}
                        onClick={() => history.push('/grupos-de-atendimento/' + id + '/pausas')}
                    />
                </SectionSatisfation>

                {loading === true ?
                    <Skeleton
                        count={1}
                        className="skeletor"
                        height="155px"
                        borderRadius="16px"
                        baseColor={title === "light" ? "#fff" : "#262655"}
                        highlightColor={title === "light" ? "#C5CED7" : "#3e3b77"}
                    />
                :
                    <SectionOperators>
                        <InfoOperators>
                            <h2>Operadores</h2>
                            
                            <ButtonLinear className="info" onClick={() => history.push('/grupos-de-atendimento/' + id + '/operadores')}>
                                Configurar
                            </ButtonLinear>
                        </InfoOperators>

                        <ListOperators>
                            <CardUser users={grupoDeAtendimento.operadores} />
                        </ListOperators>
                    </SectionOperators>
                }

            </Content>

            <ModalDefault
                id="overlayModal"
                width="550px"
                onClose={() => setModalMensagemInatividade(!modalMensagemInatividade)}
                openModal={modalMensagemInatividade}
                
            >
                    <CardMessage
                            messageType={'recebida'}
                            hasView={true}
                            color={'#FFF'}
                            
                        >
                            <div className="content-message" style={{marginTop:'15px', marginBottom:'15px'}}>
                                <p style={{wordBreak: 'break-word'}}>{messageModal}</p>
                            </div>

                            <div className="status">
                                <span>
                                    {Datetime.string(moment().format('YYYY-MM-DD HH:mm:ss'), true)}
                                </span>
                                <IconShape />
                            </div>
                    </CardMessage>
                    
                    <FooterChat backgroundColor={"#000"} style={{marginTop:'15px', paddingTop:'15px'}}>
                        <form className="containerChatInput">
                            <CardEmoji
                                active={hasMenuEmoji}
                                insertEmoji={(val) => insertEmoji(val)}
                            />
                            <button
                                className="buttonEmojiChat"
                                onClick={() => setHasMenuEmoji(!hasMenuEmoji)}
                                type="button"
                            >
                                <IconSmile />
                            </button>

                            <input
                                id="inputChat"
                                ref={inputRef}
                                value={messageModal}
                                onChange={(e) => setMessageModal(e.target.value)}
                            />
                        </form>
                    </FooterChat>

                <FieldGroup marginTop="32px">
                    <ButtonLinear onClick={() => setModalMensagemInatividade(!modalMensagemInatividade)}>Cancelar</ButtonLinear>
                    <ButtonLinear className="success" onClick={salvarModalInatividade}>Salvar</ButtonLinear>
                </FieldGroup>
            </ModalDefault>

            <ModalDefault
                id={'overlayModal'}
                onClose={() => setOpenModal((openModal) => !openModal)}
                openModal={openModal}
            >
                <Field>
                    <InputDefault
                        type="text"
                        placeholder="Nome do grupo"
                        value={pageName}
                        onChangeText={(text) => setNamePage(text)} />
                    </Field>
                <FieldGroup marginTop={'32px'}>
                    <ButtonLinear
                        type="button"
                        className="transparent"
                        onClick={() => setOpenModal((openModal) => !openModal)}
                    >
                        Cancelar
                    </ButtonLinear>

                    <ButtonLinear
                        type="button"
                        loading={loading}
                        className="success"
                        onClick={() => handleOnUpdateNamepage(namePage)}
                    >
                        Salvar
                    </ButtonLinear>
                </FieldGroup>
            </ModalDefault>

            <ModalDefault
                id={'overlayModalQueue'}
                onClose={() => setModalFila(false)}
                openModal={modalFila}
            >
                <TitleModal>Configurar Fila</TitleModal>
                <Field>
                    <InputRadioDefault
                        flexDirection="column"
                        onChange={(option) => setTipoDistribuicaoFila(option)}
                        options={[
                            {
                                label: 'Distribuído igualmente',
                                value: 'distribuido_igualmente',
                            },
                            {
                                label: 'Primeiro disponível',
                                value: 'primeiro_disponivel'
                            }
                        ]}
                        value={tipoDistribuicaoFila}
                    />
                    <InputSwitchDefault
                        flexDirection="column"
                        onChange={(option) => setPreferenciaAtendimentoAnterior(option)}
                        options={[
                            {
                                label: 'Preferência pelo operador que atendeu antes',
                                value: 'atendimento_anterior',
                            }
                        ]}
                        value={preferenciaAtendimentoAnterior}

                    />

                    <InputSwitchDefault
                        flexDirection="column"
                        onChange={(option) => setIgnorarLimiteAtendimento(option)}
                        options={[
                            {
                                label: 'Ignorar o limite de atendimento do operador',
                                value: 'ignorar_limite'
                            }
                        ]}
                        value={ignorarLimiteAtendimento}

                    />
                </Field>
                <FieldGroup marginTop={'32px'}>
                    <ButtonLinear
                        type="button"
                        className="transparent"
                        onClick={() => setModalFila((openModal) => !openModal)}
                    >
                        Cancelar
                    </ButtonLinear>

                    <ButtonLinear
                        type="button"
                        loading={loading}
                        className="success"
                        onClick={handleOnUpdateQueue}
                    >
                        Salvar
                    </ButtonLinear>
                </FieldGroup>
            </ModalDefault>

        </Container>
    );
}
