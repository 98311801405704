import styled, { css } from 'styled-components';

interface ContainerProps {
    messageType?: 'recebida' | 'enviada';
    backgroundEnviada?: string;
    backgroundRecebida?: string;
    hasView?: boolean;
    hasQuote?: boolean;
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    position: relative;

     >svg {
        position: absolute;
        bottom: 10px;
        right: 6px;

        path {
            fill: ${props => props.backgroundRecebida
            ? props.backgroundRecebida
            : '#28b3a2'};
        }

    }

    ${props => props.messageType === 'enviada' &&
    css`
        > svg {
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            bottom: 10px;
            left: 6px;

            path {
            fill: ${props.backgroundEnviada ? props.backgroundEnviada : '#4a7dff'};
            }
        }
    `
     }

    ${(props) =>
        props.messageType === 'recebida' &&
        css`
            justify-content: flex-end;
        `}    
`;

export const Content = styled.div<ContainerProps>`
    min-height: 40px;
    border-radius: 10px;
    padding: 12px 16px;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    background: ${(props) =>
        props.backgroundEnviada ? props.backgroundEnviada : '#4a7dff'};
    color: ${(props) => props.color};
    margin: 0 14px;
    max-width: 90%;
    position: relative;
    ${(props) =>
        props.messageType === 'recebida' &&
        css`
            background: ${props.backgroundRecebida
                ? props.backgroundRecebida
                : '#28b3a2'};
        `}
    p {
        font-size: 13px;
        color: #fff;
        max-width: 100%;
        white-space: pre-line;
        a {
            color: #FFF;
            font-weight: bold;
        }
    }
    span {
        font-size: 11px;
        white-space: nowrap;
        color: #eaeef3;
    }
    .content-message {
        max-width: 100%;

        img,
        video,
        audio {
            max-width: 100%;
        }
        video,
        img {
            max-height: 240px;
        }

        .downloadFileChat {
            display: flex;
            align-items: center;

            width: 100%;
            padding: 10px 6px;
            border-radius: 4px;

            border: none;
            background: #065051;
            text-decoration: none;

            span {
                font-size: 12px;
                font-weight: 600;
                color: #fff;
                margin-left: 8px;
            }

            .iconDownloadChat {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                border: 1px solid #cecece;

                border-radius: 50%;
                padding: 4px;
                margin-left: 30px;
                svg {
                    margin: 0;
                }
            }
        }
        
        .quote {
            font-size: 13px;
            color: #fff;
            max-width: 100%;
            white-space: pre-line;
            position: relative;
            display: flex;
            flex-direction: column;
            background: rgba(0,0,0,.1);
            padding: 10px;
            margin-bottom: 10px;
            margin-right: 10px;
            .quote {
                margin-left: 10px;
            }
            svg {
                width: 20px;
                height: 20px;
            }
            &:after {
                position: absolute;
                content: '';
                left: 0px;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 3px;
                height: 80%;
                background: #FFF;
                opacity: 0.6;
                border: 2px;
            }
        }
    }
    .status {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;
        width: 100%;
        button {
            border: none;
            background: rgba(0,0,0,.5);
            border-radius: 5px;
            color: #FFF;
            padding: 8px 8px;
            display: flex;
            margin-right: 10px;
            svg {
                width: 13px;
                height: 13px;
                display: block;
                margin-right: 5px;
            }
        }

    }

    ${(props) =>
        !props.hasView &&
        css`
            svg {
                display: none;
            }
        `}
    svg {
        margin-left: 4px;
    }
`;

export const NameUser = styled.div`
    display: flex;
    margin-bottom: 5px;
    font-size:11px;
    font-weight:400;
    border-radius: 5px;
    padding: 5px 0px;
    margin-bottom: -24px;
    margin-top: -20px;
    margin-left: 16px;
    color: ${(props) => props.theme.colors.spaceCaded};
    &.recebida {
        justify-content: flex-end;
        margin-right: 16px;
        color: #FFF;
    }
`