import { useEffect, useState } from 'react';
import ButtonLinear from '../../components/form/ButtonLinear';
import InputDefault from '../../components/form/InputDefault';
import SelectDefault from '../../components/form/SelectDefault';
import Field from '../../components/layout/containers/Field';
import FieldGroup from '../../components/layout/containers/FieldGroup';
import ModalDefault from '../../components/ModalDefault';
import { useRoutes } from '../../contexts/RoutesContext';
import { GrupoAtendimento } from '../../types/GrupoAtendimento';
import apiTropa from '../../services/apiTropa';
import { Container, Content, Main } from './styles';
import {
    Td,
    TdTitle,
    TdDescription,
} from '../../components/layout/containers/Td';
import TableDefault from '../../components/TableDefault';
import { useMessage } from '../../contexts/MessagesContext';
import StatusTable from '../../components/Table/StatusTable';
import { useHistory } from 'react-router-dom';
import CardUser from '../../components/Ui/CardUser';
import Loading from '../../components/Loading/Loading';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import FailedRegister from '../../components/FailedRegister';
import { useTheme } from 'styled-components';

export default function GruposAtendimento() {
    const { setPageName } = useRoutes();
    const { addDanger } = useMessage();
    const { title } = useTheme();
    const [openModal, setOpenModal] = useState(false);
    const [filter, setFilter] = useState<String>("");
    const [grupos, setGrupos] = useState<Array<GrupoAtendimento>>([]);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [loadingFull, setLoadingFull] = useState(false);

    const [DTO, setDTO] = useState({
        grupo_atendimento: '',
        ativo: '',
    });

    useEffect(() => {
        setPageName('Grupos de Atendimento');
        getGrupos();
    }, [setPageName]);

    useEffect(() => {
        
        const gruposFilter = grupos.map((grupo:any) => {
            let string = grupo.grupo_atendimento.toLowerCase()
            let search = filter.toLowerCase()
            grupo.ocultar = !( string.indexOf(search) !== -1 )
            return grupo
        })
        setGrupos([...gruposFilter])

    }, [filter])

    async function handleNew() {
        try {
            setLoading(true);
            if (!DTO.grupo_atendimento)
                throw 'Preencha um nome para o grupo de atendimento.';
            if (!DTO.ativo)
                throw 'Preencha um status para o grupo de atendimento.';

            const response = await apiTropa.post('grupo-atendimento', DTO);

            await getGrupos();
            setDTO({
                grupo_atendimento: '',
                ativo: '',
            });
            setOpenModal(false);
            setLoading(false);
        } catch (err: any) {
            addDanger('Atenção', err);
            setLoading(false);
        }
    }

    async function getGrupos() {
        try {
            setLoading(true)
            const response:any = await apiTropa.get('grupo-atendimento')
            const grupos = response.data.data
            setGrupos(grupos)
            setLoading(false)
        } catch (e) {
            addDanger('Ocorreu um erro', 'Tente novamente mais tarde')
        }
    }

    async function getOperadoresByGrupoAtendimento(id_grupo_atendimento:any) {

        setLoadingFull(true)
        const response:any = await apiTropa.post(`/operador/grupo-atendimento/${id_grupo_atendimento}`, {
            tokenEyou: window.sessionStorage.getItem('tokenInicial')
        })
        setLoadingFull(false)
        const operadores = response.data.data
        console.log("operadores", operadores)

        const gruposJoin = grupos.map((grupo:any) => {
            if( grupo.id_grupo_atendimento === id_grupo_atendimento ){
                grupo.operadores = operadores
            }
            return grupo
        })
        setGrupos([...gruposJoin])

    }

    function handleOnChange(name: string, value: string) {
        const newDTO: any = DTO;
        newDTO[name] = value;
        setDTO({ ...newDTO });
    }

    return (
        <>
            <Loading active={loadingFull} />
            
            <Container>

                <Content>
                    <InputDefault
                        onChangeText={(value) => setFilter(value)}
                        value={''}
                        search={true}
                        placeholder={'Buscar Grupo'}
                    />

                    <ButtonLinear
                        className="info"
                        rounded={false}
                        onClick={() => setOpenModal(!openModal)}
                    >
                        Novo grupo
                    </ButtonLinear>
                </Content>

                <Main>

                    {loading === true && (
                        <Skeleton
                            count={10}
                            className="skeletor"
                            height="85px"
                            borderRadius="16px"
                            baseColor="#262655"
                            highlightColor="#3e3b77"
                        />
                    )}

                    {loading === false && grupos.length === 0 && <FailedRegister />}

                    {grupos.map((row, key) => (
                    <>
                        {!row.ocultar &&
                        <TableDefault
                            columns={2}
                            onClickDropDownOpen={() => getOperadoresByGrupoAtendimento(row.id_grupo_atendimento)}
                            dropDownComponent={<CardUser users={row.operadores} />}
                        >
                            <Td flexDirection="column">
                                <TdDescription>Grupo de atendimento</TdDescription>
                                <TdTitle>{row.grupo_atendimento}</TdTitle>
                            </Td>

                            <Td
                                flexDirection="row"
                                style={{ justifyContent: 'flex-end' }}
                            >
                                <StatusTable
                                    hasButton={false}
                                    status={row.ativo === 'sim' ? true : false}
                                />
                                <ButtonLinear
                                    color={title === 'light' ? '#8A99A8' : '#fff'}
                                    rounded={false}
                                    onClick={() =>
                                        history.push(
                                            'grupos-de-atendimento/' +
                                                row.id_grupo_atendimento
                                        )
                                    }
                                >
                                    Editar
                                </ButtonLinear>

                                {/* <ButtonLinear
                                    className='info'
                                    rounded={false}
                                    onClick={() => console.log('CLONAR', row.id_grupo_atendimento)}>
                                    Clonar grupo
                                </ButtonLinear> */}
                            </Td>

                        </TableDefault>
                        }
                    </>
                    ))}
                </Main>

                <ModalDefault
                    id={'overlayModal'}
                    onClose={() => setOpenModal((openModal) => !openModal)}
                    openModal={openModal}
                >
                    <Field>
                        <InputDefault
                            type="text"
                            placeholder="Nome do grupo"
                            value={DTO.grupo_atendimento}
                            onChangeText={(text) =>
                                handleOnChange('grupo_atendimento', text)
                            }
                        />

                        <SelectDefault
                            placeholder="Status"
                            value={DTO.ativo}
                            onChangeText={(text) => handleOnChange('ativo', text)}
                        >
                            <option value="sim">Ativo</option>
                            <option value="nao">Inativo</option>
                        </SelectDefault>
                    </Field>

                    <FieldGroup marginTop={'32px'}>
                        <ButtonLinear
                            color={title === 'light' ? '#8A99A8' : '#fff'}
                            type="button"
                            className="transparent"
                            onClick={() => setOpenModal((openModal) => !openModal)}
                        >
                            Cancelar
                        </ButtonLinear>

                        <ButtonLinear
                            type="button"
                            loading={loading}
                            className="success"
                            onClick={handleNew}
                        >
                            Salvar
                        </ButtonLinear>
                    </FieldGroup>
                </ModalDefault>
            </Container>
        </>
    );
}
