import styled from 'styled-components';

interface MessageProps {
    selected?: boolean;
    hasNotification?: number;
    panel?: string;
}

export const Container = styled.div<MessageProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.2s;
    width: 100%;
    height: 90px;
    box-sizing:border-box;
    padding: ${(props) => props.selected ? '0px 10px 0px 15px' : '0px'};
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    position: relative;
    background: ${(props) =>
        props.selected === true ? (props.theme.title === 'light' ? '#F6F8FA' : 'rgba(0, 0, 0, 0.1)') : 'transparent'};

    &.new {
        animation: ${(props) => !props.selected ? 'newMessage 1s linear infinite' : ''} ;

        @keyframes newMessage {
            0% {
                background: #28b3a2;
            }
            33% {
                background: transparent;
            }
            100% {
                background: #28b3a2;
            }
        } 
    }
    &::before {
        width: 4px;
        height: 40px;
        position: absolute;
        transition: all .2s;
        left: ${(props) => props.selected ? '0px' : '-17px'};
        background-color: ${(props) =>
            props.panel === 'Atendimentos'
                ? '#2ff0ab'
                : '#fff' && props.panel === 'Aguardando'
                ? '#F5A623'
                : '#fff' && props.panel === 'Finalizados'
                ? '#4A7DFF'
                : '#fff'};
        border-radius: 4px;
        content: '';
        visibility: ${(props) => (props.selected ? 'visible' : 'hidden')};
    }
`;

export const ProfileImage = styled.div<MessageProps>`
    display: flex;
    align-items: center;
    margin-right: 10px;
    transition: all 0.5s;
    border: 2px solid
        ${(props) =>
            props.panel === 'Atendimentos'
                ? '#2ff0ab'
                : '#fff' && props.panel === 'Aguardando'
                ? '#F5A623'
                : '#fff' && props.panel === 'Finalizados'
                ? '#4A7DFF'
                : '#fff'};
    border-radius: 50%;

    .image {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-size: cover;
    }
`;

export const MessageLeft = styled.div<MessageProps>`
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: none;
    h1 {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 130px;
        font-size: 100%;
        font-weight: bold;
        color: ${(props) =>
            props.panel === 'Atendimentos'
                ? '#2ff0ab'
                : '#fff' && props.panel === 'Aguardando'
                ? '#F5A623'
                : '#fff' && props.panel === 'Finalizados'
                ? '#4A7DFF'
                : '#fff'};
    }

    span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 140px;
        font-size: 80%;
        color: ${(props) => props.theme.colors.lavanderGray};
        margin-top: 6px;
    }
`;

export const DataMessage = styled.div<MessageProps>`
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    &::before {
        display: flex;
        width: 18px;
        height: 18px;
        border-radius: 100px;
        font-size:11px;
        align-items: center;
        justify-content: center;
        text-align: center;
        line-height: 20px;
        margin-bottom: 10px;
        background-color: ${(props) => props.theme.colors.blueCrayola};
        color: ${(props) => props.theme.colors.white};
        content: '${(props) => props.hasNotification && props.hasNotification}';
        display: ${(props) =>
            !props.selected && props.hasNotification && props.hasNotification > 0
                ? 'inline'
                : 'none'};
    }
    span {
        font-size: 60%;
        color: ${(props) => props.theme.colors.lavanderGray};
        white-space: nowrap;
    }
`;

export const SectionTypeMessage = styled.div`
    display: flex;
    align-items: center;

    position: absolute;
    top: 4px;
    right: 4px;

    h3 {
        font-size: 0.812rem;
        font-weight: 500;
        color: ${({theme}) => theme.colors.white};
        margin-right: 4px;
    }
`;