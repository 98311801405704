import styled from "styled-components"

import BGLogin from '../../assets/images/login/bg.png'
import Person from '../../assets/images/login/person.png'
import Text from '../../assets/images/login/text.png'
import Message from '../../assets/images/login/message.png'
import Products from '../../assets/images/login/products.png'

interface ContainerProps {
    effect: boolean
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
    padding-bottom: 50px;
    box-sizing: border-box;
    background: #1F1F48;    
    overflow-x: hidden;
    header {
        height: 100px;
        display: flex;
        width: 1072px;
        max-width: 90%;
        justify-content: flex-end;
        align-items: center;
        a {
            display: flex;
            align-items: center;
            margin-left: 20px;
            text-decoration: none;
            svg {
                margin-right: 8px;
            }
            span {
                font-size: 16px;
                line-height: 32px;
                font-weight: 400;
                color: #FFF;
            }
        }
    }
    @keyframes effectSection {
        0% {
            width: 0px;
        }
        100% {
            width: 1072px;
        }
    }
    section {
        animation: effectSection .5s;
        width: 1072px;
        max-width: 90%;
        max-height: 824px;
        flex: 1;
        background: #FFFFFF;
        box-shadow: 1px 4px 10px 6px rgba(0, 0, 0, 0.25);
        border-radius: 48px;
        display: flex;
        > div {
            width: 50%;
            height: 100%;
            @media(max-width: 850px) {
                width: 100%;
                .center-form {
                    width: 80%;
                    form {
                        max-width: 100%;
                    }
                }
            }
        }
        .left-panel {
            transition: all .5s;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .center-form {
                display: ${props => (props.effect === true ? 'flex' : 'none')};
                flex-direction: column;
            }
            h1 {
                font-size: 32px;
                height: 48px;
                font-weight: 700;
                color: #8A99A8;
                margin: 40px 0 0 0;
                padding: 0px;
                span {
                    color: #5A646E;
                }
            }
            form {
                margin-top: 32px;
                height: 220px;
                width: 310px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                max-width: 95%;
                .description {
                    font-size: 12px;
                    color: #5A646E;
                }
                .forgetpassword {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    margin-top: 20px;
                    span {
                        color: ${(props) => props.theme.colors.skyblue};
                    }
                    svg {
                        margin-right: 10px;
                    }
                }
            }
            .forlogin {
                margin-top: 40px;
                display: flex;
                flex-direction: column;
                a {
                    display: flex;
                    height: 32px;
                    align-items: center;
                    cursor: pointer;
                    svg {
                        margin-right: 10px;
                    }
                    span {
                        color: #5A646E;
                        font-size: 16px;
                        line-height: 24px;
                        text-decoration: underline;
                    }
                }
            }
        }
        .right-panel {
            position: relative;
            display: flex;
            border-radius: 0px 48px 48px 0px;
            background: ${props => props.theme.colors.skyblue};
            display: flex;
            align-items: center;
            justify-content: center;
            @media(max-width: 850px) {
                display: none !important;
            }
            .bg {
                width: 100%;
                height: 100%;
                background: url(${BGLogin});
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: relative;
                padding: 0 80px;
                box-sizing: border-box;
                border-radius: 0px 48px 48px 0px;
                * {
                    max-width: 100%;
                    background-size: 100% !important;
                    background-repeat: no-repeat !important;
                }
                .message {
                    width: 218px;
                    height: 212px;
                    background: url(${Message});
                    position: absolute;
                    right: -60px;
                    top: 83px;
                    display: ${props => (props.effect === true ? 'flex' : 'none')};
                }
                .text {
                    width: 318px;
                    height: 136px;
                    background: url(${Text});
                    animation-delay: .3s;
                    display: ${props => (props.effect === true ? 'flex' : 'none')};
                }
                .products {
                    margin-top: 32px;
                    width: 382px;
                    height: 246px;
                    background: url(${Products});
                    animation-delay: .6s;
                    display: ${props => (props.effect === true ? 'flex' : 'none')};
                }
                .person {
                    width: 269px;
                    height: 239px;
                    position: absolute;
                    background: url(${Person});
                    right: -15px;
                    bottom: 0px;
                    animation-delay: .9s;
                    display: ${props => (props.effect === true ? 'flex' : 'none')};
                }
            }
        }
    }
`