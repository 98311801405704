import styled from "styled-components";

export const ContainerFailed = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.colors.spaceCadedLight};
    border-radius: 16px;
    width: 100%;
    height: 350px;
    margin-top: 10px;
    margin-bottom: 30px;
    position: relative;
    h1 {
        color: ${props => props.theme.colors.white};
        text-shadow: 2px 2px 2px rgba(0,0,0, .2);
    }
    .loader {
        position: absolute;
        display: flex;
    }
    @media (max-width: 900px) {
        h1 {
            font-size: 28px;
        }
    }
    @media (max-width: 425px) {
        h1 {
            font-size: 20px;
        }
    }
`