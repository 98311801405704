import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    align-items: center;
    span {
        font-size: 16px;
        font-weight: bold;
        margin-left: 8px;
        color: ${props => props.theme.colors.skyblue};
        cursor: pointer;
    }
`