import moment, { min } from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMessage } from '../../../contexts/MessagesContext';
import { useRoutes } from '../../../contexts/RoutesContext';
import { useSocket } from '../../../contexts/SocketContext';
import apiTropa from '../../../services/apiTropa';
import { Operador } from '../../../types/Operador';
import ButtonLinear from '../../form/ButtonLinear';
import SelectDefault from '../../form/SelectDefault';
import { IconClose, IconPieChart } from '../../icons';
import ModalUser from '../../Modal';
import { Container, SectionHoursCard } from './styles';

interface PopUpProps {
    showPopUp: boolean
    setShowPopUp: (value: any) => void
    operador: Operador
}

interface PauseProps {
    descricao?: string,
    fim?: string,
    inicio?: string
}

export default function PopUpHours({ 
    showPopUp, 
    setShowPopUp,
    operador
}: PopUpProps) {

    const history = useHistory()
    const { addConfirm, addWarning, addSuccess } = useMessage()
    const [modalPause, setModalPause] = useState(false)
    const [modalFinish, setModalFinish] = useState(false)
    const [pauseSelected, setPauseSelected] = useState<PauseProps>({} as PauseProps)
    const [minRestantes, setMinRestantes] = useState<string>('00:00:00')
    const [pausa, setPausa] = useState<any>('')
    const { setLogout } = useRoutes()

    useEffect(() => {

        if ( operador.diarioOperador.status === 'Pausa' ) {
            setModalPause(true)
            setPauseSelected({
                inicio: operador.diarioOperador.inicio_programado,
                fim: operador.diarioOperador.fim_programado,
            })
        }

    }, [operador])

    async function initialPause(pause: PauseProps) {
        
        try {

            if ( !pausa ) throw "Selecione um tipo de pausa antes de avançar."

            const response:any = await apiTropa.post('pausa/entrar', {
                tipo_pausa: pause,
                categoria_pausa: operador.pausas.filter((obj:any) => obj.pause === pause)[0].categoria_pausa
            })

            if ( response.data.status === 'error' ) throw response.data.message
            if ( response.data.data.status === 'aguardando_pausa' ) throw 'Sua pausa foi agendada com sucesso, finalize seus atendimentos antes para iniciar a pausa.'

            setPauseSelected(pause)
            setModalPause(true)

        } catch ( err: any ) {

            addWarning('Atenção', err)

        } 

    }

    async function finish() {

        addSuccess('Obrigado pelo trabalho,', 'até amanhã!')
        const response:any = await apiTropa.put('operador/logout')
        setLogout()
    }

    useEffect(() => {

        if ( modalPause === true ) {
            const intervalId = setInterval(() => {
                getCountDown()
            }, 1000)
            
            return () => clearInterval(intervalId); //This is important
        }

    }, [modalPause])

    function getCountDown()
    {

        var newDate = moment().utc().toDate();
        var d1 = moment(newDate).utc().format('DD/MM/YYYY HH:mm:ss')
        var d2 = moment(newDate).utc().format('DD/MM/YYYY '+pauseSelected.fim)

        var diff = moment(d2,"DD/MM/YYYY HH:mm:ss").utc().diff(moment(d1,"DD/MM/YYYY HH:mm:ss").utc());
        var mins:any = moment.duration(diff)
        
        const hours = mins._data.hours ? mins._data.hours.toString().padStart(2, '0') : '00'
        const minutes = mins._data.minutes ? mins._data.minutes.toString().padStart(2, '0') : '00'
        const seconds = mins._data.seconds ? mins._data.seconds.toString().padStart(2, '0') : '00'
        //var min = mins.format('hh:mm:ss')
        setMinRestantes(`${hours}:${minutes}:${seconds}`)

    }

    async function handleFinishPause()
    {

        const response:any = await apiTropa.put('pausa/sair')
        setModalPause(false)

    }

    return (
        <>
            <Container popUpAttendence={showPopUp}>
                <SectionHoursCard>
                    <div className="sectionRow">
                        <h1>Inicio</h1>
                        <span>{operador.horarioAtual?.inicio ? operador.horarioAtual.inicio : "Não configurado"}</span>
                    </div>
                </SectionHoursCard>

                
                <SectionHoursCard>
                    <div className="sectionRow">
                        <SelectDefault placeholder="Selecione" value={pausa} onChangeText={(value) => setPausa(value)}>       
                            {operador.pausas?.map((row:any, key:number) =>
                                <option key={key} value={row.pause}>{row.pause}</option>
                            )}
                        </SelectDefault>
                    </div>

                    <ButtonLinear
                        className="info"
                        onClick={() =>
                            pausa.descricao ? addConfirm({
                                title: 'Pausa',
                                description: 'Você deseja iniciar a pausa? Vamos interromper os atendimentos novos e iniciaremos sua pausa assim que finalizar o último atendimento da sua fila.',
                                hiddenButtonCancel: true,
                                textButtonConfirm: 'Iniciar',
                                onConfirm: () => initialPause(pausa),
                            }) : initialPause(pausa)
                        }
                    >
                        Iniciar pausa
                    </ButtonLinear>
                </SectionHoursCard>

                <SectionHoursCard style={{ borderBottom: '0' }}>
                    <div className="sectionRow">
                        <h1>Término</h1>
                        <span>{operador.horarioAtual?.fim ? operador.horarioAtual.fim : "Não configurado"}</span>
                    </div>

                    <ButtonLinear
                        className="info"
                        onClick={() =>
                            addConfirm({
                                title: 'Finalizar o dia',
                                description: 'Você deseja encerrar os atendimentos? Vamos interromper os atendimentos novos e iniciaremos seu encerramento assim que finalizar o último atendimento da sua fila.',
                                hiddenButtonCancel: false,
                                textButtonConfirm: 'Encerrar',
                                onConfirm: () => finish(),
                            })
                        }
                    >
                        Finalizar
                    </ButtonLinear>
                </SectionHoursCard>

                <button
                    onClick={() => setShowPopUp(!showPopUp)}
                    className="closePopUp"
                >
                    <IconClose color="#34D5FA" />
                </button>
            </Container>

            <ModalUser
                infos={{
                    title: 'Olá',
                    subtitle: true,
                    timeRest: minRestantes,
                    returnTime: pauseSelected.fim,
                    Icon: <IconPieChart />,
                    button: true,
                }}
                id="container"
                onClose={() => handleFinishPause()}
                openModal={modalPause}
            />

            <ModalUser
                infos={{
                    title: 'Até mais,',
                    subtitle: false,
                    timeRest: '08:00',
                    returnTime: '18:00',
                    Icon: <IconPieChart />,
                    button: false,
                }}
                id="container"
                onClose={() => setModalFinish(!modalFinish)}
                openModal={modalFinish}
            />
        </>
    );
}
