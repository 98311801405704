import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    position: relative;
    flex-wrap: wrap;

    .form-children-default {
        margin-right: 16px;
    }
    @media (max-width: 900px) {
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;
    }
    @media (max-width: 425px) {
        justify-content: space-evenly;
        margin-bottom: 10px;
    }
`