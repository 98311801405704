import styled from 'styled-components';

interface InputProps {
    type?: string;
}

export const Container = styled.label<InputProps>`
    border-bottom: 2px solid ${(props) => props.theme.colors.silverSand};
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 21px;

    &:active {
        border: 1px solid ${(props) => props.theme.colors.white};
    }

    &.file {
        border: none;
        color: transparent;
        input::-webkit-file-upload-button {
            visibility: hidden;
        }
        input::before {
            content: 'Busca no computador';
            background: linear-gradient(150deg,${(props) => props.theme.colors.skyblue} 0%,${(props) => props.theme.colors.blueCrayola} 100%);
            border-radius: 4px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 21px;
            color: ${(props) => props.theme.colors.white};
            cursor: pointer;
            border: none;
        }
        transition: all 0.2s;

        &:hover {
            transform: scale(1.05);
            box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
        }
    }

    input {
        height: 100%;
        width: 240px;
        outline: none;
        border: none;
        flex: 1;
        background: transparent;
        color: ${(props) => props.theme.colors.white};
        &::placeholder {
            color: ${(props) => props.theme.colors.silverSand};
        }
    }
    svg {
        margin-right: 10px;
    }
`;
