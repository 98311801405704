import {
    Container,
    Card,
    CardInfo,
    NameGroup,
    InfoLeft,
    InfoRight,
} from './styles';
import { UserEyou } from '../../types/UserEyou';
import LinkLinear from '../form/LinkLinear';
import StatusTable from '../Table/StatusTable';
import { IBloqueio } from '../../types/IBloqueio';
import ButtonLinear from '../form/ButtonLinear';
import { ButtonRemove } from '../../pages/GruposAtendimento/pesquisaSatisfacao/styles';
import { IconClose, IconTrash } from '../icons';

interface ListProps {
    data: IBloqueio;
    removeBloqueio: (id:any) => {}
}

const BloqueioList: React.FC<ListProps> = ({ 
    data = {},
    removeBloqueio = (id:any) => {}
}) => {
    return (
        <Container>
            <Card>
                <CardInfo>
                    <InfoLeft>
                        <NameGroup>
                            <h2>Chave</h2>
                            <span>
                                <b>{data.chave}</b>
                            </span>
                        </NameGroup>
                    </InfoLeft>
                    <InfoLeft>
                        <NameGroup>
                            <h2>Valor</h2>
                            <span>
                                <b>{data.valor}</b>
                            </span>
                        </NameGroup>
                    </InfoLeft>
                    <InfoLeft>
                        <NameGroup>
                            <h2>Descrição</h2>
                            <span>{data.descricao}</span>
                        </NameGroup>
                    </InfoLeft>

                    <InfoLeft>
                        <NameGroup>
                            <h2>Bloqueado por</h2>
                            <span>{data.nome}</span>
                        </NameGroup>
                    </InfoLeft>
                    <InfoRight>
                        <ButtonLinear
                            onClick={() => removeBloqueio(data.id_bloqueio)}
                            Icon={IconTrash}
                            className="delete"
                        />
                    </InfoRight>
                </CardInfo>
            </Card>
        </Container>
    );
};
export default BloqueioList;
