import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .buttons {
        display: flex;
        gap: 10px;

        .success {
            width: 100px;
        }
    }
`;

export const TitleQuestion = styled.div`
    display: flex;
    justify-content: space-between;
    .input-title {
        flex: 1;
        margin-right: 30px;
    }
    h2 {
        font-size: 16px;
        color: ${({theme}) => theme.colors.white};
        margin: 0
    }

    @media (max-width: 425px) {
        overflow-x: scroll;
    }
`

export const ContainerQuestion = styled.div`
    padding-top: 24px;
    animation: fadeIn .5s;
    .rating {
        display: flex;
        > div {
            width: 32px;
            height: 32px;
            border: 1px solid ${props => props.theme.colors.skyblue};
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${props => props.theme.colors.skyblue};
            font-size: 13px;
        }
    }
`

export const ContainerQuestionItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 3px 0px;
    .input-edit {
        flex: 1
    }
    .input-checkbox {
        margin-left: 15px;
    }

    @media (max-width: 425px) {
        .input-checkbox {
            margin-left: 10px;
        }        
    }
`

export const ContainerQuestionRating = styled.div`
    display: flex;    
`

export const ButtonRemove = styled.button`
    display: flex;
    height: 40px;
    align-items: center;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding-left: 10px;
    svg {
        width: 16px;
        path {
            fill: ${props => props.theme.colors.lightCoral}
        }
    }
`
export const ButtonMove = styled.button`
    display: flex;
    height: 40px;
    align-items: center;
    background: transparent;
    border: none;
    outline: none;
    cursor: move;
`

export const ButtonNew = styled.span`
    color: ${props => props.theme.colors.skyblue};
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    svg {
        margin-right: 5px;
        width: 16px;
    }
`

export const ContainerRating = styled.label`
    display: flex;
    align-items: center;
    margin-right: 20px;
    span {
        color: ${props => props.theme.colors.white};
        padding-right: 4px;
    }

    @media (max-width: 425px) {
        width: 150px;
        label {
            width: 90px;
        }
    }
`

export const ContainerConfigsQuestion = styled.div`
    border-top: 1px dotted ${props => props.theme.colors.white};
    margin-top: 24px;
    padding-top: 24px;
    display: flex;
    justify-content: flex-end;
`
export const ButtonConfigQuestion = styled.button`
    height: 30px;
    display: flex;
    align-items: center;
    background: none;
    border: none;
    outline: none;
    margin-left: 24px;
    svg {
        transition: all .2s;
    }
    &:hover {
        svg {
            transform: scale(1.5);
        }
    }
`