import React, { createContext, useContext, useEffect, useState } from 'react';
import { removeElements } from 'react-flow-renderer';
import nextId from 'react-id-generator';
import { IconChat } from '../components/Chat/CardChat/styles';
import { IconAPI, IconChannel, IconChatbot, IconClose, IconMonitor, IconPhoneCall, IconWarning } from '../components/icons';
import apiTropa from '../services/apiTropa';
import { IPropsChatbotElement } from '../types/Chatbots';

interface ChatbotContextData {
    selectedDefault: IPropsChatbotElement;
    elementsDefault: any;
    elements: any[];
    setElements(elements: IPropsChatbotElement[]): void;
    selected: IPropsChatbotElement;
    setSelected(elements: IPropsChatbotElement): void;
    handleOnConnect(params: any): void;
    handleOnMove(element: any): void;
    handleOnRemove(elementsToRemove: any): void;
    handleSetSelectedElementContext(id: any): void;
    handleNewElementAttendance(x?: any, y?: any): void;
    handleNewElementIntegration(x?: any, y?: any): void;
    handleNewElement(x?: any, y?: any): void;
    handleEditElement(selected: IPropsChatbotElement): void;
    handleEditElementName(name: string): void;
    handleNewChatbot(name: string): void;
    handleDeleteChatbot(name: string): void;
    handleLoadChatbot(id: any): any;
    handleEditChatbot(id: any, name: string): void;
    handleResetChatbot(): void;
}

const ChatbotContext = createContext<ChatbotContextData>({} as ChatbotContextData);

export const ChatbotContextProvider: React.FC = ({ children }) => {
    const [chatbot, setChatbot] = useState<any>({});

    const selectedDefault: IPropsChatbotElement = {
        id: null,
        params: {
            nameDialog: 'Diálogo',
            conditions: [],
            messages: [
                {
                    message: '...',
                    type: 'text',
                },
            ],
            messageUser: {
                required: false,
                variable: '',
            },
            horarioExcecao: false,
            finalizarAtendimento: false,
            exception: 2,
            historicoMensagem: {
                url: '',
                method: 'POST',
            },
            middlewareMensagem: {
                url: '',
                method: 'POST',
            },
        },
    };

    const elementsDefault = [
        {
            id: '1',
            type: 'input',
            typeDialog: 'start',
            style: {
                padding: 0,
            },
            data: {
                label: '...',
            },
            position: {
                x: 210,
                y: 120,
            },
            params: {
                nameDialog: 'Início',
                conditions: [
                    {
                        all: [
                            {
                                if: 'respostaUsuario',
                                condition: 'existe',
                            },
                        ],
                        target: 3,
                    },
                ],
                messages: [],
                messageUser: {
                    required: false,
                    variable: null,
                },
                exception: 2,
            },
        },
        {
            id: '2',
            style: {
                padding: 0,
            },
            type: 'input',
            typeDialog: 'end',
            data: { label: '...' },
            position: {
                x: 420,
                y: 120,
            },
            params: {
                nameDialog: 'Exceções',
                conditions: [
                    {
                        all: [
                            {
                                if: 'respostaUsuario',
                                condition: 'existe',
                            },
                        ],
                        target: 4,
                    },
                ],
                messages: [],
                messageUser: {
                    required: false,
                    variable: null,
                },
                exception: 1,
            },
        },
        {
            id: '3',
            style: {
                padding: 0,
            },
            typeDialog: 'normal',
            data: { label: '...' },
            position: {
                x: 210,
                y: 330,
            },
            params: {
                nameDialog: 'Boas vindas',
                conditions: [
                    {
                        all: [
                            {
                                if: 'respostaUsuario',
                                condition: 'existe',
                            },
                        ],
                        target: 5,
                    },
                ],
                messages: [
                    {
                        message: 'Para iniciarmos o atendimento, poderia digitar seu nome?',
                        type: 'text',
                    },
                ],
                messageUser: {
                    required: true,
                    variable: 'nome',
                },
                exception: 2,
            },
        },
        {
            id: '4',
            style: {
                padding: 0,
            },
            data: { label: '...' },
            position: {
                x: 420,
                y: 210,
            },
            params: {
                nameDialog: 'Erro padrão',
                typeDialog: 'normal',
                conditions: [
                    {
                        all: [
                            {
                                if: 'respostaUsuario',
                                condition: 'existe',
                            },
                        ],
                        target: 3,
                    },
                ],
                messages: [
                    {
                        message: 'Desculpe, não consegui entender!',
                        type: 'text',
                    },
                ],
                messageUser: {
                    required: false,
                    variable: null,
                },
                exception: 2,
            },
        },
        {
            id: '5',
            style: {
                padding: 0,
            },
            type: 'output',
            typeDialog: 'attendance',
            data: { label: '...' },
            position: {
                x: 420,
                y: 440,
            },
            params: {
                nameDialog: 'Atendimento Humano',
                conditions: [],
                messages: [
                    {
                        message: 'Aguarde um momento até que um de nossos operadores te atenda.',
                        type: 'text',
                    },
                ],
                messageUser: {
                    required: false,
                    variable: null,
                },
                exception: 1,
            },
        },
        { id: 'e1-3', source: '1', target: '3', arrowHeadType: 'arrowclosed' },
        { id: 'e2-4', source: '2', target: '4', arrowHeadType: 'arrowclosed' },
        { id: 'e4-3', source: '4', target: '3', arrowHeadType: 'arrowclosed' },
        { id: 'e3-5', source: '3', target: '5', arrowHeadType: 'arrowclosed' },
    ];

    const [elements, setElements] = useState<any[]>([]);

    const [selected, setSelected] = useState<IPropsChatbotElement>(selectedDefault);

    const [remap, setRemap] = useState<boolean>(true);

    async function handleLoadChatbot(id: any) {
        try {
            setRemap(true);
            const newSelected: any = selected;
            newSelected.id = null;
            setSelected({ ...newSelected });
            const response: any = await apiTropa.get('chatbot/' + id);
            if (response.error === true) throw 'Erro ao resgatar dados do chatbbot.';
            const newElements: any = RemapElementConext(JSON.parse(response.data.data.estrutura));
            setElements([...newElements]);
            setRemap(false);
            return {
                error: false,
                data: response.data.data,
            };
        } catch (err) {
            return {
                error: true,
            };
        }
    }

    useEffect(() => {
        if (remap === false) {
            const elementsRemap = elements.filter((obj) => obj.data && obj.data.label === '...');
            const elementsRemapArrow = elements.filter((obj) => obj.source);
            const newElements = RemapElementConext(elementsRemap, true);
            elementsRemapArrow.map((row, key) => {
                newElements.push(row);
            });

            if (elementsRemap.length > 0) setElements([...newElements]);
        }
    }, [remap]);

    function RemapElementConext(elementsData: any, label: boolean = false) {
        elementsData.map((row: any, key: any) => {
            if (!elementsData[key].source) {
                elementsData[key].data = {};
                elementsData[key].data.label =
                    label === false ? '...' : ElementContext(row.typeDialog, row.params.nameDialog, row.id);
            } else {
                elementsData[key] = row;
            }
        });

        return elementsData;
    }

    function ElementContext(typeDialog: any, children: any, id: any) {
        const iconTypes: any = {
            start: <IconChatbot />,
            end: <IconClose />,
            normal: <IconChannel />,
            attendance: <IconPhoneCall />,
            integration: <IconAPI />,
        };

        return (
            <div onClick={() => handleSetSelectedElementContext(id)} className={`el-full ${typeDialog}`}>
                 <span className="icon">{iconTypes[typeDialog] ? iconTypes[typeDialog] : <IconChannel />}</span>
                <span className="text">{children}</span>
            </div>
        );
    }

    function handleSetSelectedElementContext(id: any) {
        if (id) {
            const newSelected = elements.filter((obj: any) => obj.id === id);
            const newElements = elements;
            if (newSelected.length) setSelected(newSelected[0]);
        }
    }

    async function handleResetChatbot() {
        try {
            RemapElementConext(elementsDefault);
            return {
                error: false,
                data: elementsDefault,
            };
        } catch (err) {
            console.log('eror', err);
            return {
                error: true,
            };
        }
    }

    async function handleEditChatbot(id: any, name: string) {
        try {
            const finalBloco: any = elements.filter((obj: any) => obj.type === 'output')[0];

            elements[4].params.horarioExcecao = false;

            const response: any = await apiTropa.put('chatbot/' + id, {
                chatbot: name,
                url_middleware: finalBloco.params.middlewareMensagem?.url ?? '',
                estrutura: elements,
                status: 'ativo',
            });
            return {
                error: false,
                response: response.data,
            };
        } catch (err) {
            return {
                error: true,
            };
        }
    }

    function handleNewElement(x: number, y: number) {
        const data = new Date();

        const idElement =
            nextId() +
            '-' +
            data.getDay() +
            '-' +
            data.getSeconds() +
            '-' +
            data.getMilliseconds() +
            '-' +
            data.getMinutes();

        const xRandom = [45, 50, 55, 60, 65, 70, 75, 80, 85, 90];
        const yRandom = [255, 260, 265, 270, 275, 280, 290, 295, 300, 305];

        const randomNumber = Math.floor(Math.random() * 10);

        elements.push({
            id: idElement,
            style: {
                padding: 0,
            },
            typeDialog: 'normal',
            data: {
                label: ElementContext('default', 'Novo diálogo', idElement),
            },
            position: {
                x: x ? x : xRandom[randomNumber],
                y: y ? y : yRandom[randomNumber],
            },
            params: {
                nameDialog: 'Novo diálogo',
                conditions: [],
                messages: [],
                messageUser: {
                    required: true,
                    variable: null,
                },
                exception: 2,
            },
        });

        setElements([...elements]);
    }

    function handleNewElementAttendance(x: number, y: number) {
        const data = new Date();

        const idElement =
            nextId() +
            '-' +
            data.getDay() +
            '-' +
            data.getSeconds() +
            '-' +
            data.getMilliseconds() +
            '-' +
            data.getMinutes();

        const xRandom = [45, 50, 55, 60, 65, 70, 75, 80, 85, 90];
        const yRandom = [255, 260, 265, 270, 275, 280, 290, 295, 300, 305];

        const randomNumber = Math.floor(Math.random() * 10);

        elements.push({
            id: idElement,
            style: {
                padding: 0,
            },
            typeDialog: 'attendance',
            type: 'output',
            data: {
                label: ElementContext('attendance', 'Atendimento', idElement),
            },
            position: {
                x: x ? x : xRandom[randomNumber],
                y: y ? y : yRandom[randomNumber],
            },
            params: {
                nameDialog: 'Atendimento',
                conditions: [],
                messages: [],
                messageUser: {
                    required: true,
                    variable: null,
                },
                exception: 2,
            },
        });

        setElements([...elements]);
    }

    function handleNewElementIntegration(x: number, y: number) {
        const data = new Date();

        const idElement =
            nextId() +
            '-' +
            data.getDay() +
            '-' +
            data.getSeconds() +
            '-' +
            data.getMilliseconds() +
            '-' +
            data.getMinutes();

        const xRandom = [45, 50, 55, 60, 65, 70, 75, 80, 85, 90];
        const yRandom = [255, 260, 265, 270, 275, 280, 290, 295, 300, 305];

        const randomNumber = Math.floor(Math.random() * 10);

        elements.push({
            id: idElement,
            style: {
                padding: 0,
            },
            typeDialog: 'integration',
            data: {
                label: ElementContext('integration', 'Integração', idElement),
            },
            position: {
                x: x ? x : xRandom[randomNumber],
                y: y ? y : yRandom[randomNumber],
            },
            params: {
                nameDialog: 'Integração',
                conditions: [],
                messages: [],
                messageUser: {
                    required: false,
                    variable: null,
                },
                exception: 2,
            },
        });

        setElements([...elements]);
    }

    function handleOnConnect(params: any) {
        try {
            var elementFilter: any = elements.filter((obj) => obj.id === params.source)[0];

            const conditionDefault = {
                all: [
                    {
                        if: 'repostaUsuario',
                        condition: 'existe',
                    },
                ],
                target: params.target,
            };

            params.id = `e${params.source}-${params.target}`;
            params.arrowHeadType = `arrowclosed`;

            if (elements.filter((obj) => obj.id === params.id).length > 0) throw 'Já esta conectado.';

            elementFilter.params.conditions.push(conditionDefault);

            elements.push(params);
            setElements([...elements]);
        } catch (err) {
            console.log(err);
        }
    }

    function handleOnMove(element: IPropsChatbotElement) {
        const newElements: IPropsChatbotElement[] = [];

        elements.map((row: any, key: number) => {
            if (row.id === element.id) {
                row.position.x = element.position?.x;
                row.position.y = element.position?.y;
            }

            newElements.push(row);
        });
        setElements([...newElements]);
    }

    function handleOnRemove(elementsToRemove: any[]) {
        try {
            if (
                selected.typeDialog === 'start' ||
                selected.typeDialog === 'attendance' ||
                selected.typeDialog === 'end'
            )
                throw 'Você não pode remover esse diálogo, ele é obrigatório no fluxo.';

            elementsToRemove.map((row, key) => {
                if (row.id === selected.id) {
                    setSelected(selectedDefault);
                }
            });

            setElements((els: any) => removeElements(elementsToRemove, els));
        } catch (err) {
            alert(err);
        }
    }

    function handleEditElement(selected: IPropsChatbotElement) {
        elements.map((row, key) => {
            if (selected.id && row.id === selected.id) {
                elements[key].id = selected.id;
                elements[key].params = selected.params;
                elements[key].data.label = ElementContext(
                    selected.typeDialog,
                    selected.params?.nameDialog,
                    selected.id
                );
            }
        });

        setElements([...elements]);
    }

    function handleEditElementName(value: string) {
        const newSelected: any = selected;
        newSelected.params.nameDialog = value;
        setSelected({ ...newSelected });
        handleEditElement(newSelected);
    }

    useEffect(() => {
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, [elements]);

    function handleContextMenu(e: any) {
        if (e.target.closest('#chatbot-fluxograma')) {
            e.preventDefault();

            const ElementNode: any = document.getElementsByClassName('react-flow__nodes')[0];
            const translateFlow = ElementNode.style.transform
                .replace('translate(', '')
                .replace(') scale(1)', '')
                .replace(' ', '')
                .split(',');

            var pageX = e.pageX - 437 - parseInt(translateFlow[0]);
            var pageY = e.pageY - 350 - parseInt(translateFlow[1]);

            handleNewElement(pageX, pageY);
        }
    }

    async function handleNewChatbot(name: string) {
        try {
            const newElements: any = [];

            elementsDefault.map((row: any) => {
                row.data = {};
                newElements.push(row);
            });

            const response: any = await apiTropa.post('chatbot', {
                chatbot: name,
                estrutura: JSON.stringify(newElements),
            });

            return {
                error: false,
                id: response.data.data,
            };
        } catch (err: any) {
            return {
                error: true,
                message:
                    'Não foi possivel adicionar no momento, tente novamente mais tarde ou contate um administrador.',
            };
        }
    }

    async function handleDeleteChatbot(id: any) {
        try {
            const response: any = await apiTropa.delete('chatbot/' + id);
            return response.data;
        } catch (err) {
            return {
                error: true,
                message: err,
            };
        }
    }

    return (
        <ChatbotContext.Provider
            value={{
                elementsDefault,
                selectedDefault,
                elements,
                setElements,
                selected,
                setSelected,
                handleSetSelectedElementContext,
                handleNewElementAttendance,
                handleNewElementIntegration,
                handleNewElement,
                handleOnConnect,
                handleOnMove,
                handleOnRemove,
                handleEditElement,
                handleEditElementName,
                handleNewChatbot,
                handleDeleteChatbot,
                handleEditChatbot,
                handleLoadChatbot,
                handleResetChatbot,
            }}
        >
            {children}
        </ChatbotContext.Provider>
    );
};

export function useChatbotContext() {
    const context = useContext(ChatbotContext);
    return context;
}

export default ChatbotContext;
