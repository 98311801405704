import React, { useState } from 'react';
import { IconEye } from '../../icons';

import { Container } from './styles';

interface InputProps {
    label: string;
    value?: string;
    isPassword?: boolean;
    bold?: boolean;
    className?: string;
}

const InputReadOnly: React.FC<InputProps> = ({
    label,
    value,
    isPassword = false,
    bold = false,
    className = '',
}) => {
    const [visiblePassword, setVisiblePassword] = useState(false);

    return (
        <Container bold={bold}>
            <p className={`label ${className}`}>{label}</p>
            <div className="content-value">
                {isPassword === true ? (
                    <>
                        {visiblePassword === false ? (
                            <span className="password">********</span>
                        ) : (
                            <span>{value}</span>
                        )}
                    </>
                ) : (
                    <span>{value}</span>
                )}

                {isPassword && (
                    <button
                        onClick={() => setVisiblePassword(!visiblePassword)}
                    >
                        <IconEye />
                    </button>
                )}
            </div>
        </Container>
    );
};

export default InputReadOnly;
