import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    min-height: 100%;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
`;

export const ContentChat = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    /* display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%; */
    /* padding: 32px; */

    /* background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15); */
    /* border-radius: 8px; */

    /* position: relative; */
    .ButtonCloseChat {
        display: grid;
        place-items: center;

        width: 24px;
        height: 24px;
        border: none;
        background: transparent;

        position: absolute;
        top: 16px;
        right: 16px;
    }
`;

export const HeaderChat = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: 24px;
        font-weight: bold;
        color: #4A7DFF;
        margin-top: 20px;
    }

    p {
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #5A646E;
        margin-top: 16px;
    }

`;

export const LogoChat = styled.div`
    display: grid;
    place-items: center;

    width: 72px;
    height: 72px;
    border-radius: 50%;
    background: #cecece;
    position: relative;
`

export const MainChat = styled.div`
    display: flex;
    flex-direction: column;

    gap: 16px;

    >button {
       margin-top: 32px;
    }
`;

export const TitleQuestion = styled.div`
    display: flex;
    justify-content: space-between;
    .input-title {
        flex: 1;
    }
    h2 {
        font-size: 16px;
        margin: 0;
        padding: 0;
    }
`

export const ContainerQuestionOne = styled.div`
    animation: fadeIn .5s;
    border-bottom: 1px solid ${props => props.theme.colors.lavanderGray};
    margin-bottom: 20px;
    padding-bottom: 20px;
`

export const ContainerQuestion = styled.div`
    animation: fadeIn .5s;
    border-bottom: 1px solid ${props => props.theme.colors.lavanderGray};
    margin-bottom: 20px;
    padding-bottom: 20px;
    .label, textarea{
        color: ${props => props.theme.colors.spaceCadedDark}!important
    }
    /* .rating {
        display: flex;
        > div {
            cursor: pointer;
            width: 32px;
            height: 32px;
            border: 1px solid ${props => props.theme.colors.skyblue};
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${props => props.theme.colors.skyblue};
            font-size: 13px;
            background: transparent;
            &.active{
                background: ${props => props.theme.colors.skyblue};
                color: #fff;
            }
        }
    } */
`

export const ContainerQuestionItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 3px 0px;
    .input-edit {
        flex: 1
    }
    .input-checkbox {
        margin-left: 15px;
    }

    @media (max-width: 425px) {
        .input-checkbox {
            margin-left: 10px;
        }        
    }
`