import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: calc(100vh - 140px);
    display: flex;
    flex-direction: column;
    #chatbot-fluxograma {
        width: 100%;
        height: 100%;
    }
    .head-chatbot {
        .form-children-default.input {
            height: unset !important;
            padding: 10px 0px;
            border: none;
            border-radius: 0px;
            border-bottom: 1px solid rgba(255,255,255,.5);
            margin-bottom: 30px;
            input {
                padding: 0px;
                font-size: 24px;
            }
        }
    }
`

export const ContainerHeader = styled.div`
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    > div {
        display: flex;
    }
    .input {
        width: 300px;
    }
    button {
        margin-right: 10px;
    }
`

export const ContainerTable = styled.div`
    margin-top: 30px;
    .status {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .new {
        display: inline-block;
        margin-left: 0;
        margin-right: 10px;
        margin-bottom: -2px;
        border-radius: 50%;
        border-style: solid;
        border-width: 0.5px;
        border: none;
        height: 15px;
        width: 15px;
        background-color: #FF868B;
    }
    .skeletor {
        margin-bottom: 8px;
    }
`

export const ContainerFlow = styled.div`
    display: flex;
    height: calc(100vh - 100px);
    background: #F5F5F5;
    border-radius: 8px;
    overflow: hidden;
    .react-flow__node {
        animation: fadeIn .5s;
    }
    .el-full {
        padding: 15px 10px;
        border-radius: 5px;
    }
    .react-flow__edges {
        g  path{
            //stroke-width: 3px !important;
            
        }
    }
`

interface IPropsEdit {
    edition: boolean
}

export const ContainerEditFlow = styled.div<IPropsEdit>`
    display: flex;
    
    opacity: ${props => props.edition ? '1' : '0'};
    visibility: ${props => props.edition ? 'visible' : 'hidden'};
    margin-right: ${props => props.edition ? '0px' : '-400px'};
    box-shadow:0px 10px 10px rgba(0,0,0,.1);
    transition: all .5s;
    flex-direction: column;
    justify-content: space-between;
    background: #EEEDE8;
    max-width: 400px;
    min-width: 400px;
`
export const ContainerEditFlowHead = styled.div`
    display: flex;
    padding: 24px;
    background: #F2F2F2;
    justify-content: space-between;
    input {
        outline: none;
        background: transparent;
        border: none;
        width: 100%;
        border-bottom: 1px solid #5A646E;
        font-size: 16px;
        color: #5A646E;
        height: 30px;
        padding: 0px 10px;
    }
    button {
        background: transparent;
        border: none;
        outline: none;
        margin-left: 30px;
        svg {
            path {
                fill: #5A646E
            }
        }
    }
`

export const ContainerButtonsChatbot = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: rgba(0,0,0,0.1);
`

export const ContainerEditFlowMenu = styled.ul`
    background: #FFF;
    display: flex;
    justify-content: space-around;
    list-style: none;
    margin: 0px;
    padding: 0px;
    min-height: 32px;
`

interface IPropsEditMenuLi {
    active: boolean
}
export const ContainerEditFlowMenuLi = styled.li<IPropsEditMenuLi>`
    display: flex;
    font-size: 13px;
    padding: 0px 20px;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #8A99A8;
    border-bottom: 1px solid ${props => props.active ? props.theme.colors.skyblue : 'transparent'};
`

interface IPropsEditSelected {
    selected: 'conteudo' | 'condicoes' | 'integracoes'
}
export const ContainerEditFlowMenuSelected = styled.div<IPropsEditSelected>`
    display: flex;
    box-sizing: border-box;
    height: calc(100% - 100px);
    #conditions-edit {
        display: ${props => props.selected === 'condicoes' ? 'flex' : 'none'};
    }
    #messages-edit {
        display: ${props => props.selected === 'conteudo' ? 'flex' : 'none'};
    }
    #integrations-edit {
        display: ${props => props.selected === 'integracoes' ? 'flex' : 'none'};
    }
`

export const ContainerLoadFlow = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 100px;
        height: 100px;
    }
`