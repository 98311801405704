import styled from 'styled-components';

interface Props {
    value: string;
  }
  
  export const Container = styled.label<Props>`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #FFF;
    border-radius: 0px;
    font-size: 14px;
    line-height: 17px;
    color: #1a1a1a;
    height: unset !important;
    input {
        width: 100%;
        height: 40px;
        padding: 14px 12px;
        background: #fff;
        border-radius: 4px;
        border: 1px solid #949494;
        color: #1A1A1A;
        display: flex;
        align-items: center;
        margin-top: 6px;

        &::placeholder {
            color: #6c6c80;
            font-size: 14px;
        }
    }
  `;
  
  export const Error = styled.div`
    display: flex;
    position: absolute;
    right: 0;
    top: -22px;
    * {
      animation: fadeInUp 0.4s linear;
    }
    span {
      font-size: 12px;
      font-weight: 600;
      color: #c53030;
    }
  `;