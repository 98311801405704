import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ButtonLinear from '../../../components/form/ButtonLinear';
import BackPage from '../../../components/layout/UIElements/BackPage';
import { useRoutes } from '../../../contexts/RoutesContext';
import nextId from 'react-id-generator';

import { Container, Header } from './styles';
import Question from '../../../components/pages/GruposAtendimento/pesquisa-satisfacao/Question';
import apiTropa from '../../../services/apiTropa';
import Loading from '../../../components/Loading/Loading';
import { CardTitle, Content } from '../formularioDeEntrada/styles';

export interface IQuestion {
    id: any;
    title: string;
    type: 'checkbox' | 'rating' | 'radio';
    min?: number;
    max?: number;
    items: IQuestionItem[];
}

export interface IQuestionItem {
    id: any;
    value: string;
    type: 'radio' | 'checkbox' | 'textarea';
}

export default function PesquisaSatisfacao(props: any) {
    const history = useHistory();
    const { setPageName } = useRoutes();
    const id = props.match.params.id;
    const [data, setData] = useState<any>({});
    const [load, setLoad] = useState(true);

    useEffect(() => {
        setPageName('Pesquisa de satisfação');
        async function getData() {
            const response: any = await apiTropa.get('pesquisa-satisfacao/get-by-grupo/' + id);
            const newData = response.data.data[0];
            setData({ ...response.data.data[0] });
            setQuestions([...newData.configuracao]);
            setLoad(false);
        }
        getData();
    }, []);


    interface IForm {
        title: string;
        description: string;
    }

    const [formInfo, setFormInfo] = useState<IForm>({
        title: 'Titulo da pesquisa',
        description: 'descrição',
    });

    const radioDefault: IQuestion = {
        id: nextId(),
        title: 'Pergunta de escolha única',
        type: 'radio',
        items: [
            {
                id: nextId(),
                type: 'radio',
                value: 'Sim',
            },
            {
                id: nextId(),
                type: 'radio',
                value: 'Não',
            },
            {
                id: nextId(),
                type: 'textarea',
                value: 'Pergunta',
            },
        ],
    };

    const checkboxDefault: IQuestion = {
        id: nextId(),
        title: 'Pergunta de escolhas multiplas',
        type: 'checkbox',
        items: [
            {
                id: nextId(),
                type: 'checkbox',
                value: 'Opção 1',
            },
            {
                id: nextId(),
                type: 'checkbox',
                value: 'Opção 2',
            },
            {
                id: nextId(),
                type: 'textarea',
                value: 'Pergunta',
            },
        ],
    };

    const ratingDefault: IQuestion = {
        id: nextId(),
        title: 'Pergunta de avaliação',
        type: 'rating',
        min: 0,
        max: 10,
        items: [],
    };

    const [questions, setQuestions] = useState<IQuestion[]>([]);

    useEffect(() => {
        setQuestions([radioDefault, checkboxDefault, ratingDefault]);
    }, []);

    function handleNewItem(indice: any, type: any) {
        questions[indice].items.push({
            id: nextId(),
            type: type,
            value: 'Opção',
        });
        setQuestions([...questions]);
    }

    function handleRemoveItem(indice: any, idItem: string) {
        questions[indice].items = questions[indice].items.filter((obj) => obj.id !== idItem);
        setQuestions([...questions]);
    }

    function handleChangeItem(indice: any, indiceItem: any, value: string) {
        questions[indice].items[indiceItem].value = value;
        setQuestions([...questions]);
    }

    function handleChangeTypeQuestion(indice: any, type: any) {
        questions[indice].type = type;

        if (type === 'radio') {
            questions[indice].items.map((row, key) => {
                if (row.type === 'checkbox') questions[indice].items[key].type = type;
            });
        }

        if (type === 'checkbox') {
            questions[indice].items.map((row, key) => {
                if (row.type === 'radio') questions[indice].items[key].type = type;
            });
        }

        if (type === 'rating') {
            questions[indice].min = 0;
            questions[indice].max = 10;
        }

        setQuestions([...questions]);
    }

    function handleCloneQuestion(item: IQuestion) {
        if (item.type === 'checkbox') questions.push(checkboxDefault);
        if (item.type === 'radio') questions.push(radioDefault);
        if (item.type === 'rating') questions.push(ratingDefault);

        setQuestions([...questions]);
    }

    function handleRemoveQuestion(id: any) {
        setQuestions([...questions.filter((obj) => obj.id !== id)]);
    }

    function handleMoveQuestionItem(indice: any, items: any) {
        questions[indice].items = items;
        setQuestions([...questions]);
    }

    return (
        <>
            <Loading active={load} />
            <Container>
                <Header>
                    <BackPage />

                    <ButtonLinear
                        className="info"
                        onClick={() => history.push('/grupos-de-atendimento/' + id + '/pesquisa-de-satisfacao/editar')}
                    >
                        Configurar
                    </ButtonLinear>
                </Header>

                <Content>
                    <CardTitle>
                        <h2 style={{color: data.cor_titulo}}>{data.pesquisa_satisfacao}</h2>
                        <p style={{color: data.cor_descricao}}>{data.descricao}</p>
                    </CardTitle>
                    {questions.map((row, key) => (
                        <Question key={key} row={row} />
                    ))}
                </Content>
            </Container>
        </>
    );
}
