import { useEffect, useState } from 'react';
import { IconLoading } from '../../icons';
import { Container } from './styles';

interface ButtonProps {
    loading?: boolean;
    children?: any;
    styles?: any;
    typeButton?: 'text' | 'textIcon' | 'icon';
    type?: 'button' | 'submit'
    Icon?: any;
    onClick?(): void;
}

const ButtonConfig: React.FC<ButtonProps> = ({
    loading = false,
    children,
    styles,
    typeButton = 'text',
    type = 'button',
    Icon = false,
    onClick = () => {},
}) => {
    const [newStyles, setNewStyles] = useState<any>({});

    useEffect(() => {
        setNewStyles({
            width: styles.width,
            height: styles.height,
            borderRadius: styles.borderRadius,
            border: styles.border + ' solid ' + styles.borderColor,
            backgroundColor: styles.backgroundColor,
            color: styles.color,
            textTransform: styles.textTransform,
        });
    }, [styles]);

    return (
        <Container type={type} style={newStyles} onClick={onClick}>
            {Icon && (
                <span className="icon">
                    {Icon}
                </span>
            )}
            {loading ? (
                <span className="children">
                    <IconLoading />
                </span>
            ) : (
                children && (
                    <span
                        style={{ color: newStyles.color }}
                        className="children"
                    >
                        {children}
                    </span>
                )
            )}
        </Container>
    );
};

export default ButtonConfig;
