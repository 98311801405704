import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    .card-table{
        padding: 0;
        > div{
            height: auto;
            padding-bottom: 20px
        }
    }
`;

export const Numberoperators = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(142px, 1fr));
    gap: 16px;
    width: 100%;
`;

export const Separator = styled.div`
    width: 100%;
    border-bottom: 2px solid #312f62;
    margin-top: 24px;
`;

export const PersonList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 16px;

    width: 100%;
    max-height: 140px;
    margin-top: 24px;
    padding: 10px 0;

    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
