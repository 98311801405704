import { Link } from 'react-router-dom';
import { Logo } from '../../../assets/svg/Logo';
import { ContainerSidebar, ContainerSidebarMenuItem } from './styles';
import { Scrollbars } from 'react-custom-scrollbars';
import { useRoutes } from '../../../contexts/RoutesContext';
import BackPage from '../UIElements/BackPage';
import { useState } from 'react';
import { useTheme } from 'styled-components';

interface IMenu {
    to: string;
    onClick?(): void;
    icon: any;
    name: string;
}

interface ISiderbar {
    menus: IMenu[];
    path: string;
}

export default function Sidebar({ menus, path }: ISiderbar) {
    const [ openMenu, setOpenMenu ] = useState<boolean>(false)
    const { rules } = useRoutes();
    const { title } = useTheme()

    return (
        <ContainerSidebar open={openMenu}>
            <div 
                className='menu'
                onClick={() => setOpenMenu(!openMenu)}
            >
                <div />
                <div />
                <div />
            </div>
            <Scrollbars style={{ width: '100%', height: '100%' }}>
                <header>
                    {rules.name === 'visitor' ? (
                        <BackPage />
                    ) : (

                        <Link to="/">
                            <Logo colorEyou={title === 'light' ? '#5A646E': '#fff'} />
                        </Link>
                    )}                    
                </header>

                <nav>
                    <ul>
                        {menus.map(
                            (row, key) =>
                                rules.paths.filter(
                                    (obj: any) => obj.label === row.name
                                ).length > 0 &&  (
                    
                                    <ContainerSidebarMenuItem
                                        key={key}
                                        active={path === row.to ? true : false}
                                        onClick={() => setOpenMenu(false)}
                                    >
                                        <Link
                                            to={row.to}
                                            onClick={
                                                row.onClick
                                                    ? row.onClick
                                                    : () => {}
                                            }
                                        >
                                            <row.icon />
                                            <span>{row.name}</span>
                                        </Link>
                                    </ContainerSidebarMenuItem>
                       
                                )
                        )}
                    </ul>
                </nav>
            </Scrollbars>
        </ContainerSidebar>
    );
}
