import { useEffect, useState } from 'react';

import { CSVLink } from "react-csv"
import { Container } from './styles';
import { IconDownload } from '../../icons';

interface IProps {
    filename: string
    headers: []
    data: []
}

export default function ButtonDownloadAudit({filename, headers, data}:IProps){
    const [headersButton, setHeadersButton] = useState<any>([])
    
    const [dataButton, setDataButton] = useState<any>([])

    useEffect(() => {
        const newHeaders:any = []
        headers.map((row:any) => {
            newHeaders.push({
                label: row.label,
                key: row.value
            })
        })
        setHeadersButton([...newHeaders])
        setDataButton([...data])
    }, [headers, data])

    return (
        <Container>
            <CSVLink separator=';'  filename={filename} headers={headersButton} data={dataButton}>
                <IconDownload/>
            </CSVLink>
        </Container>
    )

}