import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .form-children-default.select {
        border: 1px solid #f2f2f2 !important;
        background: #f2f2f2;
        select {
            background: #f2f2f2;
            box-shadow: none !important;
            color: #5a646e;
        }
        svg {
            path {
                fill: #5a646e;
            }
        }
    }
`;

interface IPropsOverflow {
    minimize: boolean;
}

export const ContainerEditMessages = styled.div<IPropsOverflow>`
    box-sizing: border-box;
    transition: all 0.2s;
    height: ${(props) => (props.minimize ? 'calc(100% - 80px)' : 'calc(100% - 0px)')};
    transition: all 0.2s;
    box-sizing: border-box;
`;
export const ContainerPadding = styled.div`
    padding: 16px;
    box-sizing: border-box;
`;

interface PropsContainerWhiteShadow {
    position?: number;
    messageUserRequired?: boolean;
}
export const ContainerWhiteShadow = styled.div<PropsContainerWhiteShadow>`
    padding: 16px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.09);
    margin-bottom: 10px;
    display: ${(props) => (props.messageUserRequired === false && props.position && props.position >= 0 ? 'none' : 'block')};
    .select {
        margin-top: 5px;
    }
    b {
        font-size: 14px;
        color: #5a646e;
    }
    p {
        font-size: 14px;
        color: #5a646e;
        margin: 10px 0px 0px 0px;
    }
    .label {
        color: #5a646e !important;
    }
    .info {
        font-size: 12px;
    }
    input,
    textarea {
        height: 40px;
        background: #f2f2f2;
        border-radius: 4px;
        width: 100%;
        border: none;
        outline: none;
        padding: 10px 20px;
        color: #5a646e;
    }
    textarea {
        min-height: 100px;
        padding: 20px;
        min-width: 100%;
        max-width: 100%;
    }
`;

export const ContainerMessages = styled.div``;

interface IPropsMenuNewMessage {
    active?: boolean;
}
export const ContainerMenuNewMessages = styled.nav<IPropsMenuNewMessage>`
    margin-top: 15px;
    position: relative;
    width: 100%;
    z-index: 999;
`;
export const ContainerMenuNewMessagesButton = styled.button<IPropsMenuNewMessage>`
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    font-size: 16px;
    color: #5a646e;
    border: none;
    outline: none;
    background: transparent;
    svg {
        transition: all 0.2s;
        margin-left: 16px;
        transform: ${(props) => (props.active ? 'rotate(135deg)' : 'rotate(0deg)')};
        circle {
            transition: all 0.2s;
            fill: ${(props) => (!props.active ? props.theme.colors.skyblue : '#FFF')};
        }
        path {
            transition: all 0.2s;
            fill: ${(props) => (props.active ? props.theme.colors.skyblue : '#FFF')};
        }
    }
`;
export const ContainerMenuNewMessagesMenu = styled.ul<IPropsMenuNewMessage>`
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    list-style: none;
    margin: 0px;
    width: 180px;
    right: 0px;
    position: absolute;
    padding: 0px 19px;
    transition: all 0.2s;
    margin-top: ${(props) => (props.active === true ? '8px' : '-8px')};
    opacity: ${(props) => (props.active === true ? '1' : '0')};
    visibility: ${(props) => (props.active === true ? 'visiblity' : 'hidden')};
`;
export const ContainerMenuNewMessagesMenuItem = styled.li`
    border-bottom: 1px solid #eaeef3;
    padding: 16px 0px;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #5a646e;
    cursor: pointer;
    &:hover {
        font-weight: 600;
    }
    svg {
        margin-right: 8px;
    }
`;

export const ContainerListMessages = styled.div`
    margin-top: 20px;
`;

export const ContainerMessage = styled.div`
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    animation: fadeInRight 0.5s;
`;
export const ContainerMessageText = styled.div`
    display: flex;
    align-items: flex-end;
    max-width: 100%;
    > div {
        min-height: 40px;
        border-radius: 10px;
        background: ${(props) => props.theme.colors.blueCrayola};
        padding: 12px 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow: auto;
        font-size: 14px;
        color: #fff;
        img,
        video,
        audio {
            width: 200px;
            min-height: 50px;
        }
        &.arquivo {
            a {
                background: red;
                display: flex;
                align-items: center;
                background: rgba(0, 0, 0, 0.3);
                border-radius: 5px;
                padding: 5px;
                text-decoration: none;
                color: #fff;
                span {
                    padding-right: 5px;
                }
                svg {
                    margin-right: 5px;
                    color: #fff;
                    margin-bottom: 0px;
                    * {
                        fill: #fff;
                    }
                }
            }
        }
    }
    svg {
        margin-bottom: 8px;
        min-width: 8px;
    }
`;
export const ContainerMessageButtons = styled.div`
    display: flex;
    margin-top: 10px;
    button {
        width: 44px;
        height: 44px;
        border-radius: 10px;
        border: none;
        margin-left: 10px;
        transition: all 0.2s;
        &:hover {
            transform: scale(1.1);
        }
        &.info {
            background: ${(props) => props.theme.colors.skyblue};
        }
        &.danger {
            background: ${(props) => props.theme.colors.lightCoral};
        }
    }
    path {
        fill: #fff;
    }
`;

export const ContainerEditMessage = styled.div`
    width: 100%;
    min-height: 170px;
    background: #fff;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    z-index: 99999;
    button.close {
        display: flex;
        background: transparent;
        border: none;
        margin-left: 10px;
    }
    .full-edit {
        flex: 1;
        .text {
            display: flex;
            align-items: center;
            width: 100%;
            #input {
                display: flex;
                flex-direction: column;
                width: 100%;
                min-height: 48px;
                padding: 0px 10px;
                box-sizing: border-box;
                margin: 15px 10px;
                background: #eaeef3;
                border-radius: 10px;
                max-height: 100px;
                overflow: auto;
                padding: 10px;
                font-size: 14px !important;
                * {
                    font-size: 14px !important;
                }
            }
        }
        .upload-info {
            display: flex;
            flex-direction: column;
            input {
            }
            p {
                font-size: 13px;
                margin-bottom: 10px;
            }
        }
    }
    .close {
        path {
            fill: #5a646e;
        }
    }
`;

export const ContainerEditableMessage = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0px 16px;
    box-sizing: border-box;
    height: 100%;
`;

interface IPropsModalEmojis {
    active: boolean;
}
export const ContainerEmojis = styled.div<IPropsModalEmojis>`
    width: 100%;
    position: absolute;
    top: calc(0px - 200px);
    background: #fff;
    box-sizing: border-box;
    padding: 15px 10px;
    height: 200px;
    visibility: ${(props) => (props.active ? 'visibility' : 'hidden')};
    opacity: ${(props) => (props.active ? '1' : '0')};
    transition: all 0.2s;
    overflow: auto;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.04);
    button {
        font-size: 16px;
        padding: 4px;
    }
    button {
        display: inline-block;
    }
`;

export const ContainerConditions = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ContainerConditionAll = styled.div``;

export const ContainerConditionAllAnd = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    span {
        background: #b6b6c5;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 14px;
        width: 28px;
        height: 28px;
        min-width: 28px;
        margin: 0px 8px;
    }
    &:after,
    &:before {
        width: 100%;
        height: 1px;
        background: #c5ced7;
        content: '';
    }
    svg {
        width: 28px;
        height: 28px;
        min-width: 28px;
        margin: 0px 8px;
        cursor: pointer;
        transition: all 0.2s;
    }
    &:hover {
        svg {
            transform: scale(1.2);
        }
    }
`;

export const ContainerConditionAllIf = styled.div``;

export const ContainerConditionAllIfItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .form-children-default,
    input {
        margin-bottom: 5px;
    }
`;
interface ConditionProps {
    condition?: string;
}
export const ContainerIfAndVariable = styled.div<ConditionProps>`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 10px;
    max-width: 100%;
    .select {
        width: ${(props) => (props.condition === 'variavel' ? '150px' : '100%')};
        transition: all ${(props) => (props.condition === 'variavel' ? '0' : '0.5s')};
    }
    input {
        min-width: 170px;
    }
`;

export const ContainerInputConditionTarget = styled.div`
    display: flex;
    flex-direction: column;
    input {
        height: 38px;
        background: #f2f2f2;
    }
    .list {
        margin-top: 10px;
        .tag {
            background: ${(props) => props.theme.colors.blueCrayola};
            padding: 5px 10px;
            border-radius: 100px;
            display: inline-block;
            color: #fff;
            margin-right: 5px;
            margin-bottom: 5px;
        }
    }
`;

export const ButtonTransparent = styled.button`
    background: transparent;
    border: none;
    padding: 10px 0px;
    transition: all 0.2s;
    &:hover {
        transform: scale(1.3);
    }
`;

export const ContainerButtonDeleteCondition = styled.div`
    width: 100%;
    display: flex;
    margin-top: 20px;
    > button {
        width: 100%;
    }
`;

export const FieldIntegration = styled.div`
    padding: 10px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    .select {
        margin-top: 0px;
    }
`;

export const FieldIntegrationLabel = styled.span`
    font-size: 13px;
    color: #5a646e;
    padding-bottom: 5px;
`;
