import styled from 'styled-components';

export const OverlayModal = styled.div`
    background: #fff;
    opacity: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;    
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
`;

export const LoadingTitle = styled.h1`
    font-size: 24px;
    font-weight: bold;
    color: ${props => props.theme.colors.blueCrayola};
`;

export const LoadingSubtitle = styled.span`
    font-size: 16px;
    line-height: 150%;
    color: #5A646E;
    margin-top: 24px;
`;


export const ContentIcone = styled.div`
    display: grid;
    place-items: center;

    width: 186px;
    height: 186px;

    background: #cecece;
    margin-top: 40px;
`;
