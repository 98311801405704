import React, { createContext, useContext, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import ButtonLinear from '../components/form/ButtonLinear';
import FieldGroup from '../components/layout/containers/FieldGroup';
import MessageAlert from '../components/messageAlert';
import ModalDefault from '../components/ModalDefault';

interface IMessage {
    type: 'success' | 'warning' | 'danger' | 'info';
    title: string;
    message: string;
    visible: boolean;
}

interface ConfirmContextData {
    title?: string;
    description?: string;
    textButtonConfirm?: string;
    hiddenButtonConfirm?: boolean;
    textButtonCancel?: string;
    hiddenButtonCancel?: boolean;
    onConfirm?: any;
    onCancel?: any;
    className?:
        | 'success'
        | 'info'
        | 'warning'
        | 'danger'
        | 'delete'
        | 'transparent'
        | 'dashed'
        | 'config';
}

interface MessageContextData {
    addSuccess(title: string, message: string): void;
    addWarning(title: string, message: string): void;
    addDanger(title: string, message: string): void;
    addInfo(title: string, message: string): void;
    addConfirm({}: ConfirmContextData): void;
}

const MessageContext = createContext<MessageContextData>(
    {} as MessageContextData
);

export const MessageProvider: React.FC = ({ children }) => {
    const [message, setMessage] = useState<IMessage>({} as IMessage);
    const {title} = useTheme()
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [confirm, setConfirm] = useState<ConfirmContextData>(
        {} as ConfirmContextData
    );

    function addConfirm({
        title = '',
        description = '',
        textButtonConfirm = 'Confirmar',
        textButtonCancel = 'Cancelar',
        onConfirm = () => {},
        onCancel = () => {},
        hiddenButtonCancel = false,
        hiddenButtonConfirm = false,
        className = 'config',
    }) {
        setConfirmVisible(true);
        setConfirm({
            title,
            description,
            textButtonCancel,
            textButtonConfirm,
            onCancel,
            onConfirm,
            hiddenButtonCancel,
            hiddenButtonConfirm,
            // className,
        });
    }

    function addSuccess(title: string, message: string) {
        setMessage({
            type: 'success',
            title,
            message,
            visible: true,
        });
    }

    function addWarning(title: string, message: string) {
        setMessage({
            type: 'warning',
            title,
            message,
            visible: true,
        });
    }

    function addDanger(title: string, message: string) {
        setMessage({
            type: 'danger',
            title,
            message,
            visible: true,
        });
    }

    function addInfo(title: string, message: string) {
        setMessage({
            type: 'info',
            title,
            message,
            visible: true,
        });
    }

    function closeMessage() {
        setMessage({
            title: message.title,
            message: message.message,
            type: message.type,
            visible: false,
        });
    }

    function ConfirmSuccess() {
        setConfirmVisible(false);
        confirm.onConfirm();
    }

    function ConfirmCancel() {
        setConfirmVisible(false);
        confirm.onCancel();
    }

    const ContainerConfirm = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 24px 0px;
        position: relative;
        width: 100%;
        .title {
            font-size: 24px;
            color: #ff868b;
        }
        .description {
            margin-top: 10px;
            color: ${({theme}) => theme.colors.subTitle};
            font-size: 14px;

            text-align: center;
            margin-top: 24px;
            line-height: 150%;
        }
        .close {
            position: absolute;
            top: 0px;
            right: 0px;
            margin: 0px;
            background: transparent;
            border: none;
            outline: none;
            cursor: pointer;

            svg {
                path {
                    fill: ${({theme}) => theme.title === 'light' ? '#ff868b' : '#fff'};
                }
            }
        }
    `;

    return (
        <MessageContext.Provider
            value={{ addSuccess, addWarning, addDanger, addInfo, addConfirm }}
        >
            <ModalDefault
                openModal={confirmVisible}
                onClose={() => ConfirmCancel}
                id="confirm-modal"
            >
                <ContainerConfirm>

                    { !confirm.hiddenButtonCancel && 
                        <button className="close" onClick={ConfirmCancel}>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M4.29289 4.29304C4.68342 3.90252 5.31658 3.90252 5.70711 4.29304L19.7071 18.293C20.0976 18.6836 20.0976 19.3167 19.7071 19.7073C19.3166 20.0978 18.6834 20.0978 18.2929 19.7073L4.29289 5.70726C3.90237 5.31673 3.90237 4.68357 4.29289 4.29304Z"
                                    fill="white"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M19.7071 4.29304C19.3166 3.90252 18.6834 3.90252 18.2929 4.29304L4.29289 18.293C3.90237 18.6836 3.90237 19.3167 4.29289 19.7073C4.68342 20.0978 5.31658 20.0978 5.70711 19.7073L19.7071 5.70726C20.0976 5.31673 20.0976 4.68357 19.7071 4.29304Z"
                                    fill="white"
                                />
                            </svg>
                        </button>
                    }
                    <p className="title">{confirm.title}</p>
                    <p className="description">{confirm.description}</p>
                </ContainerConfirm>

                <FieldGroup>
                    {confirm.hiddenButtonCancel === false && (
                        <ButtonLinear
                            onClick={ConfirmCancel}
                        >
                            {confirm.textButtonCancel}
                        </ButtonLinear>
                    )}

                    {confirm.hiddenButtonConfirm === false && (
                        <ButtonLinear
                            className={confirm.className}
                            onClick={ConfirmSuccess}
                        >
                            {confirm.textButtonConfirm}
                        </ButtonLinear>
                    )}
                </FieldGroup>
            </ModalDefault>

            {children}

            <MessageAlert message={message} closeMessage={closeMessage} />
        </MessageContext.Provider>
    );
};

export function useMessage() {
    const context = useContext(MessageContext);
    return context;
}

export default MessageContext;
