import styled, { css } from "styled-components";

interface TableProps {
    visible?: boolean;
    isArrow?: boolean;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    
`

export const CardTable = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    padding: 0 24px;
    border-radius: 16px;
    background: ${props => props.theme.colors.spaceCadedDark};

`

export const CardInfoTable = styled.div<TableProps>`
    display: grid;
    align-items: center;
    gap: 20px;

    width: 100%;
    height: 85px;

    /* border-radius: 16px; */
    background: ${props => props.theme.colors.spaceCadedDark};

    
    position: relative;
    overflow: auto;
    &::-webkit-scrollbar {
        display: none;
    }
    
    @media (max-width: 900px) {
        padding-right: 30px;
    }   
    @media (max-width: 425px) {
        gap: 10px;
        overflow-x: auto;
    }   

    ${props => props.isArrow && css`
        padding-right: 60px;
    `}
`

export const ButtonArrow = styled.button<TableProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    
    position: absolute;
    right: 0;    
    
    background: transparent;
    border: none;
    outline: transparent;

    width: 24px;
    height: 24px;

    cursor: pointer;
    transition: all 0.3s;
    transform: ${props => props.visible ? 'rotate(180deg)' : 'rotate(0deg)'};

    @media (max-width: 425px) {
        margin-right: -20px;
    }
`

export const ContainerDropDowwn = styled.div`
    width: 100%;
    border-top: 1px solid rgba(255,255,255,.1);
    padding-top: 20px;
`