import React from 'react';
import { Buffer } from 'buffer';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { IconLoading } from '../components/icons';
import { useRoutes } from '../contexts/RoutesContext';
import PageChat from '../pages/PageChat';
import OperatorRoutes from './OperatorRoutes';
import Loading from '../components/Loading/Loading';

//types routes
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import PageChatIp from '../pages/PageChat/ip';
import PageChatExample from '../pages/PageChat/example';
import PageChatSMS from '../pages/PageChat/SMS';

interface IRoutes {}

const ContainerLoading = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 200px;
        height: 200px;
        border-radius: 100px;
        animation: zoomIn 0.5s;
        -webkit-filter: drop-shadow(
            0px 0px 20px ${(props) => props.theme.colors.skyblue}
        );
        filter: drop-shadow(
            0px 0px 20px ${(props) => props.theme.colors.skyblue}
        );
        path {
            fill: ${(props) => props.theme.colors.skyblue};
        }
    }
`;

const Routes: React.FC<IRoutes> = () => {
    const { signed, rules } = useRoutes();

    return (
        <>
            <Router>
                <Switch>
                    <Route
                        path="/chat-example/:token"
                        exact
                        component={PageChatExample}
                    />
                    <Route path="/chat" exact component={PageChat} />
                    <Route path="/chat/:token" exact component={PageChat} />
                    <Route path="/chat-sms/:id_atendimento/:tag" exact component={PageChatSMS} />
                    <Route path="/chat-ip/:token" exact component={PageChatIp} />
                    <Route path="/chat-example/:token" exact component={PageChatExample}/>
                    <Route path="/chat-testing/:token" exact component={PageChatExample}/>

                    {signed === false && <PublicRoutes />}

                    {signed === true && rules.name === 'visitor' && (
                        <Loading active={true} />
                    )}
                    {signed === true && rules.name === 'operador' && (
                        <OperatorRoutes />
                    )}
                    {signed === true && rules.name === 'administrador' && (
                        <PrivateRoutes />
                    )}
                </Switch>
            </Router>
        </>
    );
};

export default Routes;
