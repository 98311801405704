import styled from 'styled-components';

interface Props {
  openModal: boolean
}

export const Container = styled.div<Props>`
  background: ${props => props.theme.colors.spaceCaded};
  opacity: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: ${props => props.openModal ? 'flex' : 'none'};
  flex-direction: column;
  padding: 32px;
  z-index: 100;

`;


export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  margin-top: 40px;
  border-radius: 16px;

  button {
    margin-top: 40px;
    width: 240px;
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  .image {
      width: 48px;
      height: 48px;
      border-radius: 100px;
      background-size: cover;
    }
`;

export const Perfil = styled.div`
    display: flex;
    flex-direction: column;

    h2 {
      font-size: 16px;
      font-weight: normal;
      line-height: 150%;
      color: ${props => props.theme.colors.white};
    }

    span {
      font-size: 12px;
      font-weight: 500;
      color: ${(props) => props.theme.colors.lavanderGray};
    }

      .active {
        display: flex;
        align-items: center;
        position: relative;
        margin-left: 14px;
        font-size: 14px;
        color: ${(props) => props.theme.colors.lavanderGray};

        &::before {
            content: '';
            position: absolute;
            left: -14px;
            width: 8px;
            height: 8px;
            background: #FF868B;
            border-radius: 50%;
            display: flex;
        }
      }
`;

export const SectionIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('/coffeBreak.png') 0px 190px;
  width: 372px;
  height: 100px;
`;

export const SectionInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;

  h1 {
    font-size: 32px;
    font-weight: 500;
    color: ${props => props.theme.colors.skyblue};

    span {
      font-size: 32px;
      font-weight: 500;
      color: ${props => props.theme.colors.white};
    }
  }

  span {
    font-size: 16px;
    font-weight: normal;
    color: ${props => props.theme.colors.white};
    margin-top: 34px;
  }

`;

export const Temp = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 64px;
  margin-top: 40px;
`;

export const TempLeft = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 16px;
    font-weight: normal;
    color: ${props => props.theme.colors.white};
  }

  span {
    font-size: 24px;
    font-weight: bold;
    color: ${props => props.theme.colors.white};
    margin-top: 9px;
  }
`;

export const TempRight = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 16px;
    font-weight: normal;
    color: ${props => props.theme.colors.white};
  }

  span {
    font-size: 24px;
    font-weight: bold;
    color: ${props => props.theme.colors.lightCoral};
    margin-top: 9px;
  }
`
;