import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    .skeletor {
        margin-bottom: 8px;
    }
    .dados-entrada {
        padding: 0px;
        margin-bottom: 20px;
        h3 {
            margin-bottom: 10px;
            color:${({theme}) => theme.colors.subTitle};
            font-weight: 500;
            opacity: .5;
        }
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                color: ${({theme}) => theme.colors.subTitle};
                font-size: 12px;
                margin: 5px 0px;
            }
        }
    }
`

export const ListTable = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
    @media (max-width: 425px) {
        .card-table {
            div {
                button {
                    margin-right: -30px;
                    margin-top: -20px;
                }            
            }  
        }
    }
`

export const FilterType = styled.div`
    margin-top: 20px;
    border-bottom: 3px solid rgba(255,255,255,0.5);
    button {
        background: transparent;
        border: none;
        color: ${({theme}) => theme.colors.subTitle};
        padding: 20px 20px;
        font-size: 14px;
        border-bottom: 3px solid transparent;
        margin-bottom: -3px;
        &.active {
            border-bottom-color:${(props) => props.theme.colors.skyblue}
        }
    }
`

export const ModalCarona = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.9);
    padding: 40px;
    box-sizing: border-box;
    z-index: 99;
    display: flex;
    flex-direction: column;
    .header {
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;
        box-sizing: border-box;
        h2 {
            color: #FFF;
            font-weight: 400;
        }
        button {
            background: transparent;
            border: none;
            outline: none;
            cursor: pointer;
        }
    }
    .display {
        height: 100%;
    }
`