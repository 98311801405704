import React from 'react';
import { useHistory } from 'react-router';
import { IconArrowLeft } from '../../icons';

import { Container } from './styles';

const BackPage: React.FC = () => {
    const history = useHistory();

    return (
        <Container onClick={() => history.goBack()}>
            <IconArrowLeft />
            <span>voltar</span>
        </Container>
    );
};

export default BackPage;
