import styled from "styled-components"

interface Buttonprops {
    rounded?: string
}

export const Container = styled.button<Buttonprops>`
   border-radius: ${props => props.rounded ? '100px' : '4px'} ;
   height: 40px;
   border: none;
   background: transparent;
   color: #FFF;
   outline: none;
   padding: 0px 12px 0px 12px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   transition: all .2s;
   .icon {
       margin-right: 4px;
       margin-left: 4px;
       display: flex;
   }
   .children {
       font-size: 16px;
       margin-left: 4px;
       margin-right: 4px;
   }
   &:hover {
       transform: scale(1.05);
       box-shadow:0px 10px 10px rgba(0,0,0,.3)
   }
`