import React, { useEffect, useState } from 'react';
import { IconSearch } from '../../icons';
import InputMask from 'react-input-mask';

import { Container } from './styles';

interface InputProps extends React.HTMLAttributes<HTMLInputElement> {
    placeholder?: string;
    value: string | any;
    type?: 'datetime-local' | 'text' | 'date' | 'password' | 'email' | 'tel' | 'file' | 'number';
    mask?: string;
    onChangeText: (value: string | any) => void;
    search?: boolean;
    className?: any;
    title?: string;
    disabled?: boolean;
    required?: boolean;
}

const InputDefault: React.FC<InputProps> = ({
    placeholder,
    value,
    mask = '',
    onChangeText,
    type,
    search = false,
    title = null,
    className = '',
    disabled = false,
    required,
    ...rest
}) => {
    const [valueInput, setValueInput] = useState<string>(value);

    useEffect(() => {
        setValueInput(value);
    }, [value]);

    function handleOnChange(e: React.FormEvent<HTMLInputElement>) {
        setValueInput(e.currentTarget.value);
        onChangeText(e.currentTarget.value);
    }

    return (
        <Container className={`form-children-default input ${className}`}>
            {search && <IconSearch />}
            {title && <p className="title">{title}</p>}
            <InputMask
                type={type}
                mask={mask}
                // maskPlaceholder=""
                value={valueInput}
                onChange={handleOnChange}
                placeholder={placeholder}
                disabled={disabled}
                required={required}
                {...rest}
            />
        </Container>
    );
};

export default InputDefault;
