import styled from 'styled-components';


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    background: ${props => props.theme.colors.spaceCadedDark};
    border-radius: 16px;
    padding: 24px;

    width: 100%;
    height: 207px;
    
    svg{
      path{fill: #34D5FA}
    }
  `;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    
    width: 124px;
    
    span {
      font-size: 14px;
      line-height: 150%;
      font-weight: bold;
      color: ${props => props.theme.colors.white};
    }
`

export const ButtonConfig = styled.div`
  display: flex;
  align-items: flex-start;

  width: 124px;

  button {
    width: 100%;
  }
`
