import React, { useState } from 'react';
import { Container } from './styles';
import {
    LineChart,
    Line,
    XAxis,
    Tooltip,
    ResponsiveContainer,
    YAxis,
} from 'recharts';

export default function ChartEstatistico({content = [], submited = false, grupos = []}:any) {
    const [dataChart, setDataChart] = useState<any>([]);

    const data = content

    function gerar_cor(opacidade = 1) {
        let r = Math.random() * 255;
        let g = Math.random() * 255;
        let b = Math.random() * 255;
        
        return `rgba(${r}, ${g}, ${b}, ${opacidade})`;
     }

    return (

        <>
        <Container style={{display: submited ? 'block' : 'none'}}>


            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    data={data}
                    margin={{
                        left: 20,
                        top: 10,
                        right: 10
                    }}
                >
                    <XAxis dataKey="name" />
                    <YAxis/>
                    <Tooltip />
                    { grupos.map((row:any, key:any) =>
                        <Line
                            type="monotone"
                            dataKey={row.label}
                            stroke={gerar_cor(1)}
                            activeDot={{ r: 8 }}
                        />
                    )}
                </LineChart>
            </ResponsiveContainer>
        </Container>
        </>
    );
}
