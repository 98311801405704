import styled from "styled-components";

export const TagContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 30px;
    width: fit-content;
    height: 35px;

    background: ${(props) => props.theme.colors.cadetGrey};
    color: ${(props) => props.theme.colors.white};

    font-size: 14px;
    cursor: pointer;
    margin-bottom: 15px;
    padding: 15px 10px;    
`