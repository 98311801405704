import styled from "styled-components";

export const Container = styled.div`
    padding: 40px 40px 30px 0px;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top: 20px;
    width: 100%;
    height: 400px;
    text {
        fill: ${(props) => props.theme.colors.white} !important;
    }
`