import { useEffect, useState } from 'react';
import { Buffer } from 'buffer';
import {
    IconArrowLeft,
    IconMail,
    IconPhone,
    IconQuestion,
} from '../../components/icons';
import { Logo } from '../../assets/svg/Logo';
import InputLogin from '../../components/form/InputLogin';
import ButtonDefault from '../../components/form/ButtonDefault';
import { Container } from './styles';
import { useRoutes } from '../../contexts/RoutesContext';
import { useMessage } from '../../contexts/MessagesContext';
import apiEyou from '../../services/apiEyou';
import { Token } from '../../types/Token';
import { useUser } from '../../contexts/UserContext';
import apiTropa from '../../services/apiTropa';
import { useLocation } from 'react-router-dom';

export default function Login() {
    const { setLogged } = useRoutes();
    const { addDanger, addSuccess } = useMessage();
    const { setUserEyou, setIat } = useUser();
    const [loading, setLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('')
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showEffect, setShowEffects] = useState(false);
    const [tab, setTab] = useState<string>('login')
    const search = useLocation().search;
    const codeToken = new URLSearchParams(search).get('code');

    useEffect(() => {
        setTimeout(() => {
            setShowEffects(true);
        }, 300);
    }, [showEffect]);

    useEffect(() => {
        if(!!codeToken) {
            getPreLogin()
        }
    }, [])

    async function getPreLogin(){
        try{
        let validarApi: any = await apiEyou.post(
            '/apiOmni',
            {
                type: 'token',
                function: 'getPayload',
            },
            {
                headers: {
                    Authorization: 'Bearer ' + codeToken,
                },
            }
        );

        let tokenDecrypt: any = await apiEyou.post(
            '/apiOmni',
            {
                type: 'get',
                function: 'aesDecrypt',
                data: {
                    toDecrypt: JSON.stringify(
                        validarApi.data.data.user.idC
                    ),
                },
            },
            {
                headers: {
                    Authorization: 'Bearer ' + codeToken,
                },
            }
        );

        let tokenDecryptUser: any = await apiEyou.post(
            '/apiOmni',
            {
                type: 'get',
                function: 'aesDecrypt',
                data: {
                    toDecrypt: JSON.stringify(
                        validarApi.data.data.user.idU
                    ),
                },
            },
            {
                headers: {
                    Authorization: 'Bearer ' + codeToken,
                },
            }
        );

        validarApi.data.data.user.idU = tokenDecryptUser.data.data.decrypt;
        validarApi.data.data.user.idC = tokenDecrypt.data.data.decrypt;

        let dadosUsuario: any = await apiEyou.post(
            'apiUsuario',
            {
                type: 'get',
                function: 'listAllUsers',
                data: {
                    filter: true,
                    login: username,
                },
            },
            {
                headers: {
                    Authorization: 'Bearer ' + codeToken,
                },
            }
        );

        let buff = Buffer.from(
            JSON.stringify(validarApi.data.data),
            'ascii'
        );
        let text = buff.toString('base64');

        setUserEyou(dadosUsuario.data.data[0]);
        setIat(validarApi.data.data.iat);

        await apiTropa.put('perfil/token', {
            hash: text,
            hash_init: codeToken,
        }, {
            headers: {
                'Authorization': 'Bearer ' + text,
            }
        })

        setLogged(text, codeToken);
        // console.log(text, tokenGerado)
        window.location.reload();
    } catch (err) {
        console.log(err);
        addDanger('Falha no Login', 'Usuario ou senha inválidos');
        setLoading(false);
    }
    }

    async function handleSubmit(e: React.FormEvent) {
        try {
            e.preventDefault();
            setLoading(true);

            let formatoToken = Buffer.from(username + ':' + password, 'ascii');
            let token = formatoToken.toString('base64');

            let resultLogin: any = await apiEyou.get('/token', {
                headers: {
                    Authorization: 'Basic ' + token, //formato para autenticar na api
                },
            });
            
            let reirectUrl = resultLogin.data.redirectUrl
			
			if ( reirectUrl ){
				window.location.href = reirectUrl
			}

            let tokenGerado: any = { token: resultLogin.data.token };

            let tokenEyou: Token = { token: tokenGerado.token };

            let validarApi: any = await apiEyou.post(
                '/apiOmni',
                {
                    type: 'token',
                    function: 'getPayload',
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + tokenEyou.token,
                    },
                }
            );

            let tokenDecrypt: any = await apiEyou.post(
                '/apiOmni',
                {
                    type: 'get',
                    function: 'aesDecrypt',
                    data: {
                        toDecrypt: JSON.stringify(
                            validarApi.data.data.user.idC
                        ),
                    },
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + tokenEyou.token,
                    },
                }
            );

            let tokenDecryptUser: any = await apiEyou.post(
                '/apiOmni',
                {
                    type: 'get',
                    function: 'aesDecrypt',
                    data: {
                        toDecrypt: JSON.stringify(
                            validarApi.data.data.user.idU
                        ),
                    },
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + tokenEyou.token,
                    },
                }
            );

            validarApi.data.data.user.idU = tokenDecryptUser.data.data.decrypt;
            validarApi.data.data.user.idC = tokenDecrypt.data.data.decrypt;

            let dadosUsuario: any = await apiEyou.post(
                'apiUsuario',
                {
                    type: 'get',
                    function: 'listAllUsers',
                    data: {
                        filter: true,
                        login: username,
                    },
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + tokenEyou.token,
                    },
                }
            );

            let buff = Buffer.from(
                JSON.stringify(validarApi.data.data),
                'ascii'
            );
            let text = buff.toString('base64');

            setUserEyou(dadosUsuario.data.data[0]);
            setIat(validarApi.data.data.iat);

            await apiTropa.put('perfil/token', {
                hash: text,
                hash_init: tokenGerado.token,
            }, {
                headers: {
                    'Authorization': 'Bearer ' + text,
                }
            })

            setLogged(text, tokenGerado.token);
            // console.log(text, tokenGerado)
            window.location.reload();
        } catch (err) {
            console.log(err);
            addDanger('Falha no Login', 'Usuario ou senha inválidos');
            setLoading(false);
        }
    }

    async function handleSubmitForget(e:any)
    {
        try {
            
            e.preventDefault()

            if ( !email ) throw "Preencha o e-mail antes de prosseguir."

            setLoading(true)

            const response:any = await apiEyou.post('apiRecoveryPass', {
                type: 'get',
                function: 'getUserData',
                data: {
                    login: email
                }
            })

            if ( response.data.data.length && response.data.data[0].error === true ) throw "Não foi encontrada nenhuma conta com esse e-mail."
            
            const responseSendEmail:any = await apiEyou.post('apiRecoveryPass', {
                type: 'get',
                function: 'getLinkRecover',
                data: {
                    login: email,
                    email: response.data.data[0].email,
                    endPoint: 'https://omni.eyou.com.br/login/recuperar-senha'
                }
            })

            addSuccess('Confirmado', 'Foi enviado um e-mail com as instruções para recuperar a sua senha.')

            setLoading(false)
            setTab('login')

        } catch ( err: any ) {
            setLoading(false);
            addDanger('Atenção', err)
        }

    }

    return (
        <Container effect={showEffect}>
            <header>
                <a href="/">
                    <IconMail />
                    <span>contato@eyou.com.br</span>
                </a>

                <a href="/">
                    <IconPhone />
                    <span>+55 11 3568-2843</span>
                </a>
            </header>
            <section>
                <div className="left-panel">
                    <div className="center-form animate__animated animate__fadeIn">
                        <Logo />

                        { tab === 'login' ?
                        <h1>
                            Bem-vindo ao <span>painel</span>
                        </h1>
                        :
                        <h1>
                            Recuperar a <span>senha</span>
                        </h1>
                        }

                        { tab === 'login' ?
                            <form onSubmit={handleSubmit}>
                                <InputLogin
                                    placeholder="E-mail"
                                    value={username}
                                    onChangeText={(value) => setUsername(value)}
                                />
                                <InputLogin
                                    placeholder="Senha"
                                    type="password"
                                    value={password}
                                    onChangeText={(value) => setPassword(value)}
                                />
                                <ButtonDefault loading={loading}>
                                    Acessar
                                </ButtonDefault>

                                <a onClick={() => setTab('forget')} className="forgetpassword">
                                    <IconQuestion/>
                                    <span>Esqueceu a senha?</span>
                                </a>

                            </form>
                        :
                            <form onSubmit={handleSubmitForget}>

                                <p className="description">Insira seu email para receber as instruções.<br/> Não esqueça de verificar a caixa de SPAM.</p>
                                <InputLogin
                                    placeholder="E-mail"
                                    type="text"
                                    value={email}
                                    onChangeText={(value) => setEmail(value)}
                                />
                                <ButtonDefault loading={loading}>
                                    Me envie!
                                </ButtonDefault>

                                <a onClick={() => setTab('login')} className="forgetpassword">
                                    <IconArrowLeft/>
                                    <span>Voltar para o login</span>
                                </a>

                            </form>
                    
                        }
                        
                    </div>
                </div>
                <div className="right-panel">
                    <div className="bg animate__animated animate__fadeIn">
                        <div className="message animate__animated animate__zoomIn" />
                        <div className="text animate__animated animate__zoomIn" />
                        <div className="products animate__animated animate__zoomIn" />
                        <div className="person animate__animated animate__fadeInLeft" />
                    </div>
                </div>
            </section>
        </Container>
    );
}
