import axios from 'axios'

const token = window.sessionStorage.getItem('token')

var baseUrl

if(process.env.NODE_ENV === 'development') {

    // baseUrl = 'http://localhost:3333'
    //baseUrl = 'http://localhost:3333'
    baseUrl = 'https://eyouomni.backendtropa.com.br'
    //baseUrl = 'https://apiomni.eyou.com.br/bin/apiMiddleware/back/'
} else {
    baseUrl = 'https://eyouomni.backendtropa.com.br'
}

const apiTropa = axios.create({
    baseURL: baseUrl,
    headers: {
        'Authorization': 'Bearer ' + token,
    }
})

export default apiTropa
