import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;
  position: relative;
  .relative {
    width: 40px;
    height: 40px;
    position: absolute;
    > span {
      height: 100%;
      opacity: 0;
    }
  }
  }
`;
