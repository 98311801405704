import { useEffect, useState } from 'react';
import { CardLoadingChat } from '../../components/Chat/CardLoadingChat';
import CardWellcomeToChat from '../../components/Chat/CardWellcomeToChat';
import CardChat from '../../components/Chat/CardChat';
import { IconDownload, IconFile, IconShape } from '../../components/icons';

import { Container, ContentChat } from './styles';
import CardMessage from '../../components/Chat/CardMessage';
import apiTropa from '../../services/apiTropa';
import Datetime from '../../Helper/Datetime';

// import { io } from 'socket.io-client';

export default function PageChatIp(props: any) {
    // const SOCKETURL = "ws://192.168.15.16:443"
    // const socket = useRef<any>();
    const [loading, setLoading] = useState(false);
    const [onChat, setOnChat] = useState(false);
    const [chatData, setChatData] = useState<any>({});
    const [messages, setMessages] = useState<any[]>([]);
    const [lastMessage, setLastMessage] = useState<any>(false);
    const token = props.match.params.token;

    const [configuracaoEstilo, setConfiguracaoEstilo] = useState<any>({
        style: {},
        styleChat: {},
        styleButtonMain: {},
        styleButtonMainDark: {},
    });
    const [botaoEntrada, setBotaoEntrada] = useState<any>({});
    const [pesquisaSatisfacao, setPesquisaSatisfacao] = useState<any>({});
    const [formularioEntrada, setFormularioEntrada] = useState<any>({});
    const [formularioEntradaForm, setFormularioEntradaForm] = useState<any>([]);
    const AudioMessage = new Audio('./new-message.mp3');

    useEffect(() => {
        // socket.current = io(SOCKETURL, {
        //     rejectUnauthorized: false,
        // });

        // socket.current.on('getMessage', (data: any) => {
        //     data.criado = new Date();
        //     setLastMessage(data);
        // });
        async function getData() {
            const response: any = await apiTropa.get('tag-chat/' + token);
            const configParse = JSON.parse(response.data.data.botaoEntrada.configuracao_estilo);
            const formParse = JSON.parse(response.data.data.formularioEntrada.dados_formulario);
            setConfiguracaoEstilo({ ...configParse });
            setFormularioEntradaForm(formParse);
            setBotaoEntrada({ ...response.data.data.botaoEntrada });
            setFormularioEntrada({ ...response.data.data.formularioEntrada });
            setPesquisaSatisfacao({ ...response.data.data.pesquisaSatisfacao });
        }
        getData();
    }, []);

    useEffect(() => {
        messages.push(lastMessage);
        setMessages([...messages]);
        if (!lastMessage.id_usuario) AudioMessage.play();
    }, [lastMessage]);

    // useEffect(() => {
    //     socket.current.on('getConnections', (data: any) => {});
    // }, []);

    async function handleOnStartService(data: any) {
        setChatData(data);
    }

    useEffect(() => {
        async function getMessages() {
            setLoading(true);
            const response: any = await apiTropa.get(
                'mensagem/listar/' + chatData.id_atendimento + '?itens=10&pagina=1'
            );
            setMessages(response.data.data.reverse());
            setLoading(false);
            setOnChat(true);
            // socket.current.emit('addConnection', {
            //     referenceId: 'u' + chatData.usuario.id_usuario,
            //     groupId: chatData.id_atendimento,
            // });
        }
        if (chatData.id_atendimento) getMessages();
    }, [chatData]);

    async function handleSubmit(data: any) {
        data.id_usuario = 'u' + chatData.usuario.id_usuario;
        data.id_atendimento = chatData.id_atendimento;
        messages.push(data);
        setMessages([...messages]);
        // socket.current.emit('sendMessageUser', data);
        data.id_usuario = data.id_usuario.replace('u', '');
        const response = await apiTropa.post('mensagem/enviar/' + data.id_atendimento, data);
    }

    return (
        <Container>
            <ContentChat>
                {onChat ? (
                    <CardChat
                        icon={configuracaoEstilo.styleChat.file}
                        messages={messages}
                        onSubmit={handleSubmit}
                        backgroundColor={configuracaoEstilo.styleChat.colorMain}
                    >
                        {messages.map((row, key) => (
                            <CardMessage
                                key={key}
                                messageType={row.id_usuario ? 'recebida' : 'enviada'}
                                hasView={true}
                                backgroundEnviada={configuracaoEstilo.styleChat.backgroundEnviada}
                                backgroundRecebida={configuracaoEstilo.styleChat.backgroundRecebida}
                                color={'#FFF'}
                            >
                                <div className="content-message">
                                    {row.tipo === 'texto' && <p>{row.mensagem}</p>}
                                    {row.tipo === 'imagem' && <img src={row.url} alt={row.mensagem} />}
                                    {row.tipo === 'video' && (
                                        <video controls={true}>
                                            <source src={row.url} />
                                        </video>
                                    )}
                                    {row.tipo === 'audio' && (
                                        <audio controls={true}>
                                            <source src={row.url} />
                                        </audio>
                                    )}

                                    {row.tipo === 'arquivo' && (
                                        <a href={row.url} className="downloadFileChat">
                                            <IconFile />
                                            <span>{row.mensagem}</span>
                                            <div className="iconDownloadChat">
                                                <IconDownload size="20" />
                                            </div>
                                        </a>
                                    )}
                                </div>

                                <div className="status">
                                    <span>{Datetime.string(row.criado, true)}</span>
                                    <IconShape />
                                </div>
                            </CardMessage>
                        ))}
                    </CardChat>
                ) : (
                    <CardWellcomeToChat
                        data={{
                            formularioEntrada,
                            pesquisaSatisfacao,
                            botaoEntrada,
                            configuracaoEstilo,
                            formularioEntradaForm,
                        }}
                        onChange={handleOnStartService}
                    />
                )}
            </ContentChat>

            {loading && <CardLoadingChat />}
        </Container>
    );
}
