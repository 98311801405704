/* eslint-disable no-throw-literal */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ButtonLinear from '../../components/form/ButtonLinear';
import LinkLinear from '../../components/form/LinkLinear';
import { IconPlus, IconTrash } from '../../components/icons';
import { Td, TdDescription, TdSubtitle } from '../../components/layout/containers/Td';
import TableDefault from '../../components/TableDefault';
import { useChatbotContext } from '../../contexts/ChatbotContext';
import { useMessage } from '../../contexts/MessagesContext';
import { useRoutes } from '../../contexts/RoutesContext';
import apiTropa from '../../services/apiTropa';
import { Header } from '../../pages/GruposAtendimento/operadores/styles';

import { Container, ContainerTable } from './styles';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import FailedRegister from '../../components/FailedRegister';
import moment from 'moment';
import 'moment/locale/pt-br';

export default function Chatbot() {
    const { setPageName } = useRoutes();
    const { handleNewChatbot, handleDeleteChatbot } = useChatbotContext();
    const { addDanger, addSuccess, addConfirm } = useMessage();
    const history = useHistory();
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setPageName('Chatbot');
        getData();
    }, []);

    async function getData() {
        apiTropa.get('chatbot').then((response: any) => {
            setData([...response.data.data]);
            setLoading(false);
        });
    }

    async function handleNew() {
        try {
            const response: any = await handleNewChatbot('Novo chatbot');

            if (response.error === true)
                throw 'Não foi possivel adicionar um novo chatbot no momento, tente novamente mais tarde ou contate um administrador.';

            history.push('chatbot/' + response.id);
        } catch (err: any) {
            addDanger('Oops...', err.toString());
        }
    }

    async function handleRemove(id: any) {
        try {
            const response: any = await handleDeleteChatbot(id);

            if (response.error === true) throw 'Não foi possivel remover esse chatbot.';

            apiTropa.get('chatbot').then((response: any) => {
                setData([...response.data.data]);
            });
        } catch (err: any) {
            addDanger('Oops...', err);
        }
    }

    function handleOnClone(id: any, nameBot: string) {
        try {
            const response = apiTropa.get(`/chatbot-copiar/${id}`);
            getData();
            addSuccess('Clonagem de chatbot', `${nameBot} copiado com sucesso!`);
        } catch (err: any) {
            addDanger('Erro', 'Não foi possivel clonar esse chatbot no momento');
        }
    }

    return (
        <>
            <Container>
                <Header>
                    <ButtonLinear onClick={handleNew} Icon={IconPlus} className="info">
                        Adicionar chatbot
                    </ButtonLinear>
                </Header>

                <ContainerTable>
                    {data.map((row, key) => (
                        <TableDefault columns={3} key={key}>
                            <Td flexDirection="column">
                                <div className="bot">
                                    <TdDescription>Chatbot</TdDescription>
                                    <TdSubtitle>{row.chatbot}</TdSubtitle>
                                </div>
                            </Td>
                            <Td flexDirection="column">
                                <TdDescription>Criação</TdDescription>
                                <TdSubtitle>
                                    {moment(row.criado).locale('pt-br').format('DD/MM/YYYY - HH:mm:ss')}
                                </TdSubtitle>
                            </Td>
                            <Td justifyContent="flex-end" flexDirection="row">
                                <ButtonLinear
                                    className="config"
                                    onClick={() => handleOnClone(row.id_chatbot, row.chatbot)}
                                >
                                    Clonar
                                </ButtonLinear>
                                <LinkLinear to={`/chatbot/${row.id_chatbot}`}>Editar</LinkLinear>
                                <ButtonLinear
                                    onClick={() =>
                                        addConfirm({
                                            title: 'Atenção',
                                            description: 'Deseja mesmo remover esse chatbot?',
                                            onConfirm: () => handleRemove(row.id_chatbot),
                                        })
                                    }
                                    Icon={IconTrash}
                                    className="delete"
                                />
                            </Td>
                        </TableDefault>
                    ))}

                    {loading === true && (
                        <Skeleton
                            count={10}
                            className="skeletor"
                            height="85px"
                            borderRadius="16px"
                            baseColor="#262655"
                            highlightColor="#3e3b77"
                        />
                    )}

                    {loading === false && data.length === 0 && <FailedRegister />}
                </ContainerTable>
            </Container>
        </>
    );
}
