import React from 'react';
import { convertMessage } from '../../utils/ConvertMessages';
import { IconArrowMessageRight, IconFile, IconPencil, IconTrash } from '../icons';
import { ContainerMessage, ContainerMessageButtons, ContainerMessageText } from './styles';

interface IProps {
    row: {
        message: string;
        type: string;
        filename?: string;
    };
    indice: number;
    loadUpload: boolean;
    handleMessageEditing(indice: number): void;
    handleRemoveMessage(indice: number): void;
}

const RenderMessage: React.FC<IProps> = ({ row, indice, loadUpload, handleMessageEditing, handleRemoveMessage }) => {
    return (
        <ContainerMessage>
            <ContainerMessageText>
                {row.type === 'text' && (
                    <div>
                        <div
                            style={{whiteSpace: 'break-spaces'}}
                            dangerouslySetInnerHTML={{
                                __html: convertMessage(row.message),
                            }}
                        />
                    </div>
                )}

                {row.type === 'audio' && (
                    <div>
                        {loadUpload === false && (
                            <audio controls={true}>
                                <source src={row.message} type="audio/mp3" />
                                seu navegador não suporta HTML5
                            </audio>
                        )}
                    </div>
                )}

                {row.type === 'video' && (
                    <div>
                        {loadUpload === false && (
                            <video controls={true}>
                                <source src={row.message} type="video/mp4" />
                                seu navegador não suporta HTML5
                            </video>
                        )}
                    </div>
                )}

                {row.type === 'arquivo' && (
                    <div className="arquivo">
                        <a href={row.message} target="_blank">
                            <IconFile />
                            <span>{row.filename}</span>
                        </a>
                    </div>
                )}

                {row.type === 'imagem' && (
                    <div>
                        <img src={row.message} alt="images" />
                    </div>
                )}

                <IconArrowMessageRight />
            </ContainerMessageText>
            <ContainerMessageButtons>
                <button onClick={() => handleMessageEditing(indice)} className="info">
                    <IconPencil />
                </button>
                <button onClick={() => handleRemoveMessage(indice)} className="danger">
                    <IconTrash />
                </button>
            </ContainerMessageButtons>
        </ContainerMessage>
    );
};

export default RenderMessage;
