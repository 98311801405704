import { useEffect, useState } from 'react';
import ButtonLinear from '../../components/form/ButtonLinear';
import { IconEdicao, IconEyeOpen, IconPencil, IconTrash } from '../../components/icons';
import { Td, TdDescription, TdSubtitle } from '../../components/layout/containers/Td';
import TableDefault from '../../components/TableDefault';
import apiTropa from '../../services/apiTropa';
import { ListTable } from '../Atendimentos/styles';
import { Container } from './styles';
import Skeleton from 'react-loading-skeleton'
import FailedRegister from '../../components/FailedRegister';
import { useRoutes } from '../../contexts/RoutesContext';
import ModalDefault from '../../components/ModalDefault';
import InputDefault from '../../components/form/InputDefault';
import Field from '../../components/layout/containers/Field';
import FieldGroup from '../../components/layout/containers/FieldGroup';
import { useMessage } from '../../contexts/MessagesContext';
import { useHistory } from 'react-router-dom';
import ContainerFilter from '../../components/form/ContainerFilter';
import SelectDefault from '../../components/form/SelectDefault';
import BackPage from '../../components/layout/UIElements/BackPage';
import { useTheme } from 'styled-components';

export default function RelatoriosSalvos(){

    const history:any = useHistory()
    const { setPageName } = useRoutes()
    const { addDanger, addSuccess, addConfirm } = useMessage()
    const {title} = useTheme()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<any[]>([])
    const [modalEdit, setModalEdit] = useState<any>({})

    //save realatorio
    const [relatorioName, setRelatorioName] = useState<any>({})
    const [relatorioSave, setRelatorioSave] = useState<boolean>(false)

    useEffect(() => {
        setPageName('Meus relatórios')
        getData()
    }, [])

    async function getData()
    {

        const response:any = await apiTropa.get('relatoriobd')
        setData([...response.data.data])
        setLoading(false)

    }

    async function handleSubmit()
    {
        try {
            setLoading(true)
            setRelatorioSave(false)
            const response:any = await apiTropa.put('relatoriobd/'+modalEdit.id_relatorio, {
                nome: relatorioName,
                // json: modalEdit
            })
            setRelatorioName('')
            setLoading(false)
            addSuccess('Sucesso','Relatório salvo com sucesso.')
            getData()
        } catch ( err:any ) {
            setRelatorioSave(true)
            addDanger('Atenção', err.toString())
        }
    }

    async function handleEdit(item: any) {
        setRelatorioSave(true)
        setModalEdit({...item})
        setRelatorioName({nome: item.nome, id_relatorio: item.id_relatorio})

    }

    async function handleDelete(item:any)
    {
        try {
            setLoading(true)
            const response:any = await apiTropa.delete('relatoriobd/'+item.id_relatorio)
            addSuccess('Sucesso','Relatório apagado com sucesso.')
            setLoading(false)
            getData()
        } catch ( err:any ) {
            setRelatorioSave(true)
            addDanger('Atenção', err.toString())
        }
    }

    async function handleRedirect(item:any)
    {
        history.push({
            pathname: '/relatorios',
            filters: item.json,
        });

    }

    return (
        <Container>

            <ListTable>
                { loading === false && data.map((row, key) => (
                    <TableDefault columns={2} key={key}>
                        <Td flexDirection="column">
                            <TdDescription>Relatório</TdDescription>
                            <TdSubtitle>{row.nome}</TdSubtitle>
                        </Td>
                        <Td flexDirection="row" style={{justifyContent: 'flex-end'}}>
                            
                            <ButtonLinear
                                className="success"
                                onClick={() => handleRedirect(row)}
                                Icon={IconEyeOpen}
                            />
                            <ButtonLinear
                                className="info"
                                onClick={() => handleEdit(row)}
                                Icon={IconPencil}
                            />
                            <ButtonLinear
                                className="danger"
                                onClick={() => addConfirm({
                                    title: 'Atenção',
                                    description: 'Deseja realmente remover esse relatório?',
                                    onConfirm: () => handleDelete(row),
                                    onCancel: () => setRelatorioSave(false)
                                })}
                                Icon={IconTrash}
                            />
                        </Td>
                    </TableDefault>
                ))}

                { loading === false && data.length === 0 &&
                    <FailedRegister/>                    
                }

                {loading === true && (
                    <Skeleton
                        count={5}
                        className="skeletor"
                        height="85px"
                        borderRadius="16px"
                        baseColor="#262655"
                        highlightColor="#3e3b77"
                    />
                )}
            </ListTable>

            <ModalDefault
                    id="modalEncaminhar"
                    openModal={relatorioSave}
                    onClose={() => setRelatorioSave(false)}
                >
                    <Field>
                        <InputDefault
                            placeholder="Nome do relatório"
                            value={relatorioName.nome}
                            onChangeText={(value) => setRelatorioName(value)}
                        />
                    </Field>

                    <FieldGroup marginTop='20px'>
                        <ButtonLinear color={title === 'light' ? '#8A99A8' : '#fff'} className="transparent" onClick={() => setRelatorioSave(false)}>
                            Cancelar
                        </ButtonLinear>
                        <ButtonLinear className="success" onClick={() => handleSubmit()}>
                            Salvar
                        </ButtonLinear>
                    </FieldGroup>
                </ModalDefault>

        </Container>
    )
}