import styled from 'styled-components';

interface AreaProps {
    backgroundColor?: string;
    selectedFile?: boolean;
    inatividade?: any;
    radius?: boolean;
}

export const Container = styled.div<AreaProps>`
    display: flex;
    align-items: flex-start;
    width: 100%;
    position: relative;
    background: #eaeef3;
    border-radius: ${({ radius }) => radius ? '10px' : '0px'};

    .buttonEmojiChat {
        display: grid;
        place-items: center;
        flex-shrink: 0;

        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: none;
        background: transparent;
        position: relative;

        svg {
            path {
                fill: ${(props) =>
                        props.backgroundColor
                            ? props.backgroundColor
                            : '#34D5FA'};
            }
        }
    }

    .textareaInputDefault {
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 40px;
        padding: 17px 0px;
        border: none;
        outline: none;
        background: transparent;
        display: flex;
        resize: none;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;
