import { useEffect, useState } from 'react';
import { convertMessage } from '../../../utils/ConvertMessages';
import { IconMessage, IconWhatsApp } from '../../icons';
import { 
    Container, 
    MessageLeft, 
    DataMessage, 
    ProfileImage,
    SectionTypeMessage 
} from './styles';

interface MessageProps {
    name?: string
    message?: string
    data?: string
    hasNotification?: number
    selected?: boolean
    panel: string
    onClick: () => {}
    newMessage?: boolean
    typeMessage?: 'whatsapp' | 'web' | undefined;
}

export default function CardMessage({
    name,
    message,
    data,
    hasNotification,
    selected,
    panel,
    newMessage = false,
    onClick,
    typeMessage
}: MessageProps) {
    const [messageRegex, setMessageRegex] = useState<any>(message)
    useEffect(() => {
        if ( message ) setMessageRegex(convertMessage(message))
    }, [message])

    // function urlify(text:any) {
    //     var urlRegex = /(https?:\/\/[^\s]+)/g;

    //     return text.replace(urlRegex, function(url:any) {
    //         return '<a target="_blank" href="' + url + '">' + url + '</a>';
    //     })
    //     .replace('[b]', '<b>').replace('[/b]', '</b> ')
    //     .replace('[i]', '<i>').replace('[/i]', '</i> ')
    //     .replace('[quote]', '<span class="quote">').replace('[/quote]', '</span>')
    //     .replace('_', '<i>').replace('_', '</i> ')
    //     .replace('*', '<b>').replace('*', '</b> ')
    //   }


    return (
        <Container className={newMessage ? 'new' : 'old'} selected={selected} panel={panel} onClick={onClick}>
            <ProfileImage selected={selected} panel={panel}>
                <div className="image" style={{
                    backgroundImage: `url("https://ui-avatars.com/api/?name=${name}")`,
                }} />
            </ProfileImage>

            <MessageLeft selected={selected} panel={panel}>
                <h1>{name}</h1>
                <span dangerouslySetInnerHTML={{__html: messageRegex ?? 'Nenhuma mensagem disponivel.'}}></span>
            </MessageLeft>
            
            <DataMessage selected={selected} hasNotification={hasNotification}>
                <span>{data === '--' ? '' : data}</span>
            </DataMessage>
            {typeMessage && (
                <SectionTypeMessage>
                    <h3>{typeMessage}</h3>
                    {typeMessage === 'whatsapp' ? (
                        <IconWhatsApp color='#2FF096' />
                    ) : (
                        <IconMessage size='1em' />
                    )}
                </SectionTypeMessage>
            )}
        </Container>
    );
}
