import { Buffer } from 'buffer';
import ButtonLinear from '../../components/form/ButtonLinear';
import {
    Td,
    TdDescription,
    TdTitle,
} from '../../components/layout/containers/Td';
import TableDefault from '../../components/TableDefault';
import Skeleton from 'react-loading-skeleton';

import {
    Overlay,
    BackgroundImage,
    BackgroundFrame,
    Container,
    Header,
    Welcome,
    Main,    
} from './styles';
import { useHistory } from 'react-router';
import StatusTable from '../../components/Table/StatusTable';
import { useEffect, useState } from 'react';
import apiTropa from '../../services/apiTropa';
import Loading from '../../components/Loading/Loading';
import { useMessage } from '../../contexts/MessagesContext';

export default function ModalOperator() {
    const history = useHistory()
    const { addDanger } = useMessage()
    const [loading, setLoading] = useState<boolean>(false)
    const [operador, setOperador] = useState<any>({
        dadosUsuario: {},
        grupos: [],
        horarios: [],
        perfil: []
    })

    useEffect(() => {
        getOperador()
    }, [])

    function getOperador(){

        const tokenInicial = window.sessionStorage.getItem('tokenInicial')
        const token = String(window.sessionStorage.getItem('token'))
        const decoded = new Buffer(token, 'base64').toString('ascii')
        const decodedJson = JSON.parse(decoded)
        const idOperador = decodedJson.user.idU

        setLoading(true)
        apiTropa.post(`operador/detalhe/${idOperador}`, {
            tokenEyou: tokenInicial
        }).then((response:any) => {
            const operador = response.data.data
            if( operador.horarios.find((row:any) => row.data === "domingo") === undefined ) operador.horarios.splice(0, 0, {data: "domingo"})
            if( operador.horarios.find((row:any) => row.data === "segunda-feira") === undefined ) operador.horarios.splice(1, 0, {data: "segunda-feira"})
            if( operador.horarios.find((row:any) => row.data === "terca-feira") === undefined ) operador.horarios.splice(2, 0, {data: "terca-feira"})
            if( operador.horarios.find((row:any) => row.data === "quarta-feira") === undefined ) operador.horarios.splice(3, 0, {data: "quarta-feira"})
            if( operador.horarios.find((row:any) => row.data === "quinta-feira") === undefined ) operador.horarios.splice(4, 0, {data: "quinta-feira"})
            if( operador.horarios.find((row:any) => row.data === "sexta-feira") === undefined ) operador.horarios.splice(5, 0, {data: "sexta-feira"})
            if( operador.horarios.find((row:any) => row.data === "sabado") === undefined ) operador.horarios.splice(6, 0, {data: "sabado"})
            setOperador(operador)
            setLoading(false)
        })
    }

    async function iniciarMeuDia(){
        try {

            setLoading(true)
            await apiTropa.post("/operador/login")

            const response = await apiTropa.put('perfil/token', {
                hash: window.sessionStorage.getItem('token'),
                hash_init: window.sessionStorage.getItem('tokenInicial'),
            })
            setLoading(false)
        } catch(err:any){
            setLoading(false)
            if( err.response ){
                // addDanger('Atenção', String(err.response.data.message))
            } else {
                addDanger('Atenção', String(err))
            }
        }
        history.push('/fila-de-atendimento')
    }

    return (
        <Overlay>
            <Loading active={loading} />

            <BackgroundImage>
                <BackgroundFrame />

                <Container>
                    <Header>
                        <Welcome>
                            <h1>
                                Olá {operador.perfil[0]?.nome}
                            </h1>
                        </Welcome>

                        <ButtonLinear
                            className="info"
                            onClick={() => iniciarMeuDia()}
                        >
                            Iniciar meu dia!
                        </ButtonLinear>
                    </Header>

                    <Main>

                        <h3>Seus grupos de atendimento:</h3>
                        {loading ?
                        <Skeleton
                            count={1}
                            className="skeletor"
                            height="80px"
                            borderRadius="16px"
                            baseColor="#262655"
                            highlightColor="#3e3b77"
                        />
                        :
                        <>
                            {operador.grupos.map((grupo:any, key:any) => (
                                <TableDefault 
                                    key={key} 
                                    columns={2} 
                                    // dropDownComponent={<CardUser />}
                                >
                                    <Td flexDirection="column">
                                        <TdDescription>Grupo de atendimento</TdDescription>
                                        <TdTitle>{grupo.grupo_atendimento}</TdTitle>
                                    </Td>

                                    <Td flexDirection="column" style={{ alignItems: 'flex-end' }}>
                                        <StatusTable status={grupo.ativo === "sim"} />
                                    </Td>
                                </TableDefault>
                            ))}
                        </>
                        }

                        <h3>Seu horário de atendimento e pausas:</h3>
                        {loading ?
                        <Skeleton
                            count={1}
                            className="skeletor"
                            height="80px"
                            borderRadius="16px"
                            baseColor="#262655"
                            highlightColor="#3e3b77"
                        />
                        :
                        <>
                            {operador.horarios.map((row:any, key:any) => (
                            <TableDefault columns={3} key={key}>
                                <Td flexDirection="column">
                                    <TdDescription>Dia da semana</TdDescription>
                                    <TdTitle>{row.data}</TdTitle>
                                </Td>
                                <Td flexDirection="column">
                                    <TdDescription>Horários</TdDescription>
                                    <TdTitle>{row.horarios?.inicio ? row.horarios.inicio + " às " + row.horarios.fim : "Não definido"}</TdTitle>
                                </Td>
                                <Td flexDirection="column">
                                    <TdDescription>Pausas</TdDescription>
                                    <TdTitle>
                                        {(!row.horarios || !row.horarios.pausa) ? "Não definido" :
                                            row.horarios.pausa.map((pausa:any) => <p>{pausa.descricao} - {pausa.inicio} às {pausa.fim}</p>)
                                        }
                                    </TdTitle>
                                </Td>
                            </TableDefault>
                            ))}
                        </>
                        }
                    </Main>
                </Container>
            </BackgroundImage>
        </Overlay>
    );
}
