import { useEffect, useState, useRef } from 'react';
import ButtonLinear from '../../../components/form/ButtonLinear';
import Field from '../../../components/layout/containers/Field';
import FieldGroup from '../../../components/layout/containers/FieldGroup';
import {
    IconSmile,
    IconShape,
    IconTrash,
    IconDate,
    IconClock
} from '../../../components/icons';
import Datetime from '../../../Helper/Datetime';
import moment from 'moment';
import InputDefaultTime from '../../../components/form/InputDefaultTime';
import InputSwitchDefault from '../../../components/form/InputSwitchDefault';
import BackPage from '../../../components/layout/UIElements/BackPage';
import ModalDefault from '../../../components/ModalDefault';
import { useMessage } from '../../../contexts/MessagesContext';
import { useRoutes } from '../../../contexts/RoutesContext';
import apiTropa from '../../../services/apiTropa';
import Skeleton from 'react-loading-skeleton';
import { Container, Header, InfoHours, CardEditHours, SectionHours, Title, FooterChat, InfoHoursNew, InfoGroupNew, CardEditHoursNew, GroupCardEditHoursNew, GroupButtonsNew } from './styles';
import CardEmoji from '../../../components/Chat/CardEmoji';
import CardMessage from '../../../components/Chat/CardMessage';
import { useHistory } from 'react-router-dom';
import InputDefault from '../../../components/form/InputDefault';
import CardQuote from '../../../components/Chat/CardQuote';
import TextAreaInputDefault from '../../../components/form/TextAreaInputDefault';
import { convertMessage } from '../../../utils/ConvertMessages';


interface IDTOConfiguracao {
    field?: string
    value?: string
    required?: boolean
}

interface HorarioAtendimento{
    id_horario_atendimento?:Number;
    id_grupo_atendimento?:Number;
    horario_inicio?:String|any;
    horario_final?:String|any;
    segunda_feira?:string|any;
    terca_feira?:string|any;
    quarta_feira?:string|any;
    quinta_feira?:string|any;
    sexta_feira?:string|any;
    sabado?:string|any;
    domingo?:string|any;
    fuso_horario?:String;
    horario_inicio_segunda_feira?:string|any;
    horario_final_segunda_feira?: string|any;
    horario_inicio_terca_feira?: string|any;
    horario_final_terca_feira?: string|any;
    horario_inicio_quarta_feira?: string|any;
    horario_final_quarta_feira?: string|any;
    horario_inicio_quinta_feira?: string|any;
    horario_final_quinta_feira?: string|any;
    horario_inicio_sexta_feira?: string|any;
    horario_final_sexta_feira?: string|any;
    horario_inicio_sabado?: string|any;
    horario_final_sabado?: string|any;
    horario_inicio_domingo?: string|any;
    horario_final_domingo?: string|any;
    mensagem_segunda_feira?:string|any;
    mensagem_terca_feira?:string|any;
    mensagem_quarta_feira?:string|any;
    mensagem_quinta_feira?:string|any;
    mensagem_sexta_feira?:string|any;
    mensagem_sabado?:string|any;
    mensagem_domingo?:string|any;
  }

interface IDTO {
    id_grupo_canal_atendimento?: number
    id_grupo_atendimento?: number
    id_canal_atendimento?: number
    id_chatbot?: number
    configuracao?: IDTOConfiguracao[]
}
interface IMessage {
    message: string;
    type: 'texto' | 'audio' | 'video' | 'imagem' | 'arquivo';
    url?: string;
    date: Date;
    status: 'pendente' | 'enviado' | 'confirmado';
}

export default function HorarioAtendimento(props: any) {
    const { setPageName } = useRoutes()
    const { addConfirm, addDanger, addSuccess } = useMessage()
    const [modal, setModal] = useState(false)
    const [newModal, setNewModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const [messageKeyEdit,setMessageKeyEdit] = useState('')
    const [horarioAtendimento, setHorarioAtendimento] = useState<HorarioAtendimento>(
        {
            id_horario_atendimento: 1,
            id_grupo_atendimento: 1,
            horario_inicio: '07:00:00',
            horario_final: '20:00:00',
            fuso_horario: '(UTC-03:00) Brasilia',
            segunda_feira: '1',
            terca_feira: '1',
            quarta_feira: '1',
            quinta_feira: '1',
            sexta_feira: '1',
            sabado: '1',
            domingo: '1',
            horario_inicio_segunda_feira: '09:00:00',
            horario_final_segunda_feira: '18:00:00',
            horario_inicio_terca_feira: '09:00:00',
            horario_final_terca_feira: '18:00:00',
            horario_inicio_quarta_feira: '09:00:00',
            horario_final_quarta_feira: '18:00:00',
            horario_inicio_quinta_feira: '09:00:00',
            horario_final_quinta_feira: '18:00:00',
            horario_inicio_sexta_feira: '09:00:00',
            horario_final_sexta_feira: '18:00:00',
            horario_inicio_sabado: '09:00:00',
            horario_final_sabado: '18:00:00',
            horario_inicio_domingo: '09:00:00',
            horario_final_domingo: '18:00:00'
          }
    );
    // const [hasMenuEmoji, setHasMenuEmoji] = useState(false);
    // const [newHasMenuEmoji, setNewHasMenuEmoji] = useState(false);
    // const inputRef = useRef<any>();
    // const [DTO, setDTO] = useState<IDTO>({})
    // const [message, setMessage] = useState<IMessage>({
    //     message: '',
    //     type: 'texto',
    //     url: '',
    //     date: new Date(),
    //     status: 'pendente',
    // });

    const [data, setData] = useState<any>([])
    const [DTOModal, setDTOModal] = useState<any>({
        data: moment().format('YYYY-MM-DD'),
        horario_inicio: '09:00:00',
        horario_final: '18:00:00',
        mensagem: 'Mensagem de exceção padrão.'
    })

    function handleNewException()
    {

        setDTOModal({
            data: moment().format('YYYY-MM-DD'),
            horario_inicio: '09:00:00',
            horario_final: '18:00:00',
            mensagem: 'Mensagem de exceção padrão.'
        })
        setNewModal(true)

    }

    function handleEditException(params:any)
    {

        setDTOModal({
            data: moment(params.data).utc().format('YYYY-MM-DD'),
            horario_inicio: params.horario_inicio,
            horario_final: params.horario_final,
            mensagem: params.mensagem,
            id_horario_atendimento_excecao: params.id_horario_atendimento_excecao
        })
        setNewModal(true)

    }

    function handleOnChangeDTO(name: any, value:any)
    {
        const newDTO:any = DTOModal
        newDTO[name] = value
        setDTOModal({...newDTO})
    }

    // function insertEmoji(val: any, key:any) {

    //     const text = message.message;
    //     const cursor = inputRef.current.selectionStart;
    //     if(horarioAtendimento[key as keyof HorarioAtendimento] !== null){
    //         message.message = horarioAtendimento[key as keyof HorarioAtendimento] + val;
    //         horarioAtendimento[key as keyof HorarioAtendimento] = horarioAtendimento[key as keyof HorarioAtendimento].slice(0,cursor) + val + horarioAtendimento[key as keyof HorarioAtendimento].slice(cursor);
    //     }else{
    //         message.message = val;
    //         horarioAtendimento[key as keyof HorarioAtendimento] = val;
    //     }
        
    //     setMessage({ ...message });
    //     inputRef.current.focus();
    // }


    useEffect(() => {
        setLoading(false)
        setPageName('Configurar horário de atendimento')
        getHorarioAtendimentoByGrupo()
    }, [])

    // useEffect(() => {
    //     console.log("DTO =>", DTO)
    // }, [DTO])


    function changeHorarioAtendimento(key:any,value:any){
        horarioAtendimento[key as keyof HorarioAtendimento] = value
        setHorarioAtendimento({...horarioAtendimento})
    }

    function changeHorarioAtendimentoSwitch(key:any,value:any){

        if(value[1] === '1'){
            horarioAtendimento[key as keyof HorarioAtendimento] = value[1]
        }else{
            horarioAtendimento[key as keyof HorarioAtendimento] = "0"
        }
        
        setHorarioAtendimento({...horarioAtendimento})
    }

    async function getHorarioAtendimentoByGrupo(){
        try{
            setLoading(true)

            let result : any  = await apiTropa.get('/horario-atendimento/'+props.match.params.id)
            let response : any = await apiTropa.get('horario-atendimento-excecao/'+props.match.params.id)

            setData([...response.data.data])

            if(result.data.data){
                setHorarioAtendimento(result.data.data)
            }

            setLoading(false)

        }catch(err:any){
            if(err.response){
                addDanger("Atenção", String(err.response.data.message))
            }else{
                addDanger("Atenção", String(err))
            }
            setTimeout((function(){
                history.push('/grupo-atendimento/'+props.match.params.id)
            }),2000)
        }
    }

    function iniciaModalParaEdicao(key:any){
        setMessageKeyEdit(key)
        setModal(true)
    }

    async function saveHorarioAtendimento(){
        try{

            await apiTropa.put('/horario-atendimento/'+horarioAtendimento.id_horario_atendimento,horarioAtendimento)


            addSuccess("Sucesso", "Horário de Atendimento editado com sucesso")
            setModal(false)
            getHorarioAtendimentoByGrupo()

        }catch(err:any){
            if( err.response ){
                addDanger("Atenção", String(err.response.data.message))
            } else {
                addDanger("Atenção", String(err))
            }
            setLoading(false)
        }
    }

    // function newInsertEmoji(val: string) {
    //     const step: any = inputRef.current;
    //     step.focus();
    //     const selection: any = window.getSelection();
    //     for (var i = 0; i < step; i += 1) {
    //         selection.modify('extend', 'backward', 'character');
    //     }
    //     document.execCommand('insertText', false, val);
    // }

    // function insertQuote(val: string) {
    //     const step: any = inputRef.current;
    //     step.focus();
        
    //     const selection: any = window.getSelection();
    //     var selectedText = selection.toString(); 
        
    //     const modifyTExt = val.replace('-', selectedText) 
    //     document.execCommand('insertText', false, modifyTExt);
    // }

    async function handleSaveExcpetion()
    {

        try {
            setLoading(true)
            DTOModal.id_grupo_atendimento = props.match.params.id
            if ( DTOModal.id_horario_atendimento_excecao ){
                const response = await apiTropa.put('horario-atendimento-excecao/'+DTOModal.id_horario_atendimento_excecao, DTOModal)
            } else {
                const response = await apiTropa.post('horario-atendimento-excecao', DTOModal)
            }
            setNewModal(false)
            getHorarioAtendimentoByGrupo()
            setLoading(false)
            addSuccess('Sucesso', 'Data de exceção salva com sucesso.')
        } catch (err:any) {
            setLoading(false)
            addDanger('Atenção', err.toString())
        }

    }

    async function handleDelete(params: any)
    {

        setLoading(true)
        const resposne:any = await apiTropa.delete('horario-atendimento-excecao/'+params.id_horario_atendimento_excecao)
        getHorarioAtendimentoByGrupo()
        setLoading(false)

    }

    // const [selectedInputChat, setSelectedInputChat] = useState(false)

    // function handleOnFocus() {
    //     setSelectedInputChat(true);
    // }

    // function handleOnBlur() {
    //     setSelectedInputChat(false);
    // }

    // function urlify(text:any) {
    //     var urlRegex = /(https?:\/\/[^\s]+)/g;

    //     return text.replace(urlRegex, function(url:any) {
    //         return '<a target="_blank" href="' + url + '">' + url + '</a>';
    //     })
    //     .replaceAll('[b]', '<b>').replaceAll('[/b]', '</b> ')
    //     .replaceAll('[i]', '<i>').replaceAll('[/i]', '</i> ')
    //     .replaceAll('[quote]', '<span class="quote">').replaceAll('[/quote]', '</span>')
    //     .replaceAll('_', '<i>').replaceAll('_', '</i> ')
    //     .replaceAll('*', '<b>').replaceAll('*', '</b> ')

    //   }

    return (
        <>
            
            <Container>
                <Header>
                    <BackPage />

                    <div className='buttons'>
                        <ButtonLinear className={'info'} onClick={() => handleNewException()} >
                            Inserir data
                        </ButtonLinear>
                        <ButtonLinear onClick={() => history.goBack()} >
                            Cancelar
                        </ButtonLinear>
                        <ButtonLinear className={'success'} onClick={() => saveHorarioAtendimento()} >
                            Salvar
                        </ButtonLinear>
                    </div>

                </Header>

                {loading === true ? 
                    <Skeleton style={{marginTop: '15px'}}
                        count={7}
                        className="skeletor"
                        height="80px"
                        borderRadius="16px"
                        baseColor="#262655"
                        highlightColor="#3e3b77"
                    />
                    
                :
                    <>
                        <GroupCardEditHoursNew>
                            { data.map((row:any, key:number) =>
                                <CardEditHoursNew>

                                    <InfoGroupNew>
                                        <InfoHoursNew>
                                            <IconDate/>
                                            <span>{moment(row.data).utc().format('DD/MM/YYYY')}</span>
                                        </InfoHoursNew>
                                        <InfoHoursNew>
                                            <IconClock/>
                                            <span>{row.horario_inicio}</span>
                                            <span className="as">às</span>
                                            <span>{row.horario_final}</span>
                                        </InfoHoursNew>
                                    </InfoGroupNew>
                                    
                                    <GroupButtonsNew>
                                        <ButtonLinear className={'info'} onClick={() => handleEditException(row)}>
                                            Editar
                                        </ButtonLinear>
                                        <ButtonLinear onClick={() => addConfirm({
                                            title: 'Atenção',
                                            description: 'Deseja mesmo remover essa data de exceção?',
                                            onConfirm: () => handleDelete(row),
                                            })} 
                                            className="danger" 
                                            Icon={IconTrash}
                                        />
                                    </GroupButtonsNew>

                                </CardEditHoursNew>
                            )}
                        </GroupCardEditHoursNew>
                        <SectionHours>
                            <CardEditHours>
                                <InfoHours>
                                    <span>Segunda Feira</span>
                                </InfoHours>
                                <FieldGroup>
                                    <InputDefaultTime
                                        value={horarioAtendimento['horario_inicio_segunda_feira'] ? horarioAtendimento['horario_inicio_segunda_feira'] : '09:00:00'}
                                        onChangeText={(value) => changeHorarioAtendimento('horario_inicio_segunda_feira',value)}
                                    />
                                    <span>às</span>
                                    <InputDefaultTime
                                        value={horarioAtendimento['horario_final_segunda_feira'] ? horarioAtendimento['horario_final_segunda_feira'] : '18:00:00'}
                                        onChangeText={ (value) => changeHorarioAtendimento('horario_final_segunda_feira',value)}
                                    />
                                    <ButtonLinear className={'success'} onClick={() => iniciaModalParaEdicao('mensagem_segunda_feira')} >
                                        Mensagem de exceção
                                    </ButtonLinear>
      
                                    <InputSwitchDefault
                                        className='active'
                                        options={[
                                            {
                                                label: 'Ativo',
                                                value: '1',
                                            },
                                        ]}
                                        value={[horarioAtendimento.segunda_feira.toString()]}
                                        onChange={(value) => changeHorarioAtendimentoSwitch('segunda_feira',value)}
                                    />
                                </FieldGroup>
                            </CardEditHours>
                        </SectionHours>
                        <SectionHours>
                            <CardEditHours>
                                <InfoHours>
                                    <span>Terça Feira</span>
                                </InfoHours>
                                <FieldGroup>    
                                    <InputDefaultTime
                                        value={horarioAtendimento['horario_inicio_terca_feira'] ? horarioAtendimento['horario_inicio_terca_feira'] : '09:00:00'}
                                        onChangeText={(value) => changeHorarioAtendimento('horario_inicio_terca_feira',value)}
                                    />
                                    <span>às</span>
                                    <InputDefaultTime
                                        value={horarioAtendimento['horario_final_terca_feira'] ? horarioAtendimento['horario_final_terca_feira'] : '18:00:00'}
                                        onChangeText={ (value) => changeHorarioAtendimento('horario_final_terca_feira',value)}
                                    />
                                    <ButtonLinear className={'success'} onClick={() => iniciaModalParaEdicao('mensagem_terca_feira')} >
                                       Mensagem de exceção
                                    </ButtonLinear>

                                    <InputSwitchDefault
                                        className='active'
                                        options={[
                                            {
                                                label: 'Ativo',
                                                value: '1',
                                            },
                                        ]}
                                        value={[horarioAtendimento.terca_feira.toString()]}
                                        onChange={(value) => changeHorarioAtendimentoSwitch('terca_feira',value)}
                                    />
                                </FieldGroup>
                            </CardEditHours>
                        </SectionHours>
                        <SectionHours>
                            <CardEditHours>
                                <InfoHours>
                                    <span>Quarta Feira</span>
                                </InfoHours>
                                <FieldGroup>
                                    <InputDefaultTime
                                        value={horarioAtendimento['horario_inicio_quarta_feira'] ? horarioAtendimento['horario_inicio_quarta_feira'] : '09:00:00'}
                                        onChangeText={(value) => changeHorarioAtendimento('horario_inicio_quarta_feira',value)}
                                    />
                                    <span>às</span>
                                    <InputDefaultTime
                                        value={horarioAtendimento['horario_final_quarta_feira'] ? horarioAtendimento['horario_final_quarta_feira'] : '18:00:00'}
                                        onChangeText={ (value) => changeHorarioAtendimento('horario_final_quarta_feira',value)}
                                    />
                                    <ButtonLinear className={'success'} onClick={() => iniciaModalParaEdicao('mensagem_quarta_feira')} >
                                       Mensagem de exceção
                                    </ButtonLinear>
                                    <InputSwitchDefault
                                        className='active'
                                        options={[
                                            {
                                                label: 'Ativo',
                                                value: '1',
                                            },
                                        ]}
                                        value={[horarioAtendimento.quarta_feira.toString()]}
                                        onChange={(value) => changeHorarioAtendimentoSwitch('quarta_feira',value)}
                                    />
                                </FieldGroup>
                            </CardEditHours>
                        </SectionHours>
                        <SectionHours>
                            <CardEditHours>
                                <InfoHours>
                                    <span>Quinta Feira</span>
                                </InfoHours>
                                <FieldGroup>
                                    <InputDefaultTime
                                        value={horarioAtendimento['horario_inicio_quinta_feira'] ? horarioAtendimento['horario_inicio_quinta_feira'] : '09:00:00'}
                                        onChangeText={(value) => changeHorarioAtendimento('horario_inicio_quinta_feira',value)}
                                    />
                                    <span>às</span>
                                    <InputDefaultTime
                                        value={horarioAtendimento['horario_final_quinta_feira'] ? horarioAtendimento['horario_final_quinta_feira'] : '18:00:00'}
                                        onChangeText={ (value) => changeHorarioAtendimento('horario_final_quinta_feira',value)}
                                    />
                                    <ButtonLinear className={'success'} onClick={() => iniciaModalParaEdicao('mensagem_quinta_feira')} >
                                       Mensagem de exceção
                                    </ButtonLinear>
                                    <InputSwitchDefault
                                        className='active'
                                        options={[
                                            {
                                                label: 'Ativo',
                                                value: '1',
                                            },
                                        ]}
                                        value={[horarioAtendimento.quinta_feira.toString()]}
                                        onChange={(value) => changeHorarioAtendimentoSwitch('quinta_feira',value)}
                                    />
                                </FieldGroup>
                            </CardEditHours>
                        </SectionHours>
                        <SectionHours>
                            <CardEditHours>
                                <InfoHours>
                                    <span>Sexta Feira</span>
                                </InfoHours>
                                <FieldGroup>
                                    <InputDefaultTime
                                        value={horarioAtendimento['horario_inicio_sexta_feira'] ? horarioAtendimento['horario_inicio_sexta_feira'] : '09:00:00'}
                                        onChangeText={(value) => changeHorarioAtendimento('horario_inicio_sexta_feira',value)}
                                    />
                                    <span>às</span>
                                    <InputDefaultTime
                                        value={horarioAtendimento['horario_final_sexta_feira'] ? horarioAtendimento['horario_final_sexta_feira'] : '18:00:00'}
                                        onChangeText={ (value) => changeHorarioAtendimento('horario_final_sexta_feira',value)}
                                    />
                                    <ButtonLinear className={'success'} onClick={() => iniciaModalParaEdicao('mensagem_sexta_feira')} >
                                       Mensagem de exceção
                                    </ButtonLinear>
                                    <InputSwitchDefault
                                        className='active'
                                        options={[
                                            {
                                                label: 'Ativo',
                                                value: '1',
                                            },
                                        ]}
                                        value={[horarioAtendimento.sexta_feira.toString()]}
                                        onChange={(value) => changeHorarioAtendimentoSwitch('sexta_feira',value)}
                                    />
                                </FieldGroup>
                            </CardEditHours>
                        </SectionHours>
                        <SectionHours>
                            <CardEditHours>
                                <InfoHours>
                                    <span>Sabado</span>
                                </InfoHours>
                                <FieldGroup>
                                    <InputDefaultTime
                                        value={horarioAtendimento['horario_inicio_sabado'] ? horarioAtendimento['horario_inicio_sabado'] : '09:00:00'}
                                        onChangeText={(value) => changeHorarioAtendimento('horario_inicio_sabado',value)}
                                    />
                                    <span>às</span>
                                    <InputDefaultTime
                                        value={horarioAtendimento['horario_final_sabado'] ? horarioAtendimento['horario_final_sabado'] : '18:00:00'}
                                        onChangeText={ (value) => changeHorarioAtendimento('horario_final_sabado',value)}
                                    />
                                    <ButtonLinear className={'success'} onClick={() => iniciaModalParaEdicao('mensagem_sabado')} >
                                       Mensagem de exceção
                                    </ButtonLinear>
                                    <InputSwitchDefault
                                        className='active'
                                        options={[
                                            {
                                                label: 'Ativo',
                                                value: '1',
                                            },
                                        ]}
                                        value={[horarioAtendimento.sabado.toString()]}
                                        onChange={(value) => changeHorarioAtendimentoSwitch('sabado',value)}
                                    />
                                </FieldGroup>
                            </CardEditHours>
                        </SectionHours>
                        <SectionHours>
                            <CardEditHours>
                                <InfoHours>
                                    <span>Domingo</span>
                                </InfoHours>
                                <FieldGroup>
                                    <InputDefaultTime
                                        value={horarioAtendimento['horario_inicio_domingo'] ? horarioAtendimento['horario_inicio_domingo'] : '09:00:00'}
                                        onChangeText={(value) => changeHorarioAtendimento('horario_inicio_domingo',value)}
                                    />
                                    <span>às</span>
                                    <InputDefaultTime
                                        value={horarioAtendimento['horario_final_domingo'] ? horarioAtendimento['horario_final_domingo'] : '18:00:00'}
                                        onChangeText={ (value) => changeHorarioAtendimento('horario_final_domingo',value)}
                                    />
                                    <ButtonLinear className={'success'} onClick={() => iniciaModalParaEdicao('mensagem_domingo')} >
                                       Mensagem de exceção
                                    </ButtonLinear>
                                    <InputSwitchDefault
                                        className='active'
                                        options={[
                                            {
                                                label: 'Ativo',
                                                value: '1',
                                            },
                                        ]}
                                        value={[horarioAtendimento.domingo.toString()]}
                                        onChange={(value) => changeHorarioAtendimentoSwitch('domingo',value)}
                                    />
                                </FieldGroup>
                            </CardEditHours>
                        </SectionHours>
                        
                    </>
                }

            <ModalDefault
                id="overlayModal"
                width="550px"
                onClose={() => setModal(!modal)}
                openModal={modal}
                
            >
                <Title>
                    <h3 style={{marginTop:'10px', paddingTop:'10px'}} >Configurar Mensagem de Exceção</h3>
                </Title>
                <Field gap="5px" marginTop="16px" >

                    { horarioAtendimento[messageKeyEdit as keyof HorarioAtendimento] && 
                        <CardMessage
                                messageType={'recebida'}
                                hasView={true}
                                backgroundEnviada={'#000'}
                                backgroundRecebida={'#000'}
                                color={'#FFF'}
                                
                            >
                                <div className="content-message" style={{marginTop:'15px', marginBottom:'15px'}}>
                                    
                                    <p style={{wordBreak: 'break-word', margin: 0}} dangerouslySetInnerHTML={{__html: convertMessage(horarioAtendimento[messageKeyEdit as keyof HorarioAtendimento])}}></p>
                                </div>

                                <div className="status">
                                    <span>
                                        {Datetime.string(moment().format('YYYY-MM-DD HH:mm:ss'), true)}
                                    </span>
                                    <IconShape />
                                </div>
                        </CardMessage>
                    }
                    

                    <FooterChat backgroundColor={"#000"} style={{marginTop:'30px', paddingTop:'15px'}}>
                    </FooterChat>
                        <form className='formMessagePre'>
                            <TextAreaInputDefault
                                handleOnKeyPress={() => {}}
                                heightEmoji="110px"
                                value={horarioAtendimento[messageKeyEdit as keyof HorarioAtendimento]}
                                onChange={(e: any) => changeHorarioAtendimento(messageKeyEdit, e.target.value)}
                            />
                        </form>
                </Field>

                <FieldGroup marginTop="16px">
                    <ButtonLinear onClick={() => setModal(!modal)}>Cancelar</ButtonLinear>
                    <ButtonLinear className="success" onClick={() => saveHorarioAtendimento()}>Salvar</ButtonLinear>
                </FieldGroup>
            </ModalDefault>

            <ModalDefault
                id="overlayModal"
                width="550px"
                onClose={() => setModal(!newModal)}
                openModal={newModal}
                
            >
                <Field gap="5px" marginTop="16px">

                    <h3 className="subtitlemodal">Dia e horario da exceção</h3>
                    <CardEditHours style={{padding: 0, marginTop: 0, height: 'auto', marginBottom: 20}}>
                        <FieldGroup>
                            <InputDefault
                                type="date"
                                value={DTOModal.data}
                                onChangeText={(value) => handleOnChangeDTO('data', value)}
                            />
                            <InputDefaultTime
                                value={DTOModal.horario_inicio}
                                onChangeText={(value) => handleOnChangeDTO('horario_inicio', value)}
                            />
                            <span className='as'>às</span>
                            <InputDefaultTime
                                value={DTOModal.horario_final}
                                onChangeText={ (value) => handleOnChangeDTO('horario_final', value)}
                            />
                        </FieldGroup>
                    </CardEditHours>

                    <h3 className="subtitlemodal">Mensagem da exceção</h3>
                    <CardMessage
                        messageType={'enviada'}
                        hasView={true}
                        color={'#FFF'}
                        
                    >
                        <div className="content-message" style={{marginTop:'15px', marginBottom:'15px'}}>
                            <p style={{wordBreak: 'break-word', margin: 0}} dangerouslySetInnerHTML={{__html: convertMessage(DTOModal.mensagem)}}>
                                {/* {DTOModal.mensagem} */}
                            </p>
                        </div>

                        <div className="status">
                            <span>
                                {Datetime.string(moment().format('YYYY-MM-DD HH:mm:ss'), true)}
                            </span>
                            <IconShape />
                        </div>
                    </CardMessage>
                    
                    <FooterChat backgroundColor={"#000"} style={{marginTop:'15px', paddingTop:'30px'}}>
                    </FooterChat>
                        <form className='formMessagePre' onSubmit={handleSaveExcpetion}>
                            <TextAreaInputDefault
                                heightEmoji="110px"
                                handleOnKeyPress={() => {}}
                                value={DTOModal.mensagem}
                                onChange={(e: any) => handleOnChangeDTO('mensagem', e.target.value)}
                            />
                        </form>
                </Field>

                <FieldGroup marginTop="32px">
                    <ButtonLinear onClick={() => setNewModal(!newModal)}>Cancelar</ButtonLinear>
                    <ButtonLinear className="success" onClick={() => handleSaveExcpetion()}>
                        {DTOModal.id_horario_atendimento_excecao ? 'Salvar' : 'Inserir'}
                    </ButtonLinear>
                </FieldGroup>
            </ModalDefault>

            </Container>
        </>
        )
}
