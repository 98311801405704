import styled from "styled-components"

export const Container = styled.button`
   display: flex;
   width: 100%;
   height: 48px;
   border-radius: 8px;
   border: none;
   justify-content: center;
   font-size: 16px;
   line-height: 24px;
   align-items: center;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
   cursor: pointer;
   transition: all .2s;
   &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
   }
   &.info {
       background: ${props => props.theme.colors.skyblue};
       color: #FFF;
   }
   svg {
       font-size: 25px;
       cursor: pointer;
   }
`