import { useUser } from '../../contexts/UserContext';
import ButtonLinear from '../form/ButtonLinear'
import { 
    Container, 
    Header, 
    Content,
    Profile,
    Perfil,
    SectionIcon,
    SectionInfo,
    Temp,
    TempLeft,
    TempRight,
} from './styles'

interface InfoProps {
    title: string;
    subtitle: boolean;
    timeRest: string;
    returnTime?: string;
    button: boolean;
    Icon: any;
}

interface ModalProps {
    id: string;
    onClose: () => void;
    openModal: boolean;
    infos: InfoProps;
}

export default function ModalUser({ id, onClose, openModal, infos }: ModalProps) {
    const { user, userEyou } = useUser();
    const handleCloseModal = (e: any) => {
        if (e.target.id === id) {
            onClose()
        }
    }
    
    return (
        <Container id={id} openModal={openModal} onClick={handleCloseModal}>
           <Header>

            <Profile>
                <div
                    className="image"
                    style={{ backgroundImage: `url(${user.foto})` }}
                />
                <Perfil>
                    <h2>{userEyou.name}</h2>
                    <span className="active">Pausa</span>
                </Perfil>
            </Profile>
           </Header>
           <Content>

                <SectionIcon/>

                <SectionInfo>
                    <h1>{infos.title} <span>{userEyou.name}</span></h1>

                    {infos.subtitle && (
                        <span>No momento você está em pausa, deseja retomar os atendimentos?</span>
                    )}

                    <Temp style={{display: 'none'}}>
                        <TempLeft>
                            <h3>tempo restante:</h3>
                            <span>{infos.timeRest}</span>
                        </TempLeft>

                        <TempRight>
                            <h3>Retornar até</h3>
                            <span>{infos.returnTime}</span>
                        </TempRight>
                    </Temp>
                </SectionInfo>

                {infos.button && (
                    <ButtonLinear 
                        className='info'
                        onClick={onClose}
                    >
                        Retomar
                    </ButtonLinear>
                )}
           </Content>
        </Container>
    )
}
