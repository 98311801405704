import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ButtonLinear from '../../../components/form/ButtonLinear';
import InputDefault from '../../../components/form/InputDefault';

import BackPage from '../../../components/layout/UIElements/BackPage';
import Loading from '../../../components/Loading/Loading';
import { useRoutes } from '../../../contexts/RoutesContext';
import apiTropa from '../../../services/apiTropa';

import { Container, Header, Content, CardTitle, CardEntryForm } from './styles';

export default function FormularioDeEntrada(props: any) {
    const history = useHistory();
    const id = props.match.params.id;
    const { setPageName } = useRoutes();
    const [data, setData] = useState<any>({
        dados_formulario: [],
    });
    const [load, setLoad] = useState(true)

    useEffect(() => {
        setPageName('Formulario de entrada');
        async function getData() {
            const response: any = await apiTropa.get(
                'formulario-entrada/grupo-atendimento/' + id
            );
            const responseData = response.data.data[0];
            responseData.dados_formulario = JSON.parse(
                responseData.dados_formulario
            );
            setData({ ...responseData });
            setLoad(false)
        }
        getData();
    }, [id]);

    return (
        <>
            <Loading active={load}/>
            <Container>
                <Header>
                    <BackPage />

                    <ButtonLinear
                        className="info"
                        onClick={() =>
                            history.push(
                                '/grupos-de-atendimento/' +
                                    id +
                                    '/formulario-de-entrada/edit'
                            )
                        }
                    >
                        Configurar
                    </ButtonLinear>
                </Header>

                <Content>
                    <CardTitle>
                        <h2 style={{color: data.cor_titulo}}>{data.titulo}</h2>
                        <p style={{color: data.cor_descricao}}>{data.descricao}</p>
                    </CardTitle>

                    <CardEntryForm>
                        {data.dados_formulario.map((row: any, key: any) => (
                            <InputDefault
                                key={key}
                                value=""
                                placeholder={row}
                                onChangeText={() => console.log('texto')}
                            />
                        ))}
                    </CardEntryForm>
                </Content>
            </Container>
        </>
    );
}
