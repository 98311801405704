import styled from 'styled-components';


export const Container = styled.div`
    display: flex;
    > div {
        cursor: pointer;
        width: 32px;
        height: 32px;
        border: 1px solid ${props => props.theme.colors.skyblue};
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${props => props.theme.colors.skyblue};
        font-size: 13px;
        background: transparent;
        &.active{
            background: ${props => props.theme.colors.skyblue};
            color: #fff;
        }
    }
`;
