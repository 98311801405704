import { useState } from 'react';
import InputDefault from '../../form/InputDefault';
import { IconClose } from '../../icons';
import ChatGroups from '../../OperatorChatInternal/ChatGroups';
import ChatOperators from '../../OperatorChatInternal/ChatOperators';
import { 
    Container, 
} from './styles';

interface PopUpProps {
    showPopUp: boolean,
    setShowPopUp: (value: any) => void;
    handleMessagesChatInternal?:(key: any, value: any) => void
    users?: any[];
    name: 'Operadores' | 'Grupos'
    token?:any,
    tokenInicial?:any
}

export default function PopUpAttendence({
    showPopUp,
    setShowPopUp,
    handleMessagesChatInternal = () => {},
    users = [],
    name,
    token,
    tokenInicial
}: PopUpProps) {

    function handleSetTotal(total: number)
    {
        handleMessagesChatInternal(name.toLowerCase(), total)
    }

    return (
        <>
            <Container popUpAttendence={showPopUp}>
                
                {name === 'Operadores' ? (
                    <ChatOperators token={token} tokenInicial={tokenInicial} handleSetTotal={handleSetTotal} showPopUp={showPopUp} setShowPopUp={setShowPopUp}/>
                ) : (
                    <ChatGroups token={token} tokenInicial={tokenInicial} handleSetTotal={handleSetTotal} showPopUp={showPopUp} setShowPopUp={setShowPopUp}/>
                )}

            </Container>
        </>
    )
}
