import { IconBold, IconItalic, IconQuote } from '../../icons';
import { Container, ButtonEmojiChat } from './styles';

interface QuoteProps {
    active: boolean;
    insertQuote(quote: string): void;
}

export default function CardQuote({ active, insertQuote }: QuoteProps) {
    const quotes = [
        {
            id: 1,
            quote: '[b]-[/b]',
            icon: <IconBold />
        },
        {
            id: 2,
            quote: '[i]-[/i]',
            icon: <IconItalic />
        },
        {
            id: 3,
            quote: '[quote]-[/quote]',
            icon: <IconQuote />
        },
    ]

    return (
        <Container active={active}>
            {quotes.map((row: any) => (
                <ButtonEmojiChat
                    type="button"
                    onClick={() => insertQuote(row.quote)}
                    key={row.id}
                >
                    {row.icon}
                </ButtonEmojiChat>
            ))}
        </Container>
    );
}
