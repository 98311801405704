export function convertMessage(text:any) {
	var urlRegex = /(https?:\/\/[^\s]+)/g;

	text = bold(text)
	return text.replace(urlRegex, function(url:any) {
		return '<a target="_blank" href="' + url + '">' +  url.replace('[/b]', ' ').replace('[/i]', ' ') + '</a>';
	})
	.replaceAll('*️⃣', '&ast;&#xFE0F;&#x20E3;')
	.replaceAll(/\[(b|i|u|s)\](.*?)\[\/\1\]/gs, "<$1>$2</$1>") //b|i|u|s
	.replaceAll(/(\*)(.*?)\/\1/gs, "<a>$2</b>") //*
	.replaceAll('_', '<i>').replaceAll('_', '</i> ') //_
	.replaceAll(/\[(quote)\](.*?)\[\/\1\]/gs, "<span class='quote'>$2</span>") //quote
	.replaceAll('[quote]', '').replaceAll("[/quote]", "")
}

function bold(text:any){
	var bold = /\*(.*?)\*/gm;
    var html = text.replace(bold, '<strong>$1</strong>');
    return html;
}