
import styled from "styled-components"

export const ContainerLayout = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100%;
    .content {
        flex: 1;
        padding: 0px 32px;
        overflow: auto;
        max-height: 100vh;
        @keyframes pageReload {
            from {
                filter: blur(50px);
            }
            to {
                filter: blur(0px);
            }
        }
        .content-page > div {
            animation: pageReload .5s;
        }
    }
`
interface MenuOpen {
    open: boolean
}
export const ContainerSidebar = styled.div<MenuOpen>`
    width: 290px;
    height: 100%;
    background: ${props => props.theme.colors.spaceCadedDark};
    header {
        padding: 60px 0 0 0;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        .logo-default {
            width: 160px;
            height: 31px;
        }
    }
    nav {
        margin-top: 44px;
        padding: 0px;
        height: calc(100vh - 95px - 44px);
        ul {
            list-style: none;
            margin: 0px;
            padding: 0px;
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    }
    .menu {
        display: none;
    }
    @media (max-width: 425px) {        
        position: fixed;
        z-index: 999;
        /* transition: all .2s; */
        transform: ${ open => open.open ? 'translateX(0)' : 'translateX(-290px)'};
        .menu {
            position: absolute;
            top: 1%;
            left: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 2rem;
            height: 2rem;
            background: transparent;
            border: none;
            cursor: pointer;
            padding: 0;
            z-index: 10;
            margin-left: ${ open => open.open ? '0' : '290px'};
            z-index: 9999;
            &:focus {
                outline: none;
            }
            div {
                width: 2rem;
                height: 0.25rem;
                background: ${({ open }) => open ? '#FFF' : '#FFF'};
                border-radius: 10px;
                transition: all 0.1s linear;
                position: relative;
                transform-origin: 1px;

                :first-child {
                transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
                }

                :nth-child(2) {
                opacity: ${({ open }) => open ? '0' : '1'};
                transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
                }

                :nth-child(3) {
                transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
                }
            }
        }
    }
`

interface IMenuItem {
    active?: boolean
}
export const ContainerSidebarMenuItem = styled.li<IMenuItem>`
    width: 100%;
    position: relative;
    @keyframes effectBulletIn {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(5.5);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes effectBulletOut {
        0% {
            left: -4px;
            width: 8px;
            height: 8px;
        }
        50% {
            left: -20px;
            width: 8px;
            height: 8px;
        }
        100% {
            left: -30px;
            width: 8px;
            height: 8px;
        }
    }
    &:after {
        animation: ${props => props.active === true ? 'effectBulletIn .5s linear' : 'effectBulletOut 3s linear'};
        width: 8px;
        height: 8px;
        border-radius: 100px;
        position: absolute;
        left: ${props => props.active === true ? '-4px' : '-30px'};
        top: calc(50% - 4px);
        content: '';
        transition: all .5s;
        box-shadow: 0px 0px 9px 0px ${props => props.theme.colors.skyblue};
        background: ${props => props.theme.colors.skyblue};
    }
    a {
        box-sizing:border-box;
        align-items: center;
        display: flex;
        padding: 0 36px;
        height: 72px;
        text-decoration: none;
        svg {
            transition: all .5s;
            margin-right: 24px;
            width: 19px !important;
            height: 19px !important;
            transform: ${props => props.active ? 'scale(1.3)' : 'scale(1)'};
            path {
                transition: all .2s;
                fill: ${props => props.active === true ? props.theme.colors.skyblue : props.theme.colors.lavanderGray};
            }
            &.stroke {
                path {
                    fill: transparent;
                    stroke: ${props => props.active === true ? props.theme.colors.skyblue : props.theme.colors.lavanderGray};
                }
            }
        }
        span {
            transition: all .2s;
            font-size: 16px;
            color: ${props => props.active === true ? props.theme.colors.skyblue : props.theme.colors.lavanderGray};
        }
    }
`

export const ContainerHeader = styled.div`
    height: 110px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
        font-size: 32px;
        color: ${({theme}) => theme.colors.titleHeader};
        font-weight: 500;
    }
    .profile {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
        .perfil {
            display: flex;
            /* margin-left: 24px; */
            align-items: center;
            position: relative;
            &:hover {
                .submenu {
                    margin-top: 10px;
                    opacity: 1;
                    visibility:visible;
                    z-index: 9;
                }
            }
            .submenu {
                position: absolute;
                background: #FFF;
                right: 0px;
                top: 30px;
                padding: 10px;
                border-radius: 5px;
                opacity: 0;
                visibility:hidden;
                transition: all .2s;
                a {
                    display: flex;
                    align-items:center;
                    cursor:pointer;
                    svg {
                        margin-right: 5px;
                    }
                    span {
                        font-size: 13px;
                    }
                }
            }
            .user {
                display: grid;
                place-items: center;

                width: 48px;
                height: 48px;

                border-radius: 50%;
                background: ${props => props.theme.colors.spaceCadedLight};
            }

            .image {
                width: 48px;
                height: 48px;
                border-radius: 100px;
                background-size: cover;
                background-color: rgba(0,0,0, .4);
                flex-shrink: 0;
            }
            .name {
                padding: 0px 16px;
                font-size: 16px;
                color: ${({theme}) => theme.colors.subTitle};
            }
        }
    }
    @media (max-width: 425px) {
        padding-top: 20px;
        h1 {
            font-size: 18px;
            margin-right: 5px;
        }
    }
`