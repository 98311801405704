import styled from "styled-components"


export const Container = styled.div`
    border: 1px solid ${props => props.theme.colors.silverSand};
    border-radius: 4px;
    background: ${props => props.theme.colors.spaceCaded};
    height: 40px;
    display: flex;
    align-items: center;
    position: relative;
    &:active {
        border: 1px solid ${props => props.theme.colors.white};
    }
    select {
        padding: 0px 51px 0px 21px;
        height: 100%;
        outline: none;
        border: none;
        flex: 1;
        max-width: 100%;
        color: ${props => props.theme.colors.white};
        background: ${props => props.theme.colors.spaceCaded};
        border-radius: 4px;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        z-index: 1;
        position: relative;
        text-overflow: '';
        &::placeholder {
            color: ${props => props.theme.colors.silverSand};
        }
    }
    svg {
        position: absolute;
        z-index: 2;
        right: 0px;
        top: 0px;
        margin: 17px 21px;
    }
`
