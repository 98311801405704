import { useEffect, useState } from 'react';
import UserList from '../../components/UserList';
import InputDefault from '../../components/form/InputDefault';
import { useRoutes } from '../../contexts/RoutesContext';
import { useMessage } from '../../contexts/MessagesContext';

import { Container, Main } from './styles';
import apiEyou from '../../services/apiEyou';
import { UserEyou } from '../../types/UserEyou';
import LinkLinear from '../../components/form/LinkLinear';
import ContainerFilter from '../../components/form/ContainerFilter';
import Loading from '../../components/Loading/Loading';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function Usuario() {
    const { setPageName } = useRoutes();
    const { addDanger } = useMessage();
    const [usuarios, setUsuarios] = useState<Array<UserEyou>>([]);
    const [filter, setFilter] = useState<String>('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setPageName('Usuários');
        getUsuarios();
    }, [setPageName]);

    async function getUsuarios() {
        try {
            let tokenInicial = window.sessionStorage.getItem('tokenInicial');

            let usuarioResult: any = await apiEyou.post(
                'apiUsuario',
                {
                    type: 'get',
                    function: 'listAllUsers',
                    data: {
                        filter: filter !== '' ? 'true' : 'false',
                        login: filter,
                    },
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + tokenInicial,
                    },
                }
            );


            setUsuarios(usuarioResult.data.data);
        } catch (e) {
            addDanger('Ocorreu um erro', 'Tente novamente mais tarde');
        }
    }

    useEffect(() => {
        setTimeout(() => setLoading(false), 600)
      }, [])


    return (
    <>
        { loading === true ? <Loading active={loading} /> : 
            <Container>
                <ContainerFilter>
                    <InputDefault
                        onChangeText={(value) => setFilter(value)}
                        value={''}
                        search={true}
                        onKeyUp={()=> getUsuarios()}
                        placeholder={'Buscar Usuário por Login'}
                    />

                    <LinkLinear className="info" to="/usuarios/novo">
                        Novo usuário
                    </LinkLinear>
                </ContainerFilter>

                <Main>
                    {usuarios && usuarios.length > 0
                        ? usuarios.map((row, key) => (<UserList data={row} key={key} />))
                        : 
                        <Skeleton 
                            count={10} 
                            className="skeletor"
                            height="85px"                        
                            borderRadius="16px"
                            baseColor="#262655"
                            highlightColor="#3e3b77"
                        />
                    }
                </Main>
            </Container>
        }
    </>
    );
}
