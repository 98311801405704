import styled, { css } from 'styled-components';

interface EmojiProps {
    active: boolean;
    isQuote?: boolean;
    heightEmoji?: string;
}

export const ContainerEmoji = styled.div<EmojiProps>`
    border-radius: 5px 5px 0px 0px;
    background: #f1f1f1;
    height: ${({ heightEmoji }) => heightEmoji ? heightEmoji : '200px'};
    position: absolute;
    top: ${({ isQuote, heightEmoji }) => isQuote ? `-${heightEmoji}` : '41px'};
    border-bottom: 20px solid #eaeef3;
    left: 0px;
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(20px, 1fr));
    place-items: center;
    gap: 16px;
    width: 100%;
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    transform: 
        translate(0)
        scale(1);

    transform-origin: 100% 0%;
    padding: 10px 30px 10px 20px;
    ${props => props.active && css`
        visibility: visible;
        opacity: 1;
        transform: translate(0) scale(1);
        animation: fadeIn .5s;
    `}

    overflow-y: scroll;
    ::-webkit-scrollbar {
        width: 6px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #cecece;
        border-radius: 4px;
    }
    scrollbar-color: #cecece transparent;
    scrollbar-width: thin;
`;

export const ButtonEmojiChat = styled.button`
    font-size: 16px;
    padding: 4px;

    border: none;
    background: transparent;

    display: grid;
    place-items: center;

`;
