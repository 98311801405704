import React, { useEffect, useState } from 'react';
import InputDefault from '../../form/InputDefault';
import CardChat from '../../../components/Chat/CardChat';

import {
    CardPerson,
    ChatContainer,
    Container,
    ContainerViewsMessages,
    ContentUsers,
    ListContainer,
    PersonImage,
    PersonInfo,
} from './styles';
import CardMessageRender from '../../Chat/CardMessageRender';
import { Header } from '../../Ui/PopUpAttendence/styles';
import { IconArrowLeft, IconClose } from '../../icons';
import Skeleton from 'react-loading-skeleton'
import apiTropa from '../../../services/apiTropa';
import moment from 'moment';
import { useUser } from '../../../contexts/UserContext';

interface IProps {
    showPopUp?: boolean;
    setShowPopUp?(boolean: boolean): void;
    handleSetTotal?(total:number): void
    token?:any
    tokenInicial?:any
}
export function ChatOperators({
    setShowPopUp = () => {},
    showPopUp = false,
    handleSetTotal = () => {},
    token,
    tokenInicial
}: IProps) {

    const { user } = useUser()
    const [search, setSearch] = useState<string>('')
    const [tab, setTab] = useState<string>('list');
    const [dataLoad, setDataLoad] = useState<boolean>(true)
    const [data, setData] = useState<any>([]);
    const [list, setList] = useState<any>([])
    const [selected, setSelected] = useState<any>({});
    const [messagesLoad, setMessagesLoad] = useState(true)
    const [messages, setMessages] = useState<any>([]);

    useEffect(() => {
        if ( showPopUp === false ) setTab('list')
    }, [showPopUp])

    useEffect(() => {
        if ( tab === 'list' && showPopUp === true ) setDataLoad(true)
        if ( tab === 'list') getData()
    }, [showPopUp, tab, user])

    useEffect(() => {
        setDataLoad(true)
        // const intervalUsers = setInterval(() => {
            getData()
        // }, 20000)
        // return () => clearInterval(intervalUsers)
    }, [])

    useEffect(() => {
        // const intervalMessages = setInterval(() => {
            getMessages()
        // }, 20000)
        // if ( !selected.id_login ) { 
        //     clearInterval(intervalMessages)
        // } else {
        //     getMessages()
        // }
        // return () => clearInterval(intervalMessages)
    }, [selected])

    useEffect(() => {
        setList([...data])
    }, [data])

    useEffect(() => {
        const result = data.filter(({nome}:any) => nome &&  nome.toLowerCase().includes(search.toLowerCase()))
        setList([...result])
    }, [search])

    async function getData()
    {
        try {
            const response:any = await apiTropa.get('chat-cliente-get-operadores', {
                headers: {
                    'Authorization': 'Bearer '+token
                }})
            const responseData:any = response.data.data.filter((obj:any) => obj.nome && obj.nome !== user.perfil?.nome)
            let totalItem:number = 0
            responseData.map((row:any) => {
                totalItem = totalItem + parseFloat(row.qtd_nao_lidas)
            })
            handleSetTotal(totalItem)
            setData([...responseData])
            setDataLoad(false)
        } catch (err:any) { 
            setDataLoad(false)
            setData([])
        }
    }

    async function getMessages()
    {
        try {
            if ( !selected.id_login ) throw "Nenhum operador selecionado."
            const response:any = await apiTropa.get(`chat-cliente-mensagem-listar/${selected.id_login}?perPage=100&currentPage=1`, {
                headers: {
                    'Authorization': 'Bearer '+token
                }})
            setMessages([...response.data.data.data])
            setMessagesLoad(false)
        } catch ( err: any) {
            console.log('getMEssages', err)
        }
    }

    async function handleSubmitMessage( params:any )
    {

        try {
            params.id_login = selected.id_login
            params.criado = moment().format('YYYY-MM-DDTHH:mm:00.000')+'Z'
            messages.push(params)
            setMessages([...messages])
            const response: any = await apiTropa.post('chat-cliente', params, {
                headers: {
                    'Authorization': 'Bearer '+token
                }})
            getMessages()
        } catch (err:any) {

        }

    }

    function handleOnSelected(data: any) {
        setSelected({ ...data });
        setTab('chat');
        setMessagesLoad(true);
        setMessages([]);
    }

    function handleOnBack()
    {
        setTimeout(() => {
            setSelected({})
        }, 500)
        setTab('list')
    }

    const [messagePreLoad, setMessagePreLoad] = useState('') 
    function handleQuote(message:any)
    {
        const newMessage:any = messagePreLoad+'[quote]'+message+'[/quote]'
        setMessagePreLoad(newMessage)
    }

    return (
        <Container tab={tab}>
            <ChatContainer>
                <Header>
                    <div>
                        <a onClick={handleOnBack}>
                            <IconArrowLeft />
                        </a>
                        <h1>{selected.nome}</h1>
                    </div>
                    <button
                        onClick={() => setShowPopUp(!showPopUp)}
                        className="closePopUp"
                    >
                        <IconClose color="#34D5FA" />
                    </button>
                </Header>

                <CardChat
                    messages={messages}
                    onSubmit={handleSubmitMessage}
                    height="385px"
                    heightPreview="470px"
                    noHeader={true}
                    messagePreLoad={messagePreLoad}
                    setMessagePreLoad={setMessagePreLoad}
                >

                    { messages.map(
                        (row: any, key: number) =>
                            row.mensagem && (
                                <CardMessageRender
                                    key={key}
                                    messageType={
                                        row.nome === selected.nome ? 'enviada' : 'recebida'
                                    }
                                    data={row}
                                    handleQuote={handleQuote}
                                />
                            )
                    )}
                </CardChat>
            </ChatContainer>

            <ListContainer>
                <Header>
                    <h1>Operadores</h1>
                    <button
                        onClick={() => setShowPopUp(!showPopUp)}
                        className="closePopUp"
                    >
                        <IconClose color="#34D5FA" />
                    </button>
                </Header>

                <ContentUsers>
                    <InputDefault
                        onChangeText={(value) => setSearch(value)}
                        value={''}
                        search={true}
                        placeholder={'Buscar'}
                        style={{ minHeight: 40 }}
                    />

                    <h2>Todos operadores</h2>

                    {list.length === 0 && dataLoad === false && (
                        <p className="empty">Nenhum operador encontrado.</p>
                    )}

                    { dataLoad === true && 
                        <Skeleton count={5} height={40} style={{marginBottom: 16}} />
                    }

                    { dataLoad === false && list.filter((obj:any) => obj.nome !== user.perfil?.nome).map((row: any, key: any) => (
                        <CardPerson
                            key={key}
                            onClick={() => handleOnSelected(row)}
                        >
                            <PersonImage style={{backgroundImage: `url(${row.image ? row.image : 'https://ui-avatars.com/api/?name='+row.nome})`}}/>
                            <PersonInfo status={row.status_operador}>
                                <p>
                                    <span>{row.nome}</span>
                                    { row.status_operador === 'online' &&
                                        <i className={`ball ${row.status_operador}`}></i>
                                    }
                                </p>
                            </PersonInfo>
                            { row.qtd_nao_lidas > 0 && 
                                <ContainerViewsMessages>{row.qtd_nao_lidas}</ContainerViewsMessages>
                            }
                        </CardPerson>
                    ))}
                </ContentUsers>
            </ListContainer>
        </Container>
    );
}

export default ChatOperators;
