import styled from 'styled-components'

interface ContainerProps {
    bold?: boolean
}

export const Container = styled.div<ContainerProps>`
    height: 40px;
    .label {
        color: ${props => props.theme.colors.lavanderGray};
        font-size: 12px;
    }
    .content-value {
        display: flex;
        align-items: center;
        span {
            font-size: 16px;
            color: ${props => props.theme.colors.white};
            font-weight: ${props => props.bold ? 'bold' : 'normal'};
            display: flex;
            align-items: center;
            &.password {
                padding-top: 8px;
            }
        }
        button {
            display: flex;
            background: none;
            border: none;
            svg {
                margin-left: 10px;
                path {
                    fill: ${props => props.theme.colors.skyblue};
                }
            }
        }
    }
`