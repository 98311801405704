import React, { useEffect, useState } from 'react';
import { IconArrow, IconSearch } from '../../icons';
import InputDefault from '../InputDefault';
import InputRadioDefault from '../InputRadioDefault';

import { Container } from './styles';

interface InputProps {
    icon?: any,
    placeholder: string,
    list: any[],
    align?: string,
    checked?: any,
    name: any,
    multiple?: boolean,
    onChange(name: string, value: string):void
}

const SelectDefaultSearch: React.FC<InputProps> = ({
    icon = <IconSearch />,
    placeholder = "", 
    list = [],
    align = "right",
    checked,
    name = "",
    multiple = true,
    onChange = (name, value) => {}
}) => {
    
    const [array, setArray] = useState<any>(list)
    const [open, setOpen] = useState<boolean>(false)
    const [checkeds, setCheckeds] = useState<any>( multiple ? [] : '' )
    const [search, setSearch] = useState<any>('')

    document.addEventListener('click', function (event:any) {
        if (!event.target.closest('#'+name)) setOpen(false)
    }, false)

    useEffect(() => {
        if ( checked ) setCheckeds([...checked])
    }, [checked])

    function handleOnChange(name:any, value:any)
    {
        if ( checkeds.filter( (obj:any) => obj === value).length > 0 ) {
            onChange(name, checkeds.filter( (obj:any) => obj !== value))
        } else {
            checkeds.push(value)
            onChange(name, checkeds)
        }
    }

    function handleSearch(busca:any)
    {
        setSearch(busca)
        const result = list.filter(({label}) => label.toLowerCase().includes(busca.toLowerCase()))
        setArray([...result])
    }

    return (
        <Container id={name} className={`form-children-default select list ${open && "active"}`} >

            <div onClick={() => setOpen(!open)} className="container-input-click">
                {icon && icon}
                {placeholder && <p className="title">{placeholder}</p>}
                <span className="arrow">
                    <IconArrow/>
                </span>
            </div>

            <div className={`group ${align}`}>
            
                <InputDefault
                    search={true}
                    type="text" 
                    placeholder="Buscar" 
                    onChangeText={(value) => handleSearch(value)}
                    value={search}
                />

                {array.map((row:any, key:any) => (
                    
                    <InputRadioDefault
                        options={[
                            {
                                label: row.label,
                                value: row.value
                            }
                        ]}
                        value={checkeds.filter( (obj:any) => obj === row.value).length > 0 ? row.value : false}
                        onChange={(value) => handleOnChange(name, row.value)}
                    />
                ))}

            </div>
        </Container>
    );
};

export default SelectDefaultSearch;
