import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
    background: red;
    border-radius: 5px; 
    background: ${(props) => props.theme.colors.spaceCadedLight};
    hr {
        border-bottom: 1px solid ${(props) => props.theme.colors.stateBlue};
    }
    .info {
        margin: 25px;
    }
    .tags {
        padding: 0 15px 8px 15px;
        width: 100%;
        max-height: 140px;
        margin-bottom: 10px;
        
        &.hasVinc {
            min-height: 100px;
            overflow-y: scroll;
            ::-webkit-scrollbar {
                display: inline;
                width: 6px;
                background-color: #59588D;
                border-radius: 4px;
            }
            ::-webkit-scrollbar-thumb {
                background-color: #8383B2;
                border-radius: 2px;
            }
            scrollbar-color: #8383B2 transparent;
            scrollbar-width: thin;

        }
        ::-webkit-scrollbar {
            display: none;
        }

        h3 {
            color: ${(props) => props.theme.colors.white};
            font-size: 16px;
            padding: 5px 0 15px 0;
        }
        svg {
            width: 20px;
            height: 20px;
            margin-left: 10px;
            path {
                fill: ${(props) => props.theme.colors.lightCoral};            
            }
        }
    }  
    .tags-plus {
        padding: 0 15px;
        width: 100%;
        overflow-y: scroll;
        ::-webkit-scrollbar {
            width: 6px;
            background-color: #59588D;
            border-radius: 4px;
        }
        ::-webkit-scrollbar-thumb {
            background-color: #8383B2;
            border-radius: 2px;
        }
        scrollbar-color: #8383B2 transparent;
        scrollbar-width: thin;
        h3 {
            color: ${(props) => props.theme.colors.white};
            font-size: 16px;
            padding: 5px 0 15px 0;
        }
        svg {
            width: 20px;
            height: 20px;
            margin-left: 10px;
            path {
                fill: ${(props) => props.theme.colors.turquoiseBlue};
            }
        }
        .flex-tags {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    } 
    .observations {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;        
    }        
`
export const Title = styled.div`
    display: flex;
    h3 {
        color: ${(props) => props.theme.colors.white};
        font-size: 16px;
        padding: 24px 0 24px 24px;
    }
`

export const Buttons = styled.div`
    display: flex;
    width: 100%;
    /* height: 35px; */
    background: #000;
    margin-bottom: 30px;    
    button {
        width: 100%;
        background: #000;
        border: 1px solid ${(props) => props.theme.colors.spaceCaded};
        transition: 250ms ease-in-out;
        &:hover, &.active {
            border: 1px solid ${(props) => props.theme.colors.skyblue};    
            svg{
                path{
                    fill: ${(props) => props.theme.colors.skyblue};
                }
            }
        }
        &:focus, &.active {
            border: 1px solid ${(props) => props.theme.colors.skyblue};        
            svg{
                path{
                    fill: ${(props) => props.theme.colors.skyblue};
                }
            }
        }
    }    
`

export const Inputs = styled.div`
    width: 100%;
    display: flex;
    height: calc(100% - 220px);
    flex-direction: column;
    align-items: center;
    label.input {
        padding: 0px 21px 0px 0px;
    }     

    overflow-y: auto;
    ::-webkit-scrollbar {
        width: 6px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: ${({theme}) => theme.colors.scrolltrackBar};
        border-radius: 4px;
    }
    ::-webkit-scrollbar-track {
    background-color: ${({theme}) => theme.colors.scrolltrack};
  }
    scrollbar-color: ${({theme}) => theme.colors.scrolltrackBar} transparent;
    scrollbar-width: thin;
`

export const InputField = styled.div`
    margin-bottom: 25px;
    font-size: 16px;
    height: 50px;
    span {
        font-size: 10px;
        color: ${(props) => props.theme.colors.white};
    }
    select {
        width: 260px !important;
        border: 0 !important;
    }
`

export const User = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;

    margin-bottom: 10px;
    width: 100%;    
    .icon{
        display: flex;
        align-items: center;
        justify-content: center;

        margin-right: 10px;

        background: #DDDDEE;
        border-radius: 50%;

        height: 35px;
        width: 35px;
        svg{
            width: 18px;
        }
    }
    .user {
        color: ${(props) => props.theme.colors.white};
        span {
            font-weight: bold;
        }
    }
`

export const Msg = styled.div`
    display: flex;
    align-items: center;

    background: transparent;
    border-left: 1px solid ${(props) => props.theme.colors.blueCrayola};

    color: ${(props) => props.theme.colors.white};
    font-size: 90%;

    margin-left: 15px;
    margin-bottom: 20px;
    padding: 0 15px;
    width: 100%;
    min-height: 35px;
`