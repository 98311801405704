import {
    Container,
    Card,
    CardInfo,
    NameGroup,
    InfoLeft,
    InfoRight,
} from './styles';
import { UserEyou } from '../../types/UserEyou';
import LinkLinear from '../form/LinkLinear';
import StatusTable from '../../components/Table/StatusTable';

interface ListProps {
    data: UserEyou;
}

const UserList: React.FC<ListProps> = ({ data = {} }) => {
    return (
        <Container>
            <Card>
                <CardInfo>
                    <InfoLeft>
                        <NameGroup>
                            <h2>Nome</h2>
                            <span>
                                <b>{data.name}</b>
                            </span>
                        </NameGroup>
                    </InfoLeft>
                    <InfoLeft>
                        <NameGroup>
                            <h2>Login</h2>
                            <span>
                                <b>{data.login}</b>
                            </span>
                        </NameGroup>
                    </InfoLeft>
                    <InfoLeft>
                        <NameGroup>
                            <h2>E-mail</h2>
                            <span>{data.email}</span>
                        </NameGroup>
                    </InfoLeft>
                    <InfoLeft>
                        <NameGroup>
                            <h2>Celular</h2>
                            <span>{data.mobilePhone}</span>
                        </NameGroup>
                    </InfoLeft>


                    <InfoLeft>
                        <NameGroup>
                            <h2>Status</h2>
                            <StatusTable
                                    hasButton={false}
                                    status={data.status === 'ativo' ? true : false}
                                />
                        </NameGroup>
                    </InfoLeft>

                    <InfoRight>
                        <LinkLinear to={`/usuarios/visualizar/${data.login}`}>
                            Visualizar
                        </LinkLinear>
                    </InfoRight>
                </CardInfo>
            </Card>
        </Container>
    );
};

export default UserList;
