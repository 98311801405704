import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Datetime from '../../../Helper/Datetime';
import { convertMessage } from '../../../utils/ConvertMessages';
import {
    IconArrowMessageLeft,
    IconArrowMessageRight,
    IconDanger,
    IconDownload,
    IconFile,
    IconShape,
} from '../../icons';

import { Content, Container, NameUser } from './styles';

interface ChatProps {
    data: any;
    messageType: any;
    name?: any;
    backgroundEnviada?: string;
    backgroundRecebida?: string;
    color?: string;
    messageTime?: string;
    hasView?: boolean;
    renderName?: boolean
    canalMessage?: any
    handleQuote?(mensagem:any):void
}

export default function CardMessageRender({
    name = false,
    data = {},
    messageType,
    backgroundEnviada,
    backgroundRecebida,
    color,
    messageTime,
    hasView,
    canalMessage,
    handleQuote = () => {},
    renderName = false
}: ChatProps) {

    const [messageRegex, setMessageRegex] = useState<any>(data.mensagem)

    useEffect(() => {
        
        if ( data.mensagem ) setMessageRegex(convertMessage(data.mensagem))

    }, [data])

    // function urlify(text:any) {
    //     var urlRegex = /(https?:\/\/[^\s]+)/g;

    //     return text.replace(urlRegex, function(url:any) {
    //         return '<a target="_blank" href="' + url + '">' + url + '</a>';
    //     })
    //     .replaceAll('[b]', '<b>').replaceAll('[/b]', '</b> ')
    //     .replaceAll('[i]', '<i>').replaceAll('[/i]', '</i> ')
    //     .replaceAll('[quote]', '<span class="quote">').replaceAll('[/quote]', '</span>')
    //     .replaceAll('_', '<i>').replaceAll('_', '</i> ')
    //     .replaceAll('*', '<b>').replaceAll('*', '</b> ')
    // }

    return (
        <>
            {name && (messageType !== 'recebida' || renderName === true) && (
                <NameUser className={`name ${messageType}`}>
                    <span>{name}</span>
                </NameUser>
            )}
            <Container
                backgroundEnviada={backgroundEnviada}
                backgroundRecebida={backgroundRecebida}
                messageType={messageType}
            >
                <Content
                    messageType={messageType}
                    backgroundEnviada={backgroundEnviada}
                    backgroundRecebida={backgroundRecebida}
                    color={color}
                    hasView={hasView}
                    hasQuote={messageRegex?.includes('quote') ? true : false}
                >
                    <div className="content-message">

                        {data.tipo === 'alert' && <p><IconDanger/> {data.mensagem}</p>}
                        {data.tipo === 'texto' && <p dangerouslySetInnerHTML={{__html: messageRegex}} />}
                        {data.tipo === 'imagem' && (
                            <img src={data.url} alt={data.mensagem} />
                        )}
                        {data.tipo === 'video' && (
                            <video controls={true}>
                                <source src={data.url} />
                            </video>
                        )}
                        {data.tipo === 'audio' && (
                            <audio controls={true}>
                                <source src={data.url} />
                            </audio>
                        )}

                        {data.tipo === 'arquivo' && (
                            <a href={data.url} className="downloadFileChat">
                                <IconFile />
                                <span>{data.mensagem}</span>
                                <div className="iconDownloadChat">
                                    <IconDownload size="20" />
                                </div>
                            </a>
                        )}
                    </div>

                    <div className="status">

                        { !data.id_fluxograma ? 
                           <button onClick={() => handleQuote(data.mensagem)} className="button-quote">
                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path d="M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1H2zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z"/><path d="M7.066 4.76A1.665 1.665 0 0 0 4 5.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 1 0 .6.58c1.486-1.54 1.293-3.214.682-4.112zm4 0A1.665 1.665 0 0 0 8 5.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 1 0 .6.58c1.486-1.54 1.293-3.214.682-4.112z"/></g></svg>
                                <span>Responder</span>
                            </button>
                        : <span></span>}

                        <span>
                            {moment(data.criado)
                                .utc()
                                .format('DD/MM/YYYY HH:mm')}
                        </span>
                        
                    </div>
                </Content>
            </Container>
        </>
    );
}
