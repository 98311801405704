import React, { useCallback, useRef, useState } from 'react';
import CardEmoji from '../../Chat/CardEmoji';
import CardQuote from '../../Chat/CardQuote';
import { IconCheck, IconSend, IconSmile } from '../../icons';

import { Container } from './styles';

interface AreaProps extends React.HTMLAttributes<HTMLTextAreaElement> {
    refInputEdit?: any;
    disabled?: boolean;
    row?: number;
    value?: any;
    heightEmoji?: string;
    isButtonSend?: boolean;
    isButtonCheck?: boolean;
    radius?: boolean;
    handleOnKeyPress: (e: any) => void;
}

export default function TextAreaInputDefault({
    refInputEdit,
    disabled,
    radius,
    row,
    value,
    isButtonSend,
    isButtonCheck,
    heightEmoji = '200px',
    handleOnKeyPress,
    ...rest
}: AreaProps) {
    const inputRef = useRef<any>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [selectedInputChat, setSelectedInputChat] = useState(false);
    const [hasMenuEmoji, setHasMenuEmoji] = useState(false);

    const handleOnFocus = useCallback(() => {
        setSelectedInputChat(true);
    }, []);

    const handleOnBlur = useCallback(() => {
        setSelectedInputChat(false);
    }, []);

    function insertEmoji(val: any) {
        const step: any = inputRef.current;
        step.focus();
        const selection: any = window.getSelection();
        for (var i = 0; i < step; i += 1) {
            selection.modify('extend', 'backward', 'character');
        }
        document.execCommand('insertText', false, val);
    }

    function insertQuote(val: string) {
        const step: any = inputRef.current;
        step.focus();

        const selection: any = window.getSelection();
        var selectedText = selection.toString();

        const modifyTExt = val.replace('-', selectedText);
        document.execCommand('insertText', false, modifyTExt);
    }

    return (
        <Container radius={radius}>
            <CardEmoji
                heightEmoji={heightEmoji}
                active={hasMenuEmoji}
                insertEmoji={insertEmoji}
                isQuote={selectedInputChat || inputRef.current?.value || hasMenuEmoji}
            />

            <CardQuote
                active={selectedInputChat || inputRef.current?.value || hasMenuEmoji}
                insertQuote={insertQuote}
            />

            <button className="buttonEmojiChat" onClick={() => setHasMenuEmoji(!hasMenuEmoji)} type="button">
                <IconSmile />
            </button>

            <textarea
                className="textareaInputDefault"
                value={value}
                disabled={disabled}
                autoComplete="off"
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                rows={row ?? 1}
                ref={inputRef}
                onKeyPress={(e) => handleOnKeyPress(e)}
                {...rest}
            />

            {isButtonSend && (
                <button
                    className="buttonEmojiChat"
                    ref={buttonRef}
                    type="submit"
                    onClick={() => setHasMenuEmoji(false)}
                >
                    <IconSend />
                </button>
            )}

            {isButtonCheck && (
                <button
                    className="buttonEmojiChat"
                    ref={buttonRef}
                    type="submit"
                    onClick={() => setHasMenuEmoji(false)}
                >
                    <IconCheck />
                </button>
            )}
        </Container>
    );
}
