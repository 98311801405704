import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ButtonLinear from '../../components/form/ButtonLinear';
import { IconTrash } from '../../components/icons';
import {
    Td,
    TdDescription,
    TdSubtitle,
} from '../../components/layout/containers/Td';
import TableDefault from '../../components/TableDefault';
import { useMessage } from '../../contexts/MessagesContext';
import { useRoutes } from '../../contexts/RoutesContext';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Container, ContainerTable } from './styles';
import FailedRegister from '../../components/FailedRegister';

export default function Notification() {
    const { setPageName } = useRoutes();
    const { addDanger, addConfirm } = useMessage();
    const history = useHistory();
    const [data, setData] = useState<any[]>([]);
    const [load, setLoad] = useState(true);

    useEffect(() => {
        setPageName('Notificações');

        setTimeout(() => {
            setLoad(false);
        }, 1000);
    }, []);

    async function handleRemove(id: any) {}

    return (
        <Container>
            <ContainerTable>
                {load === false && data.length === 0 && <FailedRegister />}

                {load === false &&
                    data.map((row, key) => (
                        <TableDefault columns={2}>
                            <div className="status">
                                <div className="new" />
                                <Td flexDirection="column">
                                    <TdSubtitle>Notificação 1</TdSubtitle>
                                    <TdDescription>{row.chatbot}</TdDescription>
                                </Td>
                            </div>
                            <Td justifyContent="flex-end" flexDirection="row">
                                <ButtonLinear
                                    onClick={() =>
                                        addConfirm({
                                            title: 'Atenção',
                                            description:
                                                'Deseja mesmo remover essa notificação?',
                                            onConfirm: () =>
                                                handleRemove(
                                                    row.id_notifications
                                                ),
                                        })
                                    }
                                    Icon={IconTrash}
                                    className="delete"
                                />
                            </Td>
                        </TableDefault>
                    ))}
                {load === true && (
                    <Skeleton
                        count={10}
                        className="skeletor"
                        height="85px"
                        borderRadius="16px"
                        baseColor="#262655"
                        highlightColor="#3e3b77"
                    />
                )}
            </ContainerTable>
        </Container>
    );
}
