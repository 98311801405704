
import styled from "styled-components"

interface MessageProps {
    visible: boolean
}

export const Container = styled.div<MessageProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: ${props => props.visible ? 'visibility' : 'hidden'};
    opacity: ${props => props.visible ? 1 : 0};
    transition: all .5s;
    z-index: 99999999;
    .overlay {
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.8);
        border: none;
        animation: fadeIn 1s;
    }
    .message-container {
        position: fixed;
        top: 0;
        right: 0;
        padding: 50px;
        .box {
            display: flex;
            animation: ${props => props.visible ? 'backInDown 1s' : ''};
            padding: 25px;
            border-radius: 16px;
            position: relative;
            min-width: 300px;
            justify-content: space-between;
            .icon {
                position: absolute;
                width: 29px;
                height: 31px;
                top: -15px;
                left: 7px;
                i {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .text {
                display: flex;
                flex-direction: column;
                max-width: 350px;
                p {
                    margin: 0px;
                    color: #FFF;
                }
                .title {
                    font-size: 16px;
                    margin-bottom: 8px;
                }
                .message {
                    font-size: 14px;
                }
            }
            .close {
                background: transparent;
                border: none;
                outline: none;
                cursor: pointer;
                transition: all .2s;
                margin-left: 15px;
                &:hover {
                    transform: scale(1.4);
                }
            }
            &.success {
                background: ${props => props.theme.colors.oceanGreen};
                .icon {
                    > svg path {
                        fill: ${props => props.theme.colors.turquoiseBlue};
                    }
                }
            }
            &.warning {
                background: ${props => props.theme.colors.yellowCrayola};
                .icon {
                    > svg path {
                        fill: ${props => props.theme.colors.burntOrange};
                    }
                }
            }
            &.info {
                background: ${props => props.theme.colors.skyblue};
                .icon {
                    > svg path {
                        fill: ${props => props.theme.colors.blueCrayola};
                    }
                }
            }
            &.danger {
                background: ${props => props.theme.colors.lightCoral};
                .icon {
                    > svg path {
                        fill: ${props => props.theme.colors.redPigment};
                    }
                }
            }
        }
    }
`