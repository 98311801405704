import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ButtonLinear from '../../../components/form/ButtonLinear';
import InputSwitchDefault from '../../../components/form/InputSwitchDefault';
import SelectDefault from '../../../components/form/SelectDefault';
import { useRoutes } from '../../../contexts/RoutesContext';
import apiTropa from '../../../services/apiTropa';

import {
    Container,
    Header,
    Content,
    CardTitle,
} from './styles';

interface SelectChat {
    id_chatbot: number,
    chatbot: string,
}

export default function ConfigCanalEdit(props: any) {
    const history = useHistory();
    const { setPageName } = useRoutes();
    const [data, setData] = useState<any>({});
    const [inputSwitch, setInputSwitch] = useState<any>([]);
    const [chatOptions, setChatOptions] = useState<SelectChat[]>([])

    useEffect(() => {
        setPageName('Editar Configurações de canais');

        apiTropa.get('chatbot').then((res: any) => {
            setChatOptions(res.data.data);
        })
    }, [setPageName]);

    
    function handleOnChange(name: any, value: any) {
        data[name] = value;
        setData({ ...data });
    }

    async function saveEdicao() {
       console.log(data)
    }

    return (
        <Container>
            <Header>
                <ButtonLinear className="success" onClick={saveEdicao}>
                    Salvar
                </ButtonLinear>
                <ButtonLinear onClick={() => history.goBack()}>
                    Cancelar
                </ButtonLinear>
            </Header>

            <Content>
                <CardTitle>
                    <h2>Selecione uma opção</h2>

                    <InputSwitchDefault
                        flexDirection={'column'}
                        options={[
                            {
                                label: 'Selecione',
                                value: '',
                            },
                            {
                                label: 'WhatsApp',
                                value: 'WhatsApp',
                            },
                            {
                                label: 'Web',
                                value: 'Web',
                            },
                            {
                                label: 'SMS',
                                value: 'SMS',
                            },
                            {
                                label: 'Email',
                                value: 'Email',
                            }
                        ]}
                        value={inputSwitch}
                        onChange={(value) => setInputSwitch(value)}
                    />
                </CardTitle>

                <CardTitle>
                    <h2>Selecione uma Chatbot</h2>

                    <SelectDefault
                        value=""
                        placeholder="Selecione uma opção"
                        onChangeText={(value) => handleOnChange('chatbot', value)}
                    >
                        {chatOptions.map(row => (
                            <option key={row.id_chatbot} value={row.id_chatbot}>{row.chatbot}</option>
                        ))}
                    </SelectDefault>
                </CardTitle>
            </Content>
        </Container>
    );
}
