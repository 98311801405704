import styled from "styled-components"

export const Container = styled.div`
    padding-bottom: 30px;

    .titleAttendenceGroup {
        font-weight: 16px;
        font-size: 16px;
        color: ${props => props.theme.colors.white};
    }

    .graph-area-chart {
        height: 306px;
        width: 100%;
        display: flex;
        
    }
    .card-container {
        tspan {
            fill: #FFFFFF;
        }
    }

    .pause {
        .table-simple {
            width: 100%;
        }
    }

    .start-date {
        height: 40px;
        border: 1px solid #C5CED7;
        width: 100%;
        border-radius: 4px;
        outline: none;
        padding: 0 5px;
    }
    .react-date-picker__wrapper {
        border: none;
    }
    
    .react-date-picker__calendar {
        z-index: 2;
    }
    
    .react-date-picker__inputGroup__input {
        color: #FFFFFF;
    }

    .react-date-picker__clear-button {
        svg {
          stroke: #FFF;          
        }
        &:hover {
            svg {
                stroke: #E81E26 !important;
            }
        }
    }

    .react-date-picker__calendar-button {
        svg {
            stroke: #FFF;            
        }
        &:hover {
            svg {
                stroke: #4A7DFF !important;
            }
        }
    }

`