import { useEffect, useRef, useState } from 'react';
import { useRoutes } from '../../contexts/RoutesContext';
import { useMessage } from '../../contexts/MessagesContext';
import apiEyou from '../../services/apiEyou';
import apiTropa from '../../services/apiTropa';
import Skeleton from 'react-loading-skeleton';

//COMPONENTS
import 'react-loading-skeleton/dist/skeleton.css';
import Loading from '../../components/Loading/Loading';
import { ButtonVideo } from '../../components/Chat/CardFiles/styles';
import ButtonLinear from '../../components/form/ButtonLinear';

//ICONES
import { IconMusic } from '../../components/icons';

import { Container, Header, Main } from './styles';
import BackPage from '../../components/layout/UIElements/BackPage';

export default function ConfigUser() {
    const { addDanger, addSuccess } = useMessage();
    const { setPageName } = useRoutes();
    const [loading, setLoading] = useState(true);
    const inputRefType = useRef<any>();

    const [fileSound, setFileSound] = useState<any>({
        audio_nova_mensagem_operador: '',
        audio_nova_mensagem_usuario: '',
        audio_novo_atendimento: '',
    });

    const dataSound = [
        {
            id: 1,
            title: 'Nome do som',
            subTitle: 'Áudio Operador',
            nameType: 'audio_nova_mensagem_operador',
            sound: fileSound.audio_nova_mensagem_operador,
        },
        {
            id: 2,
            title: 'Nome do som',
            subTitle: 'Áudio Usuário',
            nameType: 'audio_nova_mensagem_usuario',
            sound: fileSound.audio_nova_mensagem_usuario,
        },
        {
            id: 3,
            title: 'Nome do som',
            subTitle: 'Áudio Atendimento',
            nameType: 'audio_novo_atendimento',
            sound: fileSound.audio_novo_atendimento,
        },
    ];

    useEffect(() => {
        setPageName('Configurar sons');
    }, []);

    useEffect(() => {
        getSounds();
    }, []);

    async function getSounds() {
        setLoading(true);
        apiTropa.get('/cliente/get').then((res: any) => {
            setFileSound({
                audio_nova_mensagem_operador: res.data.data.audio_nova_mensagem_operador,
                audio_nova_mensagem_usuario: res.data.data.audio_nova_mensagem_usuario,
                audio_novo_atendimento: res.data.data.audio_novo_atendimento,
            });
            setLoading(false);
        });
    }

    async function onChangeFile(e: any, type: string) {
        try {
            if (e.target.files[0].type.split('/')[0] !== 'audio') {
                throw 'Favor insira uma arquivo de áudio';
            }

            const formData = new FormData();
            formData.append('files', e.target.files[0], e.target.files[0].name);
            formData.append('idApp', '2');
            formData.append('destination', '1');
            formData.append('share', '1');

            let tokenInicial = window.sessionStorage.getItem('tokenInicial');

            let response: any = await apiEyou.post('apiDrive', formData, {
                headers: {
                    Authorization: 'Bearer ' + tokenInicial,
                },
            });

            if (response.data.data.error === true) {
                throw 'Não foi possivel salvar o áudio';
            }

            fileSound[type] = response.data.data.fileUrl;
        } catch (e) {
            addDanger('Ocorreu um erro', 'Não foi possível salvar o áudio');
        }
    }

    async function handleOnSaveSounds() {
        try {
            const newData = {
                audio_nova_mensagem_operador: fileSound.audio_nova_mensagem_operador,
                audio_nova_mensagem_usuario: fileSound.audio_nova_mensagem_usuario,
                audio_novo_atendimento: fileSound.audio_novo_atendimento,
            };

            const response: any = await apiTropa.put(`cliente/edit`, newData);

            if (response.data.status.error === 'error') {
                throw 'Não foi possivel salvar o áudio';
            }

            addSuccess('Sucesso', String(response.data.message));
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (e: any) {
            addDanger('Ocorreu um erro', 'Não foi possível salvar o áudio');
        }
    }

    return (
        <>
            {loading === true ? (
                <Loading active={loading} />
            ) : (
                <Container>
                    <Header>
                        <BackPage />
                        {/* <h1 className="titleSound">Meus sons</h1> */}

                        <ButtonLinear type="button" className="success" onClick={handleOnSaveSounds}>
                            Salvar
                        </ButtonLinear>
                    </Header>

                    <Main>
                        {dataSound.map((row) => (
                            <div key={row.id} className="tableSound">
                                <div className="tdSound">
                                    <h3>{row.title}</h3>
                                    <span>{row.subTitle}</span>
                                </div>

                                <div className="tdSoundPlayer">
                                    <audio controls>
                                        <source src={row.sound} type="audio/ogg" />
                                        <source src={row.sound} type="audio/mpeg" />
                                    </audio>

                                    <div className="sectionSendSound">
                                        <ButtonVideo>
                                            <input
                                                type="file"
                                                name="arquivo"
                                                onChange={(e) => onChangeFile(e, row.nameType)}
                                                ref={inputRefType}
                                            />
                                            <IconMusic color="#fff" />
                                        </ButtonVideo>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Main>
                </Container>
            )}
            {loading === true && (
                <Skeleton
                    count={3}
                    className="skeletor"
                    height="110px"
                    borderRadius="16px"
                    baseColor="#262655"
                    highlightColor="#3e3b77"
                />
            )}
        </>
    );
}
