import React, { InputHTMLAttributes, useCallback } from 'react';
import { maskCEP, maskCNPJ, maskCPF, maskDate, maskPhone } from './masks';

import { Container, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label: string;
    value: string;
    mask?: string;
    error?: {
        error: boolean;
        message: string;
        name: string;
    };
}

export default function InputChat({
    name,
    label,
    value,
    error,
    mask,
    ...rest
}: InputProps) {
    const handleKeyUp = useCallback(
        (e) => {

            if (mask === 'cpf' || mask === 'Cpf' || mask === 'CPF') {
                maskCPF(e);
            } 
             else if (mask === 'date') {
                maskDate(e);
            } else if (mask === 'CNPJ') {
                maskCNPJ(e);
            } else if (
                mask === 'fone' ||
                mask === 'Celular' ||
                mask === 'Telefone'
            ) {
                maskPhone(e);
            } else {
                return;
            }
            return;
        },
        [mask]
    );

    return (
        <Container htmlFor={name} value={value}>
            {label}
            {/* <label htmlFor={name}>{label}</label> */}

            <input id={name} name={name} onKeyUp={handleKeyUp} {...rest} />

            {error?.error && (
                <Error>
                    <span>{error.message}</span>
                </Error>
            )}
        </Container>
    );
}
