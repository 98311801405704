import React, { useEffect, useState } from 'react';
import { IconSearch } from '../../icons';
import InputMask from 'react-input-mask';

import { Container } from './styles';

interface InputProps extends React.HTMLAttributes<HTMLInputElement> {
    placeholder?: string;
    value: string | any;
    type?: 'name' | 'email' | 'CPF' | 'cargo' | 'empresa' | 'interesse' | "CEP" | 'endereço' | 'número' | 'bairro' | 'cidade' | 'estado' | 'pais' | 'complemento';
    mask?: string;
    onChangeText: (value: string | any) => void;
    search?: boolean;
    className?: any;
}

const InputBorderBottom: React.FC<InputProps> = ({
    placeholder,
    value,
    mask = '',
    onChangeText,
    type,
    search = false,
    className = '',
    ...rest
}) => {
    const [valueInput, setValueInput] = useState<string>(value);

    useEffect(() => {
        setValueInput(value);
    }, [value]);

    function handleOnChange(e: React.FormEvent<HTMLInputElement>) {
        setValueInput(e.currentTarget.value);
        onChangeText(e.currentTarget.value);
    }

    return (
        <Container className={`form-children-default input ${className}`}>
            {search && <IconSearch />}

            <InputMask
                type={type}
                mask={mask}
                value={valueInput}
                onChange={handleOnChange}
                placeholder={placeholder}
                {...rest}
            />
        </Container>
    );
};

export default InputBorderBottom;
