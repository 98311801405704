import styled from 'styled-components';

interface InputProps {
    type?: string;
}

export const Container = styled.label<InputProps>`
    border: 1px solid ${(props) => props.theme.colors.silverSand};
    border-radius: 4px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 21px;

    &:active {
        border: 1px solid ${(props) => props.theme.colors.white};
    }

    .title {
        color: ${props => props.theme.colors.blueCrayola};
        font-size: 12px;
        margin-right: 5px;
    }
    input {
        height: 100%;
        outline: none;
        border: none;
        flex: 1;
        background: transparent;
        color: ${(props) => props.theme.colors.white};
        &::placeholder {
            color: ${(props) => props.theme.colors.silverSand};
        }
    }
`;
