import styled from 'styled-components';

export const Container = styled.div`
    border: 1px solid ${(props) => props.theme.colors.silverSand};
    border-radius: 4px;
    height: 100px;
    width: 100%;
    display: flex;
    font-size: 14px;
    line-height: 150%;
    word-wrap: break-word;
    padding: 8px 21px;
    color: ${(props) => props.theme.colors.white};
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }

    span {
        color: ${(props) => props.theme.colors.silverSand};
    }
`;
