import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 24px 0;
`;

export const SectionHours = styled.div`
    display: grid;
    grid-template-columns: 1fr ;
    gap: 16px;
`;
interface IPropsEditSelected {
    selected: 'conteudo' | 'condicoes' | 'integracoes'
}

interface ChatProps {
    backgroundColor?: string;
    selectedFile?: boolean;
}

export const FunilAtendimentoComponent = styled.ul`
    list-style: none;
    display: inline-block;
    width: 100%;
    text-align: center;
    li{
        display: inline-block;
        background-color: #8B8B9E;
        height: 32px;
        line-height: 32px;
        padding: 0 16px 0 32px;
        color: #fff;
        margin: 0 5px;
        position: relative;
        vertical-align: top;
        margin-bottom: 10px;
        z-index: 1;
        span{
            display: inline-block;
            line-height: 34px;
            vertical-align: top;
            margin-right: 5px;
        }
        .funil-delete{
            display: inline-block;
            line-height: 32px;
            vertical-align: top;
            height: 32px;
            padding: 0 0px;
            box-sizing: border-box;
        }
    }
    li.new{
        background-color: #28b2a2 ;
        cursor: pointer;
    }
    li.new:after{
        border-left: 19px solid #28b2a2 ;
    }
    li:after{
        content: "";
        display: inline-block;
        position: absolute;
        left:100%;

        width: 0; 
        height: 0; 
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
        border-left: 19px solid #8B8B9E;
        z-index: 1;
    }

    li:before{
        content: "";
        display: inline-block;
        position: absolute;
        left:0%;

        width: 0; 
        height: 0; 
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
        border-left: 19px solid ${({theme}) => theme.colors.spaceCadedDark};
        z-index: 0;
    }
`;



export const FooterChat = styled.div<ChatProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    width: 100%;

    border-top: 1px solid #eaeef3;

    .buttonChat {
        display: grid;
        place-items: center;
        flex-shrink: 0;

        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: none;
        background: ${(props) =>
            props.backgroundColor ? props.backgroundColor : '#34D5FA'};
        transition: filter 0.3s;

        &:hover {
            transition: 0.3s;
            filter: brightness(0.9);
        }
    }

    .containerChatInput {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        position: relative;
        width: 100%;
        height: 48px;
        padding: 4px;

        background: #eaeef3;
        border-radius: 10px;

        .buttonEmojiChat {
            display: grid;
            place-items: center;
            flex-shrink: 0;

            width: 48px;
            height: 48px;
            border-radius: 50%;
            border: none;
            background: transparent;
            position: relative;

            svg {
                path {
                    fill: ${(props) =>
                        props.backgroundColor
                            ? props.backgroundColor
                            : '#34D5FA'};
                }
            }
        }

        #inputChat {
            display: flex;
            align-items: center;

            width: 100%;
            padding: 8px 0px;
            border: none;
            outline: none;
            background: transparent;
        }
    }

    .buttonAudioChat {
        display: grid;
        place-items: center;
        flex-shrink: 0;

        width: 48px;
        height: 48px;
        border-radius: 10px;
        border: none;
        background: ${(props) =>
            props.backgroundColor ? props.backgroundColor : '#34D5FA'};
        transition: filter 0.3s;

        svg {
            path {
                fill: #fff;
            }
        }
        &:hover {
            transition: 0.3s;
            filter: brightness(0.9);
        }
    }
`;

export const SectionFiles = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ContainerEditFlowMenuSelected = styled.div<IPropsEditSelected>`
    display: flex;
    box-sizing: border-box;
    height: calc(100% - 100px);
    #conditions-edit {
        display: ${props => props.selected === 'condicoes' ? 'flex' : 'none'};
    }
    #messages-edit {
        display: ${props => props.selected === 'conteudo' ? 'flex' : 'none'};
    }
    #integrations-edit {
        display: ${props => props.selected === 'integracoes' ? 'flex' : 'none'};
    }
`;

export const Title = styled.div`
    line-height: 40px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: bold;
    color: ${props => props.theme.colors.white};
`;

export const CardTitle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    width: 100%;
    padding: 40px 32px;

    background: ${props => props.theme.colors.spaceCadedDark};
    border-radius: 16px;

    h2 {
        font-size: 24px;
        font-weight: bold;
        color: ${props => props.theme.colors.white};
    }

    p {
        font-size: 16px;
        color: ${props => props.theme.colors.white};
    }

`;

export const CardEntryForm = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    padding: 40px 32px;

    background: ${props => props.theme.colors.spaceCadedDark};
    border-radius: 16px;
`

export const CardForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    width: 100%;
    padding: 40px 32px;

    background: ${props => props.theme.colors.spaceCadedDark};
    border-radius: 16px;
    h2 {
        font-size: 16px;
        font-weight: bold;
        color: ${props => props.theme.colors.white};
        margin-bottom: 32px;
    }
`;

export const SectionInputs = styled.div`
    /* display: grid;
    grid-template-columns: repeat( auto-fit, minmax(140px, 1fr)); */
    display: flex;
    flex-direction: column;
    gap: 16px;
    
`

export const SectionEditForm = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 16px;
    
    width: 100%;
`

export const FieldSelected = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 16px;
    width: 100%;
    button {
        padding: 0 12px;
        margin-left: 16px;
        width: 100%;
        max-width: 215px;
    }
    button:nth-child(3n-2) {
        margin-left: 0;
    }
    @media (max-width: 1400px) {
        button {
            width: 27%;
            padding: 5px 15px;
        }
    }
`;

export const CardEditHours = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    background: ${(props) => props.theme.colors.spaceCadedDark};
    border-radius: 16px;
    padding: 20px 24px;

    width: 100%;
    height: 80px;
    margin-top: 24px;

    span {
        color: #fff;
    }
`;

export const InfoHours = styled.div`
    display: flex;
    flex-direction: column;
    width: 150px;

    h3 {
        font-size: 12px;
        color: ${(props) => props.theme.colors.lavanderGray};
    }

    span {
        font-size: 14px;
        font-weight: bold;
        color: ${(props) => props.theme.colors.white};
        margin-top: 2px;
    }
`;