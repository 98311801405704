import styled from 'styled-components';

export const OverlayModalHistory = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0,0,0,0.5);
    left: 0px;
    top: 0px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .history-container {
      width: 600px;
      margin: 0px auto;
      max-width: 90%;
      .header {
        display: flex;
        color: #FFF;
        height: 80px;
        align-items: center;
        justify-content: space-between;
        button {
          border: none;
          background: none;
        }
      }
    }
    .messages {
      height: calc(100vh - 80px);
      overflow: auto; 
      width: 100%;
      backdrop-filter: blur(30px);
      cursor: not-allowed;
    }
    .messages .history-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 40px 0px 20px 0px;
      box-sizing: border-box;
    }

    .transferMessage {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 15px;
        background: ${(props) => props.theme.colors.wine};;
        margin-bottom: 5px;
        border-radius: 5px;
        font-size: 13px;
        color: #FFF;
        svg {
            font-size: 30px;
            margin-right: 20px;
            margin-left: 10px;
            animation: fadeInLeft .5s linear;
        }
        p {
            margin-bottom: 10px;
            font-size: 13px;
        }
        span {
            font-size: 11px;
        }
    }
`;
