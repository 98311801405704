import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useRoutes } from '../../../contexts/RoutesContext';
import FailedRegister from '../../FailedRegister';
import {
    IconBlock,
    IconChatbot,
    IconComputer,
    IconHeadphone,
    IconMusic,
    IconPerson,
    IconPieChart,
    IconPower,
    IconThumbUp,
} from '../../icons';
import Header from './Header';
import Sidebar from './Sidebar';
import { ContainerLayout } from './styles';

const Layout: React.FC<any> = (props) => {
    const { setLogout, rules } = useRoutes();
    const refContent = useRef<any>();
    const location = useLocation()
    
    useEffect(() => {
        refContent.current.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [props.location.pathname]);

    return (
        <ContainerLayout>
            <Sidebar
                path={props.location.pathname}
                menus={[
                    {
                        to: '/',
                        name: 'Fila de atendimento',
                        icon: IconComputer,
                    },
                    {
                        to: '/teste',
                        name: 'Teste',
                        icon: IconHeadphone,
                    },
                    {
                        to: '/atendimentos',
                        name: 'Atendimentos',
                        icon: IconHeadphone,
                    },
                    // {
                    //     to: '/fila-de-atendimento',
                    //     name: 'Fila de atendimento',
                    //     icon: IconHeadphone,
                    // },
                    {
                        to: '/grupos-de-atendimento',
                        name: 'Grupos de Atendimento',
                        icon: IconPerson,
                    },
                    {
                        to: '/chatbot',
                        name: 'Chatbot',
                        icon: IconChatbot,
                    },
                    {
                        to: '/relatorios',
                        name: 'Novo relatório',
                        icon: IconPieChart,
                    },
                    {
                        to: '/relatorios/salvos',
                        name: 'Meus relatórios',
                        icon: IconPieChart,
                    },
                    
                    {
                        to: '/usuarios',
                        name: 'Usuários',
                        icon: IconPerson,
                    },
                    {
                        to: '/auditoria',
                        name: 'Auditoria',
                        icon: IconPieChart,   
                    },
                    {
                        to: '/bloqueios',
                        name: 'Bloqueios',
                        icon: IconBlock,
                    },
                    {
                        to: '/pesquisas-respondidas',
                        name: 'Pesquisas',
                        icon: IconThumbUp,
                    },
                    {
                        to: '/configurar-sons',
                        name: 'Configurar sons',
                        icon: IconMusic,
                    },
                    {
                        onClick: setLogout,
                        to: '',
                        name: 'Sair do painel',
                        icon: IconPower,
                    },
                ]}
            />
            <div className="content" ref={refContent}>
                <Header />
                <div className="content-page">
                    {rules.paths.filter((obj: any) => obj.value.split('/')[1] === location.pathname.split('/')[1]).length > 0 ? (
                        props.children
                    ) : <FailedRegister />
                }
                </div>
            </div>
        </ContainerLayout>
    );
};

export default Layout;
