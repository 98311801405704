import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    background: ${(props) => props.theme.colors.spaceCadedLight};
    border-radius: 16px;
    margin: 16px 0 0 0;
    overflow: hidden;
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    p.title-card {
        font-size: 16px;
        color: #fff;
        margin: 0px;
        margin-bottom: 30px;
        font-weight: 600;
        u {
            text-decoration: none;
            font-weight: 300;
            font-size: 15px;
        }
    }
    p.title-section {
        font-size: 24px;
        font-weight: bold;
        margin-top: -15px;
        padding-top: 0px;
        color: ${(props) => props.theme.colors.skyblue};
    }
    p.description {
        color: ${(props) => props.theme.colors.white};
        font-size: 16px;
    }
    .hr-dashed {
        width: 100%;
        height: 1px;
        border-bottom: 1px dashed #ddddee;
        margin: 24px 0px;
    }

    &.notMargin {
        margin: 0;
    }
`;

interface IProps {
    className?: any;
}

const CardContainer: React.FC<IProps> = ({ children, className }) => {
    return (
        <Container className={`card-container ${className}`}>
            {children}
        </Container>
    );
};

export default CardContainer;
