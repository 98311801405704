import React, { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useMessage } from '../../../contexts/MessagesContext';
import { useUser } from '../../../contexts/UserContext';
import apiEyou from '../../../services/apiEyou';
import apiTropa from '../../../services/apiTropa';
import ButtonConfig from '../../form/ButtonConfig';
import ButtonLinear from '../../form/ButtonLinear';
import TextAreaInputDefault from '../../form/TextAreaInputDefault';
import {
    IconArrowLeft,
    IconAudio,
    IconClose,
    IconDownload,
    IconFile,
    IconPlus,
    IconPower,
    IconSend,
    IconSmile,
} from '../../icons';
import CardEmoji from '../CardEmoji';
import CardFiles from '../CardFiles';
import CardQuote from '../CardQuote';
import {
    Container,
    HeaderChat,
    IconChat,
    MainChat,
    FooterChat,
    SectionFiles,
    PreviewFile,
    HeaderMessage,
    MainPreview,
} from './styles';

interface ChatProps {
    icon?: React.ComponentType;
    children?: any;
    backgroundColor?: string;
    onSubmit?: any;
    messages?: any;
    height?: any;
    heightPreview?: any;
    setChatStep?(type: string): void;
    scrollOnDownParent?: any;
    filaAtendimento?: any;
    noHeader?: boolean;
    selected?: any;
    audio?: any;
    onlyView?: boolean;
    styleButtonFinished?: any;
    inatividade?: any;
    messagePreLoad?: any;
    setMessagePreLoad?(message: any): void;
}

interface IMessage {
    message: string;
    type: 'texto' | 'audio' | 'video' | 'imagem' | 'arquivo';
    url?: string;
    date: Date;
    status: 'pendente' | 'enviado' | 'confirmado';
}

export default function Chat({
    icon: Icon,
    backgroundColor,
    children,
    onSubmit = () => {},
    messages,
    height = false,
    heightPreview = false,
    noHeader = false,
    selected = false,
    onlyView = false,
    inatividade = '',
    filaAtendimento = {
        fila: false,
    },
    scrollOnDownParent,
    setChatStep = () => {},
    styleButtonFinished,
    messagePreLoad,
    setMessagePreLoad = () => {},
}: ChatProps) {
    const scrollRef = useRef<any>();
    const inputRefType = useRef<any>();
    const { addDanger } = useMessage();
    const [hasButtonDow, setHasButtonDow] = useState(false);
    const [hasMenuFile, setHasMenuFile] = useState(false);
    const [selectedFile, setSelectedFile] = useState(false);
    const [fileName, setFileName] = useState('');
    const [message, setMessage] = useState<IMessage>({
        message: '',
        type: 'texto',
        url: '',
        date: new Date(),
        status: 'pendente',
    });

    const fileTypes = {
        audio: 1,
        arquivo: 1,
        imagem: 1,
        video: 1,
    }

    useEffect(() => {
        message.message = messagePreLoad;
        setMessage({ ...message });
    }, [messagePreLoad]);

    useEffect(() => {
        setMessagePreLoad(message.message);
    }, [message]);

    useEffect(() => {
        setHasButtonDow(false);
    }, [selected]);

    useEffect(() => {
        if (hasButtonDow === false) setTimeout(() => onScrollDown(), 100);
    }, [messages]);

    useEffect(() => {
        if (filaAtendimento.fila === true) setTimeout(() => onScrollDown(), 100);
    }, [filaAtendimento]);

    useEffect(() => {
        setTimeout(() => onScrollDown(), 100);
    }, [scrollOnDownParent]);

    function handleSubmit(event: any) {
        event.preventDefault();
        onSubmit({
            tipo: message.type,
            mensagem: message.message,
            url: message.url,
            criado: new Date(),
        });
        setMessage({
            type: 'texto',
            message: '',
            date: new Date(),
            status: 'pendente',
        });

        onScrollDown();
    }

    function handleSendFile(file: any) {
        onSubmit({
            tipo: message.type,
            mensagem: message.message,
            url: message.url,
            criado: new Date(),
        });
        message.message = '';
        inputRefType.current.value = '';

        setMessage({
            message: '',
            type: 'texto',
            url: '',
            date: new Date(),
            status: 'pendente',
        });

        onScrollDown();
        setSelectedFile(false);
        setHasMenuFile(false);
    }

    function onScrollDown() {
        setTimeout(() => {
            if (scrollRef && scrollRef.current) scrollRef.current.scrollToBottom();
        }, 50);
    }

    function handleOnScrollBottom(e: any) {
        if (e.top < 0.98 && messages && messages.length > 0) {
            setHasButtonDow(true);
        } else {
            setHasButtonDow(false);
        }
    }

    function handleonKey(e: any) {
        if(e.charCode == 13 && e.shiftKey === false) {
            handleSubmit(e)
        }
    }

    function handleOnChange(text: string) {
        setMessage({
            type: 'texto',
            message: text,
            date: new Date(),
            status: 'pendente',
        });
    }

    function handleClosePreview() {
        setSelectedFile(false);
        message.message = '';
        inputRefType.current.value = '';

        setMessage({
            message: '',
            type: 'texto',
            url: '',
            date: new Date(),
            status: 'pendente',
        });
    }

    async function handleOnMessageType(e: any, type: any) {
        try {
            if (e && e.target.files.length > 0) {
                setFileName(e.target.files[0].name);
                var formData = new FormData();

                formData.append('idApp', '2');
                formData.append('destination', '1');
                formData.append('share', '1');
                formData.append('files', e.target.files[0], e.target.files[0].name);
                const response: any = await apiEyou.post('apiDrive', formData, {
                    headers: {
                        Authorization: 'Bearer ' + window.sessionStorage.getItem('tokenInicial'),
                    },
                });

                if (response.data.data.error === true) {
                    throw response.data.data.message;
                }

                setMessage({
                    type: type,
                    message: e.target.files[0].name,
                    url: response.data.data.fileUrl,
                    date: new Date(),
                    status: 'pendente',
                });
                setHasMenuFile(!hasMenuFile);
                setSelectedFile(!selectedFile);
            }
        } catch (err: any) {
            addDanger('Atenção', err.toString());
        }
    }

    const [loadFinish, setLoadFinish] = useState<boolean>(false)

    async function handleFinalChat() {
        try {
            setLoadFinish(true)
            const dadosChat: any = window.sessionStorage.getItem('chatAtendence');
            const dadosChatJSON = JSON.parse(dadosChat);
            const response: any = await apiTropa.put(`/atendimento/finalizar-externo/${dadosChatJSON.id_atendimento}`);
            if (response.data.status === 'error') throw 'Não é possivel finalizar o atendimento no momento.';
            setChatStep('pesquisa-satisfacao');
            window.sessionStorage.removeItem('chatAtendence');
            window.sessionStorage.removeItem('chatPerfil');
            setLoadFinish(false)
        } catch (err: any) {
            window.sessionStorage.removeItem('chatAtendence');
            window.sessionStorage.removeItem('chatPerfil');
            setChatStep('pesquisa-satisfacao');
            setLoadFinish(false)
        }
    }

    return (
        <Container inatividade={inatividade} noHeader={noHeader} style={height ? { height: height } : {}}>
            {Icon && (
                <HeaderChat>
                    <IconChat style={{ backgroundImage: `url(${Icon})` }} />

                    {onlyView === false && filaAtendimento.fila === false && (
                        <div className="final">
                            <ButtonConfig
                                Icon={<IconPower color={styleButtonFinished.color} />}
                                onClick={handleFinalChat}
                                loading={loadFinish}
                                styles={{
                                    backgroundColor: styleButtonFinished.backgroundColor,
                                    color: styleButtonFinished.color,
                                    borderRadius: styleButtonFinished.borderRadius,
                                    border: styleButtonFinished.border,
                                    borderColor: styleButtonFinished.borderColor,
                                    borderStyle: styleButtonFinished.borderStyle,
                                }}
                            >
                                {styleButtonFinished.textFinishedAttendence
                                    ? styleButtonFinished.textFinishedAttendence
                                    : 'Finalizar atendimento'}
                            </ButtonConfig>
                        </div>
                    )}
                </HeaderChat>
            )}

            <MainChat inatividade={inatividade}>
                <Scrollbars
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                    ref={scrollRef}
                    onScrollFrame={(e) => handleOnScrollBottom(e)}
                >
                    {children}

                    <button
                        type="button"
                        className={`buttonDown ${hasButtonDow && 'buttonDowActive'}`}
                        onClick={() => onScrollDown()}
                    >
                        <IconArrowLeft />
                    </button>
                </Scrollbars>
            </MainChat>

            {!inatividade && (
                <FooterChat backgroundColor={backgroundColor}>
                    {fileTypes.arquivo === 0 &&
                    fileTypes.audio === 0 &&
                    fileTypes.imagem === 0 &&
                    fileTypes.video === 0 ? null : (
                        <SectionFiles>
                            <CardFiles
                                fileTypes={fileTypes}
                                active={hasMenuFile}
                                handleUpload={handleOnMessageType}
                                inputRefType={inputRefType}
                            />
                            <button type="button" className="buttonChat" onClick={() => setHasMenuFile(!hasMenuFile)}>
                                <IconPlus />
                            </button>
                        </SectionFiles>
                    )}

                    <form className="formMessagePre" onSubmit={handleSubmit}>
                        <TextAreaInputDefault
                            value={message.message}
                            handleOnKeyPress={(e: any) => handleonKey(e)}
                            disabled={filaAtendimento.fila}
                            onChange={(e: any) => handleOnChange(e.target.value)}
                            radius
                            isButtonSend
                        />
                    </form>
                </FooterChat>
            )}

            <PreviewFile selectedFile={selectedFile} style={heightPreview ? { height: heightPreview } : {}}>
                <HeaderMessage>
                    <button type="button" onClick={handleClosePreview}>
                        <IconClose />
                    </button>
                    <span>Pré-visualizar</span>
                </HeaderMessage>

                <MainPreview>
                    {message.type === 'audio' && (
                        <audio controls>
                            <source src={message.url} />
                        </audio>
                    )}
                    {message.type === 'imagem' && (
                        <div className="imagePreview">
                            <img src={message.url} alt="preview" />
                        </div>
                    )}
                    {message.type === 'video' && (
                        <div className="videoPreview">
                            <video width="320" height="240" autoPlay controls src={message.url}>
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    )}
                    {message.type === 'arquivo' && (
                        <div className="filePreview">
                            <IconFile size="68px" />
                        </div>
                    )}

                    <span className="descriptionFilePreview">{fileName}</span>

                    <ButtonLinear className="info" rounded={true} onClick={(e: any) => handleSendFile(e.target.value)}>
                        Enviar
                    </ButtonLinear>
                </MainPreview>
            </PreviewFile>
        </Container>
    );
}
