import { useEffect, useRef, useState } from 'react';
import { useUser } from '../../../contexts/UserContext';
import { useMessage } from '../../../contexts/MessagesContext';
import apiEyou from '../../../services/apiEyou';
import ButtonLinear from '../../form/ButtonLinear';
import Scrollbars from 'react-custom-scrollbars';
import CardFiles from '../../Chat/CardFiles';
import FieldGroup from '../../layout/containers/FieldGroup';
import TextAreaInputDefault from '../../form/TextAreaInputDefault';

//ICONS
import { IconArrowLeft, IconClose, IconFile, IconLoading, IconPlus } from '../../icons';

//STYLES
import { Container, MainChat, FooterChat, ChatLoad } from './styles';
import { HeaderMessage, MainPreview, PreviewFile, SectionFiles } from '../../Chat/CardChat/styles';

interface ChatProps {
    children?: any;
    backgroundColor?: string;
    onSubmit?: any;
    messages?: any;
    disabled?: boolean;
    atendimento?: any;
    load?: boolean;
    height?: any;
    idAdmin?: any;
    messagePreLoad?: any;
    setMessagePreLoad?(message: any): void;
    heightFunil?: number;
}

export default function CardChat({
    backgroundColor,
    children,
    onSubmit,
    messages,
    atendimento,
    disabled = false,
    load = true,
    height = false,
    messagePreLoad,
    setMessagePreLoad = () => {},
    heightFunil
}: ChatProps) {
    const { addDanger } = useMessage();
    const scrollRef = useRef<any>();
    const inputRefType = useRef<any>();
    const [hasButtonDow, setHasButtonDow] = useState(false);
    const { user } = useUser();

    const [fileTypes, setFileTypes] = useState({
        audio: user.audio,
        arquivo: user.arquivo,
        imagem: user.imagem,
        video: user.video,
    });

    useEffect(() => {
        setFileTypes({
            audio: user.audio,
            arquivo: user.arquivo,
            imagem: user.imagem,
            video: user.video,
        });
    }, [user]);

    useEffect(() => {
        if (load === false) setTimeout(() => onScrollDown(), 100);
    }, [load]);

    useEffect(() => {
        if (hasButtonDow === false) setTimeout(() => onScrollDown(), 100);
    }, [messages]);

    function onScrollDown() {
        setTimeout(() => {
            if (scrollRef && scrollRef.current) scrollRef.current.scrollToBottom();
        }, 50);
    }

    function handleOnScrollBottom(e: any) {
        if (e.top < 0.98) {
            setHasButtonDow(true);
        } else {
            setHasButtonDow(false);
        }
    }

    const [hasMenuFile, setHasMenuFile] = useState(false);
    const [selectedFile, setSelectedFile] = useState(false);
    const [fileName, setFileName] = useState('');

    const [message, setMessage] = useState<any>({
        message: '',
        type: 'texto',
        url: '',
        date: new Date(),
        status: 'pendente',
    });

    useEffect(() => {
        message.message = messagePreLoad;
        setMessage({ ...message });
    }, [messagePreLoad]);

    useEffect(() => {
        setMessagePreLoad(message.message);
    }, [message]);

    function handleSubmit(event: any) {
        event.preventDefault();
        onSubmit({
            tipo: message.type,
            mensagem: message.message,
            url: message.url,
            criado: new Date(),
        });
        setMessage({
            type: 'texto',
            message: '',
            date: new Date(),
            status: 'pendente',
        });

        onScrollDown();
    }

    function handleOnChange(text: string) {
        setMessage({
            type: 'texto',
            message: text,
            date: new Date(),
            status: 'pendente',
        });
    }

    function handleonKey(e: any) {
        console.log(e)
        if(e.charCode == 13 && e.shiftKey === false) {
            handleSubmit(e)
        }
    }

    function handleSendFile(file: any) {
        onSubmit({
            tipo: message.type,
            mensagem: message.message,
            url: message.url,
            criado: new Date(),
        });
        message.message = '';
        setMessage({ ...message });
        inputRefType.current.value = '';

        setMessage({
            message: '',
            type: 'texto',
            url: '',
            date: new Date(),
            status: 'pendente',
        });

        onScrollDown();
        setSelectedFile(false);
        // setHasMenuEmoji(false);
        setHasMenuFile(false);
    }

    function handleClosePreview() {
        setSelectedFile(false);
        message.message = '';
        inputRefType.current.value = '';
        // setMessage({ ...message });

        setMessage({
            message: '',
            type: 'texto',
            url: '',
            date: new Date(),
            status: 'pendente',
        });
    }

    async function handleOnMessageType(e: any, type: any) {
        try {
            if (e && e.target.files.length > 0) {
                setFileName(e.target.files[0].name);
                var formData = new FormData();

                formData.append('idApp', '2');
                formData.append('destination', '1');
                formData.append('share', '1');
                formData.append('files', e.target.files[0], e.target.files[0].name);
                const response: any = await apiEyou.post('apiDrive', formData, {
                    headers: {
                        Authorization: 'Bearer ' + window.sessionStorage.getItem('tokenInicial'),
                    },
                });

                if (response.data.data.error === true) {
                    throw response.data.data.message;
                }

                setMessage({
                    type: type,
                    message: e.target.files[0].name,
                    url: response.data.data.fileUrl,
                    date: new Date(),
                    status: 'pendente',
                });
                setHasMenuFile(!hasMenuFile);
                setSelectedFile(!selectedFile);
            }
        } catch (err: any) {
            addDanger('Atenção', err.toString());
        }
    }

    return (
        <Container style={height ? { height: height } : {}}>
            <MainChat style={height ? { height: height } : {}} heightFunil={heightFunil}>
                {load && (
                    <ChatLoad
                        className={`${
                            load ? 'animate__animated animate__fadeIn' : 'animate__animated animate__fadeOut'
                        }`}
                    >
                        <IconLoading />
                    </ChatLoad>
                )}

                <Scrollbars
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                    ref={scrollRef}
                    onScrollFrame={(e) => handleOnScrollBottom(e)}
                >
                    {children}
                </Scrollbars>

                <button
                    type="button"
                    className={`buttonDown ${hasButtonDow && 'buttonDowActive'}`}
                    onClick={() => onScrollDown()}
                >
                    <IconArrowLeft />
                </button>
            </MainChat>

            {disabled === false && (
                <FooterChat backgroundColor={backgroundColor}>
                    {fileTypes.arquivo === 0 &&
                    fileTypes.audio === 0 &&
                    fileTypes.imagem === 0 &&
                    fileTypes.video === 0 ? null : (
                        <SectionFiles>
                            <CardFiles
                                fileTypes={fileTypes}
                                active={hasMenuFile}
                                handleUpload={handleOnMessageType}
                                inputRefType={inputRefType}
                            />
                            <button type="button" className="buttonChat" onClick={() => setHasMenuFile(!hasMenuFile)}>
                                <IconPlus />
                            </button>
                        </SectionFiles>
                    )}

                    <form className="formMessagePre" onSubmit={handleSubmit}>
                        <TextAreaInputDefault
                            value={message.message}
                            onChange={(e: any) => handleOnChange(e.target.value)}
                            handleOnKeyPress={(e: any) => handleonKey(e)}
                            isButtonSend
                            radius
                        />
                    </form>
                </FooterChat>
            )}

            <PreviewFile selectedFile={selectedFile}>
                <HeaderMessage>
                    <button type="button" onClick={handleClosePreview}>
                        <IconClose />
                    </button>
                    <span>Pré-visualizar</span>
                </HeaderMessage>

                <MainPreview>
                    {message.type === 'imagem' && (
                        <div className="imagePreview">
                            <img src={message.url} alt="preview" />
                        </div>
                    )}
                    {message.type === 'video' && (
                        <div className="videoPreview">
                            <video width="320" height="240" autoPlay controls src={message.url}>
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    )}
                    {message.type === 'arquivo' && (
                        <div className="filePreview">
                            <IconFile size="68px" />
                        </div>
                    )}

                    {message.type === 'audio' && (
                        <div className="viewAudio">
                            <audio controls>
                                <source src={message.url} type="audio/ogg" />
                                <source src={message.url} type="audio/mpeg" />
                            </audio>
                        </div>
                    )}

                    <span className="descriptionFilePreview">{fileName}</span>

                    <FieldGroup style={{ width: 300 }}>
                        <ButtonLinear className="danger" rounded={true} onClick={(e: any) => handleClosePreview()}>
                            Cancelar
                        </ButtonLinear>
                        <ButtonLinear
                            className="info"
                            rounded={true}
                            onClick={(e: any) => handleSendFile(e.target.value)}
                        >
                            Confirmar
                        </ButtonLinear>
                    </FieldGroup>
                </MainPreview>
            </PreviewFile>
        </Container>
    );
}
