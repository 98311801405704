import { useEffect } from 'react';
import { useRoutes } from '../../contexts/RoutesContext';
import { Container } from './styles';

export default function PageChatExample(props: any) {
    const { setPageName } = useRoutes();
    const token = props.match.params.token;
    useEffect(() => {
        setPageName('Chat');

        const script = document.createElement('script');
        script.src = `/webchat.js?token=${token}${process.env.NODE_ENV === 'development' ? '&dev=true' : ''}`;
        script.type = 'text/javascript';
        script.id = 'script-eyou-omni';
        script.async = true;

        document.body.appendChild(script);

    }, []);

    return (
        <Container>
            <h1>Seu site</h1>
        </Container>
    );
}
