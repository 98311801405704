import styled from "styled-components"


export const Container = styled.label`
    border: 1px solid ${props => props.theme.colors.silverSand};
    border-radius: 4px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    width: 252px;

    gap: 14px;

    &:active {
        border: 1px solid ${props => props.theme.colors.white};
    }
    input {
        height: 100%;
        max-width: 100%;
        flex: 1;
        outline: none;
        border: none;
        background: transparent;
        color: ${props => props.theme.colors.white};
        &::placeholder {
            color: ${props => props.theme.colors.silverSand};
        }
    }
    svg {
        width: 20px;
        height: 20px;
        path {
            fill: ${props => props.theme.colors.skyblue}
        }
    }
`