import React from 'react';
import { ContainerInputConditionTarget } from './styles';

// import { Container } from './styles';
interface IProps {
    indice: any;
    indiceCondition: any;
    condition: any;
    handleEditCondition(
        key: any,
        condiitonKey: any,
        type: any,
        value: any
    ): void;
}

const InputConditionTargetNumber: React.FC<IProps> = ({
    condition,
    handleEditCondition,
    indice,
    indiceCondition,
}) => {
    return (
        condition.condition &&
        (condition.condition === 'maior' ||
            condition.condition === 'menor' ||
            condition.condition === 'maiorIgual' ||
            condition.condition === 'menorIgual') && (
            <ContainerInputConditionTarget>
                <input
                    onChange={(e) =>
                        handleEditCondition(
                            indice,
                            indiceCondition,
                            'value',
                            e.target.value
                        )
                    }
                    placeholder="Valor"
                    type="number"
                    value={condition.value}
                    className="target"
                />
            </ContainerInputConditionTarget>
        )
    );
};

export default InputConditionTargetNumber;
