import { useEffect, useState } from 'react'
import ButtonsTable from '../../ButtonsTable'
import { Td, TdDescription, TdSubtitle } from '../../layout/containers/Td'
import TableDefault from '../../TableDefault'
import HoursAttendence from '../../Ui/HoursAttendence'

import {
  Container,
  Numberoperators,
  Separator,
  PersonList,
} from './styles'


interface Props {
  operador:any;
}

export default function OperatorAttendence({
  operador = {
    horarios: []
  }
}:Props) {

  const [horarios, setHorarios] = useState([])

  useEffect(() => {

    const horarios = operador.horarios
    if( horarios.find((row:any) => row.data === "domingo") === undefined ) horarios.splice(0, 0, {data: "domingo"})
    if( horarios.find((row:any) => row.data === "segunda-feira") === undefined ) horarios.splice(1, 0, {data: "segunda-feira"})
    if( horarios.find((row:any) => row.data === "terca-feira") === undefined ) horarios.splice(2, 0, {data: "terca-feira"})
    if( horarios.find((row:any) => row.data === "quarta-feira") === undefined ) horarios.splice(3, 0, {data: "quarta-feira"})
    if( horarios.find((row:any) => row.data === "quinta-feira") === undefined ) horarios.splice(4, 0, {data: "quinta-feira"})
    if( horarios.find((row:any) => row.data === "sexta-feira") === undefined ) horarios.splice(5, 0, {data: "sexta-feira"})
    if( horarios.find((row:any) => row.data === "sabado") === undefined ) horarios.splice(6, 0, {data: "sabado"})

    horarios.map((row:any) => {
      try{ 
        row.horarios = JSON.parse(row.horarios) 
      } catch(_err){}
    })

    setHorarios(horarios)
    
  }, [])

  return (
    <Container>
      {/* 
      <Numberoperators>
        <ButtonsTable title={'Abandonos'} number={"1"} />
        <ButtonsTable title={'Aguardando'} number={"2"} />
        <ButtonsTable title={'Atendimentos'} number={"15"} />
      </Numberoperators>
      <Separator /> 
      */}

      {/* <PersonList>
        {[0, 1, 2, 3, 4, 5,].map((row, key) => (
          <HoursAttendence
            hours="07:00"
            title="Inicio"
          />
        ))}
      </PersonList> */}

      {horarios.map((row:any, key:any) => (
      <TableDefault columns={3} key={key} >
          <Td flexDirection="column">
              <TdDescription>Dia da semana</TdDescription>
              <TdSubtitle>{row.data}</TdSubtitle>
          </Td>
          <Td flexDirection="column">
              <TdDescription>Horários</TdDescription>
              <TdSubtitle>{row.horarios?.inicio ? row.horarios.inicio + " às " + row.horarios.fim : "Não definido"}</TdSubtitle>
          </Td>
          <Td flexDirection="column">
              <TdDescription>Pausas</TdDescription>
              <TdSubtitle>
                  {(!row.horarios || !row.horarios.pausa) ? "Não definido" :
                      row.horarios.pausa.map((pausa:any) => <p>{pausa.descricao} - {pausa.inicio} às {pausa.fim}</p>)
                  }
              </TdSubtitle>
          </Td>
      </TableDefault>
      ))}

    </Container>
  )
}