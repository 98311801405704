import React, { useEffect, useState } from 'react';
import { IconEye } from '../../icons';

import { Container } from './styles';

interface InputProps extends React.HTMLAttributes<HTMLInputElement> {
    placeholder?: string;
    value: string;
    type?: 'text' | 'password' | 'email' | 'tel';
    onChangeText: (value: string) => void;
}

const InputLogin: React.FC<InputProps> = ({
    placeholder,
    value,
    type = 'text',
    onChangeText,
    ...rest
}) => {
    const [valueInput, setValueInput] = useState<string>(value);
    const [typeInput, setTypeInput] = useState<string>(type);

    function handleOnChange(e: React.FormEvent<HTMLInputElement>) {
        setValueInput(e.currentTarget.value);
        onChangeText(e.currentTarget.value);
    }

    useEffect(() => {
        setTypeInput(type)
        setValueInput(value)
    },[ type, value])

    return (
        <Container value={value}>
            {placeholder && <span>{placeholder}</span>}
            <div className="input-container">
                <input
                    value={valueInput}
                    onChange={handleOnChange}
                    type={typeInput}
                    {...rest}
                />
                {type === 'password' && (
                    <button
                        type="button"
                        onClick={() =>
                            setTypeInput(
                                typeInput === 'password' ? 'text' : 'password'
                            )
                        }
                    >
                        <IconEye />
                    </button>
                )}
            </div>
        </Container>
    );
};

export default InputLogin;
