import { useEffect, useState } from 'react';
import { useRoutes } from '../../contexts/RoutesContext';
import ReactFlow, { ReactFlowProvider, Background, EdgeTypesType, Controls } from 'react-flow-renderer';

import {
    Container,
    ContainerButtonsChatbot,
    ContainerEditFlow,
    ContainerEditFlowHead,
    ContainerEditFlowMenu,
    ContainerEditFlowMenuLi,
    ContainerEditFlowMenuSelected,
    ContainerFlow,
    ContainerFullScreen,
    ContainerHeader,
    ContainerLoadFlow,
    SectionVariables,
    SelectVariable,
} from './styles';
import { useChatbotContext } from '../../contexts/ChatbotContext';
import {
    IconAfirmative,
    IconCheck,
    IconClose,
    IconCode,
    IconDelete,
    IconFullSreen,
    IconFullSreenInverse,
    IconLoading,
    IconPlus,
} from '../../components/icons';
import MessagesEdit from '../../components/Chatbot/MessagesEdit';
import ConditionsEdit from '../../components/Chatbot/ConditionsEdit';
import CardContainer from '../../components/layout/containers/CardContainer';
import ButtonLinear from '../../components/form/ButtonLinear';
import InputDefault from '../../components/form/InputDefault';
import { useMessage } from '../../contexts/MessagesContext';
import IntegrationsEdit from '../../components/Chatbot/IntegrationsEdit';
import FloatingEdge from '../../components/Chatbot/FloatingEdge';
import FloatingConnectionLine from '../../components/Chatbot/FloatingConnectionLine';
import BackPage from '../../components/layout/UIElements/BackPage';
import ModalDefault from '../../components/ModalDefault';
import { Code } from '../GruposAtendimento/configurarBotao/styles';
import apiTropa from '../../services/apiTropa';
import { useUser } from '../../contexts/UserContext';

export default function ChatbotEdit(props: any) {
    const id = props.match.params.id;
    const { setPageName } = useRoutes();
    const { handleLoadChatbot } = useChatbotContext();
    const { addSuccess, addDanger } = useMessage();
    const { user } = useUser();
    const [tabEdition, setTabEdition] = useState<any>('conteudo');
    const [name, setName] = useState('');
    const [load, setLoad] = useState<boolean>(true);
    const [edition, setEdition] = useState<boolean>(false);
    const [fullScreen, setFullScreen] = useState(false);
    const variable: any = [
        'Nome completo',
        'E-mail',
        'Login',
        'Telefone',
        'Celular',
        'Sexo',
        'CPF',
        'CNPJ',
        'Profissão',
        'CEP',
        'Endereço',
        'Número',
        'Complemento',
        'Bairro',
        'Cidade',
        'Estado',
    ];
    const [modalVariable, setModalVariable] = useState(false);

    const {
        selected,
        elements,
        setElements,
        handleNewElement,
        handleNewElementIntegration,
        handleOnConnect,
        handleOnMove,
        handleOnRemove,
        handleEditElementName,
        handleEditChatbot,
    } = useChatbotContext();

    useEffect(() => {
        async function getData() {
            setElements([]);
            const response = await handleLoadChatbot(id);
            if (response.error === false) setName(response.data.chatbot);
            setLoad(false);
        }
        getData();
        setPageName('Chatbot');
    }, [id]);

    useEffect(() => {
        setTabEdition(selected.typeDialog === 'integration' ? 'integracoes' : 'conteudo');
        if (selected.id) setEdition(true);
    }, [selected.id]);

    async function handleOnSave() {
        try {
            const saveChatbot: any = await handleEditChatbot(id, name);
            if (saveChatbot.error === true)
                throw 'Não foi possivel alterar o chatbot, tente novamente mais tarde ou contate um administrador.';
            addSuccess('Sucesso', 'Chatbot salvo com sucesso.');
        } catch (err: any) {
            addDanger('Oops...', err);
        }
    }

    function handleCloseEdition() {
        setEdition(false);
    }

    async function handleOnElementRemove(elements: any) {
        if (elements[0].id === selected.id) setEdition(false);
        handleOnRemove(elements);
    }

    function handleRemoveOnSelected() {
        const deleteArray = [];
        deleteArray.push(selected);
        handleOnRemove(deleteArray);
        setEdition(false);
    }

    function handleCopyVariable(row: string) {
        addSuccess('Copiado!', 'Variavel ' + row + ' copiada com sucesso.');
        setModalVariable(false);
        navigator.clipboard.writeText(row);
    }

    return (
        <Container>
            <BackPage />
            <CardContainer className="card-chatbot">
                <ContainerHeader>
                    <InputDefault value={name} onChangeText={(value) => setName(value)} />
                    <div className="buttons">
                        <ButtonLinear onClick={() => setModalVariable(!modalVariable)} className="dark" Icon={IconCode}>
                            Variáveis
                        </ButtonLinear>

                        <ButtonLinear onClick={() => handleNewElement()} className="dark" Icon={IconPlus}>
                            Diálogo
                        </ButtonLinear>

                        <ButtonLinear onClick={() => handleNewElementIntegration()} className="dark" Icon={IconPlus}>
                            Integração
                        </ButtonLinear>

                        <ButtonLinear onClick={handleOnSave} className="dark" Icon={IconCheck}>
                            Salvar
                        </ButtonLinear>
                    </div>
                </ContainerHeader>

                <ContainerFlow fullscreen={fullScreen}>
                    <ReactFlowProvider>
                        <ReactFlow
                            id={'chatbot-fluxograma'}
                            elements={elements}
                            onElementsRemove={(params) => handleOnElementRemove(params)}
                            onConnect={(params) => handleOnConnect(params)}
                            onNodeDrag={(event, element) => handleOnMove(element)}
                            zoomOnScroll={true}
                            zoomOnDoubleClick={true}
                        >
                            {load && (
                                <ContainerLoadFlow>
                                    <IconLoading />
                                </ContainerLoadFlow>
                            )}
                            <Background gap={30} size={1} color={'#CCC'} />
                            <ContainerFullScreen>
                                <button onClick={() => setFullScreen(!fullScreen)}>
                                    {!fullScreen ? <IconFullSreen /> : <IconFullSreenInverse />}
                                </button>
                            </ContainerFullScreen>
                        </ReactFlow>
                    </ReactFlowProvider>

                    <ContainerEditFlow edition={edition}>
                        <ContainerEditFlowHead>
                            <input
                                onChange={(e) => handleEditElementName(e.target.value)}
                                value={selected.params?.nameDialog}
                            />
                            <button onClick={handleCloseEdition}>
                                <IconClose />
                            </button>
                        </ContainerEditFlowHead>

                        <ContainerButtonsChatbot>
                            <ButtonLinear onClick={handleRemoveOnSelected} Icon={IconDelete} className="danger">
                                Remover diálogo
                            </ButtonLinear>
                            <ButtonLinear onClick={handleOnSave} Icon={IconCheck} className="info">
                                Salvar chatbot
                            </ButtonLinear>
                        </ContainerButtonsChatbot>

                        <ContainerEditFlowMenu>
                            {selected.typeDialog !== 'integration' && (
                                <ContainerEditFlowMenuLi
                                    onClick={() => setTabEdition('conteudo')}
                                    active={tabEdition === 'conteudo' ? true : false}
                                >
                                    Conteúdo
                                </ContainerEditFlowMenuLi>
                            )}

                            {selected.typeDialog !== 'attendance' && selected.typeDialog !== 'integration' && (
                                <ContainerEditFlowMenuLi
                                    onClick={() => setTabEdition('condicoes')}
                                    active={tabEdition === 'condicoes' ? true : false}
                                >
                                    Condições de saída
                                </ContainerEditFlowMenuLi>
                            )}
                            <ContainerEditFlowMenuLi
                                onClick={() => setTabEdition('integracoes')}
                                active={tabEdition === 'integracoes' ? true : false}
                            >
                                {selected.typeDialog === 'normal' ? 'Histórico' : 'Integrações'}
                            </ContainerEditFlowMenuLi>
                        </ContainerEditFlowMenu>

                        <ContainerEditFlowMenuSelected selected={tabEdition}>
                            <MessagesEdit />
                            <ConditionsEdit />
                            <IntegrationsEdit />
                        </ContainerEditFlowMenuSelected>
                    </ContainerEditFlow>
                </ContainerFlow>
            </CardContainer>

            <ModalDefault
                onClose={() => setModalVariable(!modalVariable)}
                openModal={modalVariable}
                id="modalVariable"
                width="460px"
            >
                <SectionVariables>
                    <div className="headerVariable">
                        <h2>Selecione uma variavel abaixo 👇</h2>
                        <button type="button" onClick={() => setModalVariable(false)} className="buttonModalVarible">
                            <IconClose />
                        </button>
                    </div>

                    <div className="mainModalVariable">
                        {variable?.map((row: any, key: any) => (
                            <SelectVariable key={key}>
                                <h3 className="textCopyVariavel">{row}</h3>
                                <ButtonLinear className="config" onClick={() => handleCopyVariable(row)}>
                                    Copiar
                                </ButtonLinear>
                            </SelectVariable>
                        ))}
                    </div>
                </SectionVariables>
            </ModalDefault>
        </Container>
    );
}
