import styled from "styled-components"


export const Container = styled.div`
    border: 1px solid ${props => props.theme.colors.silverSand};
    border-radius: 4px;
    background: ${props => props.theme.colors.spaceCaded};
    height: 40px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;
    .container-input-click {
        padding: 0px 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 100%;
    }
    .title {
        color: ${props => props.theme.colors.blueCrayola};
        font-size: 12px;
        margin-left: 10px;
    }
    .arrow {
        margin-left: 10px;
    }
    .group {
        position: absolute;
        top: 40px;
        width: 300px;
        background: ${(props) => props.theme.colors.cadetGrey};
        border-radius: 5px;
        border: 1px solid rgba(255,255,255,0.1);
        padding: 0px 10px;
        box-shadow: 0px 10px 10px rgba(0,0,0,0.3);
        opacity: 0;
        visibility: hidden;
        transition: all .2s;
        margin-top: -20px;
        overflow-x: auto;
        max-height: 300px;
        .input {
            margin-bottom: 10px;
            margin-top: 10px;
        }
    }
    &.active {
        .group {
            visibility: visible;
            opacity: 1;
            margin-top: 5px;
        }
    }
`