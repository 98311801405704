import styled, { css } from "styled-components"

interface Buttonprops {
    rounded: boolean;
    radius: boolean;
    color?: string;
}

export const Container = styled.button<Buttonprops>`

    ${props => props.radius && 
        css`
            width: 48px !important;
            height: 48px !important;
            border-radius: 100% !important;

            .icon {
                svg {
                    width: 24px;
                    height: 24px;
                    
                }
            }
        `
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.7;

        :hover {
            transform: scale(1);
        }
    }


   border-radius: ${props => props.rounded ? '100px' : '4px'} ;
   height: 40px;
   border: none;
   background: transparent;
   color: ${({theme}) => theme.colors.white};
   outline: none;
   padding: 0px 12px 0px 12px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   transition: all .2s;
   position: relative;
   .tooltip {
    position: absolute;
    white-space: nowrap;
    background: rgba(0,0,0,0.9);
    top: -50px;
    padding: 10px 15px;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    transition: all .2s;
    font-size: 12px;
    color: #fff;
   }
   &:hover {
       .tooltip {
        opacity: 1;
        visibility: visible;
        top: -40px;
       }
   }
   .icon {
       margin-right: 4px;
       margin-left: 4px;
       display: flex;
       svg {
           /* width: 14px;
           height: 14px; */
           path {
               fill: #fff;
           }
       }
   }
   .children {
       font-size: 16px;
       margin-left: 4px;
       margin-right: 4px;
       display: grid;
        place-items: center;
       color: ${props => props.color ? props.color : ''};
       @media (max-width: 425px) {
           font-size: 14px;
           white-space: break-spaces;
       }
   }
   &:hover {
       transform: scale(1.05);
       box-shadow:0px 10px 10px rgba(0,0,0,.1)
   }
   &.dark {
       background: #1B1B1B;
       color: #fff;
   }
   &.transparent {
        border: 1px solid ${props => props.theme.colors.silverSand};
        background: transparent;
        color: ${({theme}) => theme.colors.white};

        svg {
            path {
                fill: ${({theme}) => theme.colors.white};
            }
        }
   }
   &.info {
        background: linear-gradient(150deg, ${props => props.theme.colors.skyblue} 0%, ${props => props.theme.colors.blueCrayola} 100%);
       color: #FFF;
   }
   &.success {
        background: linear-gradient(150deg, ${props => props.theme.colors.turquoiseBlue} 0%, ${props => props.theme.colors.oceanGreen} 100%);
       color: #FFF;
   }
   &.warning {
        background: linear-gradient(150deg, ${props => props.theme.colors.yellowCrayola} 0%, ${props => props.theme.colors.burntOrange} 100%);
       color: #FFF;
   }
   &.danger {
        background: linear-gradient(150deg, ${props => props.theme.colors.lightCoral} 0%, ${props => props.theme.colors.redPigment} 100%);
       color: #FFF;
   }
   &.delete {
        background: ${props => props.theme.colors.lightCoral};
       color: #FFF;
   }
   &.config {
       border: 1px solid ${props => props.theme.colors.skyblue};
       color: ${props => props.theme.colors.skyblue};
   }
   &.dashed {
       border: 2px dashed ${props => props.theme.colors.skyblue};
       color: ${props => props.theme.colors.skyblue};
       height: 52px;
       font-weight: bold;

       .icon {
        margin-right: 16px;
        margin-left: 4px;
        display: flex;
       svg {
           width: 24px;
           height: 24px;
           path {
               fill: ${props => props.theme.colors.skyblue};
           }
       }
   }
   }
   @media (max-width: 425px) {
       padding: 0 8px 0 8px;
   }
`