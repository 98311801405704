import { IconLoading } from '../../icons';
import { Container } from './styles';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    loading?: boolean;
    type?: 'submit' | 'button';
    children?: any;
    style?:
        'success'
        | 'info'
        | 'warning'
        | 'danger'
        | 'delete'
        | 'transparent'
        | 'dark';
    className?:
        'success'
        | 'info'
        | 'warning'
        | 'danger'
        | 'delete'
        | 'transparent'
        | 'dashed'
        | 'config'
        | 'dark'
        |any;
    rounded?: boolean;
    radius?: boolean;
    Icon?: any;
    onClick?(e?: any): void;
    color?: string;
    tooltip?: string
}

const ButtonLinear: React.FC<ButtonProps> = ({
    loading = false,
    children,
    type = 'submit',
    className = 'transparent',
    rounded = false,
    radius = false,
    Icon = false,
    onClick,
    color,
    tooltip,
    ...props
}) => {
    return (
        <Container
            rounded={rounded}
            radius={radius}
            type={type}
            className={`${className} form-children-default`}
            onClick={onClick}
            color={color}
            {...props}
        >
            { tooltip && 
                <span className="tooltip">{tooltip}</span>
            }
            {Icon && (
                <span className="icon">
                    <Icon />
                </span>
            )}
            {loading ? (
                <span className="children">
                    <IconLoading />
                </span>
            ) : (
                children && <span className="children">{children}</span>
            )}
        </Container>
    );
};

export default ButtonLinear;
