import axios from 'axios'

var baseUrl

if(process.env.NODE_ENV === 'development') {
    baseUrl = 'https://apiomni.eyou.com.br/bin/'
} else {
    baseUrl = 'https://apiomni.eyou.com.br/bin/'
}

const apiEyou = axios.create({
    baseURL: baseUrl
})

export default apiEyou
