import { Route } from 'react-router-dom';
import AttendenceOperator from '../pages/AttendenceOperator';
import AttendenceOperatorFinalizados from '../pages/AttendenceOperator/finalizados';
import ModalOperator from '../pages/ModalOperator';

export default function OperatorRoutes(props: any) {
    return (
        <>

            <Route path="/" exact component={ModalOperator} />
            <Route path="/fila-de-atendimento" exact component={AttendenceOperator} />
            <Route path="/fila-de-atendimento/finalizados" exact component={AttendenceOperatorFinalizados} />
        
            {/* <Layout {...props}> */}
                {/* <Route path="/meu-desempenho" exact component={PerformaceOperator} />
                <Route path="/horarios-e-grupos" exact component={HoursOperator} />
                <Route path="/componentes" exact component={Componentes} /> */}
            {/* </Layout> */}
        </>
    );
}
