import { Route } from 'react-router-dom';
import Layout from '../components/layout/private/Layout';

import Dashboard from '../pages/Dashboard';
import Componentes from '../pages/Componentes';

import Usuario from '../pages/Usuario/index';
import UsuarioVisualizar from '../pages/Usuario/visualizar';
import UsuarioNovo from '../pages/Usuario/novo';
import UsuarioEditar from '../pages/Usuario/editar';
import Chatbot from '../pages/Chatbot';
import ChatbotEdit from '../pages/Chatbot/edit';
import { ChatbotContextProvider } from '../contexts/ChatbotContext';
import Auditoria from '../pages/Audit/index';
import Notificacoes from '../pages/Notifications/index';
import GruposAtendimento from '../pages/GruposAtendimento';
import GrupoAtendimentoInfo from '../pages/GruposAtendimento/info';
import PesquisaSatisfacao from '../pages/GruposAtendimento/pesquisaSatisfacao';
import PesquisaSatisfacaoEdit from '../pages/GruposAtendimento/pesquisaSatisfacao/edit';
import MensagensPreDefinidas from '../pages/GruposAtendimento/mensagensPreDefinidas';
import ConfigurarBotao from '../pages/GruposAtendimento/configurarBotao';
import ConfigurarBotaoEdit from '../pages/GruposAtendimento/configurarBotao/edit';
import FormularioDeEntrada from '../pages/GruposAtendimento/formularioDeEntrada';
import GrupoAtendimentoOperadores from '../pages/GruposAtendimento/operadores';
import GrupoAtendimentoOperadoresEdit from '../pages/GruposAtendimento/operadores/edit';
import FormularioDeEntradaEdit from '../pages/GruposAtendimento/formularioDeEntrada/edit';
import ConfigCanal from '../pages/GruposAtendimento/configCanal';
import ConfigCanalEdit from '../pages/GruposAtendimento/configCanal/edit';
import HorarioAtendimento from '../pages/GruposAtendimento/horarioAtendimento/index';
import FunilAtendimento from '../pages/GruposAtendimento/funilAtendimento';
import Tags from '../pages/GruposAtendimento/tags';
import GrupoAtendimentoConfigurarPausas from '../pages/GruposAtendimento/configurarPausas';
import FilaDeAtendimento from '../pages/FilaDeAtendimento/index';
import BloqueiosList from '../pages/Bloqueios/index';
import ConfigSound from '../pages/ConfigUser';
import Atendimentos from '../pages/Atendimentos';
import Relatorios from '../pages/Relatorios';
import FilaAtendimentoGroup from '../pages/FilaAtendimentoGroup';
import RelatoriosSalvos from '../pages/Relatorios/salvos';
import PesquisaSatisfacaoRelatorio from '../pages/PesquisaSatisfacao';
import AttendenceOperator from '../pages/AttendenceOperator';
import AttendenceOperatorFinalizados from '../pages/AttendenceOperator/finalizados';
import Carona from '../pages/Carona';
import ConfigUser from '../pages/ConfigUser';

export default function PrivateRoutes(props: any) {
    return (
        <Layout {...props}>
            <Route path="/" exact component={Dashboard} />
            <Route path="/pesquisas-respondidas" exact component={PesquisaSatisfacaoRelatorio} />

            <ChatbotContextProvider>
                <Route path="/chatbot" exact component={Chatbot} />
                <Route path="/chatbot/:id" exact component={ChatbotEdit} />
            </ChatbotContextProvider>

            <Route path="/notificacoes" exact component={Notificacoes} />
            <Route path="/auditoria" exact component={Auditoria} />
            <Route path="/relatorios" exact component={Relatorios} />
            <Route path="/relatorios/salvos" exact component={RelatoriosSalvos} />

            <Route
                path="/grupos-de-atendimento"
                exact
                component={GruposAtendimento}
            />
            <Route
                path="/grupos-de-atendimento/:id"
                exact
                component={GrupoAtendimentoInfo}
            />

            <Route
                path="/grupos-de-atendimento/:id/pesquisa-de-satisfacao"
                exact
                component={PesquisaSatisfacao}
            />
            <Route
                path="/grupos-de-atendimento/:id/pesquisa-de-satisfacao/editar"
                exact
                component={PesquisaSatisfacaoEdit}
            />

            <Route
                path="/grupos-de-atendimento/:id/mensagens-pre-definidas"
                exact
                component={MensagensPreDefinidas}
            />

            <Route
                path="/grupos-de-atendimento/:id/configurar-botao"
                exact
                component={ConfigurarBotao}
            />
            <Route
                path="/grupos-de-atendimento/:id/configurar-botao/edit"
                exact
                component={ConfigurarBotaoEdit}
            />

            <Route
                path="/grupos-de-atendimento/:id/formulario-de-entrada"
                exact
                component={FormularioDeEntrada}
            />
            <Route
                path="/grupos-de-atendimento/:id/formulario-de-entrada/edit"
                exact
                component={FormularioDeEntradaEdit}
            />

            <Route
                path="/grupos-de-atendimento/:id/configurar-canais"
                exact
                component={ConfigCanal}
            />
            <Route
                path="/grupos-de-atendimento/:id/configurar-canais/edit"
                exact
                component={ConfigCanalEdit}
            />

            <Route
                path="/grupos-de-atendimento/:id/horario-atendimento"
                exact
                component={HorarioAtendimento}
            />

            <Route
                path="/grupos-de-atendimento/:id/funil-de-atendimento"
                exact
                component={FunilAtendimento}
            />

            <Route
                path="/grupos-de-atendimento/:id/tags"
                exact
                component={Tags}
            />

            <Route
                path="/grupos-de-atendimento/:id/pausas"
                exact
                component={GrupoAtendimentoConfigurarPausas}
            />

            <Route
                path="/grupos-de-atendimento/:id/operadores"
                exact
                component={GrupoAtendimentoOperadores}
            />
            <Route
                path="/grupos-de-atendimento/:id/operadores/:idOperador"
                exact
                component={GrupoAtendimentoOperadoresEdit}
            />

            <Route
                path="/fila-de-atendimento"
                exact
                component={FilaAtendimentoGroup}
            />

            <Route
                path="/atendimentos"
                exact
                component={Atendimentos}
            />

            <Route path="/usuarios" exact component={Usuario} />
            <Route
                path="/usuarios/visualizar/:id"
                exact
                component={UsuarioVisualizar}
            />
            <Route path="/usuarios/novo" exact component={UsuarioNovo} />
            <Route
                path="/usuarios/editar/:id"
                exact
                component={UsuarioEditar}
            />

            <Route
                path="/bloqueios"
                exact
                component={BloqueiosList}
            />

            <Route path="/carona" exact component={Carona} />

            <Route
                path="/configurar-sons"
                exact
                component={ConfigUser}
            />

            <Route path="/componentes" exact component={Componentes} />
            <Route path="/teste2" exact component={Dashboard} />
        </Layout>
    );
}
