import { useEffect, useState } from 'react';
import ButtonLinear from '../../components/form/ButtonLinear';
import ContainerFilter from '../../components/form/ContainerFilter';
import InputDefault from '../../components/form/InputDefault';
import InputRadioDefault from '../../components/form/InputRadioDefault';
import InputSwitchDefault from '../../components/form/InputSwitchDefault';
import SelectDefault from '../../components/form/SelectDefault';
import Column from '../../components/grid/Columns';
import Row from '../../components/grid/Row';
import {
    IconAfirmative,
    IconBell,
    IconChartLine,
    IconChartPie,
    IconDelete,
    IconHeadphone,
} from '../../components/icons';
import { useMessage } from '../../contexts/MessagesContext';
import { useRoutes } from '../../contexts/RoutesContext';

import { Container } from './style';

export default function Componentes() {
    const { setPageName } = useRoutes();
    const { addSuccess, addWarning, addInfo, addDanger } = useMessage();

    useEffect(() => {
        setPageName('Componentes');
    }, [setPageName]);

    const [inputRadio, setInputRadio] = useState<string>('group');
    const [inputSwitch, setInputSwitch] = useState<any>(['1']);

    return (
        <Container>
            <h2 className="default">Grids rows</h2>

            <Row>
                <Column width="6">
                    <p>Column 6</p>
                </Column>
                <Column width="6">
                    <p>Column 6</p>
                </Column>
            </Row>

            <Row mt="2">
                <Column width="3">
                    <p>Column 3</p>
                </Column>
                <Column width="3">
                    <p>Column 3</p>
                </Column>
                <Column width="3">
                    <p>Column 3</p>
                </Column>
                <Column width="3">
                    <p>Column 3</p>
                </Column>
            </Row>

            <Row mt="2">
                <Column width="2">
                    <p>Column 2</p>
                </Column>
                <Column width="2">
                    <p>Column 2</p>
                </Column>
                <Column width="2">
                    <p>Column 2</p>
                </Column>
                <Column width="2">
                    <p>Column 2</p>
                </Column>
                <Column width="2">
                    <p>Column 2</p>
                </Column>
                <Column width="2">
                    <p>Column 2</p>
                </Column>
            </Row>

            <hr />

            <ContainerFilter>
                <InputDefault
                    onChangeText={(value) => console.log(value)}
                    value={''}
                    search={true}
                    placeholder={'Busca com icone'}
                />

                <InputDefault
                    onChangeText={(value) => console.log(value)}
                    value={''}
                    placeholder={'Busca sem icone'}
                />

                <InputDefault
                    onChangeText={(value) => console.log(value)}
                    value={''}
                    placeholder={'Telefone'}
                    mask={'(99) 99999-9999'}
                />
            </ContainerFilter>

            <hr />

            <ContainerFilter>
                <SelectDefault
                    value=""
                    placeholder="Placeholder maneiro"
                    onChangeText={(value) => console.log(value)}
                >
                    <option value="1">Cargo 1</option>
                    <option value="2">Cargo 2</option>
                    <option value="3">Cargo 3</option>
                    <option value="4">Cargo 4</option>
                </SelectDefault>

                <SelectDefault
                    value="2"
                    onChangeText={(value) => console.log(value)}
                >
                    <option value="1">Cargo 1</option>
                    <option value="2">Cargo 2</option>
                </SelectDefault>
            </ContainerFilter>

            <hr />

            <ContainerFilter>
                <InputRadioDefault
                    options={[
                        {
                            label: 'Radio 1',
                            value: '1',
                        },
                        {
                            label: 'Radio 1',
                            value: '2',
                        },
                    ]}
                    value={inputRadio}
                    onChange={(value) => setInputRadio(value)}
                />
            </ContainerFilter>

            <hr />

            <ContainerFilter>
                <InputRadioDefault
                    flexDirection={'column'}
                    options={[
                        {
                            label: 'Radio 1',
                            value: '1',
                        },
                        {
                            label: 'Radio 2',
                            value: '2',
                        },
                    ]}
                    value={inputRadio}
                    onChange={(value) => setInputRadio(value)}
                />
            </ContainerFilter>

            <hr />

            <ContainerFilter>
                <InputRadioDefault
                    flexDirection={'row'}
                    maxColumns={4}
                    options={[
                        {
                            label: 'Radio 1',
                            value: '1',
                        },
                        {
                            label: 'Radio 2',
                            value: '2',
                        },
                        {
                            label: 'Radio 3',
                            value: '3',
                        },
                        {
                            label: 'Radio 4',
                            value: '4',
                        },
                    ]}
                    value={inputRadio}
                    onChange={(value) => setInputRadio(value)}
                />
            </ContainerFilter>

            <hr />

            <ContainerFilter>
                <InputSwitchDefault
                    options={[
                        {
                            label: 'Switch 1',
                            value: '1',
                        },
                        {
                            label: 'Switch 2',
                            value: '2',
                        },
                    ]}
                    value={inputSwitch}
                    onChange={(value) => setInputSwitch(value)}
                />
            </ContainerFilter>

            <hr />

            <ContainerFilter>
                <InputSwitchDefault
                    flexDirection={'column'}
                    options={[
                        {
                            label: 'Switch 1',
                            value: '1',
                        },
                        {
                            label: 'Switch 2',
                            value: '2',
                        },
                    ]}
                    value={inputSwitch}
                    onChange={(value) => setInputSwitch(value)}
                />
            </ContainerFilter>

            <hr />

            <ContainerFilter>
                <InputSwitchDefault
                    flexDirection={'row'}
                    maxColumns={4}
                    options={[
                        {
                            label: 'Switch 1 disabled',
                            value: '1',
                            disabled: true,
                        },
                        {
                            label: 'Switch 2',
                            value: '2',
                        },
                        {
                            label: 'Switch 3',
                            value: '3',
                        },
                        {
                            label: 'Switch 4',
                            value: '4',
                        },
                        {
                            label: 'Switch 5 disabled',
                            value: '5',
                            disabled: true,
                        },
                        {
                            label: 'Switch 6 disabled',
                            value: '6',
                            disabled: true,
                        },
                    ]}
                    value={inputSwitch}
                    onChange={(value) => setInputSwitch(value)}
                />
            </ContainerFilter>

            <hr />

            <ContainerFilter>
                <ButtonLinear>Default</ButtonLinear>
                <ButtonLinear
                    onClick={() => addInfo('Titulo info', 'Mensagem')}
                    className="info"
                >
                    Info
                </ButtonLinear>
                <ButtonLinear
                    onClick={() => addSuccess('Titulo info', 'Mensagem')}
                    className="success"
                >
                    Success
                </ButtonLinear>
                <ButtonLinear
                    onClick={() => addWarning('Titulo info', 'Mensagem')}
                    className="warning"
                >
                    Warning
                </ButtonLinear>
                <ButtonLinear
                    onClick={() => addDanger('Titulo info', 'Mensagem')}
                    className="danger"
                >
                    Danger
                </ButtonLinear>
                <ButtonLinear className="delete" Icon={IconDelete} />
            </ContainerFilter>

            <hr />

            <ContainerFilter>
                <ButtonLinear rounded={true}>Default</ButtonLinear>
                <ButtonLinear
                    onClick={() => addInfo('Titulo info', 'Mensagem')}
                    rounded={true}
                    className="info"
                >
                    Info
                </ButtonLinear>
                <ButtonLinear
                    onClick={() => addSuccess('Titulo success', 'Mensagem')}
                    rounded={true}
                    className="success"
                >
                    Success
                </ButtonLinear>
                <ButtonLinear
                    onClick={() => addWarning('Titulo warning', 'Mensagem')}
                    rounded={true}
                    className="warning"
                >
                    Warning
                </ButtonLinear>
                <ButtonLinear
                    onClick={() => addDanger('Titulo danger', 'Mensagem')}
                    rounded={true}
                    className="danger"
                >
                    Danger
                </ButtonLinear>
                <ButtonLinear rounded={true} className="delete">
                    Delete
                </ButtonLinear>
            </ContainerFilter>

            <hr />

            <ContainerFilter>
                <ButtonLinear rounded={true} Icon={IconChartPie}>
                    Default
                </ButtonLinear>
                <ButtonLinear
                    onClick={() => addInfo('Titulo info', 'Mensagem')}
                    rounded={true}
                    className="info"
                    Icon={IconBell}
                >
                    Info
                </ButtonLinear>
                <ButtonLinear
                    onClick={() => addSuccess('Titulo danger', 'Mensagem')}
                    rounded={true}
                    className="success"
                    Icon={IconAfirmative}
                >
                    Success
                </ButtonLinear>
                <ButtonLinear
                    onClick={() => addWarning('Titulo warning', 'Mensagem')}
                    rounded={true}
                    className="warning"
                    Icon={IconChartLine}
                >
                    Warning
                </ButtonLinear>
                <ButtonLinear
                    onClick={() => addDanger('Titulo danger', 'Mensagem')}
                    rounded={true}
                    className="danger"
                    Icon={IconHeadphone}
                >
                    Danger
                </ButtonLinear>
                <ButtonLinear
                    rounded={true}
                    className="delete"
                    Icon={IconDelete}
                >
                    Delete
                </ButtonLinear>
            </ContainerFilter>
        </Container>
    );
}
