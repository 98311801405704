import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin-top: 24px;

    h1 {
        font-size: 24px;
        font-weight: bold;
        color: ${props => props.theme.colors.white};

        margin-bottom: 24px;
    }

    p {
        color: ${props => props.theme.colors.white};
    }

    .skeletor{
        margin-bottom: 8px;
    }

    .Mymessage {
        font-size: 0.875rem;
        font-weight: bold;
        white-space: nowrap;
        color: ${({theme}) => theme.colors.white};
        max-width: 320px;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        
    }


    @media (max-width: 425px) {
        .card-table {
            div {
                padding-right: 0;
                div {
                    h2 {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        height: 15px;
                        width: 80px;
                    }
                }
            }
        }
    }
`;
