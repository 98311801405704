import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    input {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 48px;
        height: 48px;

        background: transparent;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
    }
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .titleSound {
        font-size: 2rem;
        font-weight: bold;
        color: ${(props) => props.theme.colors.white};
    }
`;

export const Main = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 8px;

    .skeletor {
        margin-bottom: 8px;
    }

    .tableSound {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;        

        padding: 0 24px;
        height: 85px;
        border-radius: 16px;
        background: ${props => props.theme.colors.spaceCadedDark};
    }

    .tdSound {
        display: flex;
        flex-direction: column;
        gap: 12px;
        white-space: nowrap;
        color: ${(props) => props.theme.colors.white};
        
    }

    .tdSoundPlayer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        gap: 20px;        
    }

    @media (max-width: 900px) {
        .tableSound {
            overflow: auto;
            gap: 15px;
        }
    }
    @media (max-width: 425px) {
        .tdSoundPlayer {
            width: 415px;
            audio {
                min-width: 300px;
            }
        }
    }
`;

export const Progress = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
  gap: 1rem;
  font-size: 0.875rem;
  span {
    display: inline-block;
    width: 4rem;
    text-align: center;
    color: ${(props) => props.theme.colors.white};
  }
`;

export const Sliders = styled.div`
  flex: 1;
`;

export const EmptySlider = styled.div`
  width: 100%;
  height: 4px;
  background: #9f75ff;
  border-radius: 2px;
`;

export const ButtonVideo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 48px;
    height: 48px;

    border-radius: 50%;
    border: none;
    background: ${props => props.theme.colors.blueCrayola};
    position: relative;
    cursor: pointer;
    input[type=file], /* FF, IE7+, chrome (except button) */
    input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
        cursor: pointer; 
    }


`;