import React, { useState } from 'react'
import InputColor from 'react-input-color'
import { IconPincelLine, IconText } from '../../icons'

import { Container } from './styles'

interface InputProps {
    onChangeText: (value: string | any) => void;
    value: any;
    type?: boolean;
}

export default function InputColorUi({
    onChangeText,
    value,
    type
}: InputProps) {
    const [color, setColor] = useState({} as any);

    function handleColor(event: any) {
        onChangeText(event.hex);
        setColor(event);
      }
    return (
        <Container>
            {type ? (
                <IconText />
            ) : (
            <>
                <IconPincelLine
                    color={color.hex}
                />


                <div className="relative">

                    <InputColor
                        initialValue={`${value}`}
                        onChange={handleColor}
                        placement="right"
                    />
                </div>
            </>
            )}
        </Container>
    )
}
