export const Logo = ({ colorEyou = '#5A646E', colorOmni = '#34D5FA' }) => (
    <svg
        width="275"
        height="53"
        viewBox="0 0 275 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="logo-default"
    >
        <path
            d="M19.7083 0C8.10561 0 0 7.5645 0 19.7337C0 31.9029 8.24453 39.4674 19.7083 39.4674C29.4235 39.4674 36.3411 33.6938 38.2286 26.1953H25.5097C24.5324 28.2596 22.6449 29.9092 19.2915 29.9092C15.4495 29.9092 12.3021 27.5715 11.8854 22.5521H38.9328C39.0717 21.3833 39.1436 20.2144 39.1436 19.1163C39.1388 7.42782 31.1002 0 19.7083 0ZM11.9524 16.0245C12.6518 11.7639 15.6555 9.42616 19.5694 9.42616C23.5551 9.42616 26.9085 11.8345 26.9085 16.0245H11.9524Z"
            fill={colorEyou}
        />
        <path
            d="M107.485 0C96.0936 0 87.2167 7.5645 87.2167 19.7337C87.2167 31.9029 95.8828 39.4674 107.347 39.4674C118.81 39.4674 127.754 31.9029 127.754 19.7337C127.754 7.5645 118.949 0 107.485 0ZM107.347 29.2918C103.011 29.2918 99.3799 26.1953 99.3799 19.7337C99.3799 13.2013 103.155 10.1755 107.485 10.1755C111.677 10.1755 115.591 13.2013 115.591 19.7337C115.596 26.2 111.61 29.2918 107.347 29.2918Z"
            fill={colorEyou}
        />
        <path
            d="M159.291 1.2113V21.0062C159.291 25.1585 155.856 28.5378 151.635 28.5378C147.415 28.5378 143.98 25.1585 143.98 21.0062V1.2113H132.114V21.0062C132.114 31.5965 140.871 40.2168 151.64 40.2168C162.404 40.2168 171.166 31.6013 171.166 21.0062V1.2113H159.291Z"
            fill={colorEyou}
        />
        <path
            d="M70.9097 14.9075H70.9001V18.2962C70.8043 22.3683 67.4126 25.6534 63.2496 25.6534C59.0292 25.6534 55.5943 22.2741 55.5943 18.1218V1.2113H43.7281V18.1218C43.7281 28.7121 52.4853 37.3324 63.2544 37.3324C65.9371 37.3324 68.5001 36.7951 70.8283 35.8289C70.3971 38.6379 67.9348 40.8012 64.9599 40.8012H57.8459V52.4755H64.9599C74.7805 52.4755 82.7711 44.6141 82.7711 34.9523V18.4706C82.7711 18.3528 82.7807 18.2397 82.7807 18.1218V1.2113H70.9145V14.9075H70.9097Z"
            fill={colorEyou}
        />
        <path
            d="M194.685 39.9084C192.496 39.9084 190.484 39.3926 188.649 38.361C186.814 37.3069 185.354 35.8604 184.271 34.0214C183.21 32.16 182.679 30.0631 182.679 27.7307C182.679 25.3983 183.21 23.3126 184.271 21.4736C185.354 19.6122 186.814 18.1657 188.649 17.134C190.484 16.1024 192.496 15.5866 194.685 15.5866C196.896 15.5866 198.908 16.1024 200.721 17.134C202.556 18.1657 204.004 19.6122 205.065 21.4736C206.127 23.3126 206.657 25.3983 206.657 27.7307C206.657 30.0631 206.127 32.16 205.065 34.0214C204.004 35.8604 202.556 37.3069 200.721 38.361C198.886 39.3926 196.874 39.9084 194.685 39.9084ZM194.685 33.7859C196.343 33.7859 197.647 33.2364 198.598 32.1375C199.571 31.0386 200.057 29.5697 200.057 27.7307C200.057 25.8468 199.571 24.3667 198.598 23.2902C197.647 22.1913 196.343 21.6418 194.685 21.6418C193.004 21.6418 191.689 22.1913 190.738 23.2902C189.787 24.3667 189.312 25.8468 189.312 27.7307C189.312 29.5921 189.787 31.0723 190.738 32.1712C191.689 33.2477 193.004 33.7859 194.685 33.7859Z"
            fill={colorOmni}
        />
        <path
            d="M233.89 20.6326C236.233 20.6326 238.068 21.3503 239.395 22.7856C240.744 24.2209 241.418 26.1832 241.418 28.6726V39.6729H234.918V29.5472C234.918 28.538 234.641 27.7531 234.089 27.1924C233.536 26.6318 232.784 26.3514 231.833 26.3514C230.883 26.3514 230.131 26.6318 229.578 27.1924C229.025 27.7531 228.749 28.538 228.749 29.5472V39.6729H222.249V29.5472C222.249 28.538 221.972 27.7531 221.419 27.1924C220.889 26.6318 220.148 26.3514 219.197 26.3514C218.225 26.3514 217.462 26.6318 216.909 27.1924C216.356 27.7531 216.08 28.538 216.08 29.5472V39.6729H209.579V20.8008H216.08V23.2902C216.655 22.4828 217.395 21.8437 218.302 21.3727C219.231 20.8793 220.292 20.6326 221.486 20.6326C222.857 20.6326 224.073 20.9354 225.134 21.5409C226.217 22.1464 227.069 22.9986 227.688 24.0975C228.351 23.0659 229.224 22.2361 230.308 21.6082C231.391 20.9578 232.585 20.6326 233.89 20.6326Z"
            fill={colorOmni}
        />
        <path
            d="M257.151 20.6326C259.296 20.6326 260.998 21.3615 262.258 22.8192C263.519 24.2545 264.149 26.2057 264.149 28.6726V39.6729H257.648V29.5472C257.648 28.4708 257.372 27.6298 256.819 27.0242C256.267 26.3963 255.526 26.0823 254.597 26.0823C253.624 26.0823 252.862 26.3963 252.309 27.0242C251.756 27.6298 251.48 28.4708 251.48 29.5472V39.6729H244.979V20.8008H251.48V23.492C252.055 22.6398 252.828 21.9558 253.801 21.44C254.774 20.9017 255.891 20.6326 257.151 20.6326Z"
            fill={colorOmni}
        />
        <path
            d="M270.999 19.0515C269.849 19.0515 268.921 18.7376 268.213 18.1096C267.528 17.4592 267.185 16.6519 267.185 15.6875C267.185 14.7007 267.528 13.8822 268.213 13.2318C268.921 12.5814 269.849 12.2562 270.999 12.2562C272.127 12.2562 273.033 12.5814 273.719 13.2318C274.426 13.8822 274.78 14.7007 274.78 15.6875C274.78 16.6519 274.426 17.4592 273.719 18.1096C273.033 18.7376 272.127 19.0515 270.999 19.0515ZM274.216 20.8008V39.6729H267.716V20.8008H274.216Z"
            fill={colorOmni}
        />
    </svg>
);
