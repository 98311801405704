import { useEffect, useState } from 'react';
import InputDefaultDate from '../../components/form/InputDefaultDate';
import SelectDefault from '../../components/form/SelectDefault';
import { Container, Header } from './styles';
import ButtonLinear from '../../components/form/ButtonLinear';
import { useRoutes } from '../../contexts/RoutesContext';
import Row from '../../components/grid/Row';
import Column from '../../components/grid/Columns';
import CardContainer from '../../components/layout/containers/CardContainer';
import TableSimple from '../../components/TableSimple';
import Loading from '../../components/Loading/Loading';

import FailedRegister from '../../components/FailedRegister';
import moment from 'moment';
import 'moment/locale/pt';
import apiTropa from '../../services/apiTropa';
import ButtonDownload from '../../components/form/ButtonDownload/ButtonDownload';
import ButtonDownloadAudit from '../../components/form/ButtonDownloadAudit/ButtonDownloadAudit';

interface UserProps {
    id_login: string;
    nome: string;
    apelido: string;
}

interface DataProps {
    id_login: string;
    nome: string;
    apelido: string;
    criado_log: string;
    acao: string;
    usuario: [
        {
            nome: string;
        }
    ];
}

export default function Audit() {
    moment.locale('pt-br');

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<DataProps[]>([]);
    const [users, setUsers] = useState<UserProps[]>([]);
    const [load, setLoad] = useState(false);
    const { setPageName } = useRoutes();

    const [dataFilter, setDataFilter] = useState({
        data: moment(new Date()).utc().format('DD/MM/YYYY'),
        id_usuario: '',
    });

    useEffect(() => {
        setPageName('Auditoria');
        getUsers();
    }, [setPageName]);

    function handleOnValue(name: string, value: any) {
        setDataFilter({ ...dataFilter, [name]: value });
    }

    async function getUsers() {
        try {
            setLoading(true);
            const response: any = await apiTropa.get('/auditoria/usuarios');
            setUsers(response.data.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    const convertDateTimePt = (date: any) => {
        const data = moment(date).utc().format('DD/MM/YYYY');
        const hoursComplete = date.split('T')[1];
        const hours = hoursComplete.split(':')[0];
        const min = hoursComplete.split(':')[1];

        const resolver = data + ' ' + hours + ':' + min;
        return resolver;
    };

    async function handleFilter() {
        try {
            setLoad(true);
            const response: any = await apiTropa.get(
                `/auditoria?data=${dataFilter.data}&id_usuario=${dataFilter.id_usuario}`
            );

            const newData: any = [];

            
            response.data.data.map((row: any) => {
                newData.push({
                    data: convertDateTimePt(row.criado_log),
                    usuario: row.nome,
                    acao: row.descricao,
                });
            });
            

            setData(newData);
            if (response.data.status === 'error') {
                throw response.data.message;
            }

            setLoad(false);
        } catch (err) {
            console.log('ERROR', err);
        }
    }

    const headers = [
        { label: 'Data / Hora', value: 'data' },
        { label: 'Usuário', value: 'usuario' },
        { label: 'Ação', value: 'acao' },
    ] as any;

    return (
        <>
            {loading === true ? (
                <Loading active={loading} />
            ) : (
                <Container>
                    <Header>
                        <InputDefaultDate
                            value={dataFilter.data}
                            placeholder={dataFilter.data}
                            onChange={(event: React.FormEvent<HTMLInputElement>) =>
                                handleOnValue('data', event.currentTarget.value)
                            }
                        />
                        <SelectDefault
                            value={dataFilter.id_usuario}
                            placeholder="Usuário"
                            onChangeText={(value) => handleOnValue('id_usuario', value)}
                        >
                            {users.map((row) => (
                                <option key={row.id_login} value={row.id_login}>
                                    {row.nome.length > 0 ? row.nome : row.apelido}
                                </option>
                            ))}
                        </SelectDefault>

                        <ButtonLinear className="info" onClick={handleFilter}>
                            Filtrar
                        </ButtonLinear>
                        {
                           data ? <ButtonDownloadAudit filename={`Relatório Auditoria`} headers={headers} data={data as any} /> : ''
                        }
                        
                    </Header>

                    <Row>
                        <Column width="12">
                            {data.length > 0 ? (
                                <CardContainer>
                                    <TableSimple height="600px">
                                        <thead>
                                            <tr>
                                                <th>Data / Hora</th>
                                                <th>Usuário</th>
                                                <th>Ação</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((row: any, key) => (
                                                <tr key={key}>
                                                    <td>{row.data}</td>
                                                    <td>{row.usuario}</td>
                                                    <td>{row.acao}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </TableSimple>
                                </CardContainer>
                            ) : (
                                <FailedRegister />
                            )}
                        </Column>
                    </Row>
                </Container>
            )}
        </>
    );
}
