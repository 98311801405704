import React, { useState } from 'react';
import { IconArrow } from '../icons';

import {
    Container,
    CardTable,
    CardInfoTable,
    ButtonArrow,
    ContainerDropDowwn,
} from './styles';

interface TableProps {
    columns: number;
    isArrow?: boolean;
    children: React.ReactNode;
    dropDownComponent?: any;
    onClickDropDownOpen?: () => {};
}

export default function TableDefault({
    children,
    columns,
    isArrow = true,
    dropDownComponent: Component,
    onClickDropDownOpen
}: TableProps) {
    const [visible, setVisible] = useState<boolean>(false);
    
    async function onClick(){
        if( !visible && onClickDropDownOpen instanceof Function ) await onClickDropDownOpen()
        setVisible(!visible)
    }

    return (
        <Container>
            <CardTable className="card-table">
                <CardInfoTable
                    isArrow={isArrow}
                    style={{
                        gridTemplateColumns: `repeat(${columns}, 1fr)`
                    }}
                >
                    {children}

                    {Component && (
                        <ButtonArrow
                            visible={visible}
                            onClick={() => onClick()}
                        >
                            <IconArrow />
                        </ButtonArrow>
                    )}
                </CardInfoTable>

                {visible && (
                    <ContainerDropDowwn>{Component}</ContainerDropDowwn>
                )}
            </CardTable>
        </Container>
    );
}
