import { useEffect } from "react"
import { LoadingContainer } from "./styles"
import ClipLoader from "react-spinners/ClipLoader";


export default function Loading({
    active = false
}){

    useEffect(() => {

    }, [active])

    return(
    <>
    {active === true &&
        <LoadingContainer>
            <ClipLoader color={"#34D5FA"} loading={true} css={""} size={200} />
        </LoadingContainer>
    }
    </>
    )

}