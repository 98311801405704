import { RingLoader } from "react-spinners";
import { ContainerFailed } from "./styles";


export default function FailedRegister() {

    return (
        <ContainerFailed>
            <h1>Nenhum registro encontrado.</h1>
            <div className='loader'>
                <RingLoader  color={"#34D5FA"} loading={true} css={""} size={200} />
            </div>            
        </ContainerFailed>
    )
}