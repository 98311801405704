import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    h2 {
        font-size: 24px;
        font-weight: bold;
        color: ${props => props.theme.colors.white};
    }

    span {
        font-size: 14px;
        font-weight: normal;
        color: ${props => props.theme.colors.white};
        margin-top: 24px;
    }
`;
