import React from 'react';
import { IQuestion } from '../../../../pages/GruposAtendimento/pesquisaSatisfacao/index';
import InputRadioDefault from '../../../form/InputRadioDefault';
import InputSwitchDefault from '../../../form/InputSwitchDefault';
import InputTextarea from '../../../form/InputTextarea';
import {
    ContainerQuestion,
    ContainerQuestionItem,
    TitleQuestion,
} from '../../../../pages/GruposAtendimento/pesquisaSatisfacao/styles';
import CardContainer from '../../../layout/containers/CardContainer';

interface QuestionProps {
    row: IQuestion;
}

export function Rating(props: any) {
    let array = [0];
    for (
        var i = parseFloat(props.min + 1);
        i < parseFloat(props.max) + 1;
        i++
    ) {
        array.push(i);
    }

    return (
        <div className="rating">
            {array.map((row, key) => (
                <div>{row}</div>
            ))}
        </div>
    );
}

const Question: React.FC<QuestionProps> = ({ row }) => {
    return (
        <CardContainer>
            <TitleQuestion>
                <h2>{row.title}</h2>
            </TitleQuestion>

            {(row.type === 'checkbox' || row.type === 'radio') && (
                <ContainerQuestion>
                    {row.items.map((item, itemKey) => (
                        <ContainerQuestionItem key={itemKey}>
                            {item.type === 'checkbox' && (
                                <InputSwitchDefault
                                    onChange={() => {}}
                                    options={[
                                        {
                                            label: item.value,
                                            value: item.value,
                                        },
                                    ]}
                                    value={[item.value]}
                                />
                            )}

                            {item.type === 'radio' && (
                                <InputRadioDefault
                                    onChange={() => {}}
                                    options={[
                                        {
                                            label: item.value,
                                            value: item.value,
                                        },
                                    ]}
                                    value=""
                                />
                            )}

                            {item.type === 'textarea' && (
                                <InputTextarea
                                    onChangeText={(value) => {}}
                                    value={item.value}
                                />
                            )}
                        </ContainerQuestionItem>
                    ))}
                </ContainerQuestion>
            )}

            {row.type === 'rating' && (
                <ContainerQuestion>
                    <Rating max={row.max} min={row.min} />
                </ContainerQuestion>
            )}
        </CardContainer>
    );
};

export default Question;
