import styled from 'styled-components';

// import { Props } from '.'

interface PropsUser {
  status: any
}

export const SubContainer = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(252px, 1fr));
    gap: 22px;
    width: 100%;
    max-height: 140px;
    padding-bottom: 20px;

    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    .empty {
      font-size: 13px;
      color: ${({theme}) => theme.colors.subTitle};
    }
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 252px;
`;

export const PersonImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid #FFFFFF;
    }
`;

export const PersonInfo = styled.div<PropsUser>`
    display: flex;
    flex-direction: column;
    margin-left: 16px;

    h2 {
        font-size: 14px;
        color: ${props => props.theme.colors.white};
        display: flex;
        align-items: center;

        .ball {
            width: 8px;
            height: 8px;
            margin-left: 4px;
            background: ${(props) =>
            props.status === 'Em atendimentos'
              ? '#38FFE7'
              : '#fff' && props.status === 'Pausa'
                ? '#CB3D9A'
                : '#fff' && props.status === 'Aguardando fila'
                  ? '#F5A623'
                  : '#fff' && props.status === 'Operadores Online'
                    ? '#7B61FF'
                    : '#fff' && props.status === 'Abandonos'
                      ? '#FF868B'
                      : '#fff'};
            border-radius: 50%;
        }
    }

    h3 {
        font-size: 12px;
        color: ${props => props.theme.colors.lavanderGray};
        margin-top: 4px;
    }

    span {
        font-size: 12px;
        color: ${props => props.theme.colors.lavanderGray};
        margin-top: 4px;
    }
`;
