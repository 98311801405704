import styled, { css } from 'styled-components';

interface SeparatorProps {
    padding?: boolean;
    margin?: string; 
}

export const Separator = styled.div<SeparatorProps>`
    width: 100%;
    border-bottom: 2px solid #312F62;
    /* margin-top: 24px; */

    margin: ${props => props.margin ? props.margin : "0"}

    ${props => props.padding &&
    css`
        margin-bottom: 32px;
        margin-top: 32px;
    `
    }
`;
