export const dark = {
    title: 'dark',
    colors: {
        white: '#FFFFFF',
        silverSand: '#C5CED7',
        lavanderGray: '#B6B6C5',
        cadetGrey: '#8A99A8',
        spaceCaded: '#1F1F48',
        spaceCadedDark: '#262655',
        spaceCadedLight: '#292755',
        skyblue: '#34D5FA',
        blueCrayola: '#4A7DFF',
        stateBlue: '#7B61FF',
        turquoiseBlue: '#38FFE7',
        oceanGreen: '#28B2A2',
        oceanGreenLight: '#2FF0AB',
        yellowCrayola: '#F5A623',
        burntOrange: '#CC561E',
        lightCoral: '#FF868B',
        redPigment: '#E81E26',
        wine: '#CB3D9A',
        lightPurple: '#CECEEA',

        titleHeader: '#FFFFFF',
        subTitle: '#FFFFFF',
        colorSwitch: '#5A646E',

        scrolltrack: '#8383B2',
        scrolltrackBar: '#59588D',

        bgChat: '#FFFFFF',
        bgAreaChat: '#FFFFFF',

        bgChatOp: '#28384B'
    }
}