import React from 'react';
import styled from 'styled-components';
import { IconInfo } from '../../icons';

interface ContainerProps {
    color: string;
}

const Container = styled.div<ContainerProps>`
    /* display: flex;
    justify-content: space-between;
    height: 100px;
    animation: pulse 2s;
    background: ${(props) => props.theme.colors.spaceCadedLight};
    .text {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        color: ${({theme}) => theme.colors.subTitle};
        b {
            font-size: 32px;
            font-weight: bold;
        }
        span {
            font-size: 16px;
            margin-top: 13px;

        }
        .info {
            font-size: 11px;
            display: flex;
            align-items: center;
            svg {
                margin-right: 5px;
                width: 15px;
                height: 15px;
            }
        }
    }
    svg {
        width: 30px;
        height: 30px;
        path {
            fill: ${(props) => props.color};
        }
    } */

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: pulse 2s;
    height: 120px;
    background: ${(props) => props.theme.colors.spaceCadedLight};

    .headerCard {
        display: flex;
        justify-content: space-between;
        color: ${({theme}) => theme.colors.subTitle};

        b {
            font-size: 32px;
            font-weight: bold;
            margin-top: 10px;
        }
        svg {
            width: 30px;
            height: 30px;
        path {
            fill: ${(props) => props.color};
        }
        }
    }

    .text {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        color: ${({theme}) => theme.colors.subTitle};

        >span {
            font-size: 1rem;
            margin-top: 13px;
        }

        .info {
            display: flex;
            align-items: center;
            justify-content: baseline;
            gap: 5px;
            width: 100%;
            margin-top: 12px;

            >span {
                font-size: 0.75rem;
            }

            .icone {
                
                svg {
                    width: 15px;
                    height: 15px;
                    path {
                        fill: ${(props) => props.color};
                    }
                }
            }

        }
    }
`;

interface CardProps {
    data: DataProps;
}

interface DataProps {
    name: string;
    value: number|any;
    color: string;
    icon: any;
    info?: string
}

const CardTotalIcon: React.FC<CardProps> = ({ data }) => {
    return (
        // <Container color={data.color}>
        //     <div className="text">
        //         <b>{data.value}</b>
        //         <span>{data.name}</span>
        //         { data.info && 
        //             <span className="info">
        //                 <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="2"><circle cx="12" cy="12" r="10"/><path stroke-linecap="round" d="M12 7h.01"/><path stroke-linecap="round" strokeLinejoin="round" d="M10 11h2v5m-2 0h4"/></g></svg>
        //                 {data.info}
        //             </span>
        //         }
        //     </div>
        //     <div className="icon">{data.icon}</div>
        // </Container>

        <Container color={data.color}>

            <div className="headerCard">
                <b>{data.value}</b>
                <div className="icon">{data.icon}</div>
            </div>

            <div className="text">
                <span>{data.name}</span>
                {data.info && 
                    <div className="info">
                        <div className="icone">
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="2"><circle cx="12" cy="12" r="10"/><path stroke-linecap="round" d="M12 7h.01"/><path stroke-linecap="round" strokeLinejoin="round" d="M10 11h2v5m-2 0h4"/></g></svg>
                        </div>
                        <span>{data.info}</span>
                    </div>
                }
            </div>
        </Container>
    );
};

export default CardTotalIcon;
