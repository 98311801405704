import { Buffer } from 'buffer';
import { useEffect, useState } from 'react';
import ButtonLinear from '../../components/form/ButtonLinear';
import { Td, TdDescription, TdSubtitle } from '../../components/layout/containers/Td';
import TableDefault from '../../components/TableDefault';
// import { useMessage } from '../../contexts/MessagesContext'
import { Container, FilterType, ListTable, ModalCarona } from './styles';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import FailedRegister from '../../components/FailedRegister';
import { useRoutes } from '../../contexts/RoutesContext';
import apiTropa from '../../services/apiTropa';
import moment from 'moment';
import SelectDefault from '../../components/form/SelectDefault';
import Loading from '../../components/Loading/Loading';
import ContainerFilter from '../../components/form/ContainerFilter';
import InputDefault from '../../components/form/InputDefault';
import { useSocket } from '../../contexts/SocketContext';
import { useUser } from '../../contexts/UserContext';
import AttendenceOperator from '../AttendenceOperator';
import { IconClose, IconEyeOpen, IconPower } from '../../components/icons';

export default function Atendimentos() {
    const { setPageName } = useRoutes();
    // const {addSuccess, addDanger} = useMessage()
    const [loading, setLoading] = useState(true);

    const [tab, setTab] = useState<string>('andamento');

    const [dataInicio, setDataInicio] = useState<any>(moment.utc().format('YYYY-MM-DD'));
    const [dataFim, setDataFim] = useState<any>(moment.utc().format('YYYY-MM-DD'));
    const [grupoSelecionado, setGrupoSelecionado] = useState<any>('');
    const [idOperador, setIdOperador] = useState<any>('');

    const [atendimento, setAtendimento] = useState<any>({});
    const [operadores, setOperadores] = useState<any>([]);
    const [gruposAtendimento, setGruposAtendimento] = useState<any>([]);
    const [data, setData] = useState<any[]>([]);

    const [token, setToken] = useState<any>('');
    const [tokenInicial, setTokenInicial] = useState<any>('');

    const { onInit, socketStatus, socket } = useSocket();
    const { user } = useUser();

    useEffect(() => {
        onInit();
        return () => {
            socket.current.emit('disconnect');
            socket.current.emit('disconnect2');
        };
    }, []);

    useEffect(() => {
        setLoading(true);
        getData(tab);
        // setData([])

        // const interval = setInterval(() => {
        // }, 10000)

        // return () => clearInterval(interval)
    }, [tab, dataInicio, dataFim, grupoSelecionado, idOperador]);

    useEffect(() => {
        setPageName('Atendimentos');
        getGroups();
        getOperators();
    }, []);

    async function getData(type: any) {
        if (type === 'andamento') {
            apiTropa
                .get(
                    `/relatorio/atendimentos-andamento${
                        grupoSelecionado ? `?gruposAtendimento[]=${grupoSelecionado}` : `?`
                    }&id_operador=${idOperador}`
                )
                .then((response: any) => {
                    setData([...response.data.data]);
                    setLoading(false);
                });
        } else {
            apiTropa
                .get(
                    `/relatorio/atendimentos-finalizado${
                        grupoSelecionado ? `?gruposAtendimento[]=${grupoSelecionado}` : `?`
                    }&id_operador=${idOperador}&dataInicio=${dataInicio} 00:00:00&dataFim=${dataFim} 23:59:59`
                )
                .then((response: any) => {
                    setData([...response.data.data]);
                    setLoading(false);
                });
        }
    }

    async function getGroups() {
        apiTropa.get(`/grupo-atendimento`).then((response: any) => {
            setGruposAtendimento([...response.data.data]);
        });
    }

    async function getOperators() {
        apiTropa.get(`/chat-cliente-get-operadores`).then((response: any) => {
            setOperadores([...response.data.data]);
        });
    }

    async function handleSubmit(e: any) {
        e.preventDefault();
        getData(tab);
    }

    const ContainerDadosEntrada = ({ data, item }: any) => {
        let dadosEntrada: any;
        let dadosEntradaArray: any;

        try {
            dadosEntrada = JSON.parse(JSON.parse(data));
            dadosEntradaArray = valuesToArray(dadosEntrada);
        } catch (err: any) {
            dadosEntrada = {};
            dadosEntradaArray = [];
        }

        return (
            <div className="dados-entrada">
                <h3>Dados de entrada</h3>
                <ul>
                    {dadosEntradaArray.values.map((row: any, key: any) => (
                        <li>
                            {dadosEntradaArray.keys[key]}: {row}
                        </li>
                    ))}
                </ul>
                {item?.descricao && (
                    <div style={{ marginTop: 20 }}>
                        <h3>Motivo encerramento</h3>
                        <p style={{ color: '#FFFFFF' }}>{item.descricao}</p>
                    </div>
                )}
            </div>
        );
    };

    function valuesToArray(obj: any) {
        return {
            values: Object.keys(obj).map(function (key) {
                return obj[key];
            }),
            keys: Object.keys(obj).map(function (key) {
                return key;
            }),
        };
    }

    async function handleCarona(params: any) {
        setLoading(true);
        const response: any = await apiTropa.get('perfil/token/' + params.id_operador);
        setToken(response.data.data.hash);
        setTokenInicial(response.data.data.hash_init);
        setAtendimento({ ...params });
        setLoading(false);
    }

    async function handleFinish(params: any) {
        setLoading(true);
        const responseFinal: any = await apiTropa.put(`/atendimento/finalizar-admin/${params.id_atendimento}`);
        getData(tab);
        setLoading(false);
    }

    return (
        <>
            <Loading active={loading} />
            <Container>
                {atendimento.id_atendimento && socketStatus === true && (
                    <ModalCarona>
                        <div className="header">
                            <h2></h2>
                            <button onClick={() => setAtendimento({})} className="close">
                                <IconClose />
                            </button>
                        </div>
                        <div className="display">
                            <AttendenceOperator
                                token={token}
                                tokenInicial={tokenInicial}
                                idAdmin={user.id_login}
                                user={user}
                                atendimento={atendimento}
                            />
                        </div>
                    </ModalCarona>
                )}

                <form onSubmit={handleSubmit}>
                    <ContainerFilter>
                        {tab === 'finalizados' && (
                            <InputDefault
                                type="date"
                                value={dataInicio}
                                onChangeText={(value) => setDataInicio(value)}
                                placeholder="Data inicial"
                            />
                        )}

                        {tab === 'finalizados' && (
                            <InputDefault
                                type="date"
                                value={dataFim}
                                onChangeText={(value) => setDataFim(value)}
                                placeholder="Data final"
                            />
                        )}
                        <SelectDefault
                            placeholder="Operadores"
                            onChangeText={(value) => setIdOperador(value)}
                            value={idOperador}
                        >
                            {operadores
                                .filter((obj: any) => obj.apelido)
                                .map((row: any, key: number) => (
                                    <option value={row.id_login}>{row.apelido}</option>
                                ))}
                        </SelectDefault>
                        <SelectDefault
                            placeholder="Grupos de atendimento"
                            onChangeText={(value) => setGrupoSelecionado(value)}
                            value={grupoSelecionado}
                        >
                            {gruposAtendimento.map((row: any, key: number) => (
                                <option value={row.id_grupo_atendimento}>{row.grupo_atendimento}</option>
                            ))}
                        </SelectDefault>

                        <ButtonLinear className="info">Filtrar</ButtonLinear>
                    </ContainerFilter>
                </form>

                <FilterType>
                    <button className={tab === 'andamento' ? 'active' : 'inactive'} onClick={() => setTab('andamento')}>
                        Andamento
                    </button>
                    <button
                        className={tab === 'finalizados' ? 'active' : 'inactive'}
                        onClick={() => setTab('finalizados')}
                    >
                        Finalizados
                    </button>
                </FilterType>

                <ListTable>
                    {tab === 'andamento' &&
                        data.map((row, key) => (
                            <TableDefault
                                dropDownComponent={<ContainerDadosEntrada data={row.informacao_entrada} />}
                                columns={6}
                                key={key}
                            >
                                <Td flexDirection="column">
                                    <TdDescription>{row.apelido ? 'Operador' : 'Chatbot'}</TdDescription>
                                    <TdSubtitle>{row.apelido ? row.apelido : row.chatbot}</TdSubtitle>
                                </Td>
                                <Td flexDirection="column">
                                    <TdDescription>Grupo de Atendimento</TdDescription>
                                    <TdSubtitle>{row.grupo_atendimento}</TdSubtitle>
                                </Td>
                                <Td flexDirection="column">
                                    <TdDescription>Canal</TdDescription>
                                    <TdSubtitle>{row.canal}</TdSubtitle>
                                </Td>
                                <Td flexDirection="column">
                                    <TdDescription>Data de entrada</TdDescription>
                                    <TdSubtitle>
                                        {moment(row.data_entrada).utc().format('DD/MM/YYYY [às] HH[h]:mm:ss')}
                                    </TdSubtitle>
                                </Td>
                                {row.apelido && (
                                    <Td flexDirection="column">
                                        <TdSubtitle>
                                            <ButtonLinear
                                                className="success"
                                                Icon={IconEyeOpen}
                                                onClick={() => handleCarona(row)}
                                            >
                                                Carona
                                            </ButtonLinear>
                                        </TdSubtitle>
                                    </Td>
                                )}
                                <Td flexDirection="column">
                                    <TdSubtitle>
                                        <ButtonLinear
                                            className="danger"
                                            Icon={IconPower}
                                            onClick={() => handleFinish(row)}
                                        >
                                            Finalizar
                                        </ButtonLinear>
                                    </TdSubtitle>
                                </Td>
                            </TableDefault>
                        ))}

                    {tab === 'finalizados' &&
                        data.map((row, key) => (
                            <TableDefault
                                dropDownComponent={<ContainerDadosEntrada item={row} data={row.informacao_entrada} />}
                                columns={5}
                                key={key}
                            >
                                <Td flexDirection="column">
                                    <TdDescription>{row.apelido ? 'Operador' : 'Chatbot'}</TdDescription>
                                    <TdSubtitle>{row.apelido ? row.apelido : row.chatbot}</TdSubtitle>
                                </Td>
                                <Td flexDirection="column">
                                    <TdDescription>Grupo de Atendimento</TdDescription>
                                    <TdSubtitle>{row.grupo_atendimento}</TdSubtitle>
                                </Td>
                                <Td flexDirection="column">
                                    <TdDescription>Canal</TdDescription>
                                    <TdSubtitle>{row.canal}</TdSubtitle>
                                </Td>
                                <Td flexDirection="column">
                                    <TdDescription>Finalizado por</TdDescription>
                                    <TdSubtitle>{row.finalizado_por}</TdSubtitle>
                                </Td>
                                <Td flexDirection="column">
                                    <TdDescription>Data de entrada</TdDescription>
                                    <TdSubtitle>
                                        {moment(row.data_entrada).utc().format('DD/MM/YYYY HH:mm:ss')}
                                    </TdSubtitle>
                                </Td>
                            </TableDefault>
                        ))}

                    {loading === true && (
                        <Skeleton
                            count={5}
                            className="skeletor"
                            height="85px"
                            borderRadius="16px"
                            baseColor="#262655"
                            highlightColor="#3e3b77"
                        />
                    )}
                </ListTable>

                {loading === false && data.length === 0 && <FailedRegister />}
            </Container>
        </>
    );
}
