import React, { useEffect } from 'react';
import { useSocket } from '../../contexts/SocketContext';
import { useUser } from '../../contexts/UserContext';
import AttendenceOperator from '../AttendenceOperator';

import { Container } from './styles';

const Carona: React.FC = () => {
    const { onInit, socketStatus } = useSocket();
    const { user } = useUser();

    useEffect(() => {
        onInit();

        console.log('socket.status.state.carona', socketStatus);
    }, [socketStatus]);

    return (
        <Container>
            {socketStatus === true && (
                <AttendenceOperator
                    token={
                        'eyJ1c2VyIjp7ImVtYWlsIjoiVUdkT2FrcFZUMGhVWWxrMWJYUm5XRUphTjFaemNGTndUVFZOZGpZeVdXaGpNMEp5Y0ZNM05FTTFVVDAiLCJjZWx1bGFyIjoiY1VWUldtNDBaVGh2SzJKR1NUQjFOV3BJVWk4dlFUMDkiLCJpZEMiOiI2NDQiLCJpZFUiOiIyNDQ3Iiwic3RhdHVzIjoiUmpGcFpGcDRNM0JPV2tOTlNYTjVOVWx5ZVdWQ2R6MDkiLCJleHRyYSI6IlpESkRhM2R5ZVVRMFJ6SkROWG8yYms4NFlubFhZMEp6Tmt4WmQwTXhRbGRrVkRsc2NEUlJNMUp4UlVSSlpUVjFTall6VjFaWk5VRXhkM1ZSZDNSRlFnIn0sInJvbGVzIjp7ImFwaURhZG9zIjoxLCJhcGlVc3VhcmlvIjoxLCJhcGlUaW1lbGluZSI6MSwiYXBpQmxhY2tsaXN0IjoxLCJhcGlDZW50cm9DdXN0byI6MSwiYXBpTXNnUHJlIjoxLCJhcGlFbnZBdnVsc28iOjEsImFwaUVudkxpc3RhIjoxLCJhcGlFbnZMb3RlIjoxLCJhcGlFbnZIdHRwIjoxLCJhcGlTaG9ydE9uZSI6MSwiYXBpU2hvcnRUd28iOjEsImFwaVNob3J0Rmxhc2giOjEsImFwaVNob3J0Q29uYyI6MSwiYXBpTG9uZyI6MSwiYXBpUmVsRW52IjoxLCJhcGlSZWxSZXNwIjoxLCJhcGlSZWxBbmFsaXRpY28iOjEsIndsdCI6MCwiYXBpUG9ydGFiaWxpZGFkZSI6MSwiYXBpU2Vuc2l2ZURhdGEiOjEsImFwaVdhIjowLCJhcGlUZW1wbGF0ZSI6MSwiYXBpU2hvcnRlbmVyIjoxLCJhcGlTaG9ydGVuZXJQbGFuIjowLCJhcGlWb3oiOjF9LCJpYXQiOjE2NTE1OTY0NTN9'
                    }
                    tokenInicial={
                        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7ImVtYWlsIjoiVUdkT2FrcFZUMGhVWWxrMWJYUm5XRUphTjFaemNGTndUVFZOZGpZeVdXaGpNMEp5Y0ZNM05FTTFVVDAiLCJjZWx1bGFyIjoiY1VWUldtNDBaVGh2SzJKR1NUQjFOV3BJVWk4dlFUMDkiLCJpZEMiOiJjVmQ2VlRKTWRXZ3dXbFp5ZUdFdmMyNUVVbGxOZHowOSIsImlkVSI6IldtWndVbUUyWXl0bWRYQjRMM0ZoWW1relRGQXJVVDA5Iiwic3RhdHVzIjoiUmpGcFpGcDRNM0JPV2tOTlNYTjVOVWx5ZVdWQ2R6MDkiLCJleHRyYSI6IlpESkRhM2R5ZVVRMFJ6SkROWG8yYms4NFlubFhZMEp6Tmt4WmQwTXhRbGRrVkRsc2NEUlJNMUp4UlVSSlpUVjFTall6VjFaWk5VRXhkM1ZSZDNSRlFnIn0sInJvbGVzIjp7ImFwaURhZG9zIjoxLCJhcGlVc3VhcmlvIjoxLCJhcGlUaW1lbGluZSI6MSwiYXBpQmxhY2tsaXN0IjoxLCJhcGlDZW50cm9DdXN0byI6MSwiYXBpTXNnUHJlIjoxLCJhcGlFbnZBdnVsc28iOjEsImFwaUVudkxpc3RhIjoxLCJhcGlFbnZMb3RlIjoxLCJhcGlFbnZIdHRwIjoxLCJhcGlTaG9ydE9uZSI6MSwiYXBpU2hvcnRUd28iOjEsImFwaVNob3J0Rmxhc2giOjEsImFwaVNob3J0Q29uYyI6MSwiYXBpTG9uZyI6MSwiYXBpUmVsRW52IjoxLCJhcGlSZWxSZXNwIjoxLCJhcGlSZWxBbmFsaXRpY28iOjEsIndsdCI6MCwiYXBpUG9ydGFiaWxpZGFkZSI6MSwiYXBpU2Vuc2l2ZURhdGEiOjEsImFwaVdhIjowLCJhcGlUZW1wbGF0ZSI6MSwiYXBpU2hvcnRlbmVyIjoxLCJhcGlTaG9ydGVuZXJQbGFuIjowLCJhcGlWb3oiOjF9LCJpYXQiOjE2NTE1OTY0NTN9.gCduGqNUGKOAMi2VA1mFucctFC37hf_6rtHyCJTK3Wg'
                    }
                    idAdmin={'2072'}
                    user={user}
                />
            )}
        </Container>
    );
};

export default Carona;
