import styled from "styled-components"

export const Container = styled.div`
    padding-bottom: 30px;
    .graph-area-chart {
        height: 306px;
        width: 100%;
        display: flex;
        
    }
    .card-container {
        tspan {
            fill: #FFFFFF;
        }
    }

    .pause {
        .table-simple {
            width: 100%;
        }
    }

    textarea{
        color : #fff !important;
    }
    span.label{
        color: #5A646E !important;
    }
    .column > div > div > div {
        height: 1000px !important;
    }
`