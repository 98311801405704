import { RoutesProvider } from './contexts/RoutesContext';
import { GlobalStyles } from './styles/Global';
import Routes from './routes';
import { ThemeProvider } from 'styled-components';
import { useUIThemeContext } from './contexts/UIThemeContext';
import { MessageProvider } from './contexts/MessagesContext';
import { UserProvider } from './contexts/UserContext';
import { SocketProvider } from './contexts/SocketContext';
import { Notifications } from 'react-push-notification';

const App = () => {
    const { theme } = useUIThemeContext();
    return (
        <SocketProvider>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <Notifications />
                <MessageProvider>
                    <UserProvider>
                        <RoutesProvider>
                            <Routes />
                        </RoutesProvider>
                    </UserProvider>
                </MessageProvider>
            </ThemeProvider>
        </SocketProvider>
    );
};

export default App;
