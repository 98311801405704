import styled, { css } from 'styled-components';

interface PopUpProps {
    popUpAttendence?: boolean;
    status?: string;
}

export const Container = styled.div<PopUpProps>`
    display: flex;
    flex-direction: column;
    z-index: 9;

    width: 400px;
    padding: 24px;
    border-radius: 8px;

    background: ${(props) => props.theme.colors.spaceCadedLight};
    box-shadow: -13px 16px 15px #00000020;
    
    position: absolute;
    bottom: 58px;

    visibility: hidden;
    opacity: 0;

    transform: translate(-95%, 100%) scale(0);
    transform-origin: 100% 0%;
    transition: all 0.35s;

    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }

    ${(props) =>
        props.popUpAttendence &&
        css`
            visibility: visible;
            opacity: 1;
            transform: translate(0) scale(1);
        `}

    .closePopUp {
        position: absolute;
        right: 24px;
        top: 24px;

        border: none;
        background: transparent;
    }
`;

export const SectionHoursCard = styled.div`
    display: flex;
    align-items: end;
    justify-content: space-between;

    margin-top: 24px;

    padding-bottom: 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);

    .sectionRow {
        display: flex;
        flex-direction: column;

        h1 {
            font-size: 24px;
            font-weight: bold;
            color: ${props => props.theme.colors.white};
        }
        span {
            font-size: 18px;
            color: #8A99A8;
            margin-top: 16px;
        }
    }
`;