import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const PersonImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFF;
    border-radius:100px;
    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid #FFFFFF;
    }
    @media (max-width: 425px) {
        width: 40px;
        height: 40px;
    }
`;

export const PersonInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 16px;

    h2 {
        font-size: 12px;
        color: ${props => props.theme.colors.lavanderGray};
    }

    span {
        font-size: 14px;
        font-weight: bold;
        color: ${props => props.theme.colors.white};
        margin-top: 8px;
    }

    /* @media (max-width: 425px) {
        span {
            width: 60px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    } */
`;
