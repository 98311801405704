import { useEffect, useState } from 'react';
import ButtonLinear from '../../../components/form/ButtonLinear';
import { IconClose, IconPencil } from '../../../components/icons';
import Field, { Label } from '../../../components/layout/containers/Field';
import FieldGroup from '../../../components/layout/containers/FieldGroup';
import { Td, TdButtons, TdDescription, TdSubtitle } from '../../../components/layout/containers/Td';
import BackPage from '../../../components/layout/UIElements/BackPage';
import ModalDefault from '../../../components/ModalDefault';
import TableDefault from '../../../components/TableDefault';
import { useMessage } from '../../../contexts/MessagesContext';
import { useRoutes } from '../../../contexts/RoutesContext';
import apiTropa from '../../../services/apiTropa';
import Skeleton from 'react-loading-skeleton';
import { Container, Header, Content } from './styles';
import Loading from '../../../components/Loading/Loading';
import InputDefault from '../../../components/form/InputDefault';
import SelectDefault from '../../../components/form/SelectDefault';

export default function ConfigurarPausas(props: any) {
    const id_grupo_atendimento = props.match.params.id
    const { setPageName } = useRoutes()
    const { addConfirm } = useMessage()
    const [modal, setModal] = useState(false)
    const [loading, setLoading] = useState(false)

    const [item, setItem] = useState<any>({})
    const [pausas, setPausas] = useState<any[]>([])
    const [pausa, setPausa] = useState<any>({
        pause: '',
        categoria_pausa: ''
    })
    
    useEffect(() => {
        setLoading(true)
        setPageName('Configurar pausas')
        getData()
    }, [])

    function getData(){
        apiTropa.get(`/grupo-atendimento/${id_grupo_atendimento}`).then((response:any) => {
            let result = response.data.data
            if( result.configurarPausas ) setPausas(result.configurarPausas)
            setLoading(false)
        })
    }

    async function adicionarPausa(){
        let op = ''
        if ( !item.key && item.key !== 0 ) {
            pausas.push(pausa)
            op = 'adicionar'
        } else {
            pausas[item.key] = pausa
            setPausas([...pausas])
            op = 'editar'
        }
        setItem({})
        setPausa({
            pause: '',
            categoria_pausa: ''
        })
        setModal(false)
        save(op)
    }

    async function removerPausa(key:any){
        pausas.splice(key, 1)
        let op = ''
        op = 'excluir';
        save(op)
    }

    async function save(operacao:string){
        setLoading(true)
        await apiTropa.put(`/grupo-atendimento/${id_grupo_atendimento}/pausas?edit=`+operacao, {
            configurarPausas: JSON.stringify(pausas)
        }).then((response:any) => {
            setLoading(false)
        })
    }

    async function handleEdit(item:any, id: number) {
        setItem({
            key: id,
            pause: item.pause,
            categoria_pausa: item.categoria_pausa
        })
        setPausa(item)
        setModal(true)
    }

    async function handleOnPause(name: any, value: any) {
        setPausa({...pausa, [name]: value})
    }

    return (
    <>
        <Loading active={loading} />

        <Container>

            <Header>
                <BackPage />

                <ButtonLinear className="info" onClick={() => setModal(true)}>
                    Adicionar pausa
                </ButtonLinear>
            </Header>

            <Content>
            { loading === true ? 
                <Skeleton
                    count={1}
                    className="skeletor"
                    height="80px"
                    borderRadius="16px"
                    baseColor="#262655"
                    highlightColor="#3e3b77"
                />  
            :
                <>
                    {pausas.map((pausa, key) => (
                    <TableDefault key={key} columns={2}>
                        <Td flexDirection="column">
                            <TdDescription>Pausa</TdDescription>
                            <TdSubtitle>{pausa.pause}</TdSubtitle>
                            <TdSubtitle>{pausa.categoria_pausa}</TdSubtitle>
                        </Td>
                        <Td flexDirection="column" style={{ alignItems: 'flex-end' }}>
                            <TdButtons>
                            <ButtonLinear
                                    Icon={IconPencil}
                                    onClick={() => handleEdit(pausa, key)}
                                />
                                <ButtonLinear
                                    Icon={IconClose}
                                    onClick={() =>
                                        addConfirm({
                                            title: 'Atenção',
                                            description: 'Deseja remover essa pausa?',
                                            textButtonConfirm: 'Remover',
                                            textButtonCancel: 'Cancelar',
                                            onConfirm: () => removerPausa(key),
                                            onCancel: () => {},
                                        })
                                    }
                                />
                            </TdButtons>
                        </Td>
                    </TableDefault>
                    ))}
                </>
            }
            </Content>

            <ModalDefault id="overlayModal" onClose={() => setModal(!modal)} openModal={modal}>

                <Field marginTop="15px">
                    <Label>Descreva a Pausa</Label>

                    <InputDefault
                        value={pausa.pause}
                        placeholder="Digite aqui..."
                        onChangeText={(value) => handleOnPause('pause', value)}
                    />

                    <Field gap="5px">
                        <Label>Tipo de pausa</Label>
                        <SelectDefault value={pausa.categoria_pausa} onChangeText={(value) => handleOnPause('categoria_pausa', value)}>
                            <option value="0">
                                Selecione
                            </option>
                            <option value="produtiva">
                                Produtiva
                            </option>
                            <option value="improdutiva">
                                Improdutiva
                            </option>
                        </SelectDefault>
                    </Field>
                    
                </Field>

                <FieldGroup style={{ marginTop: '32px' }}>
                    <ButtonLinear onClick={() => {setModal(false); setItem({})}}>
                        Cancelar
                    </ButtonLinear>
                    <ButtonLinear className="success" onClick={adicionarPausa}>
                        Salvar
                    </ButtonLinear>
                </FieldGroup>
            </ModalDefault>

        </Container>
    </>
    )
}
