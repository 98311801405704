import styled from 'styled-components';

interface ChatProps {
    backgroundColor?: string;
    selectedFile?: boolean;
    inatividade?: any
}

interface ChatPageProps {
    noHeader?: boolean
    inatividade?: any
}

export const Container = styled.div<ChatPageProps>`
    display: flex;
    flex-direction: column;
    background: #fff;
    width: 100%;
    height: 100vh;
    padding: 0px 24px 24px 24px;
    position: relative;
    .inatividade-reconectar {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        background: rgba(255,255,255,1);
        align-items: center;
        justify-content: center;
        z-index: 99999;
        animation: zoomIn .2s;
        img {
            width: 30vw;
        }
        p {
            font-size: 15px;
            color: #666;
            margin-bottom: 20px;
        }
    }
    .alert-message {
        display: flex;
        border-top: 1px solid rgba(0,0,0,0.1);
        padding: 25px 0px 0px 0px;
        font-size: 12px;
        align-items: center;
        color: #666;
        position: relative;
        /* z-index: 2; */
        animation: fadeInDown .2s;
        img {
            width: 40px;
            margin-right: 10px;
            font-size: 20px;
        }
        svg {
            width: 25px;
            font-size: 40px;
            margin-right: 20px;
        }
    }
    .alert-message-off {
        display: flex;
        flex-direction: column;
        border: 1px solid rgba(0,0,0,0.1);
        border-radius: 5px;
        padding: 20px;
        font-size: 12px;
        align-items: center;
        color: #666;
        position: relative;
        z-index: 9;
        animation: fadeInDown .2s;
        > div {
            display: flex;
            align-items: center;
            margin-right: 10px;
        }
        img {
            width: 40px;
            margin-right: 10px;
            font-size: 20px;
        }
        svg {
            width: 25px;
            font-size: 40px;
            margin-right: 20px;
        }
        button {
            margin-left: 5px;
            background:none;
            border:none;
            cursor: pointer;
            text-decoration: underline;
            color: #666;
        }
    }

    .queue {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 0px;
        background: rgba(0,0,0,.1);
        border-radius: 5px;
        font-size: 12px;
        animation: zoomIn .5s;
        color: #333;
        svg {
            font-size: 20px;
            margin-bottom: 10px;
        }
    }
`;

export const HeaderChat = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #eaeef3;
    min-height: 100px;
    .final {
        position: absolute;
        right: 20px;
    }

    @media (max-width: 425px) {
        .final {
            right: 10px;
        }
    }
`; 

export const IconChat = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-color: rgba(0,0,0,.1);
    border-radius: 84px;
    width: 72px;
    height: 72px;

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 72px;
        height: 72px;
        border-radius: 50%;

        background: #cecece;
    }
`;

export const MainChat = styled.div<ChatProps>`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 0px 0;
    position: relative;
    height: ${(props) => props.inatividade ? 'calc(100vh - 100px);' : 'calc(100vh - 95px - 100px);'};
    > div {
        > div {
            overflow-x: hidden;
            /* scroll-behavior: smooth; */
            display: flex;
            flex-direction: column;
            gap: 24px;
            padding: 20px 0px;
        }
    }

    .buttonDown {
        position: fixed;
        right: 38px;
        bottom: 143px;

        opacity: 0;
        display: grid;
        place-items: center;
        width: 48px;
        height: 48px;
        border-radius: 10px;
        background: ${(props) =>
            props.backgroundColor ? props.backgroundColor : '#34D5FA'};
        box-shadow: 0px 10px 15px #00000008;
        border: none;
        z-index: 10;
        visibility: hidden;

        svg {
            path {
                transform: rotate(-90deg);
                transform-origin: 50% 50%;
                fill: #fff;
            }
        }
        transition: opacity 0.3s;

        &.buttonDowActive {
            opacity: 1;
            visibility: visible;
        }
    }
`;

export const FooterChat = styled.div<ChatProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    padding: 0px 15px;
    background: #fff;

    border-top: 1px solid rgba(255, 255, 255, 0.25);
    @media(max-width:450px) {
        position: fixed;
        bottom: 0;
        left: 0;
        input {
            font-size: 16px !important;
        }
    }

    .buttonChat {
        display: grid;
        place-items: center;
        flex-shrink: 0;

        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: none;
        background: ${(props) =>
            props.backgroundColor ? props.backgroundColor : '#34D5FA'};
        transition: filter 0.3s;

        &:hover {
            transition: 0.3s;
            filter: brightness(0.9);
        }
    }

    .formMessagePre {
        width: 100%;
    }

    .containerChatInput {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        position: relative;
        width: 100%;
        height: 48px;
        padding: 4px;

        background: #eaeef3;
        border-radius: 10px;

        .buttonEmojiChat {
            display: grid;
            place-items: center;
            flex-shrink: 0;

            width: 48px;
            height: 48px;
            border-radius: 50%;
            border: none;
            background: transparent;
            position: relative;

            svg {
                path {
                    fill: ${(props) =>
                        props.backgroundColor
                            ? props.backgroundColor
                            : '#34D5FA'};
                }
            }
        }

        #inputChat {
            display: flex;
            align-items: center;

            width: 100%;
            padding: 8px 0px;
            border: none;
            outline: none;
            background: transparent;
            resize: none;
            overflow-y: scroll;

            overflow-y: scroll;
            ::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .buttonAudioChat {
        display: grid;
        place-items: center;
        flex-shrink: 0;

        width: 48px;
        height: 48px;
        border-radius: 10px;
        border: none;
        background: ${(props) =>
            props.backgroundColor ? props.backgroundColor : '#34D5FA'};
        transition: filter 0.3s;

        svg {
            path {
                fill: #fff;
            }
        }
        &:hover {
            transition: 0.3s;
            filter: brightness(0.9);
        }
    }
`;

export const SectionFiles = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PreviewFile = styled.div<ChatProps>`
    visibility: ${(props) => (props.selectedFile ? 'visibility' : 'hidden')};
    opacity: ${(props) => (props.selectedFile ? '1' : '0')};

    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background: rgba(0,0,0,.5);
    justify-content: space-between;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    position: fixed;
`;

export const HeaderMessage = styled.div`
    display: flex;
    align-items: center;
    background: ${(props) => props.theme.colors.spaceCaded};
    padding: 20px 20px;

    button {
        display: grid;
        place-items: center;

        padding: 14px;

        border: none;
        background: transparent;
    }

    span {
        font-size: 18px;
        font-weight: bold;
        color: ${(props) => props.theme.colors.white};
        margin-left: 12px;
    }
`;

export const MainPreview = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.colors.spaceCaded};
    height: 100%;
    button {
        margin-top: 30px;
    }

    .imagePreview {
        padding: 6px;
        border-radius: 4px;
        background: #CEEDBB;
        max-height: 60vh;
        img {
            max-width: 100%;
            border-radius: 4px;
            max-height: 100%;
        }
    }

    .filePreview {
        display: grid;
        place-items: center;
        padding: 60px 120px;
        border-radius: 4px;
        background: #CEEDBB;
    }

    .descriptionFilePreview {
        margin-top: 40px;
        color: #FFF;
    }
`;
