import React, { useEffect, useState } from 'react'
import { useMessage } from '../../../contexts/MessagesContext'
import { ContainerQuestion, ContainerQuestionItem, TitleQuestion } from '../../../pages/PageChat/styles'
import apiTropa from '../../../services/apiTropa'
import ButtonConfig from '../../form/ButtonConfig'
import InputRadioDefault from '../../form/InputRadioDefault'
import InputRating from '../../form/InputRating'
import InputSwitchDefault from '../../form/InputSwitchDefault'
import InputTextarea from '../../form/InputTextarea'
import Loading from '../../Loading/Loading'
import { Container, HeaderChat, LogoChat, MainChat } from './styles'

export default function CardPesquisaSatisfacao({
    atendimento = {},
    formularioEntrada = {},
    pesquisaSatisfacao = {},
    configuracaoEstilo = {},
    prod = false
}: any) {

    const [loading, setLoading] = useState(false)
    const { addDanger } = useMessage()
    // const chatAttendence: any = window.sessionStorage.getItem('chatAtendence')
    // const chatPerfil: any = window.sessionStorage.getItem('chatPerfil')
    const [questions, setQuestions] = useState<any[]>([])
    const [DTO, setDTO] = useState<any>({})

    useEffect(() => {
        if( pesquisaSatisfacao.configuracao ){
            let questions = JSON.parse(pesquisaSatisfacao.configuracao)
            setQuestions([...questions])
        }
    }, [pesquisaSatisfacao])

    function onChangeDTOCheckbox(questionId:any, itemId:any, value:any){
        if( !DTO[questionId] ) DTO[questionId] = []

        let index = DTO[questionId].findIndex((row:any) => row.id === itemId)
        if( index !== -1 ){
            DTO[questionId].splice(index, 1)
        } else {
            DTO[questionId].unshift({
                id: itemId,
                value: value
            })
        }

        setDTO({...DTO})
    }

    function onChangeDTORadio(questionId:any, itemId:any, value:any){
        
        if( !DTO[questionId] ) DTO[questionId] = []

        if( DTO[questionId].length > 0 ){
            DTO[questionId].splice(0, 1)
        } 
        
        DTO[questionId].unshift({
            id: itemId,
            value: value
        })

        setDTO({...DTO})
    }

    function onChangeDTOTextarea(questionId:any, itemId:any, value:any){
        if( !DTO[questionId] ) DTO[questionId] = []

        let index = DTO[questionId].findIndex((row:any) => row.id === itemId)
        if( index !== -1 ){
            DTO[questionId][index].value = value
        } else {
            DTO[questionId].push({
                id: itemId,
                value: value
            })
        }
        setDTO({...DTO})
    }

    function onChangeDTORating(questionId:any, value:any){
        DTO[questionId] = value
        setDTO({...DTO})
    }

    function getValue(questionId:any, itemId:any){
        if( !DTO[questionId] ) return ""
        let find = DTO[questionId].find((row:any) => row.id === itemId)
        return ( find === undefined ) ? "" : find.value
    }

    async function handleEnviarPesquisa(){
        try{
            const payload = {
                id_pesquisa_satisfacao: pesquisaSatisfacao.id_pesquisa_satisfacao,
                id_atendimento: atendimento.id_atendimento,
                resposta: {}
            } as any

            for ( var questionId in DTO ) {
                if( Array.isArray(DTO[questionId]) ){
                    payload.resposta[questionId] = []
                    DTO[questionId].map((item:any) => {
                        payload.resposta[questionId].push(item.value)
                    })

                } else {
                    payload.resposta[questionId] = DTO[questionId]
                }
            }
            
            setLoading(true)
            await apiTropa.post(`/resposta-pesquisa`, payload)
            setLoading(false)
            window.sessionStorage.removeItem('chatAtendence')
            window.sessionStorage.removeItem('chatPerfil')
            window.location.reload()

        } catch(err:any){
            if(err.response){
                addDanger("Atenção", String(err.response.data.message))
            }else{
                addDanger("Atenção", String(err))
            }
            setLoading(false)
        }
    }

    return (
        <Container>

            <Loading active={loading} />

            <HeaderChat>
                <LogoChat style={{backgroundImage: `url(${configuracaoEstilo?.styleChat?.file})`}} />
                <h1 style={{color: pesquisaSatisfacao.cor_titulo}}>{pesquisaSatisfacao?.pesquisa_satisfacao}</h1>
                <p  style={{color: pesquisaSatisfacao.cor_descricao}}>{pesquisaSatisfacao?.descricao}</p>
            </HeaderChat>

            <MainChat onSubmit={() => {}}>

                {questions.map((question:any, key:any) => (
                <>
                    <TitleQuestion>
                        <h2>{question.title}</h2>
                    </TitleQuestion>

                    {(question.type === 'checkbox' || question.type === 'radio') && (
                        <ContainerQuestion>
                            {question.items.map((item:any, itemKey:any) => (
                                <ContainerQuestionItem key={itemKey}>
                                    {item.type === 'checkbox' && (
                                        <InputSwitchDefault
                                            onChange={(value) => onChangeDTOCheckbox(question.id, item.id, item.value)}
                                            options={[
                                                {
                                                    label: item.value,
                                                    value: item.value,
                                                },
                                            ]}
                                            value={[getValue(question.id, item.id)]}
                                        />
                                    )}
        
                                    {item.type === 'radio' && (
                                        <InputRadioDefault
                                            onChange={(value) => onChangeDTORadio(question.id, item.id, item.value)}
                                            options={[
                                                {
                                                    label: item.value,
                                                    value: item.value,
                                                },
                                            ]}
                                            value={getValue(question.id, item.id)}
                                        />
                                    )}
        
                                    {item.type === 'textarea' && (
                                        <InputTextarea
                                            onChangeText={(value) => onChangeDTOTextarea(question.id, item.id, value)}
                                            value={prod === false ? item.value : ''}
                                            placeholder={item.value}
                                        />
                                    )}
                                </ContainerQuestionItem>
                            ))}
                        </ContainerQuestion>
                    )}
        
                    {question.type === 'rating' && (
                        <ContainerQuestion>
                            <InputRating 
                                min={question.min}
                                max={question.max}
                                onChangeText={(value) => onChangeDTORating(question.id, value)}
                                value={DTO[question.id]}
                            />
                        </ContainerQuestion>
                    )}
                </>
                ))}

                <ButtonConfig 
                    onClick={() => handleEnviarPesquisa()}
                    styles={{
                        backgroundColor: configuracaoEstilo.styleButtonMain.backgroundColor,
                        color: configuracaoEstilo.styleButtonMain.color,
                        borderRadius: configuracaoEstilo.styleButtonMain.borderRadius,
                        border: configuracaoEstilo.styleButtonMain.border,
                        borderColor: configuracaoEstilo.styleButtonMain.borderColor,
                        borderStyle: configuracaoEstilo.styleButtonMain.borderStyle,
                    }}
                >
                    {configuracaoEstilo.style.textSendQuestion ? configuracaoEstilo.style.textSendQuestion : 'Enviar pesquisa'}
                </ButtonConfig>

                {/* <ButtonLinear 
                    onClick={() => window.location.reload()}
                    className="danger"
                >
                    Não Responder Pesquisa
                </ButtonLinear> */}

                <ButtonConfig 
                    onClick={() => window.location.reload()}
                    styles={{
                        backgroundColor: configuracaoEstilo.styleButtonMainDark.backgroundColor,
                        color: configuracaoEstilo.styleButtonMainDark.color,
                        borderRadius: configuracaoEstilo.styleButtonMainDark.borderRadius,
                        border: configuracaoEstilo.styleButtonMainDark.border,
                        borderColor: configuracaoEstilo.styleButtonMainDark.borderColor,
                        borderStyle: configuracaoEstilo.styleButtonMainDark.borderStyle,

                    }}
                >
                    {configuracaoEstilo.style.textNotSendQuestion ? configuracaoEstilo.style.textNotSendQuestion : 'Nao responder pesquisa'}
                </ButtonConfig>
            </MainChat>
        </Container>
    );
}
