import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 60px;

    padding: 0 16px 0 16px;
    margin:  16px 0 16px 0;

    border-bottom: 1px solid ${({theme}) => theme.title === 'light' ? '#B6B6C5' : 'rgba(255, 255, 255, 0.25)'} ;
`;

export const ButtonSignInOut = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-bottom: 16px;
`;

export const Profile = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 16px;
    gap: 16px;

    border: none;
    background: transparent;

    .user {
        display: grid;
        place-items: center;
        width: 48px;
        height: 48px;

        border-radius: 50%;
        background: ${props => props.theme.colors.spaceCadedLight};
    }

    .image {
        width: 48px;
        height: 48px;
        border-radius: 100px;
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.4);
    }
`;

export const Perfil = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    /* margin-left: 16px; */

    h2 {
        font-size: 16px;
        font-weight: normal;
        line-height: 150%;
        color: ${(props) => props.theme.colors.white};
    }

    span {
        display: flex;
        align-items: center;
        position: relative;
        margin-left: 14px;
        font-size: 12px;
        color: ${(props) => props.theme.colors.lavanderGray};
        &::before {
            content: '';
            position: absolute;
            left: -14px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            display: flex;
        }
        &.Pausa, &.Aguardando {
            &::before {
                background: ${(props) => props.theme.colors.lavanderGray};
            }
        }
        &.Online {
            &::before {
                background: ${(props) => props.theme.colors.oceanGreen};
            }
        }
        &.Offline {
            &::before {
                background: ${(props) => props.theme.colors.redPigment};
            }
        }
    }

    .active {
        display: flex;
        align-items: center;
        position: relative;
        margin-left: 14px;
        font-size: 14px;
        color: ${(props) => props.theme.colors.lavanderGray};

        
    }
`;

export const ContainerHourOff = styled.div`
    
`

export const SignInOut = styled.button`
    display: grid;
    place-items: center;
    width: 32px;
    height: 32px;

    border: none;
    background: transparent;

    margin-left: 24px;
`;

export const Main = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 8px 16px 16px 16px;
`;

export const Title = styled.h2`
    font-size: 16px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.white};
`;

export const Panel = styled.div`
    display: grid;
    grid-template-columns: 317px auto 320px;
    gap: 16px;
    width: 100%;
    height: 100%;
`;

export const PanelLeft = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 8px;
    .totalmessageinternal {
        animation: zoomIn .5s;
        background: red;
        position: absolute;
        top: -5px;
        right: -5px;
        border-radius: 100px;
        font-size: 11px;
        padding: 5px 5px;
    }
`;

export const BgLeft = styled.div`
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.colors.spaceCadedDark};
    border-radius: 8px;
    padding: 16px;
    height: calc(100vh - 235px);
`;

export const HeaderMessage = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    button {
        width: 100%;
    }
`;

export const SectionMessages = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FooterMessage = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;

    padding: 24px;
    background: ${(props) => props.theme.colors.spaceCadedDark};
    border-radius: 8px;
    height: 99px;

    .popUpServer {
        position: relative;
    }
    
    .popUpGroup {
        position: relative;
    }
    
    .popUpHours {
        position: relative;
    }
`;

export const PanelCenter = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    .transferMessage {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 15px;
        background: ${(props) => props.theme.colors.wine};;
        margin-bottom: 5px;
        border-radius: 5px;
        font-size: 13px;
        color: #FFF;
        svg {
            font-size: 30px;
            margin-right: 20px;
            margin-left: 10px;
            animation: fadeInLeft .5s linear;
        }
        p {
            margin-bottom: 10px;
            font-size: 13px;
        }
        span {
            font-size: 11px;
        }
    }
`;

export const PanelRight = styled.div`
    display: flex;
    flex-direction: column;

    background: ${(props) => props.theme.colors.spaceCadedDark};
    border-radius: 8px;
    padding: 16px;
    height: calc(100vh - 128px);

`;

export const PanelCenterHeader = styled.div`
    position: relative;
    color: ${(props) => props.theme.colors.white};
    padding: 10px 0 0 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: ${(props) => props.theme.colors.spaceCadedDark};
    .buttons{
        position: absolute;
        top: 16px;
        right: 16px;
        button{
            background: transparent;
            border: none;
        }
    }
    .origem {
        color: #FFF;
        text-decoration: none;
        margin-right: 10px;
    }
    .title{
        margin: 0 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        .sectionHistory {
            height: 100%;
            display: flex;
            height: 29px;
            align-items: center;
            margin-top: 15px;
            margin-bottom: -20px;
            .children {
                svg {
                    margin-right: 5px;
                }
            }
            a {
                text-decoration: underline;
                cursor: pointer;
                font-size: 12px;
                display: flex;
                align-items: center;
                svg {
                    margin-right: 5px;
                }
            }
        }
        .titleLeft {
            display: flex;
            gap: 10px;
            h1{
                font-size: 140%;
            }
            .status {
                display: flex;
                align-items: center;
                font-size: 80%;
                color: ${(props) => props.theme.colors.lavanderGray};
                &:before{
                    content: "•";
                    font-size: 25px;
                    color: #38FFE7;
                    margin-right: 3px;
                }
                &.offline{
                    &:before{
                        color: red;
                    }
                }
            }
        }
    }
    .description{
        margin: 0 16px;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 15px 0;
        white-space: nowrap;
        h2{
            font-size: 100%;
        }
        span{
            font-size: 80%;
            color: ${(props) => props.theme.colors.lavanderGray};
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    .actions{
        display: flex;
        justify-content: space-between;
        width: 100%;
        button{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 33%;
            gap: 10px;
            padding: 8px 0;
            background: #1B1B1B;
            border: 0;
            color: #fff;
            svg{
                path{
                    fill: rgb(182, 182, 197);
                }
            }
            &.danger{
                background: #FF868B;
                svg{
                    path{
                        fill: #fff;
                    }
                }
            }
        }
    }
`

export const PanelCenterNoContent = styled.div`
    position: relative;
    color: ${(props) => props.theme.colors.white};
    padding: 16px;
    border-radius: 8px;
`

export const ContentMessage = styled.div`
    display: block;
    height: 300px;
`

export const ButtonMessage = styled.div`
    width: 100%;
    padding: 16px;
    margin-bottom: 5px;
    border-radius: 8px;
    text-align: left;
    border: 0;
    cursor: pointer;
    word-wrap: break-word;
    background: ${(props) => props.theme.colors.blueCrayola};
    color: ${(props) => props.theme.colors.white};
    &:hover{
        background: ${(props) => props.theme.colors.oceanGreen};
    }
    resize: none;
`

export const ContentFunil = styled.ul`
    list-style: none;
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    height: 32px;
    position: absolute;
    z-index: 2;
    margin-top: 143px;
    flex-wrap: wrap;
    gap: 5px 0;
    li{
        display: inline-block;
        background-color: #8B8B9E;
        padding: 0 16px 0 32px;
        color: #fff;
        font-size: 12px;
        cursor: pointer;
        height: 32px;
        margin: 0 7px 0 0;
        position: relative;
        vertical-align: top;
        z-index: -1;
        display: flex;
        align-items: center;
        white-space: nowrap;
        &:nth-child(1) {
            &:before {
                display: none;
            }
        }
    }
    li.active{
        background-color: #28b2a2 ;
        cursor: pointer;
    }
    li.active:after{
        border-left: 19px solid #28b2a2 ;
    }
    li:after{
        content: "";
        display: inline-block;
        position: absolute;
        left:100%;
        width: 0; 
        height: 0; 
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
        border-left: 19px solid #8B8B9E;
        z-index: 0;
    }

    li:before{
        content: "";
        display: inline-block;
        position: absolute;
        left:0%;
        width: 0; 
        height: 0; 
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
        border-left: 19px solid ${({theme}) => theme.title === 'light' ? '#fff' : '#28384B'} ;
        z-index: 0;
    }
`;

export const SectionDashOperator = styled.div`
    width: 100%;
    margin-top: 20px;
`;

export const ContentDahsFilter = styled.div`
    display: grid;
    grid-template-columns: 1fr min-content;
    gap: 16px;
    margin-bottom: 40px;

    @media (max-width: 1120px) {
        grid-template-columns: 1fr;
    }
`;

export const SectionDashFilterSelect = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;

    @media (max-width: 740px) {
        flex-direction: column;
    }
    >label {
        width: 100%;
    }
`;

export const SectionDashFilterAutomatic = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const SectionDashCards = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;

    @media (max-width: 740px) {
        grid-template-columns: 1fr;
    }
`;
