import React, { createContext, useContext } from 'react';
import { DefaultTheme } from 'styled-components';
import { dark } from '../styles/themes/dark';
import { light } from '../styles/themes/light';
import usePersistedState from '../utils/usePersistedState';

interface UIThemeContextData {
    theme: DefaultTheme;
    changeTheme(): void;
}

const UIThemeContext = createContext<UIThemeContextData>(
    {} as UIThemeContextData
);

export const UIThemeContextProvider: React.FC = ({ children }) => {
    const [theme, setTheme] = usePersistedState<DefaultTheme>("theme", dark);

    function changeTheme() {
        setTheme(theme.title === 'light' ? dark : light);
    }

    return (
        <UIThemeContext.Provider value={{ theme, changeTheme }}>
            {children}
        </UIThemeContext.Provider>
    );
};

export function useUIThemeContext() {
    const context = useContext(UIThemeContext);
    return context;
}

export default UIThemeContext;
