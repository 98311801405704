import styled from 'styled-components'

export const Container = styled.div `
    margin-top: 20px;
    th, td {
        padding: 13px 20px !important;
    }
    th {
        border-top: none !important
    }
`

export const ContainerTableRow = styled.div`
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    align-items: flex-start;
    th, td {
        padding: 13px 20px !important;
    }
    th {
        border-top: none !important
    }
`

export const ContainerColumns = styled.div`
    background: rgba(0,0,0,.1);
    border:1px solid rgba(255,255,255,.1);
    border-radius: 5px;
    padding: 15px;
    margin-right: 20px;
    .label {
        white-space: nowrap;
    }
`

export const ContainerTable = styled.div`
    overflow: auto;
    width: 100%;
    background: rgba(0,0,0,0.1);
    border:1px solid rgba(255,255,255,.1);
    border-radius: 5px;
    ::-webkit-scrollbar {
        width: 12px;
        height: 10px;
    }  
    ::-webkit-scrollbar-track {
        background: rgba(255,255,255,0.5);
        border-radius: 10px;
    }
                        
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0,0,0,0.2);
    }
    table {
        th {
            white-space: nowrap;
            .headname {
                padding-right: 20px;
                cursor: pointer;
                svg {
                    margin-left: 10px;
                    transition: all .2s;
                }
                &.active {
                    svg {
                        transform: rotate(180deg)
                    }
                }
            }
        }
        td {
            .valueRow {
                padding-right: 20px;
                white-space: nowrap;
            }
            .subtotal {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                font-weight: bold;
            }
        }
    }

`