import { useEffect, useState } from 'react';
import { useRoutes } from '../../../contexts/RoutesContext';
import ButtonLinear from '../../../components/form/ButtonLinear';
import { IconClose, IconConfig, IconPlus } from '../../../components/icons';
import {Container,Header,Title,Info,Group,ButtonClose,NameGroup,CardOperator,MainEdit} from './styles';
import ModalDefault from '../../../components/ModalDefault';
import Field, { Label } from '../../../components/layout/containers/Field';
import SelectDefault from '../../../components/form/SelectDefault';
import FieldGroup from '../../../components/layout/containers/FieldGroup';
import InputDefaultTime from '../../../components/form/InputDefaultTime';
import { useMessage } from '../../../contexts/MessagesContext';
import { useHistory } from 'react-router';
import BackPage from '../../../components/layout/UIElements/BackPage';
import { Separator } from '../../../components/layout/containers/Separator';
import InputDefault from '../../../components/form/InputDefault';
import apiTropa from '../../../services/apiTropa';
import TableDefault from '../../../components/TableDefault';
import { Td, TdDescription, TdSubtitle } from '../../../components/layout/containers/Td';
import Loading from '../../../components/Loading/Loading';
import InputRadioDefault from '../../../components/form/InputRadioDefault';
import { useUser } from '../../../contexts/UserContext';

interface IDTOPausa {
    "descricao": string
    "inicio": string
    "fim": string
    // "categoria_pausa": string
}

interface IDTO {
    "id_operador": number
    "data": "" | "segunda-feira" | "terca-feira" | "quarta-feira" | "quinta-feira" | "sexta-feira" | "sabado" | "domingo"
    "horarios": {
	    "inicio": string
	    "fim": string
        "pausa": IDTOPausa[]
    }
}

export default function GrupoAtendimentoOperadoresEdit(props: any) {
    const { setPageName } = useRoutes()
    const { userEyou, user } = useUser()
    const history = useHistory()
    const { addSuccess, addDanger, addConfirm } = useMessage()
    const idOperador = props.match.params.idOperador
    const [modal, setModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [operador, setOperador] = useState<any>()
    const [DTO, setDTO] = useState<IDTO>({
        id_operador: 0,
        data: "",
        horarios: {
            inicio: "",
            fim: "",
            pausa: []
        }
    })

    useEffect(() => {
        getOperador()
    }, [])

    function getOperador(){
        apiTropa.post(`operador/detalhe/${idOperador}`, {
            tokenEyou: window.sessionStorage.getItem('tokenInicial')
        }).then((response:any) => {
            const operador = response.data.data
            if( operador.horarios.find((row:any) => row.data === "domingo") === undefined ) operador.horarios.splice(0, 0, {data: "domingo"})
            if( operador.horarios.find((row:any) => row.data === "segunda-feira") === undefined ) operador.horarios.splice(1, 0, {data: "segunda-feira"})
            if( operador.horarios.find((row:any) => row.data === "terca-feira") === undefined ) operador.horarios.splice(2, 0, {data: "terca-feira"})
            if( operador.horarios.find((row:any) => row.data === "quarta-feira") === undefined ) operador.horarios.splice(3, 0, {data: "quarta-feira"})
            if( operador.horarios.find((row:any) => row.data === "quinta-feira") === undefined ) operador.horarios.splice(4, 0, {data: "quinta-feira"})
            if( operador.horarios.find((row:any) => row.data === "sexta-feira") === undefined ) operador.horarios.splice(5, 0, {data: "sexta-feira"})
            if( operador.horarios.find((row:any) => row.data === "sabado") === undefined ) operador.horarios.splice(6, 0, {data: "sabado"})
            

            operador.grupos.map((row:any, key:any) => {

                if ( row.id_grupo_atendimento === operador.perfil[0].prioridade_grupo ) {
                    row.prioridade = true
                }

            })

            setOperador(operador)
            setPageName(`Operador ${operador.perfil[0]?.nome}`)
            setLoading(false)
        })
    }

    function onChangeDTO(key:any, value:any, parent?:any){
        const copy = DTO as any
        if( parent ){
            copy[parent][key] = value
        } else {
            copy[key] = value
        }
        setDTO(copy)
    }

    function onChangeDTOPausas(key:any, value:any, index:any){
        const copy = DTO as any
        copy.horarios.pausa[index][key] = value
        setDTO(copy)
    }

    function onClickEditarHorario(DTO:any){

        const copy = {
            id_operador: idOperador,
            data: DTO.data,
            horarios: DTO.horarios ? DTO.horarios : {
                inicio: "",
                fim: "",
                pausa: []
            }
        }

        setDTO({...copy})
        setModal(true)
    }

    function adicionarPausa(){
        DTO.horarios.pausa.push({
            descricao: "",
            inicio: "",
            fim: "",
        })
        setDTO({...DTO})
    }

    function removerPausa(index:any){
        DTO.horarios.pausa.splice(index, 1)
        setDTO({...DTO})
    }

    async function salvarHorarios(){
        try {
            setLoading(true)
            await apiTropa.post(`/programacao-operador`, DTO)
            setModal(false)
            getOperador()
            addSuccess('Sucesso', 'Edição realizada!')

        } catch(err:any){
            if( err.response ){
                addDanger('Atenção', String(err.response.data.message))
            } else {
                addDanger('Atenção', String(err))
            }
        }
    }

    async function removerOperadorDoGrupo(id_grupo_atendimento:any) {
        try {

            setLoading(true)
            await apiTropa.delete(`/grupo-atendimento/operador/remover`, {
                data: {
                    id_grupo_atendimento: id_grupo_atendimento,
                    id_operador: idOperador
                }
            })
            
            addSuccess('Confirmado', 'Operador removido')
            getOperador()
            
        } catch (err:any) {
            if( err.response ){
                addDanger('Atenção', String(err.response.data.message))
            } else {
                addDanger('Atenção', String(err))
            }
        }
    }

    async function handleOnPrioridade(keyGrupo:any){



        operador.grupos.map((row:any, key:any) => {
            if ( key === keyGrupo ) {
                row.prioridade = true
            } else {
                row.prioridade = false
            }
        })
        setOperador({...operador})

        const grupoPrioridade = operador.grupos.filter((obj:any) => 
            obj.prioridade === true      
        )

        const DTO:any = operador.perfil[0]
            DTO.prioridade_grupo = grupoPrioridade.length > 0 ? grupoPrioridade[0].id_grupo_atendimento : false

        await apiTropa.put(
            'perfil/usuario/'+DTO.id_perfil_permissao,
            DTO
        )

    }


    return (
        <Container>
            <Loading active={loading} />

            <Header>
                <BackPage />
            </Header>

            <MainEdit>

                <CardOperator>
                    <Title>
                        <h2>Horários de Atendimento</h2>
                    </Title>
                    {operador &&  
                    <>
                        {operador.horarios.map((row:IDTO, key:any) => (
                        <TableDefault columns={4} key={key}>
                            <Td flexDirection="column">
                                <TdDescription>Dia da semana</TdDescription>
                                <TdSubtitle>{row.data}</TdSubtitle>
                            </Td>
                            <Td flexDirection="column">
                                <TdDescription>Horários</TdDescription>
                                <TdSubtitle>{row.horarios?.inicio ? row.horarios.inicio + " às " + row.horarios.fim : "Não definido"}</TdSubtitle>
                            </Td>
                            <Td flexDirection="column">
                                <TdDescription>Pausas</TdDescription>
                                <TdSubtitle>
                                    {(!row.horarios || !row.horarios.pausa) ? "Não definido" :
                                        row.horarios.pausa.map((pausa:any) => <p>{pausa.descricao} - {pausa.inicio} às {pausa.fim}</p>)
                                    }
                                </TdSubtitle>
                            </Td>
                            <Td flexDirection="column" style={{ alignItems: 'flex-end' }}>
                                <ButtonLinear Icon={IconConfig} onClick={() => onClickEditarHorario(row)} />
                            </Td>
                        </TableDefault>
                        ))}
                    </>
                    }
                </CardOperator>

                <CardOperator>
                    <Title>
                        <h2>Grupos que atende</h2>
                    </Title>

                    <Info>
                        {operador?.grupos.map((row:any, key:any) => (
                            <Group key={key}>
                                <NameGroup>
                                    <h2>Grupo</h2>
                                    <span>{row.grupo_atendimento}</span>
                                    <InputRadioDefault
                                        options={[
                                            {
                                                label: 'Priorizar grupo',
                                                value: row.id_grupo_atendimento
                                            },
                                        ]}
                                        onChange={() => handleOnPrioridade(key)}
                                        value={row.prioridade === true ? row.id_grupo_atendimento : false}
                                    />
                                </NameGroup>

                                <ButtonLinear
                                    Icon={IconClose}
                                    onClick={() =>
                                        addConfirm({
                                            title: 'Atenção',
                                            description: 'Você tem certeza que deseja remover o operador desse grupo?',
                                            onConfirm: () => removerOperadorDoGrupo(row.id_grupo_atendimento),
                                            textButtonCancel: 'Não',
                                            textButtonConfirm: 'Sim',
                                        })
                                    }
                                />
                            </Group>
                        ))}
                    </Info>
                </CardOperator>

            </MainEdit>

            <ModalDefault
                id="overlayModal"
                width="550px"
                onClose={() => setModal(!modal)}
                openModal={modal}
            >
                <Field gap="5px">
                    <Label>Dia da Semana</Label>
                    <SelectDefault value={DTO.data} onChangeText={(value) => onChangeDTO("data", value)}>
                        <option value="domingo" disabled={true}>Domingo</option>
                        <option value="segunda-feira" disabled={true}>Segunda-Feira</option>
                        <option value="terca-feira" disabled={true}>Terça-Feira</option>
                        <option value="quarta-feira" disabled={true}>Quarta-Feira</option>
                        <option value="quinta-feira" disabled={true}>Quinta-Feira</option>
                        <option value="sexta-feira" disabled={true}>Sexta-Feira</option>
                        <option value="sabado" disabled={true}>Sábado</option>
                    </SelectDefault>
                </Field>

                <Field gap="5px" marginTop="16px">
                    <Label>Defina os horários de inicio e fim</Label>
                    <FieldGroup>
                        <InputDefaultTime
                            value={DTO.horarios.inicio}
                            onChangeText={(value) => onChangeDTO("inicio", value, "horarios")}
                        />
                        <span style={{fontSize: '14px', fontWeight: 'bold', color: '#fff'}}>às</span>
                        <InputDefaultTime
                            value={DTO.horarios.fim}
                            onChangeText={(value) => onChangeDTO("fim", value, "horarios")}
                        />
                    </FieldGroup>
                </Field>
                
                <br/>
                <Header>
                    <Title>Configurar Pausas</Title>
                    <ButtonLinear onClick={() => adicionarPausa()} Icon={IconPlus}></ButtonLinear>
                </Header>
                <Separator margin="15px 0" />

                {DTO.horarios.pausa.map((row:IDTOPausa, key:any) => (
                <>
                <FieldGroup>
                    <div style={{width: "480px"}}>
                        <Field gap="5px">
                            <Label>Descrição</Label>
                            <InputDefault value={row.descricao} onChangeText={(value) => onChangeDTOPausas("descricao", value, key)} />
                        </Field>

                        {/* <Field gap="5px" marginTop="12px">
                            <Label>Tipo de pausa</Label>
                            <SelectDefault value={DTO.horarios.pausa.map((row) => row.categoria_pausa)[key]} onChangeText={(value) => onChangeDTOPausas('categoria_pausa', value, key)}>
                                <option value="0">
                                    Selecione
                                </option>
                                <option value="produtiva">
                                    Produtiva
                                </option>
                                <option value="improdutiva">
                                    Improdutiva
                                </option>
                            </SelectDefault>
                        </Field> */}

                        <Field gap="5px" marginTop="15px">
                            <Label>Defina os horários de inicio e fim</Label>
                            <FieldGroup>
                                <InputDefaultTime
                                    value={row.inicio}
                                    onChangeText={(value) => onChangeDTOPausas("inicio", value, key)}
                                />
                                <span style={{fontSize: '14px', fontWeight: 'bold', color: '#fff'}}>às</span>
                                <InputDefaultTime
                                    value={row.fim}
                                    onChangeText={(value) => onChangeDTOPausas("fim", value, key)}
                                />
                            </FieldGroup>
                        </Field>
                    </div>

                    <ButtonClose onClick={() => removerPausa(key)}>
                        <IconClose color="#FF868B" />
                    </ButtonClose>
                </FieldGroup>

                <Separator margin="15px 0" />
                </>
                ))}

                <FieldGroup marginTop="32px">
                    <ButtonLinear onClick={() => setModal(!modal)}>Cancelar</ButtonLinear>
                    <ButtonLinear onClick={salvarHorarios} className="success">Salvar</ButtonLinear>
                </FieldGroup>
            </ModalDefault>
        </Container>
    );
}
