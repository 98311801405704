import React from 'react';
import { IconArrowMessageLeft, IconArrowMessageRight } from '../../icons';

import { Content, Container } from './styles';

interface ChatProps {
    icon?: React.ComponentType;
    message?: string;
    messageType: 'enviada' | 'recebida';
    backgroundEnviada?: string;
    backgroundRecebida?: string;
    color?: string;
    messageTime?: string;
    hasView?: boolean;
    children: any;
}

export default function CardMessage({
    icon: Icon,
    message,
    messageType,
    backgroundEnviada,
    backgroundRecebida,
    color,
    messageTime,
    hasView,
    children,
}: ChatProps) {

    return (
        <Container 
            messageType={messageType}
            backgroundEnviada={backgroundEnviada}
            backgroundRecebida={backgroundRecebida}
        >
                
            <Content
                backgroundEnviada={backgroundEnviada}
                backgroundRecebida={backgroundRecebida}
                color={color}
                messageType={messageType}
                hasView={hasView}
            >
                {children}

            </Content>
                <IconArrowMessageRight />
        </Container>
    );
}
