import React from 'react';
import {
    IconClose,
    IconDanger,
    IconInfo,
    IconSuccess,
    IconWarning,
} from '../icons';
import { Container } from './styles';

interface IProps {
    closeMessage(): void;
    message: MessageProps;
}

interface MessageProps {
    type: string;
    title: string;
    message: string;
    visible: boolean;
}

const MessageAlert: React.FC<IProps> = ({ closeMessage, message }) => {
    return (
        <Container visible={message.visible}>
            <button className="overlay" onClick={closeMessage} />
            <div className="message-container">
                <div className={`box ${message.type}`}>
                    <div className="icon">
                        <svg
                            width="30"
                            height="31"
                            viewBox="0 0 30 31"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M14.9999 28.16C22.7761 28.16 29.0799 21.8562 29.0799 14.08C29.0799 6.30383 22.7761 0 14.9999 0C7.22375 0 0.919922 6.30383 0.919922 14.08C0.919922 18.4585 2.91853 22.3702 6.05323 24.9526C6.05825 24.9568 6.05378 24.9648 6.04763 24.9627C6.04384 24.9614 6.03991 24.9642 6.03991 24.9682V28.218C6.03991 29.9526 7.8682 31.0784 9.41712 30.2976L12.749 28.6181C13.4124 28.2836 14.1559 28.1544 14.8988 28.1596C14.9325 28.1599 14.9662 28.16 14.9999 28.16Z"
                                fill="#FFFFF"
                            />
                        </svg>

                        <i>
                            {message.type === 'success' && <IconSuccess />}
                            {message.type === 'warning' && <IconWarning />}
                            {message.type === 'danger' && <IconDanger />}
                            {message.type === 'info' && <IconInfo />}
                        </i>
                    </div>

                    <div className="text">
                        <p className="title">{message.title}</p>
                        <p className="message">{message.message}</p>
                    </div>
                    <button className="close" onClick={closeMessage}>
                        <IconClose />
                    </button>
                </div>
            </div>
        </Container>
    );
};

export default MessageAlert;
