import styled, { css } from 'styled-components';

interface ContainerProps {
    messageType?: 'recebida' | 'enviada';
    backgroundEnviada?: string;
    backgroundRecebida?: string;
    hasView?: boolean;
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    position: relative;

     >svg {
        position: absolute;
        bottom: 10px;
        right: 6px;

        path {
            fill: ${props => props.backgroundRecebida
            ? props.backgroundRecebida
            : '#28b3a2'};
        }

    }

    ${props => props.messageType === 'enviada' &&
    css`
        > svg {
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            bottom: 10px;
            left: 6px;

            path {
            fill: ${props.backgroundEnviada ? props.backgroundEnviada : '#4a7dff'};
            }
        }
    `
     }

    ${(props) =>
        props.messageType === 'recebida' &&
        css`
            justify-content: flex-end;
        `}
`;

export const Content = styled.div<ContainerProps>`
    min-height: 40px;
    border-radius: 10px;
    padding: 12px 16px;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    background: ${(props) =>
        props.backgroundEnviada ? props.backgroundEnviada : '#4a7dff'};
    color: ${(props) => props.color};
    margin: 0 14px;
    max-width: 90%;

    ${(props) =>
        props.messageType === 'recebida' &&
        css`
            background: ${props.backgroundRecebida
                ? props.backgroundRecebida
                : '#28b3a2'};
        `}

    p {
        font-size: 13px;
        color: #fff;
        max-width: 100%;
        white-space: pre-line;
    }
    span {
        font-size: 11px;
        white-space: nowrap;
        color: #eaeef3;
    }
    .content-message {
        max-width: 100%;

        img,
        video,
        audio {
            max-width: 100%;
        }
        video,
        img {
            min-height: 270px;
        }

        .downloadFileChat {
            display: flex;
            align-items: center;

            width: 100%;
            padding: 10px 6px;
            border-radius: 4px;

            border: none;
            background: #065051;
            text-decoration: none;

            span {
                font-size: 12px;
                font-weight: 600;
                color: #fff;
                margin-left: 8px;
            }

            .iconDownloadChat {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                border: 1px solid #cecece;

                border-radius: 50%;
                padding: 4px;
                margin-left: 30px;
                svg {
                    margin: 0;
                }
            }
        }
    }
    .status {
        display: flex;
        justify-content: flex-end;
        margin-top: 5px;

        margin-left: 6px;
        margin-right: 4px;
        width: 100%;
    }

    ${(props) =>
        !props.hasView &&
        css`
            svg {
                display: none;
            }
        `}
    svg {
        margin-left: 4px;
    }
`;
