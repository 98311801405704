import React from 'react';
import { IconDate } from '../../icons';
import InputMask from 'react-input-mask';

import { Container } from './styles';

interface InputProps extends React.HTMLAttributes<HTMLInputElement> {
    value: string;
}

const InputDefaultDate: React.FC<InputProps> = ({
    value,
    ...rest
}) => {

    return (
        <Container className="form-children-default">
            <IconDate />
            <InputMask
                type="tel"
                mask="99/99/9999"
                value={value}
                {...rest}
            />
        </Container>
    );
};

export default InputDefaultDate;
