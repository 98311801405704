
import { convertMessage } from '../../../utils/ConvertMessages';
import { Container } from './styles';
export default function CardRenderMessage({ value }: any) {
  return (
    <Container>
        <p dangerouslySetInnerHTML={{__html: convertMessage(value) }}/>
    </Container>
  )
}
