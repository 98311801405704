import { useEffect, useState } from 'react';
import BackPage from '../../../components/layout/UIElements/BackPage';
import { useRoutes } from '../../../contexts/RoutesContext';
import ButtonLinear from '../../../components/form/ButtonLinear';
import TableDefault from '../../../components/TableDefault';
import {
    Td,
    TdDescription,
    TdSubtitle,
} from '../../../components/layout/containers/Td';

import { Container, Header, Main } from './styles';
import UserTable from '../../../components/Table/UserTable';
import OperatorAttendence from '../../../components/Table/OperatorAttendence';
import ConfigOperator from '../../../components/Table/ConfigOperator';
import { useMessage } from '../../../contexts/MessagesContext';
import apiTropa from '../../../services/apiTropa';
import ModalDefault from '../../../components/ModalDefault';
import Field from '../../../components/layout/containers/Field';
import SelectDefault from '../../../components/form/SelectDefault';
import FieldGroup from '../../../components/layout/containers/FieldGroup';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import FailedRegister from '../../../components/FailedRegister';
import { GrupoAtendimento } from '../../../types/GrupoAtendimento';
import { Operador } from '../../../types/Operador';

export default function GrupoAtendimentoOperadores(props: any) {
    const { setPageName } = useRoutes()
    const { addConfirm, addDanger, addSuccess } = useMessage()

    const [load, setLoad] = useState<boolean>(true)
    const [modal, setModal] = useState<boolean>(false)
    const id = props.match.params.id

    const [grupoAtendimento, setGrupoAtendimento] = useState<GrupoAtendimento>({
        operadores: []
    })
    const [operadores, setOperadores] = useState<Operador[]>([])
    const [DTO, setDTO] = useState<any>({})

    useEffect(() => {
        setLoad(true)
        getGrupoAtendimento()
        getOperadores()
    }, [])

    function getGrupoAtendimento(){
        apiTropa.get(`grupo-atendimento/${id}`).then((response:any) => {
            const grupoAtendimento = response.data.data

            apiTropa.post(`/operador/grupo-atendimento/${id}`, {
                tokenEyou: window.sessionStorage.getItem('tokenInicial')
            }).then((response:any) => {
                grupoAtendimento.operadores = response.data.data
                setGrupoAtendimento(grupoAtendimento)

                setPageName(`Operadores do grupo ${grupoAtendimento.grupo_atendimento}`)
                setLoad(false)
            })
        })
    }

    function getOperadores(){
        apiTropa.post(`operador/cliente`, {
            tokenEyou: window.sessionStorage.getItem('tokenInicial')
        }).then((response:any) => {
            const operadores = response.data.data
            setOperadores(operadores)
        })
    }

    function onChangeDTO(key:any, value:any){
        DTO[key] = value
        setDTO({...DTO})
    }

    async function adicionarOperadorNoGrupo() {
        try {
            if( !DTO.id_operador ) throw 'Selecione um operador antes de inserir.'
            DTO.id_grupo_atendimento = id
            setLoad(true)
            setModal(false)
            await apiTropa.post(`/grupo-atendimento/operador`, DTO)
            
            onChangeDTO("id_operador", "")
            addSuccess('Confirmado', 'Operador adicionado')
            getGrupoAtendimento()
            
        } catch (err:any) {
            if( err.response ){
                addDanger('Atenção', String(err.response.data.message))
            } else {
                addDanger('Atenção', String(err))
            }
        }
    }

    async function removerOperadorDoGrupo(id_operador:any) {
        try {

            setLoad(true)
            await apiTropa.delete(`/grupo-atendimento/operador/remover`, {
                data: {
                    id_grupo_atendimento: id,
                    id_operador: id_operador
                }
            })
            
            addSuccess('Confirmado', 'Operador removido')
            getGrupoAtendimento()
            
        } catch (err:any) {
            if( err.response ){
                addDanger('Atenção', String(err.response.data.message))
            } else {
                addDanger('Atenção', String(err))
            }
        }
    }

    return (
    <Container>
        <Header>
            <BackPage />
            <ButtonLinear className="info" onClick={() => setModal(true)}>
                Adicionar operador
            </ButtonLinear>
        </Header>

        <Main>
        { load === true ? 
            <Skeleton
                count={1}
                className="skeletor"
                height="80px"
                borderRadius="16px"
                baseColor="#262655"
                highlightColor="#3e3b77"
            />  
        :    
        <>
            {!grupoAtendimento.operadores.length ? 
                <FailedRegister />
            : 
            <>
                {grupoAtendimento.operadores.map((row:any) => ( 
                <TableDefault
                    columns={2}
                    dropDownComponent={<OperatorAttendence operador={row} />}
                >
                    <Td flexDirection="column">
                        <UserTable 
                            name={row.nome ? row.nome : row.perfil.apelido}
                            image={row.foto}
                        />
                    </Td>
                    <Td flexDirection="column" style={{ alignItems: 'flex-end' }}>
                        <ConfigOperator
                            operador={row}
                            onDelete={() =>
                                addConfirm({
                                    title: 'Atenção',
                                    description: 'Certeza que gostaria de remover esse operador? Ao excluir a ação não poderá ser desfeita.',
                                    textButtonConfirm: 'Remover',
                                    textButtonCancel: 'Cancelar',
                                    onConfirm: () => removerOperadorDoGrupo(row.id_operador),
                                    onCancel: () => {},
                                })
                            }
                        />
                    </Td>
                </TableDefault>
                ))
                }
            </>
            }
        </>
        }
        </Main>

        <ModalDefault
            id="overlayModal"
            onClose={() => setModal(!modal)}
            openModal={modal}
        >
            <Field>
                <SelectDefault
                    value="0"
                    placeholder="Selecionar operador"
                    onChangeText={(value) => onChangeDTO("id_operador", value)}
                >
                    {operadores.filter((obj:any) => obj.ativo === "sim").map((row, key) => (
                        <option 
                            value={row.id_login} 
                            disabled={grupoAtendimento.operadores.find((operador:any) => operador.id_login === row.id_login) !== undefined}
                        >
                            {row.perfil[0]?.nome ? row.perfil[0]?.nome : row.apelido}
                        </option>
                    ))}
                </SelectDefault>
            </Field>
            <FieldGroup style={{ marginTop: '32px' }}>
                <ButtonLinear onClick={() => setModal(false)}>
                    Cancelar
                </ButtonLinear>
                <ButtonLinear className="success" onClick={adicionarOperadorNoGrupo}>
                    Inserir
                </ButtonLinear>
            </FieldGroup>
        </ModalDefault>
        
    </Container>
    );
}
