import styled from 'styled-components';

export const Container = styled.label`
    border: 1px solid ${(props) => props.theme.colors.silverSand};
    border-radius: 4px;
    height: 100px;
    width: 100%;
    display: flex;
    padding: 8px 21px;
    /* &:active {
        border: 1px solid ${(props) => props.theme.colors.white};
    } */

    textarea {
        height: 100%;
        width: 100%;
        outline: none;
        resize: none;
        border: none;
        background: transparent;
        color: ${(props) => props.theme.colors.white};
        &::placeholder {
            color: ${(props) => props.theme.colors.silverSand};
        }
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }

        font-size: 14px;
        line-height: 150%;
    }
`;
