import { useEffect, useState } from 'react';
import ButtonLinear from '../../../components/form/ButtonLinear';
import InputSwitchDefault from '../../../components/form/InputSwitchDefault';
import { IconDelete } from '../../../components/icons';
import Field from '../../../components/layout/containers/Field';
import FieldGroup from '../../../components/layout/containers/FieldGroup';
import {
    Td,
    TdDescription,
} from '../../../components/layout/containers/Td';
import BackPage from '../../../components/layout/UIElements/BackPage';
import ModalDefault from '../../../components/ModalDefault';
import TableDefault from '../../../components/TableDefault';
import { useMessage } from '../../../contexts/MessagesContext';
import { useRoutes } from '../../../contexts/RoutesContext';
import apiTropa from '../../../services/apiTropa';
import Skeleton from 'react-loading-skeleton';
import { Container, Header, Content } from './styles';
import StatusTable from '../../../components/Table/StatusTable';
import TextAreaInputDefault from '../../../components/form/TextAreaInputDefault';
import CardRenderMessage from '../../../components/Ui/CardRenderMessage';

export default function MensagensPreDefinidas(props: any) {

    const { setPageName } = useRoutes()
    const { addConfirm, addDanger, addSuccess } = useMessage()
    const id = props.match.params.id
    const [loading, setLoading] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const [modalNewMessage, setModalNewMessage] = useState(false)
    const [mensagens, setMensagens] = useState<any>([])
    const [DTO, setDTO] = useState<any>({
        mensagem: "",
        ativo: "sim"
    })

    useEffect(() => {
        setPageName('Mensagens pré-definidas')
        getData()
    }, [])

    async function getData(){
        setLoading(true)
        apiTropa.get(`/mensagem-predefinida/grupo-atendimento/${id}`).then((response:any) => {
            const mensagens = response.data.data
            setMensagens([...mensagens])
            setLoading(false)
        })
    }

    async function onChangeDTO(key:any, value:any){
        DTO[key] = value
        setDTO({...DTO})
    }

    async function onClickNewMessage(){
        await onChangeDTO("id_mensagem_predefinida","")
        await onChangeDTO("mensagem","")
        setModalNewMessage(true)
    }

    function onClickEditMessage(DTO:any){
        setDTO({...DTO})
        setModalEdit(true)
    }

    async function createNewMessage(e: any) {
        e.preventDefault()
        try {
            DTO.id_grupo_atendimento = id
            await apiTropa.post("/mensagem-predefinida", {
                ativo: DTO.ativo,
                id_grupo_atendimento: DTO.id_grupo_atendimento,
                id_mensagem_predefinida: DTO.id_mensagem_predefinida,
                mensagem: DTO.mensagem,
                tipo: DTO.tipo,
                upload: DTO.upload
            })
            await getData()
            addSuccess('Sucesso', 'Mensagem criada!')
            setModalNewMessage(false)
        } catch(err:any){
            addDanger('Atenção', String(err))
        }
    }

    async function editMessage(e: any) {
        e.preventDefault()
        try {
            DTO.id_grupo_atendimento = id
            await apiTropa.put(`/mensagem-predefinida/${DTO.id_mensagem_predefinida}`, {
                ativo: DTO.ativo,
                id_grupo_atendimento: DTO.id_grupo_atendimento,
                id_mensagem_predefinida: DTO.id_mensagem_predefinida,
                mensagem: DTO.mensagem,
                tipo: DTO.tipo,
                upload: DTO.upload
            })
            await getData()
            addSuccess('Sucesso', 'Edição realizada!')
            setModalEdit(false)

        } catch(err:any){
            addDanger('Atenção', String(err))
        }
    }

    async function deleteMessage(id:any) {
        try {
            await apiTropa.delete(`/mensagem-predefinida/${id}`)
            await getData()
            addSuccess('Sucesso', 'Mensagem excluída!')

        } catch(err:any){
            addDanger('Atenção', String(err))
        }
    }

    return (
        <Container>
            <Header>
                <BackPage />

                <ButtonLinear className="info" onClick={onClickNewMessage}>
                    Nova mensagem
                </ButtonLinear>
            </Header>

            <Content>
                <h1>Minhas Mensagens</h1>

                {loading ?
                    <Skeleton
                        count={10}
                        className="skeletor"
                        height="85px"
                        borderRadius="16px"
                        baseColor="#262655"
                        highlightColor="#3e3b77"
                    />
                :
                    <>
                        {!mensagens.length && <p>Nenhum registro encontrado!</p>}

                        {mensagens.map((row:any) => (
                            <TableDefault
                                isArrow={false} 
                                columns={2}
                            >
                                <Td flexDirection="column">
                                    <TdDescription>Mensagem</TdDescription>
                                    <div className='Mymessage'>{row.mensagem}</div>


                                </Td>
                                <Td
                                    flexDirection="row"
                                    style={{ justifyContent: 'end', gap: '22px' }}
                                >
                                    <div className="statusTable" style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <TdDescription>Status</TdDescription>
                                        <StatusTable
                                            showLabel={false}
                                            status={row.ativo === 'sim' ? true : false}
                                        />
                                    </div>
                                    <ButtonLinear onClick={() => onClickEditMessage(row)}>
                                        Editar
                                    </ButtonLinear>

                                    {/* <ButtonLinear
                                        className="info"
                                        Icon={IconShareEye}
                                        onClick={() =>
                                            addConfirm({
                                                title: 'Cancelamento',
                                                description:
                                                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dignissim, massa eget euismod consectetur, sem nulla condimentum mauris.',
                                            })
                                        }
                                    /> */}

                                    <ButtonLinear
                                        className="delete"
                                        Icon={IconDelete}
                                        onClick={() =>
                                            addConfirm({
                                                title: 'Atenção',
                                                description: 'Certeza que gostaria de excluir essa mensagem? Ao excluir a ação não poderá ser desfeita.',
                                                textButtonCancel: 'Cancelar',
                                                textButtonConfirm: 'Excluir',
                                                onConfirm: () => deleteMessage(row.id_mensagem_predefinida)
                                            })
                                        }
                                    />
                                </Td>
                            </TableDefault>
                        ))}
                    </>
                }
                
            </Content>

            <ModalDefault
                id="overlayModal"
                onClose={() => setModalEdit(!modalEdit)}
                openModal={modalEdit}
            >
                <Field>
                    <InputSwitchDefault
                        className="reverse"
                        options={[{label: "Habilitar mensagem?", value: "sim"}]}
                        value={[DTO.ativo !== "sim" ? "nao" : "sim"]}
                        onChange={(value) => onChangeDTO("ativo", (!value.length ? "nao" : "sim"))}
                    />

                    {/* <InputTextarea
                        placeholder="Digite um texto para a mensagem pré-definida"
                        value={convertMessage(DTO.mensagem)}
                        onChangeText={(value) => onChangeDTO("mensagem", value)}
                    /> */}

                    <CardRenderMessage
                        value={DTO.mensagem}
                     />

                    <form className='formMessagePre'>
                        <TextAreaInputDefault
                            heightEmoji="110px"
                            value={DTO.mensagem}
                            handleOnKeyPress={() => {}}
                            onChange={(e: any) => onChangeDTO("mensagem", e.target.value)}
                        />
                    </form>

                </Field>
                <FieldGroup style={{ marginTop: '16px' }}>
                    <ButtonLinear onClick={() => setModalEdit(false)}>
                        Cancelar
                    </ButtonLinear>
                    <ButtonLinear className="success" onClick={editMessage}>
                        Salvar
                    </ButtonLinear>
                </FieldGroup>
            </ModalDefault>

            <ModalDefault
                id="overlayModal"
                onClose={() => setModalNewMessage(!modalNewMessage)}
                openModal={modalNewMessage}
            >
                <Field>
                    <InputSwitchDefault
                        className="reverse"
                        options={[{label: "Habilitar mensagem?", value: "sim"}]}
                        value={[DTO.ativo !== "sim" ? "nao" : "sim"]}
                        onChange={(value) => onChangeDTO("ativo", (!value.length ? "nao" : "sim"))}
                    />

                    {/* <InputTextarea
                        placeholder="Digite um texto para a mensagem pré-definida"
                        value={DTO.mensagem}
                        onChangeText={(value) => onChangeDTO("mensagem", value)}
                    /> */}

                    <CardRenderMessage
                        value={DTO.mensagem ? DTO.mensagem : '<span>Digite um texto para a mensagem pré-definida</span>'}
                     />

                    <form className='formMessagePre' style={{marginTop: '32px'}}>
                        <TextAreaInputDefault
                            heightEmoji="85px"
                            handleOnKeyPress={() => {}}
                            value={DTO.mensagem}
                            onChange={(e: any) => onChangeDTO("mensagem", e.target.value)}
                        />
                    </form>
                </Field>

                <FieldGroup style={{ marginTop: '16px' }}>
                    <ButtonLinear onClick={() => setModalNewMessage(false)}>
                        Cancelar
                    </ButtonLinear>
                    <ButtonLinear
                        className="success"
                        onClick={createNewMessage}
                    >
                        Salvar
                    </ButtonLinear>
                </FieldGroup>
            </ModalDefault>
        </Container>
    );
}
