import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import ButtonLinear from '../form/ButtonLinear';

import { Container } from './styles'

interface IProps {
    setPage: any
    page: number,
    totalPage: number,
    range: number,
    totalDocs: number
}


const Paginator = ({ setPage, page, totalPage = 0, range, totalDocs }: IProps) => {
    const [data, setData] = useState<any>([]);

    useEffect(() => {
        const newData: any = [];
        for (var i = page - range; i < page + range; i++) {
            try {
                if (i <= 0) throw i;
                if (i > totalPage) throw i;

                newData.push(i);
            } catch (e) {}
        }
        setData([...newData]);
    }, [page, totalPage]);

    return data.length > 0 ? (
        <Container>
            <ul className="paginator">
                <li>
                    <ButtonLinear
                    className={page === 1 ? 'info' : 'transparent'} type="button" onClick={() => page != 1 && setPage(1)}>
                        Primeira
                    </ButtonLinear>
                </li>
                {data.map((row: any, key: any) => (
                    <li key={key}>
                        <ButtonLinear
                        className={ page === row ? 'info' : 'transparent'} type="button" onClick={() => row != page && setPage(row)}>
                            {row}
                        </ButtonLinear>
                    </li>
                ))}
                <li>
                    <ButtonLinear
                    className={page === totalPage ? 'info' : 'transparent'} type="button" onClick={() => page != totalPage && setPage(totalPage)}>
                        Ultíma
                    </ButtonLinear>
                </li>

                <li>
                    <p>{totalDocs} registros</p>
                </li>
            </ul>
        </Container>
    ) : (<></>);
};

export default Paginator;
