/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import apiTropa from '../../services/apiTropa';
import { useRoutes } from '../../contexts/RoutesContext';
import { useMessage } from '../../contexts/MessagesContext';

//LIBS
import 'react-loading-skeleton/dist/skeleton.css';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';

//COMPONENTS
import ModalDefault from '../../components/ModalDefault';
import FieldGroup from '../../components/layout/containers/FieldGroup';
import Field from '../../components/layout/containers/Field';
import FailedRegister from '../../components/FailedRegister';
import Loading from '../../components/Loading/Loading';
import TableSimple from '../../components/TableSimple';
import TableDefault from '../../components/TableDefault';
import ButtonLinear from '../../components/form/ButtonLinear';
import SelectDefault from '../../components/form/SelectDefault';
import ContainerFilter from '../../components/form/ContainerFilter';

// ICONS
import { IconArrowLeft } from '../../components/icons';

import { Container, SectionTables, SectionGroup } from './styles';
import InputSwitchDefault from '../../components/form/InputSwitchDefault';
import { Td, TdTitle } from '../../components/layout/containers/Td';

interface IDTOEncaminharManual {
    id_grupo_atendimento: number;
    id_fila_atendimento: number;
    id_usuario: number;
    id_operador: number;
}

interface OptionsOperator {
    apelido?: string;
    nome?: string
    id_login: string;
}

export default function FilaAtendimentoGroup() {
    const { setPageName } = useRoutes();
    const { addSuccess, addDanger } = useMessage();
    const [loading, setLoading] = useState(true);
    const [modalEncaminhar, setModalEncaminhar] = useState<boolean>(false);
    const [groupFilter, setGroupFilter] = useState<any>([]);
    const [grupoSelecionado, setGrupoSelecionado] = useState<any>('');
    const [data, setData] = useState<any[]>([]);
    const [DTOEncaminharManual, setDTOEncaminharManual] = useState<IDTOEncaminharManual>();
    const [optionsOperadores, setOptionsOperadores] = useState<OptionsOperator[]>([]);
    const [timeReload, setTimeReload] = useState<number>(20000);
    const [optionReload, setOptionReload] = useState<any>([]);

    useEffect(() => {
        const setInterva = setInterval(() => {
            if(optionReload.length > 0) {
                getData()
            }
        }, timeReload)

        return () => clearInterval(setInterva)
    }, [optionReload, timeReload])
    
    useEffect(() => {
        setPageName('Fila de Atendimentos');
        getGroups();
        getData();
        getOptionsOperadores();
    }, []);

    async function getData() {
        setLoading(true);
        apiTropa
            .get(`relatorio/fila${grupoSelecionado ? `?gruposAtendimento[]=${grupoSelecionado}` : ''}`)
            .then((res: any) => {
                setData(res.data.data);
                setLoading(false);
            });
    }

    async function getGroups() {
        apiTropa.get(`/grupo-atendimento`).then((response: any) => {
            setGroupFilter([...response.data.data]);
        });
    }

    async function handleSubmit(e: any) {
        e.preventDefault();
        getData();
    }

    function openModalEncaminhar(row: any) {
        setDTOEncaminharManual({
            id_grupo_atendimento: row.id_grupo_atendimento,
            id_fila_atendimento: row.id_fila_atendimento,
            id_usuario: row.id_usuario,
            id_operador: 0,
        });
        setModalEncaminhar(true);
    }

    async function onChangeDTOEncaminharManual(key: any, value: any) {
        const copy = DTOEncaminharManual as any;
        copy[key] = value;
        setDTOEncaminharManual({ ...copy });
    }

    async function handleEncaminharManual() {
        try {
            setLoading(true);
            await apiTropa.post(
                `/atendimento/encaminhar-manualmente/${DTOEncaminharManual?.id_grupo_atendimento}`,
                DTOEncaminharManual
            );
            addSuccess('Sucesso', 'Encaminhado com sucesso!');
            setModalEncaminhar(false);
            getData();
        } catch (err: any) {
            if (err.response) {
                addDanger('Atenção', String(err.response.data.message));
            } else {
                addDanger('Atenção', String(err));
            }
            setLoading(false);
        }
    }

    function getOptionsOperadores() {
        apiTropa.get('/chat-cliente-get-operadores').then((res: any) => {
            setOptionsOperadores(
                res.data.data.filter((obj: any) => obj.status_operador === 'online').map((row: any) => row)
            );
        });
    }

    function converterDadosEntradaForName(data: any) {
        try {
            const dadosEntrada: any = JSON.parse(JSON.parse(data));
            return dadosEntrada['Nome completo']
        } catch ( err:any ) {
            return ''
        }
    }

    const ContainerDadosEntrada = ({ objData }: any) => {
        return (
            <div className="sectionTable">
                <TableSimple width="100%" height="250px">
                    <thead>
                        <tr>
                            <th>Posição na fila</th>
                            <th>Nome</th>
                            <th>Data Entrada</th>
                            <th className="thButton">Encaminhar</th>
                        </tr>
                    </thead>

                    <tbody>
                        {objData.map((row: any, key: number) => (
                            <>
                                <tr key={row.id_usuario}>
                                    <td>#{key + 1}</td>
                                    <td>{converterDadosEntradaForName(row.informacao_entrada)}</td>
                                    <td>{moment(row.data_entrada).utc().format('DD/MM/YYYY HH:mm:ss')}</td>
                                    <td className="buttonForward">
                                        <ButtonLinear onClick={() => openModalEncaminhar(row)} Icon={IconArrowLeft}>
                                            Encaminhar
                                        </ButtonLinear>
                                    </td>
                                </tr>
                            </>
                        ))}
                    </tbody>
                </TableSimple>
            </div>
        );
    };

    return (
        <>
            <Loading active={loading} />
            <Container>
                <form onSubmit={handleSubmit}>
                    <ContainerFilter>
                        <SelectDefault
                            placeholder="Grupos de atendimento"
                            onChangeText={(value) => setGrupoSelecionado(value)}
                            value={grupoSelecionado}
                        >
                            {groupFilter
                                .filter((obj: any) => obj.fila.length)
                                .map((row: any, key: number) => (
                                    <option key={key} value={row.id_grupo_atendimento}>
                                        {row.grupo_atendimento}
                                    </option>
                                ))}
                        </SelectDefault>

                        <ButtonLinear className="info">Filtrar</ButtonLinear>
                        <ButtonLinear onClick={() => getData()} className="success">
                            Atualizar
                        </ButtonLinear>

                        <InputSwitchDefault
                            onChange={(value) => setOptionReload(value)}
                            flexDirection="column"
                            options={[
                                {
                                    value: '1',
                                    label: 'Atualizar automaticamente',
                                },
                            ]}
                            value={optionReload}
                        />

                        {optionReload.length > 0 && (
                            <SelectDefault
                                onChangeText={(value) => setTimeReload(Number(value))}
                                value={timeReload}
                            >
                                <option value={20000}>
                                    20 segundos
                                </option>
                                <option value={40000}>
                                    40 segundos
                                </option>
                                <option value={60000}>
                                    60 segundos
                                </option>
                            </SelectDefault>
                        )}

                    </ContainerFilter>
                </form>

                <SectionTables>
                    {data
                        .filter((obj) => obj.fila.length)
                        .map((row) => (

                                <TableDefault
                                    dropDownComponent={<ContainerDadosEntrada objData={row.fila} />}
                                    columns={1}
                                >
                                    <Td flexDirection="column">
                                        <TdTitle>
                                            {row.grupo_atendimento}
                                        </TdTitle>
                                    </Td>
                                </TableDefault>
                        ))}
                    {loading === true && (
                        <Skeleton
                            count={4}
                            className="skeletor"
                            height="110px"
                            borderRadius="16px"
                            baseColor="#262655"
                            highlightColor="#3e3b77"
                        />
                    )}
                </SectionTables>
                {loading === false && data.length === 0 && <FailedRegister />}
            </Container>

            <ModalDefault id="modalEncaminhar" openModal={modalEncaminhar} onClose={() => setModalEncaminhar(false)}>
                <Field>
                    <SelectDefault
                        value={DTOEncaminharManual?.id_operador}
                        onChangeText={(value) => onChangeDTOEncaminharManual('id_operador', value)}
                    >
                        <option value="">Selecione o operador para atender</option>
                        {optionsOperadores.map((row) => (
                            <option key={row.id_login} value={row.id_login}>
                                {row.nome}
                            </option>
                        ))}
                    </SelectDefault>
                </Field>

                <FieldGroup marginTop="20px">
                    <ButtonLinear className="transparent" onClick={() => setModalEncaminhar(false)}>
                        Cancelar
                    </ButtonLinear>
                    <ButtonLinear className="success" onClick={() => handleEncaminharManual()}>
                        Encaminhar
                    </ButtonLinear>
                </FieldGroup>
            </ModalDefault>
        </>
    );
}
