import styled from 'styled-components';

interface CardProps {
    status?: boolean;
}

export const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const Status = styled.div<CardProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-size: 10px;
        color: ${(props) => props.theme.colors.lavanderGray};
    }

    .active {
        display: flex;
        align-items: center;
        position: relative;
        margin-left: 15px;
        font-size: 14px;
        color: ${(props) => props.theme.colors.lavanderGray};

        &::before {
            content: '';
            position: absolute;
            left: -14px;
            width: 8px;
            height: 8px;
            background: ${(props) => (props.status ? '#38FFE7' : '#FF868B')};
            border-radius: 50%;
            display: flex;
        }
    }
`;

export const ButtonVizualisation = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: 55px;
`;
