import React, { useEffect, useState } from 'react';
import { IconPencil, IconSearch } from '../../icons';
import InputMask from 'react-input-mask';

import { Container } from './styles';

interface InputProps extends React.HTMLAttributes<HTMLInputElement> {
    placeholder?: string;
    value: string | any;
    type?: 'text' | 'password' | 'email' | 'tel' | 'file';
    mask?: string;
    onChangeText: (value: string | any) => void;
    search?: boolean;
    className?: any;
    title?: string;
    disabled?: boolean;
    isText?: boolean;
}

const InputDefaultColor: React.FC<InputProps> = ({
    placeholder,
    value,
    mask = '',
    onChangeText,
    type,
    search = false,
    title = null,
    className = '',
    disabled = false,
    isText,
    ...rest
}) => {
    const [valueInput, setValueInput] = useState<string>(value);

    useEffect(() => {
        setValueInput(value);
    }, [value]);

    function handleOnChange(e: React.FormEvent<HTMLInputElement>) {

        const valueInput = e.currentTarget.value
        setValueInput(valueInput);
        onChangeText(valueInput);

    }

    function handleValidateHexa(e: React.FormEvent<HTMLInputElement>)
    {
        if(isText) return {};

        if (  value.substring(0, 1) !== '#' ||  value.substring(1, 2) === '#' || value.length < 4 || value.length === 5 || value.length === 6 || value.length === 8 ) {
            setValueInput('#000000FF');
            onChangeText('#000000FF');
        }

    }

    return (
        <Container className={`form-children-default input ${className}`}>
            {title && <p className="title">{title}</p>}
            <InputMask
                type={'text'}
                mask={mask}
                value={valueInput}
                onChange={handleOnChange}
                onBlur={handleValidateHexa}
                placeholder={placeholder}
                disabled={disabled}
                {...rest}
            />
        </Container>
    );
};

export default InputDefaultColor;
