import React, { useEffect, useState } from 'react'
import { Container } from './styles'

interface InputProps {
    min: string
    max: string
    value: string | any
    onChangeText: (value: string) => void
    className?: string
    readOnly?: boolean
}

const InputRating: React.FC<InputProps> = ({
    min = "0",
    max = "5",
    value,
    onChangeText,
    className,
    readOnly = false,
}) => {

    const [valueInput, setValueInput] = useState<string>(value)

    useEffect(() => {
        setValueInput(value)
    }, [value])

    function Rating(min = "0", max = "5") {
        let array = [0]
        for (
            var i = parseFloat(min + 1);
            i < parseFloat(max) + 1;
            i++
        ) {
            array.push(i)
        }
        return array
    }

    function handleOnChange(value:any) {
        if ( readOnly === false ) {
            setValueInput(value)
            onChangeText(value)
        }
    }

    return (
        <Container className={`form-children-default ${className}`}>
            {Rating(min, max).map((number, key) => (
                <div 
                    key={key} 
                    className={`${String(valueInput) === String(number) && "active"}`}
                    onClick={() => handleOnChange(number)}
                >
                    {number}
                </div>
            ))}
        </Container>
    );
};

export default InputRating