import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    .text-chat {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
    @media (max-width: 425px) {
        overflow-x: auto;
    }
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin: 24px 0;
`;

export const SectionDescriptionButton = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    width: 100%;

    @media (max-width: 425px) {
        grid-template-columns: 1fr;
    }
`;

export const PanelLeft = styled.div`
    display: flex;
    height: 100%;

    background: ${props => props.theme.colors.spaceCadedDark};
    border-radius: 16px;
    padding: 32px;
`;

export const PreviewChat = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
`;

export const PanelRight = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const ButtonAction = styled.div`
    display: flex;
    flex-direction: column;

    background: ${props => props.theme.colors.spaceCadedDark};
    border-radius: 16px;
    padding: 32px 24px;

    h1 {
        font-size: 16px;
        font-weight: bold;
        color: ${props => props.theme.colors.white};
    }
`;

export const ButtonsAction = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 16px;
    margin-top: 16px;
`;

export const ButtonLeft = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 50%;

    .sectionButtonImageChat {
        display: flex;
        align-items: center;
        justify-content: center;

        .buttonImageChat {
            object-fit: cover;
            height: 100%;
        }
    }
`;

export const DescriptionButton = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;

    span {
        font-size: 14px;
        font-weight: 500;
        color: ${props => props.theme.colors.white};
    }
`;

export const ButtonRight = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 50%;
`;


export const ButtonMain = styled.div`
    display: flex;
    flex-direction: column;

    background: ${props => props.theme.colors.spaceCadedDark};
    border-radius: 16px;
    padding: 32px 24px;
    gap: 16px;

    h1 {
        font-size: 16px;
        font-weight: bold;
        color: ${props => props.theme.colors.white};
    }

    span {
        font-size: 16px;
        color: #fff;
    }
`;


export const SectionStage = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    background: ${props => props.theme.colors.spaceCadedDark};
    border-radius: 16px;
    padding: 32px 24px;
    gap: 40px;
    margin-top: 16px;
`;

export const Stage = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Title = styled.div`
    h2 {
        font-size: 14px;
        font-weight: bold;
        color: ${props => props.theme.colors.skyblue};
        line-height: 150%;
    }

    p {
        font-size: 14px;
        color: ${props => props.theme.colors.white};
        margin-top: 4px;
        line-height: 150%;
    }
`;

export const Code = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 120px;
    padding: 24px;
    margin-top: 24px;
    border-radius: 16px;

    background: #1B1D20;

    p {
        font-size: 14px;
        line-height: 150%;
        color: #CB3D9A;
    }

    textarea {
        cursor: pointer;
        width: 100%;
        height: 100%;
        outline: none;
        resize: none;
        border: none;
        background: transparent;
        font-size: 14px;
        line-height: 150%;
        color: #fff;

        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }

`;


/* STYLES EDIT */

export const HeaderEdit = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    margin-bottom: 8px;

    .buttons {
        display: flex;
        gap: 10px;

        .success {
            width: 100px;
        }
    }
`;

export const ContainerEdit = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    margin-bottom: 40px;
`;

export const ContentButtonMain = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-top: 8px;

    @media (max-width: 425px) {
        grid-template-columns: 1fr;
    }

`;

export const ContentButtonAction = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

`

export const ContentConfigChat = styled.div`
    display: grid;
    grid-template-columns: 1fr 448px;
    gap: 16px;

    @media (max-width: 425px) {
        grid-template-columns: 1fr;
    }
`;

export const ContentLeft = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const ContentRight = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;

    background: ${props => props.theme.colors.spaceCadedDark};
    border-radius: 16px;
    padding: 24px;
    gap: 16px;

    h1 {
        font-size: 16px;
        font-weight: bold;
        color: #34D5FA;
    }

    h2 {
        font-size: 16px;
        color: ${props => props.theme.colors.white};
    }

    h3 {
        font-size: 16px;
        font-weight: bold;
        color: ${props => props.theme.colors.white};
        margin-top: 24px;
    }

    .inputBorder {
        color: ${props => props.theme.colors.white};
    }

    .custom-file-input {
        color: transparent;
        transition: all .2s;

        &:hover {
            transform: scale(1.05);
        }
    }
    .custom-file-input::-webkit-file-upload-button {
        visibility: hidden;
    }
    .custom-file-input::before {
        content: 'Buscar no computador';
        color: #fff;
        display: inline-block;
        background: -webkit-linear-gradient(150deg, ${props => props.theme.colors.skyblue} 0%, ${props => props.theme.colors.blueCrayola} 100%);
        border: none;
        border-radius: 4px;
        padding: 12px 80px;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        cursor: pointer;
        font-weight: 700;
        font-size: 16px;
        text-align: center;
    }
    .custom-file-input:active {
        outline: 0;
    }
    
`;

export const SectionTitleCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const TitleRightCard = styled.div`
    display: flex;
    align-items: center;
    gap: 22px;
`;
