export const light = {
    title: 'light',
    colors: {
        white: '#5A646E',
        silverSand: '#C5CED7',
        lavanderGray: '#B6B6C5',
        cadetGrey: '#8A99A8',
        spaceCaded: '#F6F8FA',
        spaceCadedDark: '#FFFFFF',
        spaceCadedLight: '#FFFFFF',
        skyblue: '#34D5FA',
        blueCrayola: '#4A7DFF',
        stateBlue: '#7B61FF',
        turquoiseBlue: '#38FFE7',
        oceanGreen: '#28B2A2',
        oceanGreenLight: '#2FF0AB',
        yellowCrayola: '#F5A623',
        burntOrange: '#CC561E',
        lightCoral: '#FF868B',
        redPigment: '#E81E26',
        wine: '#CB3D9A',
        lightPurple: '#CECEEA',

        titleHeader: '#34D5FA',
        subTitle: '#5A646E',
        colorSwitch: '#FFFF',

        scrolltrack: '#EAEEF3',
        scrolltrackBar: '#C5CED7',

        bgChat: '#1F1F48',
        bgAreaChat: '#292755',
        bgChatOp: '#fff'
    }
}