import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Container } from './styles';

interface TableProps {
    width?: string;
    height?: string;
    overflowX?: boolean
    overflowY?: boolean
}

const TableSimple: React.FC<TableProps> = ({
    width = '100%',
    height = '325px',
    children,
    overflowX = true,
    overflowY = false,
}) => {
    return (
        <Container>
            { overflowX === true ?
                <Scrollbars
                    renderTrackVertical={() => <div className="scrolltrack" />}
                    style={{ width, height }}
                >
                    <table id="tableManagerTable" className="table-simple">{children}</table>
                </Scrollbars>
                :
                <table id="tableManagerTable" className="table-simple">{children}</table>
            }
        </Container>
    );
};

export default TableSimple;
