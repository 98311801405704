import React from 'react';
import { IQuestion } from '../../../../pages/GruposAtendimento/pesquisaSatisfacao';
import { ReactSortable } from 'react-sortablejs';
import InputDefault from '../../../form/InputDefault';
import InputRadioDefault from '../../../form/InputRadioDefault';
import InputSwitchDefault from '../../../form/InputSwitchDefault';
import InputTextarea from '../../../form/InputTextarea';
import SelectDefault from '../../../form/SelectDefault';
import {
    ButtonConfigQuestion,
    ButtonMove,
    ButtonNew,
    ButtonRemove,
    ContainerConfigsQuestion,
    ContainerQuestion,
    ContainerQuestionItem,
    ContainerQuestionRating,
    ContainerRating,
    TitleQuestion,
} from '../../../../pages/GruposAtendimento/pesquisaSatisfacao/styles';
import {
    IconCirclePlus,
    IconClone,
    IconClose,
    IconMoveSortable,
    IconTrash,
} from '../../../icons';
import CardContainer from '../../../layout/containers/CardContainer';

interface QuestionProps {
    indice: any;
    row: IQuestion;
    handleNewItem(indice: string, type: string): void;
    handleRemoveItem(indice: string, idItem: string): void;
    handleChangeItem(indice: string, indiceItem: number, any: string): void;
    handleChangeTypeQuestion(indice: string, type: string): void;
    handleChangeTitleQuestion(indice: string, title: string): void;
    handleChangeRatingQuestion(indice: string, name: any, value: any): void;
    handleRemoveQuestion(id: string): void;
    handleCloneQuestion(item: IQuestion): void;
    handleMoveQuestionItem(indice: string, items: any): void;
}

const QuestionEdit: React.FC<QuestionProps> = ({
    indice,
    row,
    handleNewItem,
    handleRemoveItem,
    handleChangeItem,
    handleChangeTypeQuestion,
    handleChangeTitleQuestion,
    handleChangeRatingQuestion,
    handleCloneQuestion,
    handleRemoveQuestion,
    handleMoveQuestionItem,
}) => {
    return (
        <CardContainer>
            <TitleQuestion>
                <InputDefault
                    className="input-title"
                    onChangeText={(value) =>
                        handleChangeTitleQuestion(indice, value)
                    }
                    value={row.title}
                />
                <SelectDefault
                    value={row.type}
                    onChangeText={(value) =>
                        handleChangeTypeQuestion(indice, value)
                    }
                >
                    <option value="checkbox">Multipla escolha</option>
                    <option value="radio">Resposta única</option>
                    <option value="rating">Avaliação</option>
                </SelectDefault>
            </TitleQuestion>

            {(row.type === 'checkbox' || row.type === 'radio') && (
                <ContainerQuestion>
                    <ButtonNew onClick={() => handleNewItem(indice, row.type)}>
                        <IconCirclePlus />
                        Adicionar opção
                    </ButtonNew>

                    <ReactSortable
                        list={row.items}
                        animation={200}
                        setList={(items) =>
                            handleMoveQuestionItem(indice, items)
                        }
                    >
                        {row.items.map((item, itemKey) => (
                            <ContainerQuestionItem key={itemKey}>
                                <ButtonMove>
                                    <IconMoveSortable />
                                </ButtonMove>

                                {item.type === 'checkbox' && (
                                    <InputSwitchDefault
                                        className="input-checkbox"
                                        onChange={() => {}}
                                        options={[
                                            {
                                                label: '',
                                                value: '',
                                            },
                                        ]}
                                        value={[item.value]}
                                    />
                                )}

                                {item.type === 'radio' && (
                                    <InputRadioDefault
                                        className="input-checkbox"
                                        onChange={() => {}}
                                        options={[
                                            {
                                                label: '',
                                                value: '',
                                            },
                                        ]}
                                        value=""
                                    />
                                )}

                                {(item.type === 'radio' ||
                                    item.type === 'checkbox') && (
                                    <InputDefault
                                        className="input-edit"
                                        value={item.value}
                                        onChangeText={(value) =>
                                            handleChangeItem(
                                                indice,
                                                itemKey,
                                                value
                                            )
                                        }
                                    />
                                )}

                                {item.type === 'textarea' && (
                                    <InputTextarea
                                        className="input-checkbox"
                                        onChangeText={(value) =>
                                            handleChangeItem(
                                                indice,
                                                itemKey,
                                                value
                                            )
                                        }
                                        value={item.value}
                                    />
                                )}

                                <ButtonRemove
                                    onClick={() =>
                                        handleRemoveItem(indice, item.id)
                                    }
                                >
                                    <IconClose />
                                </ButtonRemove>
                            </ContainerQuestionItem>
                        ))}
                    </ReactSortable>

                    <ButtonNew
                        onClick={() => handleNewItem(indice, 'textarea')}
                    >
                        <IconCirclePlus />
                        Adicionar caixa de texto
                    </ButtonNew>
                </ContainerQuestion>
            )}

            {row.type === 'rating' && (
                <ContainerQuestion>
                    <ContainerQuestionRating>
                        <ContainerRating>
                            <span>de:</span>
                            <InputDefault
                                value={row.min}
                                onChangeText={(value) =>
                                    handleChangeRatingQuestion(
                                        indice,
                                        'min',
                                        value
                                    )
                                }
                            />
                        </ContainerRating>

                        <ContainerRating>
                            <span>até:</span>
                            <InputDefault
                                value={row.max}
                                onChangeText={(value) =>
                                    handleChangeRatingQuestion(
                                        indice,
                                        'max',
                                        value
                                    )
                                }
                            />
                        </ContainerRating>
                    </ContainerQuestionRating>
                </ContainerQuestion>
            )}

            <ContainerConfigsQuestion>
                <ButtonConfigQuestion onClick={() => handleCloneQuestion(row)}>
                    <IconClone />
                </ButtonConfigQuestion>

                <ButtonConfigQuestion
                    onClick={() => handleRemoveQuestion(row.id)}
                >
                    <IconTrash />
                </ButtonConfigQuestion>
            </ContainerConfigsQuestion>
        </CardContainer>
    );
};

export default QuestionEdit;
