import styled from 'styled-components';
import BgModalOperator from '../../assets/images/BgModalOperator.png';
import { BackgroundDetail } from '../../assets/svg/BackgroundDetail';

export const Overlay = styled.div`
    background: ${({theme}) => theme.colors.spaceCaded};
    opacity: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;

export const BackgroundImage = styled.div`
    background: url(${BgModalOperator});
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;

    > svg {
        position: absolute;
        right: 0;
        bottom: 0;
    }
`;

export const BackgroundFrame = styled(BackgroundDetail)``;

export const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    margin: 0 auto;
    padding: 64px 0;
    max-width: 926px;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;

    width: 100%;
`;

export const Welcome = styled.div`
    display: flex;
    flex-direction: column;

    h1 {
        font-size: 32px;
        color: ${(props) => props.theme.colors.skyblue};

        span {
            font-size: 32px;
            color: ${(props) => props.theme.colors.white};
        }
    }

    span {
        font-size: 16px;
        color: ${(props) => props.theme.colors.white};
        margin-top: 32px;
    }
`;

export const Main = styled.div`
    display: flex;
    flex-direction: column;

    margin-top: 24px;

    width: 100%;

    h3 {
        font-size: 16px;
        margin: 20px 0 10px 0;
        color: ${(props) => props.theme.colors.white};
    }
`;

export const Sectionhours = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    margin-top: 40px;
`;

export const CardInfo = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    background: ${(props) => props.theme.colors.spaceCadedDark};
    border-radius: 16px;
    padding: 24px;

    span {
        font-size: 12px;
        color: ${(props) => props.theme.colors.lavanderGray};
    }

    h3 {
        font-size: 14px;
        color: ${(props) => props.theme.colors.white};
        margin-top: 2px;
    }
`;
