import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useRoutes } from '../../../contexts/RoutesContext';
import nextId from 'react-id-generator';

import { Container, Header } from './styles';
import QuestionEdit from '../../../components/pages/GruposAtendimento/pesquisa-satisfacao/QuestionEdit';
import apiTropa from '../../../services/apiTropa';
import ButtonLinear from '../../../components/form/ButtonLinear';
import { useMessage } from '../../../contexts/MessagesContext';
import { CardTitle, Content } from '../formularioDeEntrada/styles';
import InputDefault from '../../../components/form/InputDefault';
import InputTextarea from '../../../components/form/InputTextarea';
import Loading from '../../../components/Loading/Loading';
import BackPage from '../../../components/layout/UIElements/BackPage';
import SelectDefault from '../../../components/form/SelectDefault';
import ModalDefault from '../../../components/ModalDefault';
import Field from '../../../components/layout/containers/Field';
import FieldGroup from '../../../components/layout/containers/FieldGroup';
import InputDefaultColor from '../../../components/form/InputDefaultColor';
import InputColorUi from '../../../components/form/InputColorUi';

export interface IQuestion {
    id: any;
    title: string;
    type: 'checkbox' | 'rating' | 'radio';
    min?: number;
    max?: number;
    items: IQuestionItem[];
    corTitulo?: string;
    corDescricao?: string;
}

export interface IQuestionItem {
    id: any;
    value: string;
    type: 'radio' | 'checkbox' | 'textarea';
}

export default function PesquisaSatisfacaoEdit(props: any) {
    const history = useHistory();
    const { addDanger, addSuccess } = useMessage();
    const { setPageName } = useRoutes();
    const id = props.match.params.id;
    const [data, setData] = useState<any>({});
    const [load, setLoad] = useState(true)
    const [questions, setQuestions] = useState<IQuestion[]>([]);
    const [openModal, setOpenModal] = useState(false);
    const [newQuestion, setNewQuestion] = useState('checkbox')

    const [styleForm, setStyleForm] = useState({
        cor_titulo: '#7B61FF',
        cor_descricao: '#7B61FF',
    } as any);

    function handleStyles(name: any, value: any) {
        styleForm[name] = value;
        setStyleForm({ ...styleForm });
    }
    
    useEffect(() => {
        setPageName('Editar pesquisa de satisfação');
        async function getData() {
            const response: any = await apiTropa.get(
                'pesquisa-satisfacao/get-by-grupo/' + id
            );
            const newData = response.data.data[0];
            setData({ ...response.data.data[0] });
            setStyleForm({cor_titulo: newData.cor_titulo, cor_descricao: newData.cor_descricao})
            setQuestions([...newData.configuracao]);
            setLoad(false)
        }
        getData();
    }, []);

    interface IForm {
        title: string;
        description: string;
    }

    const [formInfo, setFormInfo] = useState<IForm>({
        title: 'Titulo da pesquisa',
        description: 'descrição',
    });

    const radioDefault: IQuestion = {
        id: nextId(),
        title: 'Pergunta de escolha única',
        type: 'radio',
        items: [],
    };

    const checkboxDefault: IQuestion = {
        id: nextId(),
        title: 'Pergunta de escolhas multiplas',
        type: 'checkbox',
        items: [],
    };

    const ratingDefault: IQuestion = {
        id: nextId(),
        title: 'Pergunta de avaliação',
        type: 'rating',
        min: 0,
        max: 10,
        items: [],
    };

    useEffect(() => {
        setQuestions([radioDefault, checkboxDefault, ratingDefault]);
    }, []);

    function handleNewItem(indice: any, type: any) {
        questions[indice].items.push({
            id: nextId(),
            type: type,
            value: 'Opção',
        });
        setQuestions([...questions]);
    }

    function handleRemoveItem(indice: any, idItem: string) {
        questions[indice].items = questions[indice].items.filter(
            (obj) => obj.id !== idItem
        );
        setQuestions([...questions]);
    }

    function handleChangeItem(indice: any, indiceItem: any, value: string) {
        questions[indice].items[indiceItem].value = value;
        setQuestions([...questions]);
    }

    function handleChangeTypeQuestion(indice: any, type: any) {
        questions[indice].type = type;

        if (type === 'radio') {
            questions[indice].items.map((row, key) => {
                if (row.type === 'checkbox')
                    questions[indice].items[key].type = type;
            });
        }

        if (type === 'checkbox') {
            questions[indice].items.map((row, key) => {
                if (row.type === 'radio')
                    questions[indice].items[key].type = type;
            });
        }

        if (type === 'rating') {
            questions[indice].min = 0;
            questions[indice].max = 10;
        }

        setQuestions([...questions]);
    }

    function handleChangeRatingQuestion(indice: any, name: any, value: any) {
        const newQuestion: any = questions;
        newQuestion[indice][name] = value;
        setQuestions([...newQuestion]);
    }

    function handleChangeTitleQuestion(indice: any, title: any) {
        questions[indice].title = title;
        setQuestions([...questions]);
    }

    function handleCloneQuestion(item: IQuestion) {
        if (item.type === 'checkbox') questions.push(checkboxDefault);
        if (item.type === 'radio') questions.push(radioDefault);
        if (item.type === 'rating') questions.push(ratingDefault);

        setQuestions([...questions]);
    }

    function handleRemoveQuestion(id: any) {
        setQuestions([...questions.filter((obj) => obj.id !== id)]);
    }

    function handleMoveQuestionItem(indice: any, items: any) {
        questions[indice].items = items;
        setQuestions([...questions]);
    }

    async function handleSave() {
        try {
            const response: any = await apiTropa.put(
                'pesquisa-satisfacao/update/' + data.id_pesquisa_satisfacao,
                {
                    id_grupo_atendimento: id,
                    pesquisa_satisfacao: data.pesquisa_satisfacao,
                    descricao: data.descricao,
                    configuracao: questions,
                    cor_titulo: styleForm.cor_titulo,
                    cor_descricao: styleForm.cor_descricao
                }
            );
            addSuccess('Confirmado', 'Alterações salvas com sucesso.');
        } catch (err: any) {
            addDanger('Atenção', err);
        }
    }

    function handleOnChange(name: any, value: any) {
        data[name] = value;
        setData({ ...data });
    }

    function handleNewQuestion(item: string) {
        if (item === 'checkbox') questions.push(checkboxDefault);
        if (item === 'radio') questions.push(radioDefault);
        if (item === 'rating') questions.push(ratingDefault);

        setQuestions([...questions]);

        setOpenModal(false)
        //addSuccess('Pesquisa', 'Nova pergunta criada com sucesso!!');
    }

    return (
        <>
            <Loading active={load}/>
            <Container>
                <Header>
                    <BackPage />

                    <div className='buttons'>

                        <ButtonLinear
                            className="info"
                            rounded={false}
                            onClick={() => setOpenModal(!openModal)}
                        >
                            Adicionar pergunta
                        </ButtonLinear>

                        <ButtonLinear onClick={() => history.goBack()}>
                            Cancelar
                        </ButtonLinear>
                        <ButtonLinear className="success" onClick={handleSave}>
                            Salvar
                        </ButtonLinear>

                    </div>
                </Header>

                <Content>

                    <CardTitle>
                        <FieldGroup>
                            <InputDefault
                                value={data.pesquisa_satisfacao}
                                onChangeText={(text) => handleOnChange('pesquisa_satisfacao', text)}
                                placeholder="Titulo do formulário"
                            />
                            
                            <InputColorUi
                                onChangeText={(value) =>
                                    handleStyles('cor_titulo', value)
                                }
                                value={styleForm.cor_titulo}
                            />

                        </FieldGroup>

                        <FieldGroup>
                            <InputTextarea
                                value={data.descricao}
                                onChangeText={(text) =>
                                    handleOnChange('descricao', text)
                                }
                                placeholder="Descrição"
                            >
                                {data.descricao}
                            </InputTextarea>

                            <InputColorUi
                                onChangeText={(value) =>
                                    handleStyles('cor_descricao', value)
                                }
                                value={styleForm.cor_descricao}
                            />

                        </FieldGroup>


                        {/* <FieldGroup>
                            <InputDefaultColor
                                id="textFinishedAttendence"
                                onChangeText={(value) =>
                                    handleStyles('corTitulo', value)
                                }
                                value={styleForm.corTitulo}
                                title="Finalizar atendimento"
                                isText
                            />

                            <InputColorUi
                                onChangeText={(value) =>
                                    handleStyles('corTitulo', value)
                                }
                                value={styleForm.corTitulo}
                            />

                        </FieldGroup> */}
                    </CardTitle>

                    {questions.map((row, key) => (
                        <QuestionEdit
                            key={key}
                            row={row}
                            indice={key}
                            handleNewItem={handleNewItem}
                            handleRemoveItem={handleRemoveItem}
                            handleChangeItem={handleChangeItem}
                            handleChangeTypeQuestion={handleChangeTypeQuestion}
                            handleChangeTitleQuestion={handleChangeTitleQuestion}
                            handleChangeRatingQuestion={handleChangeRatingQuestion}
                            handleCloneQuestion={handleCloneQuestion}
                            handleRemoveQuestion={handleRemoveQuestion}
                            handleMoveQuestionItem={handleMoveQuestionItem}
                        />
                    ))}
                </Content>
            </Container>

            <ModalDefault
                    id={'overlayModal'}
                    onClose={() => setOpenModal((openModal) => !openModal)}
                    openModal={openModal}
                >
                    <Field>
                        <SelectDefault
                            value={newQuestion}
                            onChangeText={(value) => setNewQuestion(value)}
                        >
                            <option value="checkbox">Multipla escolha</option>
                            <option value="radio">Resposta única</option>
                            <option value="rating">Avaliação</option>
                        </SelectDefault>
                    </Field>

                    <FieldGroup marginTop={'32px'}>
                        <ButtonLinear
                            type="button"
                            className="transparent"
                            onClick={() => setOpenModal((openModal) => !openModal)}
                        >
                            Cancelar
                        </ButtonLinear>

                        <ButtonLinear
                            type="button"
                            loading={false}
                            className="success"
                            onClick={() => handleNewQuestion(newQuestion)}
                        >
                            Salvar
                        </ButtonLinear>
                    </FieldGroup>
                </ModalDefault>
        </>
    );
}
