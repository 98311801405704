/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTheme } from 'styled-components';
import ButtonLinear from '../../../components/form/ButtonLinear';
import InputColorUi from '../../../components/form/InputColorUi';
import InputDefault from '../../../components/form/InputDefault';
import InputTextarea from '../../../components/form/InputTextarea';
import { IconSwitch, IconTrash } from '../../../components/icons';
import FieldGroup from '../../../components/layout/containers/FieldGroup';
import BackPage from '../../../components/layout/UIElements/BackPage';
import Loading from '../../../components/Loading/Loading';
import { useMessage } from '../../../contexts/MessagesContext';
import { useRoutes } from '../../../contexts/RoutesContext';
import apiTropa from '../../../services/apiTropa';

import {
    Container,
    Header,
    Content,
    CardTitle,
    CardForm,
    SectionEditForm,
    SectionInputs,
    SectionInputsSelected,
} from './styles';

export default function FormularioDeEntradaEdit(props: any) {
    const history = useHistory();
    const { addSuccess, addDanger } = useMessage();
    const { setPageName } = useRoutes();
    const {title} = useTheme()
    const id = props.match.params.id;
    const [data, setData] = useState<any>({});
    const [activeField, setActiveField] = useState<any[]>([]);
    const [load, setLoad] = useState(true)
    const [requireds, setRequireds] = useState<any>([])
    const [styleForm, setStyleForm] = useState({
        cor_titulo: '#7B61FF',
        cor_descricao: '#7B61FF',
    } as any);

    function handleStyles(name: any, value: any) {
        styleForm[name] = value;
        setStyleForm({ ...styleForm });
    }

    function handleButton(name: string) {
        if (activeField.filter((obj:any) => obj === name).length) {
            setActiveField([...activeField.filter((obj:any) => obj !== name)]);
        } else {
            const newActiveFilds: any = activeField;
            newActiveFilds.push(name);
            setActiveField([...newActiveFilds]);
        }
    }

    function handleButtonRequired(name: string) {

        if (requireds.filter((obj:any) => obj === name).length) {
            setRequireds([...requireds.filter((obj:any) => obj !== name)]);
        } else {
            const newActiveFilds: any = requireds;
            newActiveFilds.push(name);
            setRequireds([...newActiveFilds]);
        }
    }

    useEffect(() => {
        setPageName('Editar Formulário de entrada');
        async function getData() {
            const response: any = await apiTropa.get(
                'formulario-entrada/grupo-atendimento/' + id
            );
            const responseData: any = response.data.data[0];
            responseData.dados_formulario = JSON.parse(
                responseData.dados_formulario
            );

            if ( responseData.campos_obrigatorios ) {
                responseData.campos_obrigatorios = JSON.parse(
                    responseData.campos_obrigatorios
                );
                setRequireds(responseData.campos_obrigatorios);
            }

            setStyleForm({cor_titulo: responseData.cor_titulo, cor_descricao: responseData.cor_descricao})

            setActiveField(responseData.dados_formulario);
            setData({ ...responseData });
            setLoad(false)
        }
        getData();
    }, [id]);

    function handleOnChange(name: any, value: any) {
        data[name] = value;
        setData({ ...data });
    }

    async function saveEdicao() {
        try {
            const response = await apiTropa.put(
                'formulario-entrada/' + data.id_formulario_entrada,
                {
                    titulo: data.titulo,
                    descricao: data.descricao,
                    dados_formulario: activeField,
                    campos_obrigatorios: requireds,
                    cor_titulo: styleForm.cor_titulo,
                    cor_descricao: styleForm.cor_descricao
                }
            );
            addSuccess('Sucesso', 'Edição realizada!');
        } catch (err: any) {
            addDanger('Atenção', err);
        }
    }

    return (

        <>
            <Loading active={load}/>
            <Container>
                <Header>
                    <BackPage />
                    
                    <div className='buttons'>
                        <ButtonLinear onClick={() => history.goBack()}>
                            Cancelar
                        </ButtonLinear>
                        <ButtonLinear className="success" onClick={saveEdicao}>
                            Salvar
                        </ButtonLinear>
                    </div>
                </Header>

                <Content>
                    <CardTitle>
                        <FieldGroup>
                            <InputDefault
                                value={data.titulo}
                                onChangeText={(text) => handleOnChange('titulo', text)}
                                placeholder="Titulo do formulário"
                            />

                            <InputColorUi
                                onChangeText={(value) =>
                                    handleStyles('cor_titulo', value)
                                }
                                value={styleForm.cor_titulo}
                            />
                        </FieldGroup>

                        <FieldGroup>
                            <InputTextarea
                                value={data.descricao}
                                onChangeText={(text) =>
                                    handleOnChange('descricao', text)
                                }
                                placeholder="Descrição"
                            >
                                {data.descricao}
                            </InputTextarea>

                            <InputColorUi
                                onChangeText={(value) =>
                                    handleStyles('cor_descricao', value)
                                }
                                value={styleForm.cor_descricao}
                            />
                        </FieldGroup>

                    </CardTitle>

                    <SectionEditForm>
                        <CardForm>
                            <h2>Clique nos campos que deseja no seu formulário</h2>

                            <SectionInputs>
                                <ButtonLinear
                                    className={
                                        activeField.filter(
                                            (obj) => obj === 'Nome completo'
                                        ).length === 0
                                            ? 'config'
                                            : 'success'
                                    }
                                    color={activeField.filter((obj) => obj === 'Nome completo').length === 0
                                        ? title === 'light' ? '#8A99A8' : '#fff'
                                        : '#fff'
                                    }
                                    onClick={() => handleButton('Nome completo')}
                                >
                                    Nome completo
                                </ButtonLinear>
                                <ButtonLinear
                                    className={
                                        activeField.filter(
                                            (obj) => obj === 'E-mail'
                                        ).length === 0
                                            ? 'config'
                                            : 'success'
                                    }
                                    color={activeField.filter((obj) => obj === 'E-mail').length === 0
                                        ? title === 'light' ? '#8A99A8' : '#fff'
                                        : '#fff'
                                    }
                                    onClick={() => handleButton('E-mail')}
                                >
                                    E-mail
                                </ButtonLinear>
                                <ButtonLinear
                                    className={
                                        activeField.filter(
                                            (obj) => obj === 'Login'
                                        ).length === 0
                                            ? 'config'
                                            : 'success'
                                    }
                                    color={activeField.filter((obj) => obj === 'Login').length === 0
                                        ? title === 'light' ? '#8A99A8' : '#fff'
                                        : '#fff'
                                    }
                                    onClick={() => handleButton('Login')}
                                >
                                    Login
                                </ButtonLinear>
                                <ButtonLinear
                                    className={
                                        activeField.filter(
                                            (obj) => obj === 'Telefone'
                                        ).length === 0
                                            ? 'config'
                                            : 'success'
                                    }
                                    color={activeField.filter((obj) => obj === 'Telefone').length === 0
                                        ? title === 'light' ? '#8A99A8' : '#fff'
                                        : '#fff'
                                    }
                                    onClick={() => handleButton('Telefone')}
                                >
                                    Telefone
                                </ButtonLinear>
                                <ButtonLinear
                                    className={
                                        activeField.filter(
                                            (obj) => obj === 'Celular'
                                        ).length === 0
                                            ? 'config'
                                            : 'success'
                                    }
                                    color={activeField.filter((obj) => obj === 'Celular').length === 0
                                        ? title === 'light' ? '#8A99A8' : '#fff'
                                        : '#fff'
                                    }
                                    onClick={() => handleButton('Celular')}
                                >
                                    Celular
                                </ButtonLinear>

                                <ButtonLinear
                                    className={
                                        activeField.filter(
                                            (obj) => obj === 'Sexo'
                                        ).length === 0
                                            ? 'config'
                                            : 'success'
                                    }
                                    color={activeField.filter((obj) => obj === 'Sexo').length === 0
                                        ? title === 'light' ? '#8A99A8' : '#fff'
                                        : '#fff'
                                    }
                                    onClick={() => handleButton('Sexo')}
                                >
                                    Sexo
                                </ButtonLinear>
                                <ButtonLinear
                                    className={
                                        activeField.filter(
                                            (obj) => obj === 'CPF'
                                        ).length === 0
                                            ? 'config'
                                            : 'success'
                                    }
                                    color={activeField.filter((obj) => obj === 'CPF').length === 0
                                        ? title === 'light' ? '#8A99A8' : '#fff'
                                        : '#fff'
                                    }
                                    onClick={() => handleButton('CPF')}
                                >
                                    CPF
                                </ButtonLinear>
                                <ButtonLinear
                                    className={
                                        activeField.filter(
                                            (obj) => obj === 'CNPJ'
                                        ).length === 0
                                            ? 'config'
                                            : 'success'
                                    }
                                    color={activeField.filter((obj) => obj === 'CNPJ').length === 0
                                        ? title === 'light' ? '#8A99A8' : '#fff'
                                        : '#fff'
                                    }
                                    onClick={() => handleButton('CNPJ')}
                                >
                                    CNPJ
                                </ButtonLinear>
                                <ButtonLinear
                                    className={
                                        activeField.filter(
                                            (obj) => obj === 'Profissão'
                                        ).length === 0
                                            ? 'config'
                                            : 'success'
                                    }
                                    color={activeField.filter((obj) => obj === 'Profissão').length === 0
                                        ? title === 'light' ? '#8A99A8' : '#fff'
                                        : '#fff'
                                    }
                                    onClick={() => handleButton('Profissão')}
                                >
                                    Profissão
                                </ButtonLinear>

                                <ButtonLinear
                                    className={
                                        activeField.filter((obj) => obj === 'CEP')
                                            .length === 0
                                            ? 'config'
                                            : 'success'
                                    }
                                    color={activeField.filter((obj) => obj === 'CEP').length === 0
                                        ? title === 'light' ? '#8A99A8' : '#fff'
                                        : '#fff'
                                    }
                                    onClick={() => handleButton('CEP')}
                                >
                                    CEP
                                </ButtonLinear>
                                <ButtonLinear
                                    className={
                                        activeField.filter(
                                            (obj) => obj === 'Endereço'
                                        ).length === 0
                                            ? 'config'
                                            : 'success'
                                    }
                                    color={activeField.filter((obj) => obj === 'Endereço').length === 0
                                        ? title === 'light' ? '#8A99A8' : '#fff'
                                        : '#fff'
                                    }
                                    onClick={() => handleButton('Endereço')}
                                >
                                    Endereço
                                </ButtonLinear>
                                <ButtonLinear
                                    className={
                                        activeField.filter(
                                            (obj) => obj === 'Número'
                                        ).length === 0
                                            ? 'config'
                                            : 'success'
                                    }
                                    color={activeField.filter((obj) => obj === 'Número').length === 0
                                        ? title === 'light' ? '#8A99A8' : '#fff'
                                        : '#fff'
                                    }
                                    onClick={() => handleButton('Número')}
                                >
                                    Número
                                </ButtonLinear>
                                <ButtonLinear
                                    className={
                                        activeField.filter(
                                            (obj) => obj === 'Complemento'
                                        ).length === 0
                                            ? 'config'
                                            : 'success'
                                    }
                                    color={activeField.filter((obj) => obj === 'Complemento').length === 0
                                        ? title === 'light' ? '#8A99A8' : '#fff'
                                        : '#fff'
                                    }
                                    onClick={() => handleButton('Complemento')}
                                >
                                    Complemento
                                </ButtonLinear>
                                <ButtonLinear
                                    className={
                                        activeField.filter(
                                            (obj) => obj === 'Bairro'
                                        ).length === 0
                                            ? 'config'
                                            : 'success'
                                    }
                                    color={activeField.filter((obj) => obj === 'Bairro').length === 0
                                        ? title === 'light' ? '#8A99A8' : '#fff'
                                        : '#fff'
                                    }
                                    onClick={() => handleButton('Bairro')}
                                >
                                    Bairro
                                </ButtonLinear>
                                <ButtonLinear
                                    className={
                                        activeField.filter(
                                            (obj) => obj === 'Cidade'
                                        ).length === 0
                                            ? 'config'
                                            : 'success'
                                    }
                                    color={activeField.filter((obj) => obj === 'Cidade').length === 0
                                        ? title === 'light' ? '#8A99A8' : '#fff'
                                        : '#fff'
                                    }
                                    onClick={() => handleButton('Cidade')}
                                >
                                    Cidade
                                </ButtonLinear>
                                <ButtonLinear
                                    className={
                                        activeField.filter(
                                            (obj) => obj === 'Estado'
                                        ).length === 0
                                            ? 'config'
                                            : 'success'
                                    }
                                    color={activeField.filter((obj) => obj === 'Estado').length === 0
                                        ? title === 'light' ? '#8A99A8' : '#fff'
                                        : '#fff'
                                    }
                                    onClick={() => handleButton('Estado')}
                                >
                                    Estado
                                </ButtonLinear>
                            </SectionInputs>
                        </CardForm>

                        <CardForm>
                            <h2>Exclua clicando no campo</h2>
                            <SectionInputsSelected>
                                {activeField.map((row, key) => (
                                    <div
                                        className={'success item'}
                                        color="#fff"
                                        
                                        key={key}
                                    >
                                        <span>{row}</span>
                                        <div>
                                            <ButtonLinear
                                                Icon={IconSwitch}
                                                className="info"
                                                onClick={() => handleButtonRequired(row)}
                                            >
                                                { requireds.filter((obj:any) => obj === row).length ? 'Tornar opcional' : 'Tornar obrigatório' }
                                            </ButtonLinear>
                                            <ButtonLinear
                                                Icon={IconTrash}
                                                className="danger"
                                                onClick={() => handleButton(row)}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </SectionInputsSelected>
                        </CardForm>
                    </SectionEditForm>
                </Content>
            </Container>
        </>
    );
}
