import styled from 'styled-components';

interface Props {
    marginTop?: string;
    width?: string;
}

export default styled.div<Props>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: ${props => props.width ? props.width : '100%'};

    margin-top: ${props => props.marginTop};

    gap: 16px;

    label {
        width: 100%;
    }

    button {
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    button + button {
        margin-left: 16px;
    }

    @media(max-width: 425px) {
        flex-direction: column;
        label {
            width: 300px;
        }

        button + button {
            margin-left: 0;
        }
    }    
`;
