import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Chat from '../../../components/Chat/CardChat';
import CardMessage from '../../../components/Chat/CardMessage';
import ButtonConfig from '../../../components/form/ButtonConfig';
import ButtonLinear from '../../../components/form/ButtonLinear';
import { IconShape } from '../../../components/icons';
import BackPage from '../../../components/layout/UIElements/BackPage';
import Loading from '../../../components/Loading/Loading';
import { useMessage } from '../../../contexts/MessagesContext';
import { useRoutes } from '../../../contexts/RoutesContext';
import apiTropa from '../../../services/apiTropa';

import {
    Container,
    Header,
    Content,
    SectionDescriptionButton,
    PanelLeft,
    PreviewChat,
    PanelRight,
    ButtonAction,
    ButtonsAction,
    ButtonLeft,
    DescriptionButton,
    ButtonMain,
    SectionStage,
    Stage,
    Title,
    Code,
} from './styles';

export default function ConfigurarBotao(props: any) {
    const history = useHistory();
    const { addSuccess } = useMessage();
    const { setPageName, pageName } = useRoutes();
    const [load, setLoad] = useState(true)
    const [data, setData] = useState<any>({
        style: {},
        styleChat: {},
        styleButtonMain: {},
        styleButtonMainDark: {},
    });
    const [codeJS, setCodeJS] = useState('');
    const id = props.match.params.id;

    useEffect(() => {
        setPageName(pageName + ' Configurar botão do site');
        async function getData() {
            const response: any = await apiTropa.get(
                'botao-entrada/get-by-grupo/' + id
            );
            setCodeJS(response.data.data[0].codigo_js);
            setData({ ...response.data.data[0].configuracao_estilo });
            setLoad(false)
        }
        getData();
    }, [id]);

    function handleTester() {
        window.open(`/chat-testing/${codeJS}`, '_blank');
    }

    return (
        <>
                <Loading active={load}/>

            <Container>

                <Header>
                    <BackPage />

                    <ButtonLinear
                        className="info"
                        onClick={() =>
                            history.push(
                                '/grupos-de-atendimento/' +
                                    id +
                                    '/configurar-botao/edit'
                            )
                        }
                    >
                        Configurar
                    </ButtonLinear>
                </Header>

                <Content>
                    <SectionDescriptionButton>
                        <PanelLeft>
                            <PreviewChat>
                                <Chat
                                    onlyView={true}
                                    icon={data.styleChat.file}
                                    backgroundColor={data.styleChat.colorMain}
                                >
                                    <CardMessage
                                        messageType="enviada"
                                        hasView={true}
                                        backgroundEnviada={
                                            data.styleChat.backgroundEnviada
                                        }
                                        backgroundRecebida={
                                            data.styleChat.backgroundRecebida
                                        }
                                    >
                                        <p>exemplo</p>
                                        <span>11:43</span>
                                        <IconShape />
                                    </CardMessage>
                                    <CardMessage
                                        messageType="recebida"
                                        hasView={false}
                                        backgroundEnviada={
                                            data.styleChat.backgroundEnviada
                                        }
                                        backgroundRecebida={
                                            data.styleChat.backgroundRecebida
                                        }
                                    >
                                        <p>Outro Exemplo</p>
                                        <span>11:43</span>
                                        <IconShape />
                                    </CardMessage>
                                </Chat>
                            </PreviewChat>
                        </PanelLeft>

                        <PanelRight>
                            <ButtonAction>
                                <h1>Botões de ação</h1>

                                <ButtonsAction>

                                    { data.styleChat.theme === 'light' &&
                                        <ButtonLeft>
                                            <ButtonConfig styles={data.styleButtonMain}>
                                                Ações positivas
                                            </ButtonConfig>

                                            <DescriptionButton>
                                                <span>
                                                    Arredondamento:{' '}
                                                    {data.styleButtonMain.borderRadius}
                                                </span>
                                                <span>
                                                    Borda: {data.styleButtonMain.border}
                                                </span>
                                                <span>
                                                    Cor de fundo:{' '}
                                                    {
                                                        data.styleButtonMain
                                                            .backgroundColor
                                                    }
                                                </span>
                                                <span>
                                                    Cor da borda:{' '}
                                                    {data.styleButtonMain.borderColor}
                                                </span>
                                                <span>
                                                    Cor do texto:{' '}
                                                    {data.styleButtonMain.color}
                                                </span>
                                            </DescriptionButton>
                                        </ButtonLeft>
                                    }

                                    { data.styleChat.theme === 'dark' &&
                                        <ButtonLeft>

                                            <ButtonConfig
                                                styles={data.styleButtonMainDark}
                                            >
                                                Ações negativas
                                            </ButtonConfig>

                                            <DescriptionButton>
                                                <span>
                                                    Arredondamento:{' '}
                                                    {
                                                        data.styleButtonMainDark
                                                            .borderRadius
                                                    }
                                                </span>
                                                <span>
                                                    Borda:{' '}
                                                    {data.styleButtonMainDark.border}
                                                </span>
                                                <span>
                                                    Cor de fundo:{' '}
                                                    {
                                                        data.styleButtonMainDark
                                                            .backgroundColor
                                                    }
                                                </span>
                                                <span>
                                                    Cor da borda:{' '}
                                                    {
                                                        data.styleButtonMainDark
                                                            .borderColor
                                                    }
                                                </span>
                                                <span>
                                                    Cor do texto:{' '}
                                                    {data.styleButtonMainDark.color}
                                                </span>
                                            </DescriptionButton>
                                        </ButtonLeft>
                                    }

                                </ButtonsAction>
                            </ButtonAction>
                            <ButtonAction>
                                <h1>Botão principal Chat</h1>

                                <ButtonsAction>
                                    <ButtonLeft>
                                    {data.styleChat.icon === 'icon' ? (
                                        <div className="sectionButtonImageChat" style={{
                                            width: data.style.width,
                                            height: data.style.height,
                                            backgroundColor: data.style.backgroundColor,
                                            borderRadius: data.style.borderRadius,
                                            border: data.style.border + ' solid ' + data.style.borderColor,
                                            cursor: 'pointer',
                                        }}>
                                            <img className='buttonImageChat' src={data.styleChat.file} alt="Icone botao chat" />
                                        </div>
                                    ) : (
                                        <ButtonConfig
                                            styles={data.style}
                                            Icon={data.style.icone}
                                        >
                                            {data.style.textButton ? data.style.textButton : 'Iniciar atendimento'}
                                        </ButtonConfig>
                                    )}

                                        <DescriptionButton>
                                            <span>Largura: {data.style.width}</span>
                                            <span>Altura: {data.style.height}</span>
                                            <span>
                                                Arredondamento:{' '}
                                                {data.style.borderRadius}
                                            </span>
                                            <span>Borda: {data.style.border}</span>
                                            <span>
                                                Cor de fundo:{' '}
                                                {data.style.backgroundColor}
                                            </span>
                                            <span>
                                                Cor da borda:{' '}
                                                {data.style.borderColor}
                                            </span>
                                            <span>
                                                Cor do texto: {data.style.color}
                                            </span>
                                        </DescriptionButton>
                                    </ButtonLeft>
                                </ButtonsAction>
                            </ButtonAction>

                            <ButtonMain>
                               
                                    <ButtonConfig onClick={handleTester} styles={data.style}>
                                        Testar
                                    </ButtonConfig>

                                <span>
                                    Veja um exemplo de como ficaria em seu site.
                                </span>
                            </ButtonMain>
                        </PanelRight>
                    </SectionDescriptionButton>


                    <SectionStage>
                        <Stage>
                            <Title>
                                <h2>Instalação</h2>
                                <p>
                                    Inclua o SDK do JavaScript em sua página uma
                                    vez, de preferência logo após a tag de abertura
                                    do corpo.
                                </p>
                            </Title>
                            <Code>
                                <textarea
                                    id="embedScript"
                                    value={
                                        '<script id="script-eyou-omni" type="text/javascript" src="https://' +
                                        window.location.hostname +
                                        '/webchat.js?token=' +
                                        codeJS +
                                        '"></script>'
                                    }
                                />
                            </Code>
                        </Stage>
                    </SectionStage>
                </Content>
            </Container>
        </>

    );
}
