import styled from "styled-components"

interface InputProps {
    value?: string
}

export const Container = styled.div<InputProps>`
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #A9BFC8;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
    height: 48px;
    * {
        transition: all .2s;
    }
    span {
        width: 100%;
        position: absolute;
        padding: 0px 17px;
        color: #5A646E;
        z-index: 0;
        box-sizing:border-box;
        top: ${props => (props.value ? '6px' : '15px')};
        font-size: ${props => (props.value ? '10px' : '16px')};
    }
    .input-container {
        justify-content: space-between;
        display: flex;
        height: 100%;
        padding: 0px 16px;
        background: transparent;
        position: relative;
        z-index: 2;
        input {
            flex: 1;
            height: 100%;
            border: none;
            background: transparent;
            font-size: 16px;
            line-height: 24px;
            color: #5A646E;
            outline: none;
            padding-top: ${props => (props.value ? '5px' : '0px')};
        }
        button {
            right: 0px;
            position: absolute;
            height: 100%;
            display: flex;
            align-items: center;
            border: none;
            outline: none;
            background: transparent;
            cursor: pointer;
            padding: 0px 10px;
        }
    }
`