import { IconLoading } from '../../icons';
import { Container } from './styles';

interface ButtonProps {
    loading?: boolean;
    type?: 'submit' | 'button';
    children: string;
    style?: 'success' | 'info' | 'warning' | 'danger';
}

const ButtonDefault: React.FC<ButtonProps> = ({
    loading = false,
    children,
    type = 'submit',
    style = 'info',
}) => {
    return (
        <Container type={type} className={style}>
            {loading ? <IconLoading /> : <span>{children}</span>}
        </Container>
    );
};

export default ButtonDefault;
