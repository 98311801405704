import { useHistory } from 'react-router-dom'
import ButtonLinear from '../../form/ButtonLinear'

import { Container, Status, ButtonVizualisation } from './styles'

interface StatusProps {
  status: boolean;
  hasButton?: boolean;
  showLabel?: boolean;
}

export default function StatusTable({ status, hasButton, showLabel }: StatusProps) {
  const history = useHistory();

  return (
    <Container>
      <Status status={status}>
        {showLabel && <span>Status</span>}
        <span className="active">{status === true ? 'Ativo' : 'Inativo'}</span>
      </Status>

      { hasButton &&
        <ButtonVizualisation>
          <ButtonLinear
            rounded={false}
            onClick={() => history.push('grupos-de-atendimento/:id')}
          >
            Visualização
          </ButtonLinear>
        </ButtonVizualisation>
      }
    </Container>
  )
}
