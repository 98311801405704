import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const ButtonVizualisation = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    button + button {
        margin-left: 16px;
    }
`
