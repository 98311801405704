import styled, { css } from 'styled-components';

interface PopUpProps {
    popUpAttendence?: boolean;
    status?: string;
}

export const Container = styled.div<PopUpProps>`
    display: flex;
    flex-direction: column;
    z-index: 9;
    
    width: 400px;
    height: 450px;
    border-radius: 8px;

    background: ${(props) => props.theme.colors.spaceCaded};
    box-shadow: -13px 16px 15px #00000020;
    
    position: absolute;
    bottom: 58px;

    visibility: hidden;
    opacity: 0;

    transform: translate(-95%, 100%) scale(0);
    transform-origin: 100% 0%;
    transition: all 0.35s;

    overflow: hidden;
    > div {
        height: 450px;
    }
    ::-webkit-scrollbar {
        display: none;
    }

    ${(props) =>
        props.popUpAttendence &&
        css`
            visibility: visible;
            opacity: 1;
            transform: translate(0) scale(1);
        `}
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    > div {
        display: flex;
        align-items: center;
        svg {
            cursor: pointer;
        }
        h1 {
            margin-left: 10px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 290px;
        }
    }
    h1 {
        font-size: 24px;
        font-weight: bold;
        color: #5A646E;
    }

    padding-bottom: 23px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);

    .closePopUp {
        display: grid;
        place-items: center;

        border: none;
        background: transparent;
    }
`;