import styled, { css } from 'styled-components';

interface FileProps {
    active: boolean;
}

export const Container = styled.div<FileProps>`
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    transform: 
        translate(-50%, 100%)
        scale(0);

    transform-origin: 100% 0%;
    transition: all 0.35s;
    z-index: 15;

    ${props => props.active && css`
        visibility: visible;
        opacity: 1;
        transform: translate(0) scale(1);
    `}

    input {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 48px;
        height: 48px;

        background: transparent;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
    }
`;

export const ButtonImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 48px;
    height: 48px;

    border-radius: 50%;
    border: none;

    background: ${props => props.theme.colors.stateBlue};
    position: relative;
    cursor: pointer;
    input[type=file], /* FF, IE7+, chrome (except button) */
    input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
        cursor: pointer; 
    }
`;

export const ButtonVideo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 48px;
    height: 48px;

    border-radius: 50%;
    border: none;
    background: ${props => props.theme.colors.blueCrayola};
    position: relative;
    cursor: pointer;
    input[type=file], /* FF, IE7+, chrome (except button) */
    input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
        cursor: pointer; 
    }
`;
