import styled from 'styled-components'

export const Container = styled.div `
    .scrolltrack {
        position: absolute;
        width: 8px;
        right: 2px;
        bottom: 2px;
        top: 2px;
        border-radius: 100px;
        background: ${({theme}) => theme.colors.scrolltrackBar};
        > div {
            background:  ${({theme}) => theme.colors.scrolltrack} !important;
        }
    }
    table {
        width: calc(100% - 20px);
        margin-right: 20px;
        border-spacing: 0;
        thead {
            th {
                text-align:left;
                font-size: 14px;
                font-weight: bold;
                padding: 13px 0px;
                color: ${props => props.theme.colors.white};
                border-bottom: 1px solid #8A99A8;
                border-top: 1px solid #8A99A8;
            }
        }   
        tbody {
            td {
                text-align:left;
                font-size: 14px;
                padding: 13px 0px;
                color: ${props => props.theme.colors.white};
                border-bottom: 1px solid ${({theme}) => theme.title === 'dark' ? '#5A646E' : '#EAEEF3'};
            }
            tr:last-child {
                td {
                    border: none;
                }
            }
        }
        tfoot {
            td {
                text-align:left;
                font-size: 14px;
                padding: 13px 0px;
                color: ${props => props.theme.colors.white};
                border-top: 1px solid #5A646E;
            }

        }
    }
    @media (max-width: 900px) {
        table {
            width: 170%;
            thead {
                th {
                    padding: 13px 0px;
                }
            }   
            tbody {
                td {
                    padding: 13px 5px 13px 0;
                }
            }
        }
    }
    @media (max-width: 425px) {
        table {
            width: 215%;
            thead {
                th {
                    padding: 13px 2px;
                }
            }   
            tbody {
                td {
                    padding: 13px 5px 13px 0;
                    margin-right: 10px;
                }
            }
        }
    }
`