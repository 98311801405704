import moment from 'moment';
import { useState } from 'react';
import CardMessageRender from '../../Chat/CardMessageRender';
import { IconClose } from '../../icons';
import { 
    OverlayModalHistory,
} from './styles';

interface HistoryProps {
    handleOnclose: () => void;
    message?: any;
}

export default function CardMessageHistory({ handleOnclose, message }: HistoryProps) {
    const [messagePreLoad, setMessagePreLoad] = useState('');
    function handleQuote(message: any) {
        const newMessage: any = messagePreLoad + '[quote]' + message + '[/quote]';
        setMessagePreLoad(newMessage);
    }

    return (
        <OverlayModalHistory>
               <div className="history-container">
                    <div className="header">
                        <h2 className="titleHistory">Histórico</h2>
                        <button className="buttonCloseHistory" onClick={handleOnclose}>
                            <IconClose />
                        </button>
                    </div>
               </div>
      
                <div className="messages">
                    <div className="history-container">
                    {message.map((mensagem: any, key: any) => (
                            <>
                                {mensagem.tipo === 'alerta' ? (
                                    <div className="transferMessage">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            aria-hidden="true"
                                            role="img"
                                            width="1em"
                                            height="1em"
                                            preserveAspectRatio="xMidYMid meet"
                                            viewBox="0 0 24 24"
                                        >
                                            <g fill="none" stroke="currentColor" stroke-width="2">
                                                <circle cx="12" cy="12" r="10" />
                                                <path stroke-linecap="round" d="M12 7h.01" />
                                                <path stroke-linecap="round" strokeLinejoin="round" d="M10 11h2v5m-2 0h4" />
                                            </g>
                                        </svg>
                                        <div>
                                            <p>{mensagem.mensagem}</p>
                                            <span>{moment(mensagem.criado).utc().format('DD/MM/YYYY HH:mm')}</span>
                                        </div>
                                    </div>
                                ) : (
                                    mensagem.mensagem !== 'hiddenInitialMessage' && (
                                        <CardMessageRender
                                            key={key}
                                            data={mensagem}
                                            renderName={true}
                                            handleQuote={handleQuote}
                                            name={mensagem.id_fluxograma ? mensagem.chatbot : mensagem.apelido}
                                            messageType={mensagem.id_operador ? 'recebida' : 'enviada'}
                                            canalMessage={'whatsApp'}
                                        />
                                    )
                                )}
                            </>
                        ))}
                    </div>
                </div>
               
        </OverlayModalHistory>
    );
}
