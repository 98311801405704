/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useChatbotContext } from '../../contexts/ChatbotContext';
import ButtonLinear from '../form/ButtonLinear';
import SelectDefault from '../form/SelectDefault';
import { IconCirclePlus, IconPlusCircle, IconTrash } from '../icons';
import {
    ButtonTransparent,
    Container,
    ContainerConditionAll,
    ContainerConditionAllAnd,
    ContainerConditionAllIfItem,
    ContainerConditions,
    ContainerPadding,
    ContainerWhiteShadow,
    FieldIntegration,
    FieldIntegrationLabel,
} from './styles';

export default function IntegrationsEdit({}: any) {
    const { selected, setSelected, handleEditElement } = useChatbotContext();
    const refScroll = useRef<any>();
    const [methodAPI, setMethodAPI] = useState<string>('');
    const [urlAPI, setUrlAPI] = useState<string>('');

    const [methodHistory, setMethodHistory] = useState<string>('POST');
    const [urlHistory, setUrlHstory] = useState<string>('');

    const [methodMidleware, setMethodMidleware] = useState<string>('POST');
    const [urlMidleware, setUrlMidleware] = useState<string>('');

    const [headersAPI, setHeadersAPI] = useState<any[]>([]);
    const [bodyAPI, setBodyAPI] = useState<string>('');
    const [returnType, setReturnType] = useState<string>('json');
    const [variableBody, setVariableBody] = useState<string>('');
    const [variableStatus, setVariableStatus] = useState<string>('');
    const [errorJson, setErrorJson] = useState<boolean>(false);

    useEffect(() => {
        if (selected.params?.api) {
            setUrlAPI(selected.params.api.url);
            setMethodAPI(selected.params.api.method);
            setBodyAPI(selected.params.api.body);
            setVariableBody(selected.params.api.variableBody);
            setVariableStatus(selected.params.api.variableStatus);
            setUrlHstory(selected.params.historicoMensagem?.url);
            setMethodHistory(selected.params.historicoMensagem?.method);
            setUrlMidleware(selected.params.middlewareMensagem?.url);
            setMethodMidleware(selected.params.middlewareMensagem?.method);

            const newHeaders: any = [];

            try {
                Object.entries(selected.params.api.headers).map((row) => {
                    newHeaders.push({
                        key: row[0],
                        value: row[1],
                    });
                });
            } catch (err) {}

            setHeadersAPI(newHeaders);
        } else {
            setUrlAPI('');
            setMethodAPI('');
            setHeadersAPI([]);
            setBodyAPI('');
            setVariableBody('');
            setVariableStatus('');
            setUrlHstory('');
            setMethodHistory('POST');
            setUrlMidleware('');
            setMethodMidleware('POST');
        }
    }, [selected.id]);

    useEffect(() => {
        try {
            const newSelected: any = selected;
            const paramsApi: any = {
                method: methodAPI,
                url: urlAPI,
                headers: {},
                body: bodyAPI ? JSON.parse(bodyAPI) : '',
                returnType: returnType,
                variableBody: variableBody,
                variableStatus,
            };
            headersAPI.map((row, key) => {
                paramsApi.headers[row.key] = row.value;
            });
            newSelected.params.historicoMensagem = {
                url: urlHistory,
                method: methodHistory,
            };
            newSelected.params.middlewareMensagem = {
                url: urlMidleware,
                method: methodMidleware,
            };
            newSelected.params.api = paramsApi;
            handleEditElement(newSelected);
            setSelected({ ...newSelected });
        } catch (err) {
            console.log(err);
        }
    }, [
        methodAPI,
        urlAPI,
        headersAPI,
        bodyAPI,
        variableBody,
        variableStatus,
        methodHistory,
        urlHistory,
        methodMidleware,
        urlMidleware,
    ]);

    useEffect(() => {
        try {
            JSON.parse(bodyAPI);
            setErrorJson(false);
        } catch (err) {
            setErrorJson(true);
        }
    }, [bodyAPI]);

    function handleAddNewHeader() {
        headersAPI.push({
            key: '',
            value: '',
        });

        setHeadersAPI([...headersAPI]);
        onScrollDown();
    }

    function handleRemoveHeader(indice: any) {
        const newHeaders: any = [];
        headersAPI.map((row: any, key: any) => {
            if (key !== indice) newHeaders.push(row);
        });
        setHeadersAPI([...newHeaders]);
    }

    function handleOnChangeHeader(type: any, value: any, indice: any) {
        headersAPI[indice][type] = value;
        setHeadersAPI([...headersAPI]);
    }

    function onScrollDown() {
        setTimeout(() => {
            if (refScroll && refScroll.current) refScroll.current.scrollToBottom();
        }, 50);
    }

    return (
        <Container id="integrations-edit">
            <Scrollbars ref={refScroll}>
                <ContainerPadding>
                    {(selected.typeDialog === 'integration' || !selected.typeDialog) && (
                        <ContainerWhiteShadow>
                            <b>Requisição HTTP</b>
                            <p>a partir daqui, você consegue fazer requisições HTTP para qualquer API pública.</p>
                            <FieldIntegration>
                                <FieldIntegrationLabel>Método</FieldIntegrationLabel>
                                <SelectDefault
                                    placeholder="Selecione um método"
                                    value={methodAPI}
                                    onChangeText={(value) => setMethodAPI(value)}
                                >
                                    <option value="GET">GET</option>
                                    <option value="POST">POST</option>
                                    <option value="PUT">PUT</option>
                                    <option value="DELETE">DELETE</option>
                                </SelectDefault>
                            </FieldIntegration>

                            <FieldIntegration>
                                <FieldIntegrationLabel>URL</FieldIntegrationLabel>
                                <input
                                    value={urlAPI}
                                    onChangeCapture={(e: any) => setUrlAPI(e.target.value)}
                                    placeholder="ex: api.seusite.com.br"
                                />
                            </FieldIntegration>
                        </ContainerWhiteShadow>
                    )}

                    {(methodAPI === 'POST' || methodAPI === 'PUT') && (
                        <ContainerWhiteShadow style={{ marginTop: 10 }}>
                            <b>Payload body (POST)</b>
                            <FieldIntegration>
                                <ContainerConditionAll>
                                    <ContainerConditionAllIfItem>
                                        <textarea
                                            style={
                                                bodyAPI && errorJson === true
                                                    ? {
                                                          border: '3px solid red',
                                                      }
                                                    : {}
                                            }
                                            value={bodyAPI}
                                            onChangeCapture={(e: any) => setBodyAPI(e.target.value)}
                                        />
                                    </ContainerConditionAllIfItem>
                                </ContainerConditionAll>
                            </FieldIntegration>
                        </ContainerWhiteShadow>
                    )}

                    {(selected.typeDialog === 'integration' || !selected.typeDialog) && (
                        <ContainerConditions>
                            <ButtonLinear onClick={handleAddNewHeader} Icon={IconCirclePlus} className="info">
                                Adicionar cabeçalho
                            </ButtonLinear>
                        </ContainerConditions>
                    )}

                    {headersAPI.length > 0 && (
                        <ContainerWhiteShadow style={{ marginTop: 10 }}>
                            <b>Cabeçalhos</b>
                            <FieldIntegration>
                                {headersAPI.map((row, key) => (
                                    <ContainerConditionAll key={key}>
                                        <ContainerConditionAllIfItem>
                                            <input
                                                onChangeCapture={(e: any) =>
                                                    handleOnChangeHeader('key', e.target.value, key)
                                                }
                                                placeholder="Key, ex: Authorization"
                                                value={row.key}
                                            />
                                            <input
                                                onChangeCapture={(e: any) =>
                                                    handleOnChangeHeader('value', e.target.value, key)
                                                }
                                                placeholder="Value, ex: Barear token"
                                                value={row.value}
                                            />
                                        </ContainerConditionAllIfItem>
                                        <ButtonTransparent onClick={() => handleRemoveHeader(key)}>
                                            <IconTrash />
                                        </ButtonTransparent>
                                        {key !== headersAPI.length - 1 && <ContainerConditionAllAnd />}
                                    </ContainerConditionAll>
                                ))}

                                <ContainerConditionAllAnd onClick={handleAddNewHeader}>
                                    <IconPlusCircle />
                                </ContainerConditionAllAnd>
                            </FieldIntegration>
                        </ContainerWhiteShadow>
                    )}

                    {(selected.typeDialog === 'integration' || !selected.typeDialog) && (
                            <ContainerWhiteShadow style={{ marginTop: 10 }}>
                                <b>Salvar resposta</b>
                                <p>O retorno da api é salvo em uma "string".</p>
                                <FieldIntegration>
                                    <ContainerConditionAll>
                                        <ContainerConditionAllIfItem>
                                            <input
                                                style={{
                                                    display: 'none'
                                                }}
                                                onChangeCapture={(e: any) => setVariableStatus(e.target.value)}
                                                placeholder="Variavel para o status da resposta"
                                                value={variableStatus}
                                            />
                                            <input
                                                onChangeCapture={(e: any) => setVariableBody(e.target.value)}
                                                placeholder="Variavel para o corpo da resposta"
                                                value={variableBody}
                                            />
                                        </ContainerConditionAllIfItem>
                                    </ContainerConditionAll>
                                </FieldIntegration>
                            </ContainerWhiteShadow>
                        )}

                    <hr style={{ marginBottom: 10, opacity: 0.2 }} />

                    { selected.typeDialog !== 'integration' &&
                        <ContainerWhiteShadow>
                            <b>Histórico de mensagens</b>
                            <p>Todo o histórico é enviado em um payload para a URL preenchida.</p>
                            <FieldIntegration>
                                <FieldIntegrationLabel>Método</FieldIntegrationLabel>
                                <SelectDefault value={methodHistory} onChangeText={(value) => setMethodHistory(value)}>
                                    <option value="POST">POST</option>
                                </SelectDefault>
                            </FieldIntegration>

                            <FieldIntegration>
                                <FieldIntegrationLabel>URL</FieldIntegrationLabel>
                                <input
                                    value={urlHistory}
                                    onChangeCapture={(e: any) => setUrlHstory(e.target.value)}
                                    placeholder="ex: api.seusite.com.br"
                                />
                            </FieldIntegration>
                        </ContainerWhiteShadow>
                    }

                    <hr style={{ marginBottom: 10, opacity: 0.2 }} />

                    {selected.type === 'output' && (
                        <ContainerWhiteShadow>
                            <b>Middleware</b>
                            <p>
                                Toda vez que o chatbot for encaminhado para um atendimento humano, você pode configurar
                                uma URL que receberá todos os hooks de mensagens.
                            </p>
                            <FieldIntegration>
                                <FieldIntegrationLabel>Método</FieldIntegrationLabel>
                                <SelectDefault
                                    value={methodMidleware ?? ''}
                                    onChangeText={(value) => setMethodMidleware(value)}
                                >
                                    <option value="POST">POST</option>
                                </SelectDefault>
                            </FieldIntegration>

                            <FieldIntegration>
                                <FieldIntegrationLabel>URL</FieldIntegrationLabel>
                                <input
                                    value={urlMidleware ?? ''}
                                    onChangeCapture={(e: any) => setUrlMidleware(e.target.value)}
                                    placeholder="ex: api.seusite.com.br"
                                />
                            </FieldIntegration>
                        </ContainerWhiteShadow>
                    )}
                </ContainerPadding>
            </Scrollbars>
        </Container>
    );
}
