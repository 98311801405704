import { useEffect, useState } from 'react';
import FailedRegister from '../../../components/FailedRegister';
import ButtonLinear from '../../../components/form/ButtonLinear';
import SelectDefault from '../../../components/form/SelectDefault';
import { IconClose, IconConfig } from '../../../components/icons';
import Field, { Label } from '../../../components/layout/containers/Field';
import FieldGroup from '../../../components/layout/containers/FieldGroup';
import { Td, TdButtons, TdDescription, TdSubtitle } from '../../../components/layout/containers/Td';
import BackPage from '../../../components/layout/UIElements/BackPage';
import ModalDefault from '../../../components/ModalDefault';
import TableDefault from '../../../components/TableDefault';
import { useMessage } from '../../../contexts/MessagesContext';
import { useRoutes } from '../../../contexts/RoutesContext';
import apiTropa from '../../../services/apiTropa';
import Skeleton from 'react-loading-skeleton';
import { Container, Header, Content } from './styles';
import Loading from '../../../components/Loading/Loading';
import InputDefault from '../../../components/form/InputDefault';
import StatusTable from '../../../components/Table/StatusTable';

interface ICanalOptions {
    id_canal_atendimento: number
    canal: string
    configuracao: any[]
}

interface IChatbotOptions {
    id_chatbot: number
    chatbot: string
}

interface ICanaisDoGrupo {
    id_grupo_canal_atendimento: number
    id_canal_atendimento: number
    id_grupo_atendimento: number
    id_chatbot: number
    id_cliente: number
    canal: string
    chatbot: string
    conectado:number
    configuracao: any[]
    configuracao_canal?: IDTOConfiguracao[]
    configuracao_grupo?: IDTOConfiguracao[]
    criado: string
}

interface IDTOConfiguracao {
    field?: string;
    value?: string;
    label?: string;
    required?: boolean;
}

interface IDTO {
    id_grupo_canal_atendimento?: number;
    id_grupo_atendimento?: number;
    id_canal_atendimento?: number;
    id_chatbot?: number;
    configuracao?: IDTOConfiguracao[];
}

export default function ConfigCanal(props: any) {
    const id_grupo_atendimento = props.match.params.id
    const { setPageName } = useRoutes()
    const { addConfirm, addDanger, addSuccess } = useMessage()
    const [modal, setModal] = useState(false)
    const [modalQrCode, setModalQrCode] = useState(false)
    const [loading, setLoading] = useState(false)
    
    const [canalOptions, setCanalOptions] = useState<ICanalOptions[]>([])
    const [chatbotOptions, setChatbotOptions] = useState<IChatbotOptions[]>([])
    const [canaisDoGrupo, setCanaisDoGrupo] = useState<ICanaisDoGrupo[]>([])
    const [canalConfiguracao, setCanalConfiguracao] = useState<IDTOConfiguracao[]>([])
    const [DTO, setDTO] = useState<IDTO>({})
    const [currentQRCode, setCurrentQRCode] = useState<string>('')
    
    useEffect(() => {
        setLoading(true)
        setPageName('Configurar canais de atendimento')
        getData()
    }, [])

    useEffect(() => {

    }, [DTO])

    // useEffect(() => {
    //     const intervalHeart = setInterval(() => {
    //         if(canaisDoGrupo.length > 0){
                
    //              apiTropa.get('/canal-atendimento/wa-status-session').then(async function(response:any){
    //                 console.log(response.data?.data?.length,'length')
    //                 let channels :Array<any> = canaisDoGrupo
    //                 if(response.data?.data?.length > 0){
    //                     console.log(response.data?.data)
    //                     response.data?.data?.map((row: any) => {
    //                         if(row.id_grupo_canal_atendimento){
                               
    //                             console.log(channels,'teste')
    //                             if(channels.length > 0){
    //                                 channels.map(( canal:any,keyCanal:any) =>{
    //                                     console.log(canal)
    //                                     if(row.id_grupo_canal_atendimento === canal.id_grupo_canal_atendimento){
    //                                         console.log(row.conectado)
    //                                         console.log(canal.conectado)
    //                                         if(row.conectado !== canal.conectado){
    //                                             console.log('chegou aqui ?')
    //                                             getCanaisDoGrupo();
    //                                         }

    //                                         canal.conectado = row.conectado

    //                                         if(row.conectado === 1){
    //                                             setModalQrCode(false)
    //                                         }

    //                                         channels[keyCanal] = canal

    //                                     }
    //                                 })
    //                             }
                                
                                
                                
    //                         }
    //                     })
    //                     /*
    //                     response.data?.data?.map((row: any) => {
    //                         if(row.id_grupo_canal_atendimento){
    //                             canaisDoGrupo.map((canal) => {
                                    
    //                             })

                                
    //                         }
                            
    //                     })
    //                     */
    //                 }
    //                 setCanaisDoGrupo(channels)
    //             })
    //         }
            
    //     }, 60000);
    //     return () => clearInterval(intervalHeart);
    // }, [canaisDoGrupo]);



    function getData(){
        apiTropa.get(`canal-atendimento`).then((response:any) => {
            setCanalOptions(response.data.data)
            apiTropa.get(`chatbot`).then((response:any) => {
                setChatbotOptions(response.data.data)
                getCanaisDoGrupo()
            })
        })
    }

    function getCanaisDoGrupo(){

        setLoading(true)

        apiTropa.get('/canal-atendimento/wa-status-session').then(async function(response:any){
            apiTropa.get(`/canal-atendimento/grupo-atendimento/get/${id_grupo_atendimento}`).then((response:any) => {

                let finalresponse = JSON.parse(new Buffer(response.data.data, 'base64').toString('ascii'))

                setCanaisDoGrupo(finalresponse)
                setLoading(false)
            })

        })
    }

    function getConfiguracaoByField(field:any){
        if( DTO.configuracao ){
            let result = DTO.configuracao.find((row) => row.field === field)
            return result === undefined ? "" : result.value
        } else {
            return ""
        }
    }

    function onChangeDTO(key:any, value:any){
        const copy = DTO as any
        copy[key] = value
        setDTO({...copy})
    }

    function onChangeDTOConfiguracao(field:any, value:any){
        const copy = DTO as any
        
        if( !copy.configuracao.length ){
            copy.configuracao = []
        }

        if( copy.configuracao.find((item:any) => item.field === field) === undefined ){
            copy.configuracao.push({
                field: field,
                value: ""
            })
        }

        copy.configuracao.map((item:any) => {
            if( item.field === field ){
                item.value = value
            }
        })
        setDTO({...copy})
    }

    function onChangeCanal(value:any){
        onChangeDTO("id_canal_atendimento", value)
        const canalSelecionado = canalOptions.find((row) => {return String(row.id_canal_atendimento) === String(value)})

        setCanalConfiguracao([])
        if( canalSelecionado && canalSelecionado.configuracao ) setCanalConfiguracao([...canalSelecionado.configuracao])
    }

    function onClickAdicionar(){
        const newDTO = {
            id_grupo_canal_atendimento: 0,
            id_canal_atendimento: 0,
            id_chatbot: 0,
            configuracao: []
        }
        setDTO({...newDTO})
        setCanalConfiguracao([])
        setModal(true)
    }

    function onClickEditar(canal:ICanaisDoGrupo){
        const newDTO = {
            id_grupo_canal_atendimento: canal.id_grupo_canal_atendimento,
            id_canal_atendimento: canal.id_canal_atendimento,
            id_chatbot: canal.id_chatbot,
            configuracao: canal.configuracao_grupo
        }
        setDTO({...newDTO})
        setCanalConfiguracao([])
        if( canal.configuracao_canal ) setCanalConfiguracao([...canal.configuracao_canal])
        setModal(true)
    }

    async function onClickLerQRCode(canal: ICanaisDoGrupo){
        
        setLoading(true)

        const newDTO = {
            id_grupo_canal_atendimento: canal.id_grupo_canal_atendimento,
            id_canal_atendimento: canal.id_canal_atendimento,
            id_chatbot: canal.id_chatbot,
            configuracao: canal.configuracao_grupo
        }
        setDTO({...newDTO})

        setCanalConfiguracao([])
        if( canal.configuracao_canal ) setCanalConfiguracao([...canal.configuracao_canal])


        await apiTropa.post('/canal-atendimento/qr-code',{data: new Buffer(JSON.stringify(newDTO),'ascii').toString('base64')}).then((response:any) => {
            setModalQrCode(true)
            setCurrentQRCode(response.data.data)
        })

        setLoading(false)

    }

    async function onClickDesconectarQRCode(canal: ICanaisDoGrupo){
        setLoading(true)

        const newDTO = {
            id_grupo_canal_atendimento: canal.id_grupo_canal_atendimento,
            id_canal_atendimento: canal.id_canal_atendimento,
            id_chatbot: canal.id_chatbot,
            configuracao: canal.configuracao_grupo
        }
        setDTO({...newDTO})

        setCanalConfiguracao([])
        if( canal.configuracao_canal ) setCanalConfiguracao([...canal.configuracao_canal])

        await apiTropa.post('/canal-atendimento/qr-code-desconectar', {data: new Buffer(JSON.stringify(newDTO),'ascii').toString('base64')}).then((response:any) => {
            getCanaisDoGrupo()
        })

        setLoading(false)
    }

    async function salvarCanalNoGrupo(){
        try {
            
            setLoading(true)

            DTO.id_grupo_atendimento = id_grupo_atendimento
            
            if( DTO.id_grupo_canal_atendimento ){
                await apiTropa.put(`/canal-atendimento/grupo-atendimento/editar/${DTO.id_grupo_canal_atendimento}`, {data:new Buffer(JSON.stringify(DTO),'ascii').toString('base64')})
            } else {
                await apiTropa.post(`/canal-atendimento/grupo-atendimento`, {data: new Buffer(JSON.stringify(DTO),'ascii').toString('base64')})
            }

            setModal(false)
            setDTO({})
            addSuccess("Confirmado", "Canal salvo")
            getCanaisDoGrupo()
        } catch(err:any){
            if( err.response ){
                addDanger("Atenção", String(err.response.data.message))
            } else {
                addDanger("Atenção", String(err))
            }
            setLoading(false)
        }
    }

    async function removerCanalDoGrupo(id_grupo_atendimento:any, id_canal_atendimento:any){
        try {
            setLoading(true)
            await apiTropa.delete(`/canal-atendimento/grupo-atendimento`, {
                data: {
                    id_grupo_atendimento: id_grupo_atendimento,
                    id_canal_atendimento: id_canal_atendimento
                }
            })
            addSuccess("Confirmado", "Canal removido")
            getCanaisDoGrupo()
        } catch(err:any){
            if( err.response ){
                addDanger("Atenção", String(err.response.data.message))
            } else {
                addDanger("Atenção", String(err))
            }
            setLoading(false)
        }
    }

    function fecharQrCode(){
        setModalQrCode(false)
        getCanaisDoGrupo()
    }

    return (
    <>
        <Loading active={loading} />

        <Container>

            <Header>
                <BackPage />

                <ButtonLinear className="info" onClick={() => onClickAdicionar()}>
                    Adicionar canal
                </ButtonLinear>
            </Header>

            <Content>
            { loading === true ? 
                <Skeleton
                    count={1}
                    className="skeletor"
                    height="80px"
                    borderRadius="16px"
                    baseColor="#262655"
                    highlightColor="#3e3b77"
                />  
            :
                <>
                    {!canaisDoGrupo.length && <FailedRegister />}
                
                    {canaisDoGrupo.map((row, key) => (
                    <TableDefault key={key} columns={5}>
                        <Td flexDirection="column">
                            <TdDescription>Canal</TdDescription>
                            <TdSubtitle>{row.canal}</TdSubtitle>
                        </Td>
                        <Td flexDirection="column">
                            <TdDescription>Chatbot</TdDescription>
                            <TdSubtitle>{row.chatbot ? row.chatbot : "Nenhum chatbot vinculado"}</TdSubtitle>
                        </Td>
                        {
                            row.id_canal_atendimento !== 5 ?
                                <>
                                    <Td flexDirection="column">
                                        <TdDescription></TdDescription>
                                        <TdSubtitle></TdSubtitle>
                                    </Td> 
                                        <Td flexDirection="column">
                                        <TdDescription></TdDescription>
                                        <TdSubtitle></TdSubtitle>
                                    </Td> 
                                </>
                            :
                                <>
                                    <Td flexDirection="column">
                                        <TdDescription>Status da Conexão</TdDescription>
                                        <TdSubtitle>
                                            <StatusTable
                                                hasButton={false}
                                                status={row.conectado == 1 ? true : false}
                                            />
                                        </TdSubtitle>
                                    </Td> 

                                    <Td flexDirection="column">
                                        {
                                            row.conectado == 1 ?
                                            <>
                                                <TdDescription>Desconectar</TdDescription>
                                                <TdSubtitle>
                                                    <ButtonLinear
                                                        onClick={() => onClickDesconectarQRCode(row)}
                                                        
                                                    >Desconectar</ButtonLinear>
                                                </TdSubtitle>
                                            </>
                                            :
                                            <>
                                                <TdDescription>Ler QR Code</TdDescription>
                                                <TdSubtitle>
                                                    <ButtonLinear
                                                        onClick={() => onClickLerQRCode(row)}
                                                        
                                                    >Ler QR Code</ButtonLinear>
                                                </TdSubtitle>
                                            </>
                                        }
                                        
                                    </Td> 
                                </>
                        }
                        <Td flexDirection="column" style={{ alignItems: 'flex-end' }}>
                            <TdButtons>
                                <ButtonLinear
                                    Icon={IconConfig}
                                    onClick={() => onClickEditar(row)}
                                />

                                <ButtonLinear
                                    Icon={IconClose}
                                    onClick={() =>
                                        addConfirm({
                                            title: 'Atenção',
                                            description: 'Certeza que gostaria de remover esse canal de atendimento?',
                                            textButtonConfirm: 'Remover',
                                            textButtonCancel: 'Cancelar',
                                            onConfirm: () => removerCanalDoGrupo(row.id_grupo_atendimento, row.id_canal_atendimento),
                                            onCancel: () => {},
                                        })
                                    }
                                />
                            </TdButtons>
                        </Td>
                    </TableDefault>
                    ))}
                </>
            }
            </Content>

            <ModalDefault  id="overlayModal" onClose={() => setModal(!modal)} openModal={modal}>
                <form onSubmit={salvarCanalNoGrupo} style={{width: '100%'}}>
                    <Field>
                        <Label>Selecionar canal</Label>
                        <SelectDefault
                            value={DTO.id_canal_atendimento}
                            placeholder="Selecionar canal"
                            onChangeText={(value) => onChangeCanal(value)}
                        >
                            {canalOptions.map((row, key) => (
                                <option 
                                    key={key} 
                                    value={row.id_canal_atendimento} 
                                    disabled={canaisDoGrupo.find((canal) => canal.id_canal_atendimento === row.id_canal_atendimento) !== undefined}
                                >
                                    {row.canal}
                                </option>
                            ))}
                        </SelectDefault>
                    </Field>

                    {canalConfiguracao.length > 0 &&
                    <Field marginTop="15px" gap='10px'>

                        {canalConfiguracao.map((row, key) => (
                            <>
                                <Label>{row.label ?? row.field}{" "}{row.required && '*' }</Label>
                                <InputDefault
                                    key={key} 
                                    value={getConfiguracaoByField(row.field)}
                                    placeholder={row.field}
                                    type={row.field === 'senha' ? 'password' : 'text'}
                                    onChangeText={(value) => onChangeDTOConfiguracao(row.field, value)}
                                    required={row.required}
                                />
                            </>
                        ))}
                        
                    </Field>
                    }
                    {
                        Number(DTO.id_canal_atendimento) === 1 || Number(DTO.id_canal_atendimento) === 2 || Number(DTO.id_canal_atendimento) === 5 ? 
                        <Field marginTop="15px">
                            <Label>Selecionar chatbot</Label>
                            <SelectDefault
                                value={DTO.id_chatbot}
                                placeholder="Selecionar chatbot"
                                onChangeText={(value) => onChangeDTO("id_chatbot", value)}
                            >
                                {chatbotOptions.map((row, key) => (
                                    <option key={key} value={row.id_chatbot}>
                                        {row.chatbot}
                                    </option>
                                ))}
                            </SelectDefault>
                        </Field>
                        :""
                    }
                    

                    <FieldGroup style={{ marginTop: '32px' }}>
                        <ButtonLinear type='button' onClick={() => setModal(false)}>
                            Cancelar
                        </ButtonLinear>
                        <ButtonLinear className="success" type='submit'>
                            Salvar
                        </ButtonLinear>
                    </FieldGroup>
                </form>
            </ModalDefault>



            <ModalDefault id="overlayModalQrCode" width={'500px'} onClose={() => setModalQrCode(!modalQrCode)} openModal={modalQrCode}>
                <form onSubmit={salvarCanalNoGrupo} style={{width: '100%'}}>
                    <Field>
                        <Label>Ler QR Code</Label>
                        <div style={{width:"450px", height:'450px'}}>
                            <img style={{width:'100%', height:'100%'}} src={currentQRCode}/>
                        </div>
                    </Field>

                    <FieldGroup style={{ marginTop: '32px' }}>
                        <Field>
                            <Label>Após Escanear o QR Code clique em Fechar</Label>
                        </Field>
                        <Field>
                            <ButtonLinear className="success" type='button' onClick={() => fecharQrCode()}>
                                Fechar
                            </ButtonLinear>
                        </Field>
                    </FieldGroup>
                </form>
            </ModalDefault>

        </Container>
    </>
    )
}
