import React, { HtmlHTMLAttributes, useEffect, useRef, useState } from 'react';
import ButtonLinear from '../form/ButtonLinear';
import InputSwitchDefault from '../form/InputSwitchDefault';
import { IconArrow, IconEye, IconEyeOpen } from '../icons';
import Paginator from '../Paginator';
import TableSimple from '../TableSimple';
import { Container, ContainerColumns, ContainerTable, ContainerTableRow } from './styles';

interface IProps {
    title?: string
    headers: any[];
    content: any[];
    submited?: boolean
    type?: string
    pagination?: any,
    page?:number
    setPage?(page:number):void
    tableRef?: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null | undefined
    headersVisible: any;
    setHeadersVisible: (value: any) => void
}

export default function TableManager({ setHeadersVisible, headersVisible, title, headers = [], tableRef, content = [], submited = false, type = "", page = 0, setPage, pagination = {
    totalDocs: 0,
    totalPage: 0,
    page: 1
}}: IProps) {
    
    // const [headersVisible, setHeadersVisible] = useState<any[]>([]);
    const [orderBy, setOrderBy] = useState<any>({})
    const [data, setData] = useState<any[]>([])

    // useEffect(() => {
    //     const newHeadersVisibile:any = []
    //     headers.map((row, key) => {
    //         newHeadersVisibile.push(row.value)
    //     })
    //     setHeadersVisible(newHeadersVisibile)
    // }, [headers]);

    useEffect(() => {
        setData(content)
    }, [content])

    function handleOrderItem(item:any)
    {

        let newOrderBy:any = {}

        if ( item.value === orderBy.value && orderBy.order === 'desc' ) {
            newOrderBy = {
                label: item.label,
                value: item.value,
                order: 'asc',
            }
        } else if ( item.value === orderBy.value && orderBy.order === 'asc' ) {
            newOrderBy = {
                label: item.label,
                value: item.value,
                order: 'desc',
            }
        } else {
            newOrderBy = {
                label: item.label,
                value: item.value,
                order: 'desc',
            }
        }

        if ( newOrderBy.order === 'asc' ) {
            newData = data.sort((a, b) => a[newOrderBy.value] > b[newOrderBy.value] ? - 1 : Number(a[newOrderBy.value] < b[newOrderBy.value]))
        } else {
            var newData = data.sort((a, b) => a[newOrderBy.value] < b[newOrderBy.value] ? - 1 : Number(a[newOrderBy.value] > b[newOrderBy.value]))
        }

        setData([...newData])
        setOrderBy({...newOrderBy})

    }

    const ContainerSubTotal = ({data}:any) => {

        const subtotais:any = []
        headers.map((row:any, key:any) => {
            subtotais[key] = {
                label: row.value,
                value: key === 0 ? 'TOTAL' : 0
            }
        })

        subtotais.map((row:any, key:number) => {
            data.map((dataRow:any) => {
                
                if (subtotais[key].label === "agrupamento") {
                    subtotais[key].value = "TOTAL"
                }
                else if ( subtotais[key].value >= 0 ) {
                    subtotais[key].value = parseFloat(subtotais[key].value) + parseFloat(dataRow[row.label])
                } 
                
                else if (subtotais[key].label === 'canal') {
                    subtotais[key].value = "--"
                }
          
            })
        })

        return(
            <tfoot>
                <tr>
                    { subtotais.map((row:any, key:any) => headersVisible.filter((obj:any) => obj === row.label).length > 0 &&
                        <td key={key}>
                            <span className="subtotal valueRow copyy">{row.value}</span>
                        </td>
                    )}
                </tr>
            </tfoot>
        )
    }

    return (
        <Container id="tableManager" style={submited ? {display: 'block'} : {display: 'none'}}>

            { title && 
                <p className="subtitle">{title}</p>
            }

            <ContainerTableRow>
                <ContainerColumns id="tableManagerColumns">
                    {headers.map((row, key) => (
                        <InputSwitchDefault
                            options={[
                                {
                                    label: row.label,
                                    value: row.value,
                                }
                            ]}
                            value={headersVisible}
                            onChange={(value) => setHeadersVisible(value)}
                        />
                    ))}
                </ContainerColumns>

                <ContainerTable ref={tableRef}>
                    <TableSimple overflowY={true} overflowX={false}>
                        <thead>
                            <tr>
                                {headers.map((row, key) => headersVisible.filter((obj:any) => obj === row.value).length > 0 && (
                                    <th>
                                        <span onClick={() => handleOrderItem(row)} className={`headname copyy ${orderBy.value === row.value && orderBy.order === 'desc' ? 'active' : 'inactive'}`}>
                                            <span>{row.label}</span>
                                            <IconArrow />
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        </thead>

                        { data.length > 0 && 
                            <tbody>
                                {data.map((row, key) => (
                                    <tr key={key}>
                                        { headers.map((head) => headersVisible.filter((obj:any) => obj === head.value).length > 0 &&
                                            <td>
                                                {head.button !== true ? 
                                                    <>
                                                        { (type === 'sintetico' || type === 'sinteticoGeral') && row[head.value] >= 0 ? 
                                                            <span className="valueRow subtotal copyy">
                                                                {row[head.value]}
                                                            </span>
                                                        :
                                                            <span className="valueRow copyy">
                                                                {row[head.value]}
                                                            </span>
                                                        }
                                                    </>
                                                : 
                                                <>
                                                    {head?.type === 'pesquisa' ? (
                                                        <ButtonLinear
                                                            type='button'
                                                            onClick={() => head.function(row.configuracao, row.resposta)}
                                                            Icon={IconEyeOpen}
                                                            className="info"
                                                        >
                                                            Pesquisa
                                                        </ButtonLinear>

                                                    ) : (
                                                        <ButtonLinear
                                                            type='button'
                                                            onClick={() => head.function(row.id_atendimento)}
                                                            Icon={IconEyeOpen}
                                                            className="info"
                                                        >
                                                            Visualizar
                                                        </ButtonLinear>
                                                    )}
                                                </>
                                                }
                                             
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        }

                        { data.length === 0 && 
                            <tbody>
                                <tr>
                                    <td colSpan={headersVisible.length}>
                                        <span className='copyy'>Nenhum registro encontrado.</span>
                                    </td>
                                </tr>
                            </tbody>
                        }

                        { data.length > 0 && (type === 'sintetico' || type === 'sinteticoGeral') &&
                            <ContainerSubTotal
                                data={data}
                            />
                        }
                    </TableSimple>

                    { setPage && 
                        <Paginator
                            page={page}
                            setPage={setPage}
                            range={3}
                            totalDocs={pagination?.totalDocs}
                            totalPage={pagination?.totalPage}
                        />
                    }

                </ContainerTable>
            </ContainerTableRow>
        </Container>
    );
}
