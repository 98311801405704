import { useEffect, useState } from 'react';
import {
    IconMail,
    IconPhone,
} from '../../components/icons';
import { Logo } from '../../assets/svg/Logo';
import InputLogin from '../../components/form/InputLogin';
import ButtonDefault from '../../components/form/ButtonDefault';
import { Container } from './styles';
import { useMessage } from '../../contexts/MessagesContext';
import apiEyou from '../../services/apiEyou';
import { useHistory } from 'react-router-dom';

export default function Recover(props:any) {
    const history = useHistory()
    const { addDanger, addSuccess } = useMessage();
    const [loading, setLoading] = useState<boolean>(false);
    const [passwordConfirm, setPasswordConfirm] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showEffect, setShowEffects] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowEffects(true);
        }, 300);
    }, [showEffect]);

    async function handleSubmit(e: React.FormEvent) {
        try {
            e.preventDefault();
            setLoading(true);

            if ( password !== passwordConfirm ) throw "Senhas não conferem."
            
            const response:any = await apiEyou.post('apiRecoveryPass', {
                type: 'get',
                function: 'setNewPass',
                data: {
                    token: props.location.search.replace('?tokenPass=', ''),
                    password: password,
                    passwordConf: passwordConfirm
                }
            })

            if ( response.data.data[0].error === true ) throw response.data.data[0].error

            setLoading(true)

            addSuccess('Confirmado','Sua senha foi alterada com sucesso.')
            history.push('/')

        } catch (err) {
            console.log(err);
            addDanger('Falha no Login', 'Usuario ou senha inválidos');
            setLoading(false);
        }
    }

    return (
        <Container effect={showEffect}>
            <header>
                <a href="/">
                    <IconMail />
                    <span>contato@eyou.com.br</span>
                </a>

                <a href="/">
                    <IconPhone />
                    <span>+55 11 3568-2843</span>
                </a>
            </header>
            <section>
                <div className="left-panel">
                    <div className="center-form animate__animated animate__fadeIn">
                        <Logo />

   
                        <h1>
                            Recuperar <span>senha</span>
                        </h1>

                        <form onSubmit={handleSubmit}>
                            <InputLogin
                                placeholder="Nova senha"
                                type="password"
                                value={password}
                                onChangeText={(value) => setPassword(value)}
                            />
                            <InputLogin
                                placeholder="Confirme a nova senha"
                                type="password"
                                value={passwordConfirm}
                                onChangeText={(value) => setPasswordConfirm(value)}
                            />
                            <ButtonDefault loading={loading}>
                                Acessar
                            </ButtonDefault>

                        </form>
                        
                        
                    </div>
                </div>
                <div className="right-panel">
                    <div className="bg animate__animated animate__fadeIn">
                        <div className="message animate__animated animate__zoomIn" />
                        <div className="text animate__animated animate__zoomIn" />
                        <div className="products animate__animated animate__zoomIn" />
                        <div className="person animate__animated animate__fadeInLeft" />
                    </div>
                </div>
            </section>
        </Container>
    );
}
