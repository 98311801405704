import { useEffect, useState } from 'react';

import { CSVLink } from "react-csv"
import { Container } from './styles';
import { IconDownload } from '../../icons';

interface IProps {
    filename: string
    headers: any[]
    data: []
}


export default function ButtonDownload({filename, headers, data}:IProps){
    const [headersButton, setHeadersButton] = useState<any>([])
    const [dataButton, setDataButton] = useState<any>([])
    
    useEffect(() => {

        

        const newHeaders:any = []
        headers.map((row:any) => {
            newHeaders.push({
                label: row,
                key: row
            })
        })
        setHeadersButton([...newHeaders])
        setDataButton([...data])

        console.log(headers, 'myHeaders')
    }, [headers, data])

    return (
        <Container>
            <CSVLink separator=';'  filename={filename} headers={headersButton} data={dataButton}>
                <IconDownload/>
            </CSVLink>
        </Container>
    )

}