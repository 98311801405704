import React from 'react'
import { useHistory } from 'react-router-dom'
import { Operador } from '../../../types/Operador'
import ButtonLinear from '../../form/ButtonLinear'
import { IconDelete } from '../../icons'

import { Container, ButtonVizualisation } from './styles'

interface Props {
  onDelete(): void;
  operador: Operador;
}


export default function ConfigOperator({ onDelete, operador }: Props) {
  const history = useHistory();

  return (
    <Container>
      <ButtonVizualisation>
        <ButtonLinear
          rounded={false}
          onClick={() => history.push(`operadores/${operador.id_operador}`)}
          // onClick={() => history.push(`/usuarios/visualizar/${operador.dadosEyou?.login}`)}
        >
          Configurar
        </ButtonLinear>

        <ButtonLinear
          className="delete" Icon={IconDelete}
          onClick={onDelete}
        />
      </ButtonVizualisation>
    </Container>
  )
}
