import React, { useEffect, useState } from 'react';
import { IconClock } from '../../icons';
import InputMask from 'react-input-mask';

import { Container } from './styles';

interface InputProps extends React.HTMLAttributes<HTMLInputElement> {
    placeholder?: string;
    mask?: any;
    value: string;
    onChangeText: (value: string) => void;
}

const InputDefaultTime: React.FC<InputProps> = ({
    placeholder,
    value,
    mask = "99:99:99",
    onChangeText,
    ...rest
}) => {
    const [valueInput, setValueInput] = useState<string>(value);

    useEffect(() => {
        setValueInput(value);
    }, [value]);

    function handleOnChange(e: React.FormEvent<HTMLInputElement>) {
        setValueInput(e.currentTarget.value);
        onChangeText(e.currentTarget.value);
    }

    function handleOnBur()
    {
        const hours = valueInput.replaceAll('_', '').replaceAll(':', '')
        if ( hours.length < 4 ) {
            setValueInput('10:00')
        }
    }

    return (
        <Container className="form-children-default">
            <IconClock />
            <InputMask
                type="tel"
                mask={mask}
                value={valueInput}
                onChange={handleOnChange}
                onBlur={handleOnBur}
                placeholder={placeholder}
                {...rest}
            />
        </Container>
    );
};

export default InputDefaultTime;
