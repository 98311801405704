import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
    
    @import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

    //global styles
    * {
        font-family: 'Ubuntu', sans-serif;
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }
    html {
        height: 100%;

        @media (max-width: 1080px) {
        font-size: 93.75%; // 15px
        }
        @media (max-width: 720px) {
            font-size: 87.5%; // 14px
        }
    }
    body {
        margin: 0px;
        padding: 0px;
        height: 100%;
        background: ${props => props.theme.colors.spaceCaded};
    }

    button {
        cursor: pointer;
    }

    #root {
        height: 100%;
    }
    .w-100{
        width: 100%;
    }
    .h3-tit{
        color:${props => props.theme.colors.white};
    }

    @media print {
        body {
            width: 1920px;
        }
        body * {
            visibility: hidden;
        }
        #area-print * {
            visibility: visible;
            background: black;
        }
        #area-print {
            width: 1920px;
            position: fixed;
            left: 0;
            top: 0;
            height: 100%;
            #tableManagerColumns {
                display: none;
            }
        }
    }
`