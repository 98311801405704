import moment from 'moment';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { IUser } from '../types/IUser';
import { IUserEyou } from '../types/IUserEyou';

interface UserContextData {
    user: IUser;
    setUser(user: any): void;
    userEyou: IUserEyou;
    setUserEyou(user: IUserEyou): void;
    iat: number;
    setIat(iat: number): void;
}

const UserContext = createContext<UserContextData>({} as UserContextData);

export const UserProvider: React.FC = ({ children }) => {
    const [user, setUser] = useState<IUser>({} as IUser);
    const [userEyou, setUserEyou] = useState<IUserEyou>({} as IUserEyou);
    const [iat, setIat] = useState<any>(0);

    useEffect(() => {
        const userEyouStorageString: any = window.sessionStorage.getItem('user');
        const userEyouStorage: any = JSON.parse(userEyouStorageString);
        const iatStorage: any = window.sessionStorage.getItem('iat');

        if (userEyouStorageString) setUserEyou(userEyouStorage);
        if (iatStorage) setIat(iatStorage);
    }, []);

    //api profile
    useEffect(() => {
        if (userEyou.idUser) window.sessionStorage.setItem('user', JSON.stringify(userEyou));
        if (iat) window.sessionStorage.setItem('iat', iat);
    }, [userEyou, iat]);

    return (
        <UserContext.Provider value={{ user, setUser, userEyou, setUserEyou, iat, setIat }}>
            {children}
        </UserContext.Provider>
    );
};

export function useUser() {
    const context = useContext(UserContext);
    return context;
}

export default UserContext;
