import React, { createContext, useContext, useEffect, useState } from 'react';
import apiTropa from '../services/apiTropa';
import { useMessage } from './MessagesContext';
import { useSocket } from './SocketContext';
import { useUser } from './UserContext';

interface IRules {
    name: string;
    paths: any;
}

interface AuthContextData {
    signed: boolean;
    setLogged(token: string | any, tokenInicial: any): void;
    setLogout(redirect?: boolean): void;
    pageName: string;
    setPageName(page: string): void;
    subPageName: string;
    setSubPageName(page: string): void;
    rules: IRules;
    setRules: React.Dispatch<React.SetStateAction<IRules>>;
    getProfile: () => void
}

interface IRequestPerfil {
    data: {
        criado: string;
        foto: string;
        id_cliente: string;
        id_login: string;
        id_perfil: number;
        id_perfil_permissao: number;
        modificado: string;
        perfil: string;
        paths: any;
    };
}

const RoutesContext = createContext<AuthContextData>({} as AuthContextData);

export const RoutesProvider: React.FC = ({ children }) => {
    const { onInit, socketStatus } = useSocket()
    const [pageName, setPageName] = useState<string>('');
    const [subPageName, setSubPageName] = useState<string>('');
    const [signed, setSigned] = useState<boolean>(
        window.sessionStorage.getItem('token') ? true : false
    );
    const [rules, setRules] = useState<IRules>({
        name: 'visitor',
        paths: ['/'],
    });
    const { addConfirm, addDanger } = useMessage();
    const { userEyou, user, setUser, iat } = useUser();

    const [modalExpire, setModalExpire] = useState(false);
    const [timeExpire, setTimeExpire] = useState({
        min: 30,
        seg: 60,
        time: '30:00',
    });

    useEffect(() => {
        if (signed === true) getProfile();
    }, [signed]);

    useEffect(() => {
        if (iat && pageName !== 'Chat') {
            const reloadTime = setInterval(() => {
                expireSession();
            }, 1000);
            return () => {
                clearInterval(reloadTime);
            };
        }
    }, [signed, iat, pageName]);

    function expireSession() {
        try {
            
            const dateNow: any = new Date();
            const timeSession = new Date(iat * 1000);
            const tempoEmMinutos = 540;
            const expiracao: any = new Date(
                timeSession.getTime() + tempoEmMinutos * 60000
            );

            var faltam = expiracao - dateNow;

            var minutos: number = Math.floor(faltam / 60000);
            var segundos = faltam % 60000;
            var tempoRestante = `${minutos}:${segundos
                .toString()
                .substring(0, 2)}`;

            //setTimeExpire({
                //min: minutos,
                //seg: segundos,
                //time: tempoRestante,
            //});

            if (minutos < 1 && signed === true) {
                throw 'Sessão expirada, logue novamente.';
            }
        } catch (err: any) {
            setModalExpire(true);
            setSigned(false)
            addConfirm({
                title: `Oi, ${userEyou.name}!`,
                description: err,
                hiddenButtonCancel: true,
                textButtonConfirm: 'Entrar',
                onConfirm: setLogout,
                onCancel: setLogout
            });
        }
    }

    useEffect(() => {
    }, [timeExpire]);

    async function getProfile() {
        try {

            const token:any = window.sessionStorage.getItem('token')

            const perfil = await apiTropa.get<IRequestPerfil>(
                'perfil/meu-perfil',
                {
                    headers: {
                        Authorization:
                            'Bearer ' + token,
                    },
                }
            );

            if ( !perfil.data.data.id_login ) {
                getProfile()
                throw ""
            }

            setUser(perfil.data.data);

            if (perfil.data.data.perfil === 'Operador') {
                setRules({
                    name: 'operador',
                    paths: [
                        'Fila de atendimento',
                        // 'Horarios e grupos',
                        // 'Meu desempenho',
                        'Sair do painel',
                    ],
                });

                const token:any = window.sessionStorage.getItem('token')

                if ( socketStatus === false ) onInit()

            } else if (perfil.data.data.perfil === 'Administrador') {

                setRules({
                    name: 'administrador',
                    paths: perfil.data.data.paths
                });
            }

            //if ( socketStatus === false ) onInit()

        } catch (err) {
            if ( err ) {
                addDanger(
                    'Atenção',
                    'Não foi possivel realizar o login nesse momento.'
                );
                setSigned(false);
            }
        }
    }

    function setLogged(token: any, tokenInicial: any) {
        setSigned(true);
        window.sessionStorage.setItem('token', token);
        window.sessionStorage.setItem('tokenOperator', token);
        window.sessionStorage.setItem('tokenInicial', tokenInicial);
    }

    async function setLogout( redirect: boolean = true ) {

        window.sessionStorage.removeItem('token');
        window.sessionStorage.removeItem('tokenOperator')
        window.sessionStorage.removeItem('tokenInicial');
        window.sessionStorage.removeItem('user');
        window.sessionStorage.removeItem('iat');

        setSigned(false);
        setModalExpire(false);
        setRules({
            name: 'visitor',
            paths: ['/'],
        })

        setModalExpire(false)
        
        if ( redirect ) {
            window.location.href = '/';
        }
        
        const response:any = await apiTropa.put('operador/logout')
    }

    return (
        <RoutesContext.Provider
            value={{
                signed,
                setLogged,
                setLogout,
                pageName,
                setPageName,
                rules,
                setRules,
                subPageName,
                setSubPageName,
                getProfile
            }}
        >
            {children}
        </RoutesContext.Provider>
    );
};

export function useRoutes() {
    const context = useContext(RoutesContext);
    return context;
}

export default RoutesContext;
