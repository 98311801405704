import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    .form-children-default {
        margin-right: 16px;
    }
`

export const Main = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
    .form-children-default {
        /* width: 100%; */
    }
    .status-user {
        white-space: nowrap;
        color: ${({theme}) => theme.colors.white};
    }
    .image {
        width: 132px;
        height: 132px;
        background-color: #eaeaea;
        border-radius: 100px;
        display: flex;
        position: relative;
        svg {
            position: absolute;
            bottom: -2px;
            right: -2px;
        }
    }
    .skeletor {
        margin-bottom: 8px;
    }
`;

export const EditButton = styled.div`
    display: inline-block;
    width: 100%;
    margin-top: 15px;
    button{
        width: 100%;
        text-align: center;
        display: inline-block;
    }
`
