import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  background: #eaeef3;
  border-radius: 4px;
  padding: 0 10px;

  .icon {
    display: grid;
    place-items: center;
    
    margin-right: 4px;
  }

  input {
    flex: 1;
    height: 32px;
    padding: 0 12px;
    background: transparent;
    border: none;
  }

  .iconClose {
    border: none;
    display: grid;
    place-items: center;
  }
`;